import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { Button, TextFieldContainer } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { loading, success, failure, status } = useSelector(
    ({ user }) => user.changePassword
  );
  const { id } = useSelector(({ user }) => user.userData);
  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("No password provided")
      .min(8, "Password is too short - should be 8 chars minimum"),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  });
  const formFields = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: ({ oldPassword, newPassword }) => {
      dispatch(actions.changePassword({ id, oldPassword, newPassword }));
    },
  });
  useEffect(() => {
    return () => {
      dispatch(actions.clearChangePassword());
    };
  }, [dispatch]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Password Change Successfully",
        })
      );
      dispatch(actions.clearChangePassword());
      formFields.resetForm();
    }
  }, [dispatch, formFields, success]);
  useEffect(() => {
    if (failure) {
      let message = "";
      if (status === 2) {
        message = "Password is incorrect";
      } else {
        message = "Something is wrong!";
      }
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message,
        })
      );
      dispatch(actions.clearChangePassword());
    }
  }, [dispatch, failure, status]);
  const {
    resetForm,
    handleSubmit,
    errors,
    touched,
    getFieldProps,
  } = formFields;
  return (
    <form onSubmit={handleSubmit}>
      <TextFieldContainer
        type="password"
        fixedLabelWidth
        showErrors={touched["oldPassword"]}
        errorMessage={errors["oldPassword"]}
        {...getFieldProps("oldPassword")}
        name="oldPassword"
        label="Old password"
      />
      <TextFieldContainer
        type="password"
        fixedLabelWidth
        name="newPassword"
        showErrors={touched["newPassword"]}
        errorMessage={errors["newPassword"]}
        {...getFieldProps("newPassword")}
        label="New password"
      />
      <TextFieldContainer
        type="password"
        fixedLabelWidth
        name="confirmNewPassword"
        showErrors={touched["confirmNewPassword"]}
        errorMessage={errors["confirmNewPassword"]}
        {...getFieldProps("confirmNewPassword")}
        label="Confirm new password"
      />
      <div className="d-flex justify-content-end">
        <Button onClick={() => resetForm()} type="button" variant="secondary">
          Cancel
        </Button>
        <Button loading={loading} type="submit" className="ml-7">
          Save
        </Button>
      </div>
    </form>
  );
};
