import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getSelectRow } from "../../../../_helpers";
import { ClassColumn, DateColumn, RouteColumn } from "./ColumnActions";

export const FlightSegments = ({ setSelectedSegments, segments, fields }) => {
  const [ids, setIds] = useState([]);
  const entities = segments?.map(({ iSeg }, index) => {
    const from = iSeg[0].dApt;
    const to = iSeg[iSeg.length - 1].aApt;
    return { from, to, id: index };
  });
  const { getFieldProps, touched, errors, setFieldValue } = fields;

  useEffect(() => {
    const segmentsWithIds = segments?.map((segment, index) => ({
      ...segment,
      ...entities.filter((_, index) => ids.includes(index))[0],
    }));
    const temp = segmentsWithIds?.filter((_, index) => ids.includes(index));
    setSelectedSegments(temp);
  }, [ids]);
  const columns = [
    {
      formatter: RouteColumn,
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      // style: {
      //   maxWidth: "150px",
      // },
    },
    {
      formatter: DateColumn,
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      formatExtraData: {
        getFieldProps,
        errors,
        touched,
        ids,
      },
      style: {
        width: "350px",
      },
    },
    {
      formatter: ClassColumn,
      classes: "text-left pr-0",
      headerClasses: "text-left pr-3",
      formatExtraData: {
        setFieldValue,
        getFieldProps,
        errors,
        touched,
        ids,
      },
      // style: {
      //   maxWidth: "100px",
      // },
    },
  ];
  if (!segments) return <></>;
  return (
    <div className="mt-5">
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities || []}
        columns={columns}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}
        selectRow={{
          ...getSelectRow({
            entities: entities,
            ids: ids,
            setIds: setIds,
          }),
          selectColumnStyle: { width: "20px" },
          // selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
          //   <></>
          // )
        }}
        // rowEvents={rowEvents}
        rowStyle={{ cursor: "pointer" }}
        // {...paginationTableProps}
      />
    </div>
  );
};
