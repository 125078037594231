import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Seperator } from "../../../common";

export const InventoryFormSection = ({
  children,
  title,
  hasSeparator,
  rightComponent,
  bottomButton,
}) => {
  return (
    <Box className="w-100">
      <Box className="d-flex justify-content-between align-items-start">
        <Typography className="mb-7 font-weight-bold">{title}</Typography>
        {rightComponent}
      </Box>
      {children}
      {bottomButton}
      {hasSeparator && <Seperator className="my-7" />}
    </Box>
  );
};
