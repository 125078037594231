import { Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { GivePermissions } from "./GivePermissions";

export const Permissions = ({ directLink }) => {
  const { userId } = useParams();
  return (
    <Paper className="p-7">
      <GivePermissions directLink={directLink} userId={userId} />
    </Paper>
  );
};
