import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./filesConstants";

export const UPLOAD_FILE = requestActions(MODULE_NAME, "UPLOAD_FILE");

export const uploadFile = (params) =>
  requestApiCall("uploadFile", params, UPLOAD_FILE);

export const clearUpload = () => ({ type: UPLOAD_FILE.CLEAR });
