import {
  InputBase,
  Grid,
  Paper,
  withStyles,
  FormControl,
  Select,
  MenuItem,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isArray } from "lodash-es";

import { parseRoute } from "../../../../app/helpers/formatters";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import { FLIGHT_SEARCH, TRAVELLER_DETAILS } from "../../../../app/routesMap";
import { actions } from "../../../../redux/state/flightSearch";
import { actions as bookingMngActions } from "../../../../redux/state/bookingMng";
import { OrderPriceCard } from "../admin/bookingMng/issuedOrderDetails/orderSummary/OrderPriceCard";
import { LoadingSpinner, Seperator, Tabs } from "../common";
import { FlightDetailsCard } from "./DapartingFlights";
import { FlightDetailsForm } from "./manualBooking/FlightDetailsForm";
import { feeTypes } from "../admin/systemManagement/serviceFees/ServiceFeesRow";

// const BootstrapInput = withStyles((theme) => ({
//   input: {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: "#EBEDF5",
//     fontSize: 16,
//     padding: "10px 26px 10px 12px",
//   },
// }))(InputBase);
const BootstrapInput2 = withStyles((theme) => ({
  input: {
    borderRadius: 6,
    backgroundColor: "#FFFFFF",
    color: "#3798D3",
    border: "1px solid #3798D3",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);

export const FlightDetails = ({ steps, selectedAirline, searchRQ }) => {
  const { id, guid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isManual } = useSelector((state) => state.booking);
  const { bookingParams } = useSelector((state) => state.booking);

  const onManualSubmit = (newAirline) => {
    history.push(parseRoute(TRAVELLER_DETAILS, { id, guid }));
    dispatch(actions.setSelectedAirlineData(newAirline));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {steps}
      </Grid>
      <Grid item xs={12}>
        {isManual ? (
          <FlightDetailsForm
            searchRQ={searchRQ}
            selectedAirline={selectedAirline}
            onSubmit={onManualSubmit}
            bookingParams={bookingParams}
          />
        ) : (
          <FlightDetailsInfo
            searchRQ={searchRQ}
            selectedAirline={selectedAirline}
          />
        )}
      </Grid>

      {/* <Grid item sm xs={12} md>
          <Paper className={"p-7 d-flex justify-content-center"}>
            <SeatPicker />
          </Paper>
        </Grid> */}
    </Grid>
  );
};

export const FlightDetailsInfo = ({ selectedAirline, searchRQ }) => {
  const history = useHistory();
  const { id, guid } = useParams();
  const {
    failure: fareRulesFailure,
    loading: fareRulesLoading,
    data: fareRulesData,
  } = useSelector(({ flightSearch }) => flightSearch.fareRules);
  const { loading: repriceLoading } = useSelector(
    ({ flightSearch }) => flightSearch.reprice
  );
  const { cabinClass, segments, cancellationCharges } = selectedAirline;
  const { iSeg } = segments[0];
  const { paxDetails } = searchRQ;
  const [percentage, setPercentage] = useState("default");
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const handleChange = (e) => {
    setPercentage(e.target.value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md>
        <Paper className="p-7 h-100">
          <FlightDetailsCard
            cabinClass={cabinClass}
            segments={segments}
            airlinesList={airlinesList}
            airportsList={airportsList}
            gds={selectedAirline.gds}
          />
          <Seperator />
          <div className="d-flex ">
            <FormControl className="w-100 mr-10">
              <Select
                value={percentage}
                onChange={handleChange}
                input={<BootstrapInput2 />}
                name="trip"
              >
                <MenuItem value="default" disabled>
                  {iSeg[0].fba}
                </MenuItem>
                {/* <MenuItem value="oneWay">10%</MenuItem> */}
              </Select>
            </FormControl>
            <button
              onClick={() => history.replace(FLIGHT_SEARCH)}
              className="btn btn-primary m-0 w-100 h-auto"
            >
              Change Flights
            </button>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md>
        <Paper className="p-7 h-100">
          <OrderPriceCard data={{ selectedAirline, paxDetails }} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <RulesTabs
            fareRules={fareRulesData}
            cancellationCharges={cancellationCharges}
            fareRulesFailure={fareRulesFailure}
            fareRulesLoading={fareRulesLoading || repriceLoading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md>
        <Paper className="p-7 d-flex flex-column">
          {/* <h2>Select Seats</h2> */}
          <div className="d-flex flex-grow-1 flex-column justify-content-between">
            {/* <div>
                {Array(paxDetails.adult)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={`passenger_${index}`}
                      className={styles.passenger}
                    >
                      <span>Passenger 1</span>
                      <span>LON{">"}STL</span>
                      <span>Departure</span>
                      <span className={styles.label}>B10 </span>
                    </div>
                  ))}
              </div> */}
            <div>
              <div className="d-flex flex-column ">
                {/* <div>
                    <Seat available={true} />
                    <span className="font-size-h6 ml-5">Available seats</span>
                  </div>
                  <Seperator available={false} />
                  <div>
                    <Seat />
                    <span className="font-size-h6 ml-5">Unavailable seats</span>
                  </div> */}

                <button
                  type="button"
                  onClick={() =>
                    history.push(parseRoute(TRAVELLER_DETAILS, { id, guid }))
                  }
                  className="btn btn-primary ml-auto mr-auto my-10 w-50 h-auto"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const RulesTabs = ({
  fareRules,
  fareRulesLoading,
  fareRulesFailure,
  cancellationCharges,
  voidingTime,
}) => {
  const dispatch = useDispatch();
  const [selectedPanel, setSelectedPanel] = useState();
  const { list: feesList, loading: feesLoading } = useSelector(
    ({ bookingMng }) => bookingMng.fees
  );
  const { cancelPenalty, changePenalty } = cancellationCharges ?? {};
  // const { refundable, cancellationCharges } = selectedAirline || {};
  // const { changePenalty, cancelPenalty } = cancellationCharges || {};
  // const refundableText = `Flight is ${
  //   refundable === "true" ? "" : "not"
  // } Refundable`;
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setSelectedPanel(isExpanded ? panel : false);
  };
  useEffect(() => {
    dispatch(bookingMngActions.getAllFees());
  }, [dispatch]);
  const penalitiesContent = (
    <div>
      <div className="d-flex mb-5">
        <Typography variant="body1" gutterBottom>
          Cancel Penalty:
        </Typography>
        <Typography
          variant="body1"
          className="font-weight-bold ml-2"
          gutterBottom
        >
          {cancelPenalty ?? "Not Found"}
        </Typography>
      </div>
      <div className="d-flex">
        <Typography variant="body1" gutterBottom>
          Change Penalty:
        </Typography>
        <Typography
          variant="body1"
          className="font-weight-bold ml-2"
          gutterBottom
        >
          {changePenalty ?? "Not Found"}
        </Typography>
      </div>
    </div>
  );

  const flymeFeesContent = (
    <div>
      {feesLoading ? (
        <LoadingSpinner />
      ) : (
        feesList?.map(({ amount, type }) => {
          const feeName = feeTypes[type];
          return (
            <div className="d-flex mb-5">
              <Typography variant="body1" gutterBottom>
                {feeName} Service Fee:
              </Typography>
              <Typography
                variant="body1"
                className="font-weight-bold ml-2"
                gutterBottom
              >
                ${amount}
              </Typography>
            </div>
          );
        })
      )}
    </div>
  );

  const fareRulesContent = (
    <div>
      {fareRulesLoading && !fareRulesFailure && <LoadingSpinner />}
      {!fareRulesLoading && fareRulesFailure && (
        <Typography className="text-danger" variant="h6">
          can't load the fare rules
        </Typography>
      )}
      {!fareRulesLoading && !fareRulesFailure && (
        <>
          <ExpansionPanel
            key={1}
            expanded={selectedPanel === 1}
            onChange={handlePanelChange(1)}
            elevation={0}
            className="rounded"
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="text-primary"
              // color='primary'
            >
              {selectedPanel === 1 ? "Hide" : "view"} Fare Rules
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="d-flex flex-column">
              {isArray(fareRules) &&
                fareRules?.map(({ sector, description }, index) => (
                  <div key={`sector-${index}`} className="mb-5">
                    <Typography variant="h6">{sector}</Typography>
                    <div
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {/* <Typography variant="body1">{description}</Typography> */}
                    <Seperator />
                  </div>
                ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      )}
    </div>
  );
  // const timingContent = (
  //   <div>
  //     <ul className="pl-3">
  //       A printed copy of this eticket or eticket display on laptop, tablet or
  //       phone must be presented at the time of check in.
  //       <li>
  //         Checkin starts 2 hours before scheduled departure, and closes 60
  //         minutes prior to the departure time.
  //       </li>
  //       <li>
  //         We recommend you report at the checkin counter at least 2 hours prior
  //         to departure time.
  //       </li>
  //       <li>
  //         It is mandatory to carry Government recognised photo identification
  //         (ID) along with your ETicket. This can include: Driving License,
  //         Passport, PAN Card, Voter ID Card or any other ID issued by the
  //         Government.
  //       </li>
  //       <li>
  //         For infant passengers, it is mandatory to carry the Date of Birth
  //         certificate.
  //       </li>
  //       <li>
  //         Get Flight Status on SMS by giving a missed call to +2521840975
  //         /+442080049377 or SMS(Normal SMS Charges Apply) booking@flyme247.com
  //         +2521840975 /+442080049377 from the mobile number used for your
  //         booking
  //       </li>
  //     </ul>
  //   </div>
  // );
  const tabsData = [
    { id: "5", title: "Fare rules", content: fareRulesContent },
    {
      id: "2",
      title: "Penalities",
      content: penalitiesContent,
    },
    { id: "6", title: "Flyme247 service fees", content: flymeFeesContent },
    {
      id: "4",
      title: "Ticketing and voiding rules",
      content: voidingTime ?? "No Rules Found",
    },
    // { id: "1", title: "Baggage info", content: tabContent },
    // { id: "3", title: "Reissue Service Hours", content: tabContent },
  ];
  return <Tabs data={tabsData} />;
};
