import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ACCOUNT_NOTIFICATION_ACCESS } from "../../../../../app/routesMap";
import { ADMIN_NOTIFICATIONS_ACCESS } from "../../../../../app/routesMapAdmin";

export const NotificationsWrapper = ({ children }) => {
  const history = useHistory();
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 bg-white d-flex justify-content-between">
          <div>
            <Typography variant="h5">Notifications</Typography>
            <Typography variant="body1" className="text-muted">
              your notification center
            </Typography>
          </div>
          <button
            onClick={() =>
              history.push(
                isAdmin
                  ? ADMIN_NOTIFICATIONS_ACCESS
                  : ACCOUNT_NOTIFICATION_ACCESS
              )
            }
            className="btn text-primary border-primary font-weight-bold"
          >
            Notification Access
          </button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7 bg-white">{children}</Paper>
      </Grid>
    </Grid>
  );
};
