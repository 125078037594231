import React from "react";
import { Modal } from "react-bootstrap";
import { Grid, Switch } from "@material-ui/core";
import { Button, Input, Select } from "../../../common";
import { getCountriesOptions } from "../../../../../_assets/js/components/options";

export const BankForm = ({ fields, show, loading, onHide }) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;
  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Create new bank</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {loading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Input
                {...getFieldProps("bankName")}
                showErrors={touched["bankName"]}
                errorMessage={errors["bankName"]}
                variant="secondary"
                label="Bank Name"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                showErrors={touched["country"]}
                errorMessage={errors["country"]}
                value={values.country}
                onChange={(newValue) => setFieldValue("country", newValue)}
                variant="secondary"
                label="Country"
                options={getCountriesOptions()}
              />
            </Grid>
            <Grid className="d-flex justify-content-start" item xs={12} md={4}>
              <label className="d-flex flex-column align-items-center justify-content-between">
                Active
                <Switch
                  checked={values.isActive}
                  onClick={() => setFieldValue("isActive", !values.isActive)}
                  color="primary"
                />
              </label>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            smallPadding
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
