import { Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import ReactQuill from "react-quill";

import { InventoryFormActions } from "./InventoryFormActions";

export const PackageDescription = ({
  setSelectedTab,
  selectedTab,
  data,
  onSubmit,
}) => {
  const fields = useFormik({
    initialValues: data ?? { description: "" },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const { setFieldValue, values, handleSubmit } = fields;
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" className="font-weight-bold ">
        Description
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReactQuill
            theme="snow"
            value={values.description}
            onChange={(value) => setFieldValue("description", value)}
            placeholder="Write something..."
            className="h-100 w-100"
          />
        </Grid>
      </Grid>
      <div className="mb-15" />
      <InventoryFormActions
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </form>
  );
};
