import React from "react";
import { HashLink } from "react-router-hash-link";
import { Wrapper } from "../../../_metronic/layout/components/ticketSummary/common/Wrapper";
import { PRIVACY_POLICY } from "../../routesMap";
import { Grid, Paper, useMediaQuery, useTheme } from "@material-ui/core";

const parseTextIntoId = (text) => text.replaceAll(" ", "_");

const textMap = [
  {
    title: "Introduction",
    content: (
      <>
        <p>
          At Flyme247 Ltd., accessible from flyme247.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Flyme247 Ltd. and how we use it.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
      </>
    ),
  },
  {
    title: "What personal information do we collect?",
    content: (
      <>
        <p>
          We may collect personal information from you when you visit our
          website, register on the website, place an order, subscribe to our
          newsletter, respond to a survey or fill out a form. The personal
          information that we collect may include your name, email address,
          phone number, and other contact or identifying information.
        </p>
      </>
    ),
  },
  {
    title: "How do we use your personal information?",
    content: (
      <>
        <p>
          We may use the personal information that we collect from you to
          provide and improve our services, to personalize your experience on
          our website, to communicate with you, to process transactions, to send
          periodic emails, and for other purposes related to our business.
        </p>
      </>
    ),
  },
  {
    title: "How do we share your personal information?",
    content: (
      <>
        <p>
          We may share your personal information with third-party service
          providers who perform services on our behalf, such as payment
          processing, data analysis, email delivery, hosting services, and
          customer service. We may also share your personal information with our
          business partners, affiliates, and other third parties for marketing
          and other purposes related to our business.
        </p>
      </>
    ),
  },
  {
    title: "How long do we keep your personal information?",
    content: (
      <>
        <p>
          We will retain your personal information for as long as necessary to
          fulfill the purposes for which it was collected, to provide our
          services, to comply with legal obligations, to resolve disputes, and
          to enforce our agreements.
        </p>
      </>
    ),
  },
  {
    title: "How do we protect your personal information?",
    content: (
      <>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, disclosure, alteration, and destruction.
          These measures may include encryption, access controls, and other
          security measures.
        </p>
      </>
    ),
  },
  {
    title: "Your rights",
    content: (
      <>
        <p>
          You have the right to access, correct, and delete your personal
          information. You may also have the right to object to or restrict the
          processing of your personal information, and to data portability. If
          you have any questions or concerns about your rights, please contact
          us.
        </p>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          personal information we hold about you and if you want it to be
          removed from our systems, please contact us.
        </p>
        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ul>
          <li>
            The right to access, update or to delete the personal information we
            have on you;
          </li>
          <li>
            The right of rectification. You have the right to have your personal
            data rectified if that information is inaccurate or incomplete;
          </li>
          <li>
            The right to object. You have the right to object to our processing
            of your personal data;
          </li>
          <li>
            The right of restriction. You have the right to request that we
            restrict the processing of your personal information;
          </li>
          <li>
            The right to data portability. You have the right to be provided
            with a copy of your personal data in a structured, machine-readable
            and commonly used format;
          </li>
          <li>
            The right to withdraw consent. You also have the right to withdraw
            your consent at any time where Flyme247 Ltd. relied on your consent
            to process your personal information.
          </li>
        </ul>
        <p>
          Please note that we may ask you to verify your identity before
          responding to such requests.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your personal information. For more
          information, please contact your local data protection authority in
          the European Economic Area (EEA).
        </p>
      </>
    ),
  },
  {
    title: "Cookies",
    content: (
      <>
        <p>
          Like many websites, we use "cookies" to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our website.
        </p>
      </>
    ),
  },
  {
    title: "Third-party websites",
    content: (
      <>
        <p>
          Our website may contain links to third-party websites that are not
          operated by us. If you click on a third-party link, you will be
          directed to that third party's site. We strongly advise you to review
          the Privacy Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
      </>
    ),
  },
  {
    title: "Children's Information",
    content: (
      <>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          Flyme247 Ltd. does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </>
    ),
  },
  {
    title: "Changes to This Privacy Policy",
    content: (
      <>
        <p>
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, after they are posted on this
          page.
        </p>
      </>
    ),
  },
  {
    title: "Contact Us",
    content: (
      <>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </p>
      </>
    ),
  },
];

export const Navigator = () => {
  return (
    <Paper className="p-7 w-100 ">
      <ul>
        {textMap.map(({ title, list }) => (
          <li>
            <HashLink
              className="text-dark text-hover-primary"
              to={`${PRIVACY_POLICY}#${parseTextIntoId(title)}`}
            >
              {title}
            </HashLink>
            {/* <ul>
            {list.map(({ title }) => (
              <li>
                <HashLink
                  className="text-dark text-hover-primary"
                  to={`${PRIVACY_POLICY}#${parseTextIntoId(title)}`}
                >
                  {title}
                </HashLink>
              </li>
            ))}
          </ul> */}
          </li>
        ))}
      </ul>
    </Paper>
  );
};

export const PrivacyPolicy = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Wrapper>
      <Grid container>
        {!matchesMd && (
          <Grid
            item
            xs={12}
            md={4}
            className="d-flex flex-column justify-content-start"
          >
            <Navigator />
          </Grid>
        )}
        <Grid item xs={12} md={8} className="pr-7">
          {textMap.map(({ title, content }) => (
            <div key={title} id={parseTextIntoId(title)}>
              <h2>{title}</h2>
              {content}
            </div>
          ))}
        </Grid>
        {matchesMd && (
          <Grid
            item
            xs={12}
            md={4}
            className="d-flex flex-column justify-content-start"
          >
            <Navigator />
          </Grid>
        )}
      </Grid>
      {/* <Navigator /> */}
      {/* <div className={styles.content}>
        {textMap.map(({ title, content }) => (
          <div key={title} id={parseTextIntoId(title)}>
            <h2>{title}</h2>
            {content}
          </div>
        ))}
      </div> */}
    </Wrapper>
  );
};
