import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CompanyDetailsPage,
  ConfirmationPage,
  LoginDetailsPage,
} from "../../../../app/pages/signUp";
import {
  SIGNUP_COMPANY_DETAILS,
  SIGNUP_CONFIRMATION,
  SIGNUP_USER_DETAILS,
  SIGNUP_USER_TYPE,
} from "../../../../app/routesMap";
import { actions as companyActions } from "../../../../redux/state/company";
import { actions as userActions } from "../../../../redux/state/user";
import { actions as filesActions } from "../../../../redux/state/files";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_ERROR } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ORGANIZATION_TYPE_AGENCY } from "../../../../redux/state/company/companyConstants";
import { CompanyDetails, Confirmation, LoginDetails } from ".";
import { SelectUserType } from "./SelectUserType";

const routesMap = [
  {
    route: SIGNUP_USER_TYPE,
    component: SelectUserType,
  },
  {
    route: SIGNUP_COMPANY_DETAILS,
    component: CompanyDetailsPage,
  },
  {
    route: SIGNUP_USER_DETAILS,
    component: LoginDetailsPage,
  },
  {
    route: SIGNUP_CONFIRMATION,
    component: ConfirmationPage,
  },
  // {
  //   route: SIGNUP_COMPLETED,
  //   component: CompletedPage,
  // },
];
export const SignUp = () => {
  const dispatch = useDispatch();
  const [companyParams, setCompanyParams] = useState();
  const history = useHistory();
  const {
    loading: createCompanyLoading,
    success: createCompanySuccess,
    failure: createCompanyFailure,
    companyId,
    registrationType,
  } = useSelector(({ company }) => company.create);
  const {
    loading: createUserLoading,
    success: CreateUserSuccess,
    failure: CreateUserFailure,
    userId,
  } = useSelector(({ user }) => user.create);
  const {
    url,
    loading: uploadLoading,
    success: uploadSuccess,
    failure: uploadFailure,
  } = useSelector(({ files }) => files.upload);

  const onSubmitCompanyInfo = (params) => {
    setCompanyParams(params);
    history.push(SIGNUP_USER_DETAILS);
  };
  const uploadLogo = (logo) => {
    const formData = new FormData();
    formData.append("file", logo, logo.name);
    dispatch(filesActions.uploadFile({ userId: 1, formData }));
  };
  const createCompany = ({ url }) => {
    const { logo } = companyParams;
    dispatch(
      companyActions.createCompany({
        ...companyParams,
        logo: url || logo,
        registeredEmail: userFields.values.email,
        isActive: false,
        organisationTypeId: registrationType,
      })
    );
  };

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    name: Yup.string().when("registrationType", {
      is: ORGANIZATION_TYPE_AGENCY,
      then: Yup.string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Name")
        .required("First Name is required"),
    }),
    password: Yup.string()
      .required("No password provided")
      .min(6, "Password is too short - should be 8 chars minimum"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  const userFields = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      isActive: false,
    },
    validationSchema: userSchema,
    onSubmit: () => {
      if (!companyId) {
        // const { logo } = companyParams;
        // if (logo) {
        //   uploadLogo(logo);
        // } else {
        createCompany({});
        // }
      } else {
        createUser();
      }
    },
  });
  useEffect(() => {
    if (uploadSuccess) {
      createCompany({ url });
      dispatch(filesActions.clearUpload());
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (uploadFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong! Can't upload logo",
        })
      );
      dispatch(filesActions.clearUpload());
    }
  }, [uploadFailure]);

  useEffect(() => {
    dispatch(userActions.reset());
    dispatch(companyActions.reset());
    history.push(SIGNUP_USER_TYPE);
    return () => {
      if (createCompanySuccess && !CreateUserSuccess) {
        dispatch(companyActions.deleteCompany({ id: companyId }));
      }
      dispatch(userActions.reset());
      dispatch(companyActions.reset());
      dispatch(filesActions.clearUpload());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { values: userValues, resetForm: resetUserForm } = userFields;
  useEffect(() => {
    resetUserForm();
  }, [registrationType, resetUserForm]);
  const createUser = useCallback(() => {
    const { email, password, name } = userValues;
    dispatch(
      userActions.createUser({
        email,
        password,
        companyInUserId: companyId,
        name: name || companyParams?.name,
        isDeleted: false,
      })
    );
  }, [companyId, dispatch, userValues]);
  useEffect(() => {
    if (createCompanySuccess && !userId) {
      createUser();
    }
  }, [createCompanySuccess, createUser, userId]);
  useEffect(() => {
    if (CreateUserSuccess) {
      history.push(SIGNUP_CONFIRMATION);
      resetUserForm();
      // resetCompanyForm();
    }
  }, [CreateUserSuccess, history, resetUserForm]);
  useEffect(() => {
    if (createCompanyFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(companyActions.clearCreate());
      dispatch(filesActions.clearUpload());
    }
  }, [createCompanyFailure, dispatch]);
  useEffect(() => {
    if (CreateUserFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: CreateUserFailure,
        })
      );
      dispatch(userActions.clearCreate());
      dispatch(filesActions.clearUpload());
    }
  }, [CreateUserFailure, dispatch]);
  return (
    <Switch>
      <Route
        exact
        path={SIGNUP_USER_TYPE}
        render={() => (
          <SelectUserType
            routesMap={routesMap}
            onSubmit={() => {}}
            initialValues={companyParams}
          />
        )}
      />
      <Route
        path={SIGNUP_COMPANY_DETAILS}
        render={() => (
          <CompanyDetails
            routesMap={routesMap}
            onSubmitCompanyInfo={onSubmitCompanyInfo}
            initialValues={companyParams}
            companyType={registrationType}
          />
        )}
      />
      <Route
        path={SIGNUP_USER_DETAILS}
        render={() => (
          <LoginDetails
            routesMap={routesMap}
            formik={userFields}
            loading={createUserLoading || createCompanyLoading || uploadLoading}
            registrationType={registrationType}
          />
        )}
      />
      <Route
        path={SIGNUP_CONFIRMATION}
        render={() => <Confirmation routesMap={routesMap} />}
      />
    </Switch>
  );
};
