import { client } from "./client";

export const login = ({ email, password }) =>
  client.post("/api/public/login", { email, password });

export const loginByCode = ({ code }) =>
  client.post(`/api/public/loginbycode?code=${code}`);

export const logout = ({ userinUserSessionId, id }) =>
  client.post("/api/user/logout", { userinUserSessionId, id });

export const requestPasswordReset = ({ email }) =>
  client.post(`/api/public/forgetpassword?email=${email}`);

export const restPassword = ({ password, key }) =>
  client.post("/api/public/PasswordReset", { password, key });
