/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  ABOUT,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from "../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../_helpers";
import { Seperator } from "../common";
import styles from "./Home.module.scss";

export const Footer = ({ noSpacing }) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const wrapperClassNames = clsx(styles.section, "d-flex", {
    "justify-content-around": matchesMd,
    "flex-column align-items-center": !matchesMd,
  });
  return (
    <div
      className={clsx("pt-10 bg-white", {
        "mt-10": !noSpacing,
      })}
    >
      <div className={wrapperClassNames}>
        <div className="mb-5">
          <img
            src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
            className="mb-2 "
            alt="logo"
          />
          {matchesMd && (
            <div className="d-flex">
              <img
                src={toAbsoluteUrl("/media/svg/icons/SocialMedia/Facebook.svg")}
                alt="logo"
              />
              <img
                src={toAbsoluteUrl(
                  "/media/svg/icons/SocialMedia/Linkedin .svg"
                )}
                alt="logo"
              />
              <img
                src={toAbsoluteUrl("/media/svg/icons/SocialMedia/Twitter.svg")}
                alt="logo"
              />
            </div>
          )}
        </div>
        <div className="flex-grow-1">
          <Grid container className="max-w-600px ml-auto">
            <Grid item xs={6} md>
              <ul className={styles.list}>
                <li>
                  <span className="font-weight-bold text-primary">Company</span>
                </li>
                <li>
                  <NavLink to={ABOUT}>About Us</NavLink>
                </li>
                {/* <li>
                  <a>FAQ</a>
                </li>
                <li>
                  <a>Tools</a>
                </li>
                <li>
                  <a>Blog</a>
                </li> */}
              </ul>
            </Grid>
            <Grid item xs={6} md>
              <ul className={styles.list}>
                <li>
                  <span className="font-weight-bold text-primary">
                    Services
                  </span>
                </li>
                <li>
                  <a>flights & hotels</a>
                </li>
                <li>
                  <a>hajj package</a>
                </li>
                <li>
                  <a>B2B services</a>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} md>
              <ul className={styles.list}>
                <li>
                  <span className="font-weight-bold text-primary">
                    Help & Support
                  </span>
                </li>
                <li>
                  <a>Contact Us</a>
                </li>
                {/* <li>
                  <a>Knowledge Center</a>
                </li>
                <li>
                  <a>Custom Development</a>
                </li>
                <li>
                  <a>Sponsorships</a>
                </li> */}
              </ul>
            </Grid>
            <Grid item xs={6} md>
              <ul className={styles.list}>
                <li>
                  <span className="font-weight-bold text-primary">Legal</span>
                </li>
                <li>
                  <NavLink className="text-nowrap" to={TERMS_AND_CONDITIONS}>
                    Terms & Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink className="text-nowrap" to={PRIVACY_POLICY}>
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <a>Licenses (EULA)</a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        {!matchesMd && (
          <div className="d-flex mx-auto">
            <img
              src={toAbsoluteUrl("media/svg/icons/SocialMedia/Facebook.svg")}
              alt="logo"
            />
            <img
              src={toAbsoluteUrl("media/svg/icons/SocialMedia/Linkedin .svg")}
              alt="logo"
            />
            <img
              src={toAbsoluteUrl("media/svg/icons/SocialMedia/Twitter.svg")}
              alt="logo"
            />
          </div>
        )}
      </div>
      <Seperator />
      <Typography
        variant="body1"
        className="mb-3 text-center text-muted"
        // style={{ maxWidth: "600px", color: "#B2B2B2 " }}
      >
        All rights reserved. Copyright © 2021
      </Typography>
    </div>
  );
};
