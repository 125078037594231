import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useHistory, useParams } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";

import { TravellerDetails } from "../../../_metronic/layout/components/fareSearch/TravellerDetails";
import { actions } from "../../../redux/state/booking";
import { parseRoute } from "../../helpers/formatters";
import {
  BookingHold,
  PAX_TYPE_ADULT,
  PAX_TYPE_CHILD,
  PAX_TYPE_YOUTH,
  PAX_TYPE_INFANT,
  PAYMENT_TYPE_CARD,
  BookingIssue,
  ManualBooking,
  bookingTypesMap,
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_PREMIERE,
} from "../../../redux/state/booking/bookingConstants";
import { actions as inlineNotificationActions } from "../../../redux/state/inlineNotification";
import { actions as flightActions } from "../../../redux/state/flightSearch";
import { actions as accountBalanceActions } from "../../../redux/state/accountBalance";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../redux/state/inlineNotification/inlineNotificationConstants";

import { OrderConfirmed } from "../../../_metronic/layout/components/fareSearch/OrderConfirmed";
import {
  RepriceModal,
  REPRICE_ERROR_DEFAULT,
  REPRICE_ERROR_NO_HOLD,
  REPRICE_ERROR_PRICE,
} from "../../../_metronic/layout/components/fareSearch/RepriceModal";
import { Steps } from "../../../_metronic/layout/components/common/Steps";
import {
  COMPLETE_ORDER,
  FLIGHT,
  FLIGHT_SEARCH,
  ORDER_CONFIRMED,
  TRAVELLER_DETAILS,
} from "../../routesMap";
import { useGetBookingParams } from "../../helpers/hooks/useGetBookingParams";
import { getPassengerDetailsSchema } from "./schema";
import { FlightDetails } from "../../../_metronic/layout/components/fareSearch/FlightDetails";
import { usePaymentForm } from "../../helpers/hooks/usePaymentForm";
import { CompleteOrder } from "../../../_metronic/layout/components/fareSearch/CompleteOrder";
import { stripeWebhookPaymentForEnums } from "../../../redux/state/user/userConstants";
import { useTransactionFee } from "../../helpers/hooks/useTransactionFee";
import { Skeleton } from "@material-ui/lab";

const routesMap = [
  {
    route: FLIGHT,
    title: "Flights Details",
  },
  {
    route: TRAVELLER_DETAILS,
    title: "Traveller Details",
  },
  {
    route: COMPLETE_ORDER,
    title: "Complete order",
  },
  {
    route: ORDER_CONFIRMED,
  },
];

const passengerCommonParams = {
  title: "",
  gender: "",
  firstName: "",
  lastName: "",
  passportNumber: "",
  passportNationality: "",
  passportExpDate: "",
  birthday: "",
  wealChair: "",
  mealSelection: "",
};

export const FlightBookPage = () => {
  const history = useHistory();
  const { selectedAirline, searchRQ } = useSelector(
    (state) => state.flightSearch.searchResults
  );
  const [repriceErrorType, setRepriceErrorType] = useState(
    REPRICE_ERROR_DEFAULT
  );
  const { getBookingParams } = useGetBookingParams();
  const [paymentType, setPaymentType] = useState(); // 1 wallet, 2 credit card
  const [showRepriceModal, setShowRepriceModal] = useState();
  const { id, guid } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    feesFailure,
    feesLoading,
    stripeFixedFee,
    stripePercentageFee,
  } = useTransactionFee();

  const { data: fareRulesData } = useSelector(
    ({ flightSearch }) => flightSearch.fareRules
  );
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { bookFlight, bookingStatus, isManual } = useSelector(
    (state) => state.booking
  );

  const { adult, child, infant, youth } = searchRQ?.paxDetails || {};
  const { success, failure, loading } = bookFlight;
  const {
    success: repriceSuccess,
    failure: repriceFailure,
    loading: repriceLoading,
    data: repriceData,
  } = useSelector(({ flightSearch }) => flightSearch.reprice);
  // const getCompanyName = useCallback(
  //   (companyId) => list?.filter(({ name, id }) => id === +companyId)?.[0]?.name,
  //   [list]
  // );

  const dispatch = useDispatch();
  const onSubmit = () => {
    // reprice();
    setIsSubmitted(true);
    dispatch(actions.bookFlight(params));
  };

  const reprice = useCallback(
    () =>
      dispatch(
        flightActions.reprice({
          GUID: guid,
          FlightId: id,
        })
      ),
    [dispatch, guid, id]
  );
  const { b2bFields, cardFields, waafiFields, premiereFields } = usePaymentForm(
    onSubmit
  );

  const { isAdmin } = useSelector(({ company }) => company.companyData);

  const getInitialValues = (count, type) =>
    Array(count)
      .fill(0)
      .map(() => ({
        ...passengerCommonParams,
        type,
        // birthday: type === PAX_TYPE_ADULT ? "-" : "",
        id: uuid(),
      }));
  const travellersFields = useFormik({
    initialValues: {
      passengers: [
        ...getInitialValues(adult, PAX_TYPE_ADULT),
        ...getInitialValues(youth, PAX_TYPE_YOUTH),
        ...getInitialValues(child, PAX_TYPE_CHILD),
        ...getInitialValues(infant, PAX_TYPE_INFANT),
      ],
      leadGuest: {
        passengerNo: "",
        country: "",
        email: "",
        phoneCode: "",
        phoneNumber: "",
        zipCode: "",
      },
      client: {
        title: "",
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        phoneNumber: "",
        zipCode: "",
      },
      agencyId: "",
    },
    validationSchema: getPassengerDetailsSchema({ isAdmin, isManual }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (bookingStatus === BookingHold || bookingStatus === ManualBooking) {
        // history.replace(
        //   parseRoute(ORDER_CONFIRMED, { type: bookingStatus, id, guid })
        // );
        onSubmit();
        // setIsSubmitted(true);
        // dispatch(actions.bookFlight(params));
        // reprice();
      } else if (bookingStatus === BookingIssue) {
        // dispatch(actions.setBookingParams(params));
        history.push(parseRoute(COMPLETE_ORDER, { id, guid }));
      }
      // else if (bookingStatus === ManualBooking) {
      //   // book with special flag for manual booking
      //   dispatch(actions.bookFlight(params));
      // }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(flightActions.clearReprice());
      dispatch(flightActions.clearFareRules());
      // dispatch(flightActions.clearSearchResults());
      dispatch(actions.reset());
      setRepriceErrorType(REPRICE_ERROR_DEFAULT);
    };
  }, [dispatch]);
  useEffect(() => {
    if (!isManual) {
      reprice();
    }
    dispatch(
      accountBalanceActions.getAccountBalance({ companyId: companyInUserId })
    );
  }, [companyInUserId, dispatch, history, isManual, reprice]);
  // useEffect(() => {
  //   if (!fareRulesData) {
  //     // reprice();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, fareRulesData, reprice]);
  const getPaymentValues = useCallback(() => {
    switch (paymentType) {
      case PAYMENT_TYPE_CARD:
        return cardFields.values;
      case PAYMENT_TYPE_WAAFI:
        return waafiFields.values;
      case PAYMENT_TYPE_WALLET:
        return b2bFields.values;
      case PAYMENT_TYPE_PREMIERE:
        return premiereFields.values;
      default:
        return {};
    }
  }, [
    b2bFields.values,
    cardFields.values,
    paymentType,
    premiereFields.values,
    waafiFields.values,
  ]);
  const params = useMemo(() => {
    let paymentValues = getPaymentValues();

    return getBookingParams({
      travellersValues: travellersFields.values,
      paymentValues,
      selectedAirline,
      searchRQ,
      paymentType,
      isManual,
      bookingType: bookingTypesMap.flight,
      fareRules: fareRulesData,
      stripeFixedFee,
      stripePercentageFee,
    });
  }, [
    getBookingParams,
    travellersFields.values,
    paymentType,
    selectedAirline,
    searchRQ,
    isManual,
    fareRulesData,
    getPaymentValues,
    stripeFixedFee,
    stripePercentageFee,
  ]);
  const stripePaymentObject = useMemo(() => {
    const stringfiedPaymentObj = JSON.stringify(params);

    return {
      type: stripeWebhookPaymentForEnums.BookAndIssue,
      object: stringfiedPaymentObj,
    };
  }, [params]);

  const holdParams = useMemo(
    () =>
      getBookingParams({
        travellersValues: travellersFields.values,
        selectedAirline,
        searchRQ,
        isManual,
        fareRules: fareRulesData,
        bookingType: bookingTypesMap.flight,
      }),
    [
      getBookingParams,
      travellersFields.values,
      selectedAirline,
      searchRQ,
      isManual,
      fareRulesData,
    ]
  );
  useEffect(() => {
    if (repriceSuccess) {
      if (repriceData?.error) {
        if (
          bookingStatus === BookingHold &&
          repriceData?.gds === "MTR" &&
          repriceData?.refundable === "false"
        ) {
          setRepriceErrorType(REPRICE_ERROR_NO_HOLD);
          setShowRepriceModal(true);
        } else if (
          bookingStatus === BookingIssue &&
          repriceData?.gds !== "MTR" &&
          repriceData?.refundable !== "false"
        ) {
          setShowRepriceModal(true);
        }
        // else if (
        //   bookingStatus === BookingIssue &&
        //   repriceData?.gds === "MTR" &&
        //   repriceData?.refundable === "false" &&
        //   isSubmitted
        // ) {
        //   dispatch(actions.bookFlight(params));
        // }
        else {
          setShowRepriceModal(true);
        }
      } else if (+repriceData?.totalAmt > +selectedAirline.totalAmt) {
        setShowRepriceModal(true);
        setRepriceErrorType(REPRICE_ERROR_PRICE);
      }
      // else if (isSubmitted) {
      //   dispatch(actions.bookFlight(params));
      // }
      if (!fareRulesData) {
        dispatch(
          flightActions.getFareRules({
            GUID: guid,
            FlightId: id,
          })
        );
      }

      dispatch(flightActions.clearReprice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repriceSuccess, fareRulesData, isSubmitted, guid, id]);

  useEffect(() => {
    if (repriceFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(flightActions.clearReprice());
    }
  }, [dispatch, repriceFailure]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: failure,
        })
      );
      dispatch(flightActions.clearReprice());
      dispatch(actions.clearBookFlights());
    }
  }, [dispatch, failure]);
  useEffect(() => {
    if (success) {
      history.replace(
        parseRoute(ORDER_CONFIRMED, {
          id,
          guid,
          type: bookingStatus,
        })
      );
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: success,
        })
      );
      dispatch(flightActions.clearReprice());
      dispatch(actions.clearBookFlights());
      // dispatch(actions.reset());
    }
  }, [success, history, isAdmin, dispatch, id, guid, bookingStatus]);
  const continueBooking = () => {
    setRepriceErrorType(REPRICE_ERROR_DEFAULT);
    setShowRepriceModal(false);
    dispatch(flightActions.makeRepriceAsSelectedAirline());
    if (repriceErrorType === REPRICE_ERROR_NO_HOLD) {
      dispatch(actions.setBookingStatus({ bookingType: BookingIssue }));
    }
  };
  const cancelBooking = () => {
    history.push(FLIGHT_SEARCH);
    setRepriceErrorType(REPRICE_ERROR_DEFAULT);
    setShowRepriceModal(false);
    dispatch(flightActions.removeSelectedAirlineFromList());
    dispatch(flightActions.clearReprice());
  };
  const steps = <Steps routesMap={routesMap} />;
  return (
    <>
      <RepriceModal
        show={showRepriceModal}
        onGoBack={cancelBooking}
        errorMessage={repriceData?.error}
        errorType={repriceErrorType}
        oldPrice={selectedAirline?.totalAmt}
        newPrice={repriceData?.totalAmt}
        onContinue={continueBooking}
      />
      <Switch>
        {/* {!selectedAirline && <Redirect to={FLIGHT_SEARCH} />} */}
        <Route exact path={TRAVELLER_DETAILS}>
          <TravellerDetails
            steps={steps}
            searchRQ={searchRQ}
            selectedAirline={selectedAirline}
            fields={travellersFields}
            loading={(loading || repriceLoading) && isSubmitted}
          />
        </Route>
        <Route exact path={FLIGHT}>
          <FlightDetails
            steps={steps}
            selectedAirline={selectedAirline}
            searchRQ={searchRQ}
          />
        </Route>
        <Route exact path={COMPLETE_ORDER}>
          {feesLoading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={300}
            />
          ) : feesFailure ? (
            <div className="text-center">
              Error fetching data! Please contact Flyme247 support team
            </div>
          ) : (
            <CompleteOrder
              steps={steps}
              b2bFormik={b2bFields}
              cardFormik={cardFields}
              waafiFormik={waafiFields}
              setPaymentType={setPaymentType}
              paymentType={paymentType}
              loading={(loading || repriceLoading) && isSubmitted}
              bookingDetails={params}
              premiereFormik={premiereFields}
              stripePaymentBody={stripePaymentObject}
              totalPrice={params?.totalPrice}
              onSubmit={onSubmit}
            />
          )}
        </Route>
        <Route exact path={ORDER_CONFIRMED}>
          <OrderConfirmed />
        </Route>
        <Redirect to={FLIGHT} />
        {/* {routesMap.map(({ route, component: Component }) => {
          return (
            <Route exact key={route} path={route}>
              {selectedAirline ? (
                <Component steps={steps} />
              ) : (
                <Redirect to={FLIGHT_SEARCH} />
              )}
            </Route>
          );
        })} */}
      </Switch>
    </>
  );
};
