import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { LoginSessionsChart } from "../../../client/clientDetails/clientSecutrity/LoginSessionsChart";
import { LoginSessionsTable } from "../../../client/clientDetails/clientSecutrity/LoginSessionsTable";
import { OperationLog } from "../../../../account/accountSubUser/SubUser";
import { ClientRecentLog } from "../../../client/clientDetails/clientSecutrity/ClientRecentLog";
import { useSelector } from "react-redux";

export const FlymeSecurity = () => {
  const { id } = useSelector(({ company }) => company.companyData.data);
  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={8}>
        <LoginSessionsChart />
      </Grid>
      <Grid item xs={4}>
        <ClientRecentLog />
      </Grid> */}
      <Grid item xs={12}>
        <LoginSessionsTable id={id} />
      </Grid>
      {/* <Grid item xs={12}>
        <Paper>
          <OperationLog />
        </Paper>
      </Grid> */}
    </Grid>
  );
};
