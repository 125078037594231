import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Button } from "../../_metronic/layout/components/common";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";
import { AddressCard } from "../../_metronic/layout/components/public/contactUs/AddressCard";
import { GET_CONTACT_CONTENT } from "../../graphql/queries";
import { AboutUseSkeleton } from "../skeletons/AboutUsSkeleton";

export const ContactUsPage = () => {
  const { data, loading, error } = useQuery(GET_CONTACT_CONTENT);

  if (error) {
    return (
      <PublicLayout>
        <div className="text-center vh-100 pt-20">
          <Typography variant="h3" className="text-dark font-weight-bolder">
            Something went wrong
          </Typography>
          <div className="my-5" />
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </PublicLayout>
    );
  }

  if (loading)
    return (
      <PublicLayout>
        <AboutUseSkeleton />
      </PublicLayout>
    );
  if (!data?.contactUsPage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  const {
    addressCards,
    pageTitle,
    pageDescription,
  } = data?.contactUsPage?.data?.attributes;
  return (
    <PublicLayout>
      <div className="my-20">
        <Typography
          variant="h2"
          className="text-center font-weight-bolder text-dark mt-25"
          gutterBottom
        >
          {pageTitle}
        </Typography>
        <Typography variant="h6" className="text-center text-muted mb-10">
          {pageDescription}
        </Typography>
        <div
          style={{ height: "50vh", width: "100%" }}
          className="rounded overflow-hidden"
        >
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            frameborder="0"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBIMN63dwEyhtDYHDT0T2qSa3yOedL_CdU&q=2.032109022140503,+45.313472747802734`}
            title="Flyme247 Office Location"
          />
          {/* <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBIMN63dwEyhtDYHDT0T2qSa3yOedL_CdU",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={2.032109022140503}
              lng={45.313472747802734}
              text="My Marker"
            />
          </GoogleMapReact> */}
        </div>
        <Grid container spacing={3} className="mt-20 justify-content-center">
          {addressCards?.map(({ title, phone, address, email }, index) => (
            <Grid item xs={12} md={4} key={index}>
              <AddressCard
                title={title}
                phone={phone}
                address={address}
                email={email}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </PublicLayout>
  );
};
