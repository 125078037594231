import { useMemo } from "react";
import useCitiesList from "./useCitiesList";

export default function useCitiesOptions() {
  const { list, loading } = useCitiesList();
  const citiesOptions = useMemo(
    () =>
      list?.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [list]
  );
  return { citiesOptions, loading };
}
