import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/supplier";

export default function useSupplierName() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ supplier }) => supplier.suppliers);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllSuppliers());
    }
  }, [dispatch, list]);
  const getSupplierName = useCallback(
    (supplierId) =>
      list?.filter(
        ({ id, code }) => +id === +supplierId || code === supplierId
      )?.[0]?.code,
    [list]
  );
  return { getSupplierName, loading };
}
