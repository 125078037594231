import React from "react";
import styles from "./Public.module.scss";
import { toAbsoluteUrl } from "../../../_helpers";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  ABOUT,
  CERTIFICATES,
  CONTACT_US,
  DOWNLOAD,
  HELP_CENTER,
  PRIVACY_POLICY,
  PRODUCTS,
  TERMS_AND_CONDITIONS,
} from "../../../../app/routesMap";

export const PublicFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="pt-8 bg-white max-w-100 overflow-hidden">
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          md={3}
          className="d-flex flex-column align-items-center"
        >
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
            loading="lazy"
            className={styles["img-19"]}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container className="max-w-600px ml-auto">
            <Grid item xs>
              <ul className={styles.footerList}>
                <li className="mb-2">
                  <span className="font-weight-bold text-dark mb-2">
                    Company
                  </span>
                </li>
                <li>
                  <NavLink to={ABOUT}>About Us</NavLink>
                </li>
                <li>
                  <NavLink to={PRODUCTS}>Products</NavLink>
                </li>
                <li>
                  <NavLink to={DOWNLOAD}>Download</NavLink>
                </li>
                {/* <li>
                  <a>FAQ</a>
                </li>
                <li>
                  <a>Tools</a>
                </li>
                <li>
                  <a>Blog</a>
                </li> */}
              </ul>
            </Grid>
            {/* <Grid item xs={6} md>
              <ul className={styles.footerList}>
                <li className="mb-2">
                  <span className="font-weight-bold text-dark mb-2">
                    Services
                  </span>
                </li>
                <li>
                  <a>flights & hotels</a>
                </li>
                <li>
                  <a>hajj package</a>
                </li>
                <li>
                  <a>B2B services</a>
                </li>
              </ul>
            </Grid> */}
            <Grid item xs>
              <ul className={styles.footerList}>
                <li className="mb-2">
                  <span className="font-weight-bold text-dark mb-2">
                    Help & Support
                  </span>
                </li>
                <li>
                  <NavLink to={CONTACT_US}>Contact Us</NavLink>
                </li>
                {/* <li>
                  <a>Knowledge Center</a>
                </li>
                <li>
                  <a>Custom Development</a>
                </li>
                <li>
                  <a>Sponsorships</a>
                </li> */}
              </ul>
            </Grid>
            <Grid item xs={6} md>
              <ul className={styles.footerList}>
                <li className="mb-2">
                  <span className="font-weight-bold text-dark ">Legal</span>
                </li>
                <li>
                  <NavLink className="text-nowrap" to={TERMS_AND_CONDITIONS}>
                    Terms & Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink className="text-nowrap" to={PRIVACY_POLICY}>
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink className="text-nowrap" to={CERTIFICATES}>
                    Certificates
                  </NavLink>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className="d-flex flex-column align-items-center"
        >
          {/* social media */}
          <div className="d-flex align-items-center">
            <button className="btn btn-icon btn-light text-dark mb-2 btn-hover-primary mr-3 rounded-circle shadow-sm">
              <Icon icon="akar-icons:x-fill" />
            </button>
            <button className="btn btn-icon btn-light text-dark mb-2 btn-hover-primary mr-3 rounded-circle shadow-sm">
              <Icon icon="akar-icons:facebook-fill" />
            </button>
            <button className="btn btn-icon btn-light text-dark mb-2 btn-hover-primary mr-3 rounded-circle shadow-sm">
              <Icon icon="akar-icons:instagram-fill" />
            </button>
            {/* <button className="btn btn-icon btn-light text-dark mb-2 btn-hover-primary mr-3 rounded-circle shadow-sm">
              <Icon icon="akar-icons:youtube-fill" />
            </button> */}
          </div>
          <div>
            <Typography variant="h6" className="font-weight-bold mt-4 mb-1">
              Discover our app
            </Typography>
            <div className="d-flex">
              <a
                href="https://play.google.com/store/apps/details?id=com.flyme247&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3"
              >
                <img
                  alt="Get it on Google Play"
                  src={toAbsoluteUrl("/media/svg/icons/General/play-store.svg")}
                />
              </a>
              <a
                href="https://apps.apple.com/tr/app/flyme247/id6457265339"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3"
              >
                <img
                  alt="Get it on App Store"
                  src={toAbsoluteUrl("/media/svg/icons/General/app-store.svg")}
                />
              </a>
              {/* <img
              loading="lazy"
              src={toAbsoluteUrl("/media/svg/icons/General/app-store.svg")}
              className={styles["img-22"]}
              alt="play store"
            /> */}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles["div-87"]}>
        All rights reserved. Copyright © {currentYear}
      </div>
    </div>
  );
};
