import { Typography } from "@material-ui/core";
import { debounce } from "lodash-es";
import React, { useEffect } from "react";

import { Button, Input } from "../../../common";
import { actions } from "../../../../../../redux/state/accounting";
import { useDispatch } from "react-redux";

export const RateColumn = (
  cellContent,
  row,
  rowIndex,
  { getFieldProps, errors, touched }
) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="w-150px">
        <Input
          {...getFieldProps(`${rowIndex}.rate`)}
          showErrors={touched[`${rowIndex}.rate`]}
          errorMessage={errors[`${rowIndex}.rate`]}
          variant="secondary"
          fixedLabelWidth
          noErrorMessage
          type="number"
          step="0.01"
        />
      </div>
    </div>
  );
};

export const SaveColumn = (cellContent, row, rowIndex, { onSave }) => {
  const { loading } = row.edit;
  return (
    <Button onClick={() => onSave(rowIndex)} loading={loading}>
      Save
    </Button>
  );
};
