import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import styles from "./HelpCenter.module.scss";
import { Button } from "../common";
const Tab = ({ title, iconPath, active, onClick }) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const iconClassNames = clsx("svg-icon", {
    "svg-icon-white": active,
    "svg-icon-6x": matchesMd,
    "svg-icon-4x": !matchesMd,
  });
  return (
    <Paper
      onClick={onClick}
      className={clsx(styles.tab, { [styles.activeTab]: active })}
    >
      <span className={iconClassNames}>
        <SVG src={toAbsoluteUrl(iconPath)} />
      </span>
      <Typography variant="h6">{title}</Typography>
    </Paper>
  );
};

const tabsData = [
  { title: "Dashboard", iconPath: "/media/svg/icons/Design/Layers.svg" },
  {
    title: "Fare Search",
    iconPath: "/media/svg/icons/Layout/Layout-4-blocks.svg",
  },
  {
    title: "Booking Management",
    iconPath: "/media/svg/icons/Code/Compiling.svg",
  },
  { title: "Payment", iconPath: "/media/svg/icons/General/payment.svg" },
  { title: "Distribution", iconPath: "/media/svg/icons/Home/Library.svg" },
];

export const AdminGuide = () => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const tabsContent = tabsData.map(({ title, iconPath }, index) => {
    return (
      <Grid item xs>
        <Tab
          title={title}
          iconPath={iconPath}
          onClick={() => setActiveTab(index)}
          active={index === activeTab}
        />
      </Grid>
    );
  });
  return (
    <Grid container spacing={3} justify="space-between" alignItems="stretch">
      {tabsContent}
      <Grid xs={12} item>
        <Paper className="bg-white p-7 flex-grow-1 d-flex justify-content-between align-items-center">
          <div className="max-w-350px">
            <Typography variant="h6" className="font-weight-bold mb-5">
              Start Now
            </Typography>
            <Typography variant="body1" className="mb-5">
              With our new website, enjoy a seamless experience managing all
              your bookings, flight searches and even more , with our new
              dashboard, it all goes flawless.
            </Typography>
            <Button variant="danger">Start tour</Button>
          </div>
          {matchesMd && (
            <img
              className="max-w-300px h-auto"
              src={toAbsoluteUrl("/media/svg/illustrations/sitting.svg")}
              alt=""
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
