import React from "react";
import { Wrapper } from "./Wrapper";
import { LoginForm } from "../public/LoginForm";

export const SignIn = () => {
  return (
    <Wrapper>
      <LoginForm />
    </Wrapper>
  );
};
