import {
  FormControl,
  InputBase,
  NativeSelect,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { SecondaryInput } from "../textField/TextFieldContainer";
import styles from "./Select.module.scss";

const dummyOptions = [
  { value: "option1", label: "option1" },
  { value: "option2", label: "option2" },
  { value: "option3", label: "option3" },
];

export const PrimaryInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
export const SelectContainer = ({
  label,
  disabled,
  value,
  onChange,
  name,
  placeholder,
  required,
  vertical,
  showErrors,
  errorMessage,
  inputProps,
  options = dummyOptions,
  variant = "primary",
  ...rest
}) => {
  const [changed, setChanged] = useState(false);
  const optionsContent = options.map(({ label, value }) => (
    <option value={value}>{label}</option>
  ));
  const containeClassNames = clsx("w-100 d-flex", {
    "align-items-center ": !vertical,
    "flex-column align-items-start ": vertical,
  });
  const inputVariants = {
    primary: InputBase,
    secondary: SecondaryInput,
  };
  const Input = inputVariants[variant];
  useEffect(() => {
    if (inputProps?.value) setChanged(true);
  }, [inputProps?.value]);
  const secondaryClassNames = clsx({ [styles.secondary]: !changed });
  const selectClassNames = {
    secondary: secondaryClassNames,
    primary: "p-2 bg-white rounded",
  };
  return (
    <div className={containeClassNames}>
      <span className={clsx("w-150px", { "mb-3": vertical })}>
        {required && <span className="text-danger">*</span>}
        {label}
      </span>
      <FormControl className="w-100">
        <NativeSelect
          value={value}
          onChange={onChange}
          {...inputProps}
          className={selectClassNames[variant]}
          input={<Input />}
          {...rest}
        >
          <option value="" disabled className={styles.secondaryPlaceholder}>
            {placeholder || "Select"}
          </option>
          {optionsContent}
        </NativeSelect>

        <div className="fv-plugins-message-container h-20px ">
          <div className="fv-help-block">{showErrors && errorMessage}</div>
        </div>
      </FormControl>
    </div>
  );
};
