import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./accountBalanceConstants";

export const GET_ACCOUNT_BALANCE = requestActions(
  MODULE_NAME,
  "GET_ACCOUNT_BALANCE"
);

export const GET_ALL_ACCOUNT_BALANCE = requestActions(
  MODULE_NAME,
  "GET_ALL_ACCOUNT_BALANCE"
);

export const GET_TRANSACTIONS_HISTORY = requestActions(
  MODULE_NAME,
  "GET_TRANSACTIONS_HISTORY"
);

export const GET_ALL_TRANSACTIONS_HISTORY = requestActions(
  MODULE_NAME,
  "GET_ALL_TRANSACTIONS_HISTORY"
);

export const UPDATE_COMPANY_BALANCE = requestActions(
  MODULE_NAME,
  "UPDATE_COMPANY_BALANCE"
);

export const GET_COMPANY_BALANCE = "GET_COMPANY_BALANCE";

// export const getCompanyBalance = ({ companyId }) => ({
//   type: GET_COMPANY_BALANCE,
//   payload: { companyId },
// });

// export const getAccountBalance = ({ id }) =>
//   requestApiCall("getAccountBalance", { id }, GET_ACCOUNT_BALANCE);

export const updateCompanyBalance = (params) =>
  requestApiCall("updateCompanyBalance", params, UPDATE_COMPANY_BALANCE);

export const getAccountBalance = ({ companyId }) =>
  requestApiCall("getAccountBalance", { companyId }, GET_ACCOUNT_BALANCE);

export const getAllTransactionsHistory = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "getAllTransactionsHistory",
    { sortingParams, filterParam, pageNumber, pageSize },
    GET_ALL_TRANSACTIONS_HISTORY
  );

export const getTransactionsHistory = ({ companyId }) =>
  requestApiCall(
    "getTransactionsHistory",
    { companyId },
    GET_TRANSACTIONS_HISTORY
  );

export const getAllAccountBalance = () =>
  requestApiCall("getAllAccountBalance", {}, GET_ALL_ACCOUNT_BALANCE);

export const clearAccountBalance = () => ({ type: GET_ACCOUNT_BALANCE.CLEAR });

export const clearUpdateCompanyBalance = () => ({ type: UPDATE_COMPANY_BALANCE.CLEAR });
