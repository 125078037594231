import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./packageConstants";

export const GET_ALL_CITIES = requestActions(MODULE_NAME, "GET_ALL_CITIES");
export const GET_CITY = requestActions(MODULE_NAME, "GET_CITY");
export const CREATE_CITY = requestActions(MODULE_NAME, "CREATE_CITY");
export const EDIT_CITY = requestActions(MODULE_NAME, "EDIT_CITY");
export const DELETE_CITY = requestActions(MODULE_NAME, "DELETE_CITY");
export const GET_ALL_INCLUSIONS = requestActions(
  MODULE_NAME,
  "GET_ALL_INCLUSIONS"
);
export const GET_INCLUSION = requestActions(MODULE_NAME, "GET_INCLUSION");
export const CREATE_INCLUSION = requestActions(MODULE_NAME, "CREATE_INCLUSION");
export const EDIT_INCLUSION = requestActions(MODULE_NAME, "EDIT_INCLUSION");
export const DELETE_INCLUSION = requestActions(MODULE_NAME, "DELETE_INCLUSION");
export const GET_ALL_PKG_CLASSES = requestActions(
  MODULE_NAME,
  "GET_ALL_PKG_CLASSES"
);
export const GET_PKG_CLASS = requestActions(MODULE_NAME, "GET_PKG_CLASS");
export const CREATE_PKG_CLASS = requestActions(MODULE_NAME, "CREATE_PKG_CLASS");
export const EDIT_PKG_CLASS = requestActions(MODULE_NAME, "EDIT_PKG_CLASS");
export const DELETE_PKG_CLASS = requestActions(MODULE_NAME, "DELETE_PKG_CLASS");
export const GET_ALL_PKG_TYPES = requestActions(
  MODULE_NAME,
  "GET_ALL_PKG_TYPES"
);
export const GET_PKG_TYPE = requestActions(MODULE_NAME, "GET_PKG_TYPE");
export const CREATE_PKG_TYPE = requestActions(MODULE_NAME, "CREATE_PKG_TYPE");
export const EDIT_PKG_TYPE = requestActions(MODULE_NAME, "EDIT_PKG_TYPE");
export const DELETE_PKG_TYPE = requestActions(MODULE_NAME, "DELETE_PKG_TYPE");
export const GET_ALL_PKG_SEASONS = requestActions(
  MODULE_NAME,
  "GET_ALL_PKG_SEASONS"
);
export const GET_PKG_SEASON = requestActions(MODULE_NAME, "GET_PKG_SEASON");
export const CREATE_PKG_SEASON = requestActions(
  MODULE_NAME,
  "CREATE_PKG_SEASON"
);
export const EDIT_PKG_SEASON = requestActions(MODULE_NAME, "EDIT_PKG_SEASON");
export const DELETE_PKG_SEASON = requestActions(
  MODULE_NAME,
  "DELETE_PKG_SEASON"
);
export const GET_ALL_PKG_ROOM_TYPES = requestActions(
  MODULE_NAME,
  "GET_ALL_PKG_ROOM_TYPES"
);
export const GET_PKG_ROOM_TYPE = requestActions(
  MODULE_NAME,
  "GET_PKG_ROOM_TYPE"
);
export const CREATE_PKG_ROOM_TYPE = requestActions(
  MODULE_NAME,
  "CREATE_PKG_ROOM_TYPE"
);
export const EDIT_PKG_ROOM_TYPE = requestActions(
  MODULE_NAME,
  "EDIT_PKG_ROOM_TYPE"
);
export const DELETE_PKG_ROOM_TYPE = requestActions(
  MODULE_NAME,
  "DELETE_PKG_ROOM_TYPE"
);
export const CREATE_PACKAGE = requestActions(MODULE_NAME, "CREATE_PACKAGE");
export const GET_ALL_PACKAGES = requestActions(MODULE_NAME, "GET_ALL_PACKAGES");
export const SEARCH_PACKAGES = requestActions(MODULE_NAME, "SEARCH_PACKAGES");
export const SET_SELECTED_PACKAGE = `${MODULE_NAME}/SET_SELECTED_PACKAGE`;
export const RESET = `${MODULE_NAME}/RESET`;

export const searchPackages = (params) =>
  requestApiCall("searchPackages", params, SEARCH_PACKAGES);

export const createPackage = (params) =>
  requestApiCall("createPackage", params, CREATE_PACKAGE);

export const getAllPackages = (params) =>
  requestApiCall("getAllPackages", params, GET_ALL_PACKAGES);

export const createPkgRoomType = (params) =>
  requestApiCall("createPkgRoomType", params, CREATE_PKG_ROOM_TYPE);

export const editPkgRoomType = (params) =>
  requestApiCall("editPkgRoomType", params, EDIT_PKG_ROOM_TYPE);

export const deletePkgRoomType = (params) =>
  requestApiCall("deletePkgRoomType", params, DELETE_PKG_ROOM_TYPE);

export const getPkgRoomType = ({ id }) =>
  requestApiCall("getPkgRoomType", { id }, GET_PKG_ROOM_TYPE);

export const getAllPkgRoomTypes = (params) =>
  requestApiCall("getAllPkgRoomTypes", params, GET_ALL_PKG_ROOM_TYPES);

export const createPkgSeason = (params) =>
  requestApiCall("createPkgSeason", params, CREATE_PKG_SEASON);

export const editPkgSeason = (params) =>
  requestApiCall("editPkgSeason", params, EDIT_PKG_SEASON);

export const deletePkgSeason = (params) =>
  requestApiCall("deletePkgSeason", params, DELETE_PKG_SEASON);

export const getPkgSeason = ({ id }) =>
  requestApiCall("getPkgSeason", { id }, GET_PKG_SEASON);

export const getAllPkgSeasons = (params) =>
  requestApiCall("getAllPkgSeasons", params, GET_ALL_PKG_SEASONS);

export const createPkgType = (params) =>
  requestApiCall("createPkgType", params, CREATE_PKG_TYPE);

export const editPkgType = (params) =>
  requestApiCall("editPkgType", params, EDIT_PKG_TYPE);

export const deletePkgType = (params) =>
  requestApiCall("deletePkgType", params, DELETE_PKG_TYPE);

export const getPkgType = ({ id }) =>
  requestApiCall("getPkgType", { id }, GET_PKG_TYPE);

export const getAllPkgTypes = (params) =>
  requestApiCall("getAllPkgTypes", params, GET_ALL_PKG_TYPES);

export const createCity = (params) =>
  requestApiCall("createCity", params, CREATE_CITY);

export const editCity = (params) =>
  requestApiCall("editCity", params, EDIT_CITY);

export const deleteCity = (params) =>
  requestApiCall("deleteCity", params, DELETE_CITY);

export const getCity = ({ id }) => requestApiCall("getCity", { id }, GET_CITY);

export const getAllCities = (params) =>
  requestApiCall("getAllCities", params, GET_ALL_CITIES);

export const createInclusion = (params) =>
  requestApiCall("createInclusion", params, CREATE_INCLUSION);
export const editInclusion = (params) =>
  requestApiCall("editInclusion", params, EDIT_INCLUSION);

export const deleteInclusion = (params) =>
  requestApiCall("deleteInclusion", params, DELETE_INCLUSION);

export const getInclusion = ({ id }) =>
  requestApiCall("getInclusion", { id }, GET_INCLUSION);

export const getAllInclusions = (params) =>
  requestApiCall("getAllInclusions", params, GET_ALL_INCLUSIONS);

export const createPkgClass = (params) =>
  requestApiCall("createPkgClass", params, CREATE_PKG_CLASS);

export const editPkgClass = (params) =>
  requestApiCall("editPkgClass", params, EDIT_PKG_CLASS);

export const deletePkgClass = (params) =>
  requestApiCall("deletePkgClass", params, DELETE_PKG_CLASS);

export const getPkgClass = ({ id }) =>
  requestApiCall("getPkgClass", { id }, GET_PKG_CLASS);

export const getAllPkgClasses = (params) =>
  requestApiCall("getAllPkgClasses", params, GET_ALL_PKG_CLASSES);

export const reset = () => ({ type: RESET });

export const setSelectedAirlineData = (params) => ({
  type: SET_SELECTED_PACKAGE,
  payload: params,
});

export const clearEditPkgType = () => ({ type: EDIT_PKG_TYPE.CLEAR });

export const clearDeletePkgType = () => ({ type: DELETE_PKG_TYPE.CLEAR });

export const clearCreatePkgType = () => ({ type: CREATE_PKG_TYPE.CLEAR });

export const clearEditInclusion = () => ({ type: EDIT_INCLUSION.CLEAR });

export const clearCreatePackage = () => ({ type: CREATE_PACKAGE.CLEAR });

export const clearDeleteInclusion = () => ({
  type: DELETE_INCLUSION.CLEAR,
});

export const clearCreateInclusion = () => ({
  type: CREATE_INCLUSION.CLEAR,
});

export const clearEditPkgClass = () => ({
  type: EDIT_PKG_CLASS.CLEAR,
});

export const clearSearchPackages = () => ({
  type: SEARCH_PACKAGES.CLEAR,
});

export const clearDeletePkgClass = () => ({
  type: DELETE_PKG_CLASS.CLEAR,
});

export const clearCreatePkgClass = () => ({
  type: CREATE_PKG_CLASS.CLEAR,
});

export const clearEditPkgSeason = () => ({
  type: EDIT_PKG_SEASON.CLEAR,
});

export const clearDeletePkgSeason = () => ({
  type: DELETE_PKG_SEASON.CLEAR,
});

export const clearCreatePkgSeason = () => ({
  type: CREATE_PKG_SEASON.CLEAR,
});

export const clearEditCity = () => ({
  type: EDIT_CITY.CLEAR,
});

export const clearDeleteCity = () => ({
  type: DELETE_CITY.CLEAR,
});

export const clearCreateCity = () => ({
  type: CREATE_CITY.CLEAR,
});

export const clearEditPkgRoomType = () => ({
  type: EDIT_PKG_ROOM_TYPE.CLEAR,
});

export const clearDeletePkgRoomType = () => ({
  type: DELETE_PKG_ROOM_TYPE.CLEAR,
});

export const clearCreatePkgRoomType = () => ({
  type: CREATE_PKG_ROOM_TYPE.CLEAR,
});
