import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/company";

export default function useAgenciesOptions() {
  const dispatch = useDispatch();
  const { list } = useSelector(({ company }) => company.allCompanies);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllCompanies());
    }
  }, [dispatch, list]);
  const agencyOptions = useMemo(
    () =>
      list?.map(({ name, id, email, isActive }) => ({
        value: id,
        label: `${name || email} ${isActive ? "" : "(Inactive)"}`,
      })) ?? [],
    [list]
  );
  return { agencyOptions };
}
