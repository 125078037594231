import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Input } from "../../../common";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/banks";
import { actions as filesActions } from "../../../../../../redux/state/files";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { useCallback } from "react";

const withdrawSchema = Yup.object().shape({
  amount: Yup.number()
    .positive()
    .required("Amount is required"),
  reason: Yup.string().required("Reason is required"),
});

export const WithdrawModal = ({ show, onHide, data }) => {
  const dispatch = useDispatch();
  const {
    success: withdrawSuccess,
    loading: withdrawLoading,
    failure: withdrawFailure,
  } = useSelector(({ banks }) => banks.withdraw);
  const { userId } = useSelector((state) => state.auth);
  const {
    url,
    loading: uploadLoading,
    success: uploadSuccess,
    failure: uploadFailure,
  } = useSelector(({ files }) => files.upload);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const { id } = data || {};
  const fields = useFormik({
    initialValues: {
      amount: "",
      reason: "",
      proof: "",
    },
    validationSchema: withdrawSchema,
    onSubmit: ({ proof, amount, reason }) => {
      if (!proof)
        return dispatch(
          actions.withdraw({
            bankAccountId: id,
            amount,
            reason,
            createdBy: userId,
            paymentproofUrl: url,
          })
        );

      const formData = new FormData();
      formData.append("file", proof, proof.name);
      dispatch(filesActions.uploadFile({ userId, formData }));
    },
  });
  const {
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
  } = fields;
  const handleHide = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (uploadSuccess) {
      const { amount, reason } = values;
      dispatch(
        actions.withdraw({
          bankAccountId: id,
          amount,
          reason,
          createdBy: userId,
          paymentproofUrl: url,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);
  useEffect(() => {
    if (withdrawSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Withdraw Successful!",
        })
      );
      dispatch(actions.getAllBankAccounts());
      dispatch(actions.clearWithdraw());
      handleHide();
    }
  }, [withdrawSuccess, dispatch, handleHide]);
  useEffect(() => {
    if (withdrawFailure || uploadFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearWithdraw());
    }
  }, [withdrawFailure, dispatch, uploadFailure]);
  return (
    <Modal
      size="md"
      onHide={handleHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Withdraw Money</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("amount")}
                showErrors={touched["amount"]}
                errorMessage={errors["amount"]}
                variant="secondary"
                label="Amount"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("reason")}
                showErrors={touched["reason"]}
                errorMessage={errors["reason"]}
                variant="secondary"
                label="Reason"
              />
            </Grid>
            {matchesMd && (
              <Grid item xs={12}>
                <div className="d-flex flex-column my-1 align-items-start">
                  <span className="min-w-150px mb-2">Upload Proof</span>
                  <label className="btn btn-primary w-350px ">
                    + Upload Proof
                    <input
                      onChange={(e) =>
                        setFieldValue("proof", e.target.files[0])
                      }
                      type="file"
                      accept="image/* application/pdf"
                      hidden
                    />
                  </label>
                  <span className="ml-5 text-muted">
                    (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
                  </span>
                </div>
              </Grid>
            )}
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={withdrawLoading || uploadLoading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
