import { Paper, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/supplier";
import { Seperator } from "../../../common";
import { SupplierRefundSearchCard } from "./SupplierRefundSearchCard";
import { SupplierRefundTable } from "./SupplierRefundTable";

export const SupplierRefund = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { paginationInfo } = useSelector(
    ({ supplier }) => supplier.supplierRefundTopUps
  );
  const { totalPages } = paginationInfo ?? {};
  const { fromDate, toDate } = dateRange;
  useEffect(() => {
    dispatch(
      actions.filterSupplierRefundTopUps({
        filterParam: [...appliedFilters],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterSupplierRefundTopUps({
        filterParam: [...filters],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.filterSupplierRefundTopUps({}));
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <SupplierRefundSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h6" className="font-weight-bold mb-2">
            Supplier Refund
          </Typography>
          {fromDate && toDate && (
            <Typography>
              Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          <Seperator />
          <SupplierRefundTable />
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
