export const adminPermissionsIds = {
  dashboard: { id: 1 },
  fareSearch: {
    flightSearch: { id: 2 },
    pnrSearch: { id: 3 },
    umrah: { id: 3 },
  },
  clients: {
    agencies: {
      id: 4,
      subPermissions: {
        createAgency: { id: 76, label: "Create agency" },
        viewAgency: { id: 5, label: "View/Edit agency" },
        // editAgency: { id: 6, label: "Edit agency" },
        creditSettings: { id: 7, label: "Credit Settings" },
        allocateCredit: { id: 8, label: "Allocate Credit" },
        activeAgency: { id: 9, label: "Activate/Deactivate agency" },
      },
    },
    userManagement: {
      id: 10,
      subPermissions: {
        verifyEmail: { id: 11, label: "Verify Email" },
        activeUser: { id: 12, label: "Activate/Deactivate user" },
      },
    },
  },
  packages: {
    inventory: { id: 0 },
    cities: { id: 0 },
    packageClasses: { id: 0 },
    packageSeasons: { id: 0 },
    packageTypes: { id: 0 },
    packageRoomTypes: { id: 0 },
    packageInclusion: { id: 0 },
  },
  bookingManagement: {
    ticketTracking: {
      id: 13,
      subPermissions: {
        view: { id: 81, label: "View Details" },
        actions: { id: 82, label: "Make Actions" },
      },
    },
    refund: {
      id: 14,
      subPermissions: {
        view: { id: 84, label: "View Details" },
        actions: { id: 83, label: "Make Actions" },
      },
    },
    void: {
      id: 15,
      subPermissions: {
        view: { id: 85, label: "View Details" },
        actions: { id: 86, label: "Make Actions" },
      },
    },
    reissue: {
      id: 16,
      subPermissions: {
        view: { id: 87, label: "View Details" },
        actions: { id: 88, label: "Make Actions" },
      },
    },
    packages: { id: 0 },
    searchOrder: {
      id: 17,
      // subPermissions: {
      //   view: { id: 11, label: "View Details" },
      //   actions: { id: 12, label: "Make Actions" },
      // },
    },
    queue: {
      id: 18,
      subPermissions: {
        update: { id: 89, label: "Update" },
      },
    },
  },
  payment: {
    topUps: {
      id: 19,
      subPermissions: {
        approveOrReject: { id: 20, label: "Approve Or Reject" },
        addTopup: { id: 103, label: "Add Top up" },
      },
    },
    credit: {
      id: 21,
      subPermissions: {
        approveOrReject: { id: 22, label: "Approve Or Reject" },
      },
    },
    banks: {
      id: 61,
      subPermissions: {
        add: { id: 67, label: "Add Bank" },
        edit: { id: 68, label: "Edit Bank" },
        delete: { id: 69, label: "Delete Bank" },
      },
    },
    bankAccounts: {
      id: 62,
      subPermissions: {
        add: { id: 70, label: "Add Bank Account" },
        delete: { id: 71, label: "Delete Bank Account" },
        edit: { id: 72, label: "Edit Bank Account" },
        deposit: { id: 73, label: "Deposit" },
        withdraw: { id: 74, label: "Withdraw" },
        viewHistory: { id: 79, label: "View History" },
      },
    },
    paymentMethods: {
      id: 63,
      subPermissions: {
        add: { id: 64, label: "Add Bank" },
        edit: { id: 65, label: "Edit Bank" },
        delete: { id: 66, label: "Delete Bank" },
      },
    },
    paymentMethodBalances: {
      id: 104,
      // subPermissions: {
      //   add: { id: 105, label: "Deposit" },
      //   edit: { id: 106, label: "Withdraw" },
      // },
    },
    promoCodes: {
      id: 95,
      subPermissions: {
        add: { id: 96, label: "Add Promo Code" },
        edit: { id: 97, label: "Edit Promo Code" },
        delete: { id: 98, label: "Delete Promo Code" },
      },
    },
  },
  systemManagement: {
    suppliers: {
      id: 23,
      subPermissions: {
        addSupplier: { id: 24, label: "Add Supplier" },
        editSupplier: { id: 25, label: "Edit Supplier" },
        addBalance: { id: 26, label: "Add Balance" },
        addApiKey: { id: 27, label: "Add/Edit Api key" },
        supplierHistory: { id: 80, label: "View Supplier History" },
      },
    },
    notifications: { id: 28 },
    templates: {
      id: 29,
      subPermissions: {
        addTemplate: { id: 30, label: "Add Template" },
        editTemplate: { id: 31, label: "Edit Template" },
        deleteTemplate: { id: 32, label: "Delete Template" },
      },
    },
    companyProfile: { id: 33 },
    flymeStaff: {
      id: 34,
      subPermissions: {
        addUser: { id: 35, label: "Add Staff Member" },
        viewUser: { id: 36, label: "View/Edit Staff Member" },
        // editUserPermissions: { id: 37, label: "Edit User Permissions" },
      },
    },
    flymeStaffRoles: {
      id: 90,
      subPermissions: {
        addRole: { id: 91, label: "Add Role" },
        viewRole: { id: 92, label: "View Details" },
        editRole: { id: 93, label: "Edit" },
        deleteRole: { id: 94, label: "Delete" },
      },
    },
    markup: {
      id: 38,
      subPermissions: {
        setMarkup: { id: 39, label: "Set Markup" },
        viewAllMarkups: { id: 40, label: "View All Markups" },
        viewFlymeMarkup: { id: 41, label: "View Flyme Markups" },
        editMarkup: { id: 77, label: "Edit Markups" },
        deleteMarkup: { id: 78, label: "Delete Markups" },
      },
    },
    serviceFees: {
      id: 42,
      subPermissions: {
        add: { id: 43, label: "Add Service Fee" },
        edit: { id: 44, label: "Edit Service Fee" },
      },
    },
  },
  accounting: {
    currencies: { id: 45 },
    balanceSummary: { id: 46 },
    payableDue: { id: 47 },
    receivableDue: { id: 75 },
    expenses: { id: 48 },
    invoicing: { id: 49 },
    supplierRefund: { id: 50 },
    salesDashboard: { id: 51 },
    operationDashboard: { id: 52 },
    accountTypes: { id: 53 },
    chartOfAccounts: { id: 54 },
  },
  reports: {
    receivable: { id: 55 },
    sales: { id: 56 },
    margin: { id: 57 },
    fee: { id: 58 },
    cancelled: { id: 0 },
    bankAccount: { id: 59 },
    topup: { id: 60 },
  },
};

// max permission id: 94
