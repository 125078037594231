import { Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { CompanySubUsers } from "../../../../account/accountSubUser/SubUsersList";

export const ClientSubusers = () => {
  const { id } = useParams();
  return (
    <Paper>
      <CompanySubUsers companyId={id} />
    </Paper>
  );
};
