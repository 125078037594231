import Axios from "axios";
import { client } from "./client";

export const createUser = ({ companyInUserId, ...rest }) =>
  client.post(`api/public/company/${companyInUserId}/user`, {
    companyInUserId,
    ...rest,
  });

export const deleteUser = ({ id }) => client.delete(`/api/user/${id}`);

export const getAllUsers = () => client.get("/api/user");

export const filterUsers = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post("/api/user/filters", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber,
    pageSize,
  });

export const getUser = ({ id }) => client.get(`/api/user/${id}`);

export const editUser = ({ id, ...params }) =>
  client.put(`/api/user/${id}`, {
    id,
    ...params,
  });

export const getUserSessions = ({ id }) =>
  client.get(`/api/user/${id}/userSessions`);

export const changePassword = ({ id, oldPassword, newPassword }) =>
  client.post(`/api/user/${id}/passwordChange`, { oldPassword, newPassword });

export const getUserPermissions = ({ id }) =>
  client.get(`/api/user/${id}/permissions`);

export const editUserPermissions = ({ id, permissionsList }) =>
  client.put(`/api/user/${id}/updatepermissions`, permissionsList);

export const getRolePermissions = ({ id }) =>
  client.get(`/api/role/${id}/permissions`);

export const createRole = (params) => client.post(`/api/role`, params);

export const editRole = ({ id, ...params }) =>
  client.put(`/api/role/${id}`, { id, ...params });

export const deleteRole = ({ id }) => client.delete(`/api/role/${id}`);

export const getAllRoles = () => client.get(`/api/role`);

export const getRole = ({ id }) => client.get(`/api/role/${id}`);

export const getUsersByRole = ({ id }) =>
  client.get(`/api/role/usersByRole/${id}`);

export const editRolePermissions = ({ id, permissionsList }) =>
  client.put(`/api/role/${id}/updatepermissions`, permissionsList);

export const verifyUserEmail = ({ id }) =>
  Axios.get(
    `${process.env.REACT_APP_API_URL}/api/public/user/${id}/emailverify`
  );

export const blockUser = ({ userId, companyId }) =>
  client.put(`/api/company/${companyId}/user/${userId}/block`);

export const unblockUser = ({ userId, companyId }) =>
  client.put(`/api/company/${companyId}/user/${userId}/unblock`);

export const getAllPromoCodes = () => client.get("/api/PromoCode");

export const editPromoCode = ({ id, ...params }) =>
  client.put(`/api/PromoCode/${id}`, { id, ...params });

export const createPromoCode = (params) =>
  client.post(`/api/PromoCode`, params);

export const deletePromoCode = ({ id }) =>
  client.delete(`/api/PromoCode/${id}`);

export const getPromoCode = ({ id }) => client.get(`/api/PromoCode/${id}`);

export const getPromoCodeByCode = ({ code }) =>
  client.get(`/api/PromoCode/ByCode/${code}`);

export const getSubscriptionPaymentsByCompany = ({ companyId }) =>
  client.get(`/api/SubscriptionPayment/ByCompany/${companyId}`);
