import { Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";

import useAirportName from "../../../../../../../app/helpers/hooks/useAirportName";
import { LoadingSpinner } from "../../../../common";

export const AgencyOperationTable = ({ list, loading, goToRouteDetails }) => {
  const { getAirportName, loading: airportsLoading } = useAirportName();
  const tableContent = list?.map((item, index) => (
    <TableRow
      getAirportName={getAirportName}
      key={`row_${index}`}
      data={item}
      goToRouteDetails={goToRouteDetails}
    />
  ));
  return (
    <div className="table-responsive">
      {loading || airportsLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-100">
              <th>Route</th>
              <th className="text-center">Search</th>
              <th className="text-center">Booked</th>
              <th className="text-center">Look to Book ratio</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getAirportName, goToRouteDetails }) => {
  const { id: agencyId } = useParams();
  const {
    ArrivalApt: toCode,
    DepartApt: fromCode,
    TotalSearched,
    TotalBooked,
  } = data;
  const fromAirport = getAirportName(fromCode);
  const toAirport = getAirportName(toCode);
  return (
    <tr>
      <td>
        <button
          className="btn font-weight-bold text-dark text-hover-primary pl-0 "
          onClick={() =>
            goToRouteDetails({
              agencyId,
              fromCode,
              toCode,
            })
          }
        >
          <span>
            {fromAirport} ({fromCode})
          </span>
          {" - "}
          <span>
            {toAirport} ({toCode})
          </span>
        </button>
      </td>
      <td className="text-center font-weight-bold text-center ">
        <span>{TotalSearched}</span>
      </td>
      <td className="text-center font-weight-bold text-center ">
        <span>{TotalBooked}</span>
      </td>
      <td className="text-center font-weight-bold text-center ">
        <span>
          {TotalSearched}:{TotalBooked}
        </span>
      </td>
    </tr>
  );
};
