import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Input } from "../../../common";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/banks";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { useCallback } from "react";

const depositSchema = Yup.object().shape({
  amount: Yup.number()
    .positive()
    .required("Amount is required"),
  // reason: Yup.string().required("Reason is required"),
});

export const DepositModal = ({ show, onHide, data }) => {
  const dispatch = useDispatch();
  const {
    success: depositSuccess,
    loading: depositLoading,
    failure: depositFailure,
  } = useSelector(({ banks }) => banks.deposit);
  const { userId } = useSelector((state) => state.auth);

  const { id } = data || {};
  const fields = useFormik({
    initialValues: {
      amount: "",
      reason: "",
    },
    validationSchema: depositSchema,
    onSubmit: ({ amount, reason }) => {
      dispatch(
        actions.deposit({
          bankAccountId: id,
          amount,
          createdBy: userId,
        })
      );
    },
  });
  const { getFieldProps, touched, errors, handleSubmit, resetForm } = fields;
  const handleHide = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (depositSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Deposit Successful!",
        })
      );
      dispatch(actions.getAllBankAccounts());
      handleHide();
    }
  }, [depositSuccess, dispatch, handleHide]);
  useEffect(() => {
    if (depositFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearDeposit());
    }
  }, [depositFailure, dispatch]);
  return (
    <Modal
      size="sm"
      onHide={handleHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Deposit Money</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                {...getFieldProps("amount")}
                showErrors={touched["amount"]}
                errorMessage={errors["amount"]}
                variant="secondary"
                label="Amount"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={depositLoading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
