import { accountingClient } from "./client";

export const getAllBanks = () => accountingClient.get("/accounting/v1/banks");

export const getBank = ({ id }) =>
  accountingClient.get(`/accounting/v1/banks/${id}`);

export const deleteBank = ({ id }) =>
  accountingClient.delete(`/accounting/v1/banks/${id}`);

export const createBank = (params) =>
  accountingClient.post("/accounting/v1/banks", { ...params });

export const editBank = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/banks/${id}`, { id, ...rest });

export const getAllBankAccounts = () =>
  accountingClient.get("/accounting/v1/bank_accounts");

export const getBankAccount = ({ id }) =>
  accountingClient.get(`/accounting/v1/bank_accounts/${id}`);

export const deleteBankAccount = ({ id }) =>
  accountingClient.delete(`/accounting/v1/bank_accounts/${id}`);

export const deposit = ({ bankAccountId, amount, createdBy }) =>
  accountingClient.post("/accounting/v1/add_bank_balance", {
    bankAccountId,
    amount,
    createdBy,
  });

export const withdraw = (params) =>
  accountingClient.post("/accounting/v1/withdraw", params);

export const createBankAccount = (params) =>
  accountingClient.post("/accounting/v1/bank_accounts", { ...params });

export const editBankAccount = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/bank_accounts/${id}`, { id, ...rest });

export const getBankAccountHistory = ({ id }) =>
  accountingClient.get(`/accounting/v1/bank_balance_history/${id}`);

export const getAllPaymentMethods = () =>
  accountingClient.get("/accounting/v1/paymentmethod");

export const getAllBankAccountsHistory = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  accountingClient.post(
    "/accounting/v1/invoice/filter_all_bank_balance_history",
    {
      sortingParams: sortingParams || [
        {
          sortOrder: 2,
          columnName: "date",
        },
      ],
      filterParam: filterParam || [{}],
      pageNumber,
      pageSize,
    }
  );

export const getPaymentMethod = ({ id }) =>
  accountingClient.get(`/accounting/v1/paymentmethod/${id}`);

export const deletePaymentMethod = ({ id }) =>
  accountingClient.delete(`/accounting/v1/paymentmethod/${id}`);

export const createPaymentMethod = (params) =>
  accountingClient.post("/accounting/v1/paymentmethod", { ...params });

export const editPaymentMethod = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/paymentmethod/${id}`, { id, ...rest });
