import { Paper, Typography } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { Button } from "../../common";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_PAYMENT } from "../../../../../app/routesMap";

export const SubscriptionExpired = () => {
  const history = useHistory();
  return (
    <Paper className="h-100 p-8 d-flex flex-column justify-content-center align-items-center">
      <span className="svg-icon svg-icon-full svg-icon-primary mb-8">
        <SVG
          src="/media/svg/icons/Electric/Shutdown.svg"
          // className="svg-icon-full"
          width={200}
          height={200}
        />
      </span>

      <Typography
        variant="h5"
        className="max-w-500px text-center font-weight-bold mt-8 mb-1"
      >
        No Active Subscription!
      </Typography>
      <Typography
        variant="h6"
        className="max-w-500px text-center font-weight-normal"
      >
        Please renew your subscription to continue using our services.
      </Typography>
      <Typography
        variant="body1"
        className="max-w-500px text-center font-weight-normal mt-1 mb-8"
      >
        Please refer to subscription payment.
      </Typography>

      <Button type="primary" onClick={() => history.push(SUBSCRIPTION_PAYMENT)}>
        Renew
      </Button>
    </Paper>
  );
};
