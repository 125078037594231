import produce from "immer";
import { isNil } from "lodash-es";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./userActions";

const initialState = {
  create: {
    userId: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  delete: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificUser: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  userData: undefined,
  allUsers: {
    loading: false,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  getUser: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editSpecificUser: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  currentUserPermissions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  userSessions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  userPermissions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPermission: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  changePassword: {
    status: 1,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  verifyEmail: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  blockUnblock: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  usersByRole: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  rolePermissions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allRoles: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  role: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createRole: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editRole: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deleteRole: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPromoCodes: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  promoCode: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPromoCode: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPromoCode: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePromoCode: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  subscriptionPaymentsByCompany: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

// CHANGE PASSWORD STATUS
// 1 success
// 2 wrong password

export const userReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY.REQUESTED:
        state.subscriptionPaymentsByCompany.loading = true;
        break;
      case actions.GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY.SUCCEEDED:
        state.subscriptionPaymentsByCompany.loading = false;
        state.subscriptionPaymentsByCompany.success = true;
        state.subscriptionPaymentsByCompany.list = payload.data;
        break;
      case actions.GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY.FAILED:
        state.subscriptionPaymentsByCompany.loading = false;
        state.subscriptionPaymentsByCompany.failure = payload.error;
        break;
      case actions.GET_ALL_PROMO_CODES.REQUESTED:
        state.allPromoCodes.loading = true;
        break;
      case actions.GET_ALL_PROMO_CODES.SUCCEEDED:
        state.allPromoCodes.loading = false;
        state.allPromoCodes.success = true;
        state.allPromoCodes.list = payload.data;
        break;
      case actions.GET_ALL_PROMO_CODES.FAILED:
        state.allPromoCodes.loading = false;
        state.allPromoCodes.failure = payload.error;
        break;
      case actions.GET_ALL_PROMO_CODES.CLEAR:
        state.allPromoCodes = initialState.allPromoCodes;
        break;
      case actions.GET_PROMO_CODE.REQUESTED:
      case actions.GET_PROMO_CODE_BY_CODE.REQUESTED:
        state.promoCode.loading = true;
        break;
      case actions.GET_PROMO_CODE.SUCCEEDED:
      case actions.GET_PROMO_CODE_BY_CODE.SUCCEEDED:
        state.promoCode.loading = false;
        state.promoCode.success = true;
        state.promoCode.data = payload.data;
        break;
      case actions.GET_PROMO_CODE.FAILED:
      case actions.GET_PROMO_CODE_BY_CODE.FAILED:
        state.promoCode.loading = false;
        state.promoCode.failure = payload.error;
        break;
      case actions.GET_PROMO_CODE.CLEAR:
      case actions.GET_PROMO_CODE_BY_CODE.CLEAR:
        state.promoCode = initialState.promoCode;
        break;
      case actions.CREATE_PROMO_CODE.REQUESTED:
        state.createPromoCode.loading = true;
        break;
      case actions.CREATE_PROMO_CODE.SUCCEEDED:
        state.createPromoCode.loading = false;
        state.createPromoCode.success = true;
        break;
      case actions.CREATE_PROMO_CODE.FAILED:
        state.createPromoCode.loading = false;
        state.createPromoCode.failure = payload.error;
        break;
      case actions.CREATE_PROMO_CODE.CLEAR:
        state.createPromoCode = initialState.createPromoCode;
        break;
      case actions.EDIT_PROMO_CODE.REQUESTED:
        state.editPromoCode.loading = true;
        break;
      case actions.EDIT_PROMO_CODE.SUCCEEDED:
        state.editPromoCode.loading = false;
        state.editPromoCode.success = true;
        break;
      case actions.EDIT_PROMO_CODE.FAILED:
        state.editPromoCode.loading = false;
        state.editPromoCode.failure = payload.error;
        break;
      case actions.EDIT_PROMO_CODE.CLEAR:
        state.editPromoCode = initialState.editPromoCode;
        break;
      case actions.DELETE_PROMO_CODE.REQUESTED:
        state.deletePromoCode.loading = true;
        break;
      case actions.DELETE_PROMO_CODE.SUCCEEDED:
        state.deletePromoCode.loading = false;
        state.deletePromoCode.success = true;
        break;
      case actions.DELETE_PROMO_CODE.FAILED:
        state.deletePromoCode.loading = false;
        state.deletePromoCode.failure = payload.error;
        break;
      case actions.DELETE_PROMO_CODE.CLEAR:
        state.deletePromoCode = initialState.deletePromoCode;
        break;
      case actions.BLOCK.REQUESTED:
      case actions.UNBLOCK.REQUESTED:
        state.blockUnblock.loading = true;
        break;
      case actions.BLOCK.SUCCEEDED:
      case actions.UNBLOCK.SUCCEEDED:
        state.blockUnblock.loading = false;
        state.blockUnblock.success = true;
        const index = state.allUsers.list.findIndex(
          ({ id }) => id === payload.args.userId
        );
        state.allUsers.list[index] = payload.data;
        break;
      case actions.BLOCK.FAILED:
      case actions.UNBLOCK.FAILED:
        state.blockUnblock.loading = false;
        state.blockUnblock.failure = payload.error;
        break;
      case actions.BLOCK.CLEAR:
      case actions.UNBLOCK.CLEAR:
        state.blockUnblock = initialState.blockUnblock;
        break;
      case actions.CHANGE_PASSWORD.REQUESTED:
        state.changePassword.loading = undefined;
        state.changePassword.success = undefined;
        state.changePassword.userId = undefined;
        state.changePassword.loading = true;
        break;
      case actions.CHANGE_PASSWORD.SUCCEEDED:
        state.changePassword.loading = false;
        if (!isNil(payload.data?.WrongCredentials)) {
          state.changePassword.status = 2; // password is incorrect
          state.changePassword.failure = true;
        } else {
          state.changePassword.success = true;
          state.changePassword.status = 1; // email is not verified
        }
        break;
      case actions.CHANGE_PASSWORD.FAILED:
        state.changePassword.loading = false;
        state.changePassword.failure = payload.error;
        break;
      case actions.CHANGE_PASSWORD.CLEAR:
        state.changePassword = initialState.changePassword;
        break;

      case actions.VERIFY_USER_EMAIL.REQUESTED:
        state.verifyEmail.loading = undefined;
        state.verifyEmail.success = undefined;
        state.verifyEmail.userId = undefined;
        state.verifyEmail.loading = true;
        break;
      case actions.VERIFY_USER_EMAIL.SUCCEEDED:
        state.verifyEmail.loading = false;
        state.verifyEmail.success = true;
        const userIndex = state.allUsers.list.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.allUsers.list[userIndex] = {
          ...state.allUsers.list[userIndex],
          isEmailVerified: true,
        };

        break;
      case actions.VERIFY_USER_EMAIL.FAILED:
        state.verifyEmail.loading = false;
        state.verifyEmail.failure = payload.error;
        break;
      case actions.VERIFY_USER_EMAIL.CLEAR:
        state.verifyEmail = initialState.verifyEmail;
        break;
      case actions.CREATE.REQUESTED:
        state.create.loading = undefined;
        state.create.success = undefined;
        state.create.userId = undefined;
        state.create.loading = true;
        break;
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        state.create.userId = payload.data.id;
        break;
      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.CREATE.CLEAR:
        state.create.loading = undefined;
        state.create.success = undefined;
        state.create.failure = undefined;
        break;
      case actions.DELETE_USER.REQUESTED:
        state.delete.loading = undefined;
        state.delete.success = undefined;
        state.delete.loading = true;
        break;
      case actions.DELETE_USER.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        break;
      case actions.DELETE_USER.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE_USER.CLEAR:
        state.delete.loading = undefined;
        state.delete.success = undefined;
        state.delete.failure = undefined;
        break;
      case actions.GET_SPECIFIC_USER.REQUESTED:
        state.specificUser.loading = true;
        break;
      case actions.GET_SPECIFIC_USER.SUCCEEDED:
        state.specificUser.data = payload.data;
        state.specificUser.loading = false;
        state.specificUser.success = true;
        break;
      case actions.GET_SPECIFIC_USER.FAILED:
        state.specificUser.loading = false;
        state.specificUser.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_USER.CLEAR:
        state.specificUser = initialState.specificUser;
        break;
      case actions.FILTER_USERS.REQUESTED:
        state.allUsers.loading = true;
        break;
      case actions.FILTER_USERS.SUCCEEDED:
        state.allUsers.loading = false;
        state.allUsers.success = true;
        const { data, ...rest } = payload.data;
        state.allUsers.list = data;
        state.allUsers.paginationInfo = rest;
        break;
      case actions.FILTER_USERS.FAILED:
        state.allUsers.loading = false;
        state.allUsers.failure = payload.error;
        break;
      case actions.GET_ALL_USER.REQUESTED:
        state.allUsers.loading = true;
        break;
      case actions.GET_ALL_USER.SUCCEEDED:
        state.allUsers.list = payload.data?.reverse();
        state.allUsers.loading = false;
        state.allUsers.success = true;
        break;
      case actions.GET_ALL_USER.FAILED:
        state.allUsers.loading = false;
        state.allUsers.failure = payload.error;
        break;
      case actions.GET_USER.REQUESTED:
        state.getUser.loading = true;
        break;
      case actions.GET_USER.SUCCEEDED:
        state.userData = payload.data;
        state.getUser.loading = false;
        state.getUser.success = true;
        break;
      case actions.GET_USER.FAILED:
        state.getUser.loading = false;
        state.getUser.failure = payload.error;
        break;
      case actions.GET_ALL_ROLES.REQUESTED:
        state.allRoles.loading = true;
        break;
      case actions.GET_ALL_ROLES.SUCCEEDED:
        state.allRoles.list = payload.data?.reverse();
        state.allRoles.loading = false;
        state.allRoles.success = true;
        break;
      case actions.GET_ALL_ROLES.FAILED:
        state.allRoles.loading = false;
        state.allRoles.failure = payload.error;
        break;
      case actions.GET_ROLE.REQUESTED:
        state.role.loading = true;
        break;
      case actions.GET_ROLE.SUCCEEDED:
        state.userData = payload.data;
        state.role.loading = false;
        state.role.success = true;
        state.role.data = payload.data;
        break;
      case actions.GET_ROLE.FAILED:
        state.role.loading = false;
        state.role.failure = payload.error;
        break;
      case actions.CREATE_ROLE.REQUESTED:
        state.createRole.loading = true;
        break;
      case actions.CREATE_ROLE.SUCCEEDED:
        state.createRole.loading = false;
        state.createRole.success = true;
        state.specificUser.data = payload.data;
        break;
      case actions.CREATE_ROLE.FAILED:
        state.createRole.loading = false;
        state.createRole.failure = payload.error;
        break;
      case actions.CREATE_ROLE.CLEAR:
        state.createRole = initialState.createRole;
        break;
      case actions.DELETE_ROLE.REQUESTED:
        state.deleteRole.loading = true;
        break;
      case actions.DELETE_ROLE.SUCCEEDED:
        state.deleteRole.loading = false;
        state.deleteRole.success = true;
        break;
      case actions.DELETE_ROLE.FAILED:
        state.deleteRole.loading = false;
        state.deleteRole.failure = payload.error;
        break;
      case actions.DELETE_ROLE.CLEAR:
        state.deleteRole = initialState.deleteRole;
        break;
      case actions.EDIT_ROLE.REQUESTED:
        state.editRole.loading = true;
        break;
      case actions.EDIT_ROLE.SUCCEEDED:
        state.editRole.loading = false;
        state.editRole.success = true;
        break;
      case actions.EDIT_ROLE.FAILED:
        state.editRole.loading = false;
        state.editRole.failure = payload.error;
        break;
      case actions.EDIT_ROLE.CLEAR:
        state.editRole = initialState.editRole;
        break;
      case actions.EDIT_SPECIFIC_USER.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT_SPECIFIC_USER.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        state.specificUser.data = payload.data;
        break;
      case actions.EDIT_SPECIFIC_USER.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT_SPECIFIC_USER.CLEAR:
        state.editSpecificUser = initialState.editSpecificUser;
        break;
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.GET_ROLE_PERMISSION.REQUESTED:
        state.rolePermissions.loading = true;
        break;
      case actions.GET_ROLE_PERMISSION.SUCCEEDED:
        state.rolePermissions.loading = false;
        state.rolePermissions.success = true;
        state.rolePermissions.list = payload.data;
        break;
      case actions.GET_ROLE_PERMISSION.FAILED:
        state.rolePermissions.loading = false;
        state.rolePermissions.failure = payload.error;
        break;
      case actions.GET_ROLE_PERMISSION.CLEAR:
        state.rolePermissions = initialState.rolePermissions;
        break;
      case actions.GET_USER_PERMISSION.REQUESTED:
        state.userPermissions.loading = true;
        break;
      case actions.GET_USER_PERMISSION.SUCCEEDED:
        state.userPermissions.loading = false;
        state.userPermissions.success = true;
        state.userPermissions.list = payload.data;
        break;
      case actions.GET_USER_PERMISSION.FAILED:
        state.userPermissions.loading = false;
        state.userPermissions.failure = payload.error;
        break;
      case actions.GET_USER_PERMISSION.CLEAR:
        state.userPermissions = initialState.userPermissions;
        break;
      case actions.GET_USERS_BY_ROLE.REQUESTED:
        state.usersByRole.loading = true;
        break;
      case actions.GET_USERS_BY_ROLE.SUCCEEDED:
        state.usersByRole.loading = false;
        state.usersByRole.success = true;
        state.usersByRole.list = payload.data.reverse();
        break;
      case actions.GET_USERS_BY_ROLE.FAILED:
        state.usersByRole.loading = false;
        state.usersByRole.failure = payload.error;
        break;
      case actions.GET_USER_SESSIONS.REQUESTED:
        state.userSessions.loading = true;
        break;
      case actions.GET_USER_SESSIONS.SUCCEEDED:
        state.userSessions.loading = false;
        state.userSessions.success = true;
        state.userSessions.list = payload.data.reverse();
        break;
      case actions.GET_USER_SESSIONS.FAILED:
        state.userSessions.loading = false;
        state.userSessions.failure = payload.error;
        break;
      case actions.GET_USER_SESSIONS.CLEAR:
        state.userSessions = initialState.userSessions;
        break;
      case actions.GET_CURRENT_USER_PERMISSION.REQUESTED:
        state.currentUserPermissions.loading = true;
        break;
      case actions.GET_CURRENT_USER_PERMISSION.SUCCEEDED:
        state.currentUserPermissions.loading = false;
        state.currentUserPermissions.success = true;
        state.currentUserPermissions.list = payload?.data;
        break;
      case actions.GET_CURRENT_USER_PERMISSION.FAILED:
        state.currentUserPermissions.loading = false;
        state.currentUserPermissions.failure = payload.error;
        break;
      case actions.EDIT_USER_PERMISSION.REQUESTED:
      case actions.EDIT_ROLE_PERMISSION.REQUESTED:
        state.editPermission.loading = true;
        break;
      case actions.EDIT_USER_PERMISSION.SUCCEEDED:
      case actions.EDIT_ROLE_PERMISSION.SUCCEEDED:
        state.editPermission.loading = false;
        state.editPermission.success = true;
        break;
      case actions.EDIT_USER_PERMISSION.FAILED:
      case actions.EDIT_ROLE_PERMISSION.FAILED:
        state.editPermission.loading = false;
        state.editPermission.failure = payload.error;
        break;
      case actions.EDIT_USER_PERMISSION.CLEAR:
      case actions.EDIT_ROLE_PERMISSION.CLEAR:
        state.editPermission = initialState.editPermission;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
