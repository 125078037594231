import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { GoBack } from "../../../../common";
import { actions } from "../../../../../../../redux/state/accounting";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { RouteSearches } from "./RouteSearches";
import { RouteBookings } from "./RouteBookings";
import useQuery from "../../../../../../../app/helpers/hooks/useQuery";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

export const RouteDetails = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("booked");
  const { getCompanyName } = useCompanyName();
  const { fromCode, toCode, agencyId } = useParams();
  const query = useQuery();
  const fromDate = query.get("fromDate");
  const toDate = query.get("toDate");
  useEffect(() => {
    dispatch(actions.getRouteDetails({ agencyId, fromCode, toCode }));
    dispatch(
      actions.getRouteSearches({ agencyId, fromCode, toCode, fromDate, toDate })
    );
  }, [dispatch, agencyId, fromCode, toCode, fromDate, toDate]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-between align-items-center">
          <GoBack />
          <Typography className="text-primary" variant="h5">
            {getCompanyName(agencyId)} ({fromCode}-{toCode})
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
          >
            <Tab value="booked" label="Bookings" />
            {/* <Tab value="company" label="Company" /> */}
            <Tab value="searches" label="Searches" />
          </AntTabs>
          <div className="p-7">
            {selectedTab === "booked" && <RouteBookings />}
            {selectedTab === "searches" && <RouteSearches />}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
