import { useCallback } from "react";
import { adminPermissionsIds } from "../../../data/adminPermissionIds";
import { agencyPermissionIds } from "../../../data/agencyPermissionIds";
import { getObjectProperty } from "../../../_metronic/_helpers/object";

export const usePermissionIds = (attr) => {
  const { isAdminPermissions = true } = attr ?? {};
  const getPermissionObj = useCallback(
    (property) => {
      const permissionIdsObj = isAdminPermissions
        ? adminPermissionsIds
        : agencyPermissionIds;
      return getObjectProperty(property, permissionIdsObj);
    },
    [isAdminPermissions]
  );

  return {
    getPermissionObj,
  };
};
