import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/bookingMng";
import { Paper, Typography } from "@material-ui/core";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";

const loadCheckoutScript = (callback) => {
  const script = document.createElement("script");
  script.src = process.env.REACT_APP_MASTERCARD_URL;
  script.async = true;
  script["data-error"] = "errorCallback";
  script["data-cancel"] = "cancelCallback";
  script["data-complete"] = "completeCallback";
  script["data-timeout"] = "timeoutCallback";
  script.onload = callback; // Execute the callback once the script is loaded
  document.body.appendChild(script);
  window.errorCallback = function(error) {
    alert(JSON.stringify(error["error.explanation"]));
  };

  window.cancelCallback = function() {
    alert("Payment cancelled");
  };

  window.timeoutCallback = function() {
    alert("Payment timeout");
  };
};

export const MasterCardPayment = ({ amount, onSubmit }) => {
  const dispatch = useDispatch();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { loading, success, failure, token, orderId } = useSelector(
    ({ bookingMng }) => bookingMng.createMastercardIntent
  );

  // get sessionid from the server
  useEffect(() => {
    window.completeCallback = function(response, sessionVersion) {
      console.log("completeCallback", response, sessionVersion);
      console.log("Payment successful");
      onSubmit();
      // if (response.resultIndicator === "success") {
      // } else if (response.resultIndicator === "failure") {
      //   console.log("Payment failed");
      // }
    };
    loadCheckoutScript(() => setScriptLoaded(true));
    dispatch(actions.createMasterCardIntent({ amount }));
  }, [amount, dispatch]);

  console.log({ checkout: window.Checkout, scriptLoaded });

  useEffect(() => {
    if (success && scriptLoaded) {
      console.log({ checkout: window.Checkout });
      // Configure and show the MPGS Checkout page
      window.Checkout.configure({
        merchant: process.env.REACT_APP_MASTERCARD_MERCHANT_ID,
        session: {
          id: token,
        },
        order: {
          description: "Order Goods",
          id: orderId,
        },
        billing: {
          address: {
            street: "TALEX",
            city: "MOGADISHU",
            postcodeZip: "00100",
            stateProvince: "BENADIR",
            country: "SOM",
          },
        },
        interaction: {
          operation: "PURCHASE",
          merchant: {
            name: process.env.REACT_APP_MASTERCARD_MERCHANT_ID,
            address: {
              line1: "west gate",
              line2: "west gate",
            },
          },
        },
      });

      // Show the MPGS Checkout page
      // window.Checkout.showPaymentPage();
      // embed the checkout page in the iframe
      window.Checkout.showEmbeddedPage("#payment-form");
    }
  }, [orderId, scriptLoaded, success, token]);

  if (loading || !scriptLoaded) {
    return (
      <Paper className="h-100 p-7 overflow-hidden d-flex flex-column justify-content-center align-items-center">
        <LoadingSpinner />
        <Typography variant="h3" className="mt-5">
          Loading Payment Screen
        </Typography>
      </Paper>
    );
  }

  if (failure) {
    return <div>Something went wrong! Please contact Flyme247 team.</div>;
  }

  return (
    <Paper className="h-100 p-7 overflow-hidden d-flex flex-column justify-content-center align-items-center">
      <div id="payment-form" className="h-100 w-100"></div>
    </Paper>
  );
};
