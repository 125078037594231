import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../../../../../redux/state/accountBalance";
import { actions as companyActions } from "../../../../../../redux/state/company";
import { actions as companyCreditActions } from "../../../../../../redux/state/companyCredit";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const ClientInfoBar = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    dispatch(actions.getAccountBalance({ companyId: id }));
    dispatch(companyActions.getSpecificCompany({ id }));
    dispatch(
      companyCreditActions.getCreditDetailsByCompanyId({
        companyId: id,
      })
    );
    return () => {
      dispatch(actions.clearAccountBalance());
      dispatch(companyActions.clearSpecificCompany());
    };
  }, [dispatch, id]);
  const { availableBalance } =
    useSelector(({ accountBalance }) => accountBalance.balance.data) || {};
  const { availableBalance: availableCredit } =
    useSelector(({ companyCredit }) => companyCredit.creditDetails.data) || {};
  const { data: companyData } = useSelector(
    ({ company }) => company.specificCompanyData
  );
  // const{name}=companyData
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-between align-items-center">
          <div className="d-flex flex-grow-1 align-items-center">
            <img
              className="rounded-circle mr-5 max-w-100px"
              src={companyData?.logo || ""}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = toAbsoluteUrl(
                  "/media/svg/icons/General/company-logo-default.svg"
                );
              }}
              alt=""
            />
            <div className="flex-grow-1">
              <Typography variant="h4">{companyData?.name}</Typography>
              <Typography variant="h6" className="text-muted mt-3">
                ID: {companyData?.id}
              </Typography>
              {/* <Typography variant="body1" className="text-primary my-1">
              {email}
            </Typography>
            <Typography variant="body1" className="text-muted">
            {companyName}
          </Typography> */}
            </div>
          </div>
          {/* <img
            className="rounded-circle"
            src={toAbsoluteUrl("/media/users/100_6.jpg")}
            alt=""
          /> */}
          {matchesSm && (
            <div className="d-flex">
              <div className="border bg-white p-7 mr-5 text-center h-100 d-flex flex-column justify-content-center">
                <Typography variant="h4" className="mb-3">
                  <span className="text-primary">$</span>
                  {(+availableBalance || 0)?.toFixed(2)}
                </Typography>
                <Typography variant="h6" className="mb-2">
                  Available Balance
                </Typography>
                {/* <Typography variant="body2">
            Payable Balance: ${availableCredit?.toFixed(2)}
          </Typography> */}
              </div>
              <div className="border bg-white p-7 text-center h-100 d-flex flex-column justify-content-center">
                <Typography variant="h4" className="mb-3">
                  <span className="text-primary">$</span>
                  {(+availableCredit || 0)?.toFixed(2)}
                </Typography>
                <Typography variant="h6" className="mb-2">
                  Available Credit
                </Typography>
                {/* <Typography variant="body2">
            Payable Balance: ${data?.availableCredit}
          </Typography> */}
              </div>
            </div>
          )}
        </Paper>
      </Grid>
      {!matchesSm && (
        <>
          <Grid item xs={6}>
            <Paper className="p-7 d-flex justify-content-between align-items-center h-100 w-100">
              <div className="bg-white text-center h-100 d-flex flex-column justify-content-center align-items-center w-100">
                <Typography variant="h4" className="mb-3 text-center">
                  <span className="text-primary">$</span>
                  {(+availableBalance || 0)?.toFixed(2)}
                </Typography>
                <Typography variant="h6" className="text-center">
                  Available Balance
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="p-7 d-flex justify-content-between align-items-center h-100 ">
              <div className="bg-white text-center h-100 d-flex flex-column justify-content-center align-items-center w-100">
                <Typography variant="h4" className="mb-3 text-center">
                  <span className="text-primary">$</span>
                  {(+availableCredit || 0)?.toFixed(2)}
                </Typography>
                <Typography variant="h6" className="text-center">
                  Available Credit
                </Typography>
              </div>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};
