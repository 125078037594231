import React from "react";
import { Grid } from "@material-ui/core";
import { Bookings } from "../../../../dashboard/Bookings";
import { PaymentRecords } from "./PaymentRecords";
import { BalanceInfo } from "./BalanceInfo";

export const ClientView = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PaymentRecords />
      </Grid>
      {/* <Grid item xs={4}>
        <BalanceInfo />
      </Grid> */}
      <Grid item xs={12}>
        <Bookings />
      </Grid>
    </Grid>
  );
};
