import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const ResultsFilterShimmers = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs className="">
        <Skeleton
          style={{ borderRadius: "20px" }}
          animation="pulse"
          variant="rect"
          width={120}
          height={40}
        />
      </Grid>
      <Grid item xs>
        <Skeleton
          style={{ borderRadius: "20px" }}
          animation="pulse"
          variant="rect"
          width={120}
          height={40}
        />
      </Grid>
      <Grid item xs>
        <Skeleton
          style={{ borderRadius: "20px" }}
          animation="pulse"
          variant="rect"
          width={120}
          height={40}
        />
      </Grid>
    </Grid>
  );
};
