import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ADMIN_PAYMENTS_PAYMENT_BALANCES,
  ADMIN_PAYMENTS_PAYMENT_BALANCE_LOG,
} from "../../routesMapAdmin";
import { PaymentBalances } from "../../../_metronic/layout/components/admin/payments/paymentBalances/PaymentBalances";
import { PaymentBalanceLog } from "../../../_metronic/layout/components/admin/payments/paymentBalances/paymentBalanceLog/PaymentBalanceLog";

export const PaymentBalancesPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <Route
        // permissionKey={generateNestedObjectKey([
        //   permissionKey,
        //   "subPermissions.viewHistory",
        // ])}
        path={ADMIN_PAYMENTS_PAYMENT_BALANCE_LOG}
        component={PaymentBalanceLog}
      />
      <Route
        path={ADMIN_PAYMENTS_PAYMENT_BALANCES}
        render={(props) => (
          <PaymentBalances permissionId={permissionId} {...props} />
        )}
      />
    </Switch>
  );
};
