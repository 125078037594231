import { accountingClient } from "./client";

export const getCreditAllowanceByCompanyId = ({ companyId }) =>
  accountingClient.get(`/accounting/v1/creditAllowanceByAgency/${companyId}`);

export const getCreditDetailsByCompanyId = ({ companyId }) =>
  accountingClient.get(`/accounting/v1/CreditDetailsByAgency/${companyId}`);

export const getAllCompanyCredit = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  accountingClient.post("/accounting/v1/AllAddCompanyCredit", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber,
    pageSize,
  });

export const getCompanyCredit = ({ companyId }) =>
  accountingClient.get(`/accounting/v1/GetCompanyCreditByAgency/${companyId}`);

export const createCreditAllowance = (params) =>
  accountingClient.post("/accounting/v1/creditAllowance", { ...params });

export const approveCompanyCredit = ({ id }) =>
  accountingClient.post(`/accounting/v1/ApproveCompanyCredit/${id}`);

export const rejectCompanyCredit = ({ id }) =>
  accountingClient.post(`/accounting/v1/RejectCompanyCredit/${id}`);

export const editCreditAllowance = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/creditAllowance/${id}`, { id, ...rest });

export const addCompanyCredit = (params) =>
  accountingClient.post("/accounting/v1/AddCompanyCredit", { ...params });
