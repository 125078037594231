import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDateTime } from "../../../../../_helpers/date";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/accountBalance";
import { actions as companyActions } from "../../../../../../redux/state/company";

import { BalanceSummarySearchCard } from "./BalanceSummarySearchCard";
import { paymentTypeText } from "../../client/clientDetails/clientBilling/BillingHistory";
import { Pagination } from "@material-ui/lab";
import useUserName from "../../../../../../app/helpers/hooks/useUserName";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

const TableRow = ({ data, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    companyId,
    createdAt,
    bookingTransactionId,
    currency,
    type,
    paymentAmount,
    status,
    remarks,
    availableBalanceAfterTransaction,
    payableAmount,
    createdBy,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  const { getUserName } = useUserName();
  const userName = getUserName(createdBy);
  const rows = (
    <>
      {matchesSm && (
        <td>
          <span className="font-weight-bold">
            {bookingTransactionId || "-"}
          </span>
        </td>
      )}
      <td>
        <span className="font-weight-bold">
          {getCompanyName(companyId) || "-"}
        </span>
      </td>
      {matchesSm && (
        <>
          <td className="font-weight-bold">{userName || "-"}</td>
          <td>
            <span className="text-dark-75 font-weight-bold d-block font-size-lg">
              {date}
            </span>
            <span className="text-muted font-weight-bold">{time}</span>
          </td>
          <td>
            <span className="font-weight-bold">{remarks}</span>
          </td>
        </>
      )}
      <td>
        <span>{paymentTypeText[status] || "-"}</span>
      </td>
      <td>
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {currency}
          {paymentAmount?.toFixed(2)}
        </span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="font-weight-bold">
              {currency}
              {+availableBalanceAfterTransaction.toFixed(2)}
            </span>
          </td>
          <td>
            <span className="font-weight-bold">
              {currency}
              {+(payableAmount || 0).toFixed(2)}
            </span>
          </td>
        </>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Agency Name",
      value: getCompanyName(companyId) || "-",
    },
    {
      label: "Created By",
      value: userName || "-",
    },
    {
      label: "Date",
      value: date,
    },
    {
      label: "Remark",
      value: remarks,
    },
    {
      label: "Status",
      value: paymentTypeText[status] || "-",
    },
    {
      label: "Amount",
      value: (
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {currency}
          {paymentAmount?.toFixed(2)}
        </span>
      ),
    },
    {
      label: "Available Balance",
      value: (
        <span className="font-weight-bold">
          {currency}
          {+availableBalanceAfterTransaction.toFixed(2)}
        </span>
      ),
    },
    {
      label: "Payable Balance",
      value: (
        <span className="font-weight-bold">
          {currency}
          {+(payableAmount || 0).toFixed(2)}
        </span>
      ),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const AdminBillingHistory = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const { list, loading, paginationInfo } = useSelector(
    ({ accountBalance }) => accountBalance.transactionsHistory
  );
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const { fromDate, toDate } = dateRange;
  const { list: allCompanies, loading: allCompaniesLoading } = useSelector(
    ({ company }) => company.allCompanies
  );
  const { totalPages } = paginationInfo ?? {};

  useEffect(() => {
    dispatch(companyActions.getAllCompanies());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      actions.getAllTransactionsHistory({
        filterParam: [...appliedFilters],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const getCompanyName = useCallback(
    (companyId) =>
      allCompanies?.filter(({ id }) => id === companyId)?.[0]?.name,
    [allCompanies]
  );
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.getAllTransactionsHistory({
        filterParam: [...filters],
      })
    );
  };
  const tableContent = list?.map((item, index) => (
    <TableRow
      key={`row_${index}`}
      getCompanyName={getCompanyName}
      data={item}
    />
  ));
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.getAllTransactionsHistory({}));
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <BalanceSummarySearchCard
            setDateRange={setDateRange}
            onSearch={search}
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper id="billingHistory" className="p-7">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Balance History</Typography>
          </div>
          {fromDate && toDate && (
            <Typography>
              Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          {loading || allCompaniesLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-vertical-center mb-0 mt-5">
                <thead>
                  <tr className="bg-gray-100">
                    {matchesSm && <th>Booking Id</th>}
                    <th>Agency Name</th>
                    {matchesSm && (
                      <>
                        <th>Created By</th>
                        <th className="min-w-110px">Date</th>
                        <th>remark</th>
                      </>
                    )}
                    <th className="min-w-110px">Status</th>
                    <th>Amount</th>
                    {matchesSm && (
                      <>
                        <th>Available balance</th>
                        <th>Payable balance</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
            </div>
          )}
          {list?.length === 0 && (
            <Typography
              variant="h5"
              className="text-center w-100 text-muted my-5"
            >
              No Records to show
            </Typography>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
