import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./paymentAction";

const initialState = {
  topUps: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  create: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  delete: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  edit: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  specificTopup: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  paymentLogs: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    list: undefined,
  },
  paymentMethodBalances: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    list: undefined,
  },
};

export const paymentReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_PAYMENT_METHOD_BALANCES.REQUESTED:
        state.paymentMethodBalances.loading = true;
        break;
      case actions.GET_PAYMENT_METHOD_BALANCES.SUCCEEDED:
        state.paymentMethodBalances.loading = false;
        state.paymentMethodBalances.success = true;
        state.paymentMethodBalances.list = payload.data.data.$values;
        break;
      case actions.GET_PAYMENT_METHOD_BALANCES.FAILED:
        state.paymentMethodBalances.loading = false;
        state.paymentMethodBalances.failure = payload.error;
        break;
      case actions.GET_PAYMENT_LOGS.REQUESTED:
        state.paymentLogs.loading = true;
        break;
      case actions.GET_PAYMENT_LOGS.SUCCEEDED:
        state.paymentLogs.loading = false;
        state.paymentLogs.success = true;
        state.paymentLogs.list = payload.data.data.$values;
        break;
      case actions.GET_PAYMENT_LOGS.FAILED:
        state.paymentLogs.loading = false;
        state.paymentLogs.failure = payload.error;
        break;
      case actions.GET_ALL_TOP_UPS.REQUESTED:
      case actions.FILTER_ALL_TOP_UP.REQUESTED:
      case actions.GET_TOP_UPS.REQUESTED:
        state.topUps.loading = true;
        break;
      case actions.GET_ALL_TOP_UPS.SUCCEEDED:
      case actions.FILTER_ALL_TOP_UP.SUCCEEDED:
      case actions.GET_TOP_UPS.SUCCEEDED:
        state.topUps.loading = false;
        state.topUps.success = true;
        const { data, ...rest } = payload.data?.filtered;
        state.topUps.list = data;
        state.topUps.stats = payload.data?.total;
        state.topUps.paginationInfo = rest;
        break;
      case actions.GET_ALL_TOP_UPS.FAILED:
      case actions.FILTER_ALL_TOP_UP.FAILED:
      case actions.GET_TOP_UPS.FAILED:
        state.topUps.loading = false;
        state.topUps.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_TOP_UP.REQUESTED:
        state.specificTopup.loading = true;
        break;
      case actions.GET_SPECIFIC_TOP_UP.SUCCEEDED:
        state.specificTopup.loading = false;
        state.specificTopup.success = true;
        state.specificTopup.data = payload.data;
        break;
      case actions.GET_SPECIFIC_TOP_UP.FAILED:
        state.specificTopup.loading = false;
        state.specificTopup.failure = payload.error;
        break;
      case actions.CREATE.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        state.topUps.list = [payload.data, ...state.topUps.list];
        break;
      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.CREATE.CLEAR:
        state.create = initialState.create;
        break;
      case actions.DELETE_TOP_UP.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE_TOP_UP.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.topUps.list = state.topUps.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_TOP_UP.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE_TOP_UP.CLEAR:
        state.delete = initialState.delete;
        break;
      case actions.EDIT_TOP_UP.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT_TOP_UP.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        const index = state.topUps.list.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.topUps.list[index] = payload.args;
        break;
      case actions.EDIT_TOP_UP.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT_TOP_UP.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
