import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import useSupplierName from "../../../../../../app/helpers/hooks/useSupplierName";
import { TICKET_DETAILS } from "../../../../../../app/routesMap";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../../../app/routesMapAdmin";
import { getDateTime } from "../../../../../_helpers";
import { Button, LoadingSpinner } from "../../../common";
import { SupplierUpdateModal } from "./supplierUpdate/SupplierUpdateModal";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const SupplierRefundTable = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { getSupplierName, loading: suppliersLoading } = useSupplierName();
  const { list, loading } = useSelector(
    ({ supplier }) => supplier.supplierRefundTopUps
  );
  const openUpdate = (id) => {
    setShowModal(true);
    setSelectedId(id);
  };
  const getSelectBooking = () => list?.filter(({ id }) => selectedId === id)[0];
  const tableContent = list?.map((item, index) => (
    <TableRow
      getSupplierName={getSupplierName}
      key={`row_${index}`}
      data={item}
      openUpdate={openUpdate}
    />
  ));
  return (
    <div className="table-responsive">
      {loading || suppliersLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <SupplierUpdateModal
            onHide={() => setShowModal(false)}
            show={showModal}
            data={getSelectBooking()}
            getSupplierName={getSupplierName}
          />
          <thead>
            <tr className="bg-gray-100">
              <th>Booking ID</th>
              {matchesSm && (
                <>
                  <th className="min-w-100px">Requested Date</th>
                  <th className="min-w-100px">Received Date</th>
                </>
              )}
              <th>Supplier</th>
              {matchesSm && (
                <>
                  <th>Ref No.</th>
                  {/* <th>Agency Name</th> */}
                  <th>Received Amount</th>
                  <th>Supplier Fee</th>
                </>
              )}
              <th>Total Amount</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getSupplierName, openUpdate }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    id,
    createdAt,
    bookingTransactionId,
    supplierId,
    amount,
    fee,
    status,
  } = data;
  const supplierName = getSupplierName(supplierId);
  const { date } = getDateTime(createdAt);
  const detailsLink = isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS;
  const isApproved = status === 2;
  const receivedAmount = isApproved ? (amount - fee)?.toFixed(2) : 0.0;
  const rows = (
    <>
      <td>
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(detailsLink, { id: bookingTransactionId })}
        >
          <span>{bookingTransactionId}</span>
        </NavLink>
      </td>
      {matchesSm && (
        <>
          <td>
            <span>{date || "-"}</span>
          </td>
          <td>
            <span>{"-"}</span>
          </td>
        </>
      )}
      <td>
        <span>{supplierName}</span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span>{"-"}</span>
          </td>
          {/* <td>
        <span>{agencyName || "-"}</span>
      </td> */}
          <td>
            <span>{receivedAmount}</span>
          </td>
          <td>
            <span>{fee?.toFixed(2) || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span>{amount?.toFixed(2)}</span>
      </td>
      <td>
        <Button
          disabled={isApproved}
          smallPadding
          onClick={() => openUpdate(id)}
        >
          Update
        </Button>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "Requested Date",
      value: date || "-",
    },
    {
      label: "Received Date",
      value: "-",
    },
    {
      label: "Supplier",
      value: supplierName,
    },
    {
      label: "Ref No.",
      value: "-",
    },
    {
      label: "Received Amount",
      value: receivedAmount,
    },
    {
      label: "Supplier Fee",
      value: fee?.toFixed(2) || "-",
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
