import React, { useEffect, useState } from "react";
import { Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { LoadingSpinner, MenuComponent } from "../common";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../../redux/state/markup";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { DeleteModal } from "../admin/components/deleteModal/DeleteModal";
import { parseRoute } from "../../../../app/helpers/formatters";
import { useHistory } from "react-router";
import { ADMIN_MARKUP_EDIT } from "../../../../app/routesMapAdmin";
import { DISTRIBUTION_MARKUP_EDIT } from "../../../../app/routesMap";
import { NOTIFICATION_TYPE_ERROR } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import useCompanyName from "../../../../app/helpers/hooks/useCompanyName";
import useSubPermissions from "../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../data/adminPermissionIds";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";

const ComponentTypeLabels = {
  F: "Flight",
  H: "Hotel",
};

const JourneyTypeLabels = [
  "Any",
  "Oneway",
  "RoundTrip",
  "Multicity",
  "OpenJaw",
];

const FareTypeLabels = [
  "Any",
  "Public",
  "Publish",
  "Consolidator",
  "PubToNet",
  "Mix",
];

const ClassesTypeLabels = ["Any", "Economy", "Premium", "Business", "First"];

const PaxTypeLabels = ["Any", "Adult", "Child", "Infant Seat", "Infant On Lab"];

const getLabels = (keys, labels) =>
  keys
    .split(",")
    .map((key) => labels[key])
    .join(",");

const TableRow = ({
  subPermissions,
  data,
  onDelete,
  showCompanyName,
  getCompanyName,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const { markupRuleJson, id, isActive, companyId } = data;
  const companyName = getCompanyName(companyId);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const allMarkupSubPermission =
    adminPermissionsIds.systemManagement.markup.subPermissions;
  const menuOptions = [
    // { label: "View", onClick: () => {} },
    {
      label: "Edit",
      onClick: () =>
        history.push(
          parseRoute(isAdmin ? ADMIN_MARKUP_EDIT : DISTRIBUTION_MARKUP_EDIT, {
            id,
          })
        ),
      hidden: isAdmin
        ? !subPermissions.includes(allMarkupSubPermission.editMarkup.id)
        : false,
    },
    // { label: "Duplicate", onClick: () => {} },
    {
      label: "Delete",
      onClick: () => onDelete(id),
      hidden:
        isAdmin &&
        !subPermissions.includes(allMarkupSubPermission.deleteMarkup.id),
    },
  ];
  let markupData;
  try {
    markupData = JSON.parse(markupRuleJson);
  } catch (error) {
    markupData = {};
  }
  const {
    MarkupType,
    RuleType,
    RuleName,
    Amount,
    JourneyType,
    FareType,
    Classes,
    PassengerType,
    Passengers,
    ComponentType,
  } = markupData;
  const rows = (
    <>
      <td className="text-primary">{id}</td>
      {showCompanyName && <td>{companyName}</td>}
      {matchesSm && <td>{RuleType}</td>}
      <td>{RuleName}</td>
      {matchesSm && (
        <>
          <td>{ComponentTypeLabels[ComponentType]}</td>
          <td>{Amount}</td>
          <td>{MarkupType}</td>
          <td>
            {JourneyType ? getLabels(JourneyType, JourneyTypeLabels) : "-"}
          </td>
          <td>{FareType ? getLabels(FareType, FareTypeLabels) : "-"}</td>
          <td>{Classes ? getLabels(Classes, ClassesTypeLabels) : "-"}</td>
          <td>
            {PassengerType ? getLabels(PassengerType, PaxTypeLabels) : "-"}
          </td>
          <td>{Passengers}</td>
        </>
      )}
      <td>{isActive ? "Active" : "InActive"}</td>
      {/* hide for release */}
      <td>
        <MenuComponent options={menuOptions} />
      </td>
    </>
  );

  const mobileRowDetails = [
    {
      label: "Rule Type",
      value: RuleType,
    },
    {
      label: "Type",
      value: ComponentTypeLabels[ComponentType],
    },
    {
      label: "Amount",
      value: Amount,
    },
    {
      label: "Markup Type",
      value: MarkupType,
    },
    {
      label: "JourneyType",
      value: JourneyType ? getLabels(JourneyType, JourneyTypeLabels) : "-",
    },
    {
      label: "FareType",
      value: FareType ? getLabels(FareType, FareTypeLabels) : "-",
    },
    {
      label: "Class",
      value: Classes ? getLabels(Classes, ClassesTypeLabels) : "-",
    },
    {
      label: "PaxType",
      value: PassengerType ? getLabels(PassengerType, PaxTypeLabels) : "-",
    },
    {
      label: "PAX(s)",
      value: Passengers,
    },
    {
      label: "Status",
      value: isActive ? "Active" : "InActive",
    },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const ViewMarkup = ({ isAll, permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const { list, listLoading } = useSelector(({ markup }) => markup.markups);
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const {
    success: deleteSuccess,
    failure: deleteFailure,
    loading: deleteLoading,
  } = useSelector(({ markup }) => markup.delete);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const dispatch = useDispatch();

  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onSaveDelete = () => {
    dispatch(actions.deleteMarkup({ id: selectedId }));
  };

  useEffect(() => {
    if (isAll) {
      dispatch(actions.getAllMarkups());
    } else {
      dispatch(actions.getMarkupByCompany({ companyId: companyInUserId }));
    }
  }, [companyInUserId, dispatch, isAll]);
  useEffect(() => {
    if (deleteFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
    }
  }, [dispatch, deleteFailure]);

  const tableContent = list?.map((item, index) => (
    <TableRow
      onDelete={onDelete}
      key={`row_${index}`}
      index={index}
      data={item}
      subPermissions={subPermissions}
      showCompanyName={isAll}
      getCompanyName={getCompanyName}
    />
  ));
  return (
    <Paper className="p-3">
      <DeleteModal
        onHide={() => {
          setShowDelete(false);
          setSelectedId(undefined);
        }}
        show={showDelete}
        // id={selectedId}
        onSave={onSaveDelete}
        itemName="Supplier"
        loading={deleteLoading}
        success={deleteSuccess}
      />
      {listLoading || (allCompaniesLoading && isAll) ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* hide for release */}
          {/* <div className="d-flex justify-content-between">
        <form action="">
          <Input
            // name="ruleName"
            // onChange={handleChange}
            // value={values.ruleName}
            outlined={false}
            search
            noErrorMessage
            className="max-w-500px"
          />
        </form>
        <Button smallPadding>
          <span className="svg-icon svg-icon-sm">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/export.svg")} />
          </span>
          Export
        </Button>
      </div> */}
          <div className="table-responsive">
            <table className="table table-vertical-center mb-0 mt-5">
              <thead>
                <tr className="bg-gray-100">
                  <th className="w-25px">S.No.</th>
                  {isAll && <th>Agency Name</th>}
                  {matchesSm && <th>Rule Type</th>}
                  <th>Rule Name</th>
                  {matchesSm && (
                    <>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Markup Type</th>
                      <th>JourneyType</th>
                      <th>FareType</th>
                      <th>Class</th>
                      <th>PaxType</th>
                      <th>PAX(s)</th>
                    </>
                  )}
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{tableContent}</tbody>
            </table>
            {!list?.length && (
              <Typography
                variant="h5"
                className="text-center w-100 text-muted my-5"
              >
                No Markups to show
              </Typography>
            )}
          </div>
        </>
      )}
    </Paper>
  );
};
