import {
  ExpansionPanelDetails,
  Paper,
  Typography,
  Tabs,
  Tab,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Input, Select } from "../common";
import { countriesOptions } from "../fareSearch/TravellerDetails";
import { useDispatch, useSelector } from "react-redux";
import { actions as companyActions } from "../../../../redux/state/company";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import useAgenciesOptions from "../../../../app/helpers/hooks/useAgenciesOptions";
import useSuppliersOptions from "../../../../app/helpers/hooks/useSuppliersOptions";

function TabPanel(props) {
  const { children, value, index, tabsData, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-100"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" className="font-weight-bold mb-3">
            {tabsData[index]}
          </Typography>
          {children}
        </Box>
      )}
    </div>
  );
}
function* getNextNumber() {
  let i = 0;
  while (true) {
    yield i;
    i++;
  }
}
export const AdvancedSettings = ({ formik }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabsChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const generateIndex = getNextNumber();
  const { handleChange, values, touched, errors, setFieldValue } = formik;
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const airlinesOption = airlinesList?.map(({ code, name }) => ({
    value: code,
    label: `${name} (${code})`,
  }));
  const airportsOption = airportsList?.map(({ code, name }) => ({
    value: code,
    label: `${name} (${code})`,
  }));
  const { agencyOptions } = useAgenciesOptions();
  const { supplierOptions } = useSuppliersOptions(2);

  const tabsData = [
    ...(isAdmin ? ["Agencies"] : []),
    "Departure Country",
    "Departure Location",
    "Departure Date",
    "Departure Time",
    "Arrival Country",
    "Arrival Location",
    "Arrival Date",
    "Arrival Time",
    "OB Booking class",
    "OB LAYOVER Time",
    "IB Booking class",
    "IB LAYOVER Time",
    "Days To Departure",
    "Age Group",
    ...(isAdmin ? ["Suppliers"] : []),
    "Airlines",
    "Flight Duration",
  ];
  return (
    <Paper>
      <ExpansionPanel elevation={0}>
        <ExpansionPanelSummary>
          <Typography variant="h5">Advance Settings</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleTabsChange}
            aria-label="Vertical tabs example"
            // sx={{ borderRight: 1, borderColor: "divider" }}
            // textColor="white"
            indicatorColor="white"
            // indicator
            centered={false}
          >
            {tabsData.map((label, index) => (
              <Tab
                className={clsx("mr-7 d-flex", {
                  "bg-primary text-white ": index === selectedTab,
                })}
                key={`tab_${index}`}
                label={label}
              />
            ))}
          </Tabs>
          {isAdmin && (
            <TabPanel
              tabsData={tabsData}
              value={selectedTab}
              index={generateIndex.next().value}
            >
              <Select
                spacing
                multi
                options={agencyOptions}
                variant="secondary"
                name="AgencyIds"
                onChange={(values) => setFieldValue("AgencyIds", values)}
                value={values.AgencyIds}
                errorMessage={errors.AgencyIds}
                showErrors={touched.AgencyIds}
                horizontal
                fixedLabelWidth
                // label="Agencies (leave empty for all agencies)"
                placeholder="Select or Add"
              />
            </TabPanel>
          )}
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <MultiSelectContainer
              options={countriesOptions}
              placeholder="COUNTRY"
              formik={formik}
              name1="DepartCountry"
              name2="DepartCountry2"
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <MultiSelectContainer
              placeholder="AIRPORT"
              options={airportsOption}
              formik={formik}
              name1="DepartAirport"
              name2="DepartAirport2"
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <DateInputsContainer formik={formik} name="DepartDates" />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <TimeInputsContainer formik={formik} name="DepartTimes" />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <MultiSelectContainer
              options={countriesOptions}
              placeholder="COUNTRY"
              formik={formik}
              name1="ArrivalCountry"
              name2="ArrivalCountry2"
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <MultiSelectContainer
              options={airportsOption}
              placeholder="AIRPORT"
              formik={formik}
              name1="ArrivalAirport"
              name2="ArrivalAirport2"
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <DateInputsContainer formik={formik} name="ArrivalDates" />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <TimeInputsContainer formik={formik} name="ArrivalTimes" />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <Input
              spacing
              multiline
              variant="secondary"
              placeholder="OUTBOOUND BOOKING CLASS"
              name="OBC"
              onChange={handleChange}
              value={values.OBC}
              errorMessage={errors.OBC}
              showErrors={touched.OBC}
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <div className="d-flex ">
              <Input
                spacing
                type="time"
                label="From"
                horizontal
                variant="secondary"
                className="max-w-200px mr-7"
                fixedLabelWidthXS
                name="OLTFrom"
                onChange={handleChange}
                value={values.OLTFrom}
                errorMessage={errors.OLTFrom}
                showErrors={touched.OLTFrom}
              />
              <Input
                spacing
                type="time"
                label="To"
                horizontal
                variant="secondary"
                className="max-w-200px"
                fixedLabelWidthXS
                name="OLTTo"
                onChange={handleChange}
                value={values.OLTTo}
                errorMessage={errors.OLTTo}
                showErrors={touched.OLTTo}
              />
            </div>
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <Input
              spacing
              multiline
              variant="secondary"
              placeholder="INBOUND BOOKING CLASS"
              name="IBC"
              onChange={handleChange}
              value={values.IBC}
              errorMessage={errors.IBC}
              showErrors={touched.IBC}
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <div className="d-flex ">
              <Input
                spacing
                type="time"
                label="From"
                horizontal
                variant="secondary"
                className="max-w-200px mr-7"
                fixedLabelWidthXS
                name="ILTTo"
                onChange={handleChange}
                value={values.ILTTo}
                errorMessage={errors.ILTTo}
                showErrors={touched.ILTTo}
              />
              <Input
                spacing
                type="time"
                label="To"
                horizontal
                variant="secondary"
                className="max-w-200px"
                fixedLabelWidthXS
                name="ILTFrom"
                onChange={handleChange}
                value={values.ILTFrom}
                errorMessage={errors.ILTFrom}
                showErrors={touched.ILTFrom}
              />
            </div>
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <div className="d-flex ">
              <Input
                spacing
                label="From"
                horizontal
                variant="secondary"
                className="max-w-200px mr-7"
                fixedLabelWidthXS
              />
              <Input
                spacing
                label="To"
                horizontal
                variant="secondary"
                className="max-w-200px"
                fixedLabelWidthXS
              />
            </div>
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <Input
              spacing
              label="From"
              horizontal
              variant="secondary"
              className="max-w-200px mr-7"
              fixedLabelWidthXS
            />
          </TabPanel>
          {isAdmin && (
            <TabPanel
              tabsData={tabsData}
              value={selectedTab}
              index={generateIndex.next().value}
            >
              <MultiSelectContainer
                placeholder="Suppliers"
                formik={formik}
                options={supplierOptions}
                name1="Suppliers"
              />
            </TabPanel>
          )}
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <MultiSelectContainer
              placeholder="AIRLINE"
              formik={formik}
              options={airlinesOption}
              name1="Airlines"
              name2="Airlines2"
            />
          </TabPanel>
          <TabPanel
            tabsData={tabsData}
            value={selectedTab}
            index={generateIndex.next().value}
          >
            <div className="d-flex ">
              <Input
                spacing
                label="From"
                horizontal
                variant="secondary"
                className="max-w-200px mr-7"
                fixedLabelWidthXS
                name="FlightDurationFrom"
                onChange={handleChange}
                value={values.FlightDurationFrom}
                errorMessage={errors.FlightDurationFrom}
                showErrors={touched.FlightDurationFrom}
              />
              <Input
                spacing
                label="To"
                horizontal
                variant="secondary"
                className="max-w-200px"
                fixedLabelWidthXS
                name="FlightDurationTo"
                onChange={handleChange}
                value={values.FlightDurationTo}
                errorMessage={errors.FlightDurationTo}
                showErrors={touched.FlightDurationTo}
              />
            </div>
          </TabPanel>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const TimeInputsContainer = ({ formik, name }) => {
  const { touched, errors, getFieldProps } = formik;
  return (
    <div>
      {days.map((day, index) => (
        <div key={index} className="d-flex ">
          <Typography variant="body1" className="min-w-100px mt-2">
            {day}
          </Typography>
          <Input
            spacing
            showErrors={touched[name]?.[index].Times[0].From}
            errorMessage={errors[name]?.[index].Times[0].From}
            {...getFieldProps(`${name}.${index}.Times.0.From`)}
            variant="secondary"
            className="max-w-150px mx-3"
            type="time"
          />
          <Input
            spacing
            showErrors={touched[name]?.[index].Times[0].To}
            errorMessage={errors[name]?.[index].Times[0].To}
            {...getFieldProps(`${name}.${index}.Times.0.To`)}
            variant="secondary"
            className="max-w-150px"
            type="time"
          />
        </div>
      ))}
    </div>
  );
};

export const DateInputsContainer = ({ formik, name }) => {
  const [numOfInputs, setNumOfInputs] = useState(1);
  const { setFieldValue, values, touched, errors, getFieldProps } = formik;
  const dateField = {
    From: "",
    To: "",
  };
  const addField = () => {
    setFieldValue(name, [...values[name], dateField]);
    setNumOfInputs(numOfInputs + 1);
  };
  const removeField = (index) => {
    const dates = [...values[name]];
    dates.splice(index, 1);
    setFieldValue(name, [...dates]);
    setNumOfInputs(numOfInputs - 1);
  };
  return (
    <div>
      {values[name].map((_, index) => (
        <div key={index} className="d-flex">
          <Input
            spacing
            showErrors={touched[name]?.[index].From}
            errorMessage={errors[name]?.[index].From}
            {...getFieldProps(`${name}.${index}.From`)}
            variant="secondary"
            type="date"
          />
          <Input
            spacing
            showErrors={touched[name]?.[index].To}
            errorMessage={errors[name]?.[index].To}
            {...getFieldProps(`${name}.${index}.To`)}
            variant="secondary"
            type="date"
            className="mx-3"
          />

          <button
            type="button"
            onClick={
              index < numOfInputs - 1 ? () => removeField(index) : addField
            }
            className="d-flex justify-content-center align-items-center btn p-0 rounded-circle bg-primary text-white min-w-20px h-20px mt-3 font-size-h5"
          >
            {index < numOfInputs - 1 ? "-" : "+"}
          </button>
        </div>
      ))}
    </div>
  );
};

export const MultiSelectContainer = ({
  options,
  name1,
  name2,
  placeholder,
  formik,
}) => {
  const { setFieldValue, values, touched, errors } = formik;
  return (
    <div>
      <Select
        spacing
        multi
        variant="secondary"
        options={options}
        name={name1}
        onChange={(values) => setFieldValue(name1, values)}
        value={values[name1]}
        errorMessage={errors[name1]}
        showErrors={touched[name1]}
        // outlined={false}
      />
      {/* <div className="d-flex justify-content-center my-5">
        <button className="btn mr-2 p-0">
          <span className="svg-icon svg-icon-xl">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/moveDown.svg")} />
          </span>
        </button>
        <button className="btn p-0">
          <span className="svg-icon svg-icon-xl">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/moveUp.svg")} />
          </span>
        </button>
      </div>
      <Select
      spacing
        multi
        variant="secondary"
        options={options}
        name={name2}
        onChange={(values) => setFieldValue(name2, values)}
        value={values[name2]}
        errorMessage={errors[name2]}
        showErrors={touched[name2]}
        outlined={false}
      /> */}
    </div>
  );
};
