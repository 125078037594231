import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../_metronic/layout/components/common";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { HOME } from "../../../routesMap";

export const AccessDeniedPage = () => {
  const history = useHistory();
  return (
    <Paper className="h-100 p-8 d-flex flex-column justify-content-center align-items-center">
      <img
        className=""
        src={toAbsoluteUrl("/media/svg/illustrations/access-denied.svg")}
        alt=""
      />
      <Typography
        variant="h5"
        className="max-w-500px text-center font-weight-bold mt-8 mb-1"
      >
        Access Denied!
      </Typography>
      <Typography
        variant="h6"
        className="max-w-500px text-center font-weight-normal"
      >
        The page you'e trying to access has restricted access
      </Typography>
      <Typography
        variant="body1"
        className="max-w-500px text-center font-weight-normal mt-1 mb-8"
      >
        Please refer to your system administrator.
      </Typography>

      <Button type="primary" onClick={() => history.push(HOME)}>
        Go to homepage
      </Button>
    </Paper>
  );
};
