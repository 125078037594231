import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { actions } from "../../../../../../redux/state/company";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const FlymeInfoBar = () => {
  const { data: companyData } = useSelector(
    ({ company }) => company.companyData
  );
  const { name } = companyData || {};
  return (
    <Paper className="p-7 d-flex align-items-center">
      <img
        className="rounded-circle mr-5 max-w-75px"
        src={toAbsoluteUrl("/media/logo/new-logo-colored.svg")}
        alt=""
      />
      <div>
        <Typography variant="h4">{name}</Typography>
        {/* <Typography variant="body1" className="text-primary my-1">
          {email}
        </Typography>
        <Typography variant="body1" className="my-1">
          {phone}
        </Typography> */}
        {/* <Typography variant="body1" className="text-muted">
              {companyName}
            </Typography> */}
      </div>
      {/* <img
            className="rounded-circle"
            src={toAbsoluteUrl("/media/users/100_6.jpg")}
            alt=""
          /> */}
      {/* <div className="d-flex">
        <div className="border bg-white p-7 mr-5 text-center h-100 d-flex flex-column justify-content-center">
          <Typography variant="h4" className="mb-3">
            <span className="text-primary">$</span>
            {data?.availableBalance}
          </Typography>
          <Typography variant="h6" className="mb-2">
            Available Balance
          </Typography>
          <Typography variant="body2">
            Payable Balance: ${data?.availableBalance}
          </Typography>
        </div>
        <div className="border bg-white p-7 text-center h-100 d-flex flex-column justify-content-center">
          <Typography variant="h4" className="mb-3">
            <span className="text-primary">$</span>
            {data?.availableCredit}
          </Typography>
          <Typography variant="h6" className="mb-2">
            Available Credit
          </Typography>
          <Typography variant="body2">
            Payable Balance: ${data?.availableCredit}
          </Typography>
        </div> */}
      {/* </div> */}
    </Paper>
  );
};
