import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { LoginSessionsTable } from "./LoginSessionsTable";

export const ViewSessions = () => {
  const { id, type } = useParams();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LoginSessionsTable showAll isUser={+type === 0} id={id} />
      </Grid>
    </Grid>
  );
};
