import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/user";
import { getDateTime } from "../../../../_helpers";
import { SubscriptionHistoryTable } from "./SubscriptionHistoryTable";

export const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const { subscription } = useSelector(({ company }) => company.companyData);
  const { companyInUserId } = useSelector(({ user }) => user.userData || {});

  const { startDate, endDate, isActive } = subscription;
  const { date: startDateFormatted } = getDateTime(startDate);
  const { date: endDateFormatted } = getDateTime(endDate);
  const status = isActive ? "Active" : "Inactive";
  const statusColor = isActive ? "success" : "danger";
  useEffect(() => {
    dispatch(
      actions.getSubscriptionPaymentsByCompany({ companyId: companyInUserId })
    );
  }, [companyInUserId, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between">
            <div>
              <Typography variant="h5" className="m-0 p-0">
                Subscription details
              </Typography>
            </div>
            {/* <Button variant="primary">Renew</Button> */}
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between">
            <div>
              <Typography variant="h5">Status</Typography>
              <Typography variant="body1" className={`text-${statusColor}`}>
                {status}
              </Typography>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between">
            <div>
              <Typography variant="h5">Start Date</Typography>
              <Typography variant="body1">{startDateFormatted}</Typography>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between">
            <div>
              <Typography variant="h5">End Date</Typography>
              <Typography variant="body1">{endDateFormatted}</Typography>
            </div>
          </div>
        </Paper>
      </Grid>
      {/* Chart */}
      <Grid item xs={12}>
        <SubscriptionHistoryTable />
      </Grid>
    </Grid>
  );
};
