import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import {
  Grid,
  Paper,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";

import useAirportsOptions from "../../../../../app/helpers/hooks/useAirportsOptions";
import { getDateTime } from "../../../../_helpers";
import { Button, Select, Input, Seperator } from "../../common";
import {
  cabinClassOptions,
  getBookingClassOptions,
} from "../../../../_assets/js/components/options";
import useAirlinesOptions from "../../../../../app/helpers/hooks/useAirlinesOptions";
import useSuppliersOptions from "../../../../../app/helpers/hooks/useSuppliersOptions";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../redux/state/booking";

const orderDetailsSchema = Yup.object().shape({
  segments: Yup.array().of(
    Yup.object().shape({
      iSeg: Yup.array().of(
        Yup.object().shape({
          aApt: Yup.string().required("Field is required"),
          dApt: Yup.string().required("Field is required"),
          aTer: Yup.string(),
          dTer: Yup.string(),
          fn: Yup.string().required("Field is required"),
          bc: Yup.string().required("Field is required"),
          departDateTime: Yup.string().required("Field is required"),
          arrivalDateTime: Yup.string().required("Field is required"),
          baggageAllowanceKG: Yup.string(),
          baggageAllowancePC: Yup.string(),
          segmentDuration: Yup.string().required("Field is required"),
          airlineNm: Yup.string().required("Field is required"),
        })
      ),
    })
  ),
  markup: Yup.number().required("Field is required"),
  abf: Yup.number().min(0, "Base Fare must be greater than or equal to 0"),
  atx: Yup.number().min(0, "Tax must be greater than or equal to 0"),
  cbf: Yup.number().min(0, "Base Fare must be greater than or equal to 0"),
  ctx: Yup.number().min(0, "Tax must be greater than or equal to 0"),
  ibf: Yup.number().min(0, "Base Fare must be greater than or equal to 0"),
  itx: Yup.number().min(0, "Tax must be greater than or equal to 0"),
});
export const FlightDetailsForm = ({
  selectedAirline,
  searchRQ,
  isReissue,
  onSubmit,
  bookingParams,
  loading,
}) => {
  const dispatch = useDispatch();
  const { supplierOptions } = useSuppliersOptions();
  const { airportsOptions } = useAirportsOptions();
  const { airlinesOptions } = useAirlinesOptions();

  const { segments: segmentsSummary, paxDetails } = searchRQ;
  const { child, infant, adult } = paxDetails || {};

  const bcOptions = getBookingClassOptions();
  const segmentInitialValues = {
    aApt: "",
    dApt: "",
    aTer: "",
    dTer: "",
    departTime: "",
    arrivalTime: "",
    fn: "",
    bc: "",
    departDateTime: "",
    arrivalDateTime: "",
    baggageAllowanceKG: "",
    baggageAllowancePC: "",
    segmentDuration: "",
    airlineNm: "",
  };
  const initialValues = useMemo(() => {
    const { segments, cabinClass, abf, atx, cbf, ctx, ibf, itx, gds } =
      selectedAirline || {};

    if (!segments) return [];
    const segmentsInitialValue = segments.map(({ iSeg, totalDuration }) => {
      const iSegInitialValues = iSeg.map(
        ({
          dDat,
          aDat,
          aTime,
          dTime,
          dApt,
          aApt,
          bc,
          ma,
          airlineNm,
          fn,
          dTer,
          aTer,
          segmentDuration,
          fba,
        }) => {
          const isPC = fba?.includes("PC");

          return {
            aApt: aApt,
            dApt: dApt,
            departDateTime: `${dDat}T${dTime || "00:00"}`,
            arrivalDateTime: `${aDat || ""}T${aTime || ""}`,
            aTer: aTer,
            dTer: dTer,
            fn: fn,
            baggageAllowancePC: isPC ? fba : "",
            baggageAllowanceKG: isPC ? "" : fba,
            bc: bc,
            segmentDuration,
            airlineNm,
          };
        }
      );
      return { iSeg: iSegInitialValues, length: iSeg.length };
    });
    return {
      segments: segmentsInitialValue,
      markup: "",
      // totalAmt: totalAmt || "",
      abf: abf || "",
      atx: atx || "",
      cbf: cbf || "",
      ctx: ctx || "",
      ibf: ibf || "",
      itx: itx || "",
      gds,
      pnr: "",
      ltd: "",
      refNo: "",
      cabinClass,
    };
  }, [selectedAirline]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: !!bookingParams ? bookingParams : initialValues,
    validationSchema: orderDetailsSchema,
    onSubmit: (values) => {
      dispatch(actions.setBookingParams(values));
      const newAirline = getNewAirlineDataFromForm(values);
      onSubmit(newAirline);
    },
  });
  const getNewAirlineDataFromForm = (formData) => {
    const { segments } = formData;
    const newSegments = segments.map(({ iSeg }) => {
      const iSegData = iSeg.map(
        ({
          departDateTime,
          arrivalDateTime,
          baggageAllowancePC,
          baggageAllowanceKG,
          ...rest
        }) => {
          const { date: departDate, time: departTime } = getDateTime(
            departDateTime
          );
          const { date: arrivalDate, time: arrivalTime } = getDateTime(
            arrivalDateTime
          );
          return {
            ...rest,
            aDat: arrivalDate,
            aTime: arrivalTime,
            dDat: departDate,
            dTime: departTime,
            fba: `${baggageAllowancePC} ${baggageAllowanceKG}`,
          };
        }
      );
      return { iSeg: iSegData };
    });
    return {
      ...formData,
      supplierAmt,
      totalAmt,
      segments: newSegments,
      id: "Offline",
    };
  };
  const {
    values,
    setFieldValue,
    getFieldProps,
    errors,
    touched,
    handleSubmit,
  } = formik;
  const { segments } = values;
  const removeField = ({ segmentIndex, iSegIndex }) => {
    const segmentNew = [...segments[segmentIndex].iSeg];
    segmentNew.splice(iSegIndex, 1);
    setFieldValue(`segments.${segmentIndex}.iSeg`, [...segmentNew]);
    setFieldValue(
      `segments.${segmentIndex}.length`,
      segments[segmentIndex].length - 1
    );
  };
  const addSearch = (segmentIndex) => {
    setFieldValue(`segments.${segmentIndex}.iSeg`, [
      ...segments[segmentIndex].iSeg,
      segmentInitialValues,
    ]);
    setFieldValue(
      `segments.${segmentIndex}.length`,
      segments[segmentIndex].length + 1
    );
  };

  const supplierAmt = useMemo(() => {
    const { abf, atx, cbf, ctx, ibf, itx } = values;
    const { child, infant, adult } = paxDetails || {};
    return (
      +(abf || 0) * adult +
      +(atx || 0) * adult +
      +(cbf || 0) * child +
      +(ctx || 0) * child +
      +(ibf || 0) * infant +
      +(itx || 0) * infant
    );
  }, [paxDetails, values]);
  const totalAmt = useMemo(() => {
    const { markup } = values;
    return +(supplierAmt || 0) + +(markup || 0);
  }, [supplierAmt, values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p-7">
            <Typography variant="h5" className="mb-5">
              Flight Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Select
                  value={values.gds}
                  onChange={(v) => setFieldValue("gds", v)}
                  showErrors={touched?.gds}
                  errorMessage={errors?.gds}
                  variant="secondary"
                  label="Supplier"
                  options={supplierOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("pnr")}
                  showErrors={touched?.pnr}
                  errorMessage={errors?.pnr}
                  variant="secondary"
                  label="Pnr"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("refNo")}
                  showErrors={touched?.refNo}
                  errorMessage={errors?.refNo}
                  variant="secondary"
                  label="Supplier Ref No"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("ltd")}
                  showErrors={touched?.ltd}
                  errorMessage={errors?.ltd}
                  variant="secondary"
                  type="datetime-local"
                  label="Last Ticketing Date (UTC)"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  value={values.cabinClass}
                  onChange={(v) => setFieldValue("cabinClass", v)}
                  showErrors={touched?.cabinClass}
                  errorMessage={errors?.cabinClass}
                  options={cabinClassOptions}
                  variant="secondary"
                  label="Cabin Class"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {/* <Paper className="p-7"> */}
          {segments?.length &&
            segments.map(({ iSeg, length }, segmentIndex) => {
              const { arrivalApt, departApt } = segmentsSummary[segmentIndex];
              return (
                <ExpansionPanel
                  expanded={true}
                  // onChange={handlePanelChange(id)}
                  // elevation={0}
                  key={`segment-${segmentIndex}`}
                >
                  <ExpansionPanelSummary className="min-w-100">
                    <div className="d-flex">
                      <Typography variant="h5" className="font-weight-bold">
                        {departApt}-{arrivalApt}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="d-flex flex-column">
                    {iSeg.map(
                      (
                        { aApt, dApt, baggageAllowancePC, bc, airlineNm },
                        iSegIndex
                      ) => (
                        <div key={`search-${iSegIndex}`}>
                          <div className="d-flex">
                            <Grid container spacing={3}>
                              {/* <Grid item xs={12} md={6}>
                      <div
                        className={clsx("position-relative p-0 w-100", {
                          "badge border rounded border-gray-400": matchesSm,
                        })}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            className={clsx(
                              "d-flex align-items-center justify-content-between mx-3",
                              {
                                "badge border rounded border-gray-400 mb-5": !matchesSm,
                              }
                            )}
                          >
                            <AutoCompleteSelect
                              options={airportsOptions}
                              onChange={(value) =>
                                setFieldValue(
                                  `segments.${index}.dApt`,
                                  value
                                )
                              }
                              value={segments[index].dApt}
                              label="From"
                              id={`from-${index}`}
                            />

                            {!matchesSm && (
                              <button
                                className="btn w-30px h-30px  d-flex align-items-center justify-content-center 
                                    border border-primary rounded-circle bg-white"
                                style={{ pointer: "cursor" }}
                                id={`swap-${index}`}
                              >
                                <SVG
                                  src={"/media/svg/icons/General/reverse.svg"}
                                  className={"svg-icon-primary svg-icon-1"}
                                />
                              </button>
                            )}
                          </Grid>
                          {matchesSm && (
                            <Grid
                              item
                              xs={12}
                              sm
                              className={clsx(
                                "d-flex align-items-center justify-content-center"
                              )}
                            >
                              <div
                                className={clsx({
                                  [styles.seperator]: matchesSm,
                                })}
                              >
                                <button
                                  // onClick={() => swapAirports(index)}
                                  className={clsx(
                                    {
                                      [styles.reverse]: matchesSm,
                                      "w-40px h-40px": matchesSm,
                                      "w-30px h-30px": !matchesSm,
                                    },
                                    "d-flex align-items-center justify-content-center border border-primary rounded-circle bg-white"
                                  )}
                                  id={`from-${index}`}
                                >
                                  <SVG
                                    src={"/media/svg/icons/General/reverse.svg"}
                                    className={"svg-icon-primary svg-icon-1"}
                                  />
                                </button>
                              </div>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            className={clsx(" d-flex align-items-center mx-3", {
                              "badge border rounded border-gray-400": !matchesSm,
                            })}
                          >
                            <AutoCompleteSelect
                              options={airportsOptions}
                              onChange={(value) =>
                                setFieldValue(
                                  `segments.${index}.aApt`,
                                  value
                                )
                              }
                              value={segments[index].aApt}
                              label="to"
                              id={`to-${index}`}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
p                        <div className=" p-0 badge border rounded border-gray-400 w-100">
                          <div className="position-relative d-flex h-100">
                            <div className="d-flex justify-content-start w-50 p-5">
                              <DateTimePicker
                                label="Depart"
                                // {...getFieldProps(`s egments.${index}.departDate`)}
                                // inputVariant="outlined"
                                // value={selectedDate}
                                // onChange={handleDateChange}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                InputProps={{
                                  disableUnderline: true,
                                  id: `departDate-${index}`,
                                  // inputProps: {
                                  //   min: today,
                                  // },
                                }}
                              />
                            </div>
                            <div className={styles.seperator} />
                            <div className="d-flex justify-content-start w-50 p-5">
                              <DateTimePicker
                                label="Arrival"
                                // {...getFieldProps(
                                //   `segments.${index}.arrivalDate`
                                // )}
                                // inputVariant="outlined"
                                // value={selectedDate}
                                // onChange={handleDateChange}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                InputProps={{
                                  disableUnderline: true,
                                  // id: `arrivalDate-${index}`,
                                  // inputProps: {
                                  //   min: today,
                                  // },
                                }}
                              />
                            </div>
                          </div>
                        </div>
p                    </Grid> */}
                              <Grid item xs={6} md={3}>
                                <Select
                                  options={airportsOptions}
                                  variant="secondary"
                                  label="Depart Airport"
                                  value={dApt}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.dApt
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.dApt
                                  }
                                  onChange={(v) =>
                                    setFieldValue(
                                      `segments.${segmentIndex}.iSeg.${iSegIndex}.dApt`,
                                      v
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Select
                                  options={airportsOptions}
                                  variant="secondary"
                                  label="Arrival Airport"
                                  value={aApt}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.aApt
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.aApt
                                  }
                                  onChange={(v) =>
                                    setFieldValue(
                                      `segments.${segmentIndex}.iSeg.${iSegIndex}.aApt`,
                                      v
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  type="datetime-local"
                                  variant="secondary"
                                  label="Depart DateTime"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.departDateTime`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.departDateTime
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.departDateTime
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  type="datetime-local"
                                  variant="secondary"
                                  label="Arrival DateTime"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.arrivalDateTime`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.arrivalDateTime
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.arrivalDateTime
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  variant="secondary"
                                  label="Depart Terminal"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.dTer`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.dTer
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.dTer
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  variant="secondary"
                                  label="Arrival Terminal"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.aTer`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.aTer
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.aTer
                                  }
                                />
                              </Grid>

                              <Grid item xs={6} md={3}>
                                <Select
                                  options={airlinesOptions}
                                  variant="secondary"
                                  label="Airline"
                                  value={airlineNm}
                                  onChange={(v) =>
                                    setFieldValue(
                                      `segments.${segmentIndex}.iSeg.${iSegIndex}.airlineNm`,
                                      v
                                    )
                                  }
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.airlineNm
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.airlineNm
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  variant="secondary"
                                  label="Flight Number"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.fn`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.fn
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.fn
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Select
                                  options={bcOptions}
                                  variant="secondary"
                                  label="Booking Class"
                                  value={bc}
                                  onChange={(v) =>
                                    setFieldValue(
                                      `segments.${segmentIndex}.iSeg.${iSegIndex}.bc`,
                                      v
                                    )
                                  }
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.bc
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.bc
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={3} container>
                                <Grid item xs={6}>
                                  <div className="d-flex align-items-end">
                                    <Input
                                      {...getFieldProps(
                                        `segments.${segmentIndex}.iSeg.${iSegIndex}.baggageAllowanceKG`
                                      )}
                                      errorMessage={
                                        errors.segments?.[segmentIndex]?.iSeg?.[
                                          iSegIndex
                                        ]?.baggageAllowanceKG
                                      }
                                      showErrors={
                                        touched.segments?.[segmentIndex]
                                          ?.iSeg?.[iSegIndex]
                                          ?.baggageAllowanceKG
                                      }
                                      placeholder="KGs"
                                      variant="secondary"
                                      label="Baggage Allowance"
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="d-flex align-items-end h-100">
                                    <Select
                                      variant="secondary"
                                      className="ml-3"
                                      placeholder="PC"
                                      options={[
                                        { value: "None", label: "None" },
                                        { value: "1PC", label: "1PC" },
                                        { value: "2PC", label: "2PC" },
                                        { value: "3PC", label: "3PC" },
                                        { value: "4PC", label: "4PC" },
                                      ]}
                                      onChange={(value) =>
                                        setFieldValue(
                                          `segments.${segmentIndex}.iSeg.${iSegIndex}.baggageAllowancePC`,
                                          value
                                        )
                                      }
                                      value={baggageAllowancePC}
                                      errorMessage={
                                        errors.segments?.[segmentIndex]?.iSeg?.[
                                          iSegIndex
                                        ]?.baggageAllowancePC
                                      }
                                      showErrors={
                                        touched.segments?.[segmentIndex]?.[
                                          iSegIndex
                                        ]?.baggageAllowancePC
                                      }
                                      horizontal
                                      fixedLabelWidth
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <Input
                                  variant="secondary"
                                  label="Flight Duration (In Minutes)"
                                  {...getFieldProps(
                                    `segments.${segmentIndex}.iSeg.${iSegIndex}.segmentDuration`
                                  )}
                                  errorMessage={
                                    errors.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.segmentDuration
                                  }
                                  showErrors={
                                    touched.segments?.[segmentIndex]?.iSeg?.[
                                      iSegIndex
                                    ]?.segmentDuration
                                  }
                                />
                              </Grid>
                            </Grid>

                            {length > 1 && (
                              <button
                                type="button"
                                title="Remove Flight"
                                onClick={() =>
                                  removeField({ segmentIndex, iSegIndex })
                                }
                                className="btn btn-icon btn-secondary btn-hover-light-danger btn-sm my-auto mx-3"
                              >
                                <span
                                  title="Remove Flight"
                                  className="svg-icon svg-icon-sm svg-icon-danger"
                                >
                                  <SVG src="/media/svg/icons/General/cancel.svg" />
                                </span>
                              </button>
                            )}
                          </div>
                          {iSegIndex < length - 1 && (
                            <Seperator className="my-9" />
                          )}
                        </div>
                      )
                    )}
                    <Button
                      className="mt-5"
                      onClick={() => addSearch(segmentIndex)}
                      variant="danger"
                      smallPadding
                      type="button"
                    >
                      + Add another Flight
                    </Button>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          {/* </Paper> */}
        </Grid>

        <Grid item xs={12}>
          <Paper className="p-7">
            <Typography variant="h5" className="mb-5">
              Price Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("abf")}
                  showErrors={touched?.abf}
                  errorMessage={errors?.abf}
                  variant="secondary"
                  label={`Base Fare per adult (${adult})`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("atx")}
                  showErrors={touched?.atx}
                  errorMessage={errors?.atx}
                  variant="secondary"
                  label={`Tax per adult (${adult})`}
                />
              </Grid>
              {!!child && (
                <>
                  <Grid item xs={12} md={6}>
                    <Input
                      {...getFieldProps("cbf")}
                      showErrors={touched?.cbf}
                      errorMessage={errors?.cbf}
                      variant="secondary"
                      label={`Base Fare per child (${child})`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      {...getFieldProps("ctx")}
                      showErrors={touched?.ctx}
                      errorMessage={errors?.ctx}
                      variant="secondary"
                      label={`Tax per child (${child})`}
                    />
                  </Grid>
                </>
              )}
              {!!infant && (
                <>
                  <Grid item xs={12} md={6}>
                    <Input
                      {...getFieldProps("ibf")}
                      showErrors={touched?.ibf}
                      errorMessage={errors?.ibf}
                      variant="secondary"
                      label={`Base Fare per infant (${infant})`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      {...getFieldProps("itx")}
                      showErrors={touched?.itx}
                      errorMessage={errors?.itx}
                      variant="secondary"
                      label={`Tax per child (${infant})`}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <Input
                  {...getFieldProps("markup")}
                  showErrors={touched?.markup}
                  errorMessage={errors?.markup}
                  variant="secondary"
                  label="Markup"
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Typography>Supplier Price</Typography>
                <Typography className="font-weight-bold mt-3">
                  {supplierAmt?.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Total Price</Typography>
                <Typography className="font-weight-bold mt-3">
                  {totalAmt?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-center">
          <Button>Submit</Button>
        </Paper>
      </Grid> */}
        <Grid item xs={12}>
          <Paper className="p-7 d-flex flex-column">
            <Button
              type="submit"
              loading={loading}
              className="ml-auto mr-auto my-10 w-50 "
            >
              {isReissue ? "Submit" : "Next"}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
