import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Button, Input, Seperator } from "../../common";
import { toAbsoluteUrl } from "../../../../_helpers";
import { OperationLog } from "../../account/accountSubUser/SubUser";
import { getDateTime } from "../../../../_helpers/date";
import { actions } from "../../../../../redux/state/bookingMng";
import { VoidPending } from "../../../../../redux/state/bookingMng/bookingMngConstants";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import {
  CardWithBlueHeader,
  DropDownButton,
} from "../../ticketSummary/TicketDetails";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { FlightDetailsCard } from "../../fareSearch/DapartingFlights";
import { RulesTabs } from "../../fareSearch/FlightDetails";
import { AcceptVoidModal } from "./void/AcceptVoidModal";
import { RejectVoidModal } from "./void/RejectVoidModal";
import { pax_types_text } from "../../../../../redux/state/booking/bookingConstants";
import { PassengersList } from "./issuedOrderDetails/orderSummary/PassengersList";
import { ContactInfo } from "./issuedOrderDetails/orderSummary/ContactInfo";
import { InfoTabs } from "./issuedOrderDetails/orderSummary/OrderSummary";
import useAirlinesList from "../../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../../app/helpers/hooks/useAirportsList";
import useCompanyName from "../../../../../app/helpers/hooks/useCompanyName";
import { OrderSummary } from "./issuedOrderDetails/orderSummary/OrderSummary";

export const VoidDetails = () => {
  const dispatch = useDispatch();
  const { getCompanyName } = useCompanyName();
  const [isReject, setIsReject] = useState(false);
  const { id } = useParams();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const { data, loading: getVoidDataLoading } =
    useSelector(({ bookingMng }) => bookingMng.specificVoid) || {};
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const {
    transaction,
    createdAt,
    status,
    flymeFee,
    cancellationFee,
    voidAmount,
  } = data || {};
  const { passengers, orderNo, segment, agencyId } = transaction || {};
  const agencyName = getCompanyName(agencyId);
  const { date, time } = getDateTime(createdAt);
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  const {
    loading: sendEmailLoading,
    failure: sendEmailFailure,
    success: sendEmailSuccess,
  } = useSelector(({ bookingMng }) => bookingMng.sendEmail);
  useEffect(() => {
    dispatch(actions.getSpecificVoid({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, []);
  const personalSchema = Yup.object().shape({
    to: Yup.string().required("Field is required"),
    cc: Yup.string(),
    bcc: Yup.string(),
    pnr: Yup.string().required("Field is required"),
  });
  const formik = useFormik({
    initialValues: {
      to: "",
      cc: "",
      bcc: "",
      pnr: "",
    },
    validationSchema: personalSchema,
    onSubmit: ({ to, pnr }) => {
      dispatch(actions.sendVoidEmail({ email: to, pnr, id }));
    },
  });

  const { handleSubmit, getFieldProps, touched, errors } = formik;
  const leadGuest = passengers?.$values?.filter(
    ({ isLeadGuest }) => isLeadGuest === true
  )?.[0];
  useEffect(() => {
    if (sendEmailSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Email sent to supplier",
        })
      );
      dispatch(actions.clearSendEmail());
      setOpenEmailModal(false);
    }
  }, [sendEmailSuccess]);

  useEffect(() => {
    if (sendEmailFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(actions.clearSendEmail());
    }
  }, [sendEmailFailure]);

  return (
    <Grid container spacing={3}>
      {!isReject && (
        <AcceptVoidModal
          show={openAcceptModal}
          onHide={() => setOpenAcceptModal(false)}
        />
      )}
      {isReject && (
        <RejectVoidModal
          show={openRejectModal}
          onHide={() => setOpenRejectModal(false)}
        />
      )}
      <Modal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="bg-white rounded"
          style={{ maxWidth: "600px", flexGrow: 1, overflow: "hidden" }}
        >
          <div className="bg-primary p-7">
            <Typography variant="h5" className="font-weight-bolder text-white">
              Mailing Process
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit}
            className="py-7 px-20"
            style={{ color: "#464E5F" }}
          >
            <Input
              spacing
              showErrors={touched.to}
              errorMessage={errors.to}
              {...getFieldProps("to")}
              name="to"
              fixedLabelWidthSmall
              horizontal
              label="To"
              placeholder="Type here"
              required
            />
            <Input
              spacing
              showErrors={touched.cc}
              errorMessage={errors.cc}
              {...getFieldProps("cc")}
              name="cc"
              fixedLabelWidthSmall
              horizontal
              label="CC"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={touched.bcc}
              errorMessage={errors.bcc}
              {...getFieldProps("bcc")}
              name="bcc"
              fixedLabelWidthSmall
              horizontal
              label="Bcc"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={touched.pnr}
              errorMessage={errors.pnr}
              {...getFieldProps("pnr")}
              name="pnr"
              fixedLabelWidthSmall
              horizontal
              label="PNR"
              placeholder="Type here"
              required
            />
            <div className="d-flex justify-content-center mt-7 mb-3">
              <Button
                onClick={() => setOpenEmailModal(false)}
                // className="btn bg-gray-200 w-100px"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button loading={sendEmailLoading} type="submit" className="ml-7">
                Send
              </Button>
            </div>
          </form>
        </Paper>
      </Modal>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex ">
          {isAdmin && (
            <>
              {status === VoidPending && (
                <>
                  <Button
                    smallPadding
                    className="d-flex align-items-center mr-3"
                    // variant="primary-inverse"
                    onClick={() => {
                      setIsReject(false);
                      setOpenAcceptModal(true);
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    smallPadding
                    className="d-flex align-items-center mr-3"
                    variant="primary-inverse"
                    onClick={() => {
                      setIsReject(true);
                      setOpenRejectModal(true);
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}
              <Button
                smallPadding
                className="d-flex align-items-center mr-3 ml-auto"
                variant="primary-inverse"
                onClick={() => setOpenEmailModal(true)}
              >
                <SVG
                  className="mr-2"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/message-blue.svg"
                  )}
                />
                Send
              </Button>
            </>
          )}

          {/* <DropDownButton
            buttonClassName="border-primary btn-white text-primary text-hover-light-primary"
            icon={}
            // className="mx-5"
            title="Send"
          /> */}
          <DropDownButton
            // options={manageOptions}
            buttonClassName="btn-danger"
            title="Download"
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <OrderSummary data={data?.transaction} loading={getVoidDataLoading} />
      </Grid>
    </Grid>
  );
};
