import React from "react";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { useSelector } from "react-redux";
import { OrderPriceCard } from "../orderSummary/OrderPriceCard";

export const AccountsSummary = () => {
  const invoice =
    useSelector(({ invoices }) => invoices.specificInvoice.data);
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};

  return (
    <CardWithBlueHeader title="Summary">
      <OrderPriceCard invoice={invoice} isBookingDetails data={data} />
    </CardWithBlueHeader>
  );
};
