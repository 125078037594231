import { put, takeEvery, delay } from "@redux-saga/core/effects";
import { actions } from "../state/flightSearch";

function* GetSearch({ payload }) {
  const { GeneralInfo, ...params } = payload.args;
  const GUID = payload.data;
  yield put(
    actions.getSearchResults({
      ...params,
      GeneralInfo: { ...GeneralInfo, GUID },
    })
  );
}

export function* watchSearchFlightRequest() {
  yield takeEvery(actions.SEARCH_FLIGHTS.SUCCEEDED, GetSearch);
}

function* GetSearchPnr({ payload }) {
  const params = payload.data;
  yield put(
    actions.getSearchResults({
      ...params,
    })
  );
}

export function* watchPnrSearchFlightRequest() {
  yield takeEvery(actions.SEARCH_FLIGHTS_PNR.SUCCEEDED, GetSearchPnr);
}

function* completeSearch({ payload }) {
  if (!payload.data.isComplete) {
    yield delay(1000);
    yield put(actions.getSearchResults(payload.args));
  } else yield;
}

export function* watchSearchFlightComplete() {
  yield takeEvery(actions.GET_SEARCH_RESULTS.SUCCEEDED, completeSearch);
}
