import countriesData from "../../../../data/countries.json";
import countryCodes from "../../../../data/countryCodes.json";
import {
  creditAgreementLabelMap,
  creditAgreementTypeMap,
} from "../../../../redux/state/company/companyConstants";
import countries from "../../../../_metronic/layout/components/fareSearch/countries.data.json";

export const bookingNotesOptions = [
  { label: "General", value: "General" },
  { label: "Payments", value: "Payments" },
  { label: "Void", value: "Void" },
  { label: "Refund", value: "Refund" },
  { label: "Reissue", value: "Reissue" },
];

export const cabinClassOptions = [
  { label: "Economy", value: "Economy" },
  { label: "Premium Economy", value: "Premium Economy" },
  { label: "Business", value: "Business" },
  { label: "First", value: "First" },
];

export const reissueReasonsOptions = [
  { label: "Voluntary Reissue", value: "Voluntary Reissue" },
  { label: "Involuntary Reissue", value: "Involuntary Reissue" },
];

export const creditTypeOptions = [
  {
    value: creditAgreementTypeMap.oneday,
    label: creditAgreementLabelMap[creditAgreementTypeMap.oneday],
  },
  {
    value: creditAgreementTypeMap.twodays,
    label: creditAgreementLabelMap[creditAgreementTypeMap.twodays],
  },
  {
    value: creditAgreementTypeMap.week,
    label: creditAgreementLabelMap[creditAgreementTypeMap.week],
  },
];

export const getBookingClassOptions = () => {
  const bookingClassOptions = [];
  for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
    const char = String.fromCharCode(i);
    bookingClassOptions.push({ label: char, value: char });
  }
  return bookingClassOptions;
};

export const getCountriesOptions = () =>
  Object.keys(countriesData).map((country) => ({
    label: country,
    value: country,
  }));

export const currencyOptions = [
  { label: "USD", value: "$" },
  // { label: "EUR", value: "eur" },
  // { label: "GDP", value: "gdp" },
];

export const getCountryAbbrvCode = (countryName) => {
  const country = countries.filter(({ name }) => name === countryName)[0];
  return country ? country.code : countryName;
};

export const getCitiesOptions = (countryName) =>
  countriesData[countryName]?.map((city) => ({
    label: city,
    value: city,
  })) || [];

export const getCountryCode = (country) =>
  countryCodes.filter(({ name }) => name === country)[0]?.dial_code;

export const countryCodesOptions = countryCodes.map(({ dial_code }) => ({
  value: dial_code,
  label: dial_code,
}));
