import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toAbsoluteUrl } from "../../../_helpers";
import { TextFieldContainer } from "../common";

export const Faq = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const content = Array(5)
    .fill(0)
    .map(() => (
      <ExpansionPanel style={{ boxShadow: "none" }} elevation={0}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
          style={{ boxShadow: "none" }}
        >
          <Typography className="font-weight-bold" variant="h6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </span>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));
  return (
    <Grid container spacing={3} justify="space-between" alignItems="stretch">
      <Grid xs={12} item>
        <Paper className="p-7 d-flex align-items-center">
          <div>
            <Typography className="font-weight-bold mb-2" variant="h5">
              Help center search
            </Typography>
            <Typography variant="body1">Serach you question</Typography>
            <TextFieldContainer
              vertical
              // showErrors={formData.touched.email}
              // errorMessage={formData.errors.email}
              // inputProps={formData.getFieldProps("email")}
              placeholder="Search goods"
              variant="secondary"
              noErrorMessage
            />
          </div>
          {matchesSm && (
            <img
              className="ml-auto w-200px"
              src={toAbsoluteUrl("/media/svg/illustrations/fileSearch.svg")}
              alt=""
            />
          )}
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 ">
          <Typography className="font-weight-bolder mb-5" variant="h5">
            frequently asked question
          </Typography>
          <div>{content}</div>
        </Paper>
      </Grid>
    </Grid>
  );
};
