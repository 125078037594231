import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/package";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { PackageTypesForm } from "./PackageTypesForm";
import { PackageTypesRow } from "./PackageTypesRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";

const pkgTypeSchema = Yup.object().shape({
  pkgTypeName: Yup.string().required("Package Type name is required"),
  // country: Yup.string().required("country is required"),
});

export const PackageTypes = ({ permissionId }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showPkgTypeForm, setShowPkgTypeForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(
    ({ packages }) => packages.allPkgTypes
  );
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ packages }) => packages.deletePkgType);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ packages }) => packages.createPkgType);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ packages }) => packages.editPkgType);
  const fields = useFormik({
    initialValues: {
      pkgTypeName: "",
    },
    validationSchema: pkgTypeSchema,
    onSubmit: ({ pkgTypeName }) => {
      if (!!selectedId) {
        const data = getSelectedPkgType(selectedId);
        dispatch(
          actions.editPkgType({
            ...data,
            name: pkgTypeName,
          })
        );
      } else {
        dispatch(actions.createPkgType({ name: pkgTypeName }));
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllPkgTypes());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowPkgTypeForm(false);
    dispatch(actions.clearCreatePkgType());
    dispatch(actions.clearEditPkgType());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Package Type Added",
        })
      );
      dispatch(actions.getAllPkgTypes());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreatePkgType());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Package Type Edited",
        })
      );
      dispatch(actions.getAllPkgTypes());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeletePkgType());
    setSelectedId(undefined);
  }, [dispatch]);
  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(actions.clearEditPkgType());
  //   }
  // }, [dispatch, editSuccess]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deletePkgType({ id: selectedId }));
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeletePkgType());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { name } = getSelectedPkgType(id);
    fields.setValues({ pkgTypeName: name });
    setShowPkgTypeForm(true);
  };
  const getSelectedPkgType = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );

  const tableContent = list?.map((item, index) => (
    <PackageTypesRow
      key={index}
      // subPermissions={subPermissions}
      data={item}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Package Types</Typography>
            <button
              // hidden={!subPermissions.includes(allCitiesSubPermission.add.id)}
              onClick={() => setShowPkgTypeForm(true)}
              className="btn btn-danger"
            >
              Add package type
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete package type"
            body="Are you sure you want to delete this package type?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
          />
          {/* <PkgTypeForm
            onHide={() => setShowEdit(false)}
            show={showEdit}
            data={getSelectBooking()}
            success={editSuccess}
            loading={editLoading}
            fields={fields}
          /> */}
          <PackageTypesForm
            onHide={hideFormModal}
            show={showPkgTypeForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Id</th>
                      <th>Name</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Types to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
