import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

import { TopUpEditHeader } from "./TopUpEditHeader";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../../redux/state/companyCredit";
import { Button } from "../../../../common";
import { getDateTime } from "../../../../../../_helpers/date";
import { actions as inlineNotificationActions } from "../../../../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const CustomerEditSchema = Yup.object().shape({
  verificationRemark: Yup.string().nullable(),
});

export const TopUpEditModal = ({
  isReject,
  show,
  data,
  onHide,
  getCompanyName,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const { success, loading, failure } = useSelector(
    ({ companyCredit }) => companyCredit.creditAction
  );

  const fields = useFormik({
    enableReinitialize: true,
    initialValues: { verificationRemark: "" },
    validationSchema: CustomerEditSchema,
    onSubmit: ({ status, verificationRemark }) => {
      if (isReject) {
        dispatch(actions.rejectCompanyCredit({ id }));
      } else {
        dispatch(actions.approveCompanyCredit({ id }));
      }
    },
  });
  const { resetForm, handleSubmit } = fields;
  const handleClose = useCallback(() => {
    onHide();
    resetForm();
  }, [resetForm, onHide]);
  useEffect(() => {
    if (success) {
      onSuccess();
      handleClose();
    }
  }, [dispatch, handleClose, onSuccess, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearEdit());
    }
  }, [dispatch, handleClose, onHide, failure]);

  const { createdAt, creditAmount, remarks, agencyId, id } = data || {};
  const { date, time } = getDateTime(createdAt);

  return (
    <Modal
      size="md"
      onHide={handleClose}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <TopUpEditHeader
          title={isReject ? "Reject Credit Request" : "Approve Credit Request"}
          loading={loading}
        />
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Request ID</Typography>
              <Typography>{id}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Amount</Typography>
              <Typography>${creditAmount}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Requested Date
              </Typography>
              <Typography>{date}</Typography>
              <Typography>{time}</Typography>
            </Grid>
            <Grid item xs={12} md>
              <Typography className="mb-3 text-muted">Agency Name</Typography>
              <Typography>{getCompanyName(agencyId)}</Typography>
            </Grid>
            <Grid item xs={12} md>
              <Typography className="mb-3 text-muted">Remarks</Typography>
              <Typography>{remarks}</Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Input
                {...getFieldProps("verificationRemark")}
                showErrors={touched["verificationRemark"]}
                errorMessage={errors["verificationRemark"]}
                variant="secondary"
                label="Verification Remark"
              />
            </Grid> */}
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={handleClose}
            variant="light"
          >
            Cancel
          </Button>
          <> </>
          <Button smallPadding loading={loading} type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
