export const orderFilterTypesMap = {
  ticketTracking: "TicketTracking",
  reissue: "ReIssue",
  cancel: "Cancel",
  refund: "Refund",
};

export const sortTypesMap = {
  noSort: 0,
  ascending: 1,
  descending: 2,
};

export const MODULE_NAME = "bookingManagement";
export const PNR = "PNR";
export const BOOKING_DATE = "bookingDate";
export const TRANSACTION_STATUS = "TransactionStatus";
export const BOOKING_STATUS = "BookingStatus";
export const TICKET_NO = "ticketno";
export const ORDER_NO = "orderno";
export const AGENCY = "agency"; //
export const SUPPLIER = "supplier"; //

export const ALL = "All";

export const RefundQuotationRequest = "QuotationRequest";
export const RefundQuotationCompleted = "QuotationCompleted";
export const RefundRejected = "RefundRejected";
export const RefundApplied = "RefundApplied";
export const RefundProcessing = "RefundProcessing";
export const RefundApproved = "RefundApproved";
export const RefundWithdraw = "RefundWithdraw";
export const RefundCompleted = "RefundCompleted";

export const ReIssueApplied = "ReIssueApplied";
export const PendingPayments = "PendingPayments";
export const ReIssueProcessing = "ReIssueProcessing";
export const ReIssueCompleted = "ReIssueCompleted";
export const ReissueRejected = "ReissueRejected";
export const ReIssueFailed = "ReIssueFailed";
export const ReissueAgencyRejected = "ReissueAgencyRejected";

export const TransactionHold = "Hold"; // Reserved
export const TransactionPending = "Hold"; //Pending
export const TransactionProcessing = "Processing";
export const TransactionCancelled = "Cancelled";
export const TransactionCompleted = "Completed"; // Issued
export const TransactionRefund = "Refund";
export const TransactionReIssue = "ReIssue";

export const TransactionIssued = "Issued"; // Issued

export const VoidPending = "Pending";
export const VoidCompleted = "PendingCompleted"; //Accepted
export const VoidRejected = "RejectionRecord"; //Rejected
