import { useMemo } from "react";
import usePackageTypesList from "./usePackageTypesList";

export default function usePkgTypesOptions() {
  const { list, loading } = usePackageTypesList();
  const pkgTypesOptions = useMemo(
    () =>
      list?.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [list]
  );
  return { pkgTypesOptions, loading };
}
