import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { TemplateForm } from "../../../_metronic/layout/components/admin/systemManagement/tempates/TemplateForm";
import { Templates } from "../../../_metronic/layout/components/admin/systemManagement/tempates/Templates";
import { ViewTemplate } from "../../../_metronic/layout/components/admin/systemManagement/tempates/ViewTemplate";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  ADMIN_CREATE_TEMPLATE,
  ADMIN_EDIT_TEMPLATE,
  ADMIN_TEMPLATES,
  ADMIN_VIEW_TEMPLATE,
} from "../../routesMapAdmin";

export const TemplatesPage = ({ permissionKey, permissionId }) => {
  return (
    <Switch>
      <Route exact path={ADMIN_VIEW_TEMPLATE} component={ViewTemplate} />

      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.addTemplate",
        ])}
        exact
        path={ADMIN_CREATE_TEMPLATE}
        component={TemplateForm}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.editTemplate",
        ])}
        exact
        path={ADMIN_EDIT_TEMPLATE}
        render={() => <TemplateForm isEdit={true} />}
      />
      <Route
        exact
        path={ADMIN_TEMPLATES}
        render={(props) => <Templates permissionId={permissionId} {...props} />}
      />
    </Switch>
  );
};
