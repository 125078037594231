import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./inlineNotificationActions";

const initialState = {
  type:undefined,
  message: undefined,
  isShow: undefined,
};

export const inlineNotificationReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.SHOW:
        state.type = payload.type;
        state.message = payload.message;
        state.isShow = true;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
