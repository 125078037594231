import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./billsActions";

const initialState = {
  bills: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificBill: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const billsReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_ALL.REQUESTED:
        state.bills.loading = true;
        break;
      case actions.GET_ALL.SUCCEEDED:
        state.bills.loading = false;
        state.bills.success = true;
        const { data, ...rest } = payload.data.data;
        state.bills.list = data;
        state.bills.paginationInfo = rest;
        break;
      case actions.GET_ALL.FAILED:
        state.bills.loading = false;
        state.bills.failure = payload.error;
        break;
      case actions.GET_ONE.REQUESTED:
        state.specificBill.loading = true;
        break;
      case actions.GET_ONE.SUCCEEDED:
        state.specificBill.loading = false;
        state.specificBill.success = true;
        state.specificBill.data = payload.data.data;
        break;
      case actions.GET_ONE.FAILED:
        state.specificBill.loading = false;
        state.specificBill.failure = payload.error;
        break;
      case actions.GET_ONE.CLEAR:
        state.specificBill = initialState.specificBill;
        break;
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
