import { Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SVG from "react-inlinesvg";

import { parseRoute } from "../../../../../../app/helpers/formatters";
import {
  ADMIN_CREATE_TEMPLATE,
  ADMIN_EDIT_TEMPLATE,
  ADMIN_VIEW_TEMPLATE,
} from "../../../../../../app/routesMapAdmin";

import { actions } from "../../../../../../redux/state/templates";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/templates";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { Button, Input, LoadingSpinner, MenuComponent } from "../../../common";
import { NavLink } from "react-router-dom";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onView, onEdit, onDelete, subPermissions }
) => {
  const allTemplatesSubPermission =
    adminPermissionsIds.systemManagement.templates.subPermissions;
  const menuOptions = [
    // { label: "View", onClick: () => onView(row.id) },
    {
      label: "Edit",
      onClick: () => onEdit(row.id),
      hidden: !subPermissions.includes(
        allTemplatesSubPermission.editTemplate.id
      ),
    },
    {
      label: "Delete",
      onClick: () => onDelete(row.id),
      hidden: !subPermissions.includes(
        allTemplatesSubPermission.deleteTemplate.id
      ),
    },
  ];
  return <MenuComponent options={menuOptions} />;
};

export const Templates = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [entities, setEntities] = useState();
  const { list: templatesList, loading: templatesLoading } = useSelector(
    ({ templates }) => templates.templates
  );
  const allTemplatesSubPermission =
    adminPermissionsIds.systemManagement.templates.subPermissions;
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ templates }) => templates.delete);
  const { list: templateTypesList } = useSelector(
    ({ templates }) => templates.templateTypes
  );

  useEffect(() => {
    dispatch(actions.getTemplates());
    dispatch(actions.getTemplateTypes());
    return () => dispatch(actions.reset());
  }, [dispatch]);
  const columns = [
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    ...(matchesSm
      ? [
          {
            dataField: "type",
            text: "Category",
          },
          {
            dataField: "body",
            text: "Body",
          },
        ]
      : []),
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        onView: (id) => history.push(parseRoute(ADMIN_VIEW_TEMPLATE, { id })),
        onEdit: (id) => history.push(parseRoute(ADMIN_EDIT_TEMPLATE, { id })),
        onDelete: (id) => {
          setSelectedId(id);
          setShowModal(true);
        },
        subPermissions,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "70px",
      },
    },
  ];
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push(parseRoute(NOTIFICATION_DETAILS, { id: row.id }));
  //   },
  // };
  useEffect(() => {
    if (templatesList && templateTypesList) {
      const temp = templatesList.map(
        ({ id, notificationTypeId, subject, body, footer, title }) => {
          const { type } = templateTypesList?.filter(
            ({ id }) => id === notificationTypeId
          )[0];
          return {
            id,
            type,
            subject,
            body,
            footer,
            title,
          };
        }
      );
      setEntities(temp);
    }
  }, [templatesList, templateTypesList]);
  const onHide = () => {
    dispatch(actions.clearDelete());
    setShowModal(false);
    setSelectedId(undefined);
  };
  const deleteTemplate = () =>
    dispatch(actions.deleteTemplate({ id: selectedId }));
  const onFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDelete());
  };
  return (
    <Paper className="p-7">
      <div className=" d-flex align-items-center border-0 flex-wrap">
        <ConfirmationModal
          onHide={onHide}
          show={showModal}
          onConfirm={deleteTemplate}
          title="Delete Template"
          body="Are you sure you want to delete this template?"
          loading={deleteLoading}
          success={deleteSuccess}
          failure={deleteFailure}
          onFailure={onFailure}
        />
        <Input noErrorMessage className="max-w-500px mb-3" search />
        <div className="card-toolbar ml-auto">
          <Button
            variant="primary-inverse"
            className="font-weight-bolder mr-3"
            smallPadding
          >
            <span className="svg-icon svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} />
            </span>
            Export
          </Button>
          <NavLink
            hidden={
              !subPermissions.includes(allTemplatesSubPermission.addTemplate.id)
            }
            to={ADMIN_CREATE_TEMPLATE}
            className="btn btn-danger font-weight-bolder font-size-sm"
          >
            + Add new template
          </NavLink>
        </div>
      </div>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities || []}
        columns={columns}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}
        // selectRow={getSelectRow({
        //   entities: entities,
        //   ids: ids,
        //   setIds: setIds,
        // })}
        // rowEvents={rowEvents}
        // rowStyle={{ cursor: "pointer" }}
        // {...paginationTableProps}
      >
        {/* <PleaseWaitMessage entities={entities} />
            <NoRecordsFoundMessage entities={entities} /> */}
      </BootstrapTable>
      {templatesLoading && <LoadingSpinner />}
      {templatesList?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Templates to show
        </Typography>
      )}
    </Paper>
  );
};
