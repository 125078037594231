import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./bookingActions";
import { BookingHold } from "./bookingConstants";

const initialState = {
  bookFlight: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  bookingStatus: BookingHold,
  isManual: false,
  bookingParams: undefined,
};

export const bookingReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.ISSUE_HOLD_BOOKING.REQUESTED:
      case actions.PAY_REISSUE.REQUESTED:
      case actions.BOOK_FLIGHT.REQUESTED:
        state.bookFlight.loading = true;
        break;
      case actions.ISSUE_HOLD_BOOKING.SUCCEEDED:
      case actions.PAY_REISSUE.SUCCEEDED:
      case actions.BOOK_FLIGHT.SUCCEEDED:
        state.bookFlight.loading = false;
        if (payload.data.status === "200") {
          state.bookFlight.success = payload.data.message;
        } else {
          state.bookFlight.failure =
            payload?.data?.message ||
            payload?.error?.message ||
            "booking Failed";
        }

        break;
      case actions.ISSUE_HOLD_BOOKING.FAILED:
      case actions.PAY_REISSUE.FAILED:
      case actions.BOOK_FLIGHT.FAILED:
        state.bookFlight.loading = false;
        state.bookFlight.failure =
          payload.error?.response ||
          payload?.error?.message ||
          "Something is wrong";
        break;
      case actions.BOOK_FLIGHT.CLEAR:
        state.bookFlight = initialState.bookFlight;
        break;
      case actions.SET_BOOKING_STATUS:
        state.bookingStatus = payload.bookingType;
        state.isManual = payload.isManual || false;
        break;
      case actions.SET_BOOKING_PARAMS:
        state.bookingParams = payload;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
