import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button } from "../../../../common";
import {
  RefundApplied,
  RefundCompleted,
  RefundProcessing,
  RefundQuotationCompleted,
  RefundQuotationRequest,
} from "../../../../../../../redux/state/bookingMng/bookingMngConstants";
import { ConfirmationModal } from "../../../../bookingManagement/common/ConfirmationModal";
import { OfferQuotationModal } from "./OfferQuotationModal";
import { actions } from "../../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const confirmationMessageMap = {
  acceptRefund: "Are you sure you want to Accept this quotation?",
  withdrawRefund: "Are you sure you want to withdraw refund request?",
  startRefund:
    "Are you sure you want to start the refund process for this request?",
  completeRefund:
    "Once you click submit, agency will be refunded. Are you sure you want to complete the refund?",
};

const confirmationTitleMap = {
  acceptRefund: "Accept quotation",
  withdrawRefund: "Withdraw refund",
  startRefund: "Start refund process",
  completeRefund: "Complete refund",
};

export const RefundActions = ({ status }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [adminReject, setAdminReject] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [confirmationBody, setConfirmationBody] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { failure, success, loading } = useSelector(
    ({ bookingMng }) => bookingMng.refund
  );
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Success!",
        })
      );
      setOpenConfirmationModal(false);
      dispatch(actions.clearRefund());
      dispatch(actions.getSpecificRefund({ id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, dispatch]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearRefund());
    }
  }, [failure, dispatch]);
  const actionsMap = {
    acceptRefund: () => dispatch(actions.acceptRefundFromAgency({ id })),
    withdrawRefund: () => dispatch(actions.rejectRefundFromAgency({ id })),
    startRefund: () =>
      dispatch(
        actions.updateRefundStatusByAdmin({ status: RefundProcessing, id })
      ),
    completeRefund: () =>
      dispatch(
        actions.updateRefundStatusByAdmin({ status: RefundCompleted, id })
      ),
  };
  return (
    <>
      <ConfirmationModal
        body={confirmationBody}
        title={confirmationTitle}
        show={openConfirmationModal}
        onHide={() => setOpenConfirmationModal(false)}
        onConfirm={confirmationAction}
        loading={loading}
      />
      {!isAdmin && (
        <>
          {status === RefundQuotationCompleted && (
            <>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                // variant="primary-inverse"
                onClick={() => {
                  setOpenConfirmationModal(true);
                  setConfirmationBody(confirmationMessageMap.acceptRefund);
                  setConfirmationTitle(confirmationTitleMap.acceptRefund);
                  setConfirmationAction(() => actionsMap.acceptRefund);
                }}
              >
                Accept quotation
              </Button>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                variant="primary-inverse"
                onClick={() => {
                  setOpenConfirmationModal(true);
                  setConfirmationBody(confirmationMessageMap.withdrawRefund);
                  setConfirmationTitle(confirmationTitleMap.withdrawRefund);
                  setConfirmationAction(() => actionsMap.withdrawRefund);
                }}
              >
                Withdraw
              </Button>
            </>
          )}
        </>
      )}
      {isAdmin && (
        <>
          {status === RefundProcessing && (
            <Button
              smallPadding
              className="d-flex align-items-center mr-3"
              // variant="primary-inverse"
              onClick={() => {
                setOpenConfirmationModal(true);
                setConfirmationBody(confirmationMessageMap.completeRefund);
                setConfirmationTitle(confirmationTitleMap.completeRefund);
                setConfirmationAction(() => actionsMap.completeRefund);
              }}
            >
              Complete refund
            </Button>
          )}
          {status === RefundApplied && (
            <Button
              smallPadding
              className="d-flex align-items-center mr-3"
              // variant="primary-inverse"
              onClick={() => {
                setOpenConfirmationModal(true);
                setConfirmationBody(confirmationMessageMap.startRefund);
                setConfirmationTitle(confirmationTitleMap.startRefund);
                setConfirmationAction(() => actionsMap.startRefund);
              }}
            >
              Start refund process
            </Button>
          )}
          {status === RefundQuotationRequest && (
            <>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                // variant="primary-inverse"
                onClick={() => {
                  setOpenAcceptModal(true);
                }}
              >
                Offer quotation
              </Button>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                variant="primary-inverse"
                onClick={() => {
                  setOpenAcceptModal(true);
                  setAdminReject(true);
                }}
              >
                Reject
              </Button>
              <OfferQuotationModal
                show={openAcceptModal}
                onHide={() => {
                  setOpenAcceptModal(false);
                  setAdminReject(undefined);
                }}
                isReject={adminReject}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
