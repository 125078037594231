import React, { useState } from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import { BootstrapInput } from "../customInput/CustomInput";
import clsx from "clsx";

const dummyOptions = [
  { value: "option1", label: "option1" },
  { value: "option2", label: "option2" },
  { value: "option3", label: "option3" },
];

export const CustomSelect = ({
  id,
  label,
  placeholder,
  onChange,
  value = "",
  options = dummyOptions,
  horizontal,
  classNames,
  spacing,
  name,
  showErrors,
  errorMessage,
  noErrorMessage,
}) => {
  const [dummyValue, setDummyValue] = useState("");
  const handleChange = (event) => {
    setDummyValue(event.target.value);
  };
  const optionsContent = options.map(({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));
  const containerClassNames = clsx("d-flex w-100", {
    "justify-content-start flex-column": !horizontal,
    "align-items-center justify-content-between": horizontal,
    "mb-5": spacing,
  });
  const formClassNames = classNames || (horizontal ? "ml-5" : "mt-5");
  return (
    <div className={containerClassNames}>
      {label && (
        <span style={{ whiteSpace: "nowrap" }} htmlFor={id || label}>
          {label}
        </span>
      )}

      <div className="w-100">
        <FormControl
          style={{ flexGrow: 1 }}
          className={clsx(formClassNames, "w-100")}
        >
          <NativeSelect
            value={value || dummyValue}
            onChange={onChange || handleChange}
            input={<BootstrapInput name="age" id={id || label} />}
            name={name}
          >
            <option value="" disabled>
              {placeholder || "Select"}
            </option>
            {optionsContent}
          </NativeSelect>
        </FormControl>
        {!noErrorMessage && (
          <div className="fv-plugins-message-container h-20px ">
            <div className="fv-help-block">{showErrors && errorMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};
