import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { NotificationsWrapper } from "./NotificationsWrapper";
import { parseRoute } from "../../../../../app/helpers/formatters";
import { NOTIFICATION_DETAILS } from "../../../../../app/routesMap";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_NOTIFICATION_DETAILS } from "../../../../../app/routesMapAdmin";
import { notificationStatusMap } from "../../../../../redux/state/communication/communicationConstants";
import { actions } from "../../../../../redux/state/communication";
import { getTimeStamp } from "../../../../_helpers";

export const Notifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { list } = useSelector(
    ({ communication }) => communication.notifications
  );

  const columns = [
    {
      dataField: "subject",
      classes: "font-weight-bold",
    },
    // {
    //   dataField: "body",
    //   text: "",
    //   classes: "text-muted",
    // },
    {
      dataField: "createdAt",
      text: "",
      classes: "",
    },
  ];
  const entities = list?.map(
    ({ id, createdDate, subject, status, body }, index) => ({
      id,
      subject,
      status,
      body,
      createdAt: getTimeStamp(createdDate),
    })
  );
  const rowEvents = {
    onClick: (e, { id, status }, rowIndex) => {
      if (status === notificationStatusMap.unread) {
        dispatch(actions.updateNotificationStatus({ id }));
      }
      history.push(
        parseRoute(
          isAdmin ? ADMIN_NOTIFICATION_DETAILS : NOTIFICATION_DETAILS,
          { id }
        )
      );
    },
  };
  return (
    <NotificationsWrapper>
      {/* <div className="d-flex align-items-end flex-wrap">
        <button className="w-120px mr-3 btn btn-primary">All</button>
        <button className="w-120px mr-3 btn text-primary border-primary font-weight-bold">
          Read
        </button>
        <button className="w-120px mr-3 btn text-primary border-primary font-weight-bold">
          Unread
        </button>
        <button className="w-120px mr-5 btn  text-primary border-primary font-weight-bold ">
          Mark As Read
        </button>
        <div className="w-300px">
          <Input
            spacing
            outlined={false}
            noErrorMessage
            className="m-0"
            placeholder="Search"
          />
        </div>
        <button className="ml-3 w-150px btn btn-primary font-weight-bolder font-size-h6">
          Search
        </button>
      </div> */}
      <div className="position-relative">
        {/* <IconButton aria-label="Delete" className="position-absolute right-0">
          <DeleteIcon color="primary" />
        </IconButton>
        <Typography variant="body1" className="position-absolute ml-12 mt-4">
          Select All
        </Typography> */}
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="id"
          data={entities ?? []}
          columns={columns}
          // defaultSorted={uiHelpers.defaultSorted}
          // onTableChange={getHandlerTableChange(
          //   customersUIProps.setQueryParams
          // )}
          // selectRow={getSelectRow({
          //   entities: entities,
          //   ids: ids,
          //   setIds: setIds,
          // })}
          rowEvents={rowEvents}
          rowStyle={(row, rowIndex) => {
            const { status } = row;
            return {
              cursor: "pointer",
              background:
                status === notificationStatusMap.unread ? "#fff" : "#F6F6F6",
            };
          }}
          // {...paginationTableProps}
        />
      </div>
    </NotificationsWrapper>
  );
};
