import React, { useEffect, useState } from "react";
import { Grid, Paper, Tab } from "@material-ui/core";
import {
  matchPath,
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from "react-router";
import { useSelector } from "react-redux";
import {
  AGENCY_DOCUMENTS,
  AGENCY_SECURITY,
  AGENCY_SETTINGS,
} from "../../../../app/routesMap";
import { ClientInfoBar } from "../admin/client/clientDetails/ClientInfoBar";
import { AntTabs } from "../admin/client/clientDetails/ClientDetails";
import { parseRoute } from "../../../../app/helpers/formatters";
import { AgencySettings } from "./agencySettings/AgencySettings";
import { AgencyDocuments } from "./agencyDocuments/AgencyDocuments";
import { AgencySecurity } from "./agencySecurity/AgencySecurity";

const tabsRoutes = {
  Settings: AGENCY_SETTINGS,
  Security: AGENCY_SECURITY,
  // Documents: AGENCY_DOCUMENTS,
};

export const AgencyProfile = () => {
  const [selectedTab, setSelectedTab] = useState("Settings");
  const { id } = useSelector(({ company }) => company.companyData.data);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    Object.keys(tabsRoutes).forEach((key) => {
      const match = matchPath(location.pathname, {
        path: tabsRoutes[key],
        exact: true,
        strict: false,
      });
      if (match) setSelectedTab(key);
    });
  }, [location.pathname]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ClientInfoBar id={id} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => {
              history.push(parseRoute(tabsRoutes[newValue], { id }));
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
          >
            {Object.keys(tabsRoutes).map((key) => (
              <Tab value={key} key={key} label={key} />
            ))}
          </AntTabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route exact path={AGENCY_SETTINGS} component={AgencySettings} />
          <Route exact path={AGENCY_SECURITY} component={AgencySecurity} />
          {/* <Route exact path={AGENCY_DOCUMENTS} component={AgencyDocuments} /> */}
          <Redirect to={AGENCY_SETTINGS} />
        </Switch>
      </Grid>
    </Grid>
  );
};
