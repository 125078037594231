import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Button, Input, Select } from "../../../common";
import { useSelector } from "react-redux";

export const ChartOfAccountsForm = ({ fields, show, loading, onHide }) => {
  const { list: accountTypesList } = useSelector(
    ({ accounting }) => accounting.accountTypes
  );
  const accountTypesOptions = useMemo(
    () => accountTypesList?.map(({ id, name }) => ({ label: name, value: id })),
    [accountTypesList]
  );
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;

  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>Chart Of Account</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Input
                {...getFieldProps("accountName")}
                showErrors={touched["accountName"]}
                errorMessage={errors["accountName"]}
                variant="secondary"
                label="Account Name"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                {...getFieldProps("code")}
                showErrors={touched["code"]}
                errorMessage={errors["code"]}
                variant="secondary"
                label="Code"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                showErrors={touched["accountType"]}
                errorMessage={errors["accountType"]}
                value={values.accountType}
                onChange={(value) => setFieldValue("accountType", value)}
                variant="secondary"
                label="Type"
                options={accountTypesOptions}
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button loading={loading} smallPadding type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
