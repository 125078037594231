import React, { useEffect, useState } from "react";
import { Grid, Paper, withStyles, Tabs, Tab } from "@material-ui/core";
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
  Switch,
  Route,
  Redirect,
} from "react-router";
import {
  ADMIN_CLIENT_BILLING,
  ADMIN_CLIENT_DOCUMENTS,
  ADMIN_CLIENT_SECURITY,
  ADMIN_CLIENT_SETTINGS,
  ADMIN_CLIENT_USERS,
  ADMIN_CLIENT_USERS_CREATE,
  ADMIN_CLIENT_USER_PERMISSION,
  ADMIN_CLIENT_VIEW,
} from "../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { ClientView } from "./clientView/ClientView";
import { ClientSecurity } from "./clientSecutrity/ClientSecurity";
import { ClientBilling } from "./clientBilling/ClientBilling";
import { ClientDocuments } from "./clientDocuments/ClientDocuments";
import { ClientSettings } from "./clientSettings/ClientSettings";
import { ClientInfoBar } from "./ClientInfoBar";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../../redux/state/company";
import { ClientSubusers } from "./clientSubusers/ClientSubusers";
import { Permissions } from "../../../account/accountSubUser/Permissions";
import { CreateSubUserPage } from "../../../../../../app/pages/account";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

const tabsRoutes = {
  Dashboard: ADMIN_CLIENT_VIEW,
  Settings: ADMIN_CLIENT_SETTINGS,
  Billing: ADMIN_CLIENT_BILLING,
  Security: ADMIN_CLIENT_SECURITY,
  "Sub Users": ADMIN_CLIENT_USERS,
  // Documents: ADMIN_CLIENT_DOCUMENTS,
};

export const ClientDetails = () => {
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    Object.keys(tabsRoutes).forEach((key) => {
      const match = matchPath(location.pathname, {
        path: tabsRoutes[key],
        exact: true,
        strict: false,
      });
      if (match) setSelectedTab(key);
    });
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearSpecificCompany());
    };
  }, [dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ClientInfoBar id={id} />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => {
              history.push(parseRoute(tabsRoutes[newValue], { id }));
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
          >
            {Object.keys(tabsRoutes).map((key) => (
              <Tab value={key} key={key} label={key} />
            ))}
          </AntTabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route
            exact
            path={ADMIN_CLIENT_USER_PERMISSION}
            render={() => (
              <Permissions
                directLink={parseRoute(ADMIN_CLIENT_USERS, { id })}
              />
            )}
          />
          <Route
            exact
            path={ADMIN_CLIENT_USERS_CREATE}
            component={CreateSubUserPage}
          />
          <Route exact path={ADMIN_CLIENT_VIEW} component={ClientView} />
          <Route
            exact
            path={ADMIN_CLIENT_SECURITY}
            component={ClientSecurity}
          />
          <Route exact path={ADMIN_CLIENT_USERS} component={ClientSubusers} />
          <Route exact path={ADMIN_CLIENT_BILLING} component={ClientBilling} />
          {/* <Route
            exact
            path={ADMIN_CLIENT_DOCUMENTS}
            component={ClientDocuments}
          /> */}
          <Route
            exact
            path={ADMIN_CLIENT_SETTINGS}
            component={ClientSettings}
          />
          <Redirect to={ADMIN_CLIENT_VIEW} />
        </Switch>
      </Grid>
    </Grid>
  );
};
