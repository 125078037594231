import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Button, TextFieldContainer } from "../common";
import { Wrapper } from "./Wrapper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/auth";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";

const initialValues = {
  email: "",
};

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, success, failure } = useSelector(
    ({ auth }) => auth.requestPasswordReset
  );
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(),
  });

  const formFields = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: ({ email }) => {
      dispatch(actions.requestPasswordReset({ email }));
      // history.push(NEW_PASSWORD);
    },
  });
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message:
            "If your email is linked to a registered user, You should receive an email",
        })
      );
      dispatch(actions.reset());
      // formFields.resetForm();
    }
    // return () => {
    //   dispatch(actions.reset());
    // };
  }, [dispatch, formFields, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, failure]);
  return (
    <Wrapper>
      <div className="mb-10">
        <Typography
          variant="h5"
          className="font-weight-bolder text-dark text-white"
        >
          Reset your password
        </Typography>
      </div>
      <form onSubmit={formFields.handleSubmit}>
        <TextFieldContainer
          vertical
          label="Email  or User Name"
          showErrors={formFields.touched.email}
          errorMessage={formFields.errors.email}
          inputProps={formFields.getFieldProps("email")}
          variant="secondary"
        />

        <Button loading={loading} type="submit">
          Send
        </Button>
      </form>
    </Wrapper>
  );
};
