import React from "react";
// LayoutContext
// Import Layout components
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Header } from "./header/Header";

export function SubscriptionLayout({ children }) {
  return (
    <div className="d-flex flex-column flex-root">
      <HeaderMobile />
      <div
        className="d-flex flex-column flex-row-fluid wrapper"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Header fullWidth />
        <div className={`m-10`}>{children}</div>
      </div>
    </div>
  );
}
