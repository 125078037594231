import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import { parseRoute } from "../../../../../../app/helpers/formatters";
import {
  ADMIN_CREATE_SUPPLIER,
  ADMIN_EDIT_SUPPLIER,
  ADMIN_SUPPLIER_BALANCE_HISTORY,
} from "../../../../../../app/routesMapAdmin";
import { actions } from "../../../../../../redux/state/supplier";
import { Input, LoadingSpinner, MenuComponent } from "../../../common";
import { DeleteModal } from "../../components/deleteModal/DeleteModal";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { AllocateBalanceModal } from "./SupplierBalanceModal";
import clsx from "clsx";
import { ApiModal } from "./SupplierApiModal";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { SupplierSearchCard } from "./SupplierSearchCard";
import { Pagination } from "@material-ui/lab";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onAddBalance, onEdit, onDelete, onAddApiKey, subPermissions }
) => {
  const allSupplierSubPermission =
    adminPermissionsIds.systemManagement.suppliers.subPermissions;
  const menuOptions = [
    {
      label: "Edit",
      onClick: () => onEdit(row.id),
      hidden: !subPermissions.includes(
        allSupplierSubPermission.editSupplier.id
      ),
    },
    {
      label: "Add Balance",
      onClick: () => onAddBalance(row.id),
      hidden: !subPermissions.includes(allSupplierSubPermission.addBalance.id),
    },
    {
      label: "Add/Edit Api Key",
      onClick: () => onAddApiKey(row.id),
      hidden: !subPermissions.includes(allSupplierSubPermission.addApiKey.id),
    },
    // { label: "Delete", onClick: () => onDelete(row.id) },
  ];
  return <MenuComponent options={menuOptions} />;
};

export const SupplierList = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [showDelete, setShowDelete] = useState(false);
  const [showApiSettings, setShowApiSettings] = useState(false);
  const [showAllocateBalance, setShowAllocateBalance] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const dispatch = useDispatch();
  const { list, paginationInfo, loading } = useSelector(
    ({ supplier }) => supplier.filteredSuppliers
  );
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ supplier }) => supplier.delete);
  const { totalPages } = paginationInfo ?? {};

  // useEffect(() => {
  //   dispatch(actions.getAllSuppliers());
  //   return () => dispatch(actions.reset());
  // }, [dispatch]);
  const allSupplierSubPermission =
    adminPermissionsIds.systemManagement.suppliers.subPermissions;
  const columns = [
    {
      dataField: "name",
      text: "Supplier Name",
      formatter: (cellContent, { id }) => (
        <NavLink to={parseRoute(ADMIN_SUPPLIER_BALANCE_HISTORY, { id })}>
          {cellContent}
        </NavLink>
      ),
    },
    {
      dataField: "code",
      text: "Code",
    },
    ...(matchesSm
      ? [
          {
            dataField: "email",
            text: "Email Address",
          },
          {
            dataField: "contactNumber",
            text: "Phone",
          },
          {
            dataField: "country",
            text: "Country",
          },
          {
            dataField: "city",
            text: "City",
          },
          {
            dataField: "availableBalance",
            text: "Balance",
            // classes: "text-muted",
          },
          {
            dataField: "availableCredit",
            text: "Credit",
            // classes: "text-muted",
          },
          {
            dataField: "isActive",
            text: "Status",
            formatter: (cellContent) => (
              <span
                className={clsx({
                  "text-success": cellContent,
                  "text-danger": !cellContent,
                })}
              >
                {cellContent ? "Active" : "Inactive"}
              </span>
            ),
          },
        ]
      : []),
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        onAddBalance: (id) => onAddBalance(id),
        onEdit: (id) => history.push(parseRoute(ADMIN_EDIT_SUPPLIER, { id })),
        onDelete: (id) => onDelete(id),
        onAddApiKey: (id) => onApiSettings(id),
        subPermissions,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      // style: {
      //   minWidth: "70px",
      // },
    },
  ];
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     history.push(parseRoute(NOTIFICATION_DETAILS, { id: row.id }));
  //   },
  // };
  const entities = list?.map(
    ({
      id,
      email,
      name,
      contactNumber,
      country,
      city,
      availableBalance,
      availableCredit,
      isActive,
      code,
    }) => {
      return {
        id,
        email,
        name,
        contactNumber,
        country,
        city,
        availableBalance,
        availableCredit,
        isActive,
        code,
      };
    }
  );
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onAddBalance = (id) => {
    setShowAllocateBalance(true);
    setSelectedId(id);
  };
  const onApiSettings = (id) => {
    setShowApiSettings(true);
    setSelectedId(id);
  };
  const onSaveDelete = () => {
    dispatch(actions.deleteSupplier({ id: selectedId }));
  };
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Supplier was deleted successfully",
        })
      );
      dispatch(actions.clearDelete());
    }
  }, [deleteSuccess, dispatch]);
  useEffect(() => {
    if (deleteFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't delete Supplier",
        })
      );
      dispatch(actions.clearDelete());
    }
  }, [deleteFailure, dispatch]);
  const getSupplierDate = useCallback(
    (supplierId) => list?.filter(({ id }) => +id === +supplierId)?.[0],
    [list]
  );

  useEffect(() => {
    dispatch(
      actions.filterSuppliers({ pageNumber, filterParam: [...appliedFilters] })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);

  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterSuppliers({
        filterParam: [...filters],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.filterSuppliers({ pageNumber: 1 }));
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <SupplierSearchCard onSearch={search} onReset={reset} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className={clsx("d-flex align-items-center border-0 flex-wrap")}>
            {/* <Input spacing noErrorMessage className="max-w-500px" search /> */}
            <div
              hidden={
                !subPermissions.includes(
                  allSupplierSubPermission.addSupplier.id
                )
              }
              className="card-toolbar ml-auto"
            >
              {/* <Button
            variant="primary-inverse"
            className="font-weight-bolder mr-3"
            smallPadding
          >
            <span className="svg-icon svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} />
            </span>
            Export
          </Button> */}
              <NavLink
                to={ADMIN_CREATE_SUPPLIER}
                className="btn btn-danger font-weight-bolder font-size-sm"
              >
                + Add Supplier
              </NavLink>
            </div>
          </div>
          <ApiModal
            onHide={() => {
              setShowApiSettings(false);
              setSelectedId(undefined);
            }}
            show={showApiSettings}
            id={selectedId}
          />
          <AllocateBalanceModal
            onHide={() => {
              setShowAllocateBalance(false);
              setSelectedId(undefined);
            }}
            show={showAllocateBalance}
            supplierData={getSupplierDate(selectedId)}
          />
          <DeleteModal
            onHide={() => {
              setShowDelete(false);
              setSelectedId(undefined);
            }}
            show={showDelete}
            id={selectedId}
            onSave={onSaveDelete}
            itemName="Supplier"
            loading={deleteLoading}
            success={deleteSuccess}
          />
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            keyField="id"
            data={entities || []}
            columns={columns}
            // defaultSorted={uiHelpers.defaultSorted}
            // onTableChange={getHandlerTableChange(
            //   customersUIProps.setQueryParams
            // )}
            // selectRow={getSelectRow({
            //   entities: entities,
            //   ids: ids,
            //   setIds: setIds,
            // })}
            // rowEvents={rowEvents}
            rowStyle={{ cursor: "pointer" }}
            // {...paginationTableProps}
          >
            {/* <PleaseWaitMessage entities={entities} />
          <NoRecordsFoundMessage entities={entities} /> */}
          </BootstrapTable>
          {loading && <LoadingSpinner />}
          {list?.length === 0 && (
            <Typography
              variant="h5"
              className="text-center w-100 text-muted my-5"
            >
              No Supplier to show
            </Typography>
          )}
          <Pagination
            className="mx-auto mt-2"
            onChange={handlePageChange}
            page={pageNumber}
            count={totalPages}
            variant="outlined"
            shape="rounded"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
