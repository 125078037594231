import { takeEvery, put, call, race, delay } from "redux-saga/effects";
import * as calls from "../api";
import * as connectivityActions from "../state/connectivity/connectivityAction";

// worker Saga: will be fired on API_CALL_REQUESTED connectivity.actions
function* callApi(callName, args, timeoutMS) {
  try {
    const request = call(calls[callName], args);
    const { requestResult, requestTimeout } = yield race({
      requestResult: request,
      requestTimeout: delay(timeoutMS),
    });
    if (requestTimeout) {
      const error = new Error("Request timeout");
      error.connectivityError = true;
      return { error };
    }

    if (requestResult === null) {
      const error = new Error("Request failed");
      error.connectivityError = true;
      return { error };
    }

    return requestResult;
  } catch (error) {
    return {
      error: error?.response?.data || error || true,
    };
  }
}

function* startApiCall(action) {
  const { callName, timeoutMS } = action.payload;
  const args = { ...action.payload.args };
  yield put(
    connectivityActions.announceApiCall(action.payload.actions.REQUESTED, args)
  );
  const { data, error } = yield* callApi(callName, args, timeoutMS);

  if (error) {
    yield put(
      connectivityActions.failApiCall(
        action.payload.actions.FAILED,
        args,
        error
      )
    );
  } else {
    yield put(
      connectivityActions.fulfillApiCall(
        action.payload.actions.SUCCEEDED,
        args,
        data
      )
    );

    if (args.successCallback) {
      yield call(args.successCallback({ data }));
    }
  }
}

function* watchCallRequests() {
  yield takeEvery(connectivityActions.API_CALL_REQUESTED, startApiCall);
}

// single entry point to start all Sagas at once
export function* connectivitySaga() {
  yield watchCallRequests();
}
