import React from "react";
import { Route, Switch } from "react-router-dom";
import { AgencyOperation } from "../../../_metronic/layout/components/admin/accounting/operationDashboard/agencyOperation/AgencyOperation";
import { OperationDashboard } from "../../../_metronic/layout/components/admin/accounting/operationDashboard/OperationDashboard";
import { RouteDetails } from "../../../_metronic/layout/components/admin/accounting/operationDashboard/routeDetails/RouteDetails";
import {
  ADMIN_ACCOUNTING_OPERATION,
  ADMIN_ACCOUNTING_OPERATION_AGENCY,
  ADMIN_ACCOUNTING_OPERATION_ROUTE,
} from "../../routesMapAdmin";

export const OperationDashboardPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={ADMIN_ACCOUNTING_OPERATION_AGENCY}
        component={AgencyOperation}
      />
      <Route
        exact
        path={ADMIN_ACCOUNTING_OPERATION_ROUTE}
        component={RouteDetails}
      />
      <Route
        exact
        path={ADMIN_ACCOUNTING_OPERATION}
        component={OperationDashboard}
      />
    </Switch>
  );
};
