export const MODULE_NAME = "invoices";

export const INVOICES_PAGE_SIZE = 10;

export const filterOptions = {
  StartsWith: 1,
  EndsWith: 2,
  Contains: 3,
  DoesNotContain: 4,
  IsEmpty: 5,
  IsNotEmpty: 6,
  IsGreaterThan: 7,
  IsGreaterThanOrEqualTo: 8,
  IsLessThan: 9,
  IsLessThanOrEqualTo: 10,
  IsEqualTo: 11,
  IsNotEqualTo: 12,
  All: 13,
  Sum: 14,
  //GreaterThanAndLessThan=15,
};

export const invoiceTypeMap = {
  issued: "Issued",
  void: "Void",
  refund: "Refund",
  reissue: "ReIssued",
  cancelled: "Cancelled",
};

export const invoiceStatusMap = {
  paid: "Paid",
  unpaid: "UnPaid",
};

export const INVOICE_TYPE_ISSUED = "Issued";
export const INVOICE_TYPE_VOID = "Void";
export const INVOICE_TYPE_REFUND = "Refund";
export const INVOICE_TYPE_REISSUED = "ReIssued";
