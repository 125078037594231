import { isArray } from "lodash-es";

export const getObjectProperty = (propertyName, obj) => {
  var parts = propertyName.split("."),
    length = parts.length,
    i,
    property = obj;

  for (i = 0; i < length; i++) {
    property = property[parts[i]];
  }

  return property;
};

export const generateNestedObjectKey = (propertyArr) => {
  if (!isArray(propertyArr)) return "";
  return propertyArr.join(".");
};
