import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./companyCreditConstants";

export const GET_CREDIT_DETAILS_BY_COMPANY = requestActions(
  MODULE_NAME,
  "GET_CREDIT_DETAILS_BY_COMPANY"
);
export const GET_CREDIT_ALLOWANCE_BY_COMPANY = requestActions(
  MODULE_NAME,
  "GET_CREDIT_ALLOWANCE_BY_COMPANY"
);
export const CREATE_CREDIT_ALLOWANCE = requestActions(
  MODULE_NAME,
  "CREATE_CREDIT_ALLOWANCE"
);
export const EDIT_CREDIT_ALLOWANCE = requestActions(
  MODULE_NAME,
  "EDIT_CREDIT_ALLOWANCE"
);
export const ADD_COMPANY_CREDIT = requestActions(
  MODULE_NAME,
  "ADD_COMPANY_CREDIT"
);
export const GET_ِALL_COMPANY_CREDIT = requestActions(
  MODULE_NAME,
  "GET_ِALL_COMPANY_CREDIT"
);
export const GET_COMPANY_CREDIT = requestActions(
  MODULE_NAME,
  "GET_COMPANY_CREDIT"
);
export const APPROVE_CREDIT = requestActions(MODULE_NAME, "APPROVE_CREDIT");
export const REJECT_CREDIT = requestActions(MODULE_NAME, "REJECT_CREDIT");
export const RESET = `${MODULE_NAME}/RESET`;

export const getAllCompanyCredit = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "getAllCompanyCredit",
    { sortingParams, filterParam, pageNumber, pageSize },
    GET_ِALL_COMPANY_CREDIT
  );

export const getCompanyCredit = (params) =>
  requestApiCall("getCompanyCredit", params, GET_COMPANY_CREDIT);

export const rejectCompanyCredit = (params) =>
  requestApiCall("rejectCompanyCredit", params, REJECT_CREDIT);

export const approveCompanyCredit = (params) =>
  requestApiCall("approveCompanyCredit", params, APPROVE_CREDIT);

export const createCreditAllowance = (params) =>
  requestApiCall("createCreditAllowance", params, CREATE_CREDIT_ALLOWANCE);

export const editCreditAllowance = (params) =>
  requestApiCall("editCreditAllowance", params, EDIT_CREDIT_ALLOWANCE);

export const getCreditAllowanceByCompanyId = (params) =>
  requestApiCall(
    "getCreditAllowanceByCompanyId",
    params,
    GET_CREDIT_ALLOWANCE_BY_COMPANY
  );

export const getCreditDetailsByCompanyId = (params) =>
  requestApiCall(
    "getCreditDetailsByCompanyId",
    params,
    GET_CREDIT_DETAILS_BY_COMPANY
  );

export const addCompanyCredit = (params) =>
  requestApiCall("addCompanyCredit", params, ADD_COMPANY_CREDIT);

export const reset = () => ({ type: RESET });

export const clearCreateCreditAllowance = () => ({
  type: EDIT_CREDIT_ALLOWANCE.CLEAR,
});

export const clearAddCredit = () => ({ type: ADD_COMPANY_CREDIT.CLEAR });

export const clearCreditAction = () => ({ type: APPROVE_CREDIT.CLEAR });

export const clearEditCreditAllowance = () => ({
  type: CREATE_CREDIT_ALLOWANCE.CLEAR,
});
