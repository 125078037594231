import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Modal } from "react-bootstrap";
import { Button, Input } from "../../../common";

export const RejectVoidModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector(({ bookingMng }) => bookingMng.specificVoid);
  const {
    loading: rejectVoidLoading,
    success: rejectVoidSuccess,
    failure: rejectVoidFailure,
  } = useSelector(({ bookingMng }) => bookingMng.void);
  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: Yup.object().shape({
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: ({ note }) => {
      dispatch(actions.rejectVoid({ id: data.cancelToken, note }));
    },
  });
  useEffect(() => {
    if (rejectVoidSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Void is Rejected Successfully!",
        })
      );
      dispatch(actions.clearVoid());
      onHide();
      dispatch(actions.getSpecificVoid({ id: data.id }));
    }
  }, [rejectVoidSuccess, data, dispatch, history, onHide]);

  useEffect(() => {
    if (rejectVoidFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: rejectVoidFailure || "Something is wrong",
        })
      );
      dispatch(actions.clearVoid());
    }
  }, [rejectVoidFailure, dispatch]);
  const { touched, errors, handleSubmit, getFieldProps } = formik;
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>Reject Void</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Input
            {...getFieldProps("note")}
            showErrors={touched.note}
            errorMessage={errors.note}
            label="note"
            placeholder="note"
            spacing
          />
        </Modal.Body>
        <Modal.Footer>
          <Button smallPadding type="button" onClick={onHide} variant="light">
            Cancel
          </Button>
          <> </>
          <Button
            smallPadding
            type="submit"
            loading={rejectVoidLoading}
            // onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
