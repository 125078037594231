import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { RefundDetails } from "../../../_metronic/layout/components/admin/bookingMng/refund/RefundDetails";
import { RefundTracking } from "../../../_metronic/layout/components/bookingManagement";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  BOOKING_MANAGEMENT_REFUND,
  BOOKING_MANAGEMENT_REFUND_DETAILS,
} from "../../routesMap";

export const RefundPage = ({ permissionId, permissionKey }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  return (
    <Switch>
      {isAdmin && (
        <ContentRoute
          permissionKey={generateNestedObjectKey([
            permissionKey,
            "subPermissions.view",
          ])}
          exact
          path={BOOKING_MANAGEMENT_REFUND_DETAILS}
          render={(props) => (
            <RefundDetails {...props} permissionId={permissionId} />
          )}
        />
      )}
      {!isAdmin && (
        <Route
          exact
          path={BOOKING_MANAGEMENT_REFUND_DETAILS}
          component={RefundDetails}
        />
      )}
      <Route
        exact
        path={BOOKING_MANAGEMENT_REFUND}
        component={RefundTracking}
      />
    </Switch>
  );
};
