import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./markupConstants";

export const GET_COMPANY_MARKUPS = requestActions(
  MODULE_NAME,
  "GET_COMPANY_MARKUPS"
);
export const GET_ALL_MARKUPS = requestActions(MODULE_NAME, "GET_ALL_MARKUPS");
export const GET_SPECIFIC_MARKUP = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_MARKUP"
);
export const CREATE_MARKUP = requestActions(MODULE_NAME, "CREATE_MARKUP");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const DELETE = requestActions(MODULE_NAME, "DELETE");
export const RESET = `${MODULE_NAME}/RESET`;

export const createMarkup = (params) =>
  requestApiCall("createMarkup", params, CREATE_MARKUP);

export const editMarkup = (params) =>
  requestApiCall("editMarkup", params, EDIT);

export const deleteMarkup = (params) =>
  requestApiCall("deleteMarkup", params, DELETE);

export const getMarkupByCompany = (params) =>
  requestApiCall("getMarkupByCompany", params, GET_COMPANY_MARKUPS);

export const getSpecificMarkup = ({ id }) =>
  requestApiCall("getSpecificMarkup", { id }, GET_SPECIFIC_MARKUP);

export const getAllMarkups = (params) =>
  requestApiCall("getAllMarkups", params, GET_ALL_MARKUPS);

export const reset = () => ({ type: RESET });
