import { Typography, Switch, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Button, Input, LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/supplier";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { Modal } from "react-bootstrap";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const apiSchema = Yup.object().shape({
  // apiType: Yup.string().required("Field is required"),
  apiKey: Yup.string(),
  secretKey: Yup.string(),
  miscellaneous: Yup.string(),
});

export const ApiModal = ({ onHide, show, id }) => {
  const dispatch = useDispatch();
  const [hasApi, setHasApi] = useState(false);
  const {
    loading: editLoading,
    success: editSuccess,
    failure: editFailure,
  } = useSelector(({ supplier }) => supplier.edit);
  const {
    data,
    loading: getSupplierApiLoading,
    success: getSupplierApiSuccess,
  } = useSelector(({ supplier }) => supplier.supplierApi);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ supplier }) => supplier.create);
  useEffect(() => {
    if (show) {
      dispatch(actions.getSupplierApiBySupplierId({ supplierId: id }));
    }
  }, [dispatch, id, show]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(actions.getSupplierApiBySupplierId({ supplierId: id }));
    }
  }, [dispatch, id, createSuccess]);
  useEffect(() => {
    return () => {
      dispatch(actions.clearEdit());
      dispatch(actions.clearCreate());
      dispatch(actions.clearSupplierApi());
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      apiType: 0,
      apiKey: "",
      secretKey: "",
      miscellaneous: "",
      isActive: false,
    },
    validationSchema: apiSchema,
    onSubmit: ({ apiType, apiKey, secretKey, miscellaneous, isActive }) => {
      dispatch(
        actions.editSupplierApi({
          ...data,
          apiKey,
          secretKey,
          miscellaneous,
          isActive,
        })
      );
    },
  });
  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    resetForm,
    getFieldProps,
    setValues,
  } = formik;
  const setInitialValues = useCallback(() => {
    const { apiType, apiKey, secretKey, miscellaneous, isActive } = data || {};
    setValues({
      apiType: apiType || "",
      apiKey: apiKey || "",
      secretKey: secretKey || "",
      miscellaneous: miscellaneous || "",
      isActive: isActive || false,
    });
  }, [data, setValues]);
  const createSupplierApi = () => {
    dispatch(
      actions.createSupplierApi({
        supplierManagementInAPIManagementId: id,
        apiType: 0,
        apiKey: "",
        secretKey: "",
        miscellaneous: "",
        isActive: true,
      })
    );
  };
  useEffect(() => {
    setHasApi(!!data);
  }, [data]);
  useEffect(() => {
    if (getSupplierApiSuccess && data) {
      setInitialValues();
    }
  }, [data, getSupplierApiSuccess, setInitialValues]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Supplier Api updated successfully!",
        })
      );
      resetForm();
      onHide();
      dispatch(actions.getAllSuppliers());
    }
  }, [dispatch, resetForm, onHide, editSuccess]);
  useEffect(() => {
    if (editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't edit supplier api.",
        })
      );
      dispatch(actions.clearEdit());
    }
  }, [dispatch, editFailure]);
  return (
    <Modal size="md" onHide={onHide} show={show}>
      <>
        <Modal.Header>Supplier Api</Modal.Header>
        {getSupplierApiLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {hasApi ? (
              <>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Input
                        {...getFieldProps("apiKey")}
                        showErrors={touched["apiKey"]}
                        errorMessage={errors["apiKey"]}
                        variant="secondary"
                        label="Api Key"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        {...getFieldProps("secretKey")}
                        showErrors={touched["secretKey"]}
                        errorMessage={errors["secretKey"]}
                        variant="secondary"
                        label="Secret Key"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        {...getFieldProps("miscellaneous")}
                        showErrors={touched["miscellaneous"]}
                        errorMessage={errors["miscellaneous"]}
                        variant="secondary"
                        label="Miscellaneous"
                      />
                    </Grid>

                    <Grid
                      className="d-flex justify-content-start"
                      item
                      xs={12}
                      md={6}
                    >
                      <label className="d-flex flex-column align-items-center justify-content-between">
                        Active
                        <Switch
                          checked={values.isActive}
                          onChange={(e) =>
                            setFieldValue("isActive", e.target.checked)
                          }
                          color="primary"
                        />
                      </label>
                    </Grid>
                  </Grid>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="button" onClick={onHide} variant="secondary">
                    Cancel
                  </Button>
                  <> </>
                  <Button
                    loading={editLoading}
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <div className="text-center mt-5">
                <Typography variant="h5">
                  This supplier doesn't have an API!
                </Typography>
                <div className="d-flex justify-content-center mt-7 mb-3">
                  <Button variant="secondary" onClick={() => onHide(false)}>
                    Cancel
                  </Button>
                  <Button
                    loading={createLoading}
                    onClick={createSupplierApi}
                    className="ml-7"
                  >
                    Create Balance Account
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </Modal>
  );
};
