import React from "react";
import { Grid } from "@material-ui/core";
import { CompanySettings } from "../../../common/companySettings/CompanySettings";
import { useSelector } from "react-redux";

export const FlymeSettings = () => {
  const { id } = useSelector(({ company }) => company.companyData.data);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CompanySettings title="Edit Flyme Info" isCurrent id={id} />
      </Grid>
    </Grid>
  );
};
