import React from "react";
import {
  // FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
// import { Button, GreenCheckbox, Seperator, Input, Select } from "../../common";
// import {
//   getCitiesOptions,
//   getCountriesOptions,
// } from "../../../../_assets/js/components/options";
import { ClientInfoForm } from "./ClientInfoForm";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/company";
import { Button } from "../../common";
import { useState } from "react";
import { ConfirmationModal } from "../../bookingManagement/common/ConfirmationModal";
import { ORGANIZATION_TYPE_AGENCY } from "../../../../../redux/state/company/companyConstants";

export const ClientForm = ({
  onSubmitCompanyInfo,
  isEdit,
  id,
  title,
  loading,
  success,
  hasReset,
}) => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const emailCheckBoxes = [
  //   { label: "Invoice Payments", name: "invoicePayments" },
  //   { label: "Payouts", name: "payouts" },
  //   { label: "Refund Alerts", name: "refundAlerts" },
  //   { label: "Successful Payments", name: "successfulPayments" },
  //   { label: "Reissue Alerts", name: "reissueAlerts" },
  //   { label: "Fee Collection", name: "feeCancellation" },
  //   { label: "Allocated Funds", name: "allocatedFunds" },
  // ];
  // const notificationCheckBoxes = [
  //   { label: "Notifications", name: "notifications" },
  //   { label: "Billing Updates", name: "billingUpdates" },
  //   { label: "New Team Members", name: "newTeamMembers" },
  //   { label: "Completed Tickets", name: "completeTickets" },
  //   { label: "Payments Summary", name: "paymentsSummary" },
  // ];
  const { data: companyData } = useSelector(
    ({ company }) => company.specificCompanyData
  );
  const {
    success: editCompanySuccess,
    loading: editCompanyLoading,
    failure: editCompanyFailure,
  } = useSelector(({ company }) => company.editSpecificCompany);
  const resetPin = () => {
    dispatch(actions.editSpecificCompany({ ...companyData, pinNumber: null }));
  };
  const hideModal = () => {
    setShowConfirmationModal(false);
    dispatch(actions.clearEdit());
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="bg-white h-100 p-7">
          <ConfirmationModal
            title="Reset Pin"
            onHide={hideModal}
            onConfirm={resetPin}
            loading={editCompanyLoading}
            failure={editCompanyFailure}
            success={editCompanySuccess}
            show={showConfirmationModal}
          >
            <span>Are you sure you want to reset PIN for </span>
            <span className="font-weight-bold">{companyData?.name}</span>
          </ConfirmationModal>
          <div className="d-flex justify-content-between mb-10">
            <Typography variant="h5">{title}</Typography>
            {hasReset && (
              <Button onClick={() => setShowConfirmationModal(true)}>
                Reset Pin
              </Button>
            )}
          </div>
          <ClientInfoForm
            loading={loading}
            id={id}
            isEdit={isEdit}
            outlined={true}
            onSubmit={onSubmitCompanyInfo}
            success={success}
            companyType={ORGANIZATION_TYPE_AGENCY}
          />
        </Paper>
      </Grid>
      {/* {isEdit && (
        <>
          <Grid item xs={12}>
            <Paper>
              <Typography variant="h5" className="p-3">
                Email Preferences
              </Typography>
              <Seperator className="m-0" />
              <div className="d-flex flex-column px-7 py-3">
                {emailCheckBoxes.map(({ label, name }) => (
                  <FormControlLabel
                    // className="ml-8"
                    control={
                      <GreenCheckbox
                        // checked={state.checkedA}
                        // onChange={handleChange("checkedA")}
                        value="checkedA"
                      />
                    }
                    label={label}
                  />
                ))}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Typography variant="h5" className="p-3">
                Notifications
              </Typography>
              <Seperator className="m-0" />
              <div className="d-flex flex-column px-7 py-3">
                {notificationCheckBoxes.map(({ label, name }) => (
                  <div className="d-flex justify-content-between">
                    <Typography variant="body1" className="p-3">
                      {label}
                    </Typography>
                    <div>
                      <FormControlLabel
                        // className="ml-8"
                        control={
                          <GreenCheckbox
                            // checked={state.checkedA}
                            // onChange={handleChange("checkedA")}
                            value="checkedA"
                          />
                        }
                        label="email"
                      />
                      <FormControlLabel
                        // className="ml-8"
                        control={
                          <GreenCheckbox
                            // checked={state.checkedA}
                            // onChange={handleChange("checkedA")}
                            value="checkedA"
                          />
                        }
                        label="phone"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>
        </>
      )} */}
    </Grid>
  );
};
