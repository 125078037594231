import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { IssuedOrderDetails } from "../../../_metronic/layout/components/admin/bookingMng/issuedOrderDetails/IssuedOrderDetails";
import { IssueHoldBooking } from "../../../_metronic/layout/components/bookingManagement/IssueHoldBooking";
import { Tracking } from "../../../_metronic/layout/components/bookingManagement/Tracking";
import { Refund } from "../../../_metronic/layout/components/ticketSummary/refund/Refund";
import { Reissue } from "../../../_metronic/layout/components/ticketSummary/reissue/Reissue";
import { TicketDetails } from "../../../_metronic/layout/components/ticketSummary/TicketDetails";
import { Void } from "../../../_metronic/layout/components/ticketSummary/void/Void";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  BOOKING_MANAGEMENT_TRACKING,
  ISSUE_BOOKING,
  ISSUE_BOOKING_SUCCESS,
  TICKET_DETAILS,
  TICKET_DETAILS_REFUND,
  TICKET_DETAILS_REISSUE,
  TICKET_DETAILS_VOID,
} from "../../routesMap";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../routesMapAdmin";
import { OrderConfirmedPage } from "../flightSearch";

export const TrackingPage = ({ permissionId, permissionKey }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  return (
    <Switch>
      <Route exact path={ISSUE_BOOKING} render={() => <IssueHoldBooking />} />
      <Route
        exact
        path={ISSUE_BOOKING_SUCCESS}
        render={() => <OrderConfirmedPage issueHoldBooking={true} />}
      />
      {!isAdmin && (
        <Switch>
          <Route exact path={TICKET_DETAILS} component={TicketDetails} />
          <Route exact path={TICKET_DETAILS} component={TicketDetails} />
          <Route exact path={TICKET_DETAILS_VOID} component={Void} />
          <Route exact path={TICKET_DETAILS_REFUND} component={Refund} />
          <Route exact path={TICKET_DETAILS_REISSUE} component={Reissue} />
          <Route
            exact
            path={BOOKING_MANAGEMENT_TRACKING}
            component={Tracking}
          />
        </Switch>
      )}
      {isAdmin && (
        <Switch>
          <Route
            exact
            path={BOOKING_MANAGEMENT_TRACKING}
            component={Tracking}
          />
          <ContentRoute
            permissionKey={generateNestedObjectKey([
              permissionKey,
              "subPermissions.view",
            ])}
            path={ADMIN_COMPLETED_ORDER_DETAILS}
            render={(props) => (
              <IssuedOrderDetails {...props} permissionId={permissionId} />
            )}
          />
        </Switch>
      )}
    </Switch>
  );
};
