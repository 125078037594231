import objectPath from "object-path";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";

export function Topbar() {
  const uiService = useHtmlClassService();
  const { subscription } = useSelector(({ company }) => company.companyData);
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

      {/* {!subscription && !subscription?.isActive ? ( */}
      <UserNotificationsDropdown />
      {/* ) : null} */}

      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {/* {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      <div
        className="topbar-item"
        // data-toggle="tooltip"
        title="Quick panel"
        // data-placement="right"
      >
        <div
          className="btn btn-icon btn-clean btn-lg mr-1"
          // id="kt_quick_panel_toggle"
        >
          <span className="svg-icon svg-icon-lg svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/levels.svg")}
            />
          </span>
        </div>
      </div>
      <div
        className="topbar-item"
        // data-toggle="tooltip"
        title="Quick panel"
        // data-placement="right"
      >
        <div
          className="btn btn-icon btn-clean btn-lg mr-1"
          // id="kt_quick_panel_toggle"
        >
          <span className="svg-icon svg-icon-lg svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}
            />
          </span>
        </div>
      </div>
      <div
        className="topbar-item"
        // data-toggle="tooltip"
        title="Quick panel"
        // data-placement="right"
      >
        <div
          className="btn btn-icon btn-clean btn-lg mr-1"
          // id="kt_quick_panel_toggle"
        >
          <span className="svg-icon svg-icon-lg svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/profile.svg")}
            />
          </span>
        </div>
      </div> */}

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
