import { SearchResultCard } from "./SearchResultCard";
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FormControl,
  InputBase,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core/";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { parseRoute } from "../../../../app/helpers/formatters";
import { FLIGHT } from "../../../../app/routesMap";
import { actions as bookingActions } from "../../../../redux/state/booking";
import { actions as bookingMngActions } from "../../../../redux/state/bookingMng";
import { actions as flightSearchActions } from "../../../../redux/state/flightSearch";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { toAbsoluteUrl } from "../../../_helpers";
import {
  calcTimeDiff,
  convertMinToHrs,
  formatTo12Hrs,
} from "../../../_helpers/time";
import styles from "./FlightSearch.module.scss";

import useAgencyPaymentEligibility from "../../../../app/helpers/hooks/useAgencyPaymentEligibility";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import {
  BookingHold,
  BookingIssue,
  ManualBooking,
} from "../../../../redux/state/booking/bookingConstants";
import { NOTIFICATION_TYPE_ERROR } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { checkForAlphabets } from "../../../_helpers/string";
import { LoadingSpinner, Seperator } from "../common";
import { ResultsFilterShimmers } from "./departingFlights/ResultsFilterShimmers";

const BootstrapInput = withStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    // minWidth: 120,
  },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
  input: {
    borderRadius: 20,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #3798D3",
    fontSize: 16,
    fontWeight: 500,
    padding: "10px 40px 10px 20px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 20,
      borderColor: "#3798D3",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const maybePluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count > 1 ? suffix : ""}`;

export const DepartingFlights = ({ isReissue, getReissueParams }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getAgencyPaymentEligibility } = useAgencyPaymentEligibility();
  const [selectState, setSelectState] = useState({
    company: "default",
    sort: "default",
    stops: "default",
    bookingStatus: BookingHold,
  });
  const [filteredAirlines, setFilteredAirlines] = useState({
    original: [],
    current: [],
  });
  const [paginatedList, setPaginatedList] = useState([]);
  const [page, setPage] = useState();
  const { data: companyData } = useSelector(
    ({ company }) => company.companyData
  );
  const { pinNumber } = companyData || {};
  const { airlines, complete: searchComplete, searchRQ } = useSelector(
    ({ flightSearch }) => flightSearch.searchResults
  );

  const { current: currentFilteredList } = filteredAirlines;

  const { airlinesList } = useAirlinesList();

  const availableAirlinesMap = useMemo(() => {
    const availableAirlinesMap = new Map();
    if (airlines?.length && airlinesList?.length && searchComplete) {
      for (let i = 0; i < airlinesList?.length; i++) {
        const { code } = airlinesList[i];
        airlines.forEach((airline) =>
          airline.segments.forEach(({ iSeg }) =>
            iSeg.forEach(({ airlineNm }) => {
              if (airlineNm === code) {
                if (availableAirlinesMap.has(airlineNm)) {
                  const temp = availableAirlinesMap.get(airlineNm);
                  if (!temp.includes(airline)) {
                    availableAirlinesMap.set(airlineNm, [...temp, airline]);
                  }
                } else {
                  availableAirlinesMap.set(airlineNm, [airline]);
                }
              }
            })
          )
        );
      }
    }
    return availableAirlinesMap;
  }, [airlines, airlinesList, searchComplete]);

  const availableStopsMap = useMemo(() => {
    const availableStopsMap = new Map();
    if (filteredAirlines?.original?.length && searchComplete) {
      filteredAirlines.original.forEach((airline) =>
        airline.segments.forEach(({ stop }) => {
          if (availableStopsMap.has(stop)) {
            const temp = availableStopsMap.get(stop);
            if (!temp.includes(airline)) {
              availableStopsMap.set(stop, [...temp, airline]);
            }
          } else {
            availableStopsMap.set(stop, [airline]);
          }
        })
      );
    }
    return availableStopsMap;
  }, [filteredAirlines.original, searchComplete]);
  const stopsOptions = useMemo(() => {
    const stopsOptions = [];
    if (searchComplete) {
      for (const key of availableStopsMap.keys()) {
        let label = "Direct";
        if (+key) {
          label = `${maybePluralize(+key, "stop")}`;
        }
        // const label =  ? "Direct" : ;
        stopsOptions.push({
          value: key,
          label: label,
        });
      }
    }
    return stopsOptions;
  }, [availableStopsMap, searchComplete]);

  const stopsSelectItems = stopsOptions?.map(({ label, value }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

  const availableAirlinesList = useMemo(() => {
    const availableAirlinesList = [];
    if (airlinesList?.length && searchComplete) {
      for (const key of availableAirlinesMap.keys()) {
        const airline = airlinesList.filter(({ code }) => code === key)[0];
        availableAirlinesList.push(airline);
      }
    }
    return availableAirlinesList;
  }, [airlinesList, availableAirlinesMap, searchComplete]);

  const airlinesOptions = availableAirlinesList?.map(({ code, name }) => (
    <MenuItem key={code} value={code}>
      {name}
    </MenuItem>
  ));
  useEffect(() => {
    if (searchComplete) {
      setSelectState((prevSelectState) => ({
        ...prevSelectState,
        sort: "ascending",
        company: "default",
      }));
      setFilteredAirlines({ current: [...airlines], original: [...airlines] });
      setFilteredAirlines((prevFilteredAirlines) => {
        const sortedAirlines = prevFilteredAirlines.current?.sort(
          (a, b) => +a.totalAmt - +b.totalAmt
        );
        return {
          current: sortedAirlines,
          original: [...airlines],
        };
      });
    } else {
      setFilteredAirlines({ current: [...airlines], original: [...airlines] });
    }
  }, [airlines, searchComplete]);
  useEffect(() => {
    if (searchComplete) {
      setSelectState((prevSelectState) => ({
        ...prevSelectState,
        sort: "ascending",
        stops: "default",
      }));
      if (selectState.company === "all" || selectState.company === "default")
        setFilteredAirlines({
          current: [...airlines],
          original: [...airlines],
        });
      else
        setFilteredAirlines({
          current: availableAirlinesMap.get(selectState.company),
          original: availableAirlinesMap.get(selectState.company),
        });
    }
  }, [airlines, availableAirlinesMap, searchComplete, selectState.company]);
  useEffect(() => {
    if (searchComplete) {
      if (selectState.sort === "all") {
        setFilteredAirlines((prevFilteredAirlines) => ({
          current: prevFilteredAirlines.original,
          original: prevFilteredAirlines.original,
        }));
      } else if (selectState.sort === "ascending") {
        setFilteredAirlines((prevFilteredAirlines) => {
          let temp = [...prevFilteredAirlines.current];
          temp = temp?.sort((a, b) => +a.totalAmt - +b.totalAmt);
          return {
            ...prevFilteredAirlines,
            current: temp,
          };
        });
      } else if (selectState.sort === "descending") {
        setFilteredAirlines((prevFilteredAirlines) => {
          let temp = [...prevFilteredAirlines.current];
          temp = temp?.sort((a, b) => +a.totalAmt - +b.totalAmt).reverse();
          return {
            ...prevFilteredAirlines,
            current: temp,
          };
        });
      }
    }
  }, [
    searchComplete,
    selectState.sort,
    selectState.company,
    selectState.stops,
  ]);
  useEffect(() => {
    if (searchComplete) {
      if (selectState.stops === "all") {
        setFilteredAirlines({
          current: filteredAirlines.original,
          original: filteredAirlines.original,
        });
      } else if (selectState.stops !== "default") {
        setFilteredAirlines({
          current: availableStopsMap.get(selectState.stops),
          original: filteredAirlines.original,
        });
      }
    }
  }, [
    availableStopsMap,
    filteredAirlines.original,
    searchComplete,
    selectState.stops,
  ]);
  useEffect(() => {
    if (currentFilteredList?.length) {
      setPage(1);
      setPaginatedList(currentFilteredList?.slice(0, 10));
    }
  }, [currentFilteredList]);

  const handleLoadMore = () => {
    const temp = currentFilteredList?.slice(0, page * 10);
    setPaginatedList(temp);
    setPage((prevPage) => prevPage + 1);
  };

  const hasMorePages = useMemo(() => {
    if (currentFilteredList?.length) {
      return currentFilteredList?.length > page * 10;
    }
    return false;
  }, [currentFilteredList, page]);

  console.log({ paginatedList, currentFilteredList });

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const handleChange = (e) => {
    setSelectState({ ...selectState, [e.target.name]: e.target.value });
  };

  const continueBooking = (id, price) => {
    const isPaymentEligible = getAgencyPaymentEligibility(price);
    if (selectState.bookingStatus === BookingIssue && !pinNumber) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message:
            "You need to create Pin Code first to be able to issue tickets.",
        })
      );
    }
    // else if (
    //   selectState.bookingStatus === BookingIssue &&
    //   !isPaymentEligible
    // ) {
    //   dispatch(
    //     inlineNotificationActions.setInlineNotification({
    //       type: NOTIFICATION_TYPE_ERROR,
    //       message: "You don't have enough Balance or Credit to book and issue",
    //     })
    //   );
    // }
    else {
      dispatch(flightSearchActions.setSelectedAirline({ id }));
      dispatch(
        bookingActions.setBookingStatus({
          bookingType:
            selectState.bookingStatus === ManualBooking
              ? BookingHold
              : selectState.bookingStatus,
          isManual: selectState.bookingStatus === ManualBooking,
        })
      );
      // const path = isAdmin ? ADMIN_FLIGHT : FLIGHT;
      history.push(
        parseRoute(FLIGHT, { id, guid: searchRQ?.generalInfo?.guid })
      );
    }
  };
  const reissueOrder = (flightId) => {
    const selectedFlight = airlines.filter(({ id }) => id === flightId)[0];
    const { prevTransactionId, params } = getReissueParams(selectedFlight);
    dispatch(bookingMngActions.requestReissue({ prevTransactionId, params }));
  };

  const content = paginatedList?.map((item, index) => {
    return (
      <SearchResultCard
        key={`row${index}`}
        data={item}
        isReissue={isReissue}
        reissueOrder={reissueOrder}
        handleChange={handleChange}
        continueBooking={continueBooking}
        selectState={selectState}
      />
    );
  });
  return (
    <Paper className="">
      {!searchComplete && <LinearProgress />}
      <div className="px-7 py-3">
        <div
          className={clsx(
            "d-flex align-items-start justify-content-between border-0 pt-5 pb-0",
            { "flex-column": !matchesSm }
          )}
        >
          <Typography variant="h5" className="my-auto">
            <span className={"svg-icon svg-icon-2 mr-4 svg-icon-primary"}>
              <SVG src="/media/svg/icons/General/plane.svg" />
            </span>
            <span className="card-label font-weight-bold font-size-3 mb-1">
              Best departing flights
            </span>
          </Typography>
          {!isReissue && (
            <div className={clsx({ "mt-3 ml-auto": !matchesSm })}>
              {!searchComplete ? (
                <ResultsFilterShimmers />
              ) : (
                <div className={styles.inputWrapper}>
                  <FormControl>
                    <Select
                      IconComponent={() => (
                        <span
                          className={
                            "svg-icon svg-icon-2 position-absolute mr-4 svg-icon-dark"
                          }
                          style={{ right: "5px" }}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Angle-down.svg"
                            )}
                          />
                        </span>
                      )}
                      value={selectState.company}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      name="company"
                    >
                      <MenuItem value="default" disabled>
                        Air line
                      </MenuItem>
                      <MenuItem value="all">All</MenuItem>
                      {airlinesOptions}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      IconComponent={() => (
                        <span
                          className={
                            "svg-icon svg-icon-2 position-absolute mr-4 svg-icon-dark"
                          }
                          style={{ right: "5px" }}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Angle-down.svg"
                            )}
                          />
                        </span>
                      )}
                      value={selectState.sort}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      name="sort"
                    >
                      <MenuItem value="default" disabled>
                        Sort by
                      </MenuItem>
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="descending">Price: high to low</MenuItem>
                      <MenuItem value="ascending">Price: low to high</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      IconComponent={() => (
                        <span
                          className={
                            "svg-icon svg-icon-2 position-absolute mr-4 svg-icon-dark"
                          }
                          style={{ right: "5px" }}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Angle-down.svg"
                            )}
                          />
                        </span>
                      )}
                      value={selectState.stops}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      name="stops"
                    >
                      <MenuItem value="default" disabled>
                        Stops
                      </MenuItem>
                      <MenuItem value="all">All</MenuItem>
                      {stopsSelectItems}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          )}
        </div>

        {Array.isArray(airlines) && (
          <div className={styles.flightsCard}>
            {searchComplete && airlines?.length === 0 ? (
              <Typography
                variant="h5"
                className="text-center w-100 text-muted my-5"
              >
                No flights found
              </Typography>
            ) : (
              content
            )}
          </div>
        )}
        {!searchComplete && <LoadingSpinner />}
        <div className="d-flex justify-content-center">
          {hasMorePages && (
            <button
              className="btn btn-primary btn-shadow mt-5"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </Paper>
  );
};

export const FlightDetailsCard = ({
  segments,
  cabinClass,
  airlinesList,
  airportsList,
  padding,
  gds,
}) => {
  const getAirlineInfo = (airlineCode) => {
    const currAirline = airlinesList?.filter(
      ({ code }) => airlineCode === code
    )[0];
    return currAirline;
  };
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const getAirportName = (airportCode) =>
    airportsList?.filter(({ code }) => airportCode === code)?.[0]?.name;
  // const { cabinClass, segments } = airline;
  return (
    <div className={clsx({ "p-7": padding })}>
      {segments?.map(({ iSeg, totalDuration, fareName, airCraft }, index) => (
        <div key={`segment${index}`}>
          {index > 0 && (
            <>
              <Seperator />
              <Seperator />
            </>
          )}
          {iSeg?.map(
            (
              {
                dApt,
                dTime,
                segmentDuration,
                fn,
                ma,
                aTime,
                aApt,
                bc,
                fba,
                dDat,
                aDat,
                airlineNm,
              },
              index
            ) => (
              <div
                className={clsx({
                  "max-w-100": !matchesSm,
                  "min-w-400px": matchesSm,
                })}
                key={`iseg${index}`}
              >
                <div className="d-flex align-items-center justify-content-start">
                  <div
                    className={clsx("symbol symbol-45px mr-5 mb-8", {
                      "w-25px h-25px": !matchesSm,
                    })}
                  >
                    <img
                      src={
                        getAirlineInfo(airlineNm)?.logo ||
                        toAbsoluteUrl(
                          "/media/svg/icons/General/plane-default.svg"
                        )
                      }
                      className="h-100 w-100"
                      // onError={(e) => {
                      //   e.target.onerror = null;
                      //   e.target.src = toAbsoluteUrl(
                      //     "/media/svg/icons/General/plane-default.svg"
                      //   );
                      // }}
                      alt=""
                    />
                  </div>
                  <div>
                    <div
                      className={`d-flex flex-column ${styles.specialBorder} h-100px justify-content-between`}
                    >
                      <span className="text-dark  font-size-6 mr-2">
                        <span className="font-weight-bolder">
                          {dDat} {formatTo12Hrs(dTime)}
                        </span>{" "}
                        {getAirportName(dApt) || dApt}
                      </span>
                      <span className="text-muted font-weight-bold text-muted d-block ">
                        Travel time:
                        {/* {calcTimeDiff(dDat, aDat, dTime, aTime)} */}
                        {convertMinToHrs(segmentDuration)}
                      </span>
                      <span className="text-dark  font-size-6 mr-2">
                        <span className="font-weight-bolder">
                          {aDat} {formatTo12Hrs(aTime)}
                        </span>{" "}
                        {getAirportName(aApt) || aApt}
                      </span>
                    </div>
                    <div className="d-flex">
                      <li className="d-flex align-items-center py-2 ml-2">
                        <span className="bullet bullet-dot bg-primary mr-2"></span>
                        <span className="text-primary">
                          {cabinClass} {fareName ? `(${fareName})` : null}
                        </span>
                      </li>
                      <li className="d-flex align-items-center py-2 ml-2">
                        <span className="bullet bullet-dot bg-primary mr-2"></span>
                        <span className="text-primary">{fba}</span>
                      </li>
                      <li className="d-flex align-items-center py-2 ml-2">
                        <span className="bullet bullet-dot bg-primary mr-2"></span>
                        <span className="text-primary">Class type {bc}</span>
                      </li>
                      <li className="d-flex align-items-center py-2 ml-2">
                        <span className="bullet bullet-dot bg-primary mr-2"></span>
                        <span className="text-primary">
                          {checkForAlphabets(fn) ? fn : `${ma}${fn}`}
                        </span>
                      </li>
                    </div>
                  </div>
                </div>
                {index < iSeg.length - 1 && (
                  <>
                    <Seperator />
                    <div>
                      <div className="separator border-4 my-5" />
                      <span className="label label-light-warning mr-3">!</span>
                      <span>
                        {calcTimeDiff(
                          aDat,
                          iSeg[index + 1].dDat,
                          aTime,
                          iSeg[index + 1].dTime
                        )}{" "}
                        {getAirportName(aApt) || aApt}
                      </span>
                      <div className="separator border-4 my-5" />
                    </div>
                    <Seperator />
                  </>
                )}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};
