import { connectivitySaga } from "./connectivitySaga";
import { watchDeleteUser } from "./deleteSage";
import {
  watchSearchFlightRequest,
  watchSearchFlightComplete,
  watchPnrSearchFlightRequest,
} from "./flightSaga";
import { watchLogout } from "./logoutSaga";

export const sagas = [
  connectivitySaga,
  watchPnrSearchFlightRequest,
  watchSearchFlightRequest,
  watchSearchFlightComplete,
  watchLogout,
  watchDeleteUser,
];
