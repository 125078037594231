export const HOME = "/home";
export const DASHBOARD = "/dashboard";
export const FARE_SEARCH = "/fareSearch";
export const FLIGHT_SEARCH = "/fareSearch/flightSearch";
export const PNR_SEARCH = "/fareSearch/pnr";
export const PACKAGE_SEARCH = "/fareSearch/package";
export const FLIGHT_BOOKING = "/fareSearch/flightSearch/book/:guid/:id";
export const FLIGHT = "/fareSearch/flightSearch/book/:guid/:id/flight";
export const TRAVELLER_DETAILS =
  "/fareSearch/flightSearch/book/:guid/:id/travellerDetails";
export const COMPLETE_ORDER =
  "/fareSearch/flightSearch/book/:guid/:id/completeOrder";
export const ORDER_CONFIRMED =
  "/fareSearch/flightSearch/book/:guid/:id/:type/confirmed";
export const PACKAGE_BOOKING = "/fareSearch/package/book/:id";
export const PACKAGE_BOOKING_DETAILS = "/fareSearch/package/book/:id/details";
export const PACKAGE_BOOKING_TRAVELER_DETAILS =
  "/fareSearch/package/book/:id/travelerDetails";
export const PACKAGE_BOOKING_PAYMENT = "/fareSearch/package/book/:id/payment";

export const AGENCY_PROFILE = "/account/agencyProfile";
export const AGENCY_SETTINGS = "/account/agencyProfile/settings";
export const AGENCY_SECURITY = "/account/agencyProfile/security";
export const AGENCY_DOCUMENTS = "/account/agencyProfile/documents";

export const TICKET_SUMMARY = "/ticketSummary";

export const TICKET_DETAILS = "/bookingManagement/tracking/:id";
export const TICKET_DETAILS_VOID = "/bookingManagement/tracking/:id/void";
export const TICKET_DETAILS_REFUND = "/bookingManagement/tracking/:id/refund";
export const TICKET_DETAILS_REISSUE = "/bookingManagement/tracking/:id/reissue";
export const BOOKING_MANAGEMENT = "/bookingManagement";
export const BOOKING_MANAGEMENT_TRACKING = "/bookingManagement/tracking";
export const BOOKING_MANAGEMENT_REFUND = "/bookingManagement/refunds";
export const BOOKING_MANAGEMENT_REFUND_DETAILS =
  "/bookingManagement/refunds/:id/details";
export const BOOKING_MANAGEMENT_VOID = "/bookingManagement/voids";
export const BOOKING_MANAGEMENT_VOID_DETAILS =
  "/bookingManagement/voids/:id/details";
export const BOOKING_MANAGEMENT_REISSUE = "/bookingManagement/reissue";
export const BOOKING_MANAGEMENT_REISSUE_DETAILS =
  "/bookingManagement/reissue/:id/details";
export const BOOKING_MANAGEMENT_REISSUE_PAY =
  "/bookingManagement/reissue/:id/pay";
export const ISSUE_BOOKING = "/bookingManagement/tracking/:id/issueBooking";
export const ISSUE_BOOKING_SUCCESS =
  "/bookingManagement/tracking/issueBookingSuccess";
export const BOOKING_MANAGEMENT_SEARCH = "/bookingManagement/search";
export const BOOKING_MANAGEMENT_PACKAGES = "/bookingManagement/packages";
export const BOOKING_MANAGEMENT_PACKAGE_DETAILS =
  "/bookingManagement/packages/:id";

export const ACCOUNT = "/account";
export const ACCOUNT_DETAILS = "/account/details";
export const ACCOUNT_SUB_USER_LIST = "/account/subusers";
export const ACCOUNT_SUB_USER = "/account/subusers/:userId";
export const CREATE_SUB_USER = "/account/subusers/create/:id";
export const SUB_USER_PERMISSION = "/account/subusers/permissions/:userId";
export const ACCOUNT_BALANCE = "/account/balance";
export const ACCOUNT_NOTIFICATION_ACCESS = "/account/notifications/access";
export const ACCOUNT_NOTIFICATION = "/account/notifications";
export const NOTIFICATION_DETAILS = "/account/notifications/details/:id";
export const SUBSCRIPTION = "/account/subscription";
export const SUBSCRIPTION_DETAILS = "/account/subscription/details";
export const SUBSCRIPTION_PAYMENT = "/account/subscription/payment";
export const SUBSCRIPTION_EXPIRED = "/account/subscription";
export const SUBSCRIPTION_PAYMENT_SUCCESS =
  "/account/subscription/payment/success";

export const PAYMENT = "/payment";
export const PAYMENT_SETTINGS = "/payment/settings";
export const PAYMENT_PROOF = "/payment/proof";
export const PAYMENT_CREDIT = "/payment/credit";
export const PAYMENT_METHODS = "/payment/methods";

export const DISTRIBUTION = "/distribution";
export const DISTRIBUTION_REPORT = "/distribution/report";
export const DISTRIBUTION_MARKUP = "/distribution/markup";
export const DISTRIBUTION_MARKUP_EDIT = "/distribution/markup/:id/edit";

export const REPORTS = "/reports";
export const REPORTS_PAYABLE = "/reports/payable";

// public
export const HELP_CENTER = "/helpCenter";
export const HELP_CENTER_ADMIN_GUID = "/helpCenter/adminGuide";
export const HELP_CENTER_FAQ = "/helpCenter/faq";
export const HELP_CENTER_SUPPORT = "/helpCenter/support";
export const HELP_CENTER_CONTACT = "/helpCenter/contact";
export const ABOUT = "/about";
export const TERMS_AND_CONDITIONS = "/terms&conditions";
export const PRIVACY_POLICY = "/privacyPolicy";
export const PRODUCTS = "/products";
export const CERTIFICATES = "/certificates";
export const DOWNLOAD = "/download";
export const CONTACT_US = "/contactUs";

export const ACCEPT_VOID = "/void/:id/accept";
export const REJECT_VOID = "/void/:id/reject";
export const ACCEPT_REFUND = "/refund/:id/accept";
export const REJECT_REFUND = "/refund/:id/reject";
export const ACCEPT_REISSUE = "/reissue/:id/accept";
export const REJECT_REISSUE = "/reissue/:id/reject";

export const SIGNUP = "/signUp";
export const SIGNUP_USER_TYPE = "/signUp/userType";
export const SIGNUP_COMPANY_DETAILS = "/signUp/companyDetails";
export const SIGNUP_USER_DETAILS = "/signUp/userDetails";
export const SIGNUP_CONFIRMATION = "/signUp/confirmation";
export const SIGNUP_COMPLETED = "/signUp/completed";

export const SIGN_IN = "/signIn";
export const SIGN_IN_BY_CODE = "/signInByCode";
export const FORGOT_PASSWORD = "/forgotPassword";
export const NEW_PASSWORD = "/newPassword";
export const NOT_FOUND = "/notFound";
export const TIME_OUT = "/timeout";
export const PAYMENT_SUCCESS = "/paymentSuccess";
