import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import { authReducer } from "./state/auth";
import { bookingReducer } from "./state/booking";
import { companyReducer } from "./state/company";
import { sagas } from "./sagas";
import { userReducer } from "./state/user";
import { flightSearchReducer } from "./state/flightSearch";
import { dictReducer } from "./state/dict";
import { bookingMngReducer } from "./state/bookingMng";
import { paymentReducer } from "./state/payment";
import { accountBalanceReducer } from "./state/accountBalance";
import { ticketSummaryReducer } from "./state/ticketSummary";
import { filesReducer } from "./state/files";
import { markupReducer } from "./state/markup";
import { inlineNotificationReducer } from "./state/inlineNotification";
import { chartReducer } from "./state/charts";
import { templatesReducer } from "./state/templates";
import { supplierReducer } from "./state/supplier";
import { invoicesReducer } from "./state/invoices";
import { billsReducer } from "./state/bills";
import { accountingReducer } from "./state/accounting";
import { communicationReducer } from "./state/communication";
import { documentsReducer } from "./state/documents";
import { banksReducer } from "./state/banks";
import { companyCreditReducer } from "./state/companyCredit";
import { packageReducer } from "./state/package";

const allReducers = combineReducers({
  auth: authReducer,
  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  booking: bookingReducer,
  company: companyReducer,
  user: userReducer,
  flightSearch: flightSearchReducer,
  dict: dictReducer,
  bookingMng: bookingMngReducer,
  payment: paymentReducer,
  accountBalance: accountBalanceReducer,
  ticketSummary: ticketSummaryReducer,
  files: filesReducer,
  markup: markupReducer,
  inlineNotification: inlineNotificationReducer,
  charts: chartReducer,
  templates: templatesReducer,
  supplier: supplierReducer,
  invoices: invoicesReducer,
  bills: billsReducer,
  accounting: accountingReducer,
  communication: communicationReducer,
  documents: documentsReducer,
  banks: banksReducer,
  companyCredit: companyCreditReducer,
  packages: packageReducer,
});

export const rootReducer = (state, action) => {
  // if (action.type === RESET_STATE) {
  //   state = undefined;
  // }

  return allReducers(state, action);
};

export function* rootSaga() {
  yield all([...sagas.map((saga) => saga())]);
}
