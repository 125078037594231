import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./documentsActions";

const initialState = {
  create: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  delete: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  documents: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const documentsReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_USER_DOCUMENTS.REQUESTED:
      case actions.GET_COMPANY_DOCUMENTS.REQUESTED:
        state.documents.loading = true;
        state.documents.list = undefined;
        break;
      case actions.GET_USER_DOCUMENTS.SUCCEEDED:
      case actions.GET_COMPANY_DOCUMENTS.SUCCEEDED:
        state.documents.loading = false;
        state.documents.success = true;
        state.documents.list = payload.data.reverse();
        break;
      case actions.GET_USER_DOCUMENTS.FAILED:
      case actions.GET_COMPANY_DOCUMENTS.FAILED:
        state.documents.loading = false;
        state.documents.failure = payload.error;
        break;
      case actions.CREATE.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        state.documents.list = [payload.data, ...state.documents.list];
        break;
      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.CREATE.CLEAR:
        state.create = initialState.create;
        break;
      case actions.DELETE.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.documents.list = state.documents.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE.CLEAR:
        state.delete = initialState.delete;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
