import { Grid, Typography, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { HOME } from "../../../../app/routesMap";
import { actions } from "../../../../redux/state/booking";
import { actions as flightActions } from "../../../../redux/state/flightSearch";
import {
  BookingHold,
  BookingIssue,
} from "../../../../redux/state/booking/bookingConstants";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button } from "../common";

export const OrderConfirmed = ({ issueHoldBooking }) => {
  const history = useHistory();
  const { type } = useParams() || {};
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
      dispatch(flightActions.reset());
    };
  });
  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        {steps}
      </Grid> */}
      <Grid item xs={12}>
        <Paper className="p-7 text-center">
          <img
            src={toAbsoluteUrl("/media/svg/icons/General/check-green.svg")}
            className="mb-2"
            alt=""
          />
          <Typography variant="h4" className="font-weight-bolder mt-5 mb-2">
            {type === BookingHold && "We have reserved your seat(s)"}
            {(type === BookingIssue || issueHoldBooking) &&
              "Your order is confirmed!"}
          </Typography>
          <Typography variant="h6">
            {type === BookingHold && "You need to issue to confirm your seats"}
            {(type === BookingIssue || issueHoldBooking) &&
              "You will be receiving a confirmation email with order details."}
          </Typography>
          <Button
            onClick={() => history.push(HOME)}
            variant="primary"
            className="my-5"
          >
            Return to homepage
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
