import { useCallback } from "react";
import usePackageSeasonsList from "./usePackageSeasonsList";

export default function usePkgSeasonName() {
  const { list, loading, success, failure } = usePackageSeasonsList();

  const getPkgSeasonName = useCallback(
    (pgkSeasonId) => list.find(({ id }) => pgkSeasonId === id)?.name ?? "",
    [list]
  );
  return { getPkgSeasonName, loading, success, failure };
}
