import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/package";

export default function useCitiesList() {
  const dispatch = useDispatch();
  const { list, loading, success, failure } = useSelector(
    ({ packages }) => packages.allCities
  );
  useEffect(() => {
    if (!list && !loading) {
      dispatch(actions.getAllCities());
    }
  }, [dispatch, list, loading]);
  return { list, loading, success, failure };
}
