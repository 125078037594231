import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./dictConstants";

export const GET_AIRLINES = requestActions(MODULE_NAME, "GET_AIRLINES");
export const GET_AIRPORTS = requestActions(MODULE_NAME, "GET_AIRPORTS");

export const getAirports = (params) =>
  requestApiCall("getAirports", params, GET_AIRPORTS);

export const getAirlines = (params) =>
  requestApiCall("getAirlines", params, GET_AIRLINES);
