import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./bookingConstants";

export const RESET = "RESET";
export const BOOK_FLIGHT = requestActions(MODULE_NAME, "BOOK_FLIGHT");
export const ISSUE_HOLD_BOOKING = requestActions(
  MODULE_NAME,
  "ISSUE_HOLD_BOOKING"
);
export const PAY_REISSUE = requestActions(MODULE_NAME, "PAY_REISSUE");
export const SET_BOOKING_STATUS = "SET_BOOKING_STATUS";
export const SET_BOOKING_PARAMS = "SET_BOOKING_PARAMS";

export const issueHoldBooking = (params) =>
  requestApiCall("issueHoldBooking", params, ISSUE_HOLD_BOOKING);

export const payReissue = (params) =>
  requestApiCall("payReissue", params, PAY_REISSUE);

export const bookFlight = (params) =>
  requestApiCall("bookFlight", params, BOOK_FLIGHT);

export const setBookingStatus = ({ bookingType, isManual }) => ({
  type: SET_BOOKING_STATUS,
  payload: { bookingType, isManual },
});

export const setBookingParams = (params) => ({
  type: SET_BOOKING_PARAMS,
  payload: params,
});

export const clearBookFlights = () => ({ type: BOOK_FLIGHT.CLEAR });

export const reset = () => ({ type: RESET });
