import { Icon } from "@iconify/react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../_metronic/layout/components/common";
import { HOME } from "../routesMap";

import { loadStripe } from "@stripe/stripe-js";
import LoadingModal from "../../_metronic/layout/components/common/loadingModal/LoadingModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const PaymentSuccessfulPage = ({ issueHoldBooking }) => {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    ),
    appearance,
  };
  return (
    <Elements options={options} stripe={stripePromise}>
      <PaymentSuccessfulComponent />
    </Elements>
  );
};

export const PaymentSuccessfulComponent = () => {
  const history = useHistory();
  const stripe = useStripe();
  const [message, setMessage] = useState({
    title: "",
    description: "",
    icon: "",
    color: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    setLoading(true);

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log({ paymentIntent });
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage({
            title: "Payment successful",
            description:
              "Your order has been placed. We'll send you an email with your order details.",
            icon: "ep:success-filled",
            color: "success",
          });

          break;
        case "processing":
          setMessage({
            title: "Processing your order...",
            description:
              "Hold tight, your order is being processed. We will email you when your order succeeds.",
            icon: "bi:clock-fill",
            color: "warning",
          });
          break;
        case "requires_payment_method":
        case "requires_action":
          setMessage({
            title: "Payment failed",
            description:
              "We are sorry, there was an error processing your payment. Please try again with a different payment method..",
            icon: "bi:exclamation-circle-fill",
            color: "danger",
          });
          break;
        default:
          setMessage({
            title: "Something went wrong",
            // description:
            //   "We are sorry, there was an error processing your payment. Please try again with a different payment method..",
            // icon: "bi:exclamation-circle-fill",
            // color: "danger",
          });
          break;
      }
    });
    setLoading(false);
  }, [stripe]);
  console.log({ loading });
  return (
    <Grid container spacing={3}>
      <LoadingModal loading={loading} />

      {/* <Grid item xs={12}>
        {steps}
      </Grid> */}
      <Grid item xs={12}>
        <Paper className="p-7 text-center">
          <Icon
            icon={message.icon}
            className={`text-${message.color} w-100px h-100px`}
          />
          <Typography variant="h4" className="font-weight-bolder mt-5 mb-2">
            {message.title}
          </Typography>
          <Typography variant="h6">{message.description}</Typography>
          <Button
            onClick={() => history.push(HOME)}
            variant="primary"
            className="my-5"
          >
            Return to homepage
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
