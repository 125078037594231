import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { ConfirmationModal } from "../../bookingManagement/common/ConfirmationModal";
import { Button } from "../../common";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const DeleteUser = () => {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { id: userId } = useSelector(({ user }) => user.userData);
  const { loading, success, failure } = useSelector(({ user }) => user.delete);

  const onDeleteConfirm = () => {
    dispatch(actions.deleteUser({ id: userId }));
  };

  // handle delete side effects
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: "Account deleted successfully",
          type: NOTIFICATION_TYPE_SUCCESS,
        })
      );
      setShowConfirmation(false);
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: "Account deletion failed",
          type: NOTIFICATION_TYPE_ERROR,
        })
      );
      dispatch(actions.clearDeleteUser());
    }
  }, [failure, dispatch]);

  return (
    <div>
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        title="Delete Account"
        body="Are you sure you want to delete your account? This action cannot be undone."
        onConfirm={onDeleteConfirm}
        loading={loading}
      />
      <h1>Delete Account</h1>
      <p>
        This action will delete your account permanently, and cannot be undone.
      </p>
      <Button variant="danger" onClick={() => setShowConfirmation(true)}>
        <i className="fas fa-trash-alt mr-2"></i> Delete Account Permanently
      </Button>
    </div>
  );
};
