import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Modal } from "react-bootstrap";

const LoadingModal = ({ loading, message }) => {
  return (
    <Modal
      show={loading}
      size="sm"
      centered
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      // }}
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <CircularProgress color="primary" />
          <div className="mt-3">{message}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
