import { Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Seperator, Button } from "../../common";
import { actions } from "../../../../../redux/state/communication";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Section } from "./Section";

// const emailTypeSections = {
//   1: {
//     name: "flight",
//     label: "Flight Change Notification",
//     logo: toAbsoluteUrl("/media/svg/icons/General/repeat.svg"),
//   },
//   2: {
//     name: "order",
//     label: "Order Stats Notification",
//     logo: toAbsoluteUrl("/media/svg/icons/General/pin.svg"),
//   },
//   3: {
//     name: "promotion",
//     label: "Promotion Notification",
//     logo: toAbsoluteUrl("/media/svg/icons/General/up.svg"),
//   },
//   4: {
//     name: "announcement",
//     label: "Announcement Notification",
//     logo: toAbsoluteUrl("/media/svg/icons/General/bell.svg"),
//   },
// };

export const NotificationAccess = () => {
  const { list } = useSelector(
    ({ communication }) => communication.communicationTypes
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getAllCommunicationTypes());
  }, [dispatch]);
  return <EmailsSections emailTypeSections={list} />;
};

export const EmailsSections = ({ emailTypeSections }) => {
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { id: companyId } =
    useSelector(({ company }) => company.companyData.data) || {};
  const { listMap, success: getListSuccess, isFirstTime } = useSelector(
    ({ communication }) => communication.companyEmails
  );
  const {
    loading: setEmailLoading,
    failure: setEmailFailure,
    success: setEmailSuccess,
  } = useSelector(({ communication }) => communication.setEmails);
  // const initialValues = useMemo(() => {
  //   const temp = {};
  //   if (!emailTypeSections) return temp;
  //   emailTypeSections.forEach(({ id }) => {
  //     temp[id] = { isSubscribed: "", emails: [""] };
  //   });
  //   return temp;
  // }, [emailTypeSections]);
  // const emailsSchema = useMemo(() => {
  //   const temp = {};
  //   if (!emailTypeSections) return temp;
  //   emailTypeSections.forEach(
  //     ({ id }) =>
  //       (temp[id] = {
  //         emails: Yup.array().of(
  //           Yup.string().email("Wrong email format")
  //           // .required("Email can't be empty")
  //         ),
  //       })
  //   );
  //   return Yup.object().shape(temp);
  // }, [emailTypeSections]);
  const formFields = useFormik({
    initialValues: {},
    // validationSchema: emailsSchema,
    onSubmit: (values) => {
      const params = Object.keys(values).map((key) => ({
        communicationTypeId: +key,
        email: values[key].emails,
        isSubscribed: values[key].isSubscribed,
        isAdminOnly: isAdmin,
      }));
      if (isFirstTime) {
        dispatch(actions.setEmails({ companyId, params }));
      } else {
        dispatch(actions.updateEmails({ companyId, params }));
      }
    },
  });
  const { setValues, values } = formFields;
  const getInitialStates = useCallback(() => {
    const temp = {};
    if (!emailTypeSections) return temp;
    emailTypeSections.forEach(({ id }) => {
      const { emails, isSubscribed } = listMap.get(+id) || {};
      temp[id] = {
        emails: emails || [""],
        isSubscribed: isSubscribed ?? true,
      };
    });
    return temp;
  }, [emailTypeSections, listMap]);
  useEffect(() => {
    if (getListSuccess && emailTypeSections) {
      const companyEmails = getInitialStates();

      setValues(companyEmails);
    }
  }, [
    getInitialStates,
    emailTypeSections,
    getListSuccess,
    // isFirstTime,
    setValues,
  ]);
  useEffect(() => {
    if (companyId) {
      dispatch(actions.getEmails({ companyId }));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [companyId, dispatch]);
  useEffect(() => {
    if (setEmailSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Emails updated successfully",
        })
      );
    }
  }, [dispatch, setEmailSuccess]);

  useEffect(() => {
    if (setEmailFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
    }
  }, [dispatch, setEmailFailure]);
  return (
    <Paper className="bg-white p-7">
      <form onSubmit={formFields.handleSubmit}>
        <Typography variant="h5" className="font-weight-bolder">
          Notification Access
        </Typography>
        <Seperator />
        {getListSuccess &&
          Object.keys(values)?.length &&
          emailTypeSections?.map(({ id, type }) => {
            return (
              <Section
                key={id}
                formFields={formFields}
                name={id}
                title={type}
              />
            );
          })}
        <div className="d-flex justify-content-center">
          <Button variant="secondary" className="mr-3" type="button">
            Cancel
          </Button>
          <Button loading={setEmailLoading} type="submit">
            Save
          </Button>
        </div>
      </form>
    </Paper>
  );
};
