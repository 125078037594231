import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/banks";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { BankAccountForm } from "./BankAccountForm";
import { BankAccountRow } from "./BankAccountRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";
import { WithdrawModal } from "./WithdrawModal";
import { DepositModal } from "./DepositModal";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

const bankAccountSchema = Yup.object().shape({
  bank: Yup.string().required("Ticket No is required"),
  accountHolderName: Yup.string().required("Holder's name is required"),
  accountNo: Yup.string().required("Account number is required"),
  swiftCode: Yup.string().required("IBAN is required"),
  iban: Yup.string().required("SWIFT/BIC is required"),
  currency: Yup.string().required("Currency is required"),
});

export const BankAccounts = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ banks }) => banks.allBankAccounts);
  const { list: banksList } = useSelector(({ banks }) => banks.allBanks);

  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ banks }) => banks.deleteBankAccount);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ banks }) => banks.createBankAccount);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ banks }) => banks.editBankAccount);

  const banksOptions = useMemo(
    () =>
      banksList
        ?.filter(({ isActive }) => isActive)
        .map(({ name, id }) => ({ label: name, value: `${id}` })),
    [banksList]
  );
  const fields = useFormik({
    initialValues: {
      bank: "",
      accountHolderName: "",
      accountNo: "",
      swiftCode: "",
      iban: "",
      currency: "",
      isActive: true,
    },
    validationSchema: bankAccountSchema,
    onSubmit: ({
      bank,
      accountHolderName,
      accountNo,
      swiftCode,
      iban,
      currency,
      isActive,
    }) => {
      if (!!selectedId) {
        const data = getBankAccount(selectedId);
        dispatch(
          actions.editBankAccount({
            ...data,
            bankId: +bank,
            accountHolderName,
            accountNo,
            swiftCode,
            iban,
            currency,
            isActive,
          })
        );
      } else {
        dispatch(
          actions.createBankAccount({
            bankId: +bank,
            accountHolderName,
            accountNo,
            swiftCode,
            iban,
            currency,
            isActive,
          })
        );
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllBankAccounts());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearEditBankAccount());
    dispatch(actions.clearCreateBankAccount());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  const hideWithdrawModal = useCallback(() => {
    setShowWithdraw(false);
    dispatch(actions.clearWithdraw());
    setSelectedId(undefined);
  }, [dispatch]);
  const hideDepositModal = useCallback(() => {
    setShowDepositModal(false);
    dispatch(actions.clearDeposit());
    setSelectedId(undefined);
  }, [dispatch]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Bank account Added",
        })
      );
      dispatch(actions.getAllBankAccounts());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateBankAccount());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Bank account Edited",
        })
      );
      dispatch(actions.getAllBankAccounts());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeleteBankAccount());
    setSelectedId(undefined);
  }, [dispatch]);
  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(actions.clearEditBank());
  //   }
  // }, [dispatch, editSuccess]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onWithdraw = (id) => {
    setShowWithdraw(true);
    setSelectedId(id);
  };
  const onDeposit = (id) => {
    setShowDepositModal(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deleteBankAccount({ id: selectedId }));
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeleteBankAccount());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const {
      bankId,
      accountHolderName,
      accountNo,
      swiftCode,
      iban,
      currency,
      isActive,
    } = getBankAccount(id);
    fields.setValues({
      bank: `${bankId}`,
      accountHolderName,
      accountNo,
      swiftCode,
      iban,
      currency,
      isActive,
    });
    onShowForm();
  };
  const getBankAccount = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const allBankAccountsSubPermission =
    adminPermissionsIds.payment.bankAccounts.subPermissions;
  const tableContent = list?.map((item, index) => (
    <BankAccountRow
      key={index}
      data={item}
      subPermissions={subPermissions}
      onEdit={onEdit}
      onDelete={onDelete}
      onWithdraw={onWithdraw}
      onDeposit={onDeposit}
    />
  ));
  const onShowForm = () => {
    dispatch(actions.getAllBanks());
    setShowForm(true);
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Bank Accounts</Typography>
            <button
              hidden={
                !subPermissions.includes(allBankAccountsSubPermission.add.id)
              }
              onClick={onShowForm}
              className="btn btn-danger"
            >
              Add bank account
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete bank"
            body="Are you sure you want to delete this bank?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
          />
          <BankAccountForm
            onHide={hideFormModal}
            show={showForm}
            loading={createLoading || editLoading}
            banksOptions={banksOptions}
            fields={fields}
          />
          <DepositModal
            data={getBankAccount(selectedId)}
            onHide={hideDepositModal}
            show={showDepositModal}
          />
          <WithdrawModal
            data={getBankAccount(selectedId)}
            onHide={hideWithdrawModal}
            show={showWithdraw}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th>Account Number</th>
                      {matchesSm && (
                        <>
                          <th className="text-left">Country</th>
                          <th>Currency</th>
                        </>
                      )}
                      <th>Bank Name</th>
                      {matchesSm && <th>Account Name</th>}
                      <th>Status</th>
                      {matchesSm && (
                        <>
                          <th>Creation Date</th>
                          <th>Balance</th>
                          <th className="min-w-160px text-right">Actions</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No bank accounts to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
