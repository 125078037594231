import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/company";
import { actions as userActions } from "../../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { actions as filesActions } from "../../../../../../redux/state/files";
import { ClientForm } from "../ClientForm";
import { ORGANIZATION_TYPE_AGENCY } from "../../../../../../redux/state/company/companyConstants";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const CreateClient = () => {
  const dispatch = useDispatch();
  const [companyParams, setCompanyParams] = useState();
  const {
    companyId,
    success: CreateCompanySuccess,
    loading: createCompanyLoading,
  } = useSelector(({ company }) => company.create);
  const {
    loading: createUserLoading,
    success: createUserSuccess,
  } = useSelector(({ user }) => user.create);
  const {
    url,
    loading: uploadLoading,
    success: uploadSuccess,
    failure: uploadFailure,
  } = useSelector(({ files }) => files.upload);

  const loading = createUserLoading || createCompanyLoading || uploadLoading;
  // const createSchema = Yup.object().shape({
  //   companyName: Yup.string().required("Field is required"),
  //   businessManager: Yup.string().required("Field is required"),
  //   address: Yup.string().required("Field is required"),
  //   phone: Yup.string().required("Field is required"),
  //   username: Yup.string().required("Field is required"),
  //   registeredEmail: Yup.string().required("Field is required"),
  //   password: Yup.string()
  //     .required("No password provided")
  //     .min(6, "Password is too short - should be 8 chars minimum"),
  //   confirmPassword: Yup.string().oneOf(
  //     [Yup.ref("password"), null],
  //     "Passwords must match"
  //   ),
  //   registeredPhone: Yup.string(),
  //   city: Yup.string(),
  //   recoveryEmail: Yup.string(),
  //   salesEmail: Yup.string(),
  //   emergencyContactPerson: Yup.string(),
  //   emergencyContactPhone: Yup.string(),
  //   iata: Yup.string(),
  // });
  // const fields = useFormik({
  //   initialValues: {
  //     companyName: "",
  //     address: "",
  //     businessManager: "",
  //     phone: "",
  //     username: "",
  //     registeredEmail: "",
  //     registeredPhone: "",
  //     city: "",
  //     currency: "",
  //     recoveryEmail: "",
  //     salesEmail: "",
  //     emergencyContactPerson: "",
  //     emergencyContactPhone: "",
  //     iata: "",
  //     password: "",
  //     confirmPassword: "",
  //   },
  //   validationSchema: createSchema,
  //   onSubmit: ({
  //     companyName,
  //     address,
  //     businessManager,
  //     phone,
  //     username,
  //     registeredEmail,
  //     registeredPhone,
  //     city,
  //     currency,
  //     recoveryEmail,
  //     salesEmail,
  //     emergencyContactPerson,
  //     emergencyContactPhone,
  //     iata,
  //     password,
  //   }) => {
  //     dispatch(
  //       actions.createCompany({
  //         name: companyName,
  //         address,
  //         businessManager,
  //         phone,
  //         organisationTypeId: ORGANIZATION_TYPE_AGENCY,
  //         isActive: true,
  //       })
  //     );
  //   },
  // });
  const onSubmitCompanyInfo = (params) => {
    setCompanyParams(params);
    const { logo } = params;
    if (logo) {
      uploadLogo(logo);
    } else {
      createCompany({});
    }
  };
  const uploadLogo = (logo) => {
    const formData = new FormData();
    formData.append("file", logo, logo.name);
    dispatch(filesActions.uploadFile({ userId: 1, formData }));
  };
  const createCompany = ({ url }) => {
    const { logo } = companyParams;
    dispatch(
      actions.createCompany({
        ...companyParams,
        logo: url || logo,
        isActive: false,
        organisationTypeId: ORGANIZATION_TYPE_AGENCY,
      })
    );
  };
  useEffect(() => {
    if (uploadSuccess) {
      createCompany({ url });
      dispatch(filesActions.clearUpload());
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (uploadFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong! Can't upload logo",
        })
      );
      dispatch(filesActions.clearUpload());
    }
  }, [uploadFailure]);

  // useEffect(() => {
  //   if (CreateCompanySuccess) {
  //     const { registeredEmail, password, username } = fields.values;
  //     dispatch(
  //       userActions.createUser({
  //         email: registeredEmail,
  //         password,
  //         companyInUserId: companyId,
  //         name: username,
  //       })
  //     );
  //   }
  // }, [CreateCompanySuccess, companyId, dispatch, fields.values]);
  // useEffect(() => {
  //   if (createUserSuccess) {
  //     dispatch(userActions.reset());
  //     dispatch(actions.reset());
  //     fields.resetForm();
  //   }
  // }, [createUserSuccess, dispatch, fields]);
  return (
    <ClientForm
      title="Create Agency"
      onSubmitCompanyInfo={onSubmitCompanyInfo}
      loading={loading}
    />
  );
};
