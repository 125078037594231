import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import Svg from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Button, Input } from "../";
import { ConfirmButton } from "./ConfirmButton";

export const EWalletPayment = ({
  formik,
  loading,
  handleApplyPromo,
  handleRemovePromo,
  isPromoApplied,
  hasPromoCode,
  title,
  description,
}) => {
  return (
    <Paper className="h-100 overflow-hidden">
      <div className="bg-primary w-100 h-80px d-flex justify-content-center align-items-center">
        <Svg src={toAbsoluteUrl("/media/svg/icons/General/wallet.svg")} />
        <Typography variant="h3" className="ml-5 text-white">
          {title}
        </Typography>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} className="p-12">
          {hasPromoCode && (
            <Grid item xs={12} sm={12}>
              <div className="d-flex">
                <Input
                  type="text"
                  outlined={false}
                  label="Promotion Code"
                  showErrors={formik.touched["promoCode"]}
                  errorMessage={formik.errors["promoCode"]}
                  {...formik.getFieldProps("promoCode")}
                  autoComplete="new-password"
                  disabled={isPromoApplied}
                />
                {isPromoApplied ? (
                  <Button
                    variant="danger-inverse"
                    onClick={handleRemovePromo}
                    // loading={applyPromoLoading}
                    className="ml-3 mt-auto"
                    type="button"
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleApplyPromo}
                    // loading={applyPromoLoading}
                    className="ml-3 mt-auto"
                    type="button"
                  >
                    Apply
                  </Button>
                )}
              </div>
            </Grid>
          )}
          {description}

          <Grid item xs={12} sm={12}>
            <Input
              outlined={false}
              placeholder="612223333"
              label="Mobile Number"
              required
              showErrors={formik.touched["accountNo"]}
              errorMessage={formik.errors["accountNo"]}
              {...formik.getFieldProps("accountNo")}
            />
          </Grid>
          <Grid item sm={12} xs={12} className="d-flex justify-content-end">
            <ConfirmButton loading={loading} />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
