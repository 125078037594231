import { put, takeEvery } from "@redux-saga/core/effects";
import { actions as commonActions } from "../state/common";
import { actions as logoutActions } from "../state/auth";

function* resetUser() {
  yield put(commonActions.resetState());
}

export function* watchLogout() {
  yield takeEvery(logoutActions.LOGOUT.SUCCEEDED, resetUser);
  yield takeEvery(logoutActions.LOGOUT.FAILED, resetUser);
}
