import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import useBankAccountData from "../../../../../../app/helpers/hooks/useBankAccountData.js";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import { getDateTime } from "../../../../../_helpers";
import { LoadingSpinner } from "../../../common";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow.jsx";

export const BankReportTable = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const {
    getBankAccountData,
    loading: bankAccountLoading,
  } = useBankAccountData();
  const { list, loading } = useSelector(
    ({ banks }) => banks.bankAccountHistory
  );
  const tableContent = list?.map((item, index) => (
    <TableRow
      getBankAccountData={getBankAccountData}
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
    />
  ));
  return (
    <div className="table-responsive border">
      {loading || allCompaniesLoading || bankAccountLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-200">
              {matchesSm && <th>ID</th>}
              <th>Bank</th>
              <th>Account Number</th>
              {matchesSm && (
                <>
                  <th className="min-w-110px">Date</th>
                  <th>remark</th>
                </>
              )}
              <th>Amount</th>
              <th>Available balance</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getBankAccountData }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    date: createdAt,
    id,
    bankAccountId,
    currency,
    type,
    amount,
    remarks,
    availableBalance,
  } = data;
  const { accountNo, banks } = getBankAccountData(bankAccountId);
  const { date, time } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  const rows = (
    <>
      {matchesSm && (
        <td>
          <span className="font-weight-bold">{id}</span>
        </td>
      )}
      <td>
        <span className="font-weight-bold">{banks?.name || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">{accountNo || "-"}</span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="text-dark-75 font-weight-bold d-block font-size-lg">
              {date}
            </span>
            <span className="text-muted font-weight-bold">{time}</span>
          </td>
          <td>
            <span className="font-weight-bold">{remarks}</span>
          </td>
        </>
      )}
      <td>
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {currency}
          {amount?.toFixed(2)}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">
          {currency}
          {+availableBalance.toFixed(2)}
        </span>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "ID",
      value: id,
    },
    {
      label: "Bank",
      value: banks?.name || "-",
    },
    {
      label: "Account Number",
      value: accountNo || "-",
    },
    {
      label: "Date",
      value: date,
    },
    {
      label: "Time",
      value: time,
    },
    {
      label: "Remarks",
      value: remarks,
    },
    {
      label: "Amount",
      value: (
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {currency}
          {amount?.toFixed(2)}
        </span>
      ),
    },
    {
      label: "Available balance",
      value: (
        <span className="font-weight-bold">
          {currency}
          {+availableBalance.toFixed(2)}
        </span>
      ),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
