import { useMemo } from "react";
import usePackageSeasonsList from "./usePackageSeasonsList";

export default function usePkgSeasonsOptions() {
  const { list, loading } = usePackageSeasonsList();
  const pkgSeasonOptions = useMemo(
    () =>
      list?.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [list]
  );
  return { pkgSeasonOptions, loading };
}
