import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../common";
import { actions } from "../../../../../../../redux/state/banks";
import { useParams } from "react-router-dom";
import { HistoryTableRow } from "./HistoryTableRow";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { BankReportSearchCard } from "../../../reports/Bank Report/BankReportSearchCard";
import { filterOptions } from "../../../../../../../redux/state/invoices/invoicesConstants";
import useUserName from "../../../../../../../app/helpers/hooks/useUserName";

export const BankAccountHistory = () => {
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { getUserName } = useUserName();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { list, loading } = useSelector(
    ({ banks }) => banks.bankAccountHistory
  );
  const { data: bankAccountData, loading: bankAccountLoading } = useSelector(
    ({ banks }) => banks.bankAccount
  );
  const { fromDate, toDate } = dateRange;

  useEffect(() => {
    dispatch(actions.getBankAccountHistory({ id }));
    dispatch(actions.getBankAccount({ id }));
  }, [dispatch, id]);

  const tableContent = list?.map((item, index) => (
    <HistoryTableRow
      getCompanyName={getCompanyName}
      key={index}
      data={item}
      getUserName={getUserName}
    />
  ));
  const search = (filters) => {
    dispatch(
      actions.getAllBankAccountsHistory({
        filterParam: [
          {
            columnName: "bankAccountId",
            filterValue: `${id}`,
            filterOption: filterOptions.IsEqualTo,
          },
          ...filters,
        ],
      })
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <Grid container spacing={3}>
            <Grid xs={12} md={3} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">Bank</span>
                <span className="">{bankAccountData?.banks?.name}</span>
              </Typography>
            </Grid>
            <Grid xs={12} md={3} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Account No.
                </span>
                <span className="">{bankAccountData?.accountNo}</span>
              </Typography>
            </Grid>
            <Grid xs={12} md={3} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Account Holder Name
                </span>
                <span className="">{bankAccountData?.accountHolderName}</span>
              </Typography>
            </Grid>
            <Grid xs={12} md={3} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Available Balance
                </span>
                <span className="">
                  ${bankAccountData?.availableBalance.toFixed(2)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <BankReportSearchCard
            isBankHistory
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div>
            <Typography variant="h6" className="font-weight-bold">
              Bank Accounts Report
            </Typography>
            {fromDate && toDate && (
              <Typography>
                Date: <b>{fromDate}</b> to: <b>{toDate}</b>
              </Typography>
            )}
          </div>
          {loading || bankAccountLoading || allCompaniesLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th>ID</th>
                      <th>Agency Name</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>Proof</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Available Balance</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No bank accounts to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
