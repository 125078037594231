import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { formatImageUrl } from "../../../../app/helpers/formatters/strapiImagesUrl";
import { AboutUseSkeleton } from "../../../../app/skeletons/AboutUsSkeleton";
import { GET_HOME_CONTENT } from "../../../../graphql/queries";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button } from "../common";
import styles from "../public/Public.module.scss";
import { ContentSection } from "../public/common/ContentSection";
import { ContentWithTextIcons } from "../public/common/ContentWithTextIcons";

const images = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/c9dbd9925d25efcb184739d083171103ccb864e92364c6f656ddb58fa43ad068?apiKey=ebae3d3702bf466ca1191e7832204fac",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/e258f873b0cda210dd13b68985243aeac77c08e72c7f51723d57baee4b25f42f?apiKey=ebae3d3702bf466ca1191e7832204fac",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/6d7537b72e873b93d6610af6c357ce6d4c634d275fa5b8449e1096f265ccdf31?apiKey=ebae3d3702bf466ca1191e7832204fac",
];

export function Home({ isAuthorized }) {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const permissionIdsList = list?.map(({ permissionId }) => permissionId);
  const { data, loading, error } = useQuery(GET_HOME_CONTENT);
  if (loading) return <AboutUseSkeleton />;
  if (!data?.homePage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  if (error) {
    return (
      <div className="text-center vh-100 pt-20">
        <Typography variant="h3" className="text-dark font-weight-bolder">
          Something went wrong
        </Typography>
        <div className="my-5" />
        <Button variant="primary" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    );
  }
  if (!data?.homePage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  const {
    heroBlock,
    experienceBlock,
    discoverBlock,
    stepsBlock,
    downloadBlock,
  } = data?.homePage?.data?.attributes;
  return (
    <div className={styles["div"]}>
      <ContentSection data={heroBlock} imageSpan={6} />
      {stepsBlock ? (
        <div className={styles["div-27"]}>
          <div className={styles["div-28"]}>
            <div className={styles["div-29"]}>
              <div className={styles["column-7"]}>
                <img
                  loading="lazy"
                  src={formatImageUrl(stepsBlock?.image?.data?.attributes?.url)}
                  className={styles["img-7"]}
                  alt="steps"
                />
              </div>
              <div className={styles["column-8"]}>
                {stepsBlock?.steps?.map(
                  ({ title, description, icon }, index) => (
                    <div className={styles["div-32"]} key={`step-${index}`}>
                      <img
                        loading="lazy"
                        src={formatImageUrl(icon?.data?.attributes?.url)}
                        className={styles["img-8"]}
                        alt="step"
                      />
                      <div className={styles["div-33"]}>
                        <div className={styles["div-34"]}>{title}</div>
                        <div className={styles["div-35"]}>{description}</div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {discoverBlock ? <ContentWithTextIcons data={discoverBlock} /> : null}

      <div className={styles["div-63"]}>
        {experienceBlock ? <ContentSection data={experienceBlock} /> : null}
        {downloadBlock ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={12} md={8}>
              <img
                loading="lazy"
                src={formatImageUrl(
                  downloadBlock?.viewOnDifferentScreens?.data?.attributes?.url
                )}
                alt=""
                className="max-w-100"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column align-items-center mr-2">
                  {/* qr code image */}
                  <img
                    loading="lazy"
                    src={formatImageUrl(
                      downloadBlock?.androidQRCode?.data?.attributes?.url
                    )}
                    alt=""
                    className="max-w-100 mb-2"
                  />
                  <a
                    href={downloadBlock?.androidLink ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    // className="mr-3"
                  >
                    <img
                      alt="Get it on Google Play"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/play-store.svg"
                      )}
                      className="max-w-100"
                    />
                  </a>
                </div>
                <div className="d-flex flex-column align-items-center">
                  {/* qr code image */}
                  <img
                    loading="lazy"
                    src={formatImageUrl(
                      downloadBlock?.iosQRCode?.data?.attributes?.url
                    )}
                    alt=""
                    className="max-w-100 mb-2"
                  />
                  <a
                    href={downloadBlock?.iosLink ?? ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    // className="mr-3"
                  >
                    <img
                      alt="Get it on App Store"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/app-store.svg"
                      )}
                      className="max-w-100"
                    />
                  </a>
                </div>
                {/* <img
              loading="lazy"
              src={toAbsoluteUrl("/media/svg/icons/General/app-store.svg")}
              className={styles["img-22"]}
              alt="play store"
            /> */}
              </div>
            </Grid>
          </Grid>
        ) : null}
      </div>
      {/* <div className={styles["div-68"]}>
        <div className={styles["div-69"]}>
          <div className={styles["div-70"]}>
            <div className={styles["column-15"]}>
              <div className={styles["div-71"]}>
                <div className={styles["div-72"]}>
                  Be the first to see the news
                </div>
                <div className={styles["div-73"]}>
                  Your company may not be in the software business, but
                  eventually, a software company will be in your business.
                </div>
                <div className={styles["div-74"]}>
                  <div className={styles["div-75"]}>
                    <div className={styles["div-76"]}>First Name</div>
                  </div>
                  <div className={styles["div-77"]}>Subscribe</div>
                </div>
              </div>
            </div>
            <div className={styles["column-16"]}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/34af1bff78535562cf18425474f2f7414f7a8e23a4815b60186f10d001317fbb?apiKey=ebae3d3702bf466ca1191e7832204fac&"
                className={styles["img-18"]}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
