import { accountingClient } from "./client";

export const getUnpaidInvoices = () =>
  accountingClient.get("/accounting/v1/unpaidInvoices");

export const getInvoices = () => accountingClient.get("/accounting/v1/invoice");

export const getSpecificInvoices = ({ id }) =>
  accountingClient.get(`/accounting/v1/invoice/${id}`);

export const filterInvoicesPagination = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  accountingClient.post("/accounting/v1/invoice/FilterInvoicePagination", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 10,
    groupingColumns: [],
  });

export const filterInvoices = ({ sortingParams, filterParam }) =>
  accountingClient.post("/accounting/v1/invoice/FilterInvoice", {
    sortingParams: sortingParams || [
      {
        sortOrder: 1,
        columnName: "CreatedAt",
      },
    ],
    filterParam: filterParam || [],
  });

export const editInvoices = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/invoice/${id}`, { ...rest });

export const getInvoiceByInvoiceNo = ({ invoiceNo }) =>
  accountingClient.post("/accounting/v1/invoice/FilterInvoice", {
    sortingParams: [
      {
        sortOrder: 1,
        columnName: "CreatedAt",
      },
    ],
    filterParam: [
      {
        columnName: "invoiceNo",
        filterValue: invoiceNo,
        filterOption: 1,
      },
    ],
  });
