import { bookingClient } from "./client";

export const getTicketSummary = () =>
  bookingClient.get("/booking/v1/Orders/TicketSummary/");

export const getTicketSummaryByAgency = ({ id }) =>
  bookingClient.get(`/booking/v1/Orders/TicketSummaryByAgency/${id}`);

export const deleteTicket = ({ id }) =>
  bookingClient.delete(`/booking/v1/Passenger/${id}`);

export const editTicket = (params) =>
  bookingClient.put(`/booking/v1/Orders/${params.id}/passengers`, {
    ...params,
  });

export const sendETicketEmail = ({
  companyId,
  transactionId,
  to,
  cc,
  bcc,
  remarks,
}) =>
  bookingClient.post(`/booking/v1/orders/ETicket/${transactionId}/Send`, {
    to,
    cc,
    bcc,
    remarks,
  });

export const downloadETicket = ({ id }) => bookingClient.get(`/ticket/${id}`);

export const sendInvoiceEmail = ({
  companyId,
  transactionId,
  to,
  cc,
  bcc,
  remarks,
}) =>
  bookingClient.post(`/booking/v1/orders/EInvoice/${transactionId}/Send`, {
    to,
    cc,
    bcc,
    remarks,
  });

export const downloadInvoice = ({ id }) => bookingClient.get(`/invoice/${id}`);
