import { put, takeEvery } from "@redux-saga/core/effects";
import { actions as userActions } from "../state/user";
import { actions as authActions } from "../state/auth";
import { LOCAL_STORAGE_SESSION_ID } from "../state/auth/authConstants";

function* logout({ payload }) {
  yield put(
    authActions.logout({
      userinUserSessionId: payload.data.id,
      id: localStorage?.getItem(LOCAL_STORAGE_SESSION_ID),
    })
  );
}

export function* watchDeleteUser() {
  yield takeEvery(userActions.DELETE_USER.SUCCEEDED, logout);
}
