import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { pax_types_text } from "../../../../../redux/state/booking/bookingConstants";
import { getSelectRow } from "../../../../_helpers";
import { TicketNoColumn } from "./ColumnActions";
import { v4 as uuid } from "uuid";

export const FlightPassengers = ({ setSelectedPassengers, passengers }) => {
  const [ids, setIds] = useState([]);
  const entities = passengers?.$values.map(
    ({ firstName,id, lastName, gender, ticketNo, paxType }, index) => {
      return {
        name: `${firstName} ${lastName}`,
        gender,
        paxType: pax_types_text[paxType],
        ticketNo,
        id,
      };
    }
  );
  useEffect(() => {
    const temp = passengers?.$values.filter(({id}) => ids.includes
    (id));
    setSelectedPassengers(temp);
  }, [ids, passengers, setSelectedPassengers]);
  const columns = [
    {
      dataField: "name",
      classes: "text-left text-primary font-weight-bold",
    },
    {
      dataField: "gender",
      classes: "text-left font-weight-bold",
    },
    {
      dataField: "paxType",
      classes: "text-left font-weight-bold",
    },
    {
      formatter: TicketNoColumn,
      classes: "text-left pr-0 ",
      headerClasses: "text-left pr-3",
      // style: {
      //   maxWidth: "150px",
      // },
    },
  ];
  return (
    <div className="mt-5">
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities || []}
        columns={columns}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}

        selectRow={{
          ...getSelectRow({
            entities: entities,
            ids: ids,
            setIds: setIds,
          }),
          selectColumnStyle: { width: "20px" },
        }}
        // rowEvents={rowEvents}
        rowStyle={{ cursor: "pointer" }}
        // {...paginationTableProps}
      />
    </div>
  );
};
