import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/banks";

export default function useBankAccountsOptions() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ banks }) => banks.allBankAccounts);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllBankAccounts());
    }
  }, [dispatch, list]);
  const bankAccountsOptions = useMemo(
    () =>
      list?.map(({ accountNo, id, banks }) => ({
        value: id,
        label: `${banks?.name}\n${accountNo} `,
      })),
    [list]
  );
  return { bankAccountsOptions, loading };
}
