import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { TicketInfo } from "../orderSummary/TicketInfo";
import { AccountsSection } from "./AccountsSection";
import { AccountsSummary } from "./AccountsSummary";

export const OrderAccounts = () => {
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TicketInfo data={data} />
      </Grid>
      <Grid item xs={12} md={7}>
        <AccountsSection />
      </Grid>
      <Grid item xs={12} md={5}>
        <AccountsSummary />
      </Grid>
      {/* <Grid item xs={12}>
        <Paper className="p-3">
          <OrderPaymentsTable />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-5 d-flex justify-content-end">
          <Button className="mr-3" variant="light">
            Print
          </Button>
          <Button>Update Payments</Button>
        </Paper>
      </Grid> */}
    </Grid>
  );
};
