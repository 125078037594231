import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./communicationActions";

const initialState = {
  setEmails: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  communicationTypes: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  companyEmails: {
    listMap: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  updateNotificationStatus: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  notifications: {
    list: undefined,
    count: 0,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  specificNotification: {
    data: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  resetNotificationCount: {
    data: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
};

export const communicationReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.SET_NOTIFICATION:
        state.notifications.list = payload.data.notificationLogs;
        state.notifications.count = payload.data.notificationCount;
        break;
      case actions.RESET_NOTIFICATION_COUNT.REQUESTED:
        state.resetNotificationCount.loading = true;
        break;
      case actions.RESET_NOTIFICATION_COUNT.SUCCEEDED:
        state.resetNotificationCount.loading = false;
        if (payload.data.status === "200") {
          state.resetNotificationCount.success = true;
        } else {
          state.resetNotificationCount.failure =
            payload.data.message || "Something is wrong!";
        }
        break;
      case actions.RESET_NOTIFICATION_COUNT.FAILED:
        state.resetNotificationCount.loading = false;
        state.resetNotificationCount.failure = payload.error;
        break;
      case actions.RESET_NOTIFICATION_COUNT.CLEAR:
        state.resetNotificationCount = initialState.resetNotificationCount;
        break;
      case actions.GET_SPECIFIC_NOTIFICATION.REQUESTED:
        state.specificNotification.loading = true;
        break;
      case actions.GET_SPECIFIC_NOTIFICATION.SUCCEEDED:
        state.specificNotification.loading = false;
        if (payload.data.status === "200") {
          state.specificNotification.success = true;
          state.specificNotification.data = payload.data.data;
        } else {
          state.specificNotification.failure =
            payload.data.message || "Something is wrong!";
        }
        break;
      case actions.GET_SPECIFIC_NOTIFICATION.FAILED:
        state.specificNotification.loading = false;
        state.specificNotification.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_NOTIFICATION.CLEAR:
        state.specificNotification = initialState.specificNotification;
        break;
      case actions.UPDATE_NOTIFICATION_STATUS.REQUESTED:
        state.updateNotificationStatus.loading = true;
        break;
      case actions.UPDATE_NOTIFICATION_STATUS.SUCCEEDED:
        state.updateNotificationStatus.loading = false;
        if (payload.data.status === "200") {
          state.updateNotificationStatus.success = true;
        } else {
          state.updateNotificationStatus.failure =
            payload.data.message || "Something is wrong!";
        }
        break;
      case actions.UPDATE_NOTIFICATION_STATUS.FAILED:
        state.updateNotificationStatus.loading = false;
        state.updateNotificationStatus.failure = payload.error;
        break;
      case actions.UPDATE_NOTIFICATION_STATUS.CLEAR:
        state.updateNotificationStatus = initialState.updateNotificationStatus;
        break;
      case actions.UPDATE_COMPANY_EMAILS.REQUESTED:
      case actions.SET_COMPANY_EMAILS.REQUESTED:
        state.setEmails.loading = true;
        break;
      case actions.UPDATE_COMPANY_EMAILS.SUCCEEDED:
      case actions.SET_COMPANY_EMAILS.SUCCEEDED:
        state.setEmails.loading = false;
        if (payload.data.status === "200") {
          state.setEmails.success = true;
        } else {
          state.setEmails.failure =
            payload.data.message || "Something is wrong!";
        }
        break;
      case actions.UPDATE_COMPANY_EMAILS.FAILED:
      case actions.SET_COMPANY_EMAILS.FAILED:
        state.setEmails.loading = false;
        state.setEmails.failure = payload.error;
        break;
      case actions.UPDATE_COMPANY_EMAILS.CLEAR:
      case actions.SET_COMPANY_EMAILS.CLEAR:
        state.setEmails = initialState.setEmails;
        break;
      case actions.GET_ALL_COMMUNICATION_TYPES.REQUESTED:
        state.communicationTypes.loading = true;
        break;
      case actions.GET_ALL_COMMUNICATION_TYPES.SUCCEEDED:
        state.communicationTypes.loading = false;
        state.communicationTypes.success = true;
        state.communicationTypes.list = payload.data.data.$values;
        break;
      case actions.GET_ALL_COMMUNICATION_TYPES.FAILED:
        state.communicationTypes.loading = false;
        state.communicationTypes.failure = payload.error;
        break;
      case actions.GET_COMPANY_EMAILS.REQUESTED:
        state.companyEmails.loading = true;
        break;
      case actions.GET_COMPANY_EMAILS.SUCCEEDED:
        state.companyEmails.loading = false;
        state.companyEmails.success = true;
        const list = payload.data.data.$values;
        const emailTypeMap = new Map();
        list.forEach(({ communicationTypeId, email, isSubscribed }) => {
          emailTypeMap.set(communicationTypeId, {
            emails: [...email.$values],
            isSubscribed,
          });
        });
        state.companyEmails.listMap = emailTypeMap;
        state.companyEmails.isFirstTime = !emailTypeMap.size;
        break;
      case actions.GET_COMPANY_EMAILS.FAILED:
        state.companyEmails.loading = false;
        state.companyEmails.failure = payload.error;
        break;
      case actions.GET_COMPANY_EMAILS.CLEAR:
        state.companyEmails = initialState.companyEmails;
        break;

      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
