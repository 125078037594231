/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Paper, Typography, Modal, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NavLink, useHistory, useParams } from "react-router-dom";
import {
  ACCOUNT_SUB_USER,
  CREATE_SUB_USER,
  SUB_USER_PERMISSION,
} from "../../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../../_helpers";
import {
  Button,
  Input,
  LoadingSpinner,
  MenuComponent,
  Select,
  Seperator,
} from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/company";
import { getDateTime } from "../../../../_helpers/date";
import { parseRoute } from "../../../../../app/helpers/formatters";
import {
  ADMIN_CLIENT_USERS_CREATE,
  ADMIN_CLIENT_USER_PERMISSION,
} from "../../../../../app/routesMapAdmin";

export const SubUsersList = ({ className }) => {
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  return (
    <Paper>
      <CompanySubUsers companyId={companyInUserId} />
    </Paper>
  );
};

export const CompanySubUsers = ({ companyId }) => {
  const [openModal, setOpenModal] = useState(false);
  const { loading: listLoading, list } = useSelector(
    ({ company }) => company.users
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { loading: companyDataLoading } = useSelector(
    ({ company }) => company.companyData
  );

  const loading = companyDataLoading && listLoading;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCompanyUsers({ id: companyId }));
  }, []);
  const tableContent = list?.map((item, index) => (
    <TableRow
      key={`row_${index}`}
      data={item}
      openModal={() => setOpenModal(true)}
    />
  ));
  const LoginSchema = Yup.object().shape({
    paymentMethod: Yup.string(),
    currency: Yup.string(),
    creditAmount: Yup.number(),
    noteType: Yup.string(),
    creditNotes: Yup.number(),
  });
  const formik = useFormik({
    initialValues: {
      paymentMethod: "",
      currency: "",
      creditAmount: undefined,
      noteType: "",
      creditNotes: undefined,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(false);
    },
  });
  const { setFieldValue, values, touched, errors, handleChange } = formik;
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="bg-white rounded"
          style={{ maxWidth: "600px", flexGrow: 1, overflow: "hidden" }}
        >
          <div className="bg-primary p-7">
            <Typography variant="h5" className="font-weight-bolder text-white">
              Welcome to Flyme247
            </Typography>
          </div>
          <div className="py-7 px-20" style={{ color: "#464E5F" }}>
            <Grid container spacing={3}>
              <Grid item xs className="d-flex align-items-center">
                <div className="symbol symbol-75 symbol-light mr-4 rounded-circle p-0">
                  <span className="symbol-label rounded-circle overflow-hidden p-0">
                    <span className="svg-icon svg-icon-5x rounded-circle  mt-auto">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                      />
                    </span>
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    Ahmed Adel
                  </a>
                  <a
                    href="#"
                    className="text-primary font-weight-bolder  mb-1 font-size-sm"
                  >
                    Info@email.com
                  </a>
                  <a
                    href="#"
                    className="text-muted font-weight-bolder  mb-1 font-size-sm"
                  >
                    Flyme247 Ltd.
                  </a>
                </div>
              </Grid>
              <Grid
                item
                xs
                className="bg-white border rounded border-dark p-7 min-w-200px text-center h-100 d-flex flex-column justify-content-center"
              >
                <Typography variant="h4" className="mb-3">
                  <span className="text-primary">$</span>
                  445.50
                </Typography>
                <Typography variant="h6" className="mb-2">
                  Available Balance
                </Typography>
                <Typography variant="body2">
                  Payable Balance: $445.50
                </Typography>
              </Grid>
            </Grid>
            <Seperator />
            <Select
              onChange={(value) => setFieldValue("paymentMethod", value)}
              value={values.paymentMethod}
              errorMessage={errors.paymentMethod}
              showErrors={touched.paymentMethod}
              fixedLabelWidth
              horizontal
              label="Payment Method"
              spacing
            />
            <div className="d-flex align-items-end">
              <Input
                fixedLabelWidth
                horizontal
                placeholder="Amount here"
                label="Credit Amount"
                name="creditAmount"
                onChange={handleChange}
                value={values.creditAmount}
                errorMessage={errors.creditAmount}
                showErrors={touched.creditAmount}
                spacing
              />
              <Select
                onChange={(value) => setFieldValue("currency", value)}
                value={values.currency}
                errorMessage={errors.currency}
                showErrors={touched.currency}
                className="max-w-75px ml-3"
                fixedLabelWidth
                spacing
              />
            </div>
            <div className="d-flex align-items-end">
              <Input
                name="creditNotes"
                onChange={handleChange}
                value={values.creditNotes}
                errorMessage={errors.creditNotes}
                showErrors={touched.creditNotes}
                fixedLabelWidth
                horizontal
                placeholder="Amount here"
                label="Credit Notes"
                spacing
              />
              <Select
                onChange={(value) => setFieldValue("noteType", value)}
                value={values.noteType}
                errorMessage={errors.noteType}
                showErrors={touched.noteType}
                className="max-w-75px ml-3"
                fixedLabelWidth
                spacing
              />
            </div>
            <div className="d-flex justify-content-center mt-7 mb-3">
              <Button onClick={() => {}} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
      <div className="card-header d-flex justify-content-between align-items-center border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Sub Users
          </span>
          {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
            More than 15+ new sub users
          </span> */}
        </h3>
        <div className="card-toolbar">
          {/* <Button
            variant="primary-inverse"
            className="font-weight-bolder mr-3"
            smallPadding
          >
            <span className="svg-icon svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} />
            </span>
            Export
          </Button> */}
          <NavLink
            to={parseRoute(
              isAdmin ? ADMIN_CLIENT_USERS_CREATE : CREATE_SUB_USER,
              { id: companyId }
            )}
            className="btn btn-danger font-weight-bolder font-size-sm"
          >
            Create
          </NavLink>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-7" style={{ minWidth: "150px" }}>
                      <span className="text-dark-75">User Name</span>
                    </th>
                    <th style={{ minWidth: "100px" }}>Email</th>
                    <th style={{ minWidth: "100px" }}>Created Date</th>
                    {/* <th style={{ minWidth: "100px" }}>Company</th> */}
                    <th style={{ minWidth: "80px" }} className="text-right">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>{tableContent}</tbody>
              </table>
            </div>
          )}
        </div>
        {/* end::Tap pane Day */}
      </div>
    </>
  );
};

export const SubUserDropDown = ({ openModal, userId }) => {
  return (
    <div className="card-toolbar">
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi navi-hover">
            <li className="navi-item">
              <NavLink
                to={parseRoute(ACCOUNT_SUB_USER, { userId })}
                className="navi-link"
              >
                <span className="navi-text">View user</span>
              </NavLink>
            </li>
            <li className="navi-item">
              <NavLink
                to={parseRoute(SUB_USER_PERMISSION, { userId })}
                className="navi-link"
              >
                <span className="navi-text">Edit Permissions</span>
              </NavLink>
            </li>
            <li className="navi-item">
              <button
                onClick={openModal}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Allocate Funds</span>
              </button>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const TableRow = ({ data, openModal }) => {
  const history = useHistory();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { email, name, createdAt, roleInUserId, id } = data;
  const { id: clientId } = useParams();
  const { date, time } = getDateTime(createdAt);
  const { name: companyName } = useSelector(
    ({ company }) => company.companyData.data
  );
  const menuOptions = [
    // {
    //   label: "View",
    //   onClick: () => history.push(parseRoute(ACCOUNT_SUB_USER, { userId: id })),
    // },
    {
      label: "Edit Permissions",
      onClick: () =>
        history.push(
          isAdmin
            ? parseRoute(ADMIN_CLIENT_USER_PERMISSION, {
                userId: id,
                id: clientId,
              })
            : parseRoute(SUB_USER_PERMISSION, { userId: id })
        ),
    },
    // ...(!isAdmin ? [{ label: "Allocate Funds", onClick: openModal }] : []),
  ];
  return (
    <tr>
      <td className="pl-0">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50 symbol-light mr-4">
            <span className="symbol-label">
              <span className="svg-icon h-75 align-self-end">
                <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
              </span>
            </span>
          </div>
          <div>
            <a
              href="#"
              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
            >
              {name}
            </a>
          </div>
        </div>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {email}
        </span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      {/* <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {companyName}
        </span>
        <span className="text-muted font-weight">{roleInUserId || "-"}</span>
      </td> */}
      <td className="text-right">
        <MenuComponent options={menuOptions} />
      </td>
      {/* <td className="pr-0 text-right">
        <SubUserDropDown userId={id} openModal={openModal} />
      </td> */}
    </tr>
  );
};
