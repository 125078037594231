import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { Button, TextFieldContainer } from "../common";

export const Contact = ({ formData }) => {
  return (
    <Paper style={{padding:'5rem 10vw'}}>
      <Typography variant="h6" className='mb-3'>Contact us</Typography>
      <Typography variant="body1" className='mb-5'>
        contact us if you have any questions
      </Typography>
      <form onSubmit={formData.handleSubmit}>
        <TextFieldContainer
          vertical
          label="Full Name"
          showErrors={formData.touched.fullName}
          errorMessage={formData.errors.fullName}
          inputProps={formData.getFieldProps("fullName")}
          placeholder="Name here"
          variant="secondary"
        />
        <TextFieldContainer
          vertical
          label="E-Mail Address"
          showErrors={formData.touched.email}
          errorMessage={formData.errors.email}
          inputProps={formData.getFieldProps("email")}
          placeholder="E-mail Address "
          variant="secondary"
        />
        <TextFieldContainer
          vertical
          showErrors={formData.touched.msg}
          errorMessage={formData.errors.msg}
          inputProps={formData.getFieldProps("msg")}
          placeholder="message details"
          multiline
          variant="secondary"
        />
        <Button className="w-100" type="submit">
          Send
        </Button>
      </form>
    </Paper>
  );
};
