export const getPhoneNumberAndCode = (phoneNumber = "") => {
  let code = "";
  let number = "";
  if (phoneNumber?.includes("-")) {
    const numberArr = phoneNumber?.split("-");
    code = numberArr?.[0];
    number = numberArr[1];
  } else {
    number = phoneNumber;
  }
  return { code, number };
};

export const generatePhoneValue = ({ number = "", code = "" }) => {
  return [code, number].join("-");
};
