import React from "react";
import { withStyles, FormControl, InputBase } from "@material-ui/core";
import clsx from "clsx";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F3F6F9",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export const CustomInput = ({
  id,
  label,
  placeholder,
  classNames,
  horizontal,
  spacing,
  multiline,
  labelClassNames,
  percentage,
  inputProps,
  type,
  inputClassName,
  onChange,
  value,
  name,
  showErrors,
  errorMessage,
  noErrorMessage,
}) => {
  const containerClassNames = clsx("d-flex w-100", {
    "justify-content-start flex-column": !horizontal,
    "align-items-center justify-content-between": horizontal,
    "mb-5": spacing,
  });
  const formClassNames = classNames || (horizontal ? "ml-5" : "mt-5");
  return (
    <div className={containerClassNames}>
      {label && (
        <span
          style={{ whiteSpace: "nowrap" }}
          className={labelClassNames}
          htmlFor={id || label}
        >
          {label}
        </span>
      )}
      <div className="w-100">
        <FormControl
          style={{ borderRadius: 4 }}
          className={clsx("position-relative overflow-hidden w-100", formClassNames)}
        >
          <BootstrapInput
            multiline={multiline}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            id={id || label}
            name={name}
            {...inputProps}
            type={type}
            className={clsx("w-100", inputClassName)}
          />
          {percentage && (
            <div className="h-100 w-20px d-flex align-items-center justify-content-center bg-primary text-white position-absolute ml-auto right-0 top-0">
              <span>%</span>
            </div>
          )}
        </FormControl>
        {!noErrorMessage && (
          <div className="fv-plugins-message-container h-20px ">
            <div className="fv-help-block">{showErrors && errorMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};
