import React, { useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDateTime } from "../../../../../../_helpers/date";
import { actions } from "../../../../../../../redux/state/supplier";
import {
  PAYMENT_TYPE_REFUND,
  PAYMENT_TYPE_TOPUP,
  PAYMENT_TYPE_VOID,
  PAYMENT_TYPE_ISSUE,
  PAYMENT_TYPE_REFUND_FEE,
  PAYMENT_TYPE_REISSUE,
  PAYMENT_TYPE_VOID_FEE,
  PAYMENT_TYPE_CREDIT_TOPUP,
} from "../../../../../../../redux/state/accountBalance/accountBalanceConstants";
import { GoBack, LoadingSpinner } from "../../../../common";
import { useParams } from "react-router-dom";

const paymentTypeText = {
  [PAYMENT_TYPE_VOID]: "Void",
  [PAYMENT_TYPE_REFUND]: "Refund",
  [PAYMENT_TYPE_ISSUE]: "Issue Tickets",
  [PAYMENT_TYPE_REISSUE]: "Reissue",
  [PAYMENT_TYPE_REFUND_FEE]: "Refund Fee",
  [PAYMENT_TYPE_TOPUP]: "Balance Top up",
  [PAYMENT_TYPE_CREDIT_TOPUP]: "Credit Top up",
  [PAYMENT_TYPE_VOID_FEE]: "Void Fee",
};

const TableRow = ({ data }) => {
  const {
    createdAt,
    bookingTransactionId,
    currency,
    type,
    paymentAmount,
    status,
    remarks,
    availableBalanceAfterTransaction,
    payableAmountAfterTransaction,
    availableCreditAfterTransaction,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  return (
    <tr>
      <td>
        <span className="font-weight-bold">{bookingTransactionId || "-"}</span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      <td>
        <span className="font-weight-bold">{remarks}</span>
      </td>
      <td>
        <span>{paymentTypeText[status] || "-"}</span>
      </td>
      <td>
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {currency}
          {paymentAmount.toFixed(2)}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">
          {currency}
          {+availableBalanceAfterTransaction.toFixed(2)}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">
          {currency}
          {+availableCreditAfterTransaction.toFixed(2)}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">
          {currency}
          {+(payableAmountAfterTransaction || 0).toFixed(2)}
        </span>
      </td>

      {/* <td className="text-center">
        <Button variant="primary-inverse">View</Button>
      </td> */}
    </tr>
  );
};

export const SupplierBalanceHistory = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    ({ supplier }) => supplier.supplierBalanceHistory
  );
  const { id } = useParams();
  useEffect(() => {
    dispatch(actions.getSupplierBalanceHistory({ supplierId: id }));
  }, [dispatch, id]);
  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <GoBack />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper id="billingHistory" className="p-7">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Supplier Balance History</Typography>
          </div>
          <div className="table-responsive">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <table className="table table-vertical-center mb-0 mt-5">
                <thead>
                  <tr className="bg-gray-100">
                    <th>Booking Id</th>
                    <th>Date</th>
                    <th>remark</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Available Balance</th>
                    <th>Available Credit</th>
                    <th>Payable</th>
                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
            )}
            {list?.length === 0 && (
              <Typography
                variant="h5"
                className="text-center w-100 text-muted my-5"
              >
                No Records to show
              </Typography>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
