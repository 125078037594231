import React from "react";
import { pax_types_text } from "../../../../../../../redux/state/booking/bookingConstants";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";

export const PassengersList = ({ passengers, noTicket }) => {
  const passengerArr = passengers?.$values || passengers;
  return (
    <CardWithBlueHeader
      title="Passenger information"
      print
      iconSrc="/media/svg/icons/General/person.svg"
    >
      <div>
        <div>
          <div className="table-responsive">
            <table className="table text-center table-vertical-center mb-0">
              <thead className="bg-gray-100 rounded">
                <tr className="text-muted">
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Nationality</th>
                  <th>Birth Of Date</th>
                  <th>Passport No.</th>
                  <th>Expire Date</th>
                  <th>Pax Type</th>
                  {!noTicket && <th>Ticket No.</th>}
                </tr>
              </thead>
              <tbody>
                {passengerArr?.map(
                  (
                    {
                      dateOfBirth,
                      ticketNo,
                      id,
                      gender,
                      firstName,
                      lastName,
                      passportNo,
                      passportNationality,
                      paxType,
                      passportExpDate,
                    },
                    index
                  ) => (
                    <tr
                      key={`pax-${index}`}
                      style={{ border: "unset" }}
                      className="font-weight-bold"
                    >
                      <td>
                        <span>{`${lastName}/${firstName}`}</span>
                      </td>
                      <td>
                        <span>{gender}</span>
                      </td>
                      <td>
                        <span>{passportNationality}</span>
                      </td>
                      <td>
                        <span>{dateOfBirth}</span>
                      </td>
                      <td>
                        <span>{passportNo}</span>
                      </td>
                      <td>
                        <span>{passportExpDate}</span>
                      </td>
                      <td>
                        <span>{pax_types_text[paxType]}</span>
                      </td>
                      {!noTicket && (
                        <td>
                          <span>{ticketNo || "-"}</span>
                        </td>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CardWithBlueHeader>
  );
};
