import React from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Button, Input, Select } from "../../../common";
import {
  accountTypesLabel,
  ACCOUNT_TYPE_ASSETS,
  ACCOUNT_TYPE_EQUITY,
  ACCOUNT_TYPE_EXPENSES,
  ACCOUNT_TYPE_LIABILITIES,
  ACCOUNT_TYPE_REVENUES,
} from "../../../../../../redux/state/accounting/accountingConstants";

const typesOptions = [
  { label: accountTypesLabel[ACCOUNT_TYPE_ASSETS], value: ACCOUNT_TYPE_ASSETS },
  {
    label: accountTypesLabel[ACCOUNT_TYPE_LIABILITIES],
    value: ACCOUNT_TYPE_LIABILITIES,
  },
  { label: accountTypesLabel[ACCOUNT_TYPE_EQUITY], value: ACCOUNT_TYPE_EQUITY },
  {
    label: accountTypesLabel[ACCOUNT_TYPE_REVENUES],
    value: ACCOUNT_TYPE_REVENUES,
  },
  {
    label: accountTypesLabel[ACCOUNT_TYPE_EXPENSES],
    value: ACCOUNT_TYPE_EXPENSES,
  },
];

export const AccountTypesForm = ({ fields, show, loading, onHide }) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;

  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>Account Type</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("name")}
                showErrors={touched["name"]}
                errorMessage={errors["name"]}
                variant="secondary"
                label="Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                showErrors={touched["type"]}
                errorMessage={errors["type"]}
                value={values.type}
                onChange={(value) => setFieldValue("type", value)}
                variant="secondary"
                label="Type"
                options={typesOptions}
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button loading={loading} smallPadding type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
