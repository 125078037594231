import React, { useEffect, useMemo, useCallback } from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import SVG from "react-inlinesvg";

import { Button, Select, Input } from "../common";
import { actions } from "../../../../redux/state/payment";
import { actions as banksActions } from "../../../../redux/state/banks";
import { actions as filesActions } from "../../../../redux/state/files";
import { currencyOptions } from "../../../_assets/js/components/options";
import {
  PAYMENT_TYPE_TOPUP,
  topUpStatusMap,
} from "../../../../redux/state/accountBalance/accountBalanceConstants";
import useAgenciesOptions from "../../../../app/helpers/hooks/useAgenciesOptions";
import { ConfirmationModal } from "../bookingManagement/common/ConfirmationModal";
import { useState } from "react";
import useCompanyName from "../../../../app/helpers/hooks/useCompanyName";

export const TopUpFormModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { agencyOptions } = useAgenciesOptions();
  const { getCompanyName } = useCompanyName();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { id: companyId, name: companyName } =
    useSelector(({ company }) => company.companyData.data) || {};
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  const { id: userId } = useSelector(({ user }) => user.userData);
  const { list: banksList } = useSelector(({ banks }) => banks.allBanks);
  const { list: bankAccountsList } = useSelector(
    ({ banks }) => banks.allBankAccounts
  );
  const { list: paymentMethodsList } = useSelector(
    ({ banks }) => banks.allPaymentMethods
  );
  const { url, loading: uploadLoading, success: uploadSuccess } = useSelector(
    ({ files }) => files.upload
  );
  const { loading: createLoading, success: createSuccess } = useSelector(
    ({ files }) => files.upload
  );
  const proofSchema = Yup.object().shape({
    ...(isAdmin
      ? {
          agency: Yup.number()
            .positive()
            .required("Agency is required"),
        }
      : {}),
    bank: Yup.number()
      .positive()
      .required("Bank is required"),
    paymentMethod: Yup.number().required("Payment method is required"),
    bankAccount: Yup.number().required("Bank Account is required"),
    paymentAmount: Yup.number()
      .positive()
      .required("Amount is required"),
    currency: Yup.string().required("Currency is required"),
    paymentProof: Yup.mixed().when("paymentMethod", {
      is: matchesMd,
      then: Yup.mixed().required("Proof upload payment proof"),
    }),
    // paymentProof: Yup.mixed().required("Proof upload payment proof"),
    remarks: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      paymentMethod: "",
      bank: "",
      bankAccount: "",
      paymentAmount: "",
      currency: "",
      paymentProof: "",
      remarks: "",
      agency: "",
    },
    validationSchema: proofSchema,
    onSubmit: () => {
      setOpenConfirmationModal(true);
    },
  });
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    resetForm,
  } = formik;
  const { paymentProof, paymentAmount, agency } = values;
  const banksOptions = useMemo(
    () =>
      banksList
        ?.filter(({ isActive }) => isActive)
        .map(({ name, id }) => ({ label: name, value: `${id}` })),
    [banksList]
  );
  const requestTopupLoading = uploadLoading || createLoading;

  const onConfirm = useCallback(() => {
    const formData = new FormData();
    formData.append("file", paymentProof, paymentProof.name);
    dispatch(filesActions.uploadFile({ userId, formData }));
  }, [paymentProof, dispatch, userId]);

  const paymentMethodOptions = useMemo(
    () =>
      paymentMethodsList
        ?.filter(({ isActive }) => isActive)
        .map(({ name, id }) => ({
          label: name,
          value: `${id}`,
        })),
    [paymentMethodsList]
  );

  const getBankAccountOptions = useCallback(
    (selectedBankId) => {
      if (!selectedBankId || !bankAccountsList?.length) return;
      return bankAccountsList
        .filter(
          ({ bankId, isActive }) => +selectedBankId === bankId && isActive
        )
        .map(({ accountNo, id }) => ({ label: accountNo, value: id }));
    },
    [bankAccountsList]
  );
  useEffect(() => {
    dispatch(banksActions.getAllBanks());
    dispatch(banksActions.getAllPaymentMethods());
    dispatch(banksActions.getAllBankAccounts());
    return () => {
      dispatch(actions.reset());
      dispatch(filesActions.clearUpload());
    };
  }, [companyId, dispatch]);
  useEffect(() => {
    if (uploadSuccess) {
      const {
        paymentMethod,
        bank,
        bankAccount,
        paymentAmount,
        currency,
        remarks,
        agency,
      } = values;
      dispatch(
        actions.createTopUps({
          companyName,
          paymentMethod: +paymentMethod,
          bankId: +bank,
          companyId: isAdmin ? +agency : companyId,
          bankAccountId: +bankAccount,
          topupStatus: PAYMENT_TYPE_TOPUP,
          status: topUpStatusMap.pending,
          paymentAmount,
          currency,
          paymentProof: url,
          remarks: isAdmin ? "TopUp By Flyme" : remarks,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);
  useEffect(() => {
    if (createSuccess) {
      setOpen(false);
      setOpenConfirmationModal(false);
      resetForm();
      dispatch(actions.clearCreateTopUps());
      dispatch(filesActions.clearUpload());
    }
  }, [createSuccess, dispatch, resetForm, setOpen]);

  const agencyName = useMemo(() => getCompanyName(agency), [
    agency,
    getCompanyName,
  ]);
  return (
    <Modal
      size="lg"
      onHide={() => setOpen(false)}
      show={open}
      // className="d-flex align-items-center justify-content-center"
    >
      <>
        <ConfirmationModal
          onHide={() => {
            setOpenConfirmationModal(false);
          }}
          show={OpenConfirmationModal}
          title="Top Up Confirmation"
          onConfirm={onConfirm}
          loading={requestTopupLoading}
        >
          {isAdmin ? (
            <Typography>
              Are you sure you want to top up{" "}
              <span className="font-weight-bolder">${paymentAmount}</span> to{" "}
              <span className="font-weight-bold">{agencyName}</span>?
            </Typography>
          ) : (
            <Typography>
              Are you sure you want to request top up for{" "}
              <span className="font-weight-bolder">${paymentAmount}</span>?
            </Typography>
          )}
        </ConfirmationModal>
        <div className="bg-primary p-7">
          <Typography variant="h5" className="font-weight-bolder text-white">
            {isAdmin ? "Add" : "Request"} Top up
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit}
          className="py-7 px-20"
          style={{ color: "#464E5F" }}
        >
          {isAdmin && (
            <Select
              options={agencyOptions}
              showErrors={touched.agency}
              errorMessage={errors.agency}
              onChange={(value) => setFieldValue("agency", value)}
              value={values.agency}
              name="agency"
              outlined={false}
              fixedLabelWidth
              spacing
              horizontal
              label="Agency"
            />
          )}
          <Select
            options={banksOptions}
            showErrors={touched.bank}
            errorMessage={errors.bank}
            onChange={(value) => setFieldValue("bank", value)}
            value={values.bank}
            name="bank"
            outlined={false}
            fixedLabelWidth
            spacing
            horizontal
            label="Bank"
          />
          <Select
            options={getBankAccountOptions(values.bank)}
            showErrors={touched.bankAccount}
            errorMessage={errors.bankAccount}
            onChange={(value) => setFieldValue("bankAccount", value)}
            value={values.bankAccount}
            name="bankAccount"
            outlined={false}
            disabled={!values.bank}
            fixedLabelWidth
            spacing
            horizontal
            label="Bank Account"
          />
          <Select
            options={paymentMethodOptions}
            showErrors={touched.paymentMethod}
            errorMessage={errors.paymentMethod}
            onChange={(value) => setFieldValue("paymentMethod", value)}
            value={values.paymentMethod}
            name="paymentMethod"
            outlined={false}
            fixedLabelWidth
            spacing
            horizontal
            label="Payment Method"
          />
          <div className="d-flex align-items-end w-100">
            <span style={{ flexGrow: 1 }}>
              <Input
                showErrors={touched.paymentAmount}
                errorMessage={errors.paymentAmount}
                onChange={handleChange}
                value={values.paymentAmount}
                name="paymentAmount"
                outlined={false}
                fixedLabelWidth
                horizontal
                label="Payment Amount"
                placeholder="Type here"
                spacing
              />
            </span>
            <span className="w-150px ml-3">
              <Select
                showErrors={touched.currency}
                errorMessage={errors.currency}
                onChange={(value) => setFieldValue("currency", value)}
                value={values.currency}
                name="currency"
                outlined={false}
                fixedLabelWidth
                classNames="m-0"
                placeholder="currency"
                options={currencyOptions}
                spacing
              />
            </span>
          </div>
          {matchesMd && (
            <div className="d-flex my-1 align-items-center">
              <span className="min-w-150px mb-2">Upload files</span>
              <label className="btn btn-primary w-350px ">
                + Upload Files
                <input
                  onChange={(e) =>
                    setFieldValue("paymentProof", e.target.files[0])
                  }
                  type="file"
                  accept="image/* application/pdf"
                  hidden
                />
              </label>
              <span className="ml-5 text-muted">
                (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
              </span>
            </div>
          )}
          <div className="d-flex mb-6">
            <span className="min-w-150px"></span>
            {touched.paymentProof && (
              <div className="fv-plugins-message-container h-20px max-w-100">
                <div className="fv-help-block">{errors.paymentProof}</div>
              </div>
            )}
            {paymentProof && (
              <div className="w-350px">
                <div className="d-flex w-100 mb-1 align-items-center">
                  {/* <SVG src="/media/svg/icons/General/valid.svg" /> */}
                  <Typography className="ml-3" variant="body1">
                    {paymentProof?.name}
                  </Typography>
                  <button
                    type="button"
                    onClick={() => setFieldValue("paymentProof", "")}
                    className="btn btn-icon ml-auto"
                  >
                    <SVG src="/media/svg/icons/General/cancel.svg" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* <div className="d-flex mb-6 align-items-center">
          <span className="min-w-150px">Payment proof</span>
          <label>
            <input
              onChange={(e) =>
                setFieldValue("paymentProof", e.target.files[0])
              }
              type="file"
              accept="image/*"
            />
          </label>
        </div> */}
          {!isAdmin && (
            <div className="mt-3">
              <Input
                showErrors={touched.remarks}
                errorMessage={errors.remarks}
                onChange={handleChange}
                value={values.remarks}
                name="remarks"
                outlined={false}
                fixedLabelWidth
                classNames="ml-0"
                labelClassNames="mr-25"
                horizontal
                label="Remark"
                placeholder="Type here"
                multiline
              />
            </div>
          )}
          <div className="d-flex justify-content-center mt-7 mb-3">
            <Button
              onClick={() => setOpen(false)}
              variant="light"
              type="button"
              smallPadding
              className="mr-2"
            >
              Cancel
            </Button>
            <Button smallPadding loading={requestTopupLoading} type="submit">
              Save
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
};
