import React, { useCallback, useEffect } from "react";
import { Grid, Paper, Switch, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, PhoneInput, Select } from "../../../common";
import { actions } from "../../../../../../redux/state/supplier";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { useParams, useHistory } from "react-router";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import {
  getCitiesOptions,
  getCountriesOptions,
} from "../../../../../_assets/js/components/options";
import {
  generatePhoneValue,
  getPhoneNumberAndCode,
} from "../../../../../../app/helpers/formatters/phone";
import { ADMIN_SUPPLIERS } from "../../../../../../app/routesMapAdmin";

const supplierSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Email is required"),
  name: Yup.string().required("Name is required"),
  // address: Yup.string().required("Address is required"),
  phoneNumber: Yup.number().required("Phone is required"),
  phoneCode: Yup.number().required("Phone Code is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  address: Yup.string(),
  // supplierType: Yup.string().required("Supplier Type is required"),
  // allocateBalance: Yup.string().required("Balance is required"),
});

export const SupplierForm = ({ isEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ supplier }) => supplier.create);
  const {
    loading: editLoading,
    success: editSuccess,
    failure: editFailure,
  } = useSelector(({ supplier }) => supplier.edit);

  const {
    data,
    // loading: getSupplierLoading,
    success: getSupplierSuccess,
  } = useSelector(({ supplier }) => supplier.specificSupplier);

  useEffect(() => {
    if (isEdit) dispatch(actions.getSupplier({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id, isEdit]);

  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Supplier was created successfully",
        })
      );
      dispatch(actions.reset());
      history.push(ADMIN_SUPPLIERS);
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [createSuccess, dispatch, history]);
  useEffect(() => {
    if (createFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: createFailure,
        })
      );
      dispatch(actions.reset());
    }
  }, [createFailure, dispatch]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Supplier was edited successfully",
        })
      );
      dispatch(actions.reset());
      history.push(ADMIN_SUPPLIERS);
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, editSuccess, history]);
  useEffect(() => {
    if (editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't edit Supplier",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, editFailure]);
  const loading = createLoading || editLoading;

  const create = (params) => {
    dispatch(actions.createSupplier(params));
  };
  const edit = (params) => {
    dispatch(actions.editSupplier(params));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phoneNumber: "",
      phoneCode: "",
      country: "",
      city: "",
      code: "",
      address: "",
      isActive: true,
    },
    validationSchema: supplierSchema,
    onSubmit: ({
      email,
      code,
      name,
      address,
      phoneNumber,
      phoneCode,
      country,
      city,
      isActive,
    }) => {
      const contactNumber = generatePhoneValue({
        code: phoneCode,
        number: phoneNumber,
      });
      if (isEdit) {
        edit({
          ...data,
          email,
          code,
          name,
          address,
          contactNumber,
          country,
          city,
          isActive,
        });
      } else {
        create({
          email,
          code,
          name,
          address,
          contactNumber,
          country,
          city,
          isActive,
        });
      }
    },
  });
  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    setValues,
  } = formik;
  useEffect(() => {
    setFieldValue("city", "");
  }, [setFieldValue, values.country]);
  const setInitialValues = useCallback(() => {
    const {
      name,
      address,
      code,
      contactNumber,
      country,
      city,
      email,
      isActive,
    } = data || {};
    const { code: phoneCode, number: phoneNumber } = getPhoneNumberAndCode(
      contactNumber
    );
    setValues({
      email: email || "",
      name: name || "",
      phoneCode: phoneCode || "",
      phoneNumber: phoneNumber || "",
      country: country || "",
      city: city || "",
      code: code || "",
      address: address || "",
      isActive: isActive || false,
    });
  }, [data, setValues]);
  useEffect(() => {
    if (getSupplierSuccess && isEdit) {
      setInitialValues();
    }
  }, [getSupplierSuccess, isEdit, setInitialValues]);
  // useEffect(() => {
  //   setFieldValue("city", "");
  // }, [setFieldValue, values.country]);

  return (
    <Paper className="p-7">
      <Typography variant="h5" className="mb-5">
        {isEdit ? "Edit Supplier" : "Add New Supplier"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              name="name"
              showErrors={touched["name"]}
              errorMessage={errors["name"]}
              {...getFieldProps("name")}
              label="Supplier Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              disabled={isEdit}
              required
              variant="secondary"
              name="code"
              showErrors={touched["code"]}
              errorMessage={errors["code"]}
              {...getFieldProps("code")}
              label="Supplier Code"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              name="email"
              showErrors={touched["email"]}
              errorMessage={errors["email"]}
              {...getFieldProps("email")}
              label="Email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              required
              variant="secondary"
              label="Phone"
              showErrorsCode={touched.phoneCode}
              errorMessageCode={errors.phoneCode}
              valueCode={values.phoneCode}
              onChangeCode={(value) => setFieldValue("phoneCode", value)}
              showErrorsPhone={touched.phoneNumber}
              errorMessagePhone={errors.phoneNumber}
              fieldPropsPhone={{ ...getFieldProps("phoneNumber") }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              variant="secondary"
              name="country"
              showErrors={touched["country"]}
              errorMessage={errors["country"]}
              label="Country"
              options={getCountriesOptions()}
              onChange={(value) => setFieldValue("country", value)}
              value={values.country}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              variant="secondary"
              name="city"
              showErrors={touched["city"]}
              errorMessage={errors["city"]}
              disabled={values.country === ""}
              options={[
                { label: "Select City", value: "", disabled: true },
                ...getCitiesOptions(values.country),
              ]}
              onChange={(value) => setFieldValue("city", value)}
              value={values.city}
              label="City"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              name="address"
              showErrors={touched["address"]}
              errorMessage={errors["address"]}
              {...getFieldProps("address")}
              label="Address"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label className="d-flex flex-column align-items-start justify-content-between w-50px">
              Active
              <Switch
                checked={values.isActive}
                onChange={(e) => setFieldValue("isActive", e.target.checked)}
                color="primary"
              />
            </label>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-center mt-5">
          <Button loading={loading} type="submit">
            {isEdit ? "Save" : "Add New Supplier"}
          </Button>
        </div>
      </form>
    </Paper>
  );
};
