import produce from "immer";
import { checkForAlphabets } from "../../../_metronic/_helpers/string";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./flightSearchActions";

const initialState = {
  startSearch: {
    id: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  reprice: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  verifyTickets: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  fareRules: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  searchParams: undefined,
  searchDetails: undefined,
  // searchDetails: {
  //   numOfAdults: 1,
  //   numOfInfants: 0,
  //   numOfChildren: 0,
  //   tripType: "R",
  //   flightClass: "EC",
  //   multi: 1,
  //   arrivalAirport: "LHR",
  //   departAirport: "RUH",
  //   departDate: "2022-02-25",
  //   returnDate: "2022-03-05",
  //   isDirectFlight: false,
  // },

  searchResults: {
    loading: false,
    failure: undefined,
    success: undefined,
    complete: undefined,
    airlines: [],
    // airlines: [
    //   {
    //     refundable: "false",
    //     gds: "PKF",
    //     id: "PKF1",
    //     totalAmt: "333.23",
    //     totalTax: "235.39",
    //     supplierTags: {
    //       supplierAmt: 420.53,
    //       supplierTax: 311.06,
    //       supplierCurrency: "USD",
    //       adtFare: "109.47",
    //       adtTax: "311.06",
    //       solutionId: "152528684113798442",
    //     },
    //     cabinClass: "Economy",
    //     ltd: "2022-02-23 05:59:00",
    //     abf: "82.84",
    //     atx: "250.39",
    //     ayq: "0.00",
    //     cancellationCharges: {},
    //     segments: [
    //       {
    //         totalDuration: "490",
    //         iSeg: [
    //           {
    //             dDat: "2022-02-25",
    //             aDat: "2022-02-25",
    //             aTime: "06:30",
    //             dTime: "04:40",
    //             dApt: "RUH",
    //             bc: "Q",
    //             aApt: "CAI",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "2650",
    //             dTer: "1",
    //             aTer: "3",
    //             fba: "2PC",
    //             segmentDuration: "170",
    //           },
    //           {
    //             dDat: "2022-02-25",
    //             aDat: "2022-02-25",
    //             aTime: "20:35",
    //             dTime: "17:15",
    //             dApt: "CAI",
    //             bc: "Q",
    //             aApt: "LHR",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "779",
    //             dTer: "3",
    //             aTer: "2",
    //             fba: "2PC",
    //             segmentDuration: "320",
    //           },
    //         ],
    //         stop: "1",
    //       },
    //       {
    //         totalDuration: "0",
    //         iSeg: [
    //           {
    //             layoverTime: "",
    //             dDat: "2022-03-05",
    //             aDat: "2022-03-06",
    //             aTime: "00:30",
    //             dTime: "22:40",
    //             dApt: "RUH",
    //             bc: "S",
    //             aApt: "CAI",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "2648",
    //             dTer: "1",
    //             aTer: "3",
    //             fba: "2PC",
    //             segmentDuration: "170",
    //           },
    //           {
    //             layoverTime: "",
    //             dDat: "2022-03-06",
    //             aDat: "2022-03-06",
    //             aTime: "20:35",
    //             dTime: "17:15",
    //             dApt: "CAI",
    //             bc: "S",
    //             aApt: "LHR",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "779",
    //             dTer: "3",
    //             aTer: "2",
    //             fba: "2PC",
    //             segmentDuration: "320",
    //           },
    //         ],
    //         stop: "1",
    //       },
    //     ],
    //     stop: "1",
    //     ma: "MS",
    //     isConnecting: "true",
    //     markups: [
    //       {
    //         ruleName: "Admin: Normal Makup",
    //         perAdult: "15",
    //       },
    //     ],
    //   },
    //   {
    //     refundable: "false",
    //     gds: "PKF",
    //     id: "PKF2",
    //     totalAmt: "4321.65",
    //     totalTax: "205.56",
    //     supplierTags: {
    //       supplierAmt: 5691.070000000001,
    //       supplierTax: 271.64,
    //       supplierCurrency: "USD",
    //       adtFare: "5419.43",
    //       adtTax: "271.64",
    //       solutionId: "152528684039349663",
    //     },
    //     cabinClass: "Economy",
    //     ltd: "2022-02-23 04:59:00",
    //     abf: "4101.09",
    //     atx: "220.56",
    //     ayq: "0.00",
    //     cancellationCharges: {},
    //     segments: [
    //       {
    //         totalDuration: "490",
    //         iSeg: [
    //           {
    //             dDat: "2022-02-25",
    //             aDat: "2022-02-25",
    //             aTime: "06:30",
    //             dTime: "04:40",
    //             dApt: "RUH",
    //             bc: "H",
    //             aApt: "CAI",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "2650",
    //             dTer: "1",
    //             aTer: "3",
    //             fba: "35KG",
    //             segmentDuration: "170",
    //           },
    //           {
    //             dDat: "2022-02-25",
    //             aDat: "2022-02-25",
    //             aTime: "20:35",
    //             dTime: "17:15",
    //             dApt: "CAI",
    //             bc: "H",
    //             aApt: "LHR",
    //             ma: "MS",
    //             airlineNm: "MS",
    //             fn: "779",
    //             dTer: "3",
    //             aTer: "2",
    //             fba: "35KG",
    //             segmentDuration: "320",
    //           },
    //         ],
    //         stop: "1",
    //       },
    //       {
    //         totalDuration: "0",
    //         iSeg: [
    //           {
    //             layoverTime: "",
    //             dDat: "2022-03-05",
    //             aDat: "2022-03-05",
    //             aTime: "23:50",
    //             dTime: "21:10",
    //             dApt: "RUH",
    //             bc: "Y",
    //             aApt: "AUH",
    //             ma: "EY",
    //             airlineNm: "EY",
    //             fn: "2552",
    //             dTer: "1",
    //             aTer: "3",
    //             fba: "35KG",
    //             segmentDuration: "100",
    //           },
    //           {
    //             layoverTime: "",
    //             dDat: "2022-03-06",
    //             aDat: "2022-03-06",
    //             aTime: "10:45",
    //             dTime: "07:05",
    //             dApt: "AUH",
    //             bc: "Y",
    //             aApt: "LHR",
    //             ma: "EY",
    //             airlineNm: "EY",
    //             fn: "19",
    //             dTer: "3",
    //             aTer: "3",
    //             fba: "35KG",
    //             segmentDuration: "460",
    //           },
    //         ],
    //         stop: "1",
    //       },
    //     ],
    //     stop: "1",
    //     ma: "MS",
    //     isConnecting: "true",
    //     markups: [
    //       {
    //         ruleName: "Admin: Normal Makup",
    //         perAdult: "15",
    //       },
    //     ],
    //   },
    // ],
    searchRQ: undefined,
    // searchRQ: {
    //   generalInfo: {
    //     guid: "70f71923-9535-4722-9c18-869db824383f",
    //     currency: "USD",
    //     tripType: "O",
    //     journeyType: "INT",
    //     version: null,
    //     cabin: "EC",
    //     username: "Test",
    //     password: "Test",
    //     agencyId: "5",
    //     subAgencyId: null,
    //     env: "1",
    //   },
    //   segments: [
    //     {
    //       departApt: "CAI",
    //       arrivalApt: "DXB",
    //       departDate: "2022-11-16",
    //     },
    //   ],
    //   paxDetails: {
    //     adult: 1,
    //     child: 0,
    //     infant: 0,
    //     toddlersInOwnSeat: 0,
    //     youth: 0,
    //   },
    //   advanceSearch: {
    //     maxStops: 0,
    //     directFlight: false,
    //     maxLayover: 0,
    //     isFlexi: false,
    //     preferredAirlines: null,
    //     prohibitedAirlines: null,
    //   },
    // },
    selectedAirlineId: undefined,
    // selectedAirlineId: "WSP1",
    selectedAirline: undefined,
    // selectedAirline: {
    //   refundable: "true",
    //   gds: "GAL",
    //   id: "GAL1",
    //   totalAmt: "183.49",
    //   totalTax: "94.49",
    //   supplierTags: {
    //     supplierAmt: 168.49,
    //     supplierTax: 94.49,
    //     supplierCurrency: "USD",
    //   },
    //   cabinClass: "ECONOMY",
    //   ltd: "2022-11-16T23:59:00.000+05:30",
    //   abf: "74.00",
    //   atx: "109.49",
    //   ayq: "0",
    //   cancellationCharges: {
    //     changePenalty: "20.00USD",
    //     cancelPenalty: "Cancel Any time before Departure 30.00USD",
    //   },
    //   segments: [
    //     {
    //       totalDuration: "540",
    //       iSeg: [
    //         {
    //           supplierTags: {
    //             supplierAmt: 0,
    //             supplierTax: 0,
    //             supplierCurrency: null,
    //             segmentRef: "o28Y/Ql0nDKAa38vXCAAAA==",
    //             group: "0",
    //             distance: "1468",
    //             equipment: "321",
    //             effectiveDate: "2022-11-04T19:23:00.000+05:30",
    //             fareInfo: "o28Y/Ql0nDKA248vXCAAAA==",
    //             fareBasis: "MLC1EG",
    //             galDeprtDatTime: "2022-11-16T06:00:00.000+02:00",
    //             galArrDatTime: "2022-11-16T11:15:00.000+04:00",
    //           },
    //           layoverTime: "",
    //           dDat: "2022-11-16",
    //           aDat: "2022-11-16",
    //           aTime: "11:15",
    //           dTime: "06:00",
    //           dApt: "CAI",
    //           bc: "M",
    //           aApt: "AUH",
    //           ma: "EY",
    //           airlineNm: "EY",
    //           fn: "658",
    //           dTer: "2",
    //           aTer: "1",
    //           fba: "40 KGs",
    //           segmentDuration: "195",
    //         },
    //         {
    //           supplierTags: {
    //             supplierAmt: 0,
    //             supplierTax: 0,
    //             supplierCurrency: null,
    //             segmentRef: "o28Y/Ql0nDKAc38vXCAAAA==",
    //             group: "0",
    //             distance: "67",
    //             equipment: "BUS",
    //             effectiveDate: "2022-11-04T19:23:00.000+05:30",
    //             fareInfo: "o28Y/Ql0nDKA248vXCAAAA==",
    //             fareBasis: "MLC1EG",
    //             galDeprtDatTime: "2022-11-16T15:00:00.000+04:00",
    //             galArrDatTime: "2022-11-16T17:00:00.000+04:00",
    //           },
    //           layoverTime: "",
    //           dDat: "2022-11-16",
    //           aDat: "2022-11-16",
    //           aTime: "17:00",
    //           dTime: "15:00",
    //           dApt: "AUH",
    //           bc: "M",
    //           aApt: "XNB",
    //           ma: "EY",
    //           airlineNm: "EY",
    //           fn: "5424",
    //           dTer: "1",
    //           aTer: null,
    //           fba: "40 KGs",
    //           segmentDuration: "120",
    //         },
    //       ],
    //       stop: "1",
    //       fareName: "",
    //       distance: "",
    //       airCraft: "",
    //     },
    //   ],
    //   stop: "1",
    //   ma: "EY",
    //   isConnecting: "true",
    //   privateFare: "False",
    //   markups: [
    //     {
    //       ruleName: "Admin: Normal",
    //       perAdult: "15",
    //     },
    //   ],
    // },
  },
};

const setFlightNumber = (segments) =>
  segments?.map((segment) => ({
    ...segment,
    iSeg: segment.iSeg.map((subSeg) => ({
      ...subSeg,
      fn: checkForAlphabets(subSeg.fn) ? subSeg.fn : `${subSeg.ma}${subSeg.fn}`,
    })),
  }));

const getFlight = ({ list, flightId }) => {
  const flight = list.filter(({ id }) => id === flightId)[0];
  return { ...flight, segments: setFlightNumber(flight?.segments) };
};

const removeFlight = ({ list, flightId }) => {
  return list.filter(({ id }) => id !== flightId);
};

export const flightSearchReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.SEARCH_FLIGHTS.REQUESTED:
      case actions.SEARCH_FLIGHTS_PNR.REQUESTED:
        state.startSearch.loading = true;
        state.searchResults.complete = false;
        break;
      case actions.SEARCH_FLIGHTS.SUCCEEDED:
      case actions.SEARCH_FLIGHTS_PNR.SUCCEEDED:
        state.startSearch.loading = false;
        state.startSearch.success = true;
        state.startSearch.id = payload.data;
        break;
      case actions.SEARCH_FLIGHTS.FAILED:
      case actions.SEARCH_FLIGHTS_PNR.FAILED:
        state.startSearch.loading = false;
        state.startSearch.failure = payload.error;
        break;
      case actions.GET_FARE_RULES.REQUESTED:
        state.fareRules.loading = true;
        break;
      case actions.GET_FARE_RULES.SUCCEEDED:
        state.fareRules.loading = false;
        state.fareRules.success = true;
        state.fareRules.data = payload.data?.rules;
        break;
      case actions.GET_FARE_RULES.FAILED:
        state.fareRules.loading = false;
        state.fareRules.failure = payload.error;
        break;
      case actions.GET_FARE_RULES.CLEAR:
        state.fareRules = initialState.fareRules;
        break;
      case actions.VERIFY_TICKETS.REQUESTED:
        state.verifyTickets.success = undefined;
        state.verifyTickets.failure = undefined;
        state.verifyTickets.data = undefined;
        state.verifyTickets.loading = true;
        break;
      case actions.VERIFY_TICKETS.SUCCEEDED:
        state.verifyTickets.loading = false;
        state.verifyTickets.success = true;
        state.verifyTickets.data = payload.data;
        break;
      case actions.VERIFY_TICKETS.FAILED:
        state.verifyTickets.loading = false;
        state.verifyTickets.failure = payload.error;
        break;
      case actions.VERIFY_TICKETS.CLEAR:
        state.verifyTickets = initialState.verifyTickets;
        break;
      case actions.REPRICE.REQUESTED:
        state.reprice.success = undefined;
        state.reprice.failure = undefined;
        state.reprice.data = undefined;
        state.reprice.loading = true;
        break;
      case actions.REPRICE.SUCCEEDED:
        state.reprice.loading = false;
        state.reprice.success = true;
        state.reprice.data = payload.data?.airline;
        break;
      case actions.REPRICE.FAILED:
        state.reprice.loading = false;
        state.reprice.failure = payload.error;
        break;
      case actions.REPRICE.CLEAR:
        state.reprice.success = undefined;
        state.reprice.failure = undefined;
        state.reprice.loading = undefined;
        break;
      case actions.GET_SEARCH_RESULTS.REQUESTED:
        state.searchResults.loading = true;
        break;
      case actions.GET_SEARCH_RESULTS.SUCCEEDED:
        state.searchResults.loading = !payload.data.isComplete;
        state.searchResults.success = true;
        state.searchResults.airlines = payload.data.airlines;
        state.searchResults.searchRQ = payload.data.searchRQ;
        state.searchResults.complete = payload.data.isComplete;
        break;
      case actions.GET_SEARCH_RESULTS.FAILED:
        state.searchResults.loading = false;
        state.searchResults.failure = payload.error;
        break;
      case actions.GET_SEARCH_RESULTS.CLEAR:
        state.searchResults.failure = undefined;
        state.searchResults.complete = undefined;
        state.searchResults.success = undefined;
        state.searchResults.loading = undefined;
        state.searchResults.airlines = [];
        // state.searchResults.searchRQ = undefined;
        break;
      case actions.SET_SELECTED_AIRLINE:
        state.searchResults.selectedAirline = getFlight({
          list: state.searchResults.airlines,
          flightId: payload.id,
        });
        state.searchResults.selectedAirlineId = payload.id;
        break;
      case actions.SET_SELECTED_AIRLINE_DATA:
        state.searchResults.selectedAirline = payload;
        break;
      case actions.REMOVE_SELECTED_AIRLINE_FROM_LIST:
        const selectedFlightId = state.searchResults.selectedAirlineId;
        state.searchResults.airlines = removeFlight({
          list: state.searchResults.airlines,
          flightId: selectedFlightId,
        });
        break;
      case actions.MAKE_REPRICE_AS_SELECTED_AIRLINE:
        state.searchResults.selectedAirline = state.reprice.data;
        break;
      case actions.SAVE_SEARCH_DETAILS:
        state.searchDetails = payload;
        break;
      case actions.SAVE_SEARCH_PARAMS:
        state.searchParams = payload;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
