import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../common";
import { ExpensesTable } from "./ExpensesTable";
import { ADMIN_ACCOUNTING_CREATE_EXPENSES } from "../../../../../../app/routesMapAdmin";

export const Expenses = () => {
  const history = useHistory();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => history.push(ADMIN_ACCOUNTING_CREATE_EXPENSES)}
            >
              Add New Expense
            </Button>
          </div>
          <ExpensesTable />
        </Paper>
      </Grid>
    </Grid>
  );
};
