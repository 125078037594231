/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Pagination } from "@material-ui/lab";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { LoadingSpinner } from "../../../common";
import { getDateTime } from "../../../../../_helpers/date";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import {
  ADMIN_STAFF_CREATE,
  ADMIN_STAFF_MEMBER,
} from "../../../../../../app/routesMapAdmin";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { useCallback } from "react";
import { useState } from "react";
import { UsersSearchCard } from "../../client/usersSerchCard/UsersSearchCard";
import { actions } from "../../../../../../redux/state/user";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const Staff = ({ className, permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { loading: listLoading, list, paginationInfo } = useSelector(
    ({ user }) => user.allUsers
  );
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const dispatch = useDispatch();
  const { fromDate, toDate } = dateRange;
  const { totalPages } = paginationInfo ?? {};

  const filterUsers = useCallback(
    (pageNumber = 1, filters = []) => {
      let filterParam = [...filters];
      filterParam.push({
        columnName: "companyInUserId",
        filterValue: companyInUserId,
        filterOption: filterOptions.IsEqualTo,
      });

      dispatch(actions.filterUsers({ pageNumber, filterParam }));
    },
    [companyInUserId, dispatch]
  );
  useEffect(() => {
    filterUsers(pageNumber, appliedFilters);
  }, [appliedFilters, dispatch, filterUsers, pageNumber]);
  const allStaffSubPermission =
    adminPermissionsIds.systemManagement.flymeStaff.subPermissions;
  const tableContent = list?.map((item, index) => (
    <TableRow
      subPermissions={subPermissions}
      key={`row_${index}`}
      data={item}
    />
  ));
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    // dispatch(actions.filterUsers({ filterParam: [...filters] }));
  };
  const reset = () => {
    setAppliedFilters([]);
    setPageNumber(1);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <UsersSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
            isStaff
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <div className={`card card-custom ${className}`}>
          <div className="card-header d-flex align-items-center border-0 py-5">
            <div>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Staff Members
                </span>
                {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
            More than 15+ new sub users
          </span> */}
              </h3>
              {fromDate && toDate && (
                <Typography className="mb-2">
                  Created Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <div className="card-toolbar">
              <NavLink
                hidden={
                  !subPermissions.includes(allStaffSubPermission.addUser.id)
                }
                to={ADMIN_STAFF_CREATE}
                className="btn btn-danger font-weight-bolder font-size-sm"
              >
                + Add Staff Member
              </NavLink>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              {listLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-7">
                          <span className="text-dark-75">Name</span>
                        </th>
                        {matchesSm && (
                          <>
                            <th>Email</th>
                            <th>Created Date</th>
                          </>
                        )}
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>

                    <tbody>{tableContent}</tbody>
                  </table>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <Pagination
                  className="mx-auto mt-2"
                  onChange={handlePageChange}
                  page={pageNumber}
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const StaffDropdown = ({ openModal, userId, subPermissions }) => {
  const allStaffSubPermission =
    adminPermissionsIds.systemManagement.flymeStaff.subPermissions;
  return (
    <div className="card-toolbar">
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu
          // positionFixed
          // popperConfig={{
          //   strategy: "fixed",
          // }}
          className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
          placement={"bottom"}
        >
          <ul className="navi navi-hover">
            <li
              hidden={
                !subPermissions.includes(allStaffSubPermission.viewUser.id)
              }
              className="navi-item"
            >
              <NavLink
                to={parseRoute(ADMIN_STAFF_MEMBER, { id: userId })}
                className="navi-link"
              >
                <span className="navi-text">View user</span>
              </NavLink>
            </li>
            {/* <li className="navi-item">
              <NavLink
                to={parseRoute(ADMIN_STAFF_PERMISSIONS, { id: userId })}
                className="navi-link"
              >
                <span className="navi-text">Edit Permissions</span>
              </NavLink>
            </li> */}
            {/* <li  className="navi-item">
              <button
                onClick={openModal}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Allocate Funds</span>
              </button>
            </li> */}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const TableRow = ({ data, openModal, subPermissions }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { email, name, createdAt, id } = data;
  const { date, time } = getDateTime(createdAt);
  const rows = (
    <>
      <td className="pl-0">
        <NavLink
          to={parseRoute(ADMIN_STAFF_MEMBER, { id })}
          className="navi-link"
        >
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
              <span className="symbol-label">
                <span className="svg-icon h-75 align-self-end">
                  <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
                </span>
              </span>
            </div>
            <div>
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                {name}
              </a>
            </div>
          </div>
        </NavLink>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {email}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {date}
            </span>
            <span className="text-muted font-weight-bold">{time}</span>
          </td>
        </>
      )}
      {/* <td>
  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
    {companyName}
  </span>
  <span className="text-muted font-weight">{roleInUserId || "-"}</span>
</td> */}
      <td className="pr-0 text-right">
        <StaffDropdown
          subPermissions={subPermissions}
          userId={id}
          openModal={openModal}
        />
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "Email",
      value: email,
    },
    {
      label: "Created Date",
      value: `${date} ${time}`,
    },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
