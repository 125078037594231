export const MODULE_NAME = "auth";
export const LOCAL_STORAGE_TOKEN = "bearer_token";
export const LOCAL_STORAGE_SESSION_ID = "s_id";
export const LOCAL_STORAGE_USER_ID = "u_id";

export const ENV_TYPE_PROD = 2;
export const ENV_TYPE_PRE_PROD = 1;
export const ENV_TYPE_DEV = 0;

const envType = +process.env.REACT_APP_ENV_TYPE;

export const isDev = envType === ENV_TYPE_DEV;
export const isProd = envType === ENV_TYPE_PROD;