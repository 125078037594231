import React, { useCallback, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

import { TopUpEditHeader } from "./TopUpEditHeader";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/payment";
import { Button, Input } from "../../../common";
import { getDateTime } from "../../../../../_helpers/date";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const CustomerEditSchema = Yup.object().shape({
  verificationRemark: Yup.string().nullable(),
});

export const TopUpEditModal = ({
  isReject,
  show,
  data,
  onHide,
  getCompanyName,
  getBankAccountData,
}) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(({ user }) => user.userData);
  const { success, loading, failure } = useSelector(
    ({ payment }) => payment.edit
  );
  const { list: paymentMethodsList } = useSelector(
    ({ banks }) => banks.allPaymentMethods
  );

  const fields = useFormik({
    enableReinitialize: true,
    initialValues: { verificationRemark: "" },
    validationSchema: CustomerEditSchema,
    onSubmit: ({ status, verificationRemark }) => {
      dispatch(
        actions.editTopUp({
          ...data,
          status: isReject ? 1 : 2,
          verificationRemark,
          approvedByUserInTopUpId: +userId,
        })
      );
    },
  });

  const handleClose = useCallback(() => {
    onHide();
    fields.resetForm();
  }, [fields, onHide]);
  useEffect(() => {
    if (success) {
      dispatch(actions.clearEdit());
      handleClose();
    }
  }, [dispatch, handleClose, onHide, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearEdit());
    }
  }, [dispatch, handleClose, onHide, failure]);
  const { getFieldProps, touched, errors, handleSubmit } = fields;
  const {
    createdAt,
    paymentAmount,
    remarks,
    id,
    companyId,
    bankId,
    bankAccountId,
    paymentMethod,
  } = data || {};
  const { date, time } = getDateTime(createdAt);
  const paymentMethodName = useMemo(
    () =>
      paymentMethodsList?.filter(({ id }) => id === paymentMethod)?.[0]?.name,
    [paymentMethodsList, paymentMethod]
  );
  const bankAccountData = getBankAccountData(bankAccountId);
  return (
    <Modal
      size="md"
      onHide={handleClose}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <TopUpEditHeader
          title={isReject ? "Reject Top up" : "Approve Top up"}
          loading={loading}
        />
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Transaction Code
              </Typography>
              <Typography>{id}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Amount</Typography>
              <Typography>${paymentAmount}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Requested Date
              </Typography>
              <Typography>{date}</Typography>
              <Typography>{time}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Agency</Typography>
              <Typography>{getCompanyName(companyId)}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Bank</Typography>
              <Typography>{bankAccountData?.banks?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Bank Account</Typography>
              <Typography>{bankAccountData?.accountNo}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Payment Method
              </Typography>
              <Typography>{paymentMethodName}</Typography>
            </Grid>
            <Grid item xs={12} md>
              <Typography className="mb-3 text-muted">Remarks</Typography>
              <Typography>{remarks}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                className="mt-3"
                {...getFieldProps("verificationRemark")}
                showErrors={touched["verificationRemark"]}
                errorMessage={errors["verificationRemark"]}
                variant="secondary"
                label="Verification Remark"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={handleClose}
            variant="light"
          >
            Cancel
          </Button>
          <> </>
          <Button smallPadding loading={loading} type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
