import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";
import useAgenciesOptions from "../../../../../../app/helpers/hooks/useAgenciesOptions";
import { toAbsoluteUrl } from "../../../../../_helpers";

import { Button, Select } from "../../../common";
import { DateRangeModal } from "../common/reportsSearchCard/DateRangeModal";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import useBankAccountsOptions from "../../../../../../app/helpers/hooks/useBankAccountsOptions";
import { transactionStatusMap } from "../../../../../../redux/state/accountBalance/accountBalanceConstants";

const getFilterObject = (columnName, value, option) => [
  ...(value
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

const typeOptions = [
  { label: "In", value: transactionStatusMap.in },
  { label: "Out", value: transactionStatusMap.out },
];

export const BankReportSearchCard = ({
  setDateRange,
  onSearch,
  isBankHistory,
  onReset,
}) => {
  const [showModal, setShowModal] = useState(null);
  const { bankAccountsOptions } = useBankAccountsOptions();
  const { agencyOptions } = useAgenciesOptions();
  // const { usersOptions } = useUsersOptions();
  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      bankAccountId: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      type: "",
      agency: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      bankAccountId: Yup.string(),
      supplier: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      user: Yup.string(),
    }),
    onSubmit: ({ fromDate, user, toDate, type, bankAccountId, agency }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject("createdBy", user, filterOptions.IsEqualTo),
        ...getFilterObject(
          "bankAccountId",
          `${bankAccountId}`,
          filterOptions.IsEqualTo
        ),
        ...getFilterObject("type", type, filterOptions.IsEqualTo),
        ...getFilterObject("agencyId", agency, filterOptions.IsEqualTo),
        ...getFilterObject(
          "date",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "date",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const { handleSubmit, setFieldValue, values, setValues } = fields;
  // "filterParam": [
  //   {
  //     "columnName": "createdAt",
  //     "filterValue": "2022-01-06",
  //     "filterOption": 8
  //   },
  //    {
  //     "columnName": "agencyId",
  //     "filterValue": "13",
  //     "filterOption": 11
  //   }
  // ]
  const handleReset = () => {
    setValues({
      bankAccountId: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      type: "",
      agency: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };
  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid container justify="center" spacing={2}>
        {/* <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.invoiceTo}
            errorMessage={errors.invoiceTo}
            {...getFieldProps("invoiceTo")}
            placeholder="Invoice To"
          />
        </Grid> */}
        {/* <Grid item xs={6} md={2}>
          <Select
            value={values.supplier}
            onChange={(v) => setFieldValue("supplier", v)}
            placeholder="All Banks"
          />
        </Grid> */}
        {!isBankHistory && (
          <Grid item xs={6} md={2}>
            <Select
              value={values.bankAccountId}
              onChange={(v) => setFieldValue("bankAccountId", v)}
              placeholder="All Bank Accounts"
              options={[
                { value: "", label: "All Bank Accounts" },
                ...(bankAccountsOptions || []),
              ]}
            />
          </Grid>
        )}
        <Grid item xs={6} md={2}>
          <Select
            value={values.type}
            onChange={(v) => setFieldValue("type", v)}
            placeholder="Money flow"
            options={[{ value: "", label: "In/Out" }, ...(typeOptions || [])]}
          />
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <Select
            value={values.user}
            onChange={(v) => setFieldValue("user", v)}
            options={[
              { value: "", label: "All Users" },
              ...(usersOptions || []),
            ]}
            placeholder="User"
          />
        </Grid> */}
        <Grid item xs={6} md={2}>
          <Select
            value={values.agency}
            onChange={(v) => setFieldValue("agency", v)}
            options={[
              { value: "", label: "All Agencies" },
              ...(agencyOptions || []),
            ]}
            placeholder="Agency"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-center">
            <Button  type="submit">
              Search
            </Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
