import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Input } from "../../../common";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accounting";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { useCallback } from "react";
import { getCurrWeekRange } from "../../../../../_helpers";

const weaklyTargetSchema = Yup.object().shape({
  target: Yup.number()
    .positive()
    .required("Target is required"),
  // from: Yup.string().required("From Date is required"),
  // to: Yup.string().required("To Date is required"),
});

export const SetTargetModal = ({ show, onHide, data }) => {
  const dispatch = useDispatch();
  const {
    success: setWeeklyTargetSuccess,
    loading: setWeeklyTargetLoading,
    failure: setWeeklyTargetFailure,
  } = useSelector(({ accounting }) => accounting.setWeaklyTarget);
  const {
    success: editWeeklyTargetSuccess,
    loading: editWeeklyTargetLoading,
    failure: editWeeklyTargetFailure,
  } = useSelector(({ accounting }) => accounting.editWeaklyTarget);
  const { firstDay, lastDay } = getCurrWeekRange();

  // const { id, availableBalance } = data || {};
  const fields = useFormik({
    initialValues: {
      from: "",
      to: "",
      target: "",
    },
    validationSchema: weaklyTargetSchema,
    onSubmit: ({ from, to, target }) => {
      if (data) {
        dispatch(
          actions.editWeeklyTarget({
            ...data,
            target,
          })
        );
      } else {
        dispatch(
          actions.setWeeklyTarget({
            from: firstDay,
            to: lastDay,
            target,
          })
        );
      }
    },
  });
  const {
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    resetForm,
    setValues,
  } = fields;
  useEffect(() => {
    if (data) {
      setValues({ target: data?.target });
    }
  }, [data, setValues]);

  const handleHide = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (setWeeklyTargetSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Weakly Target Added!",
        })
      );
      dispatch(actions.clearSetWeaklyTarget());
      handleHide();
    }
  }, [setWeeklyTargetSuccess, dispatch, handleHide]);
  useEffect(() => {
    if (setWeeklyTargetFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearSetWeaklyTarget());
    }
  }, [setWeeklyTargetFailure, dispatch]);
  useEffect(() => {
    if (editWeeklyTargetSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Weakly Target Updated!",
        })
      );
      dispatch(actions.clearEditWeaklyTarget());
      handleHide();
    }
  }, [editWeeklyTargetSuccess, dispatch, handleHide]);
  useEffect(() => {
    if (editWeeklyTargetFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearEditWeaklyTarget());
    }
  }, [editWeeklyTargetFailure, dispatch]);
  return (
    <Modal
      size="sm"
      onHide={handleHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Weakly Target</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("from")}
                showErrors={touched["from"]}
                errorMessage={errors["from"]}
                variant="secondary"
                label="From"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("to")}
                showErrors={touched["to"]}
                errorMessage={errors["to"]}
                variant="secondary"
                label="To"
              />
            </Grid> */}
            <Grid item xs={12}>
              <Input
                {...getFieldProps("target")}
                showErrors={touched["target"]}
                errorMessage={errors["target"]}
                variant="secondary"
                label="Target"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button type="button" onClick={handleHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={setWeeklyTargetLoading || editWeeklyTargetLoading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Set
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
