import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./supplierConstants";

export const RESET = `${MODULE_NAME}/RESET`;
export const GET_SUPPLIER = requestActions(MODULE_NAME, "GET_SUPPLIER");
export const GET_ALL = requestActions(MODULE_NAME, "GET_ALL");
export const GET_ONE = requestActions(MODULE_NAME, "GET_ONE");
export const DELETE = requestActions(MODULE_NAME, "DELETE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const GET_SUPPLIER_BALANCE = requestActions(
  MODULE_NAME,
  "GET_SUPPLIER_BALANCE"
);
export const CREATE_SUPPLIER_BALANCE = requestActions(
  MODULE_NAME,
  "CREATE_SUPPLIER_BALANCE"
);
export const EDIT_SUPPLIER_BALANCE = requestActions(
  MODULE_NAME,
  "EDIT_SUPPLIER_BALANCE"
);
export const ADD_TO_SUPPLIER_BALANCE = requestActions(
  MODULE_NAME,
  "ADD_TO_SUPPLIER_BALANCE"
);
export const DEDUCT_FROM_SUPPLIER_BALANCE = requestActions(
  MODULE_NAME,
  "DEDUCT_FROM_SUPPLIER_BALANCE"
);
export const GET_SUPPLIER_API = requestActions(MODULE_NAME, "GET_SUPPLIER_API");
export const CREATE_SUPPLIER_API = requestActions(
  MODULE_NAME,
  "CREATE_SUPPLIER_API"
);
export const EDIT_SUPPLIER_API = requestActions(
  MODULE_NAME,
  "EDIT_SUPPLIER_API"
);
export const GET_SUPPLIER_BALANCE_HISTORY = requestActions(
  MODULE_NAME,
  "GET_SUPPLIER_BALANCE_HISTORY"
);

export const GET_SUPPLIER_REFUND_TOP_UPS = requestActions(
  MODULE_NAME,
  "GET_SUPPLIER_REFUND_TOP_UPS"
);

export const FILTER_SUPPLIER_REFUND_TOP_UPS = requestActions(
  MODULE_NAME,
  "FILTER_SUPPLIER_REFUND_TOP_UPS"
);

export const EDIT_SUPPLIER_TOP_UP = requestActions(
  MODULE_NAME,
  "EDIT_SUPPLIER_TOP_UP"
);

export const FILTER_SUPPLIERS = requestActions(MODULE_NAME, "FILTER_SUPPLIERS");

export const filterSuppliers = (params) =>
  requestApiCall("filterSuppliers", params, FILTER_SUPPLIERS);

export const editSupplierTopUp = (params) =>
  requestApiCall("editSupplierTopUp", params, EDIT_SUPPLIER_TOP_UP);

export const filterSupplierRefundTopUps = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterSupplierRefundTopUps",
    { sortingParams, filterParam, pageNumber, pageSize },
    FILTER_SUPPLIER_REFUND_TOP_UPS
  );

export const getSupplierRefundTopUps = (params) =>
  requestApiCall(
    "getSupplierRefundTopUps",
    params,
    GET_SUPPLIER_REFUND_TOP_UPS
  );

export const getSupplierBalanceHistory = (params) =>
  requestApiCall(
    "getSupplierBalanceHistory",
    params,
    GET_SUPPLIER_BALANCE_HISTORY
  );

export const createSupplier = (params) =>
  requestApiCall("createSupplier", params, CREATE);

export const deleteSupplier = (params) =>
  requestApiCall("deleteSupplier", params, DELETE);

export const editSupplier = (params) =>
  requestApiCall("editSupplier", params, EDIT);

export const getSupplier = (params) =>
  requestApiCall("getSupplier", params, GET_ONE);

export const getAllSuppliers = (params) =>
  requestApiCall("getAllSuppliers", params, GET_ALL);

export const createSupplierBalance = (params) =>
  requestApiCall("createSupplierBalance", params, CREATE_SUPPLIER_BALANCE);

export const editSupplierBalance = (params) =>
  requestApiCall("editSupplierBalance", params, EDIT_SUPPLIER_BALANCE);

export const addToSupplierBalance = (params) =>
  requestApiCall("addToSupplierBalance", params, ADD_TO_SUPPLIER_BALANCE);

export const deductFromSupplierBalance = (params) =>
  requestApiCall(
    "deductFromSupplierBalance",
    params,
    DEDUCT_FROM_SUPPLIER_BALANCE
  );

export const getSupplierBalanceBySupplierId = (params) =>
  requestApiCall(
    "getSupplierBalanceBySupplierId",
    params,
    GET_SUPPLIER_BALANCE
  );

export const createSupplierApi = (params) =>
  requestApiCall("createSupplierApi", params, CREATE_SUPPLIER_API);

export const editSupplierApi = (params) =>
  requestApiCall("editSupplierApi", params, EDIT_SUPPLIER_API);

export const getSupplierApiBySupplierId = (params) =>
  requestApiCall("getSupplierApiBySupplierId", params, GET_SUPPLIER_API);

export const clearCreate = () => ({ type: CREATE.CLEAR });

export const clearAddDeduct = () => ({ type: ADD_TO_SUPPLIER_BALANCE.CLEAR });

export const clearDelete = () => ({ type: DELETE.CLEAR });

export const clearEdit = () => ({ type: EDIT.CLEAR });

export const clearEditSupplierBalance = () => ({
  type: EDIT_SUPPLIER_BALANCE.CLEAR,
});

export const clearEditSupplierTopUp = () => ({
  type: EDIT_SUPPLIER_TOP_UP.CLEAR,
});

export const clearSupplierApi = () => ({ type: GET_SUPPLIER_API.CLEAR });

export const clearSupplierBalance = () => ({
  type: GET_SUPPLIER_BALANCE.CLEAR,
});

export const reset = () => ({ type: RESET });
