import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SVG from "react-inlinesvg";

import styles from "./Input.module.scss";
import { toAbsoluteUrl } from "../../../../_helpers";
import { NavLink } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../../../app/routesMap";

export const Input = ({
  className,
  type,
  placeholder,
  style,
  label,
  id,
  horizontal,
  fixedLabelWidth,
  fixedLabelWidthSmall,
  fixedLabelWidthXS,
  noErrorMessage,
  showErrors,
  errorMessage,
  inputProps,
  onChange,
  value,
  outlined,
  name,
  variant,
  search,
  multiline,
  required,
  forgotPassword,
  haveShowPassword,
  spacing,
  optional,
  ...rest
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const inputClassNames = clsx({
    [styles.input]: variant === "primary",
    [styles.inputSecondary]: variant === "secondary",
    [styles.inputOutlined]: outlined,
    [styles.inputError]: showErrors && errorMessage,
    "pl-15": search,
  });
  const containerClassNames = clsx("d-flex w-100 text-left", className, {
    "justify-content-start flex-column": !horizontal || !matchesSm,
    "align-items-center justify-content-between": horizontal && matchesSm,
    // "pb-8": !noErrorMessage,
    "mb-3": spacing,
  });

  return (
    <div className={containerClassNames}>
      {label && (
        <label
          style={{ whiteSpace: "nowrap" }}
          // className={labelClassNames}
          htmlFor={id || label}
          className={clsx("d-flex justify-content-between mb-2", {
            "min-w-150px": fixedLabelWidth,
            "min-w-75px": fixedLabelWidthSmall,
            "min-w-50px": fixedLabelWidthXS,
          })}
        >
          <span>
            {required && <span className="text-danger">*</span>}
            {label}{" "}
            {optional && <span className="text-muted">(optional)</span>}
            {horizontal && ":"}
          </span>
          {forgotPassword && (
            <NavLink
              to={FORGOT_PASSWORD}
              className="text-primary font-weight-bold"
            >
              Forgot Password?
            </NavLink>
          )}
        </label>
      )}
      <div className="w-100 position-relative">
        {multiline ? (
          <textarea
            name={name}
            id={id || label}
            type={type}
            className={inputClassNames}
            style={style}
            placeholder={(search && "Search") || placeholder || label}
            onChange={onChange}
            value={value}
            {...inputProps}
            rows="4"
          />
        ) : (
          <input
            name={name}
            id={id || label}
            type={showPassword ? "text" : type}
            className={inputClassNames}
            style={style}
            placeholder={(search && "Search") || placeholder || label}
            onChange={onChange}
            value={value}
            autoComplete={type === "password" ? "off" : "on"}
            {...inputProps}
            {...rest}
          />
        )}
        {search && (
          <span className={styles.searchIcon}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
          </span>
        )}
        {haveShowPassword && (
          <label className="d-flex my-2">
            <label className={`checkbox checkbox-md checkbox-single`}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={(e) => setShowPassword(e.target.checked)}
              />
              <span />
            </label>
            <span className="ml-2">Show password</span>
          </label>
        )}
        {showErrors && errorMessage && !noErrorMessage && (
          <div className="fv-plugins-message-container min-h-20px max-w-100">
            <div className="fv-help-block">{showErrors && errorMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = { type: "text", outlined: true, variant: "primary" };

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  horizontal: PropTypes.bool,
  outlined: PropTypes.bool,
  variant: PropTypes.string,
};
