import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Chart } from "../../../../account/common/Chart";

export const BalanceInfo = () => {
  return (
    <Paper className="bg-white h-100">
      <Chart>
        <div className="">
          <Typography variant="h6" className="mb-8">
            Sales Summary
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className="text-muted">
              Avarage Sale
            </Typography>
            <Typography variant="h5" className="font-weight-bolder">
              $650
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className="text-muted">
              Comission
            </Typography>
            <Typography variant="h5" className="font-weight-bolder">
              $29,004
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="p" className="text-muted">
              Annual Taxes 2019
            </Typography>
            <Typography variant="h5" className="font-weight-bolder">
              $233,600
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="p" className="text-muted">
              Annual Income
            </Typography>
            <Typography variant="h5" className="font-weight-bolder">
              $1,480,00
            </Typography>
          </Grid>
        </Grid>
      </Chart>
    </Paper>
  );
};