import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Button, Input } from "../../../common";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { getDateTime } from "../../../../../_helpers/date";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { useParams } from "react-router";
import {
  CardWithBlueHeader,
  DropDownButton,
} from "../../../ticketSummary/TicketDetails";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { FlightDetailsCard } from "../../../fareSearch/DapartingFlights";
import { RulesTabs } from "../../../fareSearch/FlightDetails";
import { ContactInfo } from "../issuedOrderDetails/orderSummary/ContactInfo";
import { PassengersList } from "../issuedOrderDetails/orderSummary/PassengersList";
import useAirlinesList from "../../../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../../../app/helpers/hooks/useAirportsList";

import { RefundActions } from "./actions/RefundActions";

export const RefundDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificRefund) || {};
  const { transaction, createdAt, status, refundAmount } = data || {};
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const {
    passengers,
    orderNo,
    segment,
    cabinClass,
    flight,
    totalPrice,
    userId,
    gdsPnr,
    agencyName,
  } = transaction || {};
  const { date, time } = getDateTime(createdAt);
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  const {
    loading: sendEmailLoading,
    failure: sendEmailFailure,
    success: sendEmailSuccess,
  } = useSelector(({ bookingMng }) => bookingMng.sendEmail);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  useEffect(() => {
    dispatch(actions.getSpecificRefund({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id]);
  const personalSchema = Yup.object().shape({
    to: Yup.string().required("Field is required"),
    cc: Yup.string(),
    bcc: Yup.string(),
    pnr: Yup.string().required("Field is required"),
  });
  const formik = useFormik({
    initialValues: {
      to: "",
      cc: "",
      bcc: "",
      pnr: "",
    },
    validationSchema: personalSchema,
    onSubmit: ({ to, pnr }) => {
      dispatch(actions.sendRefundEmail({ email: to, pnr, id }));
    },
  });

  const { handleSubmit, getFieldProps, touched, errors } = formik;
  const leadGuest = passengers?.$values.filter(
    ({ isLeadGuest }) => isLeadGuest === true
  )?.[0];
  useEffect(() => {
    if (sendEmailSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Email sent to supplier",
        })
      );
      dispatch(actions.clearSendEmail());
      setOpenModal(false);
    }
  }, [dispatch, sendEmailSuccess]);

  useEffect(() => {
    if (sendEmailFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(actions.clearSendEmail());
    }
  }, [dispatch, sendEmailFailure]);
  return (
    <Grid container spacing={3}>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="bg-white rounded"
          style={{ maxWidth: "600px", flexGrow: 1, overflow: "hidden" }}
        >
          <div className="bg-primary p-7">
            <Typography variant="h5" className="font-weight-bolder text-white">
              Mailing Process
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit}
            className="py-7 px-20"
            style={{ color: "#464E5F" }}
          >
            <Input
              spacing
              showErrors={touched.to}
              errorMessage={errors.to}
              {...getFieldProps("to")}
              name="to"
              fixedLabelWidthSmall
              horizontal
              label="To"
              placeholder="Type here"
              required
            />
            <Input
              spacing
              showErrors={touched.cc}
              errorMessage={errors.cc}
              {...getFieldProps("cc")}
              name="cc"
              fixedLabelWidthSmall
              horizontal
              label="CC"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={touched.bcc}
              errorMessage={errors.bcc}
              {...getFieldProps("bcc")}
              name="bcc"
              fixedLabelWidthSmall
              horizontal
              label="Bcc"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={touched.pnr}
              errorMessage={errors.pnr}
              {...getFieldProps("pnr")}
              name="pnr"
              fixedLabelWidthSmall
              horizontal
              label="PNR"
              placeholder="Type here"
              required
            />
            <div className="d-flex justify-content-center mt-7 mb-3">
              <Button
                onClick={() => setOpenModal(false)}
                // className="btn bg-gray-200 w-100px"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button loading={sendEmailLoading} type="submit" className="ml-7">
                Send
              </Button>
            </div>
          </form>
        </Paper>
      </Modal>

      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-end">
          <RefundActions status={status} />
          {isAdmin && (
            <>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3 ml-auto"
                variant="primary-inverse"
                onClick={() => setOpenModal(true)}
              >
                <SVG
                  className="mr-2"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/message-blue.svg"
                  )}
                />
                Send
              </Button>
            </>
          )}

          {/* <DropDownButton
            buttonClassName="border-primary btn-white text-primary text-hover-light-primary"
            icon={}
            // className="mx-5"
            title="Send"
          /> */}
          <DropDownButton
            // options={manageOptions}
            buttonClassName="btn-danger"
            title="Download"
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CardWithBlueHeader
          title="Ticket information"
          subtitle={`Refund Order No: ${orderNo}`} ///////////////////
        >
          <div className="table-responsive mt-5">
            <table className="table table-vertical-center mb-0">
              <thead>
                <tr>
                  <th>Creation Date</th>
                  <th>User ID</th>
                  <th>OW/RT</th>
                  <th>GDS PNR</th>
                  <th>Transaction ID</th>
                  {/* <th>Refund method </th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ border: "unset" }} className="text-primary">
                  <td className="d-flex">
                    <span className="mr-2">{date}</span>
                    <span>{time}</span>
                  </td>
                  <td>
                    <span>{userId}</span>
                  </td>
                  <td>
                    <span>-</span>
                  </td>
                  <td>
                    <span>{gdsPnr || "-"}</span>
                  </td>
                  <td>
                    <span>{flight?.transactionId || "-"}</span>
                  </td>
                  {/* <td>-</td> */}
                  <td>{status}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardWithBlueHeader>
      </Grid>
      <Grid item xs={12} md={7}>
        <CardWithBlueHeader
          title="Flight information"
          print
          iconSrc="/media/svg/icons/General/plane-white.svg"
        >
          <FlightDetailsCard
            airlinesList={airlinesList}
            airportsList={airportsList}
            segments={segments}
            cabinClass={cabinClass}
            padding
          />
        </CardWithBlueHeader>
      </Grid>
      <Grid item xs={12} md={5}>
        <CardWithBlueHeader
          title="Refund Amount Info"
          iconSrc="/media/svg/icons/General/cash.svg"
        >
          <div className="m-7">
            <div className="d-flex justify-content-between mb-5">
              <span>No. of pax</span>
              <span>{passengers?.$values.length}</span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Original Fare</span>
              <span>${totalPrice}</span>
            </div>
            {/* <div className="d-flex justify-content-between mb-2">
              <span>Original taxes</span>
              <span>$0.0</span>
            </div> */}
            <div className="d-flex justify-content-between mb-2">
              <span>Refund Amount</span>
              <span>{+refundAmount ? `$${+refundAmount}` : "unconfirmed"}</span>
            </div>
            {/* <Seperator />
            <div className="font-weight-bold d-flex justify-content-between mb-5">
              <span>Total Amount</span>
              <span>{totalPrice}$</span>
            </div> */}
          </div>
        </CardWithBlueHeader>
      </Grid>
      <Grid item xs={12}>
        <PassengersList passengers={passengers} />
      </Grid>
      <Grid item xs={12}>
        <RulesTabs />
      </Grid>
      {/* <Grid item xs={12}>
        <InfoTabs />
      </Grid> */}
      <Grid item xs={12}>
        <ContactInfo agencyName={agencyName} leadGuest={leadGuest} />
      </Grid>
      {/* <Grid item xs={12}>
        <Paper>
          <OperationLog />
        </Paper>
      </Grid> */}
    </Grid>
  );
};
