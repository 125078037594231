import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./ticketSummaryActions";

const initialState = {
  ticketDetails: {
    data: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  ticketSummary: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  sendETicket: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  downloadETicket: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  sendInvoice: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  downloadInvoice: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  delete: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
};

export const ticketSummaryReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_SELECTED_TICKET.REQUESTED:
        state.ticketDetails.loading = true;
        break;
      case actions.GET_SELECTED_TICKET.SUCCEEDED:
        state.ticketDetails.loading = false;
        state.ticketDetails.success = true;
        state.ticketDetails.data = payload.data;
        break;
      case actions.GET_SELECTED_TICKET.FAILED:
        state.ticketDetails.loading = false;
        state.ticketDetails.failure = payload.error;
        break;
      case actions.GET_TICKET_SUMMARY_BY_AGENCY.REQUESTED:
      case actions.GET_TICKET_SUMMARY.REQUESTED:
        state.ticketSummary.loading = true;
        break;
      case actions.GET_TICKET_SUMMARY_BY_AGENCY.SUCCEEDED:
      case actions.GET_TICKET_SUMMARY.SUCCEEDED:
        state.ticketSummary.loading = false;
        state.ticketSummary.success = true;
        state.ticketSummary.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_TICKET_SUMMARY_BY_AGENCY.FAILED:
      case actions.GET_TICKET_SUMMARY.FAILED:
        state.ticketSummary.loading = false;
        state.ticketSummary.failure = payload.error;
        break;
      case actions.SET_SELECTED_TICKET:
        try {
          state.ticketDetails.data = {
            ...payload,
            segment: JSON.parse(payload.segment),
          };
        } catch {
          state.ticketDetails.data = payload;
        }
        break;
      case actions.SEND_ETICKET.REQUESTED:
        state.sendETicket.loading = true;
        break;
      case actions.SEND_ETICKET.SUCCEEDED:
        state.sendETicket.loading = false;
        state.sendETicket.success = true;
        break;
      case actions.SEND_ETICKET.FAILED:
        state.sendETicket.loading = false;
        state.sendETicket.failure = payload.error;
        break;
      case actions.DOWNLOAD_ETICKET.REQUESTED:
        state.downloadETicket.loading = true;
        break;
      case actions.DOWNLOAD_ETICKET.SUCCEEDED:
        state.downloadETicket.loading = false;
        state.downloadETicket.success = true;
        const url = window.URL.createObjectURL(new Blob([payload.data.blob()]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `E-invoice-${payload.args.id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        break;
      case actions.DOWNLOAD_ETICKET.FAILED:
        state.downloadETicket.loading = false;
        state.downloadETicket.failure = payload.error;
        break;
      case actions.DELETE_TICKET.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE_TICKET.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.ticketSummary.list = state.ticketSummary.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_TICKET.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE_TICKET.CLEAR:
        state.delete = initialState.delete;
        break;
      case actions.EDIT_TICKET.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT_TICKET.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        const index = state.ticketSummary.list.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.ticketSummary.list[index] = payload.args;
        break;
      case actions.EDIT_TICKET.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT_TICKET.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.SEND_INVOICE.REQUESTED:
        state.sendInvoice.loading = true;
        break;
      case actions.SEND_INVOICE.SUCCEEDED:
        state.sendInvoice.loading = false;
        state.sendInvoice.success = true;
        break;
      case actions.SEND_INVOICE.FAILED:
        state.sendInvoice.loading = false;
        state.sendInvoice.failure = payload.error;
        break;
      case actions.DOWNLOAD_INVOICE.REQUESTED:
        state.downloadInvoice.loading = true;
        break;
      case actions.DOWNLOAD_INVOICE.SUCCEEDED:
        state.downloadInvoice.loading = false;
        state.downloadInvoice.success = true;
        break;
      case actions.DOWNLOAD_INVOICE.FAILED:
        state.downloadInvoice.loading = false;
        state.downloadInvoice.failure = payload.error;
        break;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
