import React from "react";
import { Modal } from "react-bootstrap";

import { Button, Input } from "../../../common";
import { Grid } from "@material-ui/core";

export const RoleForm = ({ fields, show, loading, onHide }) => {
  const { getFieldProps, touched, errors, handleSubmit } = fields;
  return (
    <Modal size="md" onHide={onHide} show={show}>
      <>
        <Modal.Header>Staff Role</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("name")}
                showErrors={touched["name"]}
                errorMessage={errors["name"]}
                variant="secondary"
                label="Role Name"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
