// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

// import * as actions from "../../../_redux/customers/customersActions";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_helpers";
import * as columnFormatters from "./column-formatters";
import { isNil } from "lodash-es";
import { getDateTime } from "../../../../../../_helpers/date";
import { LoadingSpinner } from "../../../../common";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { adminPermissionsIds } from "../../../../../../../data/adminPermissionIds";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../../../../app/routesMapAdmin";

export function QueueTable({ setIds, ids, onClickActions, subPermissions }) {
  const { getCompanyName } = useCompanyName();
  const { list, loading } = useSelector(({ bookingMng }) => bookingMng.orders);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const entities = list?.map(
    ({
      insertedOn,
      orderNo,
      flight,
      bookingId,
      bookingStatus,
      agencyId,
      segment,
    }) => {
      const { date } = getDateTime(insertedOn);
      let segments;
      try {
        segments = JSON.parse(segment);
      } catch {}
      const agencyName = getCompanyName(agencyId);
      return {
        id: bookingId,
        bookingId,
        creationDate: date,
        status: bookingStatus,
        agencyName,
        departDate: segments?.[0].iSeg[0].dDat || "",
      };
    }
  );
  const allQueueSubPermission =
    adminPermissionsIds.bookingManagement.queue.subPermissions;
  const columns = [
    {
      dataField: "bookingId",
      text: "Booking ID",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <NavLink
          // className="text-primary cursor-pointer"
          to={parseRoute(ADMIN_COMPLETED_ORDER_DETAILS, { id: row.id })}
        >
          {cellContent}
        </NavLink>
      ),
    },
    {
      dataField: "creationDate",
      text: "Creation Date",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "agencyName",
      text: "Agency name",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "paxName",
    //   text: "Pax Name",
    //   sort: false,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    ...(matchesSm
      ? [
          {
            dataField: "departDate",
            text: "Depart Date",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
          },
          {
            dataField: "status",
            text: "Status",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
          },
        ]
      : []),
    // {
    //   dataField: "ticketNo",
    //   text: "Ticket ID No.",
    //   sort: false,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    ...(subPermissions.includes(allQueueSubPermission.update.id)
      ? [
          {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
              openEditCustomerDialog: onClickActions.openEditCustomerDialog,
              onCancel: onClickActions.openCancelModal,
              subPermissions,
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
              minWidth: "70px",
            },
          },
        ]
      : []),
  ];
  // Table pagination properties
  // const paginationOptions = {
  //   custom: true,
  //   totalSize: entities?.length,
  //   sizePerPageList: [10],
  //   sizePerPage: 10,
  //   page: 1,
  // };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination  isLoading={loading} paginationProps={paginationProps}> */}

      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={isNil(entities) ? [] : entities}
        columns={columns}
        // selectRow={getSelectRow({
        //   entities: entities,
        //   ids: ids,
        //   setIds: setIds,
        // })}
        // rowEvents={rowEvents}
        // rowStyle={{ cursor: "pointer" }}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}
        // selectRow={getSelectRow({
        //   entities,
        //   ids: customersUIProps.ids,
        //   setIds: customersUIProps.setIds,
        // })}
        // {...paginationTableProps}
      >
        <PleaseWaitMessage entities={null} />
        <NoRecordsFoundMessage entities={entities} />
      </BootstrapTable>
      {loading && <LoadingSpinner />}
      {!loading && list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Orders to show
        </Typography>
      )}
      {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}
