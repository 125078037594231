import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import React from "react";
import { Button } from "../../_metronic/layout/components/common";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";
import { ContentSection } from "../../_metronic/layout/components/public/common/ContentSection";
import { GET_CERTIFICATES_CONTENT } from "../../graphql/queries";
import { formatImageUrl } from "../helpers/formatters/strapiImagesUrl";
import { AboutUseSkeleton } from "../skeletons/AboutUsSkeleton";

export const CertificatesPage = () => {
  const { data, loading, error } = useQuery(GET_CERTIFICATES_CONTENT);

  if (loading)
    return (
      <PublicLayout>
        <AboutUseSkeleton />
      </PublicLayout>
    );
  if (!data?.certificatesPage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  if (error) {
    return (
      <PublicLayout>
        <div className="text-center vh-100 pt-20">
          <Typography variant="h3" className="text-dark font-weight-bolder">
            Something went wrong
          </Typography>
          <div className="my-5" />
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </PublicLayout>
    );
  }
  const {
    heroBlock,
    contentBlocks,
    partners,
  } = data?.certificatesPage?.data?.attributes;
  return (
    <PublicLayout>
      <div className="my-20">
        <ContentSection data={heroBlock} />
        <div className="mt-25" />
        <div className="d-flex justify-content-center flex-wrap bg-gray-100 p-10 position-relative">
          <div className="position-absolute top-0 vw-100 bottom-0 bg-gray-100" />
          {partners?.data?.map((partner, index) => (
            <img
              key={index}
              src={formatImageUrl(partner?.attributes?.url)}
              alt=""
              className="w-150px m-4 zindex-5"
            />
          ))}
          {/* <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          /> */}
          {/* <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          />
          <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          />
          <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          />
          <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          />
          <img
            src={toAbsoluteUrl("/media/tmp/image 33.jpg")}
            alt=""
            className="w-150px m-4 zindex-5"
          /> */}
        </div>

        <div className="mt-25" />
        {contentBlocks.map((block, index) => (
          <div key={index}>
            <ContentSection data={block} />
            <div className="my-25" />
          </div>
        ))}
      </div>
    </PublicLayout>
  );
};
