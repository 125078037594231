import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { parseRoute } from "../../../../../app/helpers/formatters";
import useCompanyName from "../../../../../app/helpers/hooks/useCompanyName";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../../app/routesMapAdmin";
import { actions } from "../../../../../redux/state/accounting";
import { actions as paymentActions } from "../../../../../redux/state/payment";
import { invoiceStatusMap } from "../../../../../redux/state/invoices/invoicesConstants";
import { getDateTime } from "../../../../_helpers";
import { GoBack, LoadingSpinner } from "../../common";

const TableRow = ({ data, getCompanyName }) => {
  const {
    tranDate,
    bookingId,
    total,
    supplierName,
    invoiceNo,
    received,
    due,
    invoiceType,
    status,
  } = data.invoices;
  const { date } = getDateTime(tranDate);
  return (
    <tr>
      <td>
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(ADMIN_COMPLETED_ORDER_DETAILS, { id: bookingId })}
        >
          <span>{bookingId}</span>
        </NavLink>
      </td>
      <td>
        <span>{invoiceNo || "-"}</span>
      </td>
      <td>
        <span>{supplierName?.slice(0, 2) || "-"}</span>
      </td>
      <td>
        <span>{date || "-"}</span>
      </td>
      <td>
        <span>{invoiceType || "-"}</span>
      </td>
      <td
        className={clsx({
          "text-success": status === invoiceStatusMap.paid,
          "text-danger": status === invoiceStatusMap.unpaid,
        })}
      >
        {status}
      </td>
      <td>
        <span>{received?.toFixed(2) || "-"}</span>
      </td>
      <td>
        <span>{due?.toFixed(2) || "-"}</span>
      </td>
      <td>
        <span>{total?.toFixed(2)}</span>
      </td>
    </tr>
  );
};

export default function TopUpClearedInvoices() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getCompanyName } = useCompanyName();
  const { list, loading } = useSelector(
    ({ accounting }) => accounting.topupClearedInvoices
  );
  const { data: topupData } = useSelector(
    ({ payment }) => payment.specificTopup
  );
  useEffect(() => {
    dispatch(actions.getClearedInvoices({ topupId: id }));
    dispatch(paymentActions.getSpecificTopUp({ id }));
  }, [dispatch, id]);
  const { companyId, paymentAmount } = topupData || {};
  const agencyName = getCompanyName(companyId);
  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <GoBack title="Top up details" />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Grid container spacing={3}>
            <Grid xs={12} md={4} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Top up ID
                </span>
                <span className="">{id}</span>
              </Typography>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Amount
                </span>
                <span className="">${paymentAmount}</span>
              </Typography>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Company Name
                </span>
                <span className="">{agencyName}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="table-responsive">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <table className="table table-vertical-center mb-0 mt-0">
                <thead>
                  <tr className="bg-gray-100">
                    <th>Booking ID</th>
                    <th>Invoice No.</th>
                    <th>Supplier</th>
                    <th className="min-w-100px">Booking Date</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Received Amount</th>
                    <th>Due Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
            )}
            {list?.length === 0 && (
              <Typography
                variant="h5"
                className="text-center w-100 text-muted my-5"
              >
                No Records to show
              </Typography>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
