import { Typography } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";

export const GoBack = ({ link }) => {
  const history = useHistory();
  return (
    <button
      onClick={() => (link ? history.push(link) : history.goBack())}
      className="d-flex btn text-hover-primary align-items-center"
    >
      <span className="svg-icon mr-3 svg-icon-dark svg-icon-lg">
        <SVG
          title="Go Back"
          src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
        />
      </span>
      <Typography variant="h6">Go Back</Typography>
    </button>
  );
};
