import { Icon } from "@iconify/react";
import { Typography } from "@material-ui/core";
import React from "react";

export const AddressCard = ({ title, phone, email, address }) => {
  return (
    <div className="text-center">
      <Icon
        icon="lucide:info"
        className="text-primary"
        height={40}
        width={40}
      />
      <Typography
        variant="h5"
        className="text-dark font-weight-bolder mb-5 mt-2"
      >
        {title}
      </Typography>
      {/* address */}
      <Typography variant="h6" className="mb-5 text-muted">
        {address}
      </Typography>
      {/* phone */}
      <a href={`tel:${phone}`} className="text-primary">
        <Typography variant="h6" className="mb-5">
          {phone}
        </Typography>
      </a>
      {/* email */}
      <a href={`mailto:${email}`} className="text-primary">
        <Typography variant="h6" className="mb-5">
          {email}
        </Typography>
      </a>
    </div>
  );
};
