import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../_metronic/layout/components/common";
import { Wrapper } from "../../../../_metronic/layout/components/ticketSummary/common/Wrapper";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { HOME } from "../../../routesMap";

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <Paper className="h-100 p-8 d-flex flex-column justify-content-center align-items-center">
        <img
          className=""
          src={toAbsoluteUrl("/media/svg/illustrations/404.svg")}
          alt=""
        />
        <Typography
          variant="h5"
          className="max-w-500px text-center font-weight-bold mt-8 mb-1"
        >
          Sorry! Page not found
        </Typography>
        <Typography
          variant="h6"
          className="max-w-500px text-center font-weight-normal mb-8"
        >
          The content you’re looking for doesn’t exist.
          Either it's not available, or you mistyped the link.
        </Typography>

        <Button type="primary" onClick={() => history.push(HOME)}>
          Go to homepage
        </Button>
      </Paper>
    </Wrapper>
  );
};
