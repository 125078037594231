import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Button } from "../../_metronic/layout/components/common";
import { TextWithIcon } from "../../_metronic/layout/components/home/Home";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";
import { WorkWithUsSection } from "../../_metronic/layout/components/public/about/WorkWithUsSection";
import { ContentSection } from "../../_metronic/layout/components/public/common/ContentSection";
import { GET_ABOUT_US_CONTENT } from "../../graphql/queries";
import { AboutUseSkeleton } from "../skeletons/AboutUsSkeleton";
import { formatImageUrl } from "../helpers/formatters/strapiImagesUrl";
import { ContentWithTextIcons } from "../../_metronic/layout/components/public/common/ContentWithTextIcons";

export const AboutUsPage = () => {
  const { data, loading, error } = useQuery(GET_ABOUT_US_CONTENT);
  if (loading)
    return (
      <PublicLayout>
        <AboutUseSkeleton />
      </PublicLayout>
    );
  if (error) {
    return (
      <PublicLayout>
        <div className="text-center vh-100 pt-20">
          <Typography variant="h3" className="text-dark font-weight-bolder">
            Something went wrong
          </Typography>
          <div className="my-5" />
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </PublicLayout>
    );
  }
  if (!data?.aboutUsPage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  const {
    heroBlock,
    contentBlocks,
    cardsBlock,
    iconPointsBlock,
  } = data?.aboutUsPage?.data?.attributes;
  return (
    <PublicLayout>
      {
        <div className="my-20">
          <ContentSection data={heroBlock} />
          <div className="my-25" />
          <WorkWithUsSection data={cardsBlock} />
          {contentBlocks.map((block, index) => (
            <div key={index}>
              <ContentSection data={block} />
              <div className="my-25" />
            </div>
          ))}

          <ContentWithTextIcons data={iconPointsBlock} />
        </div>
      }
    </PublicLayout>
  );
};
