import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button, SelectContainer, TextFieldContainer } from "../common";
import styles from "./HelpCenter.module.scss";

export const Support = ({ formData }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid container spacing={3} justify="space-between" alignItems="stretch">
      <Grid xs={12} item>
        <Paper className={clsx(styles.paper, "d-flex align-items-center")}>
          <div>
            <Typography className="font-weight-bold mb-2" variant="h5">
              Need Help?
            </Typography>
            <Typography variant="body">
              get in touch with our support team
            </Typography>
          </div>
          {matchesSm && (
            <img
              className="ml-auto w-100px"
              src={toAbsoluteUrl("/media/svg/illustrations/amico.svg")}
              alt=""
            />
          )}
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className={styles.paper}>
          <Typography variant="h6" className="font-weight-normal">
            contact FlyMe247 Support
          </Typography>
          <form onSubmit={formData.handleSubmit}>
            <TextFieldContainer
              vertical
              showErrors={formData.touched.email}
              errorMessage={formData.errors.email}
              inputProps={formData.getFieldProps("email")}
              placeholder="Contact Email"
              variant="secondary"
            />
            <SelectContainer
              vertical
              showErrors={formData.touched.department}
              errorMessage={formData.errors.department}
              inputProps={formData.getFieldProps("department")}
              placeholder="select department"
              variant="secondary"
            />
            <TextFieldContainer
              vertical
              showErrors={formData.touched.msg}
              errorMessage={formData.errors.msg}
              inputProps={formData.getFieldProps("msg")}
              placeholder="message details"
              multiline
              variant="secondary"
            />
            <Button className="w-100" type="submit">
              Send
            </Button>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={styles.paper}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} className='d-flex justify-content-center flex-column' >
              <div>
                <Typography className="font-weight-bold mb-2" variant="h5">
                  About
                </Typography>
                <Typography variant="body1">
                  FlyMe247 are both trading names of FlyMe247. FlyMe247
                  established in 2019, is the U.K. retail travel arm of B2B
                  global travel services wholesaler.
                </Typography>
              </div>
              <div className="my-4">
                <Typography className="font-weight-bold mb-2" variant="h5">
                  Location
                </Typography>
                <Typography variant="body1">
                  Crown House, North Circular Road, London <br /> NW10 7PN
                </Typography>
              </div>
              <div className="d-flex align-items-center">
                <img
                  src={toAbsoluteUrl("/media/svg/icons/General/phone.svg")}
                  alt=""
                />
                <span className="ml-3">+44 2080049377</span>
              </div>
              <div className="d-flex align-items-center my-4">
                <img
                  src={toAbsoluteUrl("/media/svg/icons/General/mail.svg")}
                  alt=""
                />
                <span className="ml-3">booking@flyme247.com</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.mapContainer}>
                <img
                  src={toAbsoluteUrl("/media/svg/icons/temp/map.jpg")}
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
