import { Grid, Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import {
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_WALLET,
  bookingTypesMap,
} from "../../../../redux/state/booking/bookingConstants";
import { paymentForEnums } from "../../../../redux/state/user/userConstants";
import { PassengersList } from "../admin/bookingMng/issuedOrderDetails/orderSummary/PassengersList";
import { Payment } from "../common/payment/Payment";
import { CardWithBlueHeader } from "../ticketSummary/TicketDetails";
import { FlightDetailsCard } from "./DapartingFlights";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3, 5),
    textAlign: "left",
    height: "100%",
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

export const paymentOptions = [
  { value: PAYMENT_TYPE_WALLET, label: "B2B wallet" },
  // { value: PAYMENT_TYPE_CARD, label: "Credit Card" },
  { value: PAYMENT_TYPE_WAAFI, label: "WAAFI" },
];
export const getYearOptions = () => {
  const today = new Date();
  const currYear = parseInt(today.getFullYear());
  const yearOptions = Array(20)
    .fill(0)
    .map((_, index) => ({ value: currYear + index, label: currYear + index }));
  return yearOptions;
};

export const getMonthOptions = () =>
  Array(12)
    .fill(0)
    .map((_, index) => ({ value: index + 1, label: index + 1 }));

export const CompleteOrder = ({
  steps,
  cardFormik,
  b2bFormik,
  waafiFormik,
  premiereFormik,
  setPaymentType,
  paymentType,
  issueHoldBooking,
  loading,
  bookingDetails,
  bookingType,
  isIssueAdmin,
  stripePaymentBody,
  totalPrice,
  onSubmit,
}) => {
  const history = useHistory();
  const { id, guid } = useParams();
  const classes = useStyles();
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const { segment, cabinClass, passengers, agencyId } = bookingDetails || {};
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  return (
    <Grid container spacing={3}>
      {!issueHoldBooking && (
        <Grid item xs={12}>
          {steps}
        </Grid>
      )}
      {bookingType !== bookingTypesMap.package && (
        <Grid item xs={12}>
          <CardWithBlueHeader
            title="Flight information"
            print
            iconSrc="/media/svg/icons/General/plane-white.svg"
          >
            <FlightDetailsCard
              airlinesList={airlinesList}
              airportsList={airportsList}
              segments={segments}
              cabinClass={cabinClass}
              padding
            />
          </CardWithBlueHeader>
        </Grid>
      )}
      <Grid item xs={12}>
        <PassengersList noTicket passengers={passengers} />
      </Grid>
      <Grid item xs={12}>
        <Payment
          cardFormik={cardFormik}
          b2bFormik={b2bFormik}
          waafiFormik={waafiFormik}
          premiereFormik={premiereFormik}
          setPaymentType={setPaymentType}
          paymentType={paymentType}
          loading={loading}
          bookingDetails={bookingDetails}
          isIssueAdmin={isIssueAdmin}
          agencyId={agencyId}
          paymentFor={paymentForEnums.booking}
          stripePaymentBody={stripePaymentBody}
          totalPrice={totalPrice}
          onSubmit={onSubmit}
        />
      </Grid>
      {!issueHoldBooking && (
        <Grid item xs={12}>
          <Paper className={clsx(classes.paper, "d-flex")}>
            <button
              onClick={() => history.goBack()}
              disabled={loading}
              className="btn btn-light-primary ml-auto mr-auto w-50 h-auto"
            >
              Previous
            </button>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
