import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./paymentConstants";

export const RESET = `${MODULE_NAME}/RESET`;
export const GET_TOP_UPS = requestActions(MODULE_NAME, "GET_TOP_UPS");
export const GET_ALL_TOP_UPS = requestActions(MODULE_NAME, "GET_ALL_TOP_UPS");
export const DELETE_TOP_UP = requestActions(MODULE_NAME, "DELETE_TOP_UP");
export const EDIT_TOP_UP = requestActions(MODULE_NAME, "EDIT_TOP_UP");
export const FILTER_ALL_TOP_UP = requestActions(
  MODULE_NAME,
  "FILTER_ALL_TOP_UP"
);
export const CREATE = requestActions(MODULE_NAME, "CREATE_TOP_UP");
export const GET_SPECIFIC_TOP_UP = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_TOP_UP"
);
export const GET_PAYMENT_METHOD_BALANCES = requestActions(
  MODULE_NAME,
  "GET_PAYMENT_METHOD_BALANCES"
);
export const GET_PAYMENT_LOGS = requestActions(MODULE_NAME, "GET_PAYMENT_LOGS");

export const filterAllTopUps = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterAllTopUps",
    { sortingParams, filterParam, pageNumber, pageSize },
    FILTER_ALL_TOP_UP
  );

export const getPaymentMethodBalances = () =>
  requestApiCall("getPaymentMethodBalances", {}, GET_PAYMENT_METHOD_BALANCES);

export const getPaymentLogs = ({ companyId, paymentType }) =>
  requestApiCall(
    "getPaymentLogs",
    { companyId, paymentType },
    GET_PAYMENT_LOGS
  );

export const getSpecificTopUp = ({ id }) =>
  requestApiCall("getSpecificTopUp", { id }, GET_SPECIFIC_TOP_UP);

export const getTopUps = (params) =>
  requestApiCall("getTopUps", params, GET_TOP_UPS);

export const deleteTopUp = (params) =>
  requestApiCall("deleteTopUp", params, DELETE_TOP_UP);

export const editTopUp = (params) =>
  requestApiCall("editTopUp", params, EDIT_TOP_UP);

export const getAllTopUps = (params) =>
  requestApiCall("getAllTopUps", params, GET_ALL_TOP_UPS);

export const createTopUps = (params) =>
  requestApiCall("createTopUps", params, CREATE);

export const clearCreateTopUps = () => ({ type: CREATE.CLEAR });

export const clearDelete = () => ({ type: DELETE_TOP_UP.CLEAR });

export const clearEdit = () => ({ type: EDIT_TOP_UP.CLEAR });

export const reset = () => ({ type: RESET });
