import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/user";

export default function useUsersOptions() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ user }) => user.allUsers);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllUsers());
    }
  }, [dispatch, list]);
  const usersOptions = useMemo(
    () =>
      list?.map(({ name, id, email }) => ({
        value: id,
        label: name || email,
      })),
    [list]
  );
  return { usersOptions, loading };
}
