import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./ticketSummaryConstants";

export const GET_SELECTED_TICKET = requestActions(
  MODULE_NAME,
  "GET_SELECTED_TICKET"
);
export const GET_TICKET_SUMMARY = requestActions(
  MODULE_NAME,
  "GET_TICKET_SUMMARY"
);
export const GET_TICKET_SUMMARY_BY_AGENCY = requestActions(
  MODULE_NAME,
  "GET_TICKET_SUMMARY_BY_AGENCY"
);
export const EDIT_TICKET = requestActions(MODULE_NAME, "EDIT_TICKET");
export const DELETE_TICKET = requestActions(MODULE_NAME, "DELETE_TICKET");
export const SEND_ETICKET = requestActions(MODULE_NAME, "SEND_ETICKET");
export const DOWNLOAD_ETICKET = requestActions(MODULE_NAME, "DOWNLOAD_ETICKET");
export const SEND_INVOICE = requestActions(MODULE_NAME, "SEND_INVOICE");
export const DOWNLOAD_INVOICE = requestActions(MODULE_NAME, "DOWNLOAD_INVOICE");
export const SET_SELECTED_TICKET = "SET_SELECTED_TICKET";

export const getTicketSummary = () =>
  requestApiCall("getTicketSummary", {}, GET_TICKET_SUMMARY);

export const getTicketSummaryByAgency = ({ id }) =>
  requestApiCall(
    "getTicketSummaryByAgency",
    { id },
    GET_TICKET_SUMMARY_BY_AGENCY
  );

export const deleteTicket = ({ id }) =>
  requestApiCall("deleteTicket", { id }, DELETE_TICKET);

export const editTicket = (params) =>
  requestApiCall("editTicket", params, EDIT_TICKET);

export const getTicketDetails = ({ id }) =>
  requestApiCall("getTicketDetails", { id }, GET_SELECTED_TICKET);

export const sendETicketEmail = ({
  companyId,
  transactionId,
  to,
  cc,
  bcc,
  remarks,
}) =>
  requestApiCall(
    "sendETicketEmail",
    {
      companyId,
      transactionId,
      to,
      cc,
      bcc,
      remarks,
    },
    SEND_ETICKET
  );

export const downloadETicket = ({ id }) =>
  requestApiCall("downloadETicket", { id }, DOWNLOAD_ETICKET);

export const sendInvoiceEmail = ({
  companyId,
  transactionId,
  to,
  cc,
  bcc,
  remarks,
}) =>
  requestApiCall(
    "sendInvoiceEmail",
    {
      companyId,
      transactionId,
      to,
      cc,
      bcc,
      remarks,
    },
    SEND_INVOICE
  );

export const downloadInvoice = ({ companyId, transactionId }) =>
  requestApiCall(
    "downloadInvoice",
    { companyId, transactionId },
    DOWNLOAD_INVOICE
  );

export const setSelectedTicket = (data) => ({
  type: SET_SELECTED_TICKET,
  payload: data,
});
