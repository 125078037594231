import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Seperator } from "../../../common";
import { useSelector } from "react-redux";

export const BalanceDueSummary = ({ stats, isReport }) => {
  const { totalAmount, totalPax, totalBookings, totalDue, totalReceived } =
    stats ?? {};
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  return (
    <div>
      <Grid container className="bg-gray-200">
        <Grid item className="p-6 bg-gray-700" xs={12}></Grid>
        {/* <Grid item className="px-7 py-2" xs={6}>
          <Typography>S.NO.</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right"></Typography>
        </Grid>
        <Seperator className="m-0" /> */}
        {isReport && (
          <>
            {isAdmin && (
              <>
                <Grid item className="px-7 py-2" xs={6}>
                  <Typography>Sales Price</Typography>
                </Grid>
                <Grid item className="px-7 py-2" xs={6}>
                  <Typography align="right">
                    ${totalAmount?.toFixed(2)}
                  </Typography>
                </Grid>
              </>
            )}
            <Seperator className="m-0" />
            <Grid item className="px-7 py-2" xs={6}>
              <Typography>Amount Received</Typography>
            </Grid>
            <Grid item className="px-7 py-2" xs={6}>
              <Typography align="right">
                ${totalReceived?.toFixed(2)}
              </Typography>
            </Grid>
            <Seperator className="m-0" />
          </>
        )}
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Due</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">${totalDue?.toFixed(2)}</Typography>
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Bookings</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">{totalBookings}</Typography>
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Tickets</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">{totalPax}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
