import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { actions } from "../../../../redux/state/markup";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { getValues, optimizeDatesArray, optimizeTimesArray } from "./utils";
import { markupSchema } from "./schema";
import { MarkupForm } from "./MarkupForm";

export const CreateMarkup = () => {
  const { loading, success, failure } = useSelector(
    ({ markup }) => markup.create
  );
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const formFields = useFormik({
    initialValues: {
      MarkupId: uuid(),
      MarkupBy: isAdmin ? "Admin" : "Agency",
      CreatedByAgencyId: isAdmin ? "" : `${companyInUserId}`,
      ComponentType: "F", //F only
      MarkupType: "B2B", // B2C for agency B2B for superadmin
      RuleType: "",
      RuleName: "",
      Amount: 0,
      IsPercent: true,
      IsPaxWise: false,
      IsOnTotalFare: true,
      MinAmount: 0,
      IsPaxWiseMinAmount: false, // added new
      MaxAmount: 0,
      IsPaxWiseMaxAmount: false, // added new
      JourneyType: "0",
      TripType: [],
      FareType: [],
      Classes: [],
      StopOvers: [],
      Airlines: [],
      Suppliers: [],
      Airlines2: [],
      AgencyIds: "",
      SubAgencyIds: "",
      // Suppliers: "",
      PCC: "",
      EngineType: "",
      // PassengerType: [],
      PassengerType: "",
      Passengers: [],
      DepartCountry: [],
      DepartCountry2: [],
      DepartAirport: [],
      DepartAirport2: [],
      ArrivalCountry: [],
      ArrivalCountry2: [],
      ArrivalAirport: [],
      ArrivalAirport2: [],
      DepartDates: [
        {
          From: "",
          To: "",
        },
      ],
      DepartTimes: [
        {
          Day: "0",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "1",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "2",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "3",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "4",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "5",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "6",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
      ],
      ArrivalDates: [
        {
          From: "",
          To: "",
        },
      ],
      ArrivalTimes: [
        {
          Day: "0",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "1",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "2",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "3",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "4",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "5",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
        {
          Day: "6",
          Times: [
            {
              From: "",
              To: "",
            },
          ],
        },
      ],
      OLTFrom: "",
      OLTTo: "",
      OBC: "",
      IBC: "",
      ILTFrom: "", // added new
      ILTTo: "", // added new
      FlightDurationFrom: 0,
      FlightDurationTo: 0,
      markupStatus: true,
    },
    validationSchema: markupSchema,
    onSubmit: ({
      JourneyType,
      FareType,
      Classes,
      Suppliers,
      Airlines,
      Airlines2,
      PassengerType,
      Passengers,
      DepartCountry,
      DepartAirport,
      ArrivalCountry,
      ArrivalAirport,
      ArrivalDates,
      ArrivalTimes,
      DepartDates,
      DepartTimes,
      StopOvers,
      AgencyIds,
      TripType,
      markupStatus: isActive,
      ...rest
    }) => {
      const markupParams = {
        ...rest,
        JourneyType: getValues(JourneyType),
        TripType: getValues(TripType),
        FareType: getValues(FareType),
        Classes: getValues(Classes),
        Airlines: getValues(Airlines),
        Suppliers: getValues(Suppliers),
        PassengerType: getValues(PassengerType),
        Passengers: getValues(Passengers),
        DepartCountry: getValues(DepartCountry),
        DepartAirport: getValues(DepartAirport),
        ArrivalCountry: getValues(ArrivalCountry),
        ArrivalAirport: getValues(ArrivalAirport),
        StopOvers: getValues(StopOvers),
        ArrivalDates: optimizeDatesArray(ArrivalDates),
        ArrivalTimes: optimizeTimesArray(ArrivalTimes),
        DepartDates: optimizeDatesArray(DepartDates),
        DepartTimes: optimizeTimesArray(DepartTimes),
        AgencyIds: getValues(AgencyIds),
      };
      const markupRuleJson = JSON.stringify(markupParams);
      dispatch(
        actions.createMarkup({
          markupRuleJson,
          companyId: companyInUserId,
          isActive,
        })
      );
    },
  });
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Markup Created",
        })
      );
      formFields.resetForm();
      dispatch(actions.reset());
    }
  }, [dispatch, formFields, success]);

  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, failure]);

  return <MarkupForm loading={loading} formFields={formFields} />;
};
