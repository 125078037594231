import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./chartsActions";

const initialState = {
  getBookingByMonth: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  getRevenueByMonth: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
};

export const chartReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_BOOKING_BY_MONTH.REQUESTED:
      case actions.GET_COMPANY_BOOKING_BY_MONTH.REQUESTED:
        state.getBookingByMonth.loading = true;
        break;
      case actions.GET_BOOKING_BY_MONTH.SUCCEEDED:
      case actions.GET_COMPANY_BOOKING_BY_MONTH.SUCCEEDED:
        state.getBookingByMonth.loading = false;
        state.getBookingByMonth.success = true;
        state.getBookingByMonth.list = payload.data.data?.$values;
        break;
      case actions.GET_BOOKING_BY_MONTH.FAILED:
      case actions.GET_COMPANY_BOOKING_BY_MONTH.FAILED:
        state.getBookingByMonth.loading = false;
        state.getBookingByMonth.failure = payload.error;
        break;
      case actions.GET_REVENUE_BY_MONTH.REQUESTED:
      case actions.GET_COMPANY_REVENUE_BY_MONTH.REQUESTED:
        state.getRevenueByMonth.loading = true;
        break;
      case actions.GET_REVENUE_BY_MONTH.SUCCEEDED:
      case actions.GET_COMPANY_REVENUE_BY_MONTH.SUCCEEDED:
        state.getRevenueByMonth.loading = false;
        state.getRevenueByMonth.success = true;
        state.getRevenueByMonth.list = payload.data.data?.$values;
        break;
      case actions.GET_REVENUE_BY_MONTH.FAILED:
      case actions.GET_COMPANY_REVENUE_BY_MONTH.FAILED:
        state.getRevenueByMonth.loading = false;
        state.getRevenueByMonth.failure = payload.error;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
