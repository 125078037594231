import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/package";

export default function usePackageInclusionsList() {
  const dispatch = useDispatch();
  const { list, loading, success, failure } = useSelector(
    ({ packages }) => packages.allInclusions
  );
  useEffect(() => {
    if (!list && !loading) {
      dispatch(actions.getAllInclusions());
    }
  }, [dispatch, list, loading]);
  return { list, loading, success, failure };
}
