import React from "react";
import clsx from "clsx";
import Select from "react-select/async";
import { components } from "react-select";
import {
  emphasize,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  MenuItem,
  Chip,
  Paper,
  Tooltip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { FixedSizeList as List } from "react-window";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
    width: "100%",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    // overflow: "hidden",
    width: "330px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  chip: {
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: theme.typography.pxToRem(16),
    // backgroundColor: "red",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "190px",
  },
  placeholder: {
    position: "absolute",
    left: 0,
    bottom: 0,
    fontSize: theme.typography.pxToRem(16),
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
        disableUnderline: true,
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const theme = useTheme();
  return (
    // <Tooltip title={<Typography>{props.children}</Typography>}>
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: theme.typography.pxToRem(16),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.children}
    </MenuItem>
    // </Tooltip>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Tooltip title={<Typography>{props.children}</Typography>}>
      <Typography
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    </Tooltip>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.isLoading ? (
        <div className="w-100 h-100 p-7">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        props.children
      )}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const MenuList = ({ options, children, maxHeight, getValue, isLoading }) => {
  const [value] = getValue();
  const height = 35;
  const initialOffset = options.indexOf(value) * height;

  if (options.length === 0)
    return (
      <div className="h-100 p-7">
        <Typography variant="body1" className="text-muted text-center">
          No Airports Found
          <br />
          Try Searching by Airport Name, City or Code
        </Typography>
      </div>
    );

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const customComponents = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MenuList,

  IndicatorSeparator: () => null,
  IndicatorsContainer: () => null,
};

export function AutoCompleteSelect({
  label,
  onChange,
  value,
  isOptionsLoaded,
  options,
  id,
  isLoading,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
      fontSize: theme.typography.pxToRem(16),
    }),
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      // padding: 20,
      // fontSize: theme.typography.pxToRem(16),
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: theme.typography.pxToRem(16),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  };

  const filterOptions = (inputValue) => {
    const newOptions = [];
    options.forEach((option) => {
      const { city, code, name } = option;
      const lowerSearchText = inputValue.toLowerCase();

      if (code.toLowerCase() === lowerSearchText) {
        // add to the beginning of the array
        newOptions.unshift(option);
      } else if (
        name.toLowerCase().includes(lowerSearchText) ||
        city.toLowerCase().includes(lowerSearchText)
      ) {
        // add to the end of the array
        newOptions.push(option);
      }
    });
    return newOptions;
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 400);
  };

  const noOptionsText =
    "No Airports Found, Try Searching by City or Airport Code";

  return (
    <Select
      classes={classes}
      styles={selectStyles}
      inputId={id}
      TextFieldProps={{
        label: label,
        InputLabelProps: {
          htmlFor: { id },
          shrink: true,
        },
        placeholder: isOptionsLoaded ? "Search Airport" : "loading options",
      }}
      // options={options}
      components={customComponents}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
      // isDisabled={!isOptionsLoaded}
      // filterOption={createFilter({ ignoreAccents: false })}
      // filterOption={customFilter}
      // noOptionsMessage={() => "No Airports Found"}
      noOptionsMessage={() => noOptionsText}
      isLoading={isLoading}
      isClearable={true}
    />
  );
}
