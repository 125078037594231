import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { useParams } from "react-router-dom";
import { BillingHistory } from "./BillingHistory";

const paymentMethodSchema = Yup.object().shape({
  bank: Yup.string().required("Ticket No is required"),
  accountHolderName: Yup.string().required("Holder's name is required"),
  accountNo: Yup.number("Account number should contain numbers only").required(
    "Holder's name is required"
  ),
  swiftCode: Yup.string().required("SWIFT is required"),
  iban: Yup.string().required("IBAN/BIC is required"),
  currency: Yup.string().required("Currency is required"),
});

export const ClientBilling = () => {
  const { id } = useParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const onSave = () => {};
  const fields = useFormik({
    initialValues: {
      bank: "",
      accountHolderName: "",
      accountNo: "",
      swiftCode: "",
      iban: "",
      currency: "",
    },
    validationSchema: paymentMethodSchema,
    onSubmit: () => {},
  });
  const onHide = () => {
    setShowEditModal(false);
    fields.resetForm();
  };
  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper className="p-7 bg-white">
          <PaymentMethodForm
            onHide={onHide}
            show={showEditModal}
            success={false}
            loading={false}
            onSave={onSave}
            fields={fields}
          />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Typography variant="h5" className="font-weight-bold">
              Payment Methods
            </Typography>
            <button
              onClick={() => setShowEditModal(true)}
              className="btn btn-danger"
            >
              add payment method
            </button>
          </div>
          <ExpansionPanel className="shadow-none">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <PaymentMethodCard primary cardType={CARD_TYPE_VISA} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <CardInfo />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel className="shadow-none">
            <ExpansionPanelSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <PaymentMethodCard cardType={CARD_TYPE_MASTERCARD} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <CardInfo />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <BillingHistory companyId={id} />
      </Grid>
    </Grid>
  );
};
