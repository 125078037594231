import { Typography } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";

import { HOME, SIGN_IN } from "../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../_helpers";
import { ClientInfoForm } from "../admin/client/ClientInfoForm";
import { Button } from "../common";
import { LeftBar } from "./LeftBar";

export const SubmitContainer = ({ firstStep, loading }) => {
  const history = useHistory();
  return (
    <div className="d-flex justify-content-between">
      <Button
        onClick={() => (firstStep ? history.push(HOME) : history.goBack())}
        className="my-10"
        variant="light-primary"
        type="button"
        smallPadding
      >
        <span className="svg-icon svg-icon-md mr-3 svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
          ></SVG>
        </span>
        {firstStep ? "Home" : "Previous"}
      </Button>
      <Button
        loading={loading}
        type="submit"
        className="my-10"
        variant="primary"
        smallPadding
      >
        Next
        <span className="svg-icon hover-white svg-icon-md ml-3 svg-icon-white">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
          ></SVG>
        </span>
      </Button>
    </div>
  );
};

export const CompanyDetails = ({
  routesMap,
  initialValues,
  onSubmitCompanyInfo,
  loading,
  companyType,
}) => {
  // useEffect(() => {
  //   if (values.country) {
  //     setFieldValue("bmCountryCode", getCountryCode(values.country));
  //     setFieldValue(
  //       "registeredPhoneCountryCode",
  //       getCountryCode(values.country)
  //     );
  //     setFieldValue("emergencyCountryCode", getCountryCode(values.country));
  //   }
  // }, [setFieldValue, values.country]);
  return (
    <LeftBar routesMap={routesMap} havingIssues>
      <div className="d-flex flex-column justify-content-around w-100">
        <div className="mb-6">
          <Typography className="font-weight-bold mb-1" variant="h6">
            Create Account
          </Typography>
          <Typography variant="body1" className="text-muted" noWrap>
            Already have an Account?
            <Link className="text-primary text-weight-bold" to={SIGN_IN}>
              Sign In
            </Link>
          </Typography>
        </div>
        <ClientInfoForm
          initialValues={initialValues}
          withSubmitContainer
          onSubmit={onSubmitCompanyInfo}
          companyType={companyType}
        />
      </div>
    </LeftBar>
  );
};
