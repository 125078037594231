/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { getTimeStamp, toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Seperator } from "../../common";
import { notificationStatusMap } from "../../../../../redux/state/communication/communicationConstants";
import { actions } from "../../../../../redux/state/communication";
import {
  ADMIN_NOTIFICATIONS,
  ADMIN_NOTIFICATION_DETAILS,
} from "../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../app/helpers/formatters";
import { formatDistanceToNow } from "date-fns";
import {
  ACCOUNT_NOTIFICATION,
  NOTIFICATION_DETAILS,
} from "../../../../../app/routesMap";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const dispatch = useDispatch();
  const { companyInUserId: companyId } = useSelector(
    ({ user }) => user.userData
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const [key, setKey] = useState("Alerts");
  const [isOpen, setIsOpen] = useState(false);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const { list, count } = useSelector(
    ({ communication }) => communication.notifications
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const onClickNotification = ({ id, status }) => {
    if (status === notificationStatusMap.unread) {
      dispatch(actions.updateNotificationStatus({ id }));
    }
    setIsOpen(false);
  };
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/notificationBell.svg"
                )}
              />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          show={isOpen}
          onToggle={() => {
            if (count > 0) {
              dispatch(
                actions.resetNotificationCount({ id: isAdmin ? 0 : companyId })
              );
            }
            setIsOpen((prev) => !prev);
          }}
          drop="down"
          alignRight
          className="mr-3"
        >
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
            // onClick={() => setIsOpen((prev) => !prev)}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">Notifications</Tooltip>
              }
            >
              <div
                role="button"
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/notificationBell.svg"
                    )}
                    style={{ fill: "#3798D3" }}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />

                {count > 0 && (
                  <div
                    style={{
                      right: "2px",
                      top: "2px",
                      width: "15px",
                      height: "15px",
                      fontSize: "8px",
                    }}
                    className="bg-danger position-absolute rounded-circle text-white "
                  >
                    <span className="d-block" style={{ marginTop: "2px" }}>
                      {count}
                    </span>
                  </div>
                )}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                {/* <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    23 new
                  </span>
                </h4> */}

                {/*<Tab.Container defaultActiveKey={key}>
                   <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item " as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show text-dark${
                          key === "Alerts" ? "active" : ""
                        }`}
                      >
                        Alerts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Events"
                        className={`nav-link show ${
                          key === "Events" ? "active" : ""
                        }`}
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Logs"
                        className={`nav-link show ${
                          key === "Logs" ? "active" : ""
                        }`}
                      >
                        Logs
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}

                {/* <Tab.Content className="tab-content">
                    
                    <Tab.Pane
                      eventKey="Events"
                      id="topbar_notifications_events"
                    > */}
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  className="navi navi-hover scroll my-4"
                  style={{ maxHeight: "300px", position: "relative" }}
                >
                  {list?.map(({ subject, status, id, createdDate }, index) => (
                    <div key={id}>
                      <NavLink
                        onClick={() => onClickNotification({ id, status })}
                        to={parseRoute(
                          isAdmin
                            ? ADMIN_NOTIFICATION_DETAILS
                            : NOTIFICATION_DETAILS,
                          { id }
                        )}
                        className="navi-item"
                      >
                        <div className="navi-link d-flex justify-content-between align-items-center">
                          {/* <div className="navi-icon mr-2">
                                <i className="flaticon2-line-chart text-success"></i>
                              </div> */}
                          <div className="navi-text">
                            <div className="font-weight-bold">{subject}</div>
                            <div className="text-muted">
                              {getTimeStamp(createdDate)}
                            </div>
                          </div>
                          {status === notificationStatusMap.unread && (
                            <span className="rounded-circle bg-danger p-1" />
                          )}
                        </div>
                      </NavLink>
                      {index < list.length - 1 && <Seperator className="m-0" />}
                    </div>
                  ))}
                  <div className="d-flex flex-center text-center text-muted min-h-200px">
                    All caught up!
                    <br />
                    No new notifications.
                  </div>
                </PerfectScrollbar>
                <div className="d-flex justify-content-center align-items-center p-5">
                  <NavLink
                    onClick={() => setIsOpen(false)}
                    to={isAdmin ? ADMIN_NOTIFICATIONS : ACCOUNT_NOTIFICATION}
                  >
                    <Typography variant="h6"> View All</Typography>
                  </NavLink>
                </div>
                {/* </Tab.Pane>
                  </Tab.Content>
                </Tab.Container> */}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
