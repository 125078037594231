import clsx from "clsx";
import React from "react";
import { packageStatusMap } from "../../../../../../redux/state/package/packageConstants";
// import { InventoryActionsDropdown } from "./InventoryActionsDropdown";

export const InventoryRow = ({
  data,
  onDelete,
  onEdit,
  getPkgSeasonName,
  getPkgTypeName,
}) => {
  const { id, packageName, packageSeasonId, packageTypeId, status } = data;
  // const allPaymentMethodsSubPermission =
  //   adminPermissionsIds.payment.paymentMethods.subPermissions;
  const seasonName = getPkgSeasonName(packageSeasonId);
  const packageTypeName = getPkgTypeName(packageTypeId);

  return (
    <tr>
      <td className="text-left text-primary">{packageName || "-"}</td>
      {/* <td>
        {departApt || "-"} - {arrivalApt || "-"}
      </td> */}
      <td>{seasonName}</td>
      <td>{packageTypeName}</td>
      <td
        className={clsx({
          "text-success": status === packageStatusMap.active,
          "text-danger": status === packageStatusMap.inActive,
        })}
      >
        {/* {status === flightStatusMap.open ? "Open" : "Cancelled"} */}
        {status}
      </td>

      {/* <td className="text-right"> */}
        {/* <InventoryActionsDropdown /> */}
        {/* <button
          // hidden={
          //   !subPermissions.includes(allPaymentMethodsSubPermission.edit.id)
          // }
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button
          // hidden={
          //   !subPermissions.includes(allPaymentMethodsSubPermission.delete.id)
          // }
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button> */}
      {/* </td> */}
    </tr>
  );
};
