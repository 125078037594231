import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { LoginSessionsTable } from "../../admin/client/clientDetails/clientSecutrity/LoginSessionsTable";

export const AgencySecurity = () => {
  const { id } = useSelector(({ company }) => company.companyData.data);
  return (
    <Grid container spacing={3}>
      {/* hidden for later */}
      {/* <Grid item xs={8}>
        <LoginSessionsChart />
      </Grid>
      <Grid item xs={4}>
        <ClientRecentLog />
      </Grid> */}
      <Grid item xs={12}>
        <LoginSessionsTable id={id} />
      </Grid>
      {/* <Grid item xs={12}>
        <Paper>
          <OperationLog />
        </Paper>
      </Grid> */}
    </Grid>
  );
};
