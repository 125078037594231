import { Paper } from "@material-ui/core";
import React from "react";
import { PersonalForm } from "../../../../../account/accountDetails/PersonalForm";

export const StaffSettings = () => {
  return (
    <Paper className="p-7">
      <PersonalForm hasRoleInput />
    </Paper>
  );
};
