import { Grid, Paper } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { RateColumn, SaveColumn } from "./ColumnActions";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accounting";

export const Currencies = () => {
  const dispatch = useDispatch();
  const { list, success: getCurrenciesSuccess } = useSelector(
    ({ accounting }) => accounting.currencies
  );
  useEffect(() => {
    dispatch(actions.getCurrencies());
  }, []);
  useEffect(() => {
    if (getCurrenciesSuccess) {
      fields.setValues(
        list.map(({ conversionRate }) => ({
          rate: conversionRate,
        }))
      );
    }
  }, [getCurrenciesSuccess]);
  
  const searchSchema = Yup.array().of(
    Yup.object().shape({
      rate: Yup.number().required("Field is required"),
    })
  );
  const fields = useFormik({
    initialValues: [],
    validationSchema: searchSchema,
    onSubmit: (values) => {},
  });
  const { getFieldProps, touched, errors, values } = fields;
  const entities = list?.map(({ fromCurrency, toCurrency, edit }, index) => ({
    from: fromCurrency,
    to: toCurrency,
    id: index,
    edit,
  }));
  const onSave = (index) => {
    const { id, fromCurrency, toCurrency } = list[index];
    dispatch(
      actions.editCurrencyRate({
        id,
        fromCurrency,
        toCurrency,
        conversionRate: values[index].rate,
        index,
      })
    );
  };
  const columns = [
    {
      text: "From Currency",
      dataField: "from",
      classes: "text-left font-weight-bold",
    },
    {
      text: "To Currency",
      dataField: "to",
      classes: "text-center font-weight-bold",
      headerClasses: "text-center",
    },
    {
      text: "Exchange rate",
      formatter: RateColumn,
      classes: "text-center pr-0",
      headerClasses: "text-center",
      formatExtraData: {
        getFieldProps,
        errors,
        touched,
      },
      style: {
        width: "350px",
      },
    },
    {
      text: "Action",
      formatter: SaveColumn,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      formatExtraData: {
        onSave,
      },
      style: {
        width: "75px",
      },
    },
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            keyField="id"
            data={entities || []}
            columns={columns}
            // defaultSorted={uiHelpers.defaultSorted}
            // onTableChange={getHandlerTableChange(
            //   customersUIProps.setQueryParams
            // )}
            // selectRow={{
            //   ...getSelectRow({
            //     entities: entities,
            //     ids: ids,
            //     setIds: setIds,
            //   }),
            //   selectColumnStyle: { width: "20px" },
            // }}
            // rowEvents={rowEvents}
            // rowStyle={{ cursor: "pointer" }}
            // {...paginationTableProps}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
