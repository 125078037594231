import React from "react";
import { CompanyDetails } from "../../../_metronic/layout/components/signUp";

export const CompanyDetailsPage = ({ routesMap, formik, loading }) => {
  return (
    <CompanyDetails
      routesMap={routesMap}
      formik={formik}
      createLoading={loading}
    />
  );
};
