import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Public.module.scss";
import clsx from "clsx";

export const HeaderNavLink = ({ path, label }) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  return (
    <NavLink
      className={clsx(styles.homeNavLink, {
        [styles.homeNavLink_active]: isActive,
      })}
      to={path}
    >
      {label}
    </NavLink>
  );
};
