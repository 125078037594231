// Function to format the image URL
export function formatImageUrl(imageUrl) {
  if (!imageUrl) {
    return "";
  }
  if (imageUrl.startsWith("/")) {
    // Attach local server link to the image URL
    return `http://localhost:1337${imageUrl}`;
  } else {
    return imageUrl;
  }
}
