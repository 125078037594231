import React, { useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input, LoadingSpinner } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/companyCredit";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import {
  CREDIT_STATUS_APPROVED,
  CREDIT_STATUS_PENDING,
} from "../../../../../../redux/state/companyCredit/companyCreditConstants";

export const AllocateFundsModal = ({ show, data, onHide, isRequest }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector(({ auth }) => auth);
  const {
    data: creditDetailsData,
    loading: creditDetailsLoading,
  } = useSelector(({ companyCredit }) => companyCredit.creditDetails);
  const { availableBalance } = creditDetailsData || {};
  const dataLoading = creditDetailsLoading;
  const {
    loading: addCreditLoading,
    success: addCreditSuccess,
    failure: addCreditFailure,
  } = useSelector(({ companyCredit }) => companyCredit.addCredit);
  const { id, name } = data || {};
  useEffect(() => {
    return () => dispatch(actions.reset());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(actions.getCreditDetailsByCompanyId({ companyId: id }));
      dispatch(actions.getCreditAllowanceByCompanyId({ companyId: id }));
    }
  }, [dispatch, id]);

  const schema = Yup.object().shape({
    creditAmount: Yup.number().required("Please enter Amount first"),
  });
  const fields = useFormik({
    initialValues: {
      creditAmount: "",
      remarks: "",
    },
    validationSchema: schema,
    onSubmit: ({ creditAmount, remarks }) => {
      let params = {
        createdBy: userId,
        agencyId: id,
        creditAmount,
        remarks,
        status: CREDIT_STATUS_APPROVED,
      };
      if (isRequest) {
        params = { ...params, status: CREDIT_STATUS_PENDING };
      } else {
        params = {
          ...params,
          status: CREDIT_STATUS_APPROVED,
          approvedById: userId,
        };
      }
      dispatch(actions.addCompanyCredit(params));
    },
  });
  const { getFieldProps, touched, errors, handleSubmit, resetForm } = fields;
  const hideModal = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (addCreditSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: addCreditSuccess,
        })
      );
      hideModal();
      dispatch(actions.clearAddCredit());
    }
  }, [addCreditSuccess, dispatch, hideModal, resetForm]);
  useEffect(() => {
    if (addCreditFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: addCreditFailure || "Something is wrong!",
        })
      );
      dispatch(actions.clearAddCredit());
    }
  }, [addCreditFailure, dispatch]);

  return (
    <Modal
      size="md"
      onHide={hideModal}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {isRequest ? "Request Credit" : "Allocate Credit"}
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {dataLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Grid container className="mb-7" spacing={3}>
                <Grid item xs className="d-flex align-items-center">
                  <Typography variant="h5" className="mb-3">
                    {name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className="bg-white border rounded border-dark p-3 min-w-200px text-center h-100 d-flex flex-column justify-content-center"
                >
                  <Typography variant="h5" className="mb-3">
                    <span className="text-primary">$</span>
                    {availableBalance || "0.0"}
                  </Typography>
                  <Typography variant="h6" className="mb-2">
                    Available Credit
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    {...getFieldProps("creditAmount")}
                    showErrors={touched["creditAmount"]}
                    errorMessage={errors["creditAmount"]}
                    variant="secondary"
                    label="Credit Amount"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    {...getFieldProps("remarks")}
                    showErrors={touched["remarks"]}
                    errorMessage={errors["remarks"]}
                    variant="secondary"
                    label="Remark"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={addCreditLoading}
            smallPadding
            type="submit"
            disabled={dataLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
