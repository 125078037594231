import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./flightSearchConstants";

export const RESET = `${MODULE_NAME}/RESET`;
export const SEARCH_FLIGHTS = requestActions(MODULE_NAME, "SEARCH_FLIGHTS");
export const REPRICE = requestActions(MODULE_NAME, "REPRICE");
export const GET_FARE_RULES = requestActions(MODULE_NAME, "GET_FARE_RULES");
export const VERIFY_TICKETS = requestActions(MODULE_NAME, "VERIFY_TICKETS");
export const SEARCH_FLIGHTS_PNR = requestActions(
  MODULE_NAME,
  "SEARCH_FLIGHTS_PNR"
);
export const GET_SEARCH_RESULTS = requestActions(
  MODULE_NAME,
  "GET_SEARCH_RESULTS"
);

export const SET_SELECTED_AIRLINE = "SET_SELECTED_AIRLINE";
export const SET_SELECTED_AIRLINE_DATA = "SET_SELECTED_AIRLINE_DATA";
export const REMOVE_SELECTED_AIRLINE_FROM_LIST =
  "REMOVE_SELECTED_AIRLINE_FROM_LIST";
export const MAKE_REPRICE_AS_SELECTED_AIRLINE =
  "MAKE_REPRICE_AS_SELECTED_AIRLINE";
export const SAVE_SEARCH_DETAILS = "SAVE_SEARCH_DETAILS";
export const SAVE_SEARCH_PARAMS = "SAVE_SEARCH_PARAMS";

export const setSelectedAirlineData = (params) => ({
  type: SET_SELECTED_AIRLINE_DATA,
  payload: params,
});

export const saveSearchDetails = (params) => ({
  type: SAVE_SEARCH_DETAILS,
  payload: params,
});

export const saveSearchParams = (params) => ({
  type: SAVE_SEARCH_PARAMS,
  payload: params,
});

export const searchFlightsByPnr = (params) =>
  requestApiCall("startSearchByPnr", params, SEARCH_FLIGHTS_PNR);

export const searchFlights = (params) =>
  requestApiCall("startSearch", params, SEARCH_FLIGHTS);

export const verifyTickets = (params) =>
  requestApiCall("verifyTickets", params, VERIFY_TICKETS);

export const getFareRules = (params) =>
  requestApiCall("getFareRules", params, GET_FARE_RULES);

export const reprice = (params) => requestApiCall("reprice", params, REPRICE);

export const getSearchResults = (params) =>
  requestApiCall("getSearchResults", params, GET_SEARCH_RESULTS);

export const setSelectedAirline = ({ id }) => ({
  type: SET_SELECTED_AIRLINE,
  payload: { id },
});

export const removeSelectedAirlineFromList = () => ({
  type: REMOVE_SELECTED_AIRLINE_FROM_LIST,
});

export const makeRepriceAsSelectedAirline = () => ({
  type: MAKE_REPRICE_AS_SELECTED_AIRLINE,
});

export const clearSearchResults = () => ({
  type: GET_SEARCH_RESULTS.CLEAR,
});

export const clearReprice = () => ({
  type: REPRICE.CLEAR,
});

export const clearFareRules = () => ({
  type: GET_FARE_RULES.CLEAR,
});

export const clearVerifyTickets = () => ({
  type: VERIFY_TICKETS.CLEAR,
});

export const reset = () => ({
  type: RESET,
});
