import React from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_partials/controls";

export function TopUpEditHeader({ loading, title }) {
  return (
    <>
      {loading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
