import clsx from "clsx";
import React from "react";
import { Footer } from "../../home/Footer";
import { PublicHeader } from "../../publicHeader/PublicHeader";

export const Wrapper = ({ children, noContainer }) => {
  return (
    <div className="min-h-100 d-flex flex-column justify-content-between">
      <PublicHeader noSpacing={noContainer} />
      <div className={clsx({ container: !noContainer })}>{children}</div>
      <Footer noSpacing={noContainer} />
    </div>
  );
};
