import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Button } from "../../common";
import { HOME } from "../../../../../app/routesMap";

export const SubscriptionSuccess = ({ issueHoldBooking }) => {
  const history = useHistory();
  const handleStartUsing = () => {
    history.replace(HOME);
    window.location.reload();
  };
  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        {steps}
      </Grid> */}
      <Grid item xs={12}>
        <Paper className="p-7 text-center">
          <img
            src={toAbsoluteUrl("/media/svg/icons/General/check-green.svg")}
            className="mb-2"
            alt=""
          />
          <Typography variant="h4" className="font-weight-bolder mt-5 mb-2">
            Congratulation! You have successfully subscribed to our service
          </Typography>
          <Typography variant="h6">
            You can now start using your subscription
          </Typography>
          <Button onClick={handleStartUsing} variant="primary" className="my-5">
            Start using your subscription
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
