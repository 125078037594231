import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { Button, Select, Input } from "../../common";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import useRoleOptions from "../../../../../app/helpers/hooks/useRoleOptions";
import { useHistory } from "react-router-dom";

export const CreateSubUserForm = ({ formik }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, success, failure } = useSelector(({ user }) => user.create);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { roleOptions } = useRoleOptions();
  const {
    touched,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = formik;
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: "User created",
          type: NOTIFICATION_TYPE_SUCCESS,
        })
      );
      resetForm();
      dispatch(actions.clearCreate());
    }
  }, [dispatch, resetForm, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: failure,
          type: NOTIFICATION_TYPE_ERROR,
        })
      );
      dispatch(actions.clearCreate());
    }
  }, [dispatch, failure]);
  return (
    <form onSubmit={handleSubmit}>
      {/* <TextFieldContainer
        fixedLabelWidth
        required
        name="companyId"
        showErrors={touched.companyId}
        errorMessage={errors.companyId}
        {getFieldProps("companyId")}
        label="Sub_Account ID :"
        disabled
      /> */}

      <Input
        variant="secondary"
        spacing
        horizontal
        fixedLabelWidth
        required
        name="name"
        showErrors={touched.name}
        errorMessage={errors.name}
        {...getFieldProps("name")}
        label="Name"
      />
      <Input
        variant="secondary"
        spacing
        horizontal
        fixedLabelWidth
        required
        name="email"
        showErrors={touched.email}
        errorMessage={errors.email}
        {...getFieldProps("email")}
        label="Email"
      />
      <Input
        variant="secondary"
        spacing
        horizontal
        fixedLabelWidth
        required
        name="phone"
        showErrors={touched.phone}
        errorMessage={errors.phone}
        {...getFieldProps("phone")}
        label="Contact Number"
      />
      {isAdmin && (
        <Select
          variant="secondary"
          spacing
          horizontal
          fixedLabelWidth
          required
          name="role"
          options={roleOptions}
          showErrors={touched.role}
          errorMessage={errors.role}
          value={values.role}
          onChange={(value) => setFieldValue("role", value)}
          label="Role"
        />
      )}
      <Input
        variant="secondary"
        spacing
        horizontal
        fixedLabelWidth
        required
        name="password"
        showErrors={touched.password}
        errorMessage={errors.password}
        {...getFieldProps("password")}
        label="New Password"
        type="password"
      />
      <Input
        variant="secondary"
        spacing
        horizontal
        fixedLabelWidth
        required
        name="confirmPassword"
        showErrors={touched.confirmPassword}
        errorMessage={errors.confirmPassword}
        {...getFieldProps("confirmPassword")}
        label="Confirm Password:"
        type="password"
      />
      <div className="d-flex justify-content-end">
        <button
          type="button"
          onClick={() => {
            resetForm();
            history.goBack();
          }}
          className="btn bg-gray-200 w-100px "
        >
          Cancel
        </button>
        <Button loading={loading} type="submit" className="ml-7">
          Save
        </Button>
      </div>
    </form>
  );
};
