import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./companyConstants";

export const BLOCK = requestActions(MODULE_NAME, "BLOCK");
export const UNBLOCK = requestActions(MODULE_NAME, "UNBLOCK");
export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const DELETE = requestActions(MODULE_NAME, "DELETE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const EDIT_SPECIFIC_COMPANY = requestActions(
  MODULE_NAME,
  "EDIT_SPECIFIC_COMPANY"
);
export const GET_COMPANY = requestActions(MODULE_NAME, "GET_COMPANY");
export const GET_SPECIFIC_COMPANY = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_COMPANY"
);
export const GET_ALL_COMPANY = requestActions(MODULE_NAME, "GET_ALL_COMPANY");
export const GET_COMPANY_SESSIONS = requestActions(
  MODULE_NAME,
  "GET_COMPANY_SESSIONS"
);
export const GET_COMPANY_USERS = requestActions(
  MODULE_NAME,
  "GET_COMPANY_USERS"
);
export const FILTER_ALL_COMPANIES = requestActions(
  MODULE_NAME,
  "FILTER_ALL_COMPANIES"
);

export const RESET = `${MODULE_NAME}/RESET`;
export const SET_NEW_PIN = "SET_NEW_PIN";
export const SET_REGISTER_COMPANY_TYPE = `${MODULE_NAME}/SET_REGISTER_COMPANY_TYPE`;

export const filterAllCompanies = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterAllCompanies",
    { sortingParams, filterParam, pageNumber, pageSize },
    FILTER_ALL_COMPANIES
  );

export const getCompanySessions = (params) =>
  requestApiCall("getCompanySessions", params, GET_COMPANY_SESSIONS);

export const blockCompany = (params) =>
  requestApiCall("blockCompany", params, BLOCK);

export const unblockCompany = (params) =>
  requestApiCall("unblockCompany", params, UNBLOCK);

export const deleteCompany = (params) =>
  requestApiCall("deleteCompany", params, DELETE);

export const createCompany = (params) =>
  requestApiCall("createCompany", params, CREATE);

export const editCompany = (params) =>
  requestApiCall("editCompany", params, EDIT);

export const editSpecificCompany = (params) =>
  requestApiCall("editCompany", params, EDIT_SPECIFIC_COMPANY);

export const getCompany = (params) =>
  requestApiCall("getCompany", params, GET_COMPANY);

export const getSpecificCompany = (params) =>
  requestApiCall("getCompany", params, GET_SPECIFIC_COMPANY);

export const getAllCompanies = (params) =>
  requestApiCall("getAllCompanies", params, GET_ALL_COMPANY);

export const getCompanyUsers = (params) =>
  requestApiCall("getCompanyUsers", params, GET_COMPANY_USERS);

export const reset = () => ({ type: RESET });

export const clearSpecificCompany = () => ({
  type: GET_SPECIFIC_COMPANY.CLEAR,
});

export const clearBlockUnblock = () => ({
  type: BLOCK.CLEAR,
});

export const clearCreate = () => ({
  type: CREATE.CLEAR,
});

export const clearEdit = () => ({
  type: EDIT.CLEAR,
});

export const setRegisterCompanyType = ({ type }) => ({
  type: SET_REGISTER_COMPANY_TYPE,
  payload: { type },
});

export const setNewPin = ({ pin }) => ({ type: SET_NEW_PIN, payload: { pin } });
