import { useCallback } from "react";
import useAirlinesList from "./useAirlinesList";

export default function useAirlineName() {
  const { airlinesList, loading, success, failure } = useAirlinesList();

  const getAirlineInfo = useCallback(
    (airportCode) =>
      airlinesList?.filter(({ code }) => airportCode === code)?.[0],
    [airlinesList]
  );
  return { getAirlineInfo, loading, success, failure };
}
