import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchCard } from "./common/SearchCard";
import { actions } from "../../../../redux/state/bookingMng";
import {
  BookingCancelled,
  BookingHold,
  BookingIssue,
  BookingRefund,
} from "../../../../redux/state/booking/bookingConstants";
import { LoadingSpinner } from "../common";

export const TableRow = ({ data, noRefund }) => {
  const badgeClassNames = {
    [BookingIssue]: "text-success",
    [BookingHold]: "text-warning",
    [BookingCancelled]: "text-danger",
    [BookingRefund]: "text-info",
  };
  const badgeText = {
    [BookingIssue]: "Complete",
    [BookingHold]: "Pending",
    [BookingCancelled]: "Cancelled",
    [BookingRefund]: "Refund",
  };
  return (
    <tr>
      <td>{data.orderNo}</td>
      <td>
        {data.passengers?.$values?.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`
        ) || "no passengers Data"}
      </td>
      <td>{data.gdsPnr || "-"}</td>
      <td>{data.flight?.departureDateTime || "no flight Data"}</td>
      <td>{data.ticketNo || "-"}</td>
      <td>
        <span className={badgeClassNames[data.bookingStatus]}>
          {badgeText[data.bookingStatus] || "-"}
        </span>
      </td>
    </tr>
  );
};

export const SearchOrderTable = ({ data }) => {
  const tableContent = data?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-vertical-center mb-0">
          <thead>
            <tr className="bg-gray-100">
              <th>Order No</th>
              <th>Passengers</th>
              <th>GDS PNR </th>
              <th>Flight Date</th>
              <th>Ticket No</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
        {data?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No bookings to show
          </Typography>
        )}
      </div>
    </div>
  );
};

export const SearchOrder = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { list, loading } = useSelector(({ bookingMng }) => bookingMng.orders);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState();
  useEffect(() => {
    setSearchValue("");
  }, [filter]);
  useEffect(() => {
    if (isAdmin) {
      dispatch(actions.getAllOrders({ PageNo: 0, PageSize: 0 }));
    } else {
      actions.getOrders({ companyId: companyInUserId, PageNo: 0, PageSize: 0 });
    }
  }, []);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAdmin) {
      dispatch(
        actions.filterAllOrders({
          filterValue: searchValue,
          filterType: filter,
        })
      );
    } else {
      dispatch(
        actions.filterOrders({
          companyId: companyInUserId,
          filterValue: searchValue,
          filterType: filter,
        })
      );
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            onSubmit={handleSubmit}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          {loading ? <LoadingSpinner /> : <SearchOrderTable data={list} />}
        </Paper>
      </Grid>
    </Grid>
  );
};
