import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Modal } from "react-bootstrap";
import { Button, Input } from "../common";

export const CancelModal = ({ show, onHide, id, bookingId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector(({ bookingMng }) => bookingMng.specificVoid);
  const { token } = useSelector((state) => state.auth);
  const { companyInUserId, id: userId } = useSelector(
    ({ user }) => user.userData
  );
  const {
    loading: voidLoading,
    success: voidSuccess,
    failure: voidFailure,
  } = useSelector(({ bookingMng }) => bookingMng.voidWithNoRefund);
  const formik = useFormik({
    initialValues: {
      fee: "",
    },
    validationSchema: Yup.object().shape({
      fee: Yup.number().required("Fee is required"),
    }),
    onSubmit: ({ fee }) => {
      const params = {
        transactionId: id,
        agencyId: `${companyInUserId}`,
        requestedUserId: `${userId}`,
        token,
        cancellationFee: +fee,
      };
      dispatch(actions.voidWithNoRefund(params));
    },
  });
  useEffect(() => {
    if (voidSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Ordered is cancelled successfully!",
        })
      );
      dispatch(actions.clearVoidWithNoRefund());
      onHide();
      dispatch(actions.getSpecificOrderByBookingId({ bookingId }));
    }
  }, [voidSuccess, dispatch, history, onHide, bookingId]);

  useEffect(() => {
    if (voidFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: voidFailure || "Something is wrong",
        })
      );
      dispatch(actions.clearVoidWithNoRefund());
    }
  }, [voidFailure, dispatch]);
  const { touched, errors, handleSubmit, getFieldProps } = formik;
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <form onSubmit={handleSubmit}>
          <Modal.Header>Cancel (No Refund)</Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Input
              {...getFieldProps("fee")}
              showErrors={touched.fee}
              errorMessage={errors.fee}
              label="Fee"
              placeholder="Supplier Fee"
              spacing
            />
          </Modal.Body>
          <Modal.Footer>
            <Button smallPadding type="button" onClick={onHide} variant="light">
              Cancel
            </Button>
            <> </>
            <Button
              smallPadding
              type="submit"
              loading={voidLoading}
              // onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </>
    </Modal>
  );
};
