import React from "react";
import {
  Grid,
  Paper,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { Button, Input, Select } from "../common";
import { AdvancedSettings } from "./AdvancedSettings";
import clsx from "clsx";
// import { countriesOptions } from "../../fareSearch/TravellerDetails";

export const MarkupForm = ({ formFields, loading, isEdit }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
  } = formFields;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid className="pr-3" item xs={12} md={7}>
          <Paper className="p-7 min-h-100">
            {/* <Select
            spacing
              variant="secondary"
              name="ComponentType"
              options={[
                { label: "Flight", value: "F" },
                { label: "Hotel", value: "H" },
              ]}
              onChange={(value) => setFieldValue("ComponentType", value)}
              value={values.ComponentType}
              errorMessage={errors.ComponentType}
              showErrors={touched.ComponentType}
              horizontal
              fixedLabelWidth
              label="Component Type"
            /> */}
            {/* <Select
            spacing
              variant="secondary"
              name="MarkupType"
              options={[
                { label: "B2B", value: "B2B" },
                { label: "B2C", value: "B2C" },
              ]}
              onChange={(value) => setFieldValue("MarkupType", value)}
              value={values.MarkupType}
              errorMessage={errors.MarkupType}
              showErrors={touched.MarkupType}
              horizontal
              fixedLabelWidth
              label="Markup Type"
            /> */}
            <Select
              spacing
              variant="secondary"
              name="RuleType"
              options={[
                { label: "Markup", value: "Markup" },
                { label: "Discount", value: "Discount" },
              ]}
              onChange={(value) => setFieldValue("RuleType", value)}
              value={values.RuleType}
              errorMessage={errors.RuleType}
              showErrors={touched.RuleType}
              horizontal
              fixedLabelWidth
              label="Rule Type"
            />
            <Input
              spacing
              variant="secondary"
              name="RuleName"
              onChange={handleChange}
              value={values.RuleName}
              errorMessage={errors.RuleName}
              showErrors={touched.RuleName}
              horizontal
              fixedLabelWidth
              label="Rule Name"
              placeholder="Rule Name"
            />
            <div className={clsx("d-flex", { "flex-column": !matchesSm })}>
              <span className="min-w-150px mt-2">Amount:</span>
              <div className="d-flex align-items-center">
                <Input
                  spacing
                  variant="secondary"
                  name="Amount"
                  onChange={handleChange}
                  value={values.Amount}
                  errorMessage={errors.Amount}
                  showErrors={touched.Amount}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  variant="secondary"
                  className="mx-3 max-w-60px"
                  options={[
                    { value: true, label: "%" },
                    { value: false, label: "$" },
                  ]}
                  name="IsPercent"
                  onChange={(value) => setFieldValue("IsPercent", value)}
                  value={values.IsPercent}
                  errorMessage={errors.IsPercent}
                  showErrors={touched.IsPercent}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  variant="secondary"
                  options={[
                    { value: true, label: "Pax" },
                    { value: false, label: "Booking" },
                  ]}
                  name="IsPaxWise"
                  onChange={(value) => setFieldValue("IsPaxWise", value)}
                  value={values.IsPaxWise}
                  errorMessage={errors.IsPaxWise}
                  showErrors={touched.IsPaxWise}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  className="ml-3"
                  variant="secondary"
                  options={[
                    { value: true, label: "Total Fare" },
                    { value: false, label: "Base Fare" },
                  ]}
                  name="IsOnTotalFare"
                  onChange={(value) => setFieldValue("IsOnTotalFare", value)}
                  value={values.IsOnTotalFare}
                  errorMessage={errors.IsOnTotalFare}
                  showErrors={touched.IsOnTotalFare}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </div>
            <div className={clsx("d-flex", { "flex-column": !matchesSm })}>
              <span className="min-w-150px mt-2">Min Amount:</span>
              <div className="d-flex align-items-center">
                <Input
                  spacing
                  variant="secondary"
                  name="MinAmount"
                  onChange={handleChange}
                  errorMessage={errors.MinAmount}
                  value={values.MinAmount}
                  showErrors={touched.MinAmount}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  variant="secondary"
                  className="ml-3 w-50"
                  options={[
                    { value: true, label: "Pax" },
                    { value: false, label: "Booking" },
                  ]}
                  name="IsPaxWiseMinAmount"
                  onChange={(value) =>
                    setFieldValue("IsPaxWiseMinAmount", value)
                  }
                  value={values.IsPaxWiseMinAmount}
                  errorMessage={errors.IsPaxWiseMinAmount}
                  showErrors={touched.IsPaxWiseMinAmount}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </div>
            <div className={clsx("d-flex", { "flex-column": !matchesSm })}>
              <span className="min-w-150px mt-2">Max Amount:</span>
              <div className="d-flex align-items-center">
                <Input
                  spacing
                  variant="secondary"
                  name="MaxAmount"
                  onChange={handleChange}
                  value={values.MaxAmount}
                  errorMessage={errors.MaxAmount}
                  showErrors={touched.MaxAmount}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  variant="secondary"
                  className="ml-3 w-50"
                  options={[
                    { value: true, label: "Pax" },
                    { value: false, label: "Booking" },
                  ]}
                  name="IsPaxWiseMaxAmount"
                  onChange={(value) =>
                    setFieldValue("IsPaxWiseMaxAmount", value)
                  }
                  value={values.IsPaxWiseMaxAmount}
                  errorMessage={errors.IsPaxWiseMaxAmount}
                  showErrors={touched.IsPaxWiseMaxAmount}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </div>
            <div className={clsx("d-flex", { "flex-column": !matchesSm })}>
              <span className="min-w-150px mt-2">Trip & Fare Type:</span>
              <div className="d-flex align-items-center">
                <Select
                  spacing
                  multi
                  options={[
                    { label: "Any", value: "0" },
                    { label: "Oneway", value: "O" },
                    { label: "RoundTrip", value: "R" },
                    { label: "Multicity", value: "M" },
                    { label: "OpenJaw", value: "J" },
                  ]}
                  variant="secondary"
                  name="TripType"
                  onChange={(values) => setFieldValue("TripType", values)}
                  value={values.TripType}
                  errorMessage={errors.TripType}
                  showErrors={touched.TripType}
                  horizontal
                  fixedLabelWidth
                />
                <Select
                  spacing
                  multi
                  options={[
                    { label: "Any", value: "0" },
                    { label: "Public", value: "1" },
                    { label: "Publish", value: "2" },
                    { label: "Consolidator", value: "3" },
                    { label: "PubToNet", value: "4" },
                    { label: "Mix", value: "5" },
                  ]}
                  variant="secondary"
                  className="ml-3 max-w-50"
                  name="FareType"
                  onChange={(values) => setFieldValue("FareType", values)}
                  value={values.FareType}
                  errorMessage={errors.FareType}
                  showErrors={touched.FareType}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </div>
            <Select
              spacing
              multi
              options={[
                { label: "Any", value: "0" },
                { label: "Economy", value: "1" },
                { label: "Premium", value: "2" },
                { label: "Business", value: "3" },
                { label: "First", value: "4" },
              ]}
              variant="secondary"
              name="Classes"
              onChange={(values) => setFieldValue("Classes", values)}
              value={values.Classes}
              errorMessage={errors.Classes}
              showErrors={touched.Classes}
              horizontal
              fixedLabelWidth
              label="Class"
            />
            <Select
              spacing
              multi
              options={[
                { label: "Direct", value: "0" },
                { label: "One Stop", value: "1" },
                { label: "Multiple Stops", value: "multi" },
              ]}
              variant="secondary"
              name="StopOvers"
              onChange={(values) => setFieldValue("StopOvers", values)}
              value={values.StopOvers}
              errorMessage={errors.StopOvers}
              showErrors={touched.StopOvers}
              horizontal
              fixedLabelWidth
              label="Stop Overs"
              placeholder="Select or Add"
            />
            {/* <Input
            spacing
            variant="secondary"
            name="apiUser"
            onChange={(value) => setFieldValue("ComponentType", value)}
            // value={values.apiUser}
            // errorMessage={errors.apiUser}
            // showErrors={touched.apiUser}
            horizontal
           
            fixedLabelWidth
            label="Api User"
            placeholder="Select or Add"
          />
          <Input
          spacing
            variant="secondary"
            name="AgencyIds"
            onChange={(value) => setFieldValue("ComponentType", value)}
            value={values.AgencyIds}
            errorMessage={errors.AgencyIds}
            showErrors={touched.AgencyIds}
            horizontal
           
            fixedLabelWidth
            label="Agency"
            placeholder="Select or Add"
          />
          <Input
          spacing
            variant="secondary"
            name="Suppliers"
            onChange={(value) => setFieldValue("ComponentType", value)}
            value={values.Suppliers}
            errorMessage={errors.Suppliers}
            showErrors={touched.Suppliers}
            horizontal
           
            fixedLabelWidth
            label="Suppliers"
            placeholder="Select or Add"
          />
          <Input
          spacing
            variant="secondary"
            name="extranetSupplier"
            onChange={(value) => setFieldValue("ComponentType", value)}
            value={values.extranetSupplier}
            errorMessage={errors.extranetSupplier}
            showErrors={touched.extranetSupplier}
            horizontal
           
            fixedLabelWidth
            label="Extranet Supplier"
            placeholder="Select or Add"
          /> */}
            {/* <Select
            spacing
              multi
              variant="secondary"
              options={[
                { label: "Any", value: "0" },
                { label: "ADULT", value: "1" },
                { label: "SENIOR", value: "2" },
                { label: "CHILD", value: "3" },
                { label: "INFANTONSEAT", value: "4" },
                { label: "INFANTONLAP", value: "5" },
              ]}
              name="PassengerType"
              onChange={(values) => setFieldValue("PassengerType", values)}
              value={values.PassengerType}
              errorMessage={errors.PassengerType}
              showErrors={touched.PassengerType}
              horizontal
              fixedLabelWidth
              label="Passenger Type"
              placeholder="Select or Add"
            /> */}
            <Select
              spacing
              multi
              variant="secondary"
              options={[
                { label: "Any", value: "" },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" },
              ]}
              name="Passengers"
              onChange={(values) => setFieldValue("Passengers", values)}
              value={values.Passengers}
              errorMessage={errors.Passengers}
              showErrors={touched.Passengers}
              horizontal
              fixedLabelWidth
              label="Passenger No."
              placeholder="Select or Add"
            />
            <div className="d-flex align-items-center">
              <label htmlFor="markupStatus" className="min-w-150px">
                Markup Status:
              </label>
              <Switch
                id="markupStatus"
                checked={values.markupStatus}
                onChange={(event) =>
                  setFieldValue("markupStatus", event.target.checked)
                }
                color="primary"
              />
            </div>
          </Paper>
        </Grid>
        <Grid className="pl-3" item xs={12} md={5}>
          <Paper style={{ color: "#80808F" }} className="p-7 h-100">
            <Typography className="font-weight-bold text-dark" variant="h5">
              Rules Quick Help
            </Typography>
            <Typography
              className="font-weight-normal mb-2 text-dark"
              variant="h6"
            >
              Creating a New Rule
            </Typography>
            <ul className="pl-5">
              <li>
                Select option “Set Markup” to create a new markup on Hotel /
                Flight / Transfer / Attraction for B2C Customer or B2B Agencies.
              </li>
              <li>
                Select Component Type Hotel / Flight / Transfer / Attraction.
                Then select Markup Type whether to be on B2C or B2B.
              </li>
              <li>
                Markup can be two types Markup or Discount basis, which can be
                specified in the amount as % or Value. This amount can be placed
                on Total fare or Base fare (in case of Flights).
              </li>
              <li>
                Markup can be set between range of amounts in form of Minimum or
                Maximum amount where the markup will applied to only components
                showing price between these min and max ranges.
              </li>
              <li>
                Markup also can be setup on specific Supplier / Agency / Api
                User.
              </li>
              <li>
                Journey types / Class / Stop Overs / Api users (incase of flight
                only).
              </li>
              <li>
                Options provided to select various passenger type no of
                passengers.
              </li>
            </ul>
            <Typography
              className="font-weight-bold mb-2 text-dark"
              variant="h5"
            >
              View Markup Rule
            </Typography>
            <ul className="pl-5">
              <li>
                This option provides to view different markups set, also option
                to edit or delete the markup
              </li>
              <li>
                This option also provides to de activate or activate a specific
                markup.
              </li>
              <li>
                If you are making a change to an existing rule and wanting to
                try it out, please note the cache time and give it at last 3-5
                minutes before testing and start a new search.
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} className="pt-5">
          <AdvancedSettings formik={formFields} />
        </Grid>
        <Grid className="pt-5" item xs={12}>
          <Paper className="p-7 d-flex justify-content-center">
            <Button variant="secondary" type="button">
              Cancel
            </Button>
            <Button
              // onClick={formFields.handleSubmit}
              className="ml-7 "
              type="submit"
              loading={loading}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
