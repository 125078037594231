import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Modal,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";

import { Button, GreenCheckbox, Input } from "../common";
import { toAbsoluteUrl } from "../../../_helpers";
import { actions } from "../../../../redux/state/ticketSummary/";
import { actions as bookingMngActions } from "../../../../redux/state/bookingMng";
import { actions as invoicesActions } from "../../../../redux/state/invoices";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { useHistory, useParams } from "react-router";
import { parseRoute } from "../../../../app/helpers/formatters";
import {
  ISSUE_BOOKING,
  TICKET_DETAILS_REFUND,
  TICKET_DETAILS_REISSUE,
  TICKET_DETAILS_VOID,
} from "../../../../app/routesMap";
import {
  BookingCancelled,
  BookingHold,
  BookingIssue,
  BookingRefund,
} from "../../../../redux/state/booking/bookingConstants";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { OrderSummary } from "../admin/bookingMng/issuedOrderDetails/orderSummary/OrderSummary";
import { ConfirmationModal } from "../bookingManagement/common/ConfirmationModal";
import { CancelModal } from "./CancelModal";
import { adminPermissionsIds } from "../../../../data/adminPermissionIds";

export const TicketDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { data, loading: getDataLoading } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};
  const { data: invoice, loading: invoiceLoading } = useSelector(
    ({ invoices }) => invoices.specificInvoice
  );

  const { orderNo } = data || {};

  useEffect(() => {
    dispatch(bookingMngActions.getSpecificOrderByBookingId({ bookingId: id }));
    return () => {
      dispatch(bookingMngActions.reset());
    };
  }, [dispatch, id]);
  useEffect(() => {
    if (orderNo)
      dispatch(invoicesActions.getInvoiceByInvoiceNo({ invoiceNo: orderNo }));
  }, [orderNo, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OrderDetailsHeader />
      </Grid>
      <Grid item xs={12}>
        <OrderSummary
          data={data}
          invoice={invoice}
          loading={getDataLoading || invoiceLoading}
        />
      </Grid>
    </Grid>
  );
};

export const OrderDetailsHeader = ({ subPermissions }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [moveTo, setMoveTo] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { sendETicket, sendInvoice } = useSelector(
    ({ ticketSummary }) => ticketSummary
  );
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};
  const {
    success: updateBookingStatusSuccess,
    loading: updateBookingStatusLoading,
    failure: updateBookingStatusFailure,
  } = useSelector(({ bookingMng }) => bookingMng.updateBookingStatus);
  const {
    id,
    bookingId,
    flight,
    bookingStatus,
    isVoidable,
    // isRefundable,
    isReissueable,
    isManual,
    guid,
    flightId,
    ltd,
  } = data || {};
  const personalSchema = Yup.object().shape({
    to: Yup.string().required("Field is required"),
    cc: Yup.string(),
    bcc: Yup.string(),
    remarks: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      to: "",
      cc: "",
      bcc: "",
      remarks: "",
      isInvoice: false,
      isETicket: true,
      isAtol: false,
    },
    validationSchema: personalSchema,
    onSubmit: ({ to, cc, bcc, remarks, isInvoice, isETicket }) => {
      if (isETicket) {
        dispatch(
          actions.sendETicketEmail({
            companyId: companyInUserId,
            transactionId: flight.transactionId,
            to,
            cc,
            bcc,
            remarks,
          })
        );
      }
      if (isInvoice) {
        dispatch(
          actions.sendInvoiceEmail({
            companyId: companyInUserId,
            transactionId: flight.transactionId,
            to,
            cc,
            bcc,
            remarks,
          })
        );
      }
    },
  });
  const { isETicket, isInvoice } = formik.values;
  const downloadInvoice = (id) => {
    setDownloadLoading(true);
    return fetch(`${process.env.REACT_APP_BOOKING_API_URL}/invoice/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setDownloadLoading(false);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `E-invoice-${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  const downloadETicket = (id) => {
    setDownloadLoading(true);
    return fetch(`${process.env.REACT_APP_BOOKING_API_URL}/ticket/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setDownloadLoading(false);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `E-Ticket-${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  useEffect(() => {
    let success = false;
    if (isETicket && isInvoice) {
      success = sendInvoice.success && sendETicket.success;
    } else if (isInvoice) {
      success = sendInvoice.success;
    } else if (isETicket) {
      success = sendETicket.success;
    }
    if (success) {
      setOpenModal(false);
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Email sent Successfully",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendETicket, sendInvoice]);

  const downloadOptions = [
    {
      label: "Download E-Ticket",
      onClick: () => downloadETicket(id),
      // dispatch(actions.downloadETicket({ id })),
    },
    {
      label: "Download Invoice",
      onClick: () => downloadInvoice(id),
      hidden: bookingStatus === BookingHold,
      // dispatch(
      //   actions.downloadInvoice({
      //     companyId: companyInUserId,
      //     transactionId: flight.transactionId,
      //   })
      // ),
    },
  ];
  const manageOptions = [
    {
      label: "Issue (Pay now)",
      onClick: () => history.push(parseRoute(ISSUE_BOOKING, { id: bookingId })),
      hidden: !(bookingStatus === BookingHold),
    },
    {
      label: "Void",
      onClick: () => history.push(parseRoute(TICKET_DETAILS_VOID, { id })),
      hidden: !(isVoidable && bookingStatus === BookingIssue),
    },
    {
      label: "Reissue",
      onClick: () => history.push(parseRoute(TICKET_DETAILS_REISSUE, { id })),
      hidden: !isReissueable || bookingStatus === BookingHold,
    },
    {
      label: "Refund",
      onClick: () => history.push(parseRoute(TICKET_DETAILS_REFUND, { id })),
      hidden:
        // !isRefundable ||
        bookingStatus === BookingHold,
    },
  ];

  const manageOptionsAdmin = [
    {
      label: "Issue (Pay now)",
      onClick: () => history.push(parseRoute(ISSUE_BOOKING, { id: bookingId })),
      hidden: !(bookingStatus === BookingHold && isManual),
    },
    {
      label: "Cancel (No Refund)",
      onClick: () => setOpenCancelModal(true),
      hidden: !(bookingStatus === BookingIssue),
    },
  ];

  const moveToOptions = [
    {
      label: "Cancelled",
      onClick: () => {
        setMoveTo(BookingCancelled);
        setOpenConfirmationModal(true);
      },
      hidden: bookingStatus === BookingCancelled,
    },
    {
      label: "Hold",
      onClick: () => {
        setMoveTo(BookingHold);
        setOpenConfirmationModal(true);
      },
      hidden: bookingStatus === BookingHold,
    },
  ];

  const onFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(bookingMngActions.clearUpdateBookingStatus());
  };
  const onSuccess = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: "Status Updated!",
      })
    );
    dispatch(bookingMngActions.getSpecificOrderByBookingId({ bookingId }));
  };

  const changeBookingStatus = () => {
    dispatch(bookingMngActions.updateBookingStatus({ id, status: moveTo }));
  };

  const hideConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setMoveTo(undefined);
    dispatch(bookingMngActions.clearUpdateBookingStatus());
  };

  const { handleSubmit, setFieldValue, values } = formik;
  const allTrackingSubPermission =
    adminPermissionsIds.bookingManagement.ticketTracking.subPermissions;
  let ltdDate = new Date(ltd?.includes("Z") ? ltd : `${ltd}Z`);
  return (
    <Paper
      className={clsx("p-7 d-flex justify-content-between align-items-center", {
        "flex-column": !matchesSm,
      })}
    >
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="bg-white rounded"
          style={{ maxWidth: "600px", flexGrow: 1, overflow: "hidden" }}
        >
          <div className="bg-primary p-7">
            <Typography variant="h5" className="font-weight-bolder text-white">
              Mailing Process
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit}
            className="py-7 px-20"
            style={{ color: "#464E5F" }}
          >
            <Input
              spacing
              showErrors={formik.touched.to}
              errorMessage={formik.errors.to}
              {...formik.getFieldProps("to")}
              name="to"
              fixedLabelWidthSmall
              horizontal
              label="To"
              placeholder="Type here"
              required
            />
            <Input
              spacing
              showErrors={formik.touched.cc}
              errorMessage={formik.errors.cc}
              {...formik.getFieldProps("cc")}
              name="cc"
              fixedLabelWidthSmall
              horizontal
              label="CC"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={formik.touched.bcc}
              errorMessage={formik.errors.bcc}
              {...formik.getFieldProps("bcc")}
              name="bcc"
              fixedLabelWidthSmall
              horizontal
              label="Bcc"
              placeholder="Type here"
            />
            <Input
              spacing
              showErrors={formik.touched.remarks}
              errorMessage={formik.errors.remarks}
              {...formik.getFieldProps("remarks")}
              name="remarks"
              fixedLabelWidthSmall
              horizontal
              label="Remark"
              placeholder="Type here"
            />
            <div style={{ marginLeft: "75px" }} className="d-flex">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={values.isInvoice}
                    onClick={() =>
                      setFieldValue("isInvoice", !values.isInvoice)
                    }
                  />
                }
                label={
                  <Typography className="font-weight-bold ">Invoice</Typography>
                }
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={values.isETicket}
                    onClick={() =>
                      setFieldValue("isETicket", !values.isETicket)
                    }
                  />
                }
                label={
                  <Typography className="font-weight-bold ">
                    E-ticket
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={values.isAtol}
                    onClick={() => setFieldValue("isAtol", !values.isAtol)}
                  />
                }
                label={
                  <Typography className="font-weight-bold ">
                    Atol certificatetion
                  </Typography>
                }
              />
            </div>
            <div className="d-flex justify-content-center mt-7 mb-3">
              <Button
                onClick={() => setOpenModal(false)}
                // className="btn bg-gray-200 w-100px"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                loading={sendETicket.loading || sendInvoice.loading}
                type="submit"
                className="ml-7"
              >
                Send
              </Button>
            </div>
          </form>
        </Paper>
      </Modal>
      <CancelModal
        show={openCancelModal}
        onHide={() => setOpenCancelModal(false)}
        bookingId={bookingId}
        id={id}
      />
      <ConfirmationModal
        show={openConfirmationModal}
        onHide={hideConfirmationModal}
        onConfirm={changeBookingStatus}
        title="Status Change"
        loading={updateBookingStatusLoading}
        success={updateBookingStatusSuccess}
        failure={updateBookingStatusFailure}
        onFailure={onFailure}
        onSuccess={onSuccess}
      >
        Are you Sure you want to change status to <b>{moveTo}</b>?
      </ConfirmationModal>
      <div
        className={clsx("d-flex flex-column text-muted", {
          "mb-3": !matchesSm,
        })}
      >
        <Typography>GUID: {guid}</Typography>
        <Typography>Flight Id: {flightId}</Typography>
      </div>
      {bookingStatus === BookingHold && (
        <div className="mx-auto d-flex flex-column justify-content-center align-items-center text-white bg-danger rounded w-120px py-2">
          <span>Cancels In</span>
          <Countdown
            className="font-weight-bold font-size-h5 mt-1"
            date={ltdDate}
          />
        </div>
      )}
      <div className="d-flex">
        {bookingStatus !== BookingHold && (
          <>
            <Button
              smallPadding
              className="d-flex align-items-center"
              variant="primary-inverse"
              onClick={() => setOpenModal(true)}
            >
              <SVG
                className="mr-2"
                src={toAbsoluteUrl("/media/svg/icons/General/message-blue.svg")}
              />
              Send
            </Button>
          </>
        )}
        <DropDownButton
          options={downloadOptions}
          className="mx-5"
          title="Download"
          loading={downloadLoading}
        />
        {bookingStatus !== BookingRefund &&
          bookingStatus !== BookingCancelled &&
          !isAdmin && (
            <DropDownButton
              options={manageOptions}
              buttonClassName="btn-danger"
              title="Manage"
            />
          )}
        {isAdmin &&
          subPermissions.includes(allTrackingSubPermission.actions.id) && (
            <>
              <DropDownButton
                options={manageOptionsAdmin}
                buttonClassName="btn-danger mr-5"
                title="Manage"
              />
              {(bookingStatus === BookingCancelled ||
                bookingStatus === BookingHold) && (
                <DropDownButton
                  options={moveToOptions}
                  title="Move To"
                  buttonClassName="btn-danger"
                />
              )}
            </>
          )}
      </div>
    </Paper>
  );
};

// export const RulesTabs = () => (
//   <Paper className="p-7">
//     <Tabs data={tabsData} />
//   </Paper>
// );

export const CardWithBlueHeader = ({
  title,
  iconSrc,
  subtitle,
  children,
  print,
}) => {
  return (
    <Paper className="h-100 overflow-hidden">
      <div className="bg-primary px-7 py-4 text-white">
        <div className="d-flex justify-content-between d-flex align-items-center">
          <div className="d-flex align-items-center">
            {!!iconSrc && (
              <span className="svg-icon mr-4">
                <SVG src={toAbsoluteUrl(iconSrc)} />
              </span>
            )}
            <Typography variant="h5" className="font-weight-bolder">
              {title}
            </Typography>
          </div>
          {print && (
            <button className="p-0 btn">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/printer.svg")}
              />
            </button>
          )}
        </div>
        <Typography variant="body1">{subtitle}</Typography>
      </div>
      <div>{children}</div>
    </Paper>
  );
};

export const DropDownButton = ({
  title,
  buttonClassName,
  className,
  icon,
  options,
  loading,
}) => {
  const isRender = !!options?.filter(({ hidden }) => !hidden).length;
  return isRender ? (
    <div className={clsx("card-toolbar", className)}>
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className={clsx(
            "btn btn-primary btn-sm font-weight-bolder dropdown-toggle h-40px",
            buttonClassName
          )}
          disabled={loading}
        >
          {icon && <SVG className="mr-2" src={icon} />}
          {!loading && title}
          {loading && <span className="ml-3 mr-7 spinner spinner-white"></span>}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          {options?.map(({ label, onClick, disabled, hidden }, index) => (
            <Dropdown.Item
              disabled={disabled}
              hidden={hidden}
              onClick={onClick}
              key={`item-${index}`}
            >
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    <></>
  );
};
