import React from "react";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";

export const ContactInfo = ({ agencyName, leadGuest }) => {
  return (
    <CardWithBlueHeader
      title="Contact Information"
      iconSrc="/media/svg/icons/General/message.svg"
    >
      <div
        style={{ background: "rgba(159, 219, 255, 0.16)" }}
        className="table-responsive"
      >
        <table className=" font-weight-bold table table-vertical-center mb-0">
          <thead>
            <tr>
              <th>Company</th>
              <th>Contact Name</th>
              <th>Email Address</th>
              {/* <th>country code</th> */}
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "unset" }} className="font-weight-bold">
              <td>
                <span className="text-primary">{agencyName}</span>
              </td>
              <td>
                <span>{`${leadGuest?.firstName} ${leadGuest?.lastName}`} </span>
              </td>
              <td>
                <span className="text-primary">{leadGuest?.email}</span>
              </td>
              {/* <td>
                    <span>+44</span>
                  </td> */}
              <td>
                <span>{leadGuest?.contactNo}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CardWithBlueHeader>
  );
};