import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";
import useAgenciesOptions from "../../../../../../../app/helpers/hooks/useAgenciesOptions";
import { toAbsoluteUrl } from "../../../../../../_helpers";

import { Button, Input, Select } from "../../../../common";
import { DateRangeModal } from "./DateRangeModal";
import {
  filterOptions,
  invoiceStatusMap,
  invoiceTypeMap,
} from "../../../../../../../redux/state/invoices/invoicesConstants";
import useUsersOptions from "../../../../../../../app/helpers/hooks/useUsersOptions";
import useSuppliersOptions from "../../../../../../../app/helpers/hooks/useSuppliersOptions";
import { useSelector } from "react-redux";

export const getFilterObject = (columnName, value, option) => [
  ...(value
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

const getInvoiceTypeOptions = (haveIssued) => [
  ...(haveIssued ? [{ label: "Issued", value: invoiceTypeMap.issued }] : []),
  { label: "Void", value: invoiceTypeMap.void },
  { label: "Refund", value: invoiceTypeMap.refund },
  { label: "ReIssued", value: invoiceTypeMap.reissue },
];

const invoiceStatusOptions = [
  { label: "Paid", value: invoiceStatusMap.paid },
  { label: "Unpaid", value: invoiceStatusMap.unpaid },
];

export const ReportsSearchCard = ({
  isInvoicing,
  isFees,
  setDateRange,
  onSearch,
  onReset,
  dateFilterKey = "createdAt",
}) => {
  const [showModal, setShowModal] = useState(null);
  const { agencyOptions } = useAgenciesOptions();
  const { usersOptions } = useUsersOptions();
  const { supplierOptions } = useSuppliersOptions();
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      bookingId: "",
      invoiceNo: "",
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      status: "",
      invoiceType: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      bookingId: Yup.string(),
      invoiceNo: Yup.string(),
      agency: Yup.string(),
      supplier: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      user: Yup.string(),
      status: Yup.string(),
      invoiceType: Yup.string(),
    }),
    onSubmit: ({
      fromDate,
      user,
      toDate,
      supplier,
      bookingId,
      invoiceNo,
      agency,
      status,
      invoiceType,
    }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject("bookingId", bookingId, filterOptions.Contains),
        ...getFilterObject("invoiceNo", invoiceNo, filterOptions.Contains),
        ...getFilterObject("createdBy", user, filterOptions.IsEqualTo),
        ...getFilterObject("agencyId", agency, filterOptions.IsEqualTo),
        ...getFilterObject("supplierId", supplier, filterOptions.IsEqualTo),
        ...getFilterObject("status", status, filterOptions.IsEqualTo),
        ...getFilterObject("invoiceType", invoiceType, filterOptions.IsEqualTo),
        ...getFilterObject(
          dateFilterKey,
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          dateFilterKey,
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    values,
    setValues,
    touched,
    errors,
    getFieldProps,
  } = fields;
  const handleReset = () => {
    setValues({
      bookingId: "",
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      status: "",
      invoiceType: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };
  // "filterParam": [
  //   {
  //     "columnName": "createdAt",
  //     "filterValue": "2022-01-06",
  //     "filterOption": 8
  //   },
  //    {
  //     "columnName": "agencyId",
  //     "filterValue": "13",
  //     "filterOption": 11
  //   }
  // ]
  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid justify="center" container spacing={2}>
        {/* <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.bookingId}
            errorMessage={errors.bookingId}
            {...getFieldProps("bookingId")}
            placeholder="Invoice To"
          />
        </Grid> */}
        <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.bookingId}
            errorMessage={errors.bookingId}
            {...getFieldProps("bookingId")}
            placeholder="Booking Id"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.invoiceNo}
            errorMessage={errors.invoiceNo}
            {...getFieldProps("invoiceNo")}
            placeholder="Invoice No"
          />
        </Grid>
        {isFees && (
          <Grid item xs={6} md={2}>
            <Select
              value={values.invoiceType}
              onChange={(v) => setFieldValue("invoiceType", v)}
              placeholder="All Types"
              options={[
                { value: "", label: "All Types" },
                ...(getInvoiceTypeOptions() || []),
              ]}
            />
          </Grid>
        )}
        {isInvoicing && (
          <>
            <Grid item xs={6} md={2}>
              <Select
                value={values.status}
                onChange={(v) => setFieldValue("status", v)}
                placeholder="All Status"
                options={[
                  { value: "", label: "All Status" },
                  ...(invoiceStatusOptions || []),
                ]}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Select
                value={values.invoiceType}
                onChange={(v) => setFieldValue("invoiceType", v)}
                placeholder="All Types"
                options={[
                  { value: "", label: "All Types" },
                  ...(getInvoiceTypeOptions() || []),
                ]}
              />
            </Grid>
          </>
        )}
        {isAdmin && (
          <>
            <Grid item xs={6} md={2}>
              <Select
                value={values.supplier}
                onChange={(v) => setFieldValue("supplier", v)}
                placeholder="All Suppliers"
                options={[
                  { value: "", label: "All Suppliers" },
                  ...(supplierOptions || []),
                ]}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Select
                value={values.user}
                onChange={(v) => setFieldValue("user", v)}
                options={[
                  { value: "", label: "All Users" },
                  ...(usersOptions || []),
                ]}
                placeholder="User"
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Select
                value={values.agency}
                onChange={(v) => setFieldValue("agency", v)}
                options={[
                  { value: "", label: "All Agencies" },
                  ...(agencyOptions || []),
                ]}
                placeholder="Agency"
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} md={2}>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs>
          <div className="d-flex justify-content-center">
            <Button type="submit">Search</Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
