import { Paper, Typography, Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";

import { actions } from "../../../../../../redux/state/package";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { ADMIN_PACKAGES_INVENTORY } from "../../../../../../app/routesMapAdmin";
import { GoBack } from "../../../common";
import { PackageDetails } from "./PackageDetails";
import { PackagePricing } from "./PackagePricing";
import { PackageInclusions } from "./PackageInclusions";
import { PackageDescription } from "./PackageDescription";
import { packageStatusMap } from "../../../../../../redux/state/package/packageConstants";

export const inventoryTabsData = [
  {
    title: "Package Details",
    subtitle: "Enter Package Details",
    key: "details",
  },
  {
    title: "Rooms and Pricing",
    subtitle: "Configure Pricing and Rooms",
    key: "pricing",
  },
  {
    title: "Inclusions",
    subtitle: "Add Package Inclusions",
    key: "inclusions",
  },
  {
    title: "Description",
    subtitle: "Add Package Description",
    key: "description",
  },
];

const getFlightParams = ({ values, userId }) => {
  const { details, pricing: priceDetails, inclusions, description } = values;
  const {
    packageName,
    packageTYpe,
    packageSeason,
    fromCity,
    destination,
    departDate,
    returnDate,
    quantity,
    duration,
  } = details;
  const { pricing, roomFees } = priceDetails;

  return {
    id: 0,
    packageName,
    duration,
    departureDate: departDate,
    returnDate: returnDate,
    destination: `${destination}`,
    qty: +quantity,
    availableQty: +quantity,
    thumbnail: "",
    status: packageStatusMap.active,
    fromCity: +fromCity,
    // packageClassId: 0,
    packageSeasonId: +packageSeason,
    packageTypeId: +packageTYpe,
    createdBy: +userId,
    updatedBy: +userId,
    packageDescription: {
      description: description.description,
    },
    packageInclusions: Object.keys(inclusions)
      .filter((key) => inclusions[key])
      .map((key) => ({
        inclusionId: +key,
      })),
    packagePrices: Object.keys(pricing).map((key) => ({
      price: +pricing[key],
      packageClassId: +key,
    })),
    packageRooms: roomFees.map(({ roomType, fee }) => ({
      roomTypeId: +roomType,
      price: +fee,
    })),
  };
};

const dummyData = {
  details: {
    packageName: "Umrah test 1",
    packageTYpe: 2,
    packageSeason: 2,
    fromCity: 1,
    destination: 3,
    departDate: "2022-10-19T01:03",
    returnDate: "2023-01-04T01:03",
    quantity: "20",
    bookingClasses: [3, 2],
    duration: "15",
  },
  pricing: {
    pricing: {
      "2": "500",
      "3": "750",
    },
    roomFees: [
      {
        roomType: 3,
        fee: "0",
      },
      {
        roomType: 2,
        fee: "50",
      },
    ],
  },
  inclusions: {
    "2": true,
    "3": true,
  },
  description: {
    description: "<p>description</p>",
  },
};

export const InventoryForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(1);
  const [data, setData] = useState(
    // dummyData
    {
      details: undefined,
      pricing: undefined,
      inclusions: undefined,
      description: undefined,
    }
  );
  // const { flightFormValues } = useSelector(({ inventory }) => inventory);
  const { success, failure } = useSelector(
    ({ packages }) => packages.createPackage
  );
  const { userId } = useSelector(({ auth }) => auth);

  const onSubmitGeneral = (values) => {
    const currStepKey = inventoryTabsData[selectedTab].key;
    setData((prev) => ({ ...prev, [currStepKey]: values }));
    if (selectedTab === inventoryTabsData.length - 1) {
      const newValues = { ...data, [currStepKey]: values };
      const params = getFlightParams({ values: newValues, userId });
      dispatch(actions.createPackage(params));
    } else {
      setSelectedTab((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Package Created!",
        })
      );
      history.replace(ADMIN_PACKAGES_INVENTORY);
      // setSelectedTab((prev) => prev + 5);
      dispatch(actions.reset());
    }
  }, [success, dispatch, history]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreatePackage());
    }
  }, [dispatch, failure]);
  const handleActivate = () => {
    history.replace(parseRoute(ADMIN_PACKAGES_INVENTORY));
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <GoBack title="Create Package" />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="px-8 py-12 d-flex min-h-100">
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Box>
                {inventoryTabsData.map(({ title, subtitle }, index) => (
                  <button
                    className={clsx(
                      "btn p-3 d-flex align-items-center text-left w-100 rounded",
                      {
                        "bg-gray-100 ": index === selectedTab,
                      }
                    )}
                    key={`tab_${index}`}
                  >
                    <Box
                      className={clsx(
                        "w-40px h-40px d-flex align-items-center justify-content-center rounded-circle font-weight-bolder mr-4",
                        {
                          "bg-primary text-white font-weight-bold ":
                            index === selectedTab,
                          "bg-light-success text-white": index < selectedTab,
                          "bg-gray-300 text-dark": index > selectedTab,
                        }
                      )}
                    >
                      {index < selectedTab ? (
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/check-stroke.svg"
                          )}
                        />
                      ) : (
                        index + 1
                      )}
                    </Box>
                    <Box>
                      <Typography variant="h6" className="font-weight-bolder">
                        {title}
                      </Typography>
                      <Typography>{subtitle}</Typography>
                    </Box>
                  </button>
                ))}
              </Box>
            </Grid>
            <Grid item md={9} className="d-flex justify-content-center">
              <Box className="d-flex flex-column justify-content-between flex-grow-1 max-w-700px">
                {selectedTab === 0 && (
                  <PackageDetails
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    data={data.details}
                    onSubmit={onSubmitGeneral}
                  />
                )}
                {selectedTab === 1 && (
                  <PackagePricing
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    data={data.pricing}
                    onSubmit={onSubmitGeneral}
                    selectedClasses={data.details?.bookingClasses}
                  />
                )}
                {selectedTab === 2 && (
                  <PackageInclusions
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    data={data.inclusions}
                    onSubmit={onSubmitGeneral}
                  />
                )}
                {selectedTab === 3 && (
                  <PackageDescription
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    data={data.description}
                    onSubmit={onSubmitGeneral}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
