import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { Button, Select } from "../../../common";
import { SalesDashboardTable } from "./SalesDashboardTable";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { useHistory } from "react-router-dom";

import {
  getCurrWeekRange,
  getDateTime,
  toAbsoluteUrl,
} from "../../../../../_helpers";
import { SetTargetModal } from "./SetTargetModal";
import useAgenciesOptions from "../../../../../../app/helpers/hooks/useAgenciesOptions";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accounting";
import { getFilterObject } from "../adminBalanceSummary/BalanceSummarySearchCard";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { ADMIN_ACCOUNTING_SALES_GRAPH } from "../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../app/helpers/formatters";

export const SalesDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { agencyOptions } = useAgenciesOptions();
  const [openSetModal, setOpenSetModal] = useState(false);
  const [data, setData] = useState({});
  const [selectedClient, setSelectedClient] = useState(0);
  const [connected, setConnected] = useState(false);

  const { weeklyList, countBooking } = data;
  const { firstDay, lastDay } = getCurrWeekRange();
  const { data: weaklyTargetData } = useSelector(
    ({ accounting }) => accounting.weaklyTarget
  );
  const { success: setWeeklyTargetSuccess } = useSelector(
    ({ accounting }) => accounting.setWeaklyTarget
  );
  const { success: editWeeklyTargetSuccess } = useSelector(
    ({ accounting }) => accounting.editWeaklyTarget
  );
  // console.log({ firstDay, lastDay });
  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BOOKING_API_URL.replace(
            "https",
            "wss"
          )}/flyme-hub`,
          {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          }
        )
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build(),
    []
  );
  const start = useCallback(async () => {
    try {
      connection.on("WeeklyTargetList", (data) => {
        setData(data.data);
      });
      connection.on("GetWeeklyTargetList", async () => {
        invokeWeaklyTargetList()
      });
      connection
      .start()
      .then(() => {
        setConnected(true);
      })
      .catch((e) => {
        console.log(e);
      });
    } catch (err) {
      console.error(err);
      setData([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const invokeWeaklyTargetList = useCallback(() => {
    connection.invoke(
      "WeeklyTargetList",
      firstDay,
      lastDay,
      selectedClient
    )
  }, [connection, firstDay, lastDay, selectedClient]);

  useEffect(() => {
    if (!connected) return;
    invokeWeaklyTargetList();
  }, [connected, invokeWeaklyTargetList]);
  useEffect(() => {
    start();
    return () => connection.stop();
  }, [connection, start]);
  useEffect(() => {
    dispatch(
      actions.filterWeeklyTargets({
        filterParam: [
          ...getFilterObject("from", `${firstDay}`, filterOptions.IsEqualTo),
          ...getFilterObject("to", `${lastDay}`, filterOptions.IsEqualTo),
        ],
      })
    );
  }, [
    dispatch,
    firstDay,
    lastDay,
    setWeeklyTargetSuccess,
    editWeeklyTargetSuccess,
  ]);
  const { date: fromDate } = getDateTime(firstDay);
  const { date: toDate } = getDateTime(lastDay);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-center">
          <Button variant="primary-inverse" className="mr-3 font-size-h6">
            TARGET: {weaklyTargetData?.target ?? "Unset"}
          </Button>
          <Button variant="danger" className="font-size-h6">
            CURRENT: {countBooking?.totalTicket}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7 ">
          <SetTargetModal
            onHide={() => setOpenSetModal(false)}
            show={openSetModal}
            data={weaklyTargetData}
          />
          <Grid container spacing={3} className="mb-3 justify-content-between">
            <Grid item xs={8} md={4}>
              <Select
                value={selectedClient}
                onChange={(v) => setSelectedClient(v)}
                options={[
                  { value: 0, label: "All Clients" },
                  ...(agencyOptions || []),
                ]}
                placeholder="Client"
              />
            </Grid>
            {/* <Grid item xs={12} md={4}></Grid> */}
            <Grid item xs>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary-inverse"
                  className="font-weight-bolder mr-3"
                  smallPadding
                  title="Statistics"
                  onClick={() =>
                    history.push(
                      parseRoute(ADMIN_ACCOUNTING_SALES_GRAPH, {
                        id: 0,
                      })
                    )
                  }
                >
                  <span className="svg-icon svg-icon-primary">
                    <SVG
                      title="Statistics"
                      src={toAbsoluteUrl("/media/svg/icons/General/Graph.svg")}
                    />
                  </span>
                </Button>
                <Button
                  variant="primary-inverse"
                  className="font-weight-bolder mr-3"
                  smallPadding
                  title="Export"
                >
                  <span className="svg-icon svg-icon-primary">
                    <SVG
                      title="Export"
                      src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")}
                    />
                  </span>
                  Export
                </Button>
                <Button onClick={() => setOpenSetModal(true)}>
                  {weaklyTargetData ? "Edit" : "Set"} weekly target
                </Button>
              </div>
            </Grid>
          </Grid>
          <Typography>
            Date: <b>{fromDate}</b> to: <b>{toDate}</b>
          </Typography>

          {/* <Grid item xs>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid> */}

          <SalesDashboardTable list={weeklyList} />
        </Paper>
      </Grid>
    </Grid>
  );
};
