import { useState } from "react";

export const exportAccountingTypesMap = {
  invoices: "Invoices",
  bills: "Bills",
  bankTransactionHistory: "BankTransactionHistory",
  credit: "CompanyAddCredit",
};

export default function useExportAccounting() {
  const [loading, setLoading] = useState(false);
  const exportToExcel = ({ sortingParams, filterParam, type }) => {
    setLoading(true);
    return fetch(
      `${process.env.REACT_APP_ACCOUNTING_API_URL}/accounting/v1/ExportToExcel`,
      {
        method: "POST",
        body: JSON.stringify({
          sortingParams: sortingParams || [
            {
              sortOrder: 1,
              columnName: "CreatedAt",
            },
          ],
          filterParam: filterParam || [{}],
          type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        setLoading(false);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${type}.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  return { exportToExcel, loading };
}
