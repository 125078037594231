import { useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DesktopHeader } from "./DesktopHeader";
import { LoginModal } from "./LoginModal";
import { MobileHeader } from "./MobileHeader";
import styles from "./Public.module.scss";

export const PublicHeader = () => {
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const onSignInClick = () => {
    setOpenModal(true);
  };
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const { isAuthorized } = useSelector(({ auth }) => ({
    isAuthorized: !!auth.token,
  }));

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const cls = visible ? "headerVisible" : "headerHidden";
  return (
    <div className={clsx(styles.header, styles[cls])}>
      {matchesMd ? (
        <DesktopHeader
          isAuthorized={isAuthorized}
          onSignInClick={onSignInClick}
        />
      ) : (
        <MobileHeader isAuthorized={isAuthorized} />
      )}
      <LoginModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};
