import { Grid, Paper, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useExportUser, {
  exportUserTypesMap,
} from "../../../../../../app/helpers/hooks/useExportUser";
import { PAYMENT_TYPE_TOPUP } from "../../../../../../redux/state/accountBalance/accountBalanceConstants";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { actions } from "../../../../../../redux/state/payment";
import { Button, Seperator } from "../../../common";
import { TopupsReportSearchCard } from "./TopupsReportSearchCard";
import { TopupsReportTable } from "./TopupsReportTable";
import { TopupsSummary } from "./TopupsSummary";

export const TopupsReport = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { exportToExcel, loading: downloadLoading } = useExportUser();
  const { paginationInfo, stats } = useSelector(
    ({ payment }) => payment.topUps
  );
  const { totalPages } = paginationInfo ?? {};
  const { fromDate, toDate } = dateRange;
  useEffect(() => {
    dispatch(
      actions.filterAllTopUps({
        filterParam: [
          {
            columnName: "status",
            filterValue: "Approved",
            filterOption: filterOptions.IsEqualTo,
          },
          {
            columnName: "topupStatus",
            filterValue: PAYMENT_TYPE_TOPUP,
            filterOption: filterOptions.IsEqualTo,
          },
          ...appliedFilters,
        ],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterAllTopUps({
        filterParam: [
          {
            columnName: "status",
            filterValue: "Approved",
            filterOption: filterOptions.IsEqualTo,
          },
          {
            columnName: "topupStatus",
            filterValue: PAYMENT_TYPE_TOPUP,
            filterOption: filterOptions.IsEqualTo,
          },
          ...filters,
        ],
        pageNumber: 1,
      })
    );
  };
  const onExport = () => {
    exportToExcel({
      type: exportUserTypesMap.topUp,
      filterParam: [
        {
          columnName: "status",
          filterValue: "Approved",
          filterOption: filterOptions.IsEqualTo,
        },
        {
          columnName: "topupStatus",
          filterValue: PAYMENT_TYPE_TOPUP,
          filterOption: filterOptions.IsEqualTo,
        },
        ...appliedFilters,
      ],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(
        actions.filterAllTopUps({
          filterParam: [
            {
              columnName: "status",
              filterValue: "Approved",
              filterOption: filterOptions.IsEqualTo,
            },
            {
              columnName: "topupStatus",
              filterValue: PAYMENT_TYPE_TOPUP,
              filterOption: filterOptions.IsEqualTo,
            },
          ],
          pageNumber: 1,
        })
      );
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <TopupsReportSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Typography variant="h6" className="font-weight-bold">
                Top up Report
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <Button
              onClick={onExport}
              loading={downloadLoading}
              variant="danger"
            >
              Export
            </Button>
          </div>

          <Seperator />
          <TopupsReportTable />
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
          <div className="m-5" />
          <TopupsSummary stats={stats} />
        </Paper>
      </Grid>
    </Grid>
  );
};
