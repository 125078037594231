import { client } from "./client";

export const createCompany = (params) =>
  client.post("/api/public/company", {
    ...params,
  });

export const deleteCompany = ({ id }) => client.delete(`/api/company/${id}`);

export const getCompany = ({ id }) => client.get(`/api/company/${id}`);

export const getAllCompanies = () => client.get("/api/company");

export const filterAllCompanies = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post("/api/company/filters", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [],
    pageNumber,
    pageSize,
  });

export const getCompanyUsers = ({ id }) =>
  client.get(`/api/company/${id}/user`);

export const getCompanySessions = ({ id }) =>
  client.get(`/api/company/${id}/sessions`);

export const editCompany = ({ id, ...params }) =>
  client.put(`/api/company/${id}`, {
    id,
    ...params,
  });

export const blockCompany = ({ id }) => client.put(`/api/company/${id}/block`);

export const unblockCompany = ({ id }) =>
  client.put(`/api/company/${id}/unblock`);
