import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import {
  AdminBillingHistory,
  Invoicing,
  PayableDue,
  ReceivableDue,
  SupplierRefund,
} from "../_metronic/layout/components/admin/accounting";
import { AccountTypes } from "../_metronic/layout/components/admin/accounting/accountTypes/AccountTypes";
import { ChartOfAccounts } from "../_metronic/layout/components/admin/accounting/chartOfAccounts/ChartOfAccounts";
import { Currencies } from "../_metronic/layout/components/admin/accounting/currencies/Currencies";
import { Queue } from "../_metronic/layout/components/admin/bookingMng/queue/Queue";
import { AllSubUsers } from "../_metronic/layout/components/admin/client/allSubusers/AllSubUsers";
import { ViewSessions } from "../_metronic/layout/components/admin/client/clientDetails/clientSecutrity/ViewSessions";
import {
  Cities,
  Inclusions,
  PackageClasses,
  PackageRoomTypes,
  PackageSeasons,
  PackageTypes,
} from "../_metronic/layout/components/admin/packageInventory";
import { AdminCredit } from "../_metronic/layout/components/admin/payments/adminCredit/AdminCredit";
import { Banks } from "../_metronic/layout/components/admin/payments/banks/Banks";
import { PaymentMethods } from "../_metronic/layout/components/admin/payments/paymentMethods/PaymentMethods";
import { BankReport } from "../_metronic/layout/components/admin/reports/Bank Report/BankReport";
import { TopupsReport } from "../_metronic/layout/components/admin/reports/TopupsReport/TopupsReport";
import { BalanceDueReport } from "../_metronic/layout/components/admin/reports/balanceDueReport/BalanceDueReport";
import { CancelledReport } from "../_metronic/layout/components/admin/reports/cancelledReport/CancelledReport";
import { FeesReport } from "../_metronic/layout/components/admin/reports/feesReport/FeesReport";
import { MarginReport } from "../_metronic/layout/components/admin/reports/marginReport/MarginReport";
import { SalesReport } from "../_metronic/layout/components/admin/reports/salesReport/SalesReport";
import { FlymeProfile } from "../_metronic/layout/components/admin/systemManagement/flymeProfile/FlymeProfile";
import { PromoCodes } from "../_metronic/layout/components/admin/systemManagement/promoCode/PromoCodes";
import { DashboardPage } from "./pages/DashboardPage";
import { ExpensesPage } from "./pages/accounting/ExpensesPage";
import { OperationDashboardPage } from "./pages/accounting/OperationDashboardPage";
import { SalesDashboardPage } from "./pages/accounting/SalesDashboardPage";
import { AdminNotificationPage } from "./pages/adminNotifications/AdminNotificationPage";
import {
  RefundPage,
  ReissuePage,
  SearchOrderPage,
  VoidTrackingPage,
} from "./pages/bookingManagement";
import { TrackingPage } from "./pages/bookingManagement/TrackingPage";
import { ClientsPage } from "./pages/clients/ClientsPage";
import { DistributionMarkupPage } from "./pages/distribution";
import { FlightSearchPage } from "./pages/flightSearch";
import { InventoryPage } from "./pages/packages/InventoryPage";
import { BankAccountsPage } from "./pages/payment";
import { PaymentBalancesPage } from "./pages/payment/PaymentBalancesPage";
import { ServiceFeesPage } from "./pages/payment/ServiceFeesPage";
import { TopUpsPage } from "./pages/payment/TopUpsPage";
import { StaffPage } from "./pages/staff/StaffPage";
import { StaffRolesPage } from "./pages/staffRoles/StaffRolesPage";
import { SuppliersPage } from "./pages/suppliers/SuppliersPage";
import { TemplatesPage } from "./pages/templates/TemplatesPage";
import {
  BOOKING_MANAGEMENT,
  BOOKING_MANAGEMENT_REFUND,
  BOOKING_MANAGEMENT_REISSUE,
  BOOKING_MANAGEMENT_SEARCH,
  BOOKING_MANAGEMENT_TRACKING,
  BOOKING_MANAGEMENT_VOID,
  FARE_SEARCH,
  FLIGHT_SEARCH,
  NOT_FOUND,
  PNR_SEARCH,
} from "./routesMap";
import {
  ADMIN_ACCOUNTING,
  ADMIN_ACCOUNTING_ACCOUNT_TYPES,
  ADMIN_ACCOUNTING_BALANCE_SUMMARY,
  ADMIN_ACCOUNTING_CHART_OF_ACCOUNTS,
  ADMIN_ACCOUNTING_CURRENCIES,
  ADMIN_ACCOUNTING_EXPENSES,
  ADMIN_ACCOUNTING_INVOICING,
  ADMIN_ACCOUNTING_OPERATION,
  ADMIN_ACCOUNTING_PAYABLE_DUE,
  ADMIN_ACCOUNTING_RECEIVABLE_DUE,
  ADMIN_ACCOUNTING_SALES,
  ADMIN_ACCOUNTING_SUPPLIER_REFUND,
  ADMIN_AGENCIES,
  ADMIN_ALL_SUBUSERS,
  ADMIN_BOOKING_MANAGEMENT_QUEUE,
  ADMIN_CLIENTS,
  ADMIN_DASHBOARD,
  ADMIN_FEES,
  ADMIN_FLYME_PROFILE,
  ADMIN_MARKUPS,
  ADMIN_NOTIFICATIONS,
  ADMIN_PACKAGES,
  ADMIN_PACKAGES_CITIES,
  ADMIN_PACKAGES_INCLUSIONS,
  ADMIN_PACKAGES_INVENTORY,
  ADMIN_PACKAGES_PKG_CLASSES,
  ADMIN_PACKAGES_PKG_ROOM_TYPES,
  ADMIN_PACKAGES_PKG_SEASON,
  ADMIN_PACKAGES_PKG_TYPE,
  ADMIN_PAYMENTS,
  ADMIN_PAYMENTS_BANKS,
  ADMIN_PAYMENTS_BANK_ACCOUNTS,
  ADMIN_PAYMENTS_CREDIT,
  ADMIN_PAYMENTS_PAYMENT_BALANCES,
  ADMIN_PAYMENTS_PAYMENT_METHODS,
  ADMIN_PAYMENTS_PROMO_CODES,
  ADMIN_PAYMENTS_TOP_UPS,
  ADMIN_REPORTS,
  ADMIN_REPORTS_BANK_ACCOUNTS,
  ADMIN_REPORTS_CANCELLED,
  ADMIN_REPORTS_DUE,
  ADMIN_REPORTS_FEES,
  ADMIN_REPORTS_MARGIN,
  ADMIN_REPORTS_SALES,
  ADMIN_REPORTS_TOP_UP,
  ADMIN_SESSIONS,
  ADMIN_STAFF,
  ADMIN_STAFF_ROLES,
  ADMIN_SUPPLIERS,
  ADMIN_SYSTEM_MANAGEMENT,
  ADMIN_TEMPLATES,
} from "./routesMapAdmin";

export const adminRoutesPermissionMap = {
  Dashboard: {
    mainRoute: ADMIN_DASHBOARD,
    icon: "/media/svg/icons/Design/Layers.svg",
    items: [
      {
        label: "Main",
        permissionKey: "dashboard",
        route: ADMIN_DASHBOARD,
        component: DashboardPage,
      },
    ],
  },
  "Fare Search": {
    mainRoute: FARE_SEARCH,
    icon: "/media/svg/icons/General/search.svg",
    items: [
      {
        label: "Flight Search",
        permissionKey: "fareSearch.flightSearch",
        route: FLIGHT_SEARCH,
        component: FlightSearchPage,
      },
      {
        label: "Pnr Search",
        permissionKey: "fareSearch.pnrSearch",
        route: PNR_SEARCH,
        component: FlightSearchPage,
      },
      // {
      //   label: "Umrah Search",
      //   permissionKey: "fareSearch.umrah",
      //   route: PACKAGE_SEARCH,
      //   component: PackageSearchPage,
      // },
    ],
  },
  Client: {
    mainRoute: ADMIN_CLIENTS,
    icon: "/media/svg/icons/General/person.svg",
    items: [
      {
        label: "Agencies",
        permissionKey: "clients.agencies",
        route: ADMIN_AGENCIES,
        component: ClientsPage,
      },
      {
        label: "User Management",
        permissionKey: "clients.userManagement",
        route: ADMIN_ALL_SUBUSERS,
        component: AllSubUsers,
      },
    ],
  },
  "Booking Management": {
    mainRoute: BOOKING_MANAGEMENT,
    icon: "/media/svg/icons/General/booking.svg",
    items: [
      {
        label: "Ticket Tracking",
        permissionKey: "bookingManagement.ticketTracking",
        route: BOOKING_MANAGEMENT_TRACKING,
        component: TrackingPage,
      },
      {
        label: "Refund management",
        permissionKey: "bookingManagement.refund",
        route: BOOKING_MANAGEMENT_REFUND,
        component: RefundPage,
      },
      {
        label: "Void management",
        permissionKey: "bookingManagement.void",
        route: BOOKING_MANAGEMENT_VOID,
        component: VoidTrackingPage,
      },
      {
        label: "Reissue management",
        permissionKey: "bookingManagement.reissue",
        route: BOOKING_MANAGEMENT_REISSUE,
        component: ReissuePage,
      },
      // {
      //   label: "Packages Tracking",
      //   permissionKey: "bookingManagement.packages",
      //   route: BOOKING_MANAGEMENT_PACKAGES,
      //   component: PackagesTrackingPage,
      // },
      {
        label: "Search Order",
        permissionKey: "bookingManagement.searchOrder",
        route: BOOKING_MANAGEMENT_SEARCH,
        component: SearchOrderPage,
      },
      {
        label: "Queue",
        permissionKey: "bookingManagement.queue",
        route: ADMIN_BOOKING_MANAGEMENT_QUEUE,
        component: Queue,
      },
    ],
  },
  Packages: {
    mainRoute: ADMIN_PACKAGES,
    icon: "/media/svg/icons/General/package.svg",
    items: [
      {
        label: "Inventory",
        permissionKey: "packages.inventory",
        route: ADMIN_PACKAGES_INVENTORY,
        component: InventoryPage,
      },
      {
        label: "Cities",
        permissionKey: "packages.cities",
        route: ADMIN_PACKAGES_CITIES,
        component: Cities,
      },
      {
        label: "Package Classes",
        permissionKey: "packages.packageClasses",
        route: ADMIN_PACKAGES_PKG_CLASSES,
        component: PackageClasses,
      },
      {
        label: "Package Seasons",
        permissionKey: "packages.packageSeasons",
        route: ADMIN_PACKAGES_PKG_SEASON,
        component: PackageSeasons,
      },
      {
        label: "Package Types",
        permissionKey: "packages.packageTypes",
        route: ADMIN_PACKAGES_PKG_TYPE,
        component: PackageTypes,
      },
      {
        label: "Package Room Types",
        permissionKey: "packages.packageRoomTypes",
        route: ADMIN_PACKAGES_PKG_ROOM_TYPES,
        component: PackageRoomTypes,
      },
      {
        label: "Package Inclusion",
        permissionKey: "packages.packageInclusion",
        route: ADMIN_PACKAGES_INCLUSIONS,
        component: Inclusions,
      },
    ],
  },
  Payment: {
    mainRoute: ADMIN_PAYMENTS,
    icon: "/media/svg/icons/General/payments.svg",
    items: [
      {
        label: "Top ups",
        permissionKey: "payment.topUps",
        route: ADMIN_PAYMENTS_TOP_UPS,
        component: TopUpsPage,
      },
      {
        label: "Credit",
        permissionKey: "payment.credit",
        route: ADMIN_PAYMENTS_CREDIT,
        component: AdminCredit,
      },
      {
        label: "Banks",
        permissionKey: "payment.banks",
        route: ADMIN_PAYMENTS_BANKS,
        component: Banks,
      },
      {
        label: "Bank Accounts",
        permissionKey: "payment.bankAccounts",
        route: ADMIN_PAYMENTS_BANK_ACCOUNTS,
        component: BankAccountsPage,
      },
      {
        label: "Payment Methods",
        permissionKey: "payment.paymentMethods",
        route: ADMIN_PAYMENTS_PAYMENT_METHODS,
        component: PaymentMethods,
      },
      {
        label: "Payment Balances",
        permissionKey: "payment.paymentMethodBalances",
        route: ADMIN_PAYMENTS_PAYMENT_BALANCES,
        component: PaymentBalancesPage,
      },
      {
        label: "Promo Codes",
        permissionKey: "payment.promoCodes",
        route: ADMIN_PAYMENTS_PROMO_CODES,
        component: PromoCodes,
      },
    ],
  },
  "System Management": {
    mainRoute: ADMIN_SYSTEM_MANAGEMENT,
    icon: "/media/svg/icons/Code/Settings4.svg",
    items: [
      // {
      //   label: "Pages",
      //   permissionKey:

      // 21,

      //   route: ADMIN_PAGES,
      //   component: TrackingPage,
      // },
      // {
      //   label: "Posts",
      //   permissionKey:

      // 22,

      //   route: ADMIN_POSTS,
      //   component: TrackingPage,
      // },
      {
        label: "Suppliers",
        permissionKey: "systemManagement.suppliers",
        route: ADMIN_SUPPLIERS,
        component: SuppliersPage,
      },
      {
        label: "Notifications",
        permissionKey: "systemManagement.notifications",
        route: ADMIN_NOTIFICATIONS,
        component: AdminNotificationPage,
      }, // add
      // {
      //   label: "Audits",
      //   permissionKey:
      //  'systemManagement.suppliers.'
      //  id,

      //   route: ADMIN_AUDITS,
      //   component: TrackingPage,
      // },
      {
        label: "Templates",
        permissionKey: "systemManagement.templates",
        route: ADMIN_TEMPLATES,
        component: TemplatesPage,
      },
      {
        label: "Company Profile",
        permissionKey: "systemManagement.companyProfile",
        route: ADMIN_FLYME_PROFILE,
        component: FlymeProfile,
      },
      {
        label: "Flyme Staff",
        permissionKey: "systemManagement.flymeStaff",
        route: ADMIN_STAFF,
        component: StaffPage,
      },
      {
        label: "Flyme Staff Roles",
        permissionKey: "systemManagement.flymeStaffRoles",
        route: ADMIN_STAFF_ROLES,
        component: StaffRolesPage,
      },
      {
        label: "Markup",
        permissionKey: "systemManagement.markup",
        route: ADMIN_MARKUPS,
        component: DistributionMarkupPage,
      },
      {
        label: "Service Fees",
        permissionKey: "systemManagement.serviceFees",
        route: ADMIN_FEES,
        component: ServiceFeesPage,
      },
    ],
  },
  Accounting: {
    mainRoute: ADMIN_ACCOUNTING,
    icon: "/media/svg/icons/General/calculator.svg",
    items: [
      {
        label: "Currencies",
        permissionKey: "accounting.currencies",
        route: ADMIN_ACCOUNTING_CURRENCIES,
        component: Currencies,
      },
      {
        label: "Balance Summary",
        permissionKey: "accounting.balanceSummary",
        route: ADMIN_ACCOUNTING_BALANCE_SUMMARY,
        component: AdminBillingHistory, //add
      },
      {
        label: "Receivable due/update",
        permissionKey: "accounting.receivableDue",
        route: ADMIN_ACCOUNTING_RECEIVABLE_DUE,
        component: ReceivableDue,
      },
      {
        label: "Payable due/update",
        permissionKey: "accounting.payableDue",
        route: ADMIN_ACCOUNTING_PAYABLE_DUE,
        component: PayableDue,
      },
      // {
      //   label: "Statements",
      //   permissionKey:
      // 'accounting.currencies.'
      // id,

      //   route: ADMIN_ACCOUNTING_STATEMENTS,
      //   component: Statements,
      // },
      // {
      //   label: "Cash flow",
      //   permissionKey:
      // 'accounting.currencies.'
      // id,

      //   route: ADMIN_ACCOUNTING_CASHFLOW,
      //   component: Cashflow,
      // },
      {
        label: "Expenses",
        permissionKey: "accounting.expenses",
        route: ADMIN_ACCOUNTING_EXPENSES,
        component: ExpensesPage,
      },
      {
        label: "Invoicing",
        permissionKey: "accounting.invoicing",
        route: ADMIN_ACCOUNTING_INVOICING,
        component: Invoicing,
      },
      // {
      //   label: "Bank reconciliation",
      //   permissionKey:
      // 'accounting.currencies.'
      // id,

      //   route: ADMIN_ACCOUNTING_BANK_RECONCILIATION,
      //   component: BankReconciliation,
      // },
      // {
      //   label: "Paid suppliers view/update",
      //   permissionKey:
      // 'accounting.currencies.'
      // id,

      //   route: ADMIN_ACCOUNTING_PAID_SUPPLIERS,
      //   component: PaidSuppliers,
      // },
      {
        label: "Supplier refund",
        permissionKey: "accounting.supplierRefund",
        route: ADMIN_ACCOUNTING_SUPPLIER_REFUND,
        component: SupplierRefund,
      },
      // {
      //   label: "Client refund update",
      //   permissionKey:
      // 'accounting.currencies.'
      // id,

      //   route: ADMIN_ACCOUNTING_CLIENT_REFUND,
      //   component: ClientRefund,
      // },
      {
        label: "Sales Dashboard",
        permissionKey: "accounting.salesDashboard",
        route: ADMIN_ACCOUNTING_SALES,
        component: SalesDashboardPage,
      },
      {
        label: "Operation Dashboard",
        permissionKey: "accounting.operationDashboard",
        route: ADMIN_ACCOUNTING_OPERATION,
        component: OperationDashboardPage,
      },
      {
        label: "Account Types",
        permissionKey: "accounting.accountTypes",
        route: ADMIN_ACCOUNTING_ACCOUNT_TYPES,
        component: AccountTypes,
      },
      {
        label: "Chart of Accounts",
        permissionKey: "accounting.chartOfAccounts",
        route: ADMIN_ACCOUNTING_CHART_OF_ACCOUNTS,
        component: ChartOfAccounts,
      },
    ],
  },
  "Reports & Statistics": {
    mainRoute: ADMIN_REPORTS,
    icon: "/media/svg/icons/General/reports.svg",
    items: [
      {
        label: "Receivable Due Report",
        permissionKey: "reports.receivable",
        route: ADMIN_REPORTS_DUE,
        component: BalanceDueReport,
      },
      {
        label: "Sales Report",
        permissionKey: "reports.sales",
        route: ADMIN_REPORTS_SALES,
        component: SalesReport,
      },
      // {
      //   label: "Purchase Report",
      //   permissionKey:
      //  'reports.receivable.'
      //  id,

      //   route: ADMIN_REPORTS_PURCHASE,
      //   component: PurchaseReport,
      // },
      {
        label: "Margin Report",
        permissionKey: "reports.margin",
        route: ADMIN_REPORTS_MARGIN,
        component: MarginReport,
      },
      {
        label: "Fees Report",
        permissionKey: "reports.fee",
        route: ADMIN_REPORTS_FEES,
        component: FeesReport,
      },
      {
        label: "Cancelled Report",
        permissionKey: "reports.cancelled",
        route: ADMIN_REPORTS_CANCELLED,
        component: CancelledReport,
      },
      {
        label: "Bank Accounts Report",
        permissionKey: "reports.bankAccount",
        route: ADMIN_REPORTS_BANK_ACCOUNTS,
        component: BankReport,
      },
      {
        label: "Top up Report",
        permissionKey: "reports.topup",
        route: ADMIN_REPORTS_TOP_UP,
        component: TopupsReport,
      },
    ],
  },
  // "Support Center": {
  //   mainRoute: ADMIN_REPORTS,
  //   icon: "/media/svg/icons/General/reports.svg",
  //   items: [
  //    { label: "Overview", permissionKey:

  // 43 },

  // { label: "Live Chat", permissionKey:

  //  44 },

  // { label: "Inbox", permissionKey:

  //  45 },

  // { label: "Tickets", permissionKey:

  //  46 },

  // { label: "Admin Guide", permissionKey:

  //  47 },

  // { label: "FAQ", permissionKey:

  //  48 },

  // { label: "Support", permissionKey:

  //  49 },

  // { label: "Contact", permissionKey:

  //  50 },

  // { label: "About", permissionKey:

  //  51 },

  //   ],
  // },
};

const AdminRoutes = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {Object.keys(adminRoutesPermissionMap).map((key) =>
          adminRoutesPermissionMap[
            key
          ].items.map(({ permissionKey, route, component: Component }) => (
            <ContentRoute
              path={route}
              component={Component}
              permissionKey={permissionKey}
            />
          ))
        )}
        <Route exact path={ADMIN_SESSIONS} component={ViewSessions} />

        <Redirect to={NOT_FOUND} />
      </Switch>
    </Suspense>
  );
};

export default AdminRoutes;
