import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Button } from "../Button/Button";
import styles from "./Tabs.module.scss";

export const Tabs = ({
  data,
  noContent,
  rightButtonTitle,
  onRightButtonClick,
  buttonLoading,
  label,
  dangerTabs,
  setFilter,
  tabsClassName,
  selectedFilter,
}) => {
  const [visibleTab, setVisibleTab] = useState(0);
  useEffect(() => {
    if (setFilter) setFilter(data[visibleTab].filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedFilter) {
      const index = data.findIndex(({ filter }) => filter === selectedFilter);
      setVisibleTab(index);
    }
  }, [data, selectedFilter]);
  const listTitles = data.map((item, index) => (
    <li
      key={`title_${index}`}
      onClick={() => {
        setVisibleTab(index);
        if (setFilter) setFilter(item.filter);
      }}
      className={clsx(styles.tabTitle, {
        [styles.tabTitle_active]: visibleTab === index && !dangerTabs,
        [styles.tabTitle_activeDanger]: visibleTab === index && dangerTabs,
      })}
    >
      {item.title}
    </li>
  ));

  const content =
    typeof data[visibleTab].content === "string" ? (
      <p>{data[visibleTab].content}</p>
    ) : (
      <>{data[visibleTab].content}</>
    );

  return (
    <div>
      <div
        className={clsx("d-flex  align-items-center", tabsClassName, {
          "justify-content-between": !tabsClassName,
        })}
      >
        {label && (
          <Typography
            style={{ whiteSpace: "nowrap" }}
            variant="h5"
            className="mr-5"
          >
            {label}:
          </Typography>
        )}
        <ScrollContainer horizontal={true}>
          <ul className={styles.tabsTitles}>{listTitles}</ul>
        </ScrollContainer>
        {rightButtonTitle && (
          <Button
            onClick={onRightButtonClick}
            loading={buttonLoading}
            variant="danger"
            className="ml-5"
          >
            {rightButtonTitle}
          </Button>
        )}
      </div>
      {!noContent && <div className={styles.tabContent}>{content}</div>}
    </div>
  );
};
