import clsx from "clsx";
import React from "react";
import { PublicFooter } from "./PublicFooter";
import { PublicHeader } from "./PublicHeader";

export const PublicLayout = ({ children }) => {
  return (
    <div className="bg-white">
      <PublicHeader />
      <div className={clsx(" mt-25 container")}>{children}</div>
      <PublicFooter />
    </div>
  );
};
