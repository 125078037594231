import { Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { SIGNUP_COMPLETED } from "../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../_helpers";
import { LeftBar } from "./LeftBar";
import styles from "./SignUp.module.scss";

export const Confirmation = ({ routesMap }) => {
  const history = useHistory();
  return (
    <LeftBar routesMap={routesMap}>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 text-center">
        <img
          src={toAbsoluteUrl("/media/svg/icons/General/email.svg")}
          alt=""
          className={styles.emailImg}
        />
        <div className="mb-6">
          <Typography className="font-weight-bold mb-1" variant="h3">
            Verify your email
          </Typography>
          <Typography variant="body1" className="mb-3">
            We've sent an email to you{/* <b>info@test.com</b>  */} to verify your email address and activate your account. The link in
            the email will expire in 24 hours.
          </Typography>
          {/* <Typography variant="body1">
            <button
              className="btn text-primary"
              onClick={() => history.push(SIGNUP_COMPLETED)}
            >
              Click here{" "}
            </button>
            if you did not receive an email or would like to change the email
            address you signed up with.
          </Typography> */}
        </div>
      </div>
    </LeftBar>
  );
};
