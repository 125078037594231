export const agencyPermissionIds = {
  dashboard: { id: 1 },
  fareSearch: {
    flightSearch: { id: 2 },
    pnrSearch: { id: 3 },
    umrah: { id: 3 },
  },
  bookingManagement: {
    ticketTracking: { id: 13 },
    refund: { id: 14 },
    void: { id: 15 },
    reissue: { id: 16 },
    searchOrder: { id: 17 },
    packages: { id: 0 },
  },
  ticketSummary: { id: 95 },
  myAccount: {
    companyProfile: {
      id: 97,
    },
    userDetails: {
      id: 98,
    },
    subscription: {
      id: 0,
    },
    subUsers: {
      id: 99,
    },
    balanceSummary: {
      id: 100,
    },
    notificationAccess: {
      id: 101,
    },
  },
  payments: {
    pinSettings: {
      id: 96,
    },
    paymentProof: { id: 19 },
    credit: {
      id: 21,
    },
  },
  markup: { id: 38 },
  reports: {
    payable: { id: 0 },
  },
};
