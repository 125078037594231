import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import {
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  withStyles,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core/";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./FlightSearch.module.scss";
import clsx from "clsx";
import { AutoCompleteSelect } from "./AutoCompleteSelect";
import { Button, CustomSelect } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/flightSearch";
import { getToday, getTomorrow } from "../../../_helpers/date";
import { FLIGHT_SEARCH } from "../../../../app/routesMap";
import {
  CHILD_AGE,
  pax_types_text,
  PAX_TYPE_CHILD,
  PAX_TYPE_INFANT,
  PAX_TYPE_YOUTH,
  YOUTH_AGE,
} from "../../../../redux/state/booking/bookingConstants";
import { pluralize } from "../../../_helpers";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import useAirportsOptions from "../../../../app/helpers/hooks/useAirportsOptions";

const agesOptions = {
  [PAX_TYPE_YOUTH]: Array(16 - YOUTH_AGE)
    .fill(0)
    .map((_, index) => ({
      value: index + YOUTH_AGE,
      label: index + YOUTH_AGE,
    })),
  [PAX_TYPE_CHILD]: Array(10)
    .fill(0)
    .map((_, index) => ({
      value: index + CHILD_AGE,
      label: index + CHILD_AGE,
    })),
  [PAX_TYPE_INFANT]: [
    { value: "<1", label: "<1" },
    { value: 1, label: 1 },
  ],
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(1),
  },
  InputRoot: {
    "&&::-webkit-calendar-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
  },
}));

const searchSchema = Yup.object().shape({
  numOfAdults: Yup.number(),
  numOfChildren: Yup.number(),
  numOfInfants: Yup.number(),
  tripType: Yup.string(),
  flightClass: Yup.string(),
  multi: Yup.string(),
  arrivalAirport: Yup.array()
    .of(Yup.string().required())
    .required(),
  departAirport: Yup.array()
    .of(Yup.string().required())
    .required(),
});

export const FlightSearchCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [numOfSearches, setNumOfSearches] = useState(1);
  const [ages, setAges] = useState({
    [PAX_TYPE_YOUTH]: [],
    [PAX_TYPE_CHILD]: [],
    [PAX_TYPE_INFANT]: [],
  });
  const fromRef = useRef();

  const { id: companyId } = useSelector(
    ({ company }) => company.companyData.data
  );
  const { loading } = useSelector(
    ({ flightSearch }) => flightSearch.searchResults
  );
  const { loading: startSearchLoading } = useSelector(
    ({ flightSearch }) => flightSearch.startSearch
  );
  const { searchDetails } = useSelector(({ flightSearch }) => flightSearch);
  const {
    airportsOptions,
    loading: airportsLoading,
    success: airportsSuccess,
  } = useAirportsOptions();

  const today = getToday();
  const tomorrow = getTomorrow();

  const initialValues = {
    numOfAdults: searchDetails?.numOfAdults || 1,
    numOfChildren: searchDetails?.numOfChildren || 0,
    numOfInfants: searchDetails?.numOfInfants || 0,
    numOfYouth: searchDetails?.numOfYouth || 0,
    tripType: searchDetails?.tripType || "O",
    flightClass: searchDetails?.flightClass || "EC",
    multi: searchDetails?.multi || 1,
    arrivalAirport: searchDetails?.arrivalAirport || [""],
    departAirport: searchDetails?.departAirport || [""],
    departDate: searchDetails?.departDate || [today],
    returnDate: searchDetails?.returnDate,
    isDirectFlight: searchDetails?.isDirectFlight || false,
    isFlexi: searchDetails?.isFlexi || false,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: searchSchema,
    onSubmit: () => {
      // const searchFlightPath = isAdmin ? ADMIN_FLIGHT_SEARCH : FLIGHT_SEARCH;
      if (location.pathname !== FLIGHT_SEARCH) {
        dispatch(
          actions.saveSearchDetails({ ...formik.values, isFromHome: true })
        );
        history.push(FLIGHT_SEARCH);
      } else {
        search();
      }
    },
  });
  const search = () => {
    dispatch(
      actions.saveSearchDetails({ ...formik.values, isFromHome: false })
    );
    dispatch(actions.searchFlights(params));
  };
  useEffect(() => {
    if (searchDetails?.isFromHome) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDetails]);

  const {
    numOfAdults,
    numOfChildren,
    tripType,
    flightClass,
    arrivalAirport,
    departAirport,
    departDate,
    returnDate,
    isDirectFlight,
    isFlexi,
    numOfInfants,
    numOfYouth,
  } = formik.values;

  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { setFieldValue, getFieldProps, resetForm } = formik;

  const handleAgesChange = (index, e, type) => {
    const temp = [...ages[type]];
    temp[index] = e.target.value;
    setAges({ ...ages, [type]: temp });
  };
  let modalSelects = [];
  for (const [key, value] of Object.entries(ages)) {
    const selects = value?.map((age, index) => (
      <div key={`select-${key}-${index}`}>
        <Typography variant="h6" className="my-4">
          Age of {pax_types_text[key]} - {index + 1}
        </Typography>
        <CustomSelect
          value={age}
          onChange={(e) => handleAgesChange(index, e, key)}
          options={agesOptions[key]}
        />
      </div>
    ));
    modalSelects = [...modalSelects, ...selects];
  }
  // useEffect(() => {
  //   setFieldValue("returnDate", getTomorrow(departDate[0]));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [departDate, setFieldValue]);

  useEffect(() => {
    if (tripType === "M") {
      setFieldValue("departAirport", [
        ...values.departAirport,
        values.arrivalAirport[0],
      ]);
      setFieldValue("arrivalAirport", [...values.arrivalAirport, ""]);
      setFieldValue("departDate", [...values.departDate, values.departDate[0]]);
      setNumOfSearches(2);
    } else {
      setFieldValue("departAirport", [values.departAirport[0]]);
      setFieldValue("arrivalAirport", [values.arrivalAirport[0]]);
      setFieldValue("departDate", [values.departDate[0]]);
      setNumOfSearches(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripType]);

  const addSearch = () => {
    const prevLastIndex = values.arrivalAirport.length - 1;
    setFieldValue("departAirport", [
      ...values.departAirport,
      values.arrivalAirport[prevLastIndex],
    ]);
    setFieldValue("arrivalAirport", [...values.arrivalAirport, ""]);
    setFieldValue("departDate", [
      ...values.departDate,
      values.departDate[prevLastIndex],
    ]);
    setNumOfSearches(numOfSearches + 1);
  };

  const removeField = (index) => {
    const dApts = [...values.departAirport];
    dApts.splice(index, 1);
    setFieldValue("departAirport", [...dApts]);
    const aApts = [...values.arrivalAirport];
    aApts.splice(index, 1);
    setFieldValue("arrivalAirport", [...aApts]);
    const dDates = [...values.departDate];
    dDates.splice(index, 1);
    setFieldValue("departDate", [...dDates]);
    setNumOfSearches(numOfSearches - 1);
  };

  const params = {
    AdvanceSearch: {
      DirectFlight: isDirectFlight,
      IsFlexi: isFlexi,
    },
    GeneralInfo: {
      Cabin: flightClass,
      Currency: "USD",
      GUID: "",
      JourneyType: "INT",
      TripType: tripType,
      Username: "Test",
      Password: "Test",
      AgencyId: `${companyId}`,
      Env: process.env.REACT_APP_ENV_TYPE,
    },
    PaxDetails: {
      Adult: numOfAdults,
      Child: numOfChildren,
      Youth: numOfYouth,
      Infant: numOfInfants,
    },
    Segments: [
      ...(tripType === "M"
        ? departAirport.map(({ value }, index) => ({
            ArrivalApt: arrivalAirport[index].value,
            DepartApt: departAirport[index].value,
            DepartDate: departDate[index],
          }))
        : [
            {
              ArrivalApt: arrivalAirport[0].value,
              DepartApt: departAirport[0].value,
              DepartDate: departDate[0],
            },
          ]),

      ...(tripType === "R"
        ? [
            {
              ArrivalApt: departAirport[0].value,
              DepartApt: arrivalAirport[0].value,
              DepartDate: returnDate,
            },
          ]
        : []),
    ],
  };

  const { values } = formik;
  // useEffect(() => {
  //   if (complete && location.pathname !== FLIGHT_SEARCH) {
  //     history.push(FLIGHT_SEARCH);
  //   }
  // }, [complete]);
  const swapAirports = (index) => {
    const tempDepart = departAirport[index];
    setFieldValue(`departAirport.${index}`, arrivalAirport[index]);
    setFieldValue(`arrivalAirport.${index}`, tempDepart);
  };
  const clearAll = () => {
    dispatch(actions.reset());
    resetForm({
      numOfAdults: 1,
      numOfChildren: 0,
      numOfInfants: 0,
      tripType: "O",
      flightClass: "EC",
      multi: 1,
      arrivalAirport: "",
      departAirport: "",
      departDate: [today],
      returnDate: tomorrow,
      isDirectFlight: false,
      isFlexi: false,
    });
  };
  const onArrAirportChange = (value, index) => {
    setFieldValue(`arrivalAirport.${index}`, value);
    const lastIndex = values.arrivalAirport.length - 1;
    if (index < lastIndex) {
      if (!values.departAirport[index]) {
        setFieldValue(`departAirport.${index + 1}`, value);
      }
    }
  };
  const removeAgeSelect = (type) => {
    const temp = [...ages[type]];
    temp.pop();
    setAges({ ...ages, [type]: [...temp] });
  };
  const addAgeSelect = (type) => {
    let temp = [...ages[type]];
    temp = [...temp, ""];
    setAges({ ...ages, [type]: temp });
  };
  const numOfPassengers =
    numOfAdults + numOfChildren + numOfYouth + numOfInfants;
  return (
    <div>
      <div className="d-flex flex-wrap mb-2 align-items-start">
        <FormControl className={classes.margin}>
          <Select
            {...getFieldProps("tripType")}
            input={<BootstrapInput />}
            name="tripType"
            id="tripType"
          >
            <MenuItem value="O" id="O">
              One way
            </MenuItem>
            <MenuItem value="R" id="R">
              Round
            </MenuItem>
            <MenuItem value="M" id="M">
              Multi City
            </MenuItem>
          </Select>
        </FormControl>

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          className="d-flex align-items-center justify-content-center"
        >
          <Paper
            className="d-flex flex-column bg-white rounded flex-grow-1 overflow-auto p-8 max-h-500px"
            style={{ maxWidth: "350px" }}
          >
            <Typography variant="h6">Adults</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfAdultsPlus"
                onClick={() => setFieldValue("numOfAdults", numOfAdults - 1)}
                disabled={numOfAdults === 1}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfAdults}
              </Typography>
              <button
                id="numOfAdultsMinus"
                className={styles.roundButton}
                onClick={() => setFieldValue("numOfAdults", numOfAdults + 1)}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                12+ Years
              </Typography>
            </div>

            {/* <Typography variant="h6">Youth</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfYouthPlus"
                onClick={() => {
                  setFieldValue("numOfYouth", numOfYouth - 1);
                  removeAgeSelect(PAX_TYPE_YOUTH);
                }}
                disabled={numOfYouth === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfYouth}
              </Typography>
              <button
                id="numOfYouthMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfYouth", numOfYouth + 1);
                  addAgeSelect(PAX_TYPE_YOUTH);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                12-15 Years
              </Typography>
            </div> */}
            <Typography variant="h6">Children</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfChildrenPlus"
                onClick={() => {
                  setFieldValue("numOfChildren", numOfChildren - 1);
                  removeAgeSelect(PAX_TYPE_CHILD);
                }}
                disabled={numOfChildren === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfChildren}
              </Typography>
              <button
                id="numOfChildrenMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfChildren", numOfChildren + 1);
                  addAgeSelect(PAX_TYPE_CHILD);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                2-12 Years
              </Typography>
            </div>
            <Typography variant="h6">Infants</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfInfantsPlus"
                onClick={() => {
                  setFieldValue("numOfInfants", numOfInfants - 1);
                  removeAgeSelect(PAX_TYPE_INFANT);
                }}
                disabled={numOfInfants === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfInfants}
              </Typography>
              <button
                id="numOfInfantsMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfInfants", numOfInfants + 1);
                  addAgeSelect(PAX_TYPE_INFANT);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                0-2 Years
              </Typography>
            </div>
            {/* <div>{modalSelects}</div> */}
            <Button
              id="saveAges"
              className="mx-auto mt-3"
              onClick={() => setOpenModal(false)}
            >
              Save
            </Button>
          </Paper>
        </Modal>
        <FormControl className={classes.margin}>
          <Select
            {...getFieldProps("flightClass")}
            input={<BootstrapInput />}
            name="flightClass"
            id="flightClass"
          >
            <MenuItem value="ALL" id="ALL">
              ALL
            </MenuItem>
            <MenuItem value="EC" id="EC">
              Economy
            </MenuItem>
            <MenuItem value="PE" id="PE">
              Premium Economy
            </MenuItem>
            <MenuItem value="BC" id="BC">
              Business
            </MenuItem>
            <MenuItem value="FC" id="FC">
              First
            </MenuItem>
          </Select>
        </FormControl>
        <button
          id="Passengers"
          onClick={() => setOpenModal(true)}
          className={clsx(styles.passengersButton, "position-relative")}
        >
          {pluralize(numOfPassengers, "Passenger", "s")}
          <span className="ml-3" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            <SVG
              src={"/media/svg/icons/General/caret-down-fill.svg"}
              className="MuiSvgIcon-root"
            />
          </span>
        </button>
        {/* <FormControl className={classes.margin}>
          <Select
            {...getFieldProps("multi")}
            input={<BootstrapInput />}
            name="multi"
          >
            <MenuItem value="multi">Multi city</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      {Array(numOfSearches)
        .fill(0)
        .map((_, index) => (
          <div key={`search-${index}`} className="d-flex">
            <Grid container spacing={3} key={`search${index}`}>
              <Grid item xs={12} md={6}>
                <div
                  className={clsx("position-relative p-0 w-100", {
                    "badge border rounded border-gray-400": matchesSm,
                  })}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      className={clsx(
                        "d-flex align-items-center justify-content-between mx-3",
                        {
                          "badge border rounded border-gray-400 mb-5": !matchesSm,
                        }
                      )}
                    >
                      <AutoCompleteSelect
                        options={airportsOptions}
                        isOptionsLoaded={airportsSuccess}
                        isLoading={airportsLoading}
                        onChange={(value) =>
                          setFieldValue(`departAirport.${index}`, value || "")
                        }
                        value={departAirport[index]}
                        label="From"
                        id={`from-${index}`}
                      />

                      {!matchesSm && (
                        <button
                          className="btn w-30px h-30px  d-flex align-items-center justify-content-center 
                                    border border-primary rounded-circle bg-white"
                          style={{ pointer: "cursor" }}
                          id={`swap-${index}`}
                        >
                          <SVG
                            src={"/media/svg/icons/General/reverse.svg"}
                            className={"svg-icon-primary svg-icon-1"}
                          />
                        </button>
                      )}
                    </Grid>
                    {matchesSm && (
                      <Grid
                        item
                        xs={12}
                        sm
                        className={clsx(
                          "d-flex align-items-center justify-content-center"
                        )}
                      >
                        <div
                          className={clsx({ [styles.seperator]: matchesSm })}
                        >
                          <button
                            onClick={() => swapAirports(index)}
                            className={clsx(
                              {
                                [styles.reverse]: matchesSm,
                                "w-40px h-40px": matchesSm,
                                "w-30px h-30px": !matchesSm,
                              },
                              "d-flex align-items-center justify-content-center border border-primary rounded-circle bg-white"
                            )}
                            id={`from-${index}`}
                          >
                            <SVG
                              src={"/media/svg/icons/General/reverse.svg"}
                              className={"svg-icon-primary svg-icon-1"}
                            />
                          </button>
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      className={clsx(" d-flex align-items-center mx-3", {
                        "badge border rounded border-gray-400": !matchesSm,
                      })}
                    >
                      <AutoCompleteSelect
                        options={airportsOptions}
                        isOptionsLoaded={airportsSuccess}
                        isLoading={airportsLoading}
                        onChange={(value) =>
                          onArrAirportChange(value || "", index)
                        }
                        value={arrivalAirport[index]}
                        label="to"
                        id={`to-${index}`}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className=" p-0 badge border rounded border-gray-400 w-100">
                  <div className="position-relative d-flex h-100">
                    <div className="d-flex justify-content-start w-50 p-5">
                      <TextField
                        {...getFieldProps(`departDate.${index}`)}
                        className={styles.datePickerInput}
                        id="date"
                        label="Depart"
                        type="date"
                        // defaultValue={today}
                        // onClick={()=>fromRef.current.datepicker("show")}
                        ref={fromRef}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          id: `departDate-${index}`,
                          inputProps: {
                            min: today,
                          },
                        }}
                      />
                    </div>
                    <div className={styles.seperator} />
                    <div className="d-flex justify-content-start w-50 p-5">
                      <TextField
                        id="date"
                        label="Return"
                        type="date"
                        className={styles.datePickerInput}
                        // defaultValue={tomorrow}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          id: `returnDate-${index}`,
                          inputProps: {
                            min: departDate[0],
                          },
                        }}
                        disabled={tripType !== "R"}
                        {...getFieldProps("returnDate")}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            {numOfSearches > 2 && (
              <button
                type="button"
                onClick={() => removeField(index)}
                className="btn btn-icon btn-secondary btn-hover-light-danger btn-sm my-auto mx-3"
              >
                <span className="svg-icon svg-icon-sm svg-icon-danger">
                  <SVG src="/media/svg/icons/General/cancel.svg" />
                </span>
              </button>
            )}
          </div>
        ))}
      <div>
        <div
          className={clsx("d-flex justify-content-between mt-3", {
            "flex-column ": !matchesSm,
          })}
        >
          <div
            className={clsx("d-flex mb-3", {
              "justify-content-between ": !matchesSm,
            })}
          >
            {tripType === "M" && (
              <Button
                className="mr-3"
                onClick={addSearch}
                variant="danger"
                smallPadding
                type="button"
              >
                + Add another Flight
              </Button>
            )}
            <Button onClick={clearAll} type="button" variant="primary-inverse">
              Clear all
            </Button>
          </div>
          <Button
            disabled={
              !!formik.errors.departAirport || !!formik.errors.arrivalAirport
            }
            loading={loading || startSearchLoading}
            onClick={formik.handleSubmit}
            variant="primary"
            type="submit"
          >
            Search
          </Button>
        </div>
        <div className={clsx("d-flex", { "flex-column": !matchesSm })}>
          {tripType !== "M" && (
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps("isFlexi")}
                  // checked={state.checkedB}
                  // onChange={handleChange}
                  color="primary"
                />
              }
              label="Flexible Dates"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("isDirectFlight")}
                // checked={state.checkedB}
                // onChange={handleChange}
                color="primary"
              />
            }
            label="Direct flights only"
          />
          <FormControlLabel
            control={
              <Checkbox
                // checked={state.checkedB}
                // onChange={handleChange}
                color="primary"
              />
            }
            label="Add Nearby Airport"
          />
        </div>
      </div>
    </div>
  );
};
