import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Button, LoadingSpinner } from "../common";
import clsx from "clsx";
import { actions } from "../../../../redux/state/payment";
import { getDateTime } from "../../../_helpers/date";
import { Pagination } from "@material-ui/lab";
import { TopupsReportSearchCard } from "../admin/reports/TopupsReport/TopupsReportSearchCard";
import useBankAccountData from "../../../../app/helpers/hooks/useBankAccountData.js";
import { TopUpFormModal } from "./TopUpFormModal";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";

const TableRow = ({ data, getBankAccountData }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    status,
    createdAt,
    paymentAmount,
    remarks,
    verificationRemark,
    paymentProof,
    id,
    bankAccountId,
  } = data;
  const badgeClassNames = {
    0: "text-warning",
    1: "text-danger",
    2: "text-success",
  };
  const badgeText = {
    0: "Pending",
    1: "Failed",
    2: "Complete",
  };
  const { date, time } = getDateTime(createdAt);
  const { banks, accountNo } = getBankAccountData(bankAccountId);
  const rows = (
    <>
      <td className="text-left text-primary">{id || "-"}</td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </div>
      </td>
      {matchesSm && (
        <>
          <td className="text-primary">
            <a target="_blank" rel="noopener noreferrer" href={paymentProof}>
              View Proof
            </a>
          </td>
          <td>{remarks || "-"}</td>
        </>
      )}
      <td>${paymentAmount?.toFixed(2) || "-"}</td>
      {matchesSm && (
        <td>
          <div>
            <p className="mb-0">{accountNo}</p>
            <p className="text-muted font-weight-normal mb-0">{banks?.name}</p>
          </div>
        </td>
      )}
      <td>
        <span className={badgeClassNames[status]}>{badgeText[status]}</span>
      </td>
      {matchesSm && <td>{verificationRemark || "-"}</td>}
    </>
  );

  const mobileRowDetails = [
    {
      label: "Payment proof",
      value: (
        <a target="_blank" rel="noopener noreferrer" href={paymentProof}>
          View Proof
        </a>
      ),
    },
    { label: "Remark", value: remarks },
    { label: "Bank Account", value: accountNo },
    { label: "Verification Remark", value: verificationRemark },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

// export const paymentMethodOptions = [
//   // { label: "Card", value: 0 },
//   { label: "Bank Transfer", value: 1 },
// ];

export const PaymentProof = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const {
    getBankAccountData,
    loading: allBankAccountsLoading,
  } = useBankAccountData();
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { fromDate, toDate } = dateRange;
  const [openModal, setOpenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { id: companyId } =
    useSelector(({ company }) => company.companyData.data) || {};
  const { list, loading: topUpsLoading, paginationInfo } = useSelector(
    ({ payment }) => payment.topUps
  );
  const { totalPages } = paginationInfo ?? {};

  useEffect(() => {
    dispatch(
      actions.filterAllTopUps({
        filterParam: [
          {
            columnName: "companyId",
            filterValue: `${companyId}`,
            filterOption: 1,
          },
          ...appliedFilters,
        ],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch]);

  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterAllTopUps({
        filterParam: [
          {
            columnName: "companyId",
            filterValue: `${companyId}`,
            filterOption: 1,
          },
          ...filters,
        ],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(
        actions.filterAllTopUps({
          filterParam: [
            {
              columnName: "companyId",
              filterValue: `${companyId}`,
              filterOption: 1,
            },
          ],
        })
      );
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const tableContent = list?.map((item, index) => (
    <TableRow key={index} data={item} getBankAccountData={getBankAccountData} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          {/* hidden for release */}
          {/* <Typography variant="h5" className="font-weight-bold mb-5">
            Payment proof
          </Typography> */}
          <TopUpFormModal open={openModal} setOpen={setOpenModal} />
          <div
            className={clsx("d-flex justify-content-between", {
              "flex-column": !matchesSm,
            })}
          >
            <Typography variant="h5" className="font-weight-bold mb-5">
              Payment proof
            </Typography>
            {/* hidden for release */}
            {/* <div className="d-flex flex-grow-1">
              <Input
                noErrorMessage
                className={clsx("flex-grow-1", { "max-w-500px": matchesSm })}
                placeholder="Search"
              />
              <Button className="ml-4" variant="primary">
                Search
              </Button>
            </div> */}
            <Button
              onClick={() => setOpenModal(true)}
              variant="primary-inverse"
              className={clsx({
                "ml-auto": matchesSm,
                "mx-auto mt-3": !matchesSm,
              })}
              smallPadding
            >
              + Upload payment proof
            </Button>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <TopupsReportSearchCard
            setDateRange={setDateRange}
            onSearch={search}
            isAll
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          {fromDate && toDate && (
            <Typography>
              Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          {topUpsLoading || allBankAccountsLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-vertical-center text-center font-weight-bold">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">ID</th>
                    <th className="min-w-100px">Upload Date</th>
                    {matchesSm && (
                      <>
                        <th>Payment Proof </th>
                        <th>Remark</th>
                      </>
                    )}
                    <th>Payment Amount</th>
                    {matchesSm && <th>Bank Account</th>}
                    <th>Status</th>
                    {matchesSm && <th>Verification Remark</th>}
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No topups to show
                </Typography>
              )}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
