import { Paper } from "@material-ui/core";
import React from "react";

export const ClientRecentLog = () => {
  return (
    <div className="d-flex flex-column h-100">
      <RecentLogCard />
      <RecentLogCard className='my-6' />
      <RecentLogCard />
    </div>
  );
};

export const RecentLogCard = ({className}) => {
  return (
    <Paper className={`p-7 d-flex flex-column flex-grow-1 ${className}`}>
      <span className="text-muted mb-3">Recent Logs</span>
      <span className="font-weight-bold">Ahmed Fayez</span>
      <span className="my-3">Requested to void order no. FM2G54</span>
      <span className={`label label-lg max-w-110px font-weight-bold label-light-primary label-inline`}>
        Sep 11, 2021
      </span>
    </Paper>
  );
};
