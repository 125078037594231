import React from "react";
import { Modal } from "react-bootstrap";
import { Grid, Switch } from "@material-ui/core";

import { Button, Input, Select } from "../../../common";
import {
  promoAppliedOnEnums,
  promoAppliedOnLabels,
  promoDurationTypeEnums,
  promoDurationTypeLabels,
} from "../../../../../../redux/state/user/userConstants";

const typeOptions = Object.values(promoDurationTypeEnums).map((value) => ({
  label: promoDurationTypeLabels[value],
  value,
}));

const appliedOnOptions = Object.values(promoAppliedOnEnums).map((value) => ({
  label: promoAppliedOnLabels[value],
  value,
}));

// generate random code with 6 characters and numbers
const generatePromoCode = () => {
  const length = 6;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result.toUpperCase();
};

export const PromoCodeForm = ({ fields, show, loading, onHide }) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;
  const handleGenerate = () => {
    const generatedCode = generatePromoCode();
    setFieldValue("code", generatedCode);
  };
  return (
    <Modal size="lg" onHide={onHide} show={show}>
      <>
        <Modal.Header>Service Fee</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("name")}
                showErrors={touched["name"]}
                errorMessage={errors["name"]}
                variant="secondary"
                label="Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex align-items-end">
                <Input
                  {...getFieldProps("code")}
                  showErrors={touched["code"]}
                  errorMessage={errors["code"]}
                  variant="secondary"
                  label="Code"
                />
                <Button
                  className="ml-3"
                  variant="danger"
                  onClick={handleGenerate}
                >
                  Generate
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                showErrors={touched["durationType"]}
                errorMessage={errors["durationType"]}
                value={values.durationType}
                onChange={(newValue) => setFieldValue("durationType", newValue)}
                variant="secondary"
                label="Duration Type"
                options={typeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                showErrors={touched["appliedOn"]}
                errorMessage={errors["appliedOn"]}
                value={values.appliedOn}
                onChange={(newValue) => setFieldValue("appliedOn", newValue)}
                variant="secondary"
                label="Applied On"
                options={appliedOnOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex align-items-end">
                <Input
                  {...getFieldProps("amount")}
                  showErrors={touched["amount"]}
                  errorMessage={errors["amount"]}
                  variant="secondary"
                  label="Discount Amount"
                />
                <Select
                  variant="secondary"
                  className="mx-3 max-w-60px"
                  options={[
                    { value: true, label: "%" },
                    { value: false, label: "$" },
                  ]}
                  onChange={(value) => setFieldValue("isPercentage", value)}
                  value={values.isPercentage}
                  errorMessage={errors.isPercentage}
                  showErrors={touched.isPercentage}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("validTo")}
                showErrors={touched["validTo"]}
                errorMessage={errors["validTo"]}
                variant="secondary"
                label="Valid Till"
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="pl-6 d-flex flex-column align-items-start justify-content-between">
                Active
                <Switch
                  checked={values.isActive}
                  onClick={() => setFieldValue("isActive", !values.isActive)}
                  color="primary"
                />
              </label>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            type="submit"
            loading={loading}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
