import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/banks";

export default function useBankAccountData() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ banks }) => banks.allBankAccounts);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllBankAccounts());
    }
  }, [dispatch, list]);
  const getBankAccountData = useCallback(
    (bankAccountId) =>
      list?.filter(({ id }) => +id === +bankAccountId)?.[0] || {},
    [list]
  );
  return { getBankAccountData, loading };
}
