import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import { TICKET_DETAILS } from "../../../../../../app/routesMap";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../../../app/routesMapAdmin";
import { getDateTime } from "../../../../../_helpers";
import { LoadingSpinner, MenuComponent } from "../../../common";
import { DueDateUpdateModal } from "./DueDateUpdateModal";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const ReceivableDueTable = ({ onUpdateSuccess }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { list, loading } = useSelector(({ invoices }) => invoices.invoices);

  const openUpdateDueDate = (id) => {
    setShowModal(true);
    setSelectedId(id);
  };
  const getSelectBooking = () => list?.filter(({ id }) => selectedId === id)[0];
  const tableContent = list?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
      openUpdateDueDate={openUpdateDueDate}
    />
  ));
  return (
    <div className="table-responsive">
      {showModal && (
        <DueDateUpdateModal
          onHide={() => setShowModal(false)}
          show={showModal}
          data={getSelectBooking()}
          onSuccess={onUpdateSuccess}
        />
      )}
      {loading || allCompaniesLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-100">
              <th>SNo.</th>
              <th>Booking ID</th>
              {/* <th>Invoice To</th> */}
              {matchesSm && (
                <>
                  <th>Invoice No.</th>
                  <th>Supplier</th>
                  <th className="min-w-100px">Booking Date</th>
                </>
              )}
              <th>Agency Name</th>
              {matchesSm && (
                <>
                  <th className="min-w-100px">Due Date</th>
                  <th className="min-w-150px">Due Date Remarks</th>
                  <th>Received Amount</th>
                  <th>Due Amount</th>
                </>
              )}
              <th>Total Amount</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getCompanyName, openUpdateDueDate }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    id,
    tranDate,
    bookingId,
    agencyId,
    total,
    supplierName,
    invoiceNo,
    received,
    due,
    remarks,
    due_date: dueDateTime,
  } = data;
  const agencyName = getCompanyName(agencyId);
  const { date, time } = getDateTime(tranDate);
  const { date: dueDate, time: dueTime } = getDateTime(dueDateTime);
  const detailsLink = isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS;
  const menuOptions = [
    {
      label: "Update Due Date",
      onClick: () => openUpdateDueDate(id),
    },
  ];
  const rows = (
    <>
      <td>
        <span>{id || "-"}</span>
      </td>
      <td>
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(detailsLink, { id: bookingId })}
        >
          <span>{bookingId}</span>
        </NavLink>
      </td>
      {/* <td>
        <span>{passenger || "-"}</span>
      </td> */}
      {matchesSm && (
        <>
          <td>
            <span>{invoiceNo || "-"}</span>
          </td>
          <td>
            <span>{supplierName?.slice(0, 2) || "-"}</span>
          </td>
          <td>
            <span className="text-dark-75 d-block font-size-lg">{date}</span>
            <span className="text-muted">{time}</span>
          </td>
        </>
      )}
      <td>
        <span>{agencyName || "-"}</span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="text-dark-75 d-block font-size-lg">{dueDate}</span>
            <span className="text-muted">{dueTime}</span>
          </td>
          <td>
            <span>{remarks || "-"}</span>
          </td>
          <td>
            <span>{received?.toFixed(2) || "-"}</span>
          </td>
          <td>
            <span>{due?.toFixed(2) || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span>{total?.toFixed(2)}</span>
      </td>
      <td className="text-right">
        <MenuComponent options={menuOptions} />
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "Invoice No.",
      value: invoiceNo || "-",
    },
    {
      label: "Supplier",
      value: supplierName?.slice(0, 2) || "-",
    },
    {
      label: "Booking Date",
      value: `${date} ${time}`,
    },
    {
      label: "Due Date",
      value: `${dueDate} ${dueTime}`,
    },
    {
      label: "Due Date Remarks",
      value: remarks || "-",
    },
    {
      label: "Received Amount",
      value: received?.toFixed(2) || "-",
    },
    {
      label: "Due Amount",
      value: due?.toFixed(2) || "-",
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
