import { useCallback } from "react";
import usePackageTypesList from "./usePackageTypesList";

export default function usePkgTypeName() {
  const { list, loading, success, failure } = usePackageTypesList();

  const getPkgTypeName = useCallback(
    (pgkTypeId) => list.find(({ id }) => pgkTypeId === id)?.name ?? "",
    [list]
  );
  return { getPkgTypeName, loading, success, failure };
}
