import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import { getDateTime } from "../../../../../_helpers";
import { LoadingSpinner } from "../../../common";
import { topupBadgeClassNames, topupBadgeText } from "../../topUps/TopUps";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const TopupsReportTable = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { list, loading } = useSelector(({ payment }) => payment.topUps);
  const tableContent = list?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
    />
  ));
  return (
    <div className="table-responsive border">
      {loading || allCompaniesLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-200">
              {matchesSm && <th className="text-left">ID</th>}
              <th>Agency Name</th>
              {matchesSm && <th className="min-w-100px">Requested Date</th>}
              <th className="min-w-100px">Payment proof </th>
              {matchesSm && <th>remark</th>}
              <th>Payment Amount</th>
              <th>Status</th>
              {matchesSm && <th>Verification remark</th>}
              {/* <th className="min-w-120px">Actions</th> */}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    status,
    createdAt,
    paymentAmount,
    remarks,
    verificationRemark,
    paymentProof,
    id,
    companyId,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const rows = (
    <>
      {matchesSm && <td className="text-left text-primary">{id || "-"}</td>}
      <td className="text-left">{getCompanyName(companyId) || "-"}</td>
      {matchesSm && (
        <td>
          <div>
            <p className="mb-0">{date}</p>
            <p className="text-muted font-weight-normal mb-0">{time}</p>
          </div>
        </td>
      )}
      <td className="text-primary">
        <a target="_blank" rel="noopener noreferrer" href={paymentProof}>
          View Proof
        </a>
      </td>
      {matchesSm && <td>{remarks || "-"}</td>}
      <td>${paymentAmount?.toFixed(2) || "-"}</td>
      <td>
        <span className={topupBadgeClassNames[status]}>
          {topupBadgeText[status]}
        </span>
      </td>
      {matchesSm && <td>{verificationRemark || "-"}</td>}
    </>
  );
  const mobileRowDetails = [
    { label: "ID", value: id || "-" },
    { label: "Agency Name", value: getCompanyName(companyId) || "-" },
    { label: "Requested Date", value: date },
    { label: "Payment proof", value: paymentProof },
    { label: "Remark", value: remarks || "-" },
    { label: "Payment Amount", value: `$${paymentAmount?.toFixed(2) || "-"}` },
    { label: "Status", value: topupBadgeText[status] },
    { label: "Verification remark", value: verificationRemark || "-" },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
