import { useCallback } from "react";
import useAirportsList from "./useAirportsList";

export default function useAirportName() {
  const { airportsList, loading, success, failure } = useAirportsList();

  const getAirportName = useCallback(
    (airportCode) =>
      airportsList?.filter(({ code }) => airportCode === code)?.[0]?.name,
    [airportsList]
  );
  return { getAirportName, loading, success, failure };
}
