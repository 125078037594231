import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FLIGHT_BOOKING, FLIGHT_SEARCH, PNR_SEARCH, PACKAGE_SEARCH } from "../../routesMap";
import { FlightSearch } from "../../../_metronic/layout/components/fareSearch/FlightSearch";
import { FlightBookPage } from "../flightBook/FlightBookPage";
import { actions } from "../../../redux/state/booking";
import { actions as flightActions } from "../../../redux/state/flightSearch";

export const FlightSearchPage = () => {
  const dispatch = useDispatch();  
  const { selectedAirline } = useSelector(
    (state) => state.flightSearch.searchResults
  );
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
      dispatch(flightActions.reset());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path={FLIGHT_BOOKING}>
        {selectedAirline ? <FlightBookPage /> : <Redirect to={FLIGHT_SEARCH} />}
      </Route>
      {/* <Route path={FLIGHT_BOOKING} component={FlightBookPage} /> */}
      <Route path={FLIGHT_SEARCH} component={FlightSearch} />
      <Route path={PNR_SEARCH} component={FlightSearch} />
      {/* <Route path={UMRAH_SEARCH} component={FlightSearch} /> */}

    </Switch>
  );
};
