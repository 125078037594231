import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./invoicesActions";

const initialState = {
  invoices: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificInvoice: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const invoicesReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_ALL.REQUESTED:
      case actions.GET_UNPAID_INVOICES.REQUESTED:
      case actions.FILTER.REQUESTED:
        state.invoices.loading = true;
        break;
      case actions.GET_ALL.SUCCEEDED:
      case actions.GET_UNPAID_INVOICES.SUCCEEDED:
      case actions.FILTER.SUCCEEDED:
        state.invoices.loading = false;
        state.invoices.success = true;
        const { data, ...rest } = payload.data.data?.filteredInvoice;
        state.invoices.list = data;
        state.invoices.stats = payload.data.data?.totalInvoice;
        state.invoices.paginationInfo = rest;
        break;
      case actions.GET_ALL.FAILED:
      case actions.GET_UNPAID_INVOICES.FAILED:
      case actions.FILTER.FAILED:
        state.invoices.loading = false;
        state.invoices.failure = payload.error;
        break;
      case actions.GET_ONE.REQUESTED:
      case actions.GET_BY_INVOICE_NO.REQUESTED:
        state.specificInvoice.loading = true;
        break;
      case actions.GET_BY_INVOICE_NO.SUCCEEDED:
        state.specificInvoice.loading = false;
        state.specificInvoice.success = true;
        state.specificInvoice.data = payload.data.data[0];
        break;
      case actions.GET_ONE.SUCCEEDED:
        state.specificInvoice.loading = false;
        state.specificInvoice.success = true;
        state.specificInvoice.data = payload.data.data;
        break;
      case actions.GET_ONE.FAILED:
      case actions.GET_BY_INVOICE_NO.FAILED:
        state.specificInvoice.loading = false;
        state.specificInvoice.failure = payload.error;
        break;
      case actions.GET_ONE.CLEAR:
      case actions.GET_BY_INVOICE_NO.CLEAR:
        state.specificInvoice = initialState.specificInvoice;
        break;
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
