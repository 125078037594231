/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Nav, Tab } from "react-bootstrap";
import { useHtmlClassService } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/charts";

export function RevenueStates({ className, chartColor = "danger" }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { list } = useSelector(({ charts }) => charts.getRevenueByMonth);
  const { isAdmin } = useSelector(
    ({ company }) => company.companyData
  );

  useEffect(() => {
    if (isAdmin) {
      dispatch(actions.getRevenueByMonth());
    } else {
      dispatch(
        actions.getCompanyRevenueByMonth({ companyId: companyInUserId })
      );
    }
  }, [companyInUserId, dispatch, isAdmin]);
  useEffect(() => {
    if (list) {
      const tempData = [],
        tempLabels = [];
      list.forEach(({ month, value }) => {
        tempData.push(parseInt(value));
        tempLabels.push(month);
      });
      setData(tempData);
      setLabels(tempLabels);
    }
  }, [list]);
  const [key, setKey] = useState("Month");
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  useEffect(() => {
    const element = document.getElementById("kt_tiles_widget_1_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, data, labels);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, labels, layoutProps]);

  return (
    <div className={`card card-custom h-100 ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <div className="card-title">
          <div className="card-label">
            <div className="font-weight-bolder">Revenue Stats</div>
            {/* <div className="font-size-sm text-muted mt-2">
              40% More Revenue this month
            </div> */}
          </div>
        </div>
        {/* <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={(_key) => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Month
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Week
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  className={`nav-link py-2 px-4 ${
                    key === "Day" ? "active" : ""
                  }`}
                >
                  Day
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex flex-column px-0">
        {/* begin::Chart */}
        <div
          id="kt_tiles_widget_1_chart"
          data-color={chartColor}
          style={{ height: "150px" }}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
}

function getChartOption(layoutProps, data, labels) {
  const options = {
    series: [
      {
        name: "Revenue",
        data: data,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseColor],
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        // show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      // max: 37,
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return "$" + val;
        },
      },
    },
    colors: [layoutProps.colorsThemeLightColor],
    markers: {
      colors: [layoutProps.colorsThemeLightColor],
      strokeColor: [layoutProps.colorsThemeBaseColor],
      strokeWidth: 3,
    },
    padding: {
      top: 0,
      bottom: 0,
    },
  };
  return options;
}
