import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/company";
import { actions as filesActions } from "../../../../../../redux/state/files";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { ClientForm } from "../../client/ClientForm";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const CompanySettings = ({ title, id, isCurrent, hasReset }) => {
  const dispatch = useDispatch();
  const { data: companyData } = useSelector(
    ({ company }) => company.specificCompanyData
  );
  const { userId } = useSelector(({ auth }) => auth);
  const [companyNewParams, setCompanyNewParams] = useState();
  const {
    success: editCompanySuccess,
    loading: editCompanyLoading,
    failure: editCompanyFailure,
  } = useSelector(({ company }) => company.editSpecificCompany);
  const {
    url,
    loading: uploadLoading,
    success: uploadSuccess,
    failure: uploadFailure,
  } = useSelector(({ files }) => files.upload);
  const uploadLogo = (logo) => {
    const formData = new FormData();
    formData.append("file", logo, logo.name);
    dispatch(filesActions.uploadFile({ userId, formData }));
  };
  const editCompany = (params) => {
    dispatch(
      actions.editSpecificCompany({
        ...companyData,
        ...(params || companyNewParams),
        logo: url || companyNewParams?.logo,
      })
    );
  };
  const onSubmitCompanyInfo = (params) => {
    setCompanyNewParams(params);
    const { logo: prevLogo } = companyData;
    const { logo: currLogo } = params;
    if (!!currLogo && currLogo !== prevLogo) {
      uploadLogo(currLogo);
    } else {
      editCompany(params);
    }
  };
  useEffect(() => {
    if (uploadSuccess) {
      editCompany();
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (uploadFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong! Can't upload logo",
        })
      );
      dispatch(filesActions.clearUpload());
    }
  }, [uploadFailure]);
  useEffect(() => {
    if (editCompanySuccess) {
      if (isCurrent) {
        dispatch(actions.getCompany({ id }));
      }
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Info updated",
        })
      );
      dispatch(actions.clearEdit());
      dispatch(filesActions.clearUpload());
    }
  }, [editCompanySuccess, dispatch, isCurrent, id]);
  useEffect(() => {
    if (editCompanyFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(actions.clearEdit());
      dispatch(filesActions.clearUpload());
    }
  }, [editCompanyFailure, dispatch]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ClientForm
          success={editCompanySuccess}
          title={title}
          id={id}
          isEdit
          onSubmitCompanyInfo={onSubmitCompanyInfo}
          loading={editCompanyLoading || uploadLoading}
          hasReset={hasReset}
        />
      </Grid>
    </Grid>
  );
};
