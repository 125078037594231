import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export const TopupsSummary = ({ stats }) => {
  const { totaltopups } = stats ?? {};

  return (
    <div>
      <Grid container className="bg-gray-200">
        <Grid item className="p-6 bg-gray-700" xs={12}></Grid>
        {/* <Grid item className="px-7 py-2" xs={6}>
          <Typography>S.NO.</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right"></Typography>
        </Grid>
        <Seperator className="m-0" /> */}
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>total</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">${totaltopups?.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
