import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  SIGNUP_COMPANY_DETAILS,
  SIGNUP_USER_DETAILS,
  SIGNUP_USER_TYPE,
  SIGNUP_CONFIRMATION,
} from "../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../_helpers";
import styles from "./SignUp.module.scss";

const title = {
  [SIGNUP_USER_TYPE]: "User Type",
  [SIGNUP_COMPANY_DETAILS]: "Company Details",
  [SIGNUP_USER_DETAILS]: "Login Details",
  [SIGNUP_CONFIRMATION]: "verification",
  // [SIGNUP_COMPLETED]: "Completed",
};

const desc = {
  [SIGNUP_USER_TYPE]: "Select User Type",
  [SIGNUP_COMPANY_DETAILS]: "Enter company Details",
  [SIGNUP_USER_DETAILS]: "Enter Login credentials",
  [SIGNUP_CONFIRMATION]: "Verify your email",
  // [SIGNUP_COMPLETED]: "Review and Submit",
};

export const LeftBar = ({ routesMap, children, havingIssues }) => {
  const location = useLocation();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("md"));
  const stepNumber = routesMap.findIndex(
    ({ route }) => location.pathname === route
  );
  const getStepClasses = (currStep) =>
    clsx(styles.stepNumber, {
      [styles.active]: currStep <= stepNumber,
    });
  const steps = routesMap.map(({ route }, index) => (
    <div key={route} className={styles.step}>
      <Typography variant="h5" className={getStepClasses(index)}>
        {index + 1}
      </Typography>
      <div>
        <Typography className="font-weight-bold" variant="h6">
          {title[route]}
        </Typography>
        <Typography variant="body1" className={styles.desc} noWrap>
          {desc[route]}
        </Typography>
      </div>
    </div>
  ));
  return (
    <Grid container className={styles.container}>
      <Grid item md={5} xs={12}>
        <div className={styles.leftBar}>
          <Link to="/">
            <img
              alt="logo"
              className={styles.logo}
              src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
            />
          </Link>
          <div className={styles.stepsWrapper}>
            <div className={styles.steps}>{steps}</div>

            {matchesSm && (
              <img
                className={styles.plane}
                src={toAbsoluteUrl("/media/svg/illustrations/plane.svg")}
                alt=""
              />
            )}
          </div>
        </div>
      </Grid>
      <Grid item md={7} xs={12} className={styles.content}>
        {havingIssues && (
          <span className={styles.issues}>
            Having issues?
            <Link className="text-primary font-weight-bold"> Get Help</Link>
          </span>
        )}
        {children}
      </Grid>
    </Grid>
  );
};
