import axios from "axios";
import { isNil } from "lodash";
import { LOCAL_STORAGE_TOKEN } from "../state/auth/authConstants";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
request.interceptors.request.use((config) => {
  const token = localStorage?.getItem(LOCAL_STORAGE_TOKEN);
  if (!isNil(token))
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  return config;
});

export const bookingClient = axios.create({
  baseURL: process.env.REACT_APP_BOOKING_API_URL,
});

export const communicationClient = axios.create({
  baseURL: process.env.REACT_APP_COMMUNICATION_API_URL,
});

export const accountingClient = axios.create({
  baseURL: process.env.REACT_APP_ACCOUNTING_API_URL,
});

export const searchClient = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_API_URL,
});

export const reportsClient = axios.create({
  baseURL: process.env.REACT_APP_REPORTING_API_URL,
});

export const packagesClient = axios.create({
  baseURL: process.env.REACT_APP_PACKAGE_API_URL,
});

export const client = request;
