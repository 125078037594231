import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./companyActions";
import { ORGANIZATION_TYPE_ADMIN } from "./companyConstants";

const initialState = {
  create: {
    companyId: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
    registrationType: undefined,
  },
  delete: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allCompanies: {
    loading: false,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  companySessions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editSpecificCompany: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  blockUnblock: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  companyData: {
    data: undefined,
    isAdmin: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificCompanyData: {
    data: undefined,
    subscription: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  users: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const companyReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.SET_REGISTER_COMPANY_TYPE:
        state.create.registrationType = payload.type;
        break;
      case actions.GET_COMPANY_SESSIONS.REQUESTED:
        state.companySessions.loading = true;
        break;
      case actions.GET_COMPANY_SESSIONS.SUCCEEDED:
        state.companySessions.loading = false;
        state.companySessions.success = true;
        state.companySessions.list = payload.data.reverse();
        break;
      case actions.GET_COMPANY_SESSIONS.FAILED:
        state.companySessions.loading = false;
        state.companySessions.failure = payload.error;
        break;
      case actions.GET_COMPANY_SESSIONS.CLEAR:
        state.companySessions = initialState.companySessions;
        break;
      case actions.BLOCK.REQUESTED:
      case actions.UNBLOCK.REQUESTED:
        state.blockUnblock.loading = true;
        break;
      case actions.BLOCK.SUCCEEDED:
      case actions.UNBLOCK.SUCCEEDED:
        state.blockUnblock.loading = false;
        state.blockUnblock.success = true;
        const index = state.allCompanies.list.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.allCompanies.list[index] = payload.data;
        break;
      case actions.BLOCK.FAILED:
      case actions.UNBLOCK.FAILED:
        state.blockUnblock.loading = false;
        state.blockUnblock.failure = payload.error;
        break;
      case actions.BLOCK.CLEAR:
      case actions.UNBLOCK.CLEAR:
        state.blockUnblock = initialState.blockUnblock;
        break;
      case actions.CREATE.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        state.create.companyId = payload.data.id;
        break;
      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.CREATE.CLEAR:
        state.create.loading = undefined;
        state.create.failure = undefined;
        state.create.success = undefined;
        break;
      case actions.DELETE.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.delete.companyId = payload.data.id;
        break;
      case actions.DELETE.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        state.companyData.data = payload.data;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT_SPECIFIC_COMPANY.REQUESTED:
        state.editSpecificCompany.loading = true;
        break;
      case actions.EDIT_SPECIFIC_COMPANY.SUCCEEDED:
        state.editSpecificCompany.loading = false;
        state.editSpecificCompany.success = true;
        state.specificCompanyData.data = payload.data;
        break;
      case actions.EDIT_SPECIFIC_COMPANY.FAILED:
        state.editSpecificCompany.loading = false;
        state.editSpecificCompany.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.editSpecificCompany = initialState.editSpecificCompany;
        state.edit = initialState.edit;
        break;
      case actions.GET_COMPANY.REQUESTED:
        state.companyData.loading = true;
        break;
      case actions.GET_COMPANY.SUCCEEDED:
        state.companyData.loading = false;
        state.companyData.success = true;
        const companyData = payload.data?.company || payload.data;
        state.companyData.isAdmin =
          companyData.organisationTypeId === ORGANIZATION_TYPE_ADMIN;
        state.companyData.data = companyData;
        state.companyData.subscription = payload.data?.subscription;

        break;
      case actions.GET_COMPANY.FAILED:
        state.companyData.loading = false;
        state.companyData.failure = payload.error;
        break;
      case actions.FILTER_ALL_COMPANIES.REQUESTED:
        state.allCompanies.loading = true;
        break;
      case actions.FILTER_ALL_COMPANIES.SUCCEEDED:
        state.allCompanies.loading = false;
        state.allCompanies.success = true;
        const { data, ...rest } = payload.data;
        state.allCompanies.list = data;
        state.allCompanies.paginationInfo = rest;
        break;
      case actions.FILTER_ALL_COMPANIES.FAILED:
        state.allCompanies.loading = false;
        state.allCompanies.failure = payload.error;
        break;
      case actions.FILTER_ALL_COMPANIES.CLEAR:
        state.allCompanies = initialState.allCompanies;
        break;
      case actions.GET_ALL_COMPANY.REQUESTED:
        state.allCompanies.loading = true;
        break;
      case actions.GET_ALL_COMPANY.SUCCEEDED:
        state.allCompanies.loading = false;
        state.allCompanies.success = true;
        state.allCompanies.list = payload.data;
        break;
      case actions.GET_ALL_COMPANY.FAILED:
        state.allCompanies.loading = false;
        state.allCompanies.failure = payload.error;
        break;
      case actions.GET_ALL_COMPANY.CLEAR:
        state.allCompanies = initialState.allCompanies;
        break;
      case actions.GET_COMPANY_USERS.REQUESTED:
        state.users.loading = true;
        break;
      case actions.GET_COMPANY_USERS.SUCCEEDED:
        state.users.loading = false;
        state.users.success = true;
        state.users.list = payload.data.reverse();
        break;
      case actions.GET_COMPANY_USERS.FAILED:
        state.users.loading = false;
        state.users.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_COMPANY.REQUESTED:
        state.specificCompanyData.loading = true;
        break;
      case actions.GET_SPECIFIC_COMPANY.SUCCEEDED:
        state.specificCompanyData.loading = false;
        state.specificCompanyData.success = true;
        const specificCompanyData = payload.data?.company || payload.data;

        state.specificCompanyData.data = specificCompanyData;
        state.specificCompanyData.subscription = payload.data?.subscription;
        break;
      case actions.GET_SPECIFIC_COMPANY.FAILED:
        state.specificCompanyData.loading = false;
        state.specificCompanyData.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_COMPANY.CLEAR:
        state.specificCompanyData = initialState.specificCompanyData;
        break;
      case actions.SET_NEW_PIN:
        state.companyData.data.pinNumber = payload.pin;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
