import React from "react";
import { Route, Switch } from "react-router-dom";
import { SalesDashboard } from "../../../_metronic/layout/components/admin/accounting/salesDashboard/SalesDashboard";
import { SalesGraph } from "../../../_metronic/layout/components/admin/accounting/salesDashboard/SalesGraph";
import {
  ADMIN_ACCOUNTING_SALES,
  ADMIN_ACCOUNTING_SALES_GRAPH,
} from "../../routesMapAdmin";

export const SalesDashboardPage = () => {
  return (
    <Switch>
      <Route exact path={ADMIN_ACCOUNTING_SALES_GRAPH} component={SalesGraph} />
      <Route exact path={ADMIN_ACCOUNTING_SALES} component={SalesDashboard} />
    </Switch>
  );
};
