import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./accountBalanceActions";

const initialState = {
  balance: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allAccounts: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  transactionsHistory: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  updateCompanyBalance: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const accountBalanceReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.UPDATE_COMPANY_BALANCE.REQUESTED:
        state.updateCompanyBalance.loading = true;
        break;
      case actions.UPDATE_COMPANY_BALANCE.SUCCEEDED:
        state.updateCompanyBalance.loading = false;
        state.updateCompanyBalance.success = true;
        break;
      case actions.UPDATE_COMPANY_BALANCE.FAILED:
        state.updateCompanyBalance.loading = false;
        state.updateCompanyBalance.failure = payload.error.message;
        break;
      case actions.UPDATE_COMPANY_BALANCE.CLEAR:
        state.updateCompanyBalance = initialState.updateCompanyBalance;
        break;
      case actions.GET_ACCOUNT_BALANCE.REQUESTED:
        state.balance.loading = true;
        break;
      case actions.GET_ACCOUNT_BALANCE.SUCCEEDED:
        state.balance.loading = false;
        state.balance.success = true;
        const { availableBalance, ...rest } = payload.data[0];
        state.balance.data = {
          ...rest,
          availableBalance: Number(availableBalance).toFixed(2),
        };
        break;
      case actions.GET_ACCOUNT_BALANCE.FAILED:
        state.transactionsHistory.loading = false;
        state.transactionsHistory.failure = payload.error;
        break;
      case actions.GET_TRANSACTIONS_HISTORY.REQUESTED:
        state.transactionsHistory.loading = true;
        break;
      case actions.GET_TRANSACTIONS_HISTORY.SUCCEEDED:
        state.transactionsHistory.loading = false;
        state.transactionsHistory.success = true;
        state.transactionsHistory.list = payload.data;
        break;
      case actions.GET_TRANSACTIONS_HISTORY.FAILED:
        state.transactionsHistory.loading = false;
        state.transactionsHistory.failure = payload.error;
        break;
      case actions.GET_ALL_TRANSACTIONS_HISTORY.REQUESTED:
        state.transactionsHistory.loading = true;
        break;
      case actions.GET_ALL_TRANSACTIONS_HISTORY.SUCCEEDED:
        state.transactionsHistory.loading = false;
        state.transactionsHistory.success = true;
        const { data, ...paginationInfo } = payload.data;
        state.transactionsHistory.list = data;
        state.transactionsHistory.paginationInfo = paginationInfo;
        break;
      case actions.GET_ALL_TRANSACTIONS_HISTORY.FAILED:
        state.transactionsHistory.loading = false;
        state.transactionsHistory.failure = payload.error;
        break;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
