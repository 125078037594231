import { Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { ADMIN_CLIENT_BILLING } from "../../../../../../../app/routesMapAdmin";
import { actions } from "../../../../../../../redux/state/accountBalance";
import { getDateTime } from "../../../../../../_helpers/date";
import { TransactionsTableRow } from "../clientBilling/BillingHistory";

const PaymentCompleted = "Completed";
const PaymentPending = "Pending";
const PaymentFailed = "Failed";

const paymentBadgeClassNames = {
  [PaymentCompleted]: "text-success",
  [PaymentPending]: "text-warning",
  [PaymentFailed]: "text-danger",
};
const paymentBadgeText = {
  [PaymentCompleted]: PaymentCompleted,
  [PaymentPending]: PaymentPending,
  [PaymentFailed]: PaymentFailed,
};

const TableRow = ({ data }) => {
  const { dateTime, id, amount, status } = data;
  const { date, time } = getDateTime(dateTime);

  return (
    <tr>
      <td>
        <span className="font-weight-bold">{id}</span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      <td>
        <span className="font-weight-bold">${amount}</span>
      </td>
      <td>
        <span className={paymentBadgeClassNames[status]}>
          {paymentBadgeText[status] || "-"}
        </span>
      </td>
    </tr>
  );
};

export const PaymentRecords = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { list } = useSelector(
    ({ accountBalance }) => accountBalance.transactionsHistory
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    dispatch(actions.getTransactionsHistory({ companyId: id }));
  }, [dispatch, id]);
  const tableContent = list
    ?.slice(0, 5)
    .map((item, index) => (
      <TransactionsTableRow key={`row_${index}`} data={item} />
    ));
  return (
    <Paper className="h-100 p-7">
      <div className="d-flex justify-content-between">
        <Typography variant="h6">Payment Records</Typography>
        <HashLink
          to={parseRoute(`${ADMIN_CLIENT_BILLING}#billingHistory`, { id })}
          className="btn btn-primary"
        >
          View All
        </HashLink>
      </div>
      <div className="table-responsive">
        <table className="table table-vertical-center mb-0 mt-5">
          <thead>
            <tr className="bg-gray-100">
              <th>Booking Id</th>
              {isAdmin && matchesSm ? <th>Created By</th> : null}
              <th>Date</th>
              {matchesSm && <th>remark</th>}
              <th>Status</th>
              <th>Amount</th>
              {matchesSm && <th>Available balance</th>}
              {matchesSm && <th>Payable balance</th>}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
        {list?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No Records to show
          </Typography>
        )}
      </div>
    </Paper>
  );
};
