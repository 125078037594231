import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { PromoCodeForm } from "./PromoCodeForm";
import { PromoCodeRow } from "./PromoCodeRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { useCallback } from "react";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

const feesSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  code: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Only letters and numbers"),
  durationType: Yup.string().required("Required"),
  appliedOn: Yup.string().required("Required"),
  validTo: Yup.string().required("Required"),
});

export const PromoCodes = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);

  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ user }) => user.allPromoCodes);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ user }) => user.createPromoCode);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ user }) => user.editPromoCode);
  const fields = useFormik({
    initialValues: {
      name: "",
      amount: "",
      code: "",
      durationType: "",
      appliedOn: "",
      validTo: "",
      isPercentage: false,
      isActive: true,
    },
    validationSchema: feesSchema,
    onSubmit: ({
      name,
      amount,
      code,
      durationType,
      appliedOn,
      validTo,
      isPercentage,
      isActive,
    }) => {
      if (!!selectedId) {
        const data = getPromoCode(selectedId);
        const { $id, ...params } = data;
        // "id": 0,
        // "name": "string",
        // "code": "string",
        // "amount": 0,
        // "isPercentage": true,
        // "durationType": 1,
        // "appliedOn": 1,
        // "numOfRedemptions": 0,
        // "isUsed": true,
        // "validFrom": "2023-08-06T14:42:26.565Z",
        // "validTo": "2023-08-06T14:42:26.565Z"
        dispatch(
          actions.editPromoCode({
            ...params,
            name,
            amount,
            code,
            durationType: +durationType,
            appliedOn: +appliedOn,
            validTo: new Date(validTo).toISOString(),
            isPercentage,
            isActive,
          })
        );
      } else {
        dispatch(
          actions.createPromoCode({
            name,
            amount,
            code,
            durationType: +durationType,
            appliedOn: +appliedOn,
            validTo: new Date(validTo).toISOString(),
            isPercentage,
            validFrom: new Date().toISOString(),
            isActive,
          })
        );
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllPromoCodes());
  }, [dispatch]);
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearEditPromoCode());
    dispatch(actions.clearCreatePromoCode());
    setSelectedId(undefined);
    fields.resetForm();
  }, [dispatch, fields]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Promotion Code Added",
        })
      );
      dispatch(actions.getAllPromoCodes());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreatePromoCode());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Promotion Code Edited",
        })
      );
      dispatch(actions.getAllPromoCodes());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const onEdit = (id) => {
    setSelectedId(id);
    const {
      amount,
      validTo,
      code,
      appliedOn,
      isPercentage,
      name,
      durationType,
      isActive,
    } = getPromoCode(id);
    fields.setValues({
      name,
      amount,
      code,
      durationType,
      appliedOn,
      validTo: new Date(validTo).toISOString().split("T")[0],
      isPercentage,
      isActive,
    });
    onShowForm();
  };
  const getPromoCode = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const allPromoCodeSubPermission =
    adminPermissionsIds.payment.promoCodes.subPermissions;
  const tableContent = list?.map((item, index) => (
    <PromoCodeRow
      subPermissions={subPermissions}
      key={index}
      data={item}
      onEdit={onEdit}
    />
  ));
  const onShowForm = () => {
    setShowForm(true);
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Promotion Codes</Typography>
            <button
              hidden={
                !subPermissions.includes(allPromoCodeSubPermission.add.id)
              }
              onClick={onShowForm}
              className="btn btn-danger"
            >
              Add Promotion Code
            </button>
          </div>
          <PromoCodeForm
            onHide={hideFormModal}
            show={showForm}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Code</th>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Applied On</th>
                      <th>Duration Type</th>
                      <th>No. Redemptions</th>
                      <th>Valid Till</th>
                      <th>Is Used</th>
                      <th>Status</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No promo codes to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
