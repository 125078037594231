import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

import { SupplierUpdateHeader } from "../supplierRefund/supplierUpdate/SupplierUpdateHeader";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accounting";
import { Button, Input } from "../../../common";
import { getDateTime } from "../../../../../_helpers/date";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import moment from "moment";

const updateDueSchema = Yup.object().shape({
  dueDate: Yup.string().required("Please Enter Due Date"),
  remarks: Yup.string(),
});

export const DueDateUpdateModal = ({ show, data, onHide, onSuccess }) => {
  const dispatch = useDispatch();
  const { success, loading, failure } = useSelector(
    ({ accounting }) => accounting.updateInvoiceDueDate
  );
  const {
    invoiceNo,
    createdAt,
    bookingId,
    due,
    total,
    received,
    due_date: dueDate,
  } = data || {};
  const { date, time } = getDateTime(createdAt);

  const fields = useFormik({
    enableReinitialize: true,
    initialValues: {
      dueDate: moment(dueDate).format("YYYY-MM-DDTHH:mm"),
      remarks: "",
    },
    validationSchema: updateDueSchema,
    onSubmit: ({ dueDate, remarks }) => {
      dispatch(
        actions.updateInvoiceDueDate({
          dueDate,
          remarks,
          bookingId,
        })
      );
    },
  });
  const { getFieldProps, touched, errors, handleSubmit, resetForm } = fields;
  const handleClose = useCallback(() => {
    onHide();
    resetForm();
  }, [resetForm, onHide]);
  useEffect(() => {
    if (success) {
      dispatch(actions.clearUpdateInvoiceDueDate());
      onSuccess();
      handleClose();
    }
  }, [dispatch, handleClose, onSuccess, onHide, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearUpdateInvoiceDueDate());
    }
  }, [dispatch, handleClose, onHide, failure]);

  return (
    <Modal
      size="md"
      onHide={handleClose}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <SupplierUpdateHeader
          title={"Update Supplier Refund"}
          loading={loading}
        />
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Invoice No.</Typography>
              <Typography>{invoiceNo}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Booking ID</Typography>
              <Typography>{bookingId}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Requested Date
              </Typography>
              <Typography>{date}</Typography>
              <Typography>{time}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Received Amount
              </Typography>
              <Typography>${received}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Due Amount</Typography>
              <Typography>${due}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Total Amount</Typography>
              <Typography>${total}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                className="mt-3"
                {...getFieldProps("dueDate")}
                showErrors={touched["dueDate"]}
                errorMessage={errors["dueDate"]}
                type="datetime-local"
                variant="secondary"
                label="Due Date"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                className="mt-3"
                {...getFieldProps("remarks")}
                showErrors={touched["remarks"]}
                errorMessage={errors["remarks"]}
                variant="secondary"
                label="Remarks"
                optional
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={handleClose}
            variant="light"
          >
            Cancel
          </Button>
          <> </>
          <Button smallPadding loading={loading} type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
