import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  CARD_TYPE_VISA,
  CARD_TYPE_MASTERCARD,
  PaymentMethodCard,
} from "./PaymentMethodCard";
import { Button } from "../../common";
import { Modal } from "react-bootstrap";

const InfoElement = ({ title, value, color }) => (
  <div className="d-flex justify-content-between mb-2">
    <span>{title}</span>
    <span className={`text-${color || "primary"} font-weight-bold`}>
      {value}
    </span>
  </div>
);

export const CardInfo = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <InfoElement title="Card hoder Name" value="Ahmed Adel Mohamed" />
        <InfoElement title="Number" value="**** 8856" />
        <InfoElement title="Expires" value="08/2024" />
        <InfoElement title="Type" value="Visa Card" />
        <InfoElement title="User ID" value="Ahmed2456" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <InfoElement title="Billing Adress" value="Canada" />
        <InfoElement title="Phone" value="01 05196549" />
        <InfoElement title="Origin" value="USA" />
        <InfoElement color="danger" title="CVC Check" value="Faild" />
        <InfoElement title="Address" value="Toronto A15 B2" />
      </Grid>
    </Grid>
  );
};

const PaymentMethodForm = ({ show, onHide }) => {
  return <Modal onHide={onHide} show={show} size="md"></Modal>;
};

export const PaymentMethods = () => {
  const [showCreateModal, setShowCreateModal] = useState();
  return (
    <Paper className="p-7 bg-white">
      <PaymentMethodForm
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Typography variant="h5" className="font-weight-bold">
          Payment Methods
        </Typography>
        <Button variant="danger">add payment method</Button>
      </div>
      <ExpansionPanel className="shadow-none">
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <PaymentMethodCard primary cardType={CARD_TYPE_VISA} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CardInfo />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className="shadow-none">
        <ExpansionPanelSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <PaymentMethodCard cardType={CARD_TYPE_MASTERCARD} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CardInfo />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};
