import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./filesActions";

const initialState = {
  upload: {
    url: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const filesReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.UPLOAD_FILE.REQUESTED:
        state.upload.loading = true;
        break;
      case actions.UPLOAD_FILE.SUCCEEDED:
        state.upload.loading = false;
        state.upload.success = true;
        state.upload.url = payload.data;
        break;
      case actions.UPLOAD_FILE.FAILED:
        state.upload.loading = false;
        state.upload.failure = payload.error;
        break;
      case actions.UPLOAD_FILE.CLEAR:
        state.upload = initialState.upload;
        break;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
