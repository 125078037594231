import {
  startOfWeek,
  endOfWeek,
  differenceInHours,
  formatDistanceToNow,
} from "date-fns";

export const getTimeStamp = (date) => {
  if (differenceInHours(new Date(), new Date(date)) >= 24) {
    return new Date(date).toDateString();
  } else {
    return `${formatDistanceToNow(
      new Date(date.includes("Z") ? date : `${date}Z`)
    )} Ago`;
  }
};

export const getToday = () => {
  const todayDate = new Date();
  const dd = String(todayDate.getDate()).padStart(2, "0");
  const mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = todayDate.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

const formatDateToISO = (date) => {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

const getLocalTime = (date) => {
  return new Date(
    date.getTime() + date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const getPastDaysRange = (days) => {
  let firstDay = new Date();
  let lastDay = new Date();
  firstDay.setUTCHours(0, 0, 0, 0);
  lastDay.setUTCHours(23, 59, 59, 999);

  firstDay.setDate(lastDay.getDate() - days);

  firstDay = formatDateToISO(firstDay);
  lastDay = formatDateToISO(lastDay);
  return { lastDay, firstDay };
};

export const getCurrWeekRange = () => {
  let firstDay = startOfWeek(new Date(), {
    weekStartsOn: 6,
  });
  firstDay = formatDateToISO(firstDay);

  let lastDay = endOfWeek(new Date(), { weekStartsOn: 6 });
  lastDay = formatDateToISO(lastDay);
  return { firstDay, lastDay };
};

export const getTomorrow = (date) => {
  const tomorrowDate = new Date(date ?? Date());
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const dd = String(tomorrowDate.getDate()).padStart(2, "0");
  const mm = String(tomorrowDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = tomorrowDate.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const getDateTime = (date) => {
  if (!date) return { date: undefined, time: undefined };
  let dateArr = date.split(".");
  dateArr = dateArr[0].replace(" ", "T").split("T");
  return { date: dateArr[0], time: dateArr[1] };
};

export const calcAge = (dateString) => {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
