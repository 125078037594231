import { Paper, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/invoices";
import { Seperator } from "../../../common";
import { ReportsSearchCard } from "../../reports/common";
import { SalesSummary } from "../../reports/salesReport/SalesSummary";
import { InvoicingTable } from "./InvoicingTable";

export const Invoicing = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const { stats, paginationInfo } = useSelector(
    ({ invoices }) => invoices.invoices
  );
  const { totalPages } = paginationInfo ?? {};
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { fromDate, toDate } = dateRange;
  useEffect(() => {
    dispatch(
      actions.filterInvoices({ pageNumber, filterParam: [...appliedFilters] })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterInvoices({
        filterParam: [...filters],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.filterInvoices({ pageNumber: 1 }));
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <ReportsSearchCard
            isInvoicing
            setDateRange={setDateRange}
            onSearch={search}
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex flex-column">
          <Typography variant="h6" className="font-weight-bold mb-2">
            Invoicing
          </Typography>
          {fromDate && toDate && (
            <Typography>
              Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          <Seperator />
          <SalesSummary stats={stats} />
          <Seperator />

          <InvoicingTable />
          <Pagination
            className="mx-auto mt-2"
            onChange={handlePageChange}
            page={pageNumber}
            count={totalPages}
            variant="outlined"
            shape="rounded"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
