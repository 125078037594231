/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../_helpers";
import { headerLinks } from "../public/DesktopHeader";

export const Sidebar = () => {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div className="header-menu-wrapper header-menu-wrapper-right header-menu-wrapper-on">
      <div
        id="kt_header_menu"
        className="header-menu header-menu-mobile header-menu-layout-default"
      >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav `}>
          {headerLinks.map((link, index) => (
            <li
              key={index}
              className={`menu-item menu-item-rel ${getMenuItemActive(
                link.path
              )}`}
            >
              <NavLink className="menu-link" to={link.path}>
                <span className="menu-text">{link.label}</span>
                {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
              </NavLink>
            </li>
          ))}
          {/* <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="">
              <span className="menu-text">Flights</span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="">
              <span className="menu-text">Hotels</span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="">
              <span className="menu-text">Umrah Package</span>
            </NavLink>
          </li> */}
        </ul>
        {/*end::Header Nav*/}
      </div>
    </div>
  );
};
