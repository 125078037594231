import React, { useEffect, useState } from "react";
import { Grid, Paper, Tab } from "@material-ui/core";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { actions } from "../../../../../../../redux/state/user";
import { ADMIN_STAFF } from "../../../../../../../app/routesMapAdmin";
import { CreateSubUserForm } from "../../../../account/accountSubUser/CreateSubUserForm";
import { AntTabs } from "../../../../account/accountSubUser/SubUser";

export const CreateStaffMember = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyInUserId } = useSelector(({ user }) => user.userData || {});
  const { success: createSuccess } = useSelector(({ user }) => user.create);

  const subUserSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .required("No password provided")
      .min(6, "Password is too short - should be 8 chars minimum"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    name: Yup.string().required(),
    phone: Yup.number().required(),
  });
  const formik = useFormik({
    initialValues: {
      companyId: companyInUserId,
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      phone: "",
      role: "",
    },
    validationSchema: subUserSchema,
    onSubmit: ({ email, password, companyId, name, phone, role }) =>
      dispatch(
        actions.createUser({
          email,
          password,
          companyInUserId: companyId,
          name,
          phone,
          roleId: +role,
          isDeleted: false,
        })
      ),
  });
  useEffect(() => {
    if (createSuccess && formik.isValid) formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (createSuccess) {
      // setSelectedTab(1);
      history.push(ADMIN_STAFF);
    }
  }, [createSuccess, history]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Paper className="bg-white h-100">
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
          >
            <Tab label="Create a staff member" />
            {/* <Tab label="Give Permission" /> */}
          </AntTabs>
          <div className="p-7">
            {selectedTab === 0 && <CreateSubUserForm formik={formik} />}
            {/* {selectedTab === 1 && <GivePermissions userId={userId} />} */}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
