import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Modal } from "react-bootstrap";
import { Button, Input } from "../../../common";

export const AcceptVoidModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector(({ bookingMng }) => bookingMng.specificVoid);
  const {
    loading: acceptVoidLoading,
    success: acceptVoidSuccess,
    failure: acceptVoidFailure,
  } = useSelector(({ bookingMng }) => bookingMng.void);
  const formik = useFormik({
    initialValues: {
      fee: "",
    },
    validationSchema: Yup.object().shape({
      fee: Yup.number().required("Fee is required"),
    }),
    onSubmit: ({ fee }) => {
      dispatch(actions.acceptVoidManually({ id: data.cancelToken, fee }));
    },
  });
  useEffect(() => {
    if (acceptVoidSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Void is accepted successfully!",
        })
      );
      dispatch(actions.clearVoid());
      onHide();
      dispatch(actions.getSpecificVoid({ id: data.id }));
    }
  }, [acceptVoidSuccess, data, dispatch, history, onHide]);

  useEffect(() => {
    if (acceptVoidFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: acceptVoidFailure || "Something is wrong",
        })
      );
      dispatch(actions.clearVoid());
    }
  }, [acceptVoidFailure, dispatch]);
  const { touched, errors, handleSubmit, getFieldProps } = formik;
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <form onSubmit={handleSubmit}>
          <Modal.Header>Accept Void</Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Input
              {...getFieldProps("fee")}
              showErrors={touched.fee}
              errorMessage={errors.fee}
              label="Fee"
              placeholder="Fee"
              spacing
            />
          </Modal.Body>
          <Modal.Footer>
            <Button smallPadding type="button" onClick={onHide} variant="light">
              Cancel
            </Button>
            <> </>
            <Button
              smallPadding
              type="submit"
              loading={acceptVoidLoading}
              // onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </>
    </Modal>
  );
};
