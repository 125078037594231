export const ADMIN_DASHBOARD = "/admin/dashboard";

export const ADMIN_CLIENTS = "/admin/client";
export const ADMIN_ALL_SUBUSERS = "/admin/client/subusers";
export const ADMIN_AGENCIES = "/admin/client/agencies";
export const ADMIN_CREATE_CLIENT = "/admin/client/agencies/create";
export const ADMIN_CLIENT_DETAILS = "/admin/client/agencies/:id";
export const ADMIN_CLIENT_VIEW = "/admin/client/agencies/:id/view";
export const ADMIN_CLIENT_SETTINGS = "/admin/client/agencies/:id/settings";
export const ADMIN_CLIENT_BILLING = "/admin/client/agencies/:id/billing";
export const ADMIN_CLIENT_SECURITY = "/admin/client/agencies/:id/security";
export const ADMIN_CLIENT_DOCUMENTS = "/admin/client/agencies/:id/documents";
export const ADMIN_CLIENT_USERS = "/admin/client/agencies/:id/users";
export const ADMIN_CLIENT_USERS_CREATE =
  "/admin/client/agencies/:id/users/create";
export const ADMIN_CLIENT_USER_PERMISSION =
  "/admin/client/agencies/:id/users/:userId/permissions";
export const ADMIN_PAYMENTS = "/admin/payments";
export const ADMIN_PAYMENTS_TOP_UPS = "/admin/payments/topUps";
export const ADMIN_PAYMENTS_TOP_UP_CLEARED_INVOICES =
  "/admin/payments/topUps/:id/clearedInvoices";
export const ADMIN_PAYMENTS_CREDIT = "/admin/payments/credit";
export const ADMIN_PAYMENTS_BANKS = "/admin/payments/banks";
export const ADMIN_PAYMENTS_BANK_ACCOUNTS = "/admin/payments/bankAccounts";
export const ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY =
  "/admin/payments/bankAccounts/:id/history";
export const ADMIN_PAYMENTS_PAYMENT_METHODS = "/admin/payments/paymentMethods";
export const ADMIN_PAYMENTS_PROMO_CODES = "/admin/payments/promoCodes";
export const ADMIN_PAYMENTS_PAYMENT_BALANCES =
  "/admin/payments/paymentBalances";
export const ADMIN_PAYMENTS_PAYMENT_BALANCE_LOG =
  "/admin/payments/paymentBalances/:id/log";

// export const ADMIN_BOOKING_MANAGEMENT = "/admin/bookingManagement";
// export const ADMIN_BOOKING_MANAGEMENT_TRACKING =
//   "/admin/bookingManagement/tracking";
export const ADMIN_COMPLETED_ORDER_DETAILS =
  "/bookingManagement/tracking/order/:id/completed";
export const ADMIN_COMPLETED_ORDER_SUMMARY =
  "/bookingManagement/tracking/order/:id/completed/summary";
export const ADMIN_COMPLETED_ORDER_FLIGHT =
  "/bookingManagement/tracking/order/:id/completed/flight";
export const ADMIN_COMPLETED_ORDER_NOTES =
  "/bookingManagement/tracking/order/:id/completed/notes";
export const ADMIN_COMPLETED_ORDER_ACCOUNTS =
  "/bookingManagement/tracking/order/:id/completed/accounts";
// export const ADMIN_BOOKING_MANAGEMENT_REFUND =
//   "/admin/bookingManagement/refunds";
// export const ADMIN_BOOKING_MANAGEMENT_REFUND_DETAILS =
//   "/admin/bookingManagement/refund/:id";
// export const ADMIN_BOOKING_MANAGEMENT_VOID = "/admin/bookingManagement/voids";
// export const ADMIN_BOOKING_MANAGEMENT_VOID_DETAILS =
//   "/admin/bookingManagement/void/:id";
// export const ADMIN_BOOKING_MANAGEMENT_REISSUE =
//   "/admin/bookingManagement/reissues";
// export const ADMIN_BOOKING_MANAGEMENT_REISSUE_DETAILS =
//   "/admin/bookingManagement/reissue/:id";
// export const ADMIN_BOOKING_MANAGEMENT_SEARCH =
//   "/admin/bookingManagement/search";
// export const ADMIN_ISSUE_BOOKING = "/admin/bookingManagement/issueBooking/:id";
export const ADMIN_BOOKING_MANAGEMENT_QUEUE = "/bookingManagement/queue";
export const BOOKING_MANAGEMENT_REISSUE_ACCEPT =
  "/bookingManagement/reissue/:id/accept";

export const ADMIN_PACKAGES = "/admin/packages";
export const ADMIN_PACKAGES_INVENTORY = "/admin/packages/inventory";
export const ADMIN_PACKAGES_CREATE_INVENTORY =
  "/admin/packages/inventory/create";
export const ADMIN_PACKAGES_CITIES = "/admin/packages/cities";
export const ADMIN_PACKAGES_INCLUSIONS = "/admin/packages/inclusions";
export const ADMIN_PACKAGES_PKG_SEASON = "/admin/packages/seasons";
export const ADMIN_PACKAGES_PKG_TYPE = "/admin/packages/type";
export const ADMIN_PACKAGES_PKG_ROOM_TYPES = "/admin/packages/roomTypes";
export const ADMIN_PACKAGES_PKG_CLASSES = "/admin/packages/classes";

export const ADMIN_SYSTEM_MANAGEMENT = "/admin/systemManagement";
export const ADMIN_MARKUPS = "/admin/systemManagement/markups";
export const ADMIN_MARKUP_EDIT = "/admin/systemManagement/markups/:id/edit";
export const ADMIN_TEMPLATES = "/admin/systemManagement/templates";
export const ADMIN_PAGES = "/admin/systemManagement/pages";
export const ADMIN_POSTS = "/admin/systemManagement/posts";
export const ADMIN_AUDITS = "/admin/systemManagement/audits";
export const ADMIN_NOTIFICATIONS = "/admin/systemManagement/notifications";
export const ADMIN_NOTIFICATION_DETAILS =
  "/admin/systemManagement/notifications/details/:id";
export const ADMIN_NOTIFICATIONS_ACCESS =
  "/admin/systemManagement/notifications/access";
export const ADMIN_FEES = "/admin/systemManagement/fees";
export const ADMIN_FEE_LOGS = "/admin/systemManagement/fees/logs/:type";

export const ADMIN_FLYME_PROFILE = "/admin/systemManagement/flyme";
export const ADMIN_FLYME_PROFILE_VIEW = "/admin/systemManagement/flyme/view";
export const ADMIN_FLYME_PROFILE_SETTINGS =
  "/admin/systemManagement/flyme/settings";
export const ADMIN_FLYME_PROFILE_SECURITY =
  "/admin/systemManagement/flyme/security";
export const ADMIN_SESSIONS = "/admin/systemManagement/sessions/:type/:id"; //0 user/ 1 company
export const ADMIN_FLYME_PROFILE_DOCUMENTS =
  "/admin/systemManagement/flyme/documents";
export const ADMIN_FLYME_PROFILE_BILLING =
  "/admin/systemManagement/flyme/billing";

export const ADMIN_STAFF = "/admin/systemManagement/staff";
export const ADMIN_STAFF_ROLES = "/admin/systemManagement/roles";
export const ADMIN_STAFF_ROLE_DETAILS =
  "/admin/systemManagement/roles/:id/details";
export const ADMIN_STAFF_ROLE_PERMISSIONS =
  "/admin/systemManagement/roles/:id/permissions";
export const ADMIN_STAFF_CREATE = "/admin/systemManagement/staff/create";
export const ADMIN_STAFF_MEMBER = "/admin/systemManagement/staff/member/:id";
export const ADMIN_STAFF_VIEW =
  "/admin/systemManagement/staff/member/:id/overview";
export const ADMIN_STAFF_PERMISSIONS =
  "/admin/systemManagement/staff/member/:id/permissions";
export const ADMIN_STAFF_SETTINGS =
  "/admin/systemManagement/staff/member/:id/settings";
export const ADMIN_STAFF_SECURITY =
  "/admin/systemManagement/staff/member/:id/security";
export const ADMIN_STAFF_DOCUMENTS =
  "/admin/systemManagement/staff/member/:id/documents";

export const ADMIN_CREATE_TEMPLATE = "/admin/systemManagement/templates/create";
export const ADMIN_VIEW_TEMPLATE = "/admin/systemManagement/templates/view/:id";
export const ADMIN_EDIT_TEMPLATE = "/admin/systemManagement/templates/edit/:id";
export const ADMIN_SUPPLIERS = "/admin/systemManagement/suppliers";
export const ADMIN_CREATE_SUPPLIER = "/admin/systemManagement/suppliers/create";
export const ADMIN_EDIT_SUPPLIER = "/admin/systemManagement/suppliers/:id/edit";
export const ADMIN_SUPPLIER_BALANCE_HISTORY =
  "/admin/systemManagement/suppliers/:id/balanceHistory";

export const ADMIN_ACCOUNTING = "/admin/accounting";
export const ADMIN_ACCOUNTING_RECEIVABLE_DUE =
  "/admin/accounting/receivableDue";
export const ADMIN_ACCOUNTING_PAYABLE_DUE = "/admin/accounting/payableDue";
export const ADMIN_ACCOUNTING_STATEMENTS = "/admin/accounting/statements";
export const ADMIN_ACCOUNTING_CASHFLOW = "/admin/accounting/cashflow";
export const ADMIN_ACCOUNTING_EXPENSES = "/admin/accounting/expenses";
export const ADMIN_ACCOUNTING_CREATE_EXPENSES =
  "/admin/accounting/expenses/create";
export const ADMIN_ACCOUNTING_INVOICING = "/admin/accounting/invoicing";
export const ADMIN_ACCOUNTING_ACCOUNT_TYPES = "/admin/accounting/accountTypes";
export const ADMIN_ACCOUNTING_CHART_OF_ACCOUNTS =
  "/admin/accounting/accountChart";
export const ADMIN_ACCOUNTING_BANK_RECONCILIATION =
  "/admin/accounting/bankReconciliation";
export const ADMIN_ACCOUNTING_PAID_SUPPLIERS =
  "/admin/accounting/paidSuppliers";
export const ADMIN_ACCOUNTING_SUPPLIER_REFUND =
  "/admin/accounting/supplierRefund";
export const ADMIN_ACCOUNTING_CLIENT_REFUND = "/admin/accounting/clientRefund";
export const ADMIN_ACCOUNTING_CURRENCIES = "/admin/accounting/currencies";
export const ADMIN_ACCOUNTING_BALANCE_SUMMARY =
  "/admin/accounting/balanceSummary";
export const ADMIN_ACCOUNTING_SALES = "/admin/accounting/sales";
export const ADMIN_ACCOUNTING_SALES_GRAPH = "/admin/accounting/sales/graph/:id";
export const ADMIN_ACCOUNTING_OPERATION = "/admin/accounting/operation";
export const ADMIN_ACCOUNTING_OPERATION_AGENCY =
  "/admin/accounting/operation/agency/:id";
export const ADMIN_ACCOUNTING_OPERATION_ROUTE =
  "/admin/accounting/operation/agency/:agencyId/route/:fromCode/:toCode";

export const ADMIN_FARE_SEARCH = "/admin/fareSearch";
export const ADMIN_FLIGHT_SEARCH = "/admin/fareSearch/flightSearch";
export const ADMIN_PNR_SEARCH = "/admin/fareSearch/pnrSearch";
export const ADMIN_FLIGHT = "/admin/fareSearch/flightSearch/flight/:id";
export const ADMIN_FLIGHT_DETAILS =
  "/admin/fareSearch/flightSearch/flightDetails/:id";
export const ADMIN_TRAVELLER_DETAILS =
  "/admin/fareSearch/flightSearch/travellerDetails/:id";
export const ADMIN_COMPLETE_ORDER =
  "/admin/fareSearch/flightSearch/completeOrder/:id";
export const ADMIN_ORDER_CONFIRMED =
  "/admin/fareSearch/flightSearch/confirmed/:type";

export const ADMIN_REPORTS = "/admin/reports";
export const ADMIN_REPORTS_MARGIN = "/admin/reports/margin";
export const ADMIN_REPORTS_FEES = "/admin/reports/fees";
export const ADMIN_REPORTS_CANCELLED = "/admin/reports/cancelled";
export const ADMIN_REPORTS_BANK_ACCOUNTS = "/admin/reports/bank";
export const ADMIN_REPORTS_TOP_UP = "/admin/reports/topup";
export const ADMIN_REPORTS_SALES = "/admin/reports/sales";
export const ADMIN_REPORTS_PURCHASE = "/admin/reports/purchase";
export const ADMIN_REPORTS_DUE = "/admin/reports/due";

export const ADMIN_SUPPORT_CENTER = "/admin/support";
