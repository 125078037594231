/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_helpers";
import { GoBack, LoadingSpinner } from "../../../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../../redux/state/user";
import { getDateTime } from "../../../../../../_helpers/date";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { NavLink, useParams } from "react-router-dom";
import { ADMIN_STAFF_MEMBER } from "../../../../../../../app/routesMapAdmin";
import { adminPermissionsIds } from "../../../../../../../data/adminPermissionIds";
import useSubPermissions from "../../../../../../../app/helpers/hooks/useSubPermissions";
import { Grid, Paper, Typography } from "@material-ui/core";

export const RoleDetails = ({ className, permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const { id } = useParams();
  const { loading, list } = useSelector(({ user }) => user.usersByRole);
  const { data } = useSelector(({ user }) => user.role);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getUsersByRole({ id }));
    dispatch(actions.getRole({ id }));
  }, [id, dispatch]);
  const tableContent = list?.map((item, index) => (
    <TableRow
      subPermissions={subPermissions}
      key={`row_${index}`}
      data={item}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex align-items-center justify-content-between">
          <GoBack />
          <Typography variant="h5">{data?.name}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <div className={`card card-custom ${className}`}>
          <div className="card-header d-flex align-items-center border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Users Assigned
              </span>
            </h3>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <thead>
                        <tr className="text-left text-uppercase">
                          <th className="pl-7" style={{ minWidth: "150px" }}>
                            <span className="text-dark-75">Name</span>
                          </th>
                          <th style={{ minWidth: "100px" }}>Email</th>
                          <th style={{ minWidth: "100px" }}>Created Date</th>
                          <th
                            style={{ minWidth: "80px" }}
                            className="text-right"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>

                      <tbody>{tableContent}</tbody>
                    </table>
                  </div>
                  {list?.length === 0 && (
                    <Typography
                      variant="h5"
                      className="text-center w-100 text-muted my-5"
                    >
                      No Assigned users to show
                    </Typography>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const StaffDropdown = ({ openModal, userId, subPermissions }) => {
  const allStaffSubPermission =
    adminPermissionsIds.systemManagement.flymeStaff.subPermissions;
  return (
    <div className="card-toolbar">
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu
          // positionFixed
          // popperConfig={{
          //   strategy: "fixed",
          // }}
          className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
          placement={"bottom"}
        >
          <ul className="navi navi-hover">
            <li
              hidden={
                !subPermissions.includes(allStaffSubPermission.viewUser.id)
              }
              className="navi-item"
            >
              <NavLink
                to={parseRoute(ADMIN_STAFF_MEMBER, { id: userId })}
                className="navi-link"
              >
                <span className="navi-text">View user</span>
              </NavLink>
            </li>
            {/* <li className="navi-item">
              <NavLink
                to={parseRoute(ADMIN_STAFF_PERMISSIONS, { id: userId })}
                className="navi-link"
              >
                <span className="navi-text">Edit Permissions</span>
              </NavLink>
            </li> */}
            {/* <li  className="navi-item">
              <button
                onClick={openModal}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Allocate Funds</span>
              </button>
            </li> */}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const TableRow = ({ data, openModal, subPermissions }) => {
  const { email, name, createdAt, id } = data;
  const { date, time } = getDateTime(createdAt);
  return (
    <tr>
      <td className="pl-0">
        <NavLink
          to={parseRoute(ADMIN_STAFF_MEMBER, { id })}
          className="navi-link"
        >
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
              <span className="symbol-label">
                <span className="svg-icon h-75 align-self-end">
                  <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
                </span>
              </span>
            </div>
            <div>
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                {name}
              </a>
            </div>
          </div>
        </NavLink>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {email}
        </span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      {/* <td>
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {companyName}
        </span>
        <span className="text-muted font-weight">{roleInUserId || "-"}</span>
      </td> */}
      <td className="pr-0 text-right">
        <StaffDropdown
          subPermissions={subPermissions}
          userId={id}
          openModal={openModal}
        />
      </td>
    </tr>
  );
};
