/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { routesPermissionMap } from "../../../../../app/AgencyRoutes";
import clsx from "clsx";
import { adminRoutesPermissionMap } from "../../../../../app/AdminRoutes";
import { usePermissionIds } from "../../../../../app/helpers/hooks/usePermissionIds";
import {
  ORGANIZATION_TYPE_AGENCY,
  ORGANIZATION_TYPE_SUBSCRIBER,
} from "../../../../../redux/state/company/companyConstants";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { isAdmin } = useSelector(({ company }) => company.companyData);
  return (
    <>
      {/* begin::Menu Nav */}
      {/* {organizationType === 0 && <AgencyMenu />} */}
      {isAdmin ? (
        <AdminMenu
          getMenuItemActive={getMenuItemActive}
          layoutProps={layoutProps}
        />
      ) : (
        <AgencyMenu
          getMenuItemActive={getMenuItemActive}
          layoutProps={layoutProps}
        />
      )}
    </>
  );
}

const AdminMenu = ({ getMenuItemActive, layoutProps }) => {
  const { getPermissionObj } = usePermissionIds();
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const permissionIdsList = list.map(({ permissionId }) => permissionId);
  return (
    <ul className={`menu-nav mb-12 ${layoutProps.ulClasses}`}>
      {Object.keys(adminRoutesPermissionMap).map((key) => {
        const {
          mainRoute,
          icon,
          items,
          withSubMenu,
        } = adminRoutesPermissionMap[key];
        const hasSubMenu = items.length > 1 || withSubMenu;
        const showSubMenu = !!items.filter(({ permissionKey }) => {
          const { id } = getPermissionObj(permissionKey);
          return permissionIdsList.includes(id) || id === 0;
        }).length;
        return (
          <li
            key={mainRoute}
            className={clsx(
              "menu-item",
              getMenuItemActive(mainRoute, hasSubMenu),
              {
                "menu-item-submenu": hasSubMenu,
              }
            )}
            aria-haspopup="true"
            hidden={!showSubMenu}
          >
            <NavLink
              className={clsx("menu-link menu-toggle", {
                "menu-toggle": hasSubMenu,
              })}
              to={mainRoute}
            >
              <span className="svg-icon menu-icon svg-icon-lg svg-icon-white">
                <SVG src={toAbsoluteUrl(icon)} />
              </span>
              <span className="menu-text">{key}</span>
              {hasSubMenu && <i className="menu-arrow" />}
            </NavLink>
            {hasSubMenu && (
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">{key}</span>
                    </span>
                  </li>
                  {items.map(({ permissionKey, label, route }) => {
                    const { id } = getPermissionObj(permissionKey);
                    return (
                      <li
                        key={route}
                        hidden={!(permissionIdsList.includes(id) || id === 0)}
                        className={`menu-item ${getMenuItemActive(route)}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to={route}>
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">{label}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const AgencyMenu = ({ getMenuItemActive, layoutProps }) => {
  const { getPermissionObj } = usePermissionIds({ isAdminPermissions: false });
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const { organisationTypeId } = useSelector(
    ({ company }) => company.companyData.data
  );
  const permissionIdsList = list.map(({ permissionId }) => permissionId);
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {Object.keys(routesPermissionMap).map((key) => {
          const { mainRoute, icon, items, withSubMenu } = routesPermissionMap[
            key
          ];
          const hasSubMenu = items.length > 1 || withSubMenu;
          const newItems = items.filter(
            ({ agencyOnly, subscriberOnly, label }) => {
              if (
                organisationTypeId === ORGANIZATION_TYPE_SUBSCRIBER &&
                agencyOnly
              ) {
                return false;
              } else if (
                organisationTypeId === ORGANIZATION_TYPE_SUBSCRIBER &&
                subscriberOnly
              ) {
                return true;
              } else if (
                organisationTypeId === ORGANIZATION_TYPE_AGENCY &&
                subscriberOnly
              ) {
                return false;
              }
              return true;
            }
          );

          const showSubMenu = !!newItems.filter(({ permissionKey }) => {
            if (!permissionKey) return true;
            const { id } = getPermissionObj(permissionKey);
            return permissionIdsList.includes(id) || id === 0;
          }).length;
          return (
            <li
              key={mainRoute}
              className={clsx(
                "menu-item",
                getMenuItemActive(mainRoute, hasSubMenu),
                {
                  "menu-item-submenu": hasSubMenu,
                }
              )}
              aria-haspopup="true"
              hidden={!showSubMenu}
            >
              <NavLink
                className={clsx("menu-link menu-toggle", {
                  "menu-toggle": hasSubMenu,
                })}
                to={mainRoute}
              >
                <span className="svg-icon menu-icon svg-icon-lg svg-icon-white">
                  <SVG src={toAbsoluteUrl(icon)} />
                </span>
                <span className="menu-text">{key}</span>
                {hasSubMenu && <i className="menu-arrow" />}
              </NavLink>
              {hasSubMenu && (
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">{key}</span>
                      </span>
                    </li>
                    {newItems.map(({ permissionKey, label, route }) => {
                      // if (!permissionKey) return true;

                      const { id } = permissionKey
                        ? getPermissionObj(permissionKey)
                        : {};
                      return (
                        <li
                          key={route}
                          hidden={
                            !(
                              permissionIdsList.includes(id) ||
                              id === 0 ||
                              !permissionKey
                            )
                          }
                          className={`menu-item ${getMenuItemActive(route)}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to={route}>
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{label}</span>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
