import { makeStyles, Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { getDateTime, toAbsoluteUrl } from "../../../../../../_helpers";
import { LoadingSpinner } from "../../../../common";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(2),
    width: "130px",
  },
}));

const TableRow = ({ data, isAdmin, getCompanyName }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const {
    FromDate,
    ToDate,
    TripType,
    NumAdults,
    NumChild,
    NumInfant,
    Guid,
    LowestPrice,
    HighestPrice,
    TotalPax,
    CreatedDate,
  } = data;
  const { date: fromDate } = getDateTime(FromDate);
  const { date: toDate } = getDateTime(ToDate);
  const { date: createdDate } = getDateTime(CreatedDate);

  return (
    <tr>
      <td>
        <span className="font-weight-bold">{Guid || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">{createdDate || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">{fromDate || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">{toDate || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">
          ${(+LowestPrice)?.toFixed(2) || "-"}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">
          ${(+HighestPrice)?.toFixed(2) || "-"}
        </span>
      </td>
      <td>
        <span className="font-weight-bold">{TripType || "-"}</span>
      </td>
      <td>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          disableScrollLock
        >
          <div className="d-flex justify-content-between mb-5">
            <span>Adults</span>
            <span>{NumAdults}</span>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <span>Children</span>
            <span>{NumChild}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Infants</span>
            <span>{NumInfant}</span>
          </div>
        </Popover>

        <div className="d-flex w-25px justify-content-between">
          <span className="font-weight-bold">{TotalPax || "-"}</span>
          <span
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className="svg-icon svg-icon-sm svg-icon-gray ml-1"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
          </span>
        </div>
      </td>
    </tr>
  );
};

export const RouteSearches = () => {
  const { list, loading } = useSelector(
    ({ accounting }) => accounting.routeSearches
  );
  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="table-responsive">
          <table className="table table-vertical-center mb-0 mt-5">
            <thead>
              <tr className="bg-gray-100">
                <th>
                  <span>Guid</span>
                </th>
                <th>Created Date</th>
                <th>Depart Date</th>
                <th>Return Date</th>
                <th>Lowest Price</th>
                <th>Highest Price</th>
                <th>Trip Type</th>
                <th>Pax</th>
              </tr>
            </thead>
            <tbody>{tableContent}</tbody>
          </table>
          {list?.length === 0 && (
            <Typography
              variant="h5"
              className="text-center w-100 text-muted my-5"
            >
              No bookings to show
            </Typography>
          )}
        </div>
      )}
    </>
  );
};
