import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/inlineNotification";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const InlineNotification = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { type, isShow, message } = useSelector(
    (state) => state.inlineNotification
  );
  // useEffect(() => {
  //   setOpen(!!message);
  // }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(actions.reset());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isShow}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
