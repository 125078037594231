import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

export const Button = ({
  children,
  className,
  style,
  onClick,
  disabled,
  variant = "primary",
  type,
  smallPadding,
  loading,
  ...rest
}) => {
  const ref = useRef();
  const [buttonWidth, setButtonWidth] = useState();
  const [buttonHeight, setButtonHeight] = useState();
  useEffect(() => {
    if (!loading) {
      setButtonWidth(ref.current.clientWidth);
      setButtonHeight(ref.current.clientHeight);
    }
  }, [loading, ref?.current?.clientWidth]);
  const buttonClassNames = clsx(
    " btn font-weight-bold d-inline-block position-relative",
    {
      "px-5": smallPadding,
      "px-10": !smallPadding,
      "btn-primary": variant === "primary",
      "btn-light-primary": variant === "light-primary",
      "btn-secondary": variant === "secondary",
      "btn-light": variant === "light",
      "btn-danger": variant === "danger",
      "border-primary border-2 text-primary text-hover-light-primary":
        variant === "primary-inverse",
      "border-danger text-danger text-hover-light-danger":
        variant === "danger-inverse",
    },
    className
  );
  const spinnerClassNames = clsx("spinner", {
    "spinner-white": variant === "primary" || variant === "danger",
    "spinner-secondary": variant === "secondary",
    "spinner-primary": variant === "primary-inverse",
  });
  return (
    <button
      {...rest}
      ref={ref}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={buttonClassNames}
      style={{
        ...style,
        ...(loading ? { width: buttonWidth, height: buttonHeight } : {}),
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        {loading ? (
          <div className="position-absolute d-flex align-items-center justify-content-center min-w-100 min-h-100">
            <span
              style={{ marginLeft: "-14px" }}
              className={spinnerClassNames}
            />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </button>
  );
};
