import { useMemo } from "react";
import usePackageClassesList from "./usePackageClassesList";

export default function usePkgClassOptions() {
  const { list, loading } = usePackageClassesList();
  const pkgClassesOptions = useMemo(
    () =>
      list?.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [list]
  );
  return { pkgClassesOptions, loading };
}
