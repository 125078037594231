import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Button } from "../../_metronic/layout/components/common";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";
import { ContentSection } from "../../_metronic/layout/components/public/common/ContentSection";
import { GET_DOWNLOAD_CONTENT } from "../../graphql/queries";
import { formatImageUrl } from "../helpers/formatters/strapiImagesUrl";
import { AboutUseSkeleton } from "../skeletons/AboutUsSkeleton";

export const DownloadPage = () => {
  const { data, loading, error } = useQuery(GET_DOWNLOAD_CONTENT);
  if (loading)
    return (
      <PublicLayout>
        <AboutUseSkeleton />
      </PublicLayout>
    );
  if (!data?.downloadPage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  if (error) {
    return (
      <PublicLayout>
        <div className="text-center vh-100 pt-20">
          <Typography variant="h3" className="text-dark font-weight-bolder">
            Something went wrong
          </Typography>
          <div className="my-5" />
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </PublicLayout>
    );
  }

  const { heroBlock, downloadDetails } = data?.downloadPage?.data?.attributes;
  return (
    <PublicLayout>
      <div className="my-20">
        <ContentSection data={heroBlock} imageSpan={6} />

        <div className="mt-25" />
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12} md={8}>
            <img
              loading="lazy"
              src={formatImageUrl(
                downloadDetails?.viewOnDifferentScreens?.data?.attributes?.url
              )}
              alt=""
              className="max-w-100"
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center mr-2">
                {/* qr code image */}
                <img
                  loading="lazy"
                  src={formatImageUrl(
                    downloadDetails?.androidQRCode?.data?.attributes?.url
                  )}
                  alt=""
                  className="max-w-100 mb-2"
                />
                <a
                  href={downloadDetails?.androidLink ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  // className="mr-3"
                >
                  <img
                    alt="Get it on Google Play"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/play-store.svg"
                    )}
                    className="max-w-100"
                  />
                </a>
              </div>
              <div className="d-flex flex-column align-items-center">
                {/* qr code image */}
                <img
                  loading="lazy"
                  src={formatImageUrl(
                    downloadDetails?.iosQRCode?.data?.attributes?.url
                  )}
                  alt=""
                  className="max-w-100 mb-2"
                />
                <a
                  href={downloadDetails?.iosLink ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  // className="mr-3"
                >
                  <img
                    alt="Get it on App Store"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/app-store.svg"
                    )}
                    className="max-w-100"
                  />
                </a>
              </div>
              {/* <img
              loading="lazy"
              src={toAbsoluteUrl("/media/svg/icons/General/app-store.svg")}
              className={styles["img-22"]}
              alt="play store"
            /> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </PublicLayout>
  );
};
