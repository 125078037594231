import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { usePaymentForm } from "../../../../app/helpers/hooks/usePaymentForm";
import { actions } from "../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { actions as bookingActions } from "../../../../redux/state/booking";
import { actions as flightSearchActions } from "../../../../redux/state/flightSearch";

import { CompleteOrder } from "../fareSearch/CompleteOrder";
import {
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_PREMIERE,
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_WALLET,
} from "../../../../redux/state/booking/bookingConstants";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { TICKET_DETAILS } from "../../../../app/routesMap";
import { parseRoute } from "../../../../app/helpers/formatters";
import { isProd } from "../../../../redux/state/auth/authConstants";
import { useMemo } from "react";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../app/routesMapAdmin";
import { stripeWebhookPaymentForEnums } from "../../../../redux/state/user/userConstants";
import { useTransactionFee } from "../../../../app/helpers/hooks/useTransactionFee";
import { getStripeFee } from "../common/payment/utils";
import { Skeleton } from "@material-ui/lab";

export const IssueHoldBooking = ({ isReissue }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: bookingId } = useParams();
  const [paymentType, setPaymentType] = useState(); // 1 wallet, 2 credit card
  const { data: orderData } = useSelector(
    ({ bookingMng }) => bookingMng.specificOrder
  );
  const { data: reissueData } = useSelector(
    ({ bookingMng }) => bookingMng.specificReissue
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    feesFailure,
    feesLoading,
    stripeFixedFee,
    stripePercentageFee,
  } = useTransactionFee();

  const { reIssue } = reissueData || {};
  const { transaction, id: reissueId } = reIssue || {};
  const data = isReissue ? transaction : orderData;
  const {
    totalPrice,
    id: bookingTransactionId,
    price,
    supplierCode,
    pnr,
    gdsPnr,
    supplierId,
    isManual,
    agencyId,
  } = data || {};
  const id = isReissue ? reissueId : bookingTransactionId;
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { availableBalance } =
    useSelector(({ accountBalance }) => accountBalance.balance.data) || {};
  const { token } = useSelector((state) => state.auth);
  const { success, failure, loading } = useSelector(
    ({ booking }) => booking.bookFlight
  );
  const {
    success: verifySuccess,
    failure: verifyFailure,
    loading: verifyLoading,
    data: verifyData,
  } = useSelector(({ flightSearch }) => flightSearch.verifyTickets);
  const isIssueAdmin = useMemo(() => isManual && isAdmin, [isManual, isAdmin]);

  useEffect(() => {
    if (isReissue) {
      dispatch(actions.getSpecificReissue({ id: reissueId }));
    } else {
      dispatch(actions.getSpecificOrderByBookingId({ bookingId }));
    }
  }, [
    companyInUserId,
    dispatch,
    bookingId,
    isReissue,
    reissueId,
    agencyId,
    isIssueAdmin,
  ]);

  const onSubmit = () => {
    if (isReissue || !isProd || isManual || supplierId === "GAL") {
      onIssue();
    } else {
      dispatch(
        flightSearchActions.verifyTickets({
          GeneralInfo: {
            GUID: "",
            Username: "Test",
            Password: "Test",
          },
          PnrDetails: {
            Gds: supplierId,
            PnrNumber: pnr,
            GdsPnr: gdsPnr,
          },
        })
      );
    }
  };
  const onIssue = () => {
    if (paymentType === PAYMENT_TYPE_CARD) {
      bookViaCard();
    }
    if (paymentType === PAYMENT_TYPE_WALLET) {
      bookViaWallet();
    }
    if (paymentType === PAYMENT_TYPE_WAAFI) {
      bookViaWaafi();
    }
    if (paymentType === PAYMENT_TYPE_PREMIERE) {
      bookViaPremiere();
    }
  };
  const { cardFields, b2bFields, waafiFields, premiereFields } = usePaymentForm(
    onSubmit
  );
  const { totalAmount, stripeFee } = useMemo(() => {
    let total = 0;
    let stripeFee = 0;
    if (paymentType === PAYMENT_TYPE_CARD) {
      stripeFee = getStripeFee({
        stripeFixedFee,
        stripePercentageFee,
        total: totalPrice,
      });
    }
    total = +totalPrice + +stripeFee;
    return {
      totalAmount: total,
      stripeFee: stripeFee,
    };
  }, [
    paymentType,
    stripeFixedFee,
    stripePercentageFee,
    totalPrice,
    // discount,
  ]);
  const getSharedParams = useCallback(
    () => ({
      id,
      transactionId: id,
      flymeAgencyId: isIssueAdmin ? agencyId : companyInUserId, //AgencyId
      customerEmail: "",
      customerName: "",
      bookingId, //bookingId
      amount: +totalAmount,
      transactionFee: stripeFee,
      // amount: 1,
      pinCode: "", // if payment is from user account
      token: token, // User token
      paymentType: 2, // 1 for User account, 2 for Card
      availableBalance: +(availableBalance ?? 0),
      supplierCode, // make dynamic
      supplierAmount: price,
      isAdmin: isManual && isAdmin,
      billingCardInfo: {
        customerId: "", //leave it as it is
        nameOnCard: "", //from user
        cardNumber: "", //from user
        expYear: 2022, //from user
        expMonth: 9, //from user
        cardCVC: "", //from user
        billingAddress: "", //from user
        zipCode: "", //from user
      },
    }),
    [
      agencyId,
      availableBalance,
      bookingId,
      companyInUserId,
      id,
      isAdmin,
      isIssueAdmin,
      isManual,
      price,
      stripeFee,
      supplierCode,
      token,
      totalAmount,
    ]
  );
  useEffect(() => {
    if (isIssueAdmin) {
      const { setFieldValue } = b2bFields;
      setFieldValue("pin", "0000");
    }
  }, [isIssueAdmin]);

  const stripePaymentBody = useMemo(() => {
    const {
      nameOnCard,
      cardNumber,
      expYear,
      expMonth,
      cardCVC,
    } = cardFields.values;
    const sharedParams = getSharedParams();
    const paymentObj = {
      ...sharedParams,
      paymentType,
      billingCardInfo: {
        ...sharedParams.billingCardInfo,
        nameOnCard: nameOnCard,
        cardNumber: cardNumber,
        expYear: +expYear,
        expMonth: +expMonth,
        cardCVC: cardCVC.toString(),
      },
    };

    const stringfiedPaymentObj = JSON.stringify(paymentObj);

    return {
      type: stripeWebhookPaymentForEnums.IssueHold,
      object: stringfiedPaymentObj,
    };
  }, [cardFields.values, paymentType, getSharedParams]);

  const bookViaCard = () => {
    const {
      nameOnCard,
      cardNumber,
      expYear,
      expMonth,
      cardCVC,
    } = cardFields.values;
    const sharedParams = getSharedParams();
    dispatch(
      bookingActions.issueHoldBooking({
        ...sharedParams,
        paymentType,
        billingCardInfo: {
          ...sharedParams.billingCardInfo,
          nameOnCard: nameOnCard,
          cardNumber: cardNumber,
          expYear: +expYear,
          expMonth: +expMonth,
          cardCVC: cardCVC.toString(),
        },
      })
    );
  };
  const bookViaWallet = () => {
    const { pin } = b2bFields.values;
    const sharedParams = getSharedParams();
    const params = {
      ...sharedParams,
      paymentType,
      pinCode: pin,
    };
    if (isReissue) {
      dispatch(bookingActions.payReissue(params));
    } else {
      dispatch(bookingActions.issueHoldBooking(params));
    }
  };
  const bookViaWaafi = () => {
    const { accountNo } = waafiFields.values;
    const sharedParams = getSharedParams();
    const params = {
      ...sharedParams,
      paymentType,
      billingCardInfo: {
        ...sharedParams.billingCardInfo,
        cardNumber: accountNo,
      },
    };
    if (isReissue) {
      dispatch(bookingActions.payReissue(params));
    } else {
      dispatch(bookingActions.issueHoldBooking(params));
    }
  };
  const bookViaPremiere = () => {
    const { accountNo } = premiereFields.values;
    const sharedParams = getSharedParams();
    const params = {
      ...sharedParams,
      paymentType,
      billingCardInfo: {
        ...sharedParams.billingCardInfo,
        cardNumber: `00252${accountNo}`,
      },
    };
    if (isReissue) {
      dispatch(bookingActions.payReissue(params));
    } else {
      dispatch(bookingActions.issueHoldBooking(params));
    }
  };
  useEffect(() => {
    if (verifySuccess) {
      const { status } = verifyData;
      if (status === "Active") {
        onIssue();
      } else if (status === "InsufficientBalance") {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: "Supplier error: Please contact Flyme support",
          })
        );
      } else {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message:
              "Tickets are no longer available, please make a new booking.",
          })
        );
      }

      dispatch(flightSearchActions.clearVerifyTickets());
    }
  }, [dispatch, verifySuccess]);
  useEffect(() => {
    if (verifyFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(flightSearchActions.clearVerifyTickets());
    }
  }, [dispatch, verifyFailure]);
  useEffect(() => {
    if (success) {
      history.push(
        parseRoute(isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS, {
          id: bookingId,
        })
      );
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Hold Order is Issued Successfully",
        })
      );
      dispatch(bookingActions.clearBookFlights());
    }
  }, [bookingId, dispatch, history, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: failure,
        })
      );
      dispatch(bookingActions.clearBookFlights());
    }
  }, [dispatch, failure]);
  if (feesLoading) {
    return (
      <Skeleton animation="wave" variant="rect" width={"100%"} height={300} />
    );
  }

  if (feesFailure) {
    return (
      <div className="text-center">
        Error fetching data! Please contact Flyme247 support team
      </div>
    );
  }
  return (
    <CompleteOrder
      issueHoldBooking={true}
      b2bFormik={b2bFields}
      cardFormik={cardFields}
      waafiFormik={waafiFields}
      premiereFormik={premiereFields}
      setPaymentType={setPaymentType}
      paymentType={paymentType}
      loading={loading || verifyLoading}
      bookingDetails={{
        ...data,
        totalPrice: totalAmount,
        transactionFee: stripeFee,
      }}
      isIssueAdmin={isIssueAdmin}
      stripePaymentBody={stripePaymentBody}
      totalPrice={totalAmount}
    />
  );
};
