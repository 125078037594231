import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/communication";
import { EmailsSections } from "../../../account/notificationAccess/NotificationAccess";

export const NotificationsTypes = () => {
  const { list } = useSelector(
    ({ communication }) => communication.communicationTypes
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getAllCommunicationTypes());
  }, [dispatch]);

  return <EmailsSections emailTypeSections={list} />;
};
