import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/payment";
import { LoadingSpinner } from "../../../common";

import { useCallback } from "react";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { PaymentBalanceRow } from "./PaymentBalanceRow";

export const PaymentBalances = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(
    ({ payment }) => payment.paymentMethodBalances
  );

  useEffect(() => {
    dispatch(actions.getPaymentMethodBalances());
  }, [dispatch]);
  const hideWithdrawModal = useCallback(() => {
    setShowWithdraw(false);
    dispatch(actions.clearWithdraw());
    setSelectedId(undefined);
  }, [dispatch]);
  const hideDepositModal = useCallback(() => {
    setShowDepositModal(false);
    dispatch(actions.clearDeposit());
    setSelectedId(undefined);
  }, [dispatch]);
  const onWithdraw = (id) => {
    setShowWithdraw(true);
    setSelectedId(id);
  };
  const onDeposit = (id) => {
    setShowDepositModal(true);
    setSelectedId(id);
  };

  const getBankAccount = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const tableContent = list?.map((item, index) => (
    <PaymentBalanceRow
      key={index}
      data={item}
      subPermissions={subPermissions}
      onWithdraw={onWithdraw}
      onDeposit={onDeposit}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          {/* <DepositModal
            data={getBankAccount(selectedId)}
            onHide={hideDepositModal}
            show={showDepositModal}
          />
          <WithdrawModal
            data={getBankAccount(selectedId)}
            onHide={hideWithdrawModal}
            show={showWithdraw}
          /> */}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Payment Type</th>
                      <th>Balance</th>
                      <th className="min-w-160px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Payment Balances to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
