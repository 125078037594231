import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { SubscriptionDetails } from "../_metronic/layout/components/account/Subscription/SubscriptionDetails";
import { BalanceDueReport } from "../_metronic/layout/components/admin/reports/balanceDueReport/BalanceDueReport";
import { AgencyProfile } from "../_metronic/layout/components/agencyProfile/AgencyProfile";
import { FlightSearch } from "../_metronic/layout/components/fareSearch/FlightSearch";
import { Credit } from "../_metronic/layout/components/payment/credit/Credit";
import { DashboardPage } from "./pages/DashboardPage";
import { TicketSummaryPage } from "./pages/TicketSummaryPage";
import {
  AccountBalancePage,
  AccountDetailsPage,
  NotificationsPage,
  SubUsersListPage,
} from "./pages/account";
import {
  RefundPage,
  ReissuePage,
  SearchOrderPage,
  VoidTrackingPage,
} from "./pages/bookingManagement";
import { TrackingPage } from "./pages/bookingManagement/TrackingPage";
import { DistributionMarkupPage } from "./pages/distribution";
import { FlightSearchPage } from "./pages/flightSearch";
import {
  AdminGuidePage,
  ContactPage,
  FaqPage,
  SupportPage,
} from "./pages/helpCenter";
import { PaymentProofPage, PaymentSettingsPage } from "./pages/payment";
import {
  ACCOUNT,
  ACCOUNT_BALANCE,
  ACCOUNT_DETAILS,
  ACCOUNT_NOTIFICATION,
  ACCOUNT_SUB_USER_LIST,
  AGENCY_PROFILE,
  BOOKING_MANAGEMENT,
  BOOKING_MANAGEMENT_REFUND,
  BOOKING_MANAGEMENT_REISSUE,
  BOOKING_MANAGEMENT_SEARCH,
  BOOKING_MANAGEMENT_TRACKING,
  BOOKING_MANAGEMENT_VOID,
  DASHBOARD,
  DISTRIBUTION_MARKUP,
  FARE_SEARCH,
  FLIGHT_SEARCH,
  HELP_CENTER_ADMIN_GUID,
  HELP_CENTER_CONTACT,
  HELP_CENTER_FAQ,
  HELP_CENTER_SUPPORT,
  NOT_FOUND,
  PAYMENT,
  PAYMENT_CREDIT,
  PAYMENT_PROOF,
  PAYMENT_SETTINGS,
  PNR_SEARCH,
  REPORTS,
  REPORTS_PAYABLE,
  SUBSCRIPTION_DETAILS,
  TICKET_SUMMARY,
} from "./routesMap";

export const routesPermissionMap = {
  Dashboard: {
    mainRoute: DASHBOARD,
    icon: "/media/svg/icons/Design/Layers.svg",
    items: [
      {
        label: "Main",
        permissionKey: "dashboard",
        route: DASHBOARD,
        component: DashboardPage,
      },
    ],
  },
  "Fare Search": {
    mainRoute: FARE_SEARCH,
    icon: "/media/svg/icons/General/search.svg",
    items: [
      {
        label: "Flight Search",
        permissionKey: "fareSearch.flightSearch",
        route: FLIGHT_SEARCH,
        component: FlightSearchPage,
      },
      {
        label: "Pnr Search",
        permissionKey: "fareSearch.pnrSearch",
        route: PNR_SEARCH,
        component: FlightSearch,
      },
      // {
      //   label: "Umrah Search",
      //   permissionKey: "fareSearch.umrah",
      //   route: PACKAGE_SEARCH,
      //   component: PackageSearchPage,
      // },
    ],
  },
  "Booking Management": {
    mainRoute: BOOKING_MANAGEMENT,
    icon: "/media/svg/icons/General/booking.svg",
    items: [
      {
        label: "Ticket Tracking",
        permissionKey: "bookingManagement.ticketTracking",
        route: BOOKING_MANAGEMENT_TRACKING,
        component: TrackingPage,
      },
      {
        label: "Refund management",
        permissionKey: "bookingManagement.refund",
        route: BOOKING_MANAGEMENT_REFUND,
        component: RefundPage,
      },
      {
        label: "Void management",
        permissionKey: "bookingManagement.void",
        route: BOOKING_MANAGEMENT_VOID,
        component: VoidTrackingPage,
      },
      {
        label: "Reissue management",
        permissionKey: "bookingManagement.reissue",
        route: BOOKING_MANAGEMENT_REISSUE,
        component: ReissuePage,
      },
      // {
      //   label: "Packages Tracking",
      //   permissionKey: "bookingManagement.packages",
      //   route: BOOKING_MANAGEMENT_PACKAGES,
      //   component: PackagesTrackingPage,
      // },
      {
        label: "Search Order",
        permissionKey: "bookingManagement.searchOrder",
        route: BOOKING_MANAGEMENT_SEARCH,
        component: SearchOrderPage,
      },
    ],
  },
  "Ticket Summary": {
    mainRoute: TICKET_SUMMARY,
    icon: "/media/svg/icons/General/summary.svg",
    items: [
      {
        label: "Main",
        permissionKey: "ticketSummary",
        route: TICKET_SUMMARY,
        component: TicketSummaryPage,
      },
    ],
  },
  "My Account": {
    mainRoute: ACCOUNT,
    icon: "/media/svg/icons/General/account.svg",
    items: [
      {
        label: "Company Profile",
        permissionKey: "myAccount.companyProfile",
        route: AGENCY_PROFILE,
        component: AgencyProfile,
      }, // add
      {
        label: "User Details",
        permissionKey: "myAccount.userDetails",
        route: ACCOUNT_DETAILS,
        component: AccountDetailsPage,
      },
      {
        label: "Subscription",
        permissionKey: "myAccount.subscription",
        route: SUBSCRIPTION_DETAILS,
        component: SubscriptionDetails,
        subscriberOnly: true,
      },
      {
        label: "Sub User & Permission",
        permissionKey: "myAccount.subUsers",
        route: ACCOUNT_SUB_USER_LIST,
        component: SubUsersListPage,
        agencyOnly: true,
      },
      {
        label: "Balance Summary",
        permissionKey: "myAccount.balanceSummary",
        route: ACCOUNT_BALANCE,
        component: AccountBalancePage,
      },
      {
        label: "Notifications",
        permissionKey: "myAccount.notificationAccess",
        route: ACCOUNT_NOTIFICATION,
        component: NotificationsPage,
      },
    ],
  },
  Payments: {
    mainRoute: PAYMENT,
    icon: "/media/svg/icons/General/payments.svg",
    items: [
      {
        label: "PIN settings",
        permissionKey: "payments.pinSettings",
        route: PAYMENT_SETTINGS,
        component: PaymentSettingsPage,
      },
      {
        label: "Payment Proof",
        permissionKey: "payments.paymentProof",
        route: PAYMENT_PROOF,
        component: PaymentProofPage,
      },
      {
        label: "Credit",
        permissionKey: "payments.credit",
        route: PAYMENT_CREDIT,
        component: Credit,
        agencyOnly: true,
      },
    ],
  },
  "Distribution Markup": {
    mainRoute: DISTRIBUTION_MARKUP,
    icon: "/media/svg/icons/General/distribution.svg",
    items: [
      {
        label: "Distribution Markup",
        permissionKey: "markup",
        route: DISTRIBUTION_MARKUP,
        component: DistributionMarkupPage,
        agencyOnly: true,
      },
    ],
  },
  "Reports & Statistics": {
    mainRoute: REPORTS,
    icon: "/media/svg/icons/General/reports.svg",
    withSubMenu: true,
    items: [
      {
        label: "Payable Due Report",
        permissionKey: "reports.payable",
        route: REPORTS_PAYABLE,
        component: BalanceDueReport,
      },
    ],
  },
};

export default function AgencyRoutes() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {Object.keys(routesPermissionMap).map((key) =>
          routesPermissionMap[
            key
          ].items.map(({ permissionKey, route, component: Component }) => (
            <ContentRoute
              path={route}
              component={Component}
              permissionKey={permissionKey}
            />
          ))
        )}
        <Route path={AGENCY_PROFILE} component={AgencyProfile} />
        <Route exact path={PAYMENT_CREDIT} component={Credit} />

        <Route exact path={HELP_CENTER_ADMIN_GUID} component={AdminGuidePage} />
        <Route exact path={HELP_CENTER_CONTACT} component={ContactPage} />
        <Route exact path={HELP_CENTER_FAQ} component={FaqPage} />
        <Route exact path={HELP_CENTER_SUPPORT} component={SupportPage} />
        <Redirect to={NOT_FOUND} />
      </Switch>
    </Suspense>
  );
}
