import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ADMIN_STAFF_ROLES,
  ADMIN_STAFF_ROLE_DETAILS,
  ADMIN_STAFF_ROLE_PERMISSIONS,
} from "../../routesMapAdmin";
import { ContentRoute } from "../../../_metronic/layout/components/content/ContentRoute";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import { Roles } from "../../../_metronic/layout/components/admin/systemManagement/staffRoles/Roles";
import { RoleDetails } from "../../../_metronic/layout/components/admin/systemManagement/staffRoles/roleDetails/RoleDetails";
import { StaffRolePermissions } from "../../../_metronic/layout/components/admin/systemManagement/staffRoles/staffRolePermissions/StaffRolePermissions";

export const StaffRolesPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.viewRole",
        ])}
        path={ADMIN_STAFF_ROLE_DETAILS}
        component={RoleDetails}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.editRole",
        ])}
        path={ADMIN_STAFF_ROLE_PERMISSIONS}
        component={StaffRolePermissions}
      />
      <Route
        exact
        path={ADMIN_STAFF_ROLES}
        render={(props) => <Roles {...props} permissionId={permissionId} />}
      />
    </Switch>
  );
};
