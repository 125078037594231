import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Button } from "../../../../common";

export const DateRangeModal = ({ show, onHide, fields, onSave }) => {
  const [prevDates, setPrevDates] = useState({
    fromDate: "",
    toDate: "",
  });
  const { touched, errors, getFieldProps, setFieldValue, values } = fields;
  useEffect(() => {
    const { fromDate, toDate } = values;
    setPrevDates({
      fromDate: fromDate,
      toDate: toDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    const { fromDate, toDate } = prevDates;
    onHide();
    setFieldValue("fromDate", fromDate);
    setFieldValue("toDate", toDate);
  };
  const handleSave = () => {
    if (onSave) {
      onSave();
    }
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="md">
      <div className="p-7">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              showErrors={touched.fromDate}
              errorMessage={errors.fromDate}
              {...getFieldProps("fromDate")}
              label="From"
              type="date"
              noErrorMessage
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              showErrors={touched.toDate}
              errorMessage={errors.toDate}
              {...getFieldProps("toDate")}
              label="To"
              type="date"
              noErrorMessage
            />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-center mt-7 mb-3">
          <Button type="button" onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="button" onClick={handleSave} className="ml-7">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
