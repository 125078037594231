import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { ClientDetails } from "../../../_metronic/layout/components/admin/client/clientDetails/ClientDetails";
import { Clients } from "../../../_metronic/layout/components/admin/client/Clients";
import { CreateClient } from "../../../_metronic/layout/components/admin/client/createClient/CreateClient";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  ADMIN_AGENCIES,
  ADMIN_CLIENT_DETAILS,
  ADMIN_CREATE_CLIENT,
} from "../../routesMapAdmin";

export const ClientsPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <ContentRoute
        exact
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.createAgency",
        ])}
        path={ADMIN_CREATE_CLIENT}
        component={CreateClient}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.viewAgency",
        ])}
        path={ADMIN_CLIENT_DETAILS}
        component={ClientDetails}
      />
      <Route
        exact
        path={ADMIN_AGENCIES}
        render={(props) => <Clients {...props} permissionId={permissionId} />}
      />
    </Switch>
  );
};
