import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { Seperator } from "../../../../common";

export const SupplierPayment = () => {
  return (
    <CardWithBlueHeader
      title="Supplier Payments"
      iconSrc="/media/svg/icons/General/cash.svg"
    >
      <Grid container>
        <Grid item className="p-7" xs={6}>
          <Typography className="font-weight-bold">Bais Amount</Typography>
        </Grid>
        <Grid item className="p-7" xs={6}>
          {/* <Typography align="right" className="font-weight-bold text-primary">
            $550.00
          </Typography> */}
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="p-7" xs={6}>
          <Typography className="font-weight-bold">Amount Paid</Typography>
        </Grid>
        <Grid item className="p-7" xs={6}>
          {/* <Typography align="right" className="font-weight-bold text-primary">
            $225.00
          </Typography> */}
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="p-7" xs={6}>
          <Typography className="font-weight-bold">Payment Data</Typography>
        </Grid>
        <Grid item className="p-7" xs={6}>
          {/* <Typography align="right" className="font-weight-bold text-primary">
            20/2/2021
          </Typography> */}
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="p-7" xs={6}>
          <Typography className="font-weight-bold">Paid By</Typography>
        </Grid>
        <Grid item className="p-7" xs={6}>
          {/* <Typography align="right" className="font-weight-bold text-primary">
            Noor Travel
          </Typography> */}
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="p-7" xs={6}>
          <Typography className="font-weight-bold">Payment Mode</Typography>
        </Grid>
        <Grid item className="p-7" xs={6}>
          {/* <Typography align="right" className="font-weight-bold text-primary">
            Salama Bank
          </Typography> */}
        </Grid>
      </Grid>
    </CardWithBlueHeader>
  );
};
