import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { Button, Input } from "../../../../common";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../../../redux/state/bookingMng";

const acceptSchema = Yup.object().shape({
  refundAmount: Yup.string().required("Refund Amount is required"),
  note: Yup.string(),
});

const rejectSchema = Yup.object().shape({
  note: Yup.string(),
});

export const OfferQuotationModal = ({ show, onHide, isReject }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, success } = useSelector(
    ({ bookingMng }) => bookingMng.refund
  );
  const fields = useFormik({
    initialValues: {
      refundAmount: "",
      note: "",
    },
    validationSchema: isReject ? rejectSchema : acceptSchema,
    onSubmit: ({ note, refundAmount }) => {
      if (isReject) {
        dispatch(actions.rejectRefundFromAdmin({ id, note }));
      } else {
        dispatch(actions.acceptRefundFromAdmin({ id, note, refundAmount }));
      }
    },
  });
  const { getFieldProps, touched, errors, handleSubmit } = fields;
  useEffect(() => {
    if (success) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, dispatch]);
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>
          {isReject ? "Reject Refund" : "Offer Quotation"}{" "}
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            {!isReject && (
              <Grid item xs={12}>
                <Input
                  {...getFieldProps("refundAmount")}
                  showErrors={touched["refundAmount"]}
                  errorMessage={errors["refundAmount"]}
                  variant="secondary"
                  label="Refund Amount"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Input
                {...getFieldProps("note")}
                // showErrors={touched["note"]}
                // errorMessage={errors["note"]}
                variant="secondary"
                label="Note"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
