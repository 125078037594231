import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { adminRoutesPermissionMap } from "../../../../../../../../app/AdminRoutes";
import { Permissions } from "../../../../../account/accountSubUser/GivePermissions";
import { parseRoute } from "../../../../../../../../app/helpers/formatters";
import {
  ADMIN_STAFF_PERMISSIONS,
} from "../../../../../../../../app/routesMapAdmin";

export const StaffPermissions = () => {
  const { id } = useParams();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h5" className="mb-5">
            Permissions
          </Typography>
          <Permissions
            directLink={parseRoute(ADMIN_STAFF_PERMISSIONS, { id })}
            permissions={adminRoutesPermissionMap}
            userId={id}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
