import React, { useState, Fragment, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "../../../../../redux/state/user";
import { Button, LoadingSpinner, Input, Select } from "../../common";
import useRoleOptions from "../../../../../app/helpers/hooks/useRoleOptions";

export const PersonalForm = ({ hasRoleInput }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const { loading, success } = useSelector(({ user }) => user.edit);
  const {
    loading: editSpecificUserLoading,
    success: editSpecificUserSuccess,
  } = useSelector(({ user }) => user.edit);
  const { roleOptions } = useRoleOptions();

  const editLoading = loading || editSpecificUserLoading;
  const editSuccess = success || editSpecificUserSuccess;

  const {
    name,
    email,
    address,
    phone,
    companyInUserId,
    ...userParams
  } = useSelector(({ user }) => user.userData);
  const {
    loading: specificUserLoading,
    success: specificUserSuccess,
    data: specificUserData,
  } = useSelector(({ user }) => user.specificUser);
  // useEffect(() => {
  //   return () => {
  //     dispatch(actions.clearSpecificUser());

  //   };
  // }, [dispatch]);

  const personalSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    registeredEmail: Yup.string().required("Registered Email is required"),
    registeredPhone: Yup.string().required("Registered Phone is required"),
    // city: Yup.string().required("City is required"),
    // currency: Yup.string().required("Currency is required"),
    // recoveryEmail: Yup.string(),
    // salesEmail: Yup.string(),
    // emergencyContactPerson: Yup.string(),
    // emergencyContactPhone: Yup.string(),
    // iata: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      // id,
      username: name || "",
      registeredEmail: email || "",
      registeredPhone: phone || "",
      city: address || "cairo",
      currency: "gdp",
      recoveryEmail: "Recovery@email.com",
      salesEmail: "Sales@email.com",
      emergencyContactPerson: "Mr.Johan",
      emergencyContactPhone: "+20 10234567890",
      iata: "00000",
      role: "",
    },
    validationSchema: personalSchema,
    onSubmit: ({ registeredEmail, username, registeredPhone, role }) => {
      let params = {
        companyInUserId,
        email: registeredEmail,
        name: username,
        phone: registeredPhone,
        roleId: +role,
      };
      if (id) {
        params = { ...specificUserData, ...params };
        dispatch(
          actions.ediSpecificUser({
            ...params,
          })
        );
      } else {
        params = { ...userParams, ...params };
        dispatch(
          actions.editUser({
            ...params,
          })
        );
      }
    },
  });
  // const [inputsState, setInputsState] = useState();
  const [savedState, setSavedState] = useState({});
  const { setValues } = formik;
  useEffect(() => {
    if (specificUserSuccess) {
      const { name, email, address, phone, roleId } = specificUserData || {};
      setValues({
        username: name || "",
        registeredEmail: email || "",
        registeredPhone: phone || "",
        city: address || "cairo",
        currency: "gdp",
        recoveryEmail: "Recovery@email.com",
        salesEmail: "Sales@email.com",
        emergencyContactPerson: "Mr.Johan",
        emergencyContactPhone: "+20 10234567890",
        iata: "00000",
        role: roleId,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificUserSuccess]);
  useEffect(() => {
    if (editSuccess) {
      setEdit(false);
      setSavedState(values);
      dispatch(actions.clearEdit());
      dispatch(actions.clearEditSpecificUser());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSuccess]);
  const {
    touched,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    handleSubmit,
  } = formik;
  return (
    <>
      {specificUserLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <Input
          variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="id"
        {...getFieldProps("id")}
        disabled={true}
        label="Account ID"
      /> */}
          <Input
            variant="secondary"
            spacing
            horizontal
            fixedLabelWidth
            showErrors={touched["username"]}
            errorMessage={errors["username"]}
            {...getFieldProps("username")}
            name="username"
            disabled={!edit}
            label="User Name"
          />
          <Input
            variant="secondary"
            spacing
            horizontal
            fixedLabelWidth
            showErrors={touched["registeredEmail"]}
            errorMessage={errors["registeredEmail"]}
            {...getFieldProps("registeredEmail")}
            name="registeredEmail"
            disabled={!edit}
            label="Registered Email"
          />
          <Input
            variant="secondary"
            spacing
            horizontal
            fixedLabelWidth
            name="registeredPhone"
            showErrors={touched["registeredPhone"]}
            errorMessage={errors["registeredPhone"]}
            {...getFieldProps("registeredPhone")}
            disabled={!edit}
            label="Registered Phone"
          />
          {hasRoleInput && (
            <Select
              variant="secondary"
              spacing
              horizontal
              fixedLabelWidth
              disabled={!edit}
              required
              name="role"
              options={roleOptions}
              showErrors={touched.role}
              errorMessage={errors.role}
              value={values.role}
              onChange={(value) => setFieldValue("role", value)}
              label="Role"
            />
          )}
          {/* hidden not supported on backend */}
          {/* <Input
          variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="city"
        showErrors={touched["city"]}
        errorMessage={errors["city"]}
        {...getFieldProps("city")}
        // disabled={!edit}
        disabled={true}
        label="City/Region"
      />
      <div
        className={clsx("d-flex mb-7", {
          "flex-column": !matchesSm,
          "align-items-center": matchesSm,
        })}
      >
        <span className={clsx("w-150px", { "mb-3  ": !matchesSm })}>
          Currency
        </span>
        <NativeSelect
          name="currency"
          showErrors={touched["currency"]}
          errorMessage={errors["currency"]}
          {...getFieldProps("currency")}
          // disabled={!edit}
          disabled={true}
          input={
            <Input
            variant="secondary"
              spacing
              horizontalBase className="h-40px border rounded p-2 flex-grow-1 bg-white" />
          }
        >
          <option value="gdp">GDP</option>
        </NativeSelect>
      </div>
      <Input
      variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="recoveryEmail"
        showErrors={touched["recoveryEmail"]}
        errorMessage={errors["recoveryEmail"]}
        {...getFieldProps("recoveryEmail")}
        disabled={true}
        // disabled={!edit}
        label="recovery email"
      />
      <Input
      variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="salesEmail"
        showErrors={touched["salesEmail"]}
        errorMessage={errors["salesEmail"]}
        {...getFieldProps("salesEmail")}
        label="Sales Email"
        // disabled={!edit}
        disabled={true}
      />
      <Input
      variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="emergencyContactPerson"
        showErrors={touched["emergencyContactPerson"]}
        errorMessage={errors["emergencyContactPerson"]}
        {...getFieldProps("emergencyContactPerson")}
        label="emergency contact person"
        // disabled={!edit}
        disabled={true}
      />
      <Input
      variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="emergencyContactPhone"
        showErrors={touched["emergencyContactPhone"]}
        errorMessage={errors["emergencyContactPhone"]}
        {...getFieldProps("emergencyContactPhone")}
        // disabled={!edit}
        label="emergency contact Phone"
        disabled={true}
      />
      <Input
      variant="secondary"
              spacing
              horizontal
        fixedLabelWidth
        name="iata"
        showErrors={touched["iata"]}
        errorMessage={errors["iata"]}
        {...getFieldProps("iata")}
        // disabled={!edit}
        disabled={true}
        label="IATA Number"
      /> */}
          <div className="d-flex justify-content-end">
            {!edit ? (
              <button
                type="button"
                onClick={() => {
                  setEdit(true);
                  setSavedState(values);
                }}
                className="btn btn-primary w-100px"
              >
                Edit
              </button>
            ) : (
              <Fragment>
                <button
                  onClick={() => {
                    setEdit(false);
                    setValues(savedState);
                  }}
                  className="btn bg-gray-200 w-100px "
                  type="button"
                >
                  Cancel
                </button>
                <Button
                  loading={editLoading}
                  onClick={handleSubmit}
                  type="submit"
                  className="ml-7 w-100px"
                >
                  Save
                </Button>
              </Fragment>
            )}
          </div>
        </form>
      )}
    </>
  );
};
