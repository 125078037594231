import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { ServiceFeesForm } from "./ServiceFeesForm";
import { ServiceFeesRow } from "./ServiceFeesRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { useCallback } from "react";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

const feesSchema = Yup.object().shape({
  amount: Yup.string().required("Amount is required"),
  type: Yup.string().required("Type is required"),
});

export const ServiceFees = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const { userId } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ bookingMng }) => bookingMng.fees);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ bookingMng }) => bookingMng.createFee);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ bookingMng }) => bookingMng.editFee);
  const fields = useFormik({
    initialValues: {
      amount: "",
      type: "",
      isPercentage: false,
    },
    validationSchema: feesSchema,
    onSubmit: ({ amount, type, isPercentage }) => {
      if (!!selectedId) {
        const data = getFee(selectedId);
        const { $id, ...params } = data;
        dispatch(
          actions.editFee({
            ...params,
            amount,
            type: +type,
            isPercentage,
            updatedBy: +userId,
          })
        );
      } else {
        dispatch(
          actions.createFee({
            amount,
            type: +type,
            isPercentage,
            createdBy: +userId,
          })
        );
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllFees());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearEditFee());
    dispatch(actions.clearCreateFee());
    setSelectedId(undefined);
    fields.resetForm();
  }, [dispatch, fields]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Fee Added",
        })
      );
      dispatch(actions.getAllFees());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateFee());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Fee Edited",
        })
      );
      dispatch(actions.getAllFees());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const onEdit = (id) => {
    setSelectedId(id);
    const { amount, type, isPercentage } = getFee(id);
    fields.setValues({
      amount,
      type: `${type}`,
      isPercentage,
    });
    onShowForm();
  };
  const getFee = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const allServiceFeeSubPermission =
    adminPermissionsIds.systemManagement.serviceFees.subPermissions;
  const tableContent = list?.map((item, index) => (
    <ServiceFeesRow
      subPermissions={subPermissions}
      key={index}
      data={item}
      onEdit={onEdit}
    />
  ));
  const onShowForm = () => {
    setShowForm(true);
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Service Fees</Typography>
            <button
              hidden={
                !subPermissions.includes(allServiceFeeSubPermission.add.id)
              }
              onClick={onShowForm}
              className="btn btn-danger"
            >
              Add Service Fee
            </button>
          </div>
          <ServiceFeesForm
            onHide={hideFormModal}
            show={showForm}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Type</th>
                      <th>Amount</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Service Fees to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
