import React from "react";

export const AboutContentCard = ({ title, content }) => {
  return (
    <>
      <div className="column">
        <div className="div-8">
          <div className="div-9">{title}</div>
          <div className="div-10">{content}</div>
        </div>
      </div>
      <style jsx>{`
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 34%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-8 {
          border-radius: 35px;
          background-color: #f8f9fa;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          padding: 50px 45px 50px 37px;
        }
        @media (max-width: 991px) {
          .div-8 {
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .div-9 {
          color: #000;
          margin-top: 6px;
          white-space: nowrap;
          font: 600 20px/38px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-9 {
            white-space: initial;
          }
        }
        .div-10 {
          color: #525252;
          margin-top: 35px;
          font: 400 14px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
      `}</style>{" "}
    </>
  );
};
