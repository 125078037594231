import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { DASHBOARD, SIGN_IN } from "../../../../app/routesMap";
import { toAbsoluteUrl } from "../../../_helpers";
import { Sidebar } from "../home/Sidebar";
import { AccountButton, HeaderMenu } from "../home/Header_OLD";
import { ADMIN_DASHBOARD } from "../../../../app/routesMapAdmin";
import { Button } from "../common";

export const MobileHeader = ({ isAuthorized, logout }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSide, setOpenSide] = useState();
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <div className="d-flex justify-content-between align-items-center w-100 pr-4">
      <div className="header-logo">
        <NavLink to="/">
          <img
            alt="logo"
            className="w-100px"
            src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
          />
        </NavLink>
      </div>
      <div className="d-flex align-items-center ">
        {openSide && (
          <>
            <Sidebar />
            <div
              onClick={() => setOpenSide(false)}
              className="header-menu-wrapper-overlay"
            />
          </>
        )}
        {isAuthorized ? (
          <div className="d-flex align-items-center">
            <Button
              variant="primary"
              onClick={() =>
                history.push(isAdmin ? ADMIN_DASHBOARD : DASHBOARD)
              }
            >
              Dashboard
            </Button>
            <div>
              <AccountButton handleClick={handleClick} />
            </div>
          </div>
        ) : (
          <Button variant="primary" onClick={() => history.push(SIGN_IN)}>
            Sign In
          </Button>
        )}
        <button
          className="btn p-0 burger-icon ml-3"
          id="kt_aside_mobile_toggle"
          onClick={() => setOpenSide(true)}
        >
          <span />
        </button>
        <HeaderMenu anchorEl={anchorEl} handleClose={handleClose} />
      </div>
    </div>
  );
};
