import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Modal } from "react-bootstrap";
import { Button, Input } from "../../../common";

export const RejectReissueModal = ({ show, onHide, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    loading: rejectReissueLoading,
    success: rejectReissueSuccess,
    failure: rejectReissueFailure,
  } = useSelector(({ bookingMng }) => bookingMng.reissue);
  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: Yup.object().shape({
      note: Yup.string(),
    }),
    onSubmit: ({ note }) => {
      if (isAdmin) {
        dispatch(actions.rejectReissue({ id, note }));
      } else {
        dispatch(actions.rejectReissueOffer({ id, note }));
      }
    },
  });
  useEffect(() => {
    if (rejectReissueSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Reissue is Rejected Successfully!",
        })
      );
      dispatch(actions.clearReissue());
      onHide();
      dispatch(actions.getSpecificReissue({ id }));
    }
  }, [rejectReissueSuccess, dispatch, history, onHide, id]);

  useEffect(() => {
    if (rejectReissueFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: rejectReissueFailure || "Something is wrong",
        })
      );
      dispatch(actions.clearReissue());
    }
  }, [rejectReissueFailure, dispatch]);
  const { touched, errors, handleSubmit, getFieldProps } = formik;
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          Reject Reissue {isAdmin ? "Request" : "Offer"}
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Input
            {...getFieldProps("note")}
            showErrors={touched.note}
            errorMessage={errors.note}
            label="note"
            placeholder="note"
            spacing
          />
        </Modal.Body>
        <Modal.Footer>
          <Button smallPadding type="button" onClick={onHide} variant="light">
            Cancel
          </Button>
          <> </>
          <Button
            smallPadding
            type="submit"
            loading={rejectReissueLoading}
            // onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
