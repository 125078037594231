import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../../app/routesMap";
import { Menu, MenuItem } from "@material-ui/core";
import styles from "./Home.module.scss";
import { toAbsoluteUrl } from "../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/auth";
import { ADMIN_DASHBOARD } from "../../../../app/routesMapAdmin";

export const Header = ({ onSignInClick, isAuthorized }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  return (
    <div className={styles.nav}>
      <div className="header-logo">
        <Link to="/">
          <img alt="logo" src={toAbsoluteUrl("/media/logos/new-logo.svg")} />
        </Link>
      </div>
      <div className="d-flex align-items-center w-50 justify-content-between">
        <div className="mr-10">
          <NavLink className="text-white font-weight-bolder font-size-h6" to="">
            Flights
          </NavLink>
          <NavLink
            className="text-white font-weight-bolder font-size-h6 ml-5"
            to=""
          >
            Hotels
          </NavLink>
          <NavLink
            className="text-white font-weight-bolder font-size-h6 ml-5"
            to=""
          >
            Umrah Package
          </NavLink>
        </div>
        {isAuthorized ? (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-danger mr-5"
              onClick={() =>
                history.push(isAdmin ? ADMIN_DASHBOARD : DASHBOARD)
              }
            >
              Dashboard
            </button>
            <div>
              <AccountButton handleClick={handleClick} />
            </div>
          </div>
        ) : (
          <button className="btn btn-danger" onClick={onSignInClick}>
            Sign In
          </button>
        )}
        <HeaderMenu anchorEl={anchorEl} handleClose={handleClose} />
      </div>
    </div>
  );
};

export const HeaderMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const { userId, sessionId } = useSelector(({ auth }) => auth);
  const logout = () => {
    dispatch(actions.logout({ userinUserSessionId: userId, id: sessionId }));
  };
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className="mt-15"
    >
      <MenuItem onClick={handleClose}>
        <button className="btn" onClick={logout}>
          Sign Out
        </button>
      </MenuItem>
    </Menu>
  );
};

export const AccountButton = ({ handleClick }) => {
  return (
    <button
      className="btn p-0 rounded-circle overflow-hidden"
      onClick={handleClick}
      aria-controls="simple-menu"
      aria-haspopup="true"
    >
      <img
        className="w-50px h-50px"
        src={toAbsoluteUrl("media/users/default.jpg")}
        alt=""
      />
    </button>
  );
};
