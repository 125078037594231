import React from "react";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { ADMIN_PAYMENTS_PAYMENT_BALANCE_LOG } from "../../../../../../app/routesMapAdmin";
import { paymentTypeLabel } from "../../../../../../redux/state/booking/bookingConstants";

export const PaymentBalanceRow = ({
  subPermissions,
  data,
  onWithdraw,
  onDeposit,
}) => {
  const { paymentMethod, availableBalance } = data;
  // const allBankAccountsSubPermission =
  //   adminPermissionsIds.payment.paymentMethodBalances.subPermissions;
  const rows = (
    <>
      <td className="text-left">
        <NavLink
          to={parseRoute(ADMIN_PAYMENTS_PAYMENT_BALANCE_LOG, {
            id: paymentMethod,
          })}
          className="btn text-primary text-hover-primary"
        >
          {paymentTypeLabel[paymentMethod] || "-"}
        </NavLink>
      </td>
      <td>
        <span>${availableBalance?.toFixed(2)}</span>
      </td>

      <td className="text-right">
        {/* <button
          hidden={
            !subPermissions.includes(allBankAccountsSubPermission.deposit.id)
          }
          title="Deposit"
          className="btn btn-icon btn-light btn-hover-success btn-xs"
          onClick={() => onDeposit(id)}
        >
          <span className="svg-icon svg-icon-success svg-icon-sm">
            <SVG
              title="Deposit"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
            />
          </span>
        </button>
        <button
          hidden={
            !subPermissions.includes(allBankAccountsSubPermission.withdraw.id)
          }
          title="withdraw"
          className="btn btn-icon btn-light btn-xs mx-3"
          onClick={() => onWithdraw(id)}
        >
          <span className="svg-icon svg-icon-sm">
            <SVG
              title="withdraw"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
            />
          </span>
        </button> */}
      </td>
    </>
  );
  return <tr>{rows}</tr>;
};
