import React from "react";
import { Paper } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import { getDateTime } from "../../../../../../_helpers";
import { useSelector } from "react-redux";
import useUserName from "../../../../../../../app/helpers/hooks/useUserName";

export const BookingNotesTable = () => {
  const { getUserName } = useUserName();
  const { list } = useSelector(({ bookingMng }) => bookingMng.bookingNotes);
  const columns = [
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "user",
      text: "User",
    },
    {
      dataField: "notes",
      text: "Note",
      classes: "text-muted",
    },
    {
      dataField: "date",
      text: "Date",
      classes: "font-weight-bold ",
    },
  ];
  const entities = list?.map(({ category, userId, notes, date, id }) => ({
    id,
    category,
    user: getUserName(userId),
    notes,
    date: `${getDateTime(date).date} ${getDateTime(date).time}`,
  }));
  return (
    <Paper className="position-relative">
      {/* <IconButton aria-label="Delete" className="position-absolute right-0">
        <DeleteIcon color="primary" />
      </IconButton> */}
      {/* <Typography variant="body1" className="position-absolute ml-12 mt-4">
        Select All
      </Typography> */}
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities || []}
        columns={columns}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}
        // selectRow={getSelectRow({
        //   entities: data,
        //   ids: ids,
        //   setIds: setIds,
        // })}
        // rowEvents={rowEvents}
        // rowStyle={{ cursor: "pointer" }}
        // {...paginationTableProps}
      >
        {/* <PleaseWaitMessage entities={entities} />
            <NoRecordsFoundMessage entities={entities} /> */}
      </BootstrapTable>
    </Paper>
  );
};
