import { isProd } from "../state/auth/authConstants";
import { bookingClient } from "./client";

// export const bookFlight = (params) =>
//   bookingClient.post("/booking/v1/Orders", params);

export const bookFlight = (params) =>
  isProd
    ? bookingClient.post("/booking/v1/Orders", params)
    : bookingClient.post("/TestBooknIssue", params);

// export const issueHoldBooking = ({ id, ...rest }) =>
//   bookingClient.post(`/booking/v1/orders/${id}/issueBookedSeat`, { ...rest });

export const issueHoldBooking = ({ id, ...rest }) =>
  isProd
    ? bookingClient.post(`/booking/v1/orders/${id}/issueBookedSeat`, {
        ...rest,
      })
    : bookingClient.post(`/TestIssueBookedSeat/${id}`, { ...rest });

export const payReissue = ({ id, ...rest }) =>
  bookingClient.post(`/booking/v1/reissue/ReIssuePaymentByAgency/${id}`, {
    ...rest,
  });
