import React from "react";
import SVG from "react-inlinesvg";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { getDateTime, toAbsoluteUrl } from "../../../../../_helpers";
import {
  promoAppliedOnLabels,
  promoDurationTypeEnums,
  promoDurationTypeLabels,
} from "../../../../../../redux/state/user/userConstants";

export const FEE_TYPE_REISSUE = 1;
export const FEE_TYPE_REFUND = 2;
export const FEE_TYPE_VOID = 3;
export const feeTypes = {
  [FEE_TYPE_REISSUE]: "ReIssue",
  [FEE_TYPE_REFUND]: "Refund",
  [FEE_TYPE_VOID]: "Void",
};

export const PromoCodeRow = ({ subPermissions, data, onEdit }) => {
  const {
    id,
    amount,
    validTo,
    code,
    numOfRedemptions,
    appliedOn,
    isPercentage,
    name,
    durationType,
    isUsed,
    isActive,
  } = data;
  const allServiceFeeSubPermission =
    adminPermissionsIds.systemManagement.serviceFees.subPermissions;
  const { date } = getDateTime(validTo);
  return (
    <tr>
      <td className="text-left text-primary">{code}</td>
      <td>{name || "-"}</td>
      <td>{`${amount}${isPercentage ? "%" : "$"}`}</td>
      <td>{promoAppliedOnLabels[appliedOn] || "-"}</td>
      <td>{promoDurationTypeLabels[durationType] || "-"}</td>
      <td>{numOfRedemptions ?? "-"}</td>
      <td>{durationType === promoDurationTypeEnums.forever ? "N/A" : date}</td>
      <td className={`text-center ${isUsed ? "text-success" : "text-danger"}`}>
        {isUsed ? "Yes" : "No"}
      </td>
      <td
        className={`text-center ${isActive ? "text-success" : "text-danger"}`}
      >
        {isActive ? "Active" : "Inactive"}
      </td>
      <td className="text-right">
        <button
          hidden={!subPermissions.includes(allServiceFeeSubPermission.edit.id)}
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        {/* <button
          title=""
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button> */}
      </td>
    </tr>
  );
};
