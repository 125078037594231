import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import styles from "./SignIn.module.scss";

export const Wrapper = ({ children }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div className="bg-white h-100 d-flex w-100">
      <div className="d-flex flex-column flex-grow-1">
        <Link to="/">
          <img
            className={styles.logo}
            src={toAbsoluteUrl("media/logos/new-logo-colored.svg")}
            alt="logo"
          />
        </Link>
        <div className={styles.signInWrapper}>{children}</div>
        <ul className={styles.links}>
          <li>
            <Link to="/">Terms</Link>
          </li>
          <li>
            <Link to="/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/">Contact Us</Link>
          </li>
        </ul>
      </div>
      {matchesSm && (
        <img
          className="h-100 w-"
          src={toAbsoluteUrl("/media/bg/Blur.jpg")}
          alt=""
        />
      )}
    </div>
  );
};
