import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { HOME, SIGNUP_USER_TYPE } from "../../../../app/routesMap";
import { Button, Input } from "../common";
import { actions } from "../../../../redux/state/auth";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";

import { Wrapper } from "./Wrapper";
import { NOTIFICATION_TYPE_ERROR } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const SignInByCode = () => {
  const { loading, success, failure } = useSelector(
    ({ auth }) => auth.loginByCode
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    code: Yup.string()
      .min(3, "Minimum 3 symbols")
      .required("Please enter the code sent to you to be able to login"),
  });
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: failure,
        })
      );
      dispatch(actions.clearLoginByCode());
    }
  }, [dispatch, failure]);
  useEffect(() => {
    if (success) {
      history.replace(HOME);
      dispatch(actions.authorizeLoginByCode());
      dispatch(actions.clearLoginByCode());
    }
  }, [dispatch, history, success]);
  const formik = useFormik({
    initialValues: { code: "" },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(actions.loginByCode(values));
    },
  });
  return (
    <Wrapper>
      <div className="mb-10">
        <Typography
          variant="h5"
          className="font-weight-bolder text-dark text-white"
        >
          Sign in By Code
        </Typography>
        <span className="text-muted font-weight-bold">
          New Here?
          <NavLink to={SIGNUP_USER_TYPE}>
            <span className="text-primary "> Create an Account</span>
          </NavLink>
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Code"
          showErrors={formik.touched.code}
          errorMessage={formik.errors.code}
          inputProps={formik.getFieldProps("code")}
          outlined={false}
          type="password"
          spacing
        />
        <div className="d-flex w-100">
          <Button
            loading={loading}
            className="mx-auto"
            variant="primary"
            type="submit"
          >
            Sign in
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
