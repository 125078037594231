import { Typography } from "@material-ui/core";
import React from "react";
import { v4 as uuid } from "uuid";
import { getBookingClassOptions } from "../../../../_assets/js/components/options";
import { Input, Select } from "../../common";

export const DateColumn = (
  cellContent,
  row,
  rowIndex,
  { getFieldProps, errors, touched, ids }
) => {
  return (
    <div key={uuid()} className="d-flex align-items-center">
      <label>Change date:</label>
      <div className="w-200px ml-3">
        <Input
          {...getFieldProps(`segments.${rowIndex}.date`)}
          showErrors={touched[`segments.${rowIndex}.date`]}
          errorMessage={errors[`segments.${rowIndex}.date`]}
          variant="secondary"
          fixedLabelWidth
          noErrorMessage
          type="date"
          disabled={!ids.includes(row.id)}
        />
      </div>
    </div>
  );
};

export const ClassColumn = (
  cellContent,
  row,
  rowIndex,
  { setFieldValue, getFieldProps, errors, touched, ids }
) => {
  const bookingClassOptions = getBookingClassOptions();

  const { value } = getFieldProps(`segments.${rowIndex}.bookingClass`);
  return (
    <div key={uuid()} className="d-flex align-items-center">
      <label>Class:</label>
      <div className="w-200px ml-3">
        <Select
          onChange={(value) =>
            setFieldValue(`segments.${rowIndex}.bookingClass`, value)
          }
          value={value}
          showErrors={touched[`segments.${rowIndex}.bookingClass`]}
          errorMessage={errors[`segments.${rowIndex}.bookingClass`]}
          variant="secondary"
          noErrorMessage
          fixedLabelWidthSmall
          options={bookingClassOptions}
          disabled={!ids.includes(row.id)}
        />
      </div>
    </div>
  );
};

export const RouteColumn = (cellContent, row, rowIndex) => {
  const { from, to } = row;
  return (
    <Typography
      variant="h6"
      className="text-primary"
    >{`${from} - ${to}`}</Typography>
  );
};

export const TicketNoColumn = (cellContent, row, rowIndex) => {
  const { ticketNo } = row;
  return (
    <Typography variant="body1" className="font-weight-bold">
      <span className="text-primary">Ticket NO: </span>
      {ticketNo}
    </Typography>
  );
};
