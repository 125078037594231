import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Input, Select, Tabs } from "../../common";
import {
  AGENCY,
  SUPPLIER,
} from "../../../../../redux/state/bookingMng/bookingMngConstants";
import useAgenciesOptions from "../../../../../app/helpers/hooks/useAgenciesOptions";
import useSuppliersOptions from "../../../../../app/helpers/hooks/useSuppliersOptions";
import { filterOptions } from "../../../../../redux/state/invoices/invoicesConstants";

const TabContent = ({ filterType, loading, name, handleReset, fields }) => {
  const { agencyOptions } = useAgenciesOptions();
  const { supplierOptions } = useSuppliersOptions();
  const { setFieldValue, values, getFieldProps } = fields;
  const inputComponent = useMemo(() => {
    switch (filterType) {
      case AGENCY:
        return (
          <Select
            value={values.agencyId}
            onChange={(v) => setFieldValue("agencyId", v)}
            placeholder="Select Agency"
            noErrorMessage
            options={agencyOptions}
            key="agencyId"
          />
        );
      case SUPPLIER:
        return (
          <Select
            value={values.supplierId}
            onChange={(v) => setFieldValue("supplierId", v)}
            placeholder="Select Supplier"
            noErrorMessage
            options={supplierOptions}
            key="supplierId"
          />
        );
      case "date":
        return (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Input
                {...getFieldProps("fromDate")}
                placeholder="Search"
                type={filterType}
                noErrorMessage
                name="fromDate"
                label="From"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                {...getFieldProps("toDate")}
                placeholder="Search"
                type={filterType}
                noErrorMessage
                name="toDate"
                label="To"
              />
            </Grid>
          </Grid>
        );

      default:
        return (
          <Input {...getFieldProps(name)} placeholder="Search" noErrorMessage />
        );
    }
  }, [
    filterType,
    values,
    name,
    agencyOptions,
    supplierOptions,
    getFieldProps,
    setFieldValue,
  ]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        {inputComponent}
      </Grid>
      <Grid item xs={12} md={4} className="d-flex align-items-end">
        <Button loading={loading} type="submit" variant="danger">
          Search
        </Button>
        <Button onClick={handleReset} className="ml-5" variant="danger-inverse">
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

const getTabsData = (props, ticketSummary, isAdmin, queue) => [
  {
    title: "Issue Date",
    content: <TabContent {...props} filterType="date" />,
    filter: "insertedOn",
  },
  {
    title: "Order Number",
    content: <TabContent {...props} name="bookingId" />,
    filter: "bookingId",
  },
  {
    title: "PNR",
    content: <TabContent {...props} name="gdsPnr" />,
    filter: "gdsPnr",
  },
  ...(isAdmin
    ? [
        {
          title: "Agency",
          content: (
            <TabContent {...props} name="agencyId" filterType={AGENCY} />
          ),
          filter: "agencyId",
        },
      ]
    : []),
  ...(isAdmin
    ? [
        {
          title: "Supplier",
          content: (
            <TabContent {...props} name="supplierId" filterType={SUPPLIER} />
          ),
          filter: "supplierId",
        },
      ]
    : []),
  // { title: "Order Form" },
  // ...(!queue
  //   ? [
  //       {
  //         title: "Ticket No",
  //         content: <TabContent {...props} />,
  //         filter: TICKET_NO,
  //       },
  //     ]
  //   : []),

  // { title: "settlement status" },
  // ...(!ticketSummary ? [{ title: "Agency" }, { title: "Subagency" }] : []),
];

export const getFilterObject = (columnName, value, option) => [
  ...(value
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

export const SearchCard = ({
  onSubmit,
  onChange,
  value,
  setFilter,
  ticketSummary,
  reset,
  queue,
  secondValue,
}) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  const fields = useFormik({
    initialValues: {
      bookingId: "",
      supplierId: "",
      gdsPnr: "",
      fromDate: "",
      toDate: "",
      agencyId: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      bookingId: Yup.string(),
      supplierId: Yup.string(),
      gdsPnr: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      agencyId: Yup.string(),
    }),
    onSubmit: ({
      fromDate,
      gdsPnr,
      toDate,
      supplierId,
      bookingId,
      agencyId,
    }) => {
      onSubmit([
        ...getFilterObject("bookingId", bookingId, filterOptions.Contains),
        ...getFilterObject("gdsPnr", gdsPnr, filterOptions.Contains),
        ...getFilterObject("agencyId", agencyId, filterOptions.IsEqualTo),
        ...getFilterObject("supplierId", supplierId, filterOptions.IsEqualTo),
        ...getFilterObject(
          "insertedOn",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "insertedOn",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const { handleSubmit, setValues, values } = fields;
  const handleReset = () => {
    setValues({
      bookingId: "",
      supplierId: "",
      gdsPnr: "",
      fromDate: "",
      toDate: "",
      agencyId: "",
    });
    reset();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Tabs
        tabsClassName="justify-content-start"
        data={getTabsData({ fields, handleReset }, ticketSummary, isAdmin)}
        label="Search By"
        dangerTabs
        setFilter={setFilter}
      />
    </form>
  );
};
// export const SearchCard = ({
//   onSubmit,
//   onChange,
//   value,
//   setFilter,
//   ticketSummary,
//   reset,
//   queue,
//   secondValue,
// }) => {
//   const { isAdmin } = useSelector(({ company }) => company.companyData);
//   console.log({ value });
//   return (
//     <Fragment>
//       <Tabs
//         tabsClassName="justify-content-start"
//         data={getTabsData(
//           { onSubmit, onChange, value, reset, queue, secondValue },
//           ticketSummary,
//           isAdmin
//         )}
//         label="Search By"
//         dangerTabs
//         setFilter={setFilter}
//       />
//     </Fragment>
//   );
// };
