import * as Yup from "yup";
import { ORGANIZATION_TYPE_SUBSCRIBER } from "../../../../../redux/state/company/companyConstants";

export const getCompanySchema = (companyType) =>
  Yup.object().shape({
    name: Yup.string().required("Field is required"),
    address: Yup.string().required("Field is required"),
    ...(companyType === ORGANIZATION_TYPE_SUBSCRIBER
      ? {}
      : {
          businessManager: Yup.string().required("Field is required"),
          salesEmail: Yup.string().required("Field is required"),
          bmCountryCode: Yup.string().required(),
          mobileNumber: Yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required("A phone number is required"),
          iata: Yup.string(),
        }),
    registeredPhoneCountryCode: Yup.string().required(),
    registeredPhone: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .required("A phone number is required"),
    country: Yup.string().required("Field is required"),
    city: Yup.string().required("Field is required"),
    recoveryEmail: Yup.string().required("Field is required"),
    emergencyContactPerson: Yup.string().required("Field is required"),
    emergencyCountryCode: Yup.string().required(),
    emergencyContactPhone: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .required("A phone number is required"),
  });
