import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const PackageTypesRow = ({ data, onDelete, onEdit }) => {
  const { id, name } = data;
  // const allBanksSubPermission =
  //   adminPermissionsIds.payment.banks.subPermissions;
  return (
    <tr>
      <td className="text-left text-primary">{id || "-"}</td>
      <td>{name || "-"}</td>
      <td className="text-right">
        <button
          // hidden={!subPermissions.includes(allBanksSubPermission.edit.id)}
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span title="Edit" className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button
          // hidden={!subPermissions.includes(allBanksSubPermission.delete.id)}
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span title="Delete" className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </td>
    </tr>
  );
};
