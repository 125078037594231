import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import {
  ADMIN_COMPLETED_ORDER_ACCOUNTS,
  ADMIN_COMPLETED_ORDER_FLIGHT,
  ADMIN_COMPLETED_ORDER_NOTES,
  ADMIN_COMPLETED_ORDER_SUMMARY,
} from "../../../../../../app/routesMapAdmin";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as invoicesActions } from "../../../../../../redux/state/invoices";
import { OrderDetailsHeader } from "../../../ticketSummary/TicketDetails";
import { BookingNotes } from "./bokingNotes/BookingNotes";
import { BookingDetailsTabs } from "./BookingDetailsTabs";
import { OrderDetailsShimmer } from "./orderDetailsShimmer/OrderDetailsShimmer";
// import { OrderETicket } from "./orderEticket/OrderETicket";
import { OrderFlight } from "./orderFlight/OrderFlight";
// import { OrderInvoice } from "./orderInvoice/OrderInvoice";
import { OrderSummary } from "./orderSummary/OrderSummary";
import { OrderAccounts } from "./orderAccounts/OrderAccounts";
import { OrderDetailsBar } from "./OrderDetailsBar";
import {
  BookingHold,
  BookingIssue,
} from "../../../../../../redux/state/booking/bookingConstants";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

export const IssuedOrderDetails = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading: getDataLoading, data } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};
  const { data: invoice, loading: invoiceLoading } = useSelector(
    ({ invoices }) => invoices.specificInvoice
  );
  const { bookingStatus, orderNo } = data || {};
  useEffect(() => {
    dispatch(actions.getSpecificOrderByBookingId({ bookingId: id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id]);
  useEffect(() => {
    if (orderNo)
      dispatch(invoicesActions.getInvoiceByInvoiceNo({ invoiceNo: orderNo }));
  }, [orderNo, dispatch]);
  const allTrackingSubPermission =
    adminPermissionsIds.bookingManagement.ticketTracking.subPermissions;

  const tabsRoutes = [
    {
      title: "Summary",
      route: ADMIN_COMPLETED_ORDER_SUMMARY,
      component: OrderSummary,
    },
    {
      title: "Flight",
      route: ADMIN_COMPLETED_ORDER_FLIGHT,
      component: OrderFlight,
    },
    {
      title: "Booking Notes",
      route: ADMIN_COMPLETED_ORDER_NOTES,
      component: BookingNotes,
    },
    {
      title: "Accounts",
      route: ADMIN_COMPLETED_ORDER_ACCOUNTS,
      component: OrderAccounts,
      hidden: !(
        bookingStatus === BookingIssue ||
        bookingStatus === BookingHold ||
        subPermissions.includes(allTrackingSubPermission.actions.id)
      ),
    },
  ];
  return (
    <>
      {getDataLoading || invoiceLoading ? (
        <OrderDetailsShimmer />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrderDetailsHeader subPermissions={subPermissions} />
          </Grid>
          <Grid item xs={12}>
            <OrderDetailsBar />
          </Grid>
          <Grid item xs={12}>
            <BookingDetailsTabs
              tabsRoutes={tabsRoutes.filter(({ hidden }) => !hidden)}
            />
          </Grid>
          <Grid item xs={12}>
            <Switch>
              {tabsRoutes
                .filter(({ hidden }) => !hidden)
                .map(({ component: Component, route }) => {
                  const props =
                    route === ADMIN_COMPLETED_ORDER_SUMMARY
                      ? { data, invoice }
                      : {};
                  return (
                    <Route
                      key={route}
                      path={route}
                      render={() => <Component {...props} />}
                    />
                  );
                })}
              <Redirect to={ADMIN_COMPLETED_ORDER_SUMMARY} />
            </Switch>
          </Grid>
        </Grid>
      )}
    </>
  );
};
