import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./supplierAction";

const initialState = {
  suppliers: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  filteredSuppliers: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  supplierBalance: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  supplierApi: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  specificSupplier: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  create: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  delete: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  edit: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  addDeductAmount: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  supplierBalanceHistory: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  supplierRefundTopUps: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  editSupplierTopUIp: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
};

export const supplierReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.EDIT_SUPPLIER_TOP_UP.REQUESTED:
        state.editSupplierTopUIp.loading = true;
        break;
      case actions.EDIT_SUPPLIER_TOP_UP.SUCCEEDED:
        state.editSupplierTopUIp.loading = false;
        state.editSupplierTopUIp.success = true;
        const index = state.supplierRefundTopUps?.list?.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.supplierRefundTopUps.list[index] = payload.args;
        break;
      case actions.EDIT_SUPPLIER_TOP_UP.FAILED:
        state.editSupplierTopUIp.loading = false;
        state.editSupplierTopUIp.failure = payload.error;
        break;
      case actions.EDIT_SUPPLIER_TOP_UP.CLEAR:
        state.editSupplierTopUIp = initialState.editSupplierTopUIp;
        break;
      case actions.GET_SUPPLIER_REFUND_TOP_UPS.REQUESTED:
      case actions.FILTER_SUPPLIER_REFUND_TOP_UPS.REQUESTED:
        state.supplierRefundTopUps.loading = true;
        break;
      case actions.GET_SUPPLIER_REFUND_TOP_UPS.SUCCEEDED:
      case actions.FILTER_SUPPLIER_REFUND_TOP_UPS.SUCCEEDED:
        state.supplierRefundTopUps.loading = false;
        state.supplierRefundTopUps.success = true;
        const { data, ...rest } = payload.data;
        state.supplierRefundTopUps.list = data;
        state.supplierRefundTopUps.paginationInfo = rest;
        break;
      case actions.GET_SUPPLIER_REFUND_TOP_UPS.FAILED:
      case actions.FILTER_SUPPLIER_REFUND_TOP_UPS.FAILED:
        state.supplierRefundTopUps.loading = false;
        state.supplierRefundTopUps.failure = payload.error;
        break;
      case actions.FILTER_SUPPLIERS.REQUESTED:
        state.filteredSuppliers.loading = true;
        break;
      case actions.FILTER_SUPPLIERS.SUCCEEDED:
        state.filteredSuppliers.loading = false;
        state.filteredSuppliers.success = true;
        const { data: suppliersList, ...paginationInfo } = payload.data;
        state.filteredSuppliers.list = suppliersList;
        state.filteredSuppliers.paginationInfo = paginationInfo;
        break;
      case actions.FILTER_SUPPLIERS.FAILED:
        state.filteredSuppliers.loading = false;
        state.filteredSuppliers.failure = payload.error;
        break;
      case actions.GET_ALL.REQUESTED:
        state.suppliers.loading = true;
        break;
      case actions.GET_ALL.SUCCEEDED:
        state.suppliers.loading = false;
        state.suppliers.success = true;
        state.suppliers.list = payload.data.reverse();
        break;
      case actions.GET_ALL.FAILED:
        state.suppliers.loading = false;
        state.suppliers.failure = payload.error;
        break;
      case actions.GET_ONE.REQUESTED:
        state.specificSupplier.loading = true;
        break;
      case actions.GET_ONE.SUCCEEDED:
        state.specificSupplier.loading = false;
        state.specificSupplier.success = true;
        state.specificSupplier.supplierBalance = payload.data.supplierBalance;
        state.specificSupplier.data = payload.data.supplierManagement;
        break;
      case actions.GET_ONE.FAILED:
        state.specificSupplier.loading = false;
        state.specificSupplier.failure = payload.error;
        break;
      case actions.CREATE_SUPPLIER_API.REQUESTED:
      case actions.CREATE_SUPPLIER_BALANCE.REQUESTED:
      case actions.CREATE.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE_SUPPLIER_API.SUCCEEDED:
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        // state.suppliers.list = [payload.data, ...state.suppliers.list];
        break;
      case actions.CREATE_SUPPLIER_BALANCE.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        state.supplierBalance.data = payload.data;
        break;
      case actions.CREATE_SUPPLIER_API.FAILED:
      case actions.CREATE_SUPPLIER_BALANCE.FAILED:
      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.CREATE.CLEAR:
        state.create = initialState.create;
        break;

      case actions.EDIT_SUPPLIER_API.REQUESTED:
      case actions.EDIT_SUPPLIER_BALANCE.REQUESTED:
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT_SUPPLIER_API.SUCCEEDED:
      case actions.EDIT_SUPPLIER_BALANCE.SUCCEEDED:
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        // const index = state.suppliers.list.findIndex(
        //   ({ id }) => id === payload.args.id
        // );
        // state.suppliers.list[index] = payload.args;
        break;
      case actions.EDIT_SUPPLIER_API.FAILED:
      case actions.EDIT_SUPPLIER_BALANCE.FAILED:
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.GET_SUPPLIER_BALANCE_HISTORY.REQUESTED:
        state.supplierBalanceHistory.loading = true;
        break;
      case actions.GET_SUPPLIER_BALANCE_HISTORY.SUCCEEDED:
        state.supplierBalanceHistory.loading = false;
        state.supplierBalanceHistory.success = true;
        state.supplierBalanceHistory.list = payload.data;
        break;
      case actions.GET_SUPPLIER_BALANCE_HISTORY.FAILED:
        state.supplierBalanceHistory.loading = false;
        state.supplierBalanceHistory.failure = payload.error;
        break;
      case actions.GET_SUPPLIER_BALANCE.REQUESTED:
        state.supplierBalance.loading = true;
        break;
      case actions.GET_SUPPLIER_BALANCE.SUCCEEDED:
        state.supplierBalance.loading = false;
        state.supplierBalance.success = true;
        state.supplierBalance.data = payload.data;
        break;
      case actions.GET_SUPPLIER_BALANCE.FAILED:
        state.supplierBalance.loading = false;
        state.supplierBalance.failure = payload.error;
        break;
      case actions.GET_SUPPLIER_BALANCE.CLEAR:
        state.supplierBalance = initialState.supplierBalance;
        break;
      case actions.GET_SUPPLIER_API.REQUESTED:
        state.supplierApi.loading = true;
        break;
      case actions.GET_SUPPLIER_API.SUCCEEDED:
        state.supplierApi.loading = false;
        state.supplierApi.success = true;
        state.supplierApi.data = payload.data;
        break;
      case actions.GET_SUPPLIER_API.FAILED:
        state.supplierApi.loading = false;
        state.supplierApi.failure = payload.error;
        break;
      case actions.GET_SUPPLIER_API.CLEAR:
        state.supplierApi = initialState.supplierApi;
        break;
      case actions.DELETE.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.suppliers.list = state.suppliers.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE.CLEAR:
        state.delete = initialState.delete;
        break;
      case actions.ADD_TO_SUPPLIER_BALANCE.REQUESTED:
      case actions.DEDUCT_FROM_SUPPLIER_BALANCE.REQUESTED:
        state.addDeductAmount.loading = true;
        break;
      case actions.ADD_TO_SUPPLIER_BALANCE.SUCCEEDED:
      case actions.DEDUCT_FROM_SUPPLIER_BALANCE.SUCCEEDED:
        state.addDeductAmount.loading = false;
        state.addDeductAmount.success = true;
        break;
      case actions.ADD_TO_SUPPLIER_BALANCE.FAILED:
      case actions.DEDUCT_FROM_SUPPLIER_BALANCE.FAILED:
        state.addDeductAmount.loading = false;
        state.addDeductAmount.failure = payload.error;
        break;
      case actions.ADD_TO_SUPPLIER_BALANCE.CLEAR:
      case actions.DEDUCT_FROM_SUPPLIER_BALANCE.CLEAR:
        state.addDeductAmount = initialState.addDeductAmount;
        break;

      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
