import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

import { Button, LoadingSpinner } from "../../common";
import { actions } from "../../../../../redux/state/payment";
import { actions as banksActions } from "../../../../../redux/state/banks";

import { TopUpEditModal } from "./topUpEdit/TopUpEditModal";
import { getDateTime } from "../../../../_helpers/date";
import useCompanyName from "../../../../../app/helpers/hooks/useCompanyName";
import useExportUser, {
  exportUserTypesMap,
} from "../../../../../app/helpers/hooks/useExportUser";
import useSubPermissions from "../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../data/adminPermissionIds";
import { TopupsReportSearchCard } from "../reports/TopupsReport/TopupsReportSearchCard";
import { Pagination } from "@material-ui/lab";
import useBankAccountData from "../../../../../app/helpers/hooks/useBankAccountData.js";
import { TopUpFormModal } from "../../payment/TopUpFormModal";
import useUserName from "../../../../../app/helpers/hooks/useUserName";
import { parseRoute } from "../../../../../app/helpers/formatters";
import { ADMIN_PAYMENTS_TOP_UP_CLEARED_INVOICES } from "../../../../../app/routesMapAdmin";
import { NavLink } from "react-router-dom";
import { ResponsiveRow } from "../../common/ResponsiveRow/ResponsiveRow";

export const topupBadgeClassNames = {
  0: "text-warning",
  1: "text-danger",
  2: "text-success",
};
export const topupBadgeText = {
  0: "Pending",
  1: "Rejected",
  2: "Approved",
};
const TableRow = ({
  hideActions,
  data,
  onApprove,
  onReject,
  getCompanyName,
  getBankAccountData,
  getUserName,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    status,
    createdAt,
    paymentAmount,
    remarks,
    verificationRemark,
    paymentProof,
    id,
    companyId,
    bankAccountId,
    approvedByUserInTopUpId,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const { banks, accountNo } = getBankAccountData(bankAccountId);
  const rows = (
    <>
      <td className="text-left text-primary">
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(ADMIN_PAYMENTS_TOP_UP_CLEARED_INVOICES, { id })}
        >
          <span className="font-weight-bold">{id}</span>
        </NavLink>
      </td>
      <td>{getCompanyName(companyId) || "-"}</td>
      {matchesSm && (
        <>
          <td>
            <div>
              <p className="mb-0">{date}</p>
              <p className="text-muted font-weight-normal mb-0">{time}</p>
            </div>
          </td>
          <td className="text-primary">
            <a target="_blank" rel="noopener noreferrer" href={paymentProof}>
              View Proof
            </a>
          </td>
          <td>{remarks || "-"}</td>
        </>
      )}
      <td>${paymentAmount?.toFixed(2) || "-"}</td>
      {matchesSm && (
        <td>
          <div>
            <p className="mb-0">{accountNo}</p>
            <p className="text-muted font-weight-normal mb-0">{banks?.name}</p>
          </div>
        </td>
      )}
      <td>
        <span className={topupBadgeClassNames[status]}>
          {topupBadgeText[status]}
        </span>
      </td>
      {matchesSm && (
        <>
          <td>{verificationRemark || "-"}</td>
          <td className="text-left ">
            {getUserName(approvedByUserInTopUpId) || "-"}
          </td>

          <td>
            <div hidden={hideActions || status !== 0}>
              <button
                title="Accept Top up"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => onApprove(id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    title="Accept Top up"
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                  />
                </span>
              </button>
              <button
                title="Reject Top up"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => onReject(id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    title="Reject Top up"
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </span>
              </button>
            </div>
          </td>
        </>
      )}
    </>
  );
  const mobileRowDetails = [
    { label: "Agency Name", value: getCompanyName(companyId) || "-" },
    { label: "Requested Date", value: `${date} ${time}` },
    {
      label: "Payment proof",
      value: (
        <a target="_blank" rel="noopener noreferrer" href={paymentProof}>
          View Proof
        </a>
      ),
    },
    { label: "remark", value: remarks || "-" },
    { label: "Payment Amount", value: `$${paymentAmount?.toFixed(2) || "-"}` },
    { label: "Bank Account", value: `${accountNo} ${banks?.name}` },
    { label: "Status", value: topupBadgeText[status] },
    { label: "Verification remark", value: verificationRemark || "-" },
    {
      label: "Approved By",
      value: getUserName(approvedByUserInTopUpId) || "-",
    },
    {
      label: "Actions",
      value: (
        <div hidden={hideActions || status !== 0}>
          <button
            title="Accept Top up"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onApprove(id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Accept Top up"
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </button>
          <button
            title="Reject Top up"
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => onReject(id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                title="Reject Top up"
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </button>
        </div>
      ),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const TopUps = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const {
    getBankAccountData,
    loading: allBankAccountsLoading,
  } = useBankAccountData();

  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { getUserName, loading: usersLoading } = useUserName();
  const { fromDate, toDate } = dateRange;
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { paginationInfo } = useSelector(({ payment }) => payment.topUps);
  const { totalPages } = paginationInfo ?? {};
  const { exportToExcel, loading: downloadLoading } = useExportUser();
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { list, loading } = useSelector(({ payment }) => payment.topUps);
  useEffect(() => {
    dispatch(banksActions.getAllPaymentMethods());
    dispatch(banksActions.getAllBankAccounts());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      actions.filterAllTopUps({ filterParam: [...appliedFilters], pageNumber })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);

  const onReject = (id) => {
    setIsReject(true);
    setShowModal(true);
    setSelectedId(id);
  };
  const onApprove = (id) => {
    setIsReject(false);
    setShowModal(true);
    setSelectedId(id);
  };
  const getSelectBooking = () => list?.filter(({ id }) => selectedId === id)[0];
  const onExport = () => {
    exportToExcel({
      type: exportUserTypesMap.topUp,
      filterParam: [...appliedFilters, {}],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const hideActions = useMemo(
    () =>
      !subPermissions.includes(
        adminPermissionsIds.payment.topUps.subPermissions.approveOrReject.id
      ),
    [subPermissions]
  );
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterAllTopUps({
        filterParam: [...filters],
      })
    );
  };
  const tableContent = list?.map((item, index) => (
    <TableRow
      key={index}
      data={item}
      onApprove={onApprove}
      onReject={onReject}
      getCompanyName={getCompanyName}
      hideActions={hideActions}
      getBankAccountData={getBankAccountData}
      getUserName={getUserName}
    />
  ));
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.filterAllTopUps({}));
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const showAddTopup = subPermissions.includes(
    adminPermissionsIds.payment.topUps.subPermissions.addTopup.id
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <TopupsReportSearchCard
            setDateRange={setDateRange}
            onSearch={search}
            isAll
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <TopUpFormModal open={openModal} setOpen={setOpenModal} />
          <div className="d-flex justify-content-between align-items-start mb-2">
            <div>
              <Typography variant="h5" className="font-weight-bold mb-5">
                Top Ups
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <div className="d-flex">
              {showAddTopup && (
                <Button
                  onClick={() => setOpenModal(true)}
                  variant="primary-inverse"
                  className="mr-3"
                  smallPadding
                >
                  + Add Top up
                </Button>
              )}
              <Button
                onClick={onExport}
                loading={downloadLoading}
                variant="danger"
              >
                Export
              </Button>
            </div>
          </div>
          <TopUpEditModal
            onHide={() => setShowModal(false)}
            show={showModal}
            data={getSelectBooking()}
            isReject={isReject}
            getCompanyName={getCompanyName}
            getBankAccountData={getBankAccountData}
          />
          {loading ||
          allCompaniesLoading ||
          allBankAccountsLoading ||
          usersLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-vertical-center text-center font-weight-bold">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">ID</th>
                    <th>Agency Name</th>
                    {matchesSm && (
                      <>
                        <th className="min-w-100px">Requested Date</th>
                        <th className="min-w-100px">Payment proof </th>
                        <th>remark</th>
                      </>
                    )}
                    <th>Payment Amount</th>
                    {matchesSm && <th>Bank Account</th>}
                    <th>Status</th>
                    {matchesSm && (
                      <>
                        <th>Verification remark</th>
                        <th>Approved By</th>
                        <th className="min-w-120px">Actions</th>{" "}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Records to show
                </Typography>
              )}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
