import React from "react";
import { getDateTime } from "../../../../../../_helpers/date";

export const HistoryTableRow = ({ data, getCompanyName, getUserName }) => {
  const {
    id,
    amount,
    date: createdAt,
    availableBalance,
    type,
    agencyId,
    remarks,
    createdBy,
    paymentproofUrl,
  } = data;
  const { time, date } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  const agencyName = getCompanyName(agencyId);
  const userName = getUserName(createdBy);
  return (
    <tr>
      <td className="text-primary">{id || "-"}</td>
      <td>{agencyName || "-"}</td>
      <td>{userName || "-"}</td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </div>
      </td>
      <td className="text-primary">
        {paymentproofUrl ? (
          <a target="_blank" rel="noopener noreferrer" href={paymentproofUrl}>
            View Proof
          </a>
        ) : (
          "-"
        )}
      </td>

      <td>{remarks || "-"}</td>
      <td>
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {amount?.toFixed(2)}
        </span>
      </td>
      <td>{availableBalance?.toFixed(2) || "-"}</td>
    </tr>
  );
};
