import {
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { HashLink } from "react-router-hash-link";
import { Wrapper } from "../../../_metronic/layout/components/ticketSummary/common/Wrapper";
import { TERMS_AND_CONDITIONS } from "../../routesMap";
import styles from "./helpCenter.module.scss";

const parseTextIntoId = (text) => text.replaceAll(" ", "_");

export const Navigator = () => {
  return (
    <Paper className="p-7 w-100">
      {textMap.map(({ title, list }) => (
        <>
          <HashLink
            className="text-dark text-hover-primary"
            to={`${TERMS_AND_CONDITIONS}#${parseTextIntoId(title)}`}
          >
            {title}
          </HashLink>
          <ul>
            {list.map(({ title }) => (
              <li>
                <HashLink
                  className="text-dark text-hover-primary"
                  to={`${TERMS_AND_CONDITIONS}#${parseTextIntoId(title)}`}
                >
                  {title}
                </HashLink>
              </li>
            ))}
          </ul>
        </>
      ))}
    </Paper>
  );
};

export const TermsAndConditions = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Wrapper noContainer>
      <div className={styles.background}>
        <Typography variant="h3" className="font-weight-bolder text-white">
          Terms and Conditions
        </Typography>
      </div>
      <div className={styles.container}>
        <div className="p-7 d-flex flex-column">
          <Typography variant="h5" className="mb-7">
            Terms and conditions
          </Typography>
          <Typography variant="body1">
            <Box sx={{ lineHeight: 2 }}>
              The end user must be agreed with “Term and Conditions” of Flyme247
              whenever, a request form has been submitted to Flyme247 trading
              Plat form.
            </Box>
            <Box sx={{ lineHeight: 2 }} className="mt-2">
              Before proceeding on Flyme247 B2B, Client must need to accept and
              fully understand all the clauses, terms, and condition
              particularly the bullet and highlighted points.
            </Box>
            <Box sx={{ lineHeight: 2 }} className="mt-2">
              Submitted form will be considered with terms and condition
              acceptance. These terms and conditions will be considered for any
              legal authority and other liability in future if occurs, Flyme247
              have all the rights to revamp, change and update all the clauses
              of this terms and conditions on Flyme247 for client’s review.
            </Box>
          </Typography>
        </div>
        <Grid container>
          {!matchesMd && (
            <Grid
              item
              xs={12}
              md={4}
              className="d-flex flex-column justify-content-start"
            >
              <Navigator />
            </Grid>
          )}
          <Grid item xs={12} md={8} className="pr-7">
            {textMap.map((section) => (
              <Section {...section} />
            ))}
          </Grid>
          {matchesMd && (
            <Grid
              item
              xs={12}
              md={4}
              className="d-flex flex-column justify-content-start"
            >
              <Navigator />
            </Grid>
          )}
        </Grid>
      </div>
    </Wrapper>
  );
};

const textMap = [
  {
    title: "User Registration and Account Security",
    list: [
      {
        title: "Registration Requirement",
        bullets: [
          "User Acceptance: All clients are fully authorizing and have civil rights as agreed to register for an account to use Flyme247 services. If not, Flyme247 remains the right for cancellation of registration and suspension of account for third party.",
        ],
      },
      {
        title: "Registration Procedures",
        bullets: [
          "Clients agree to provide all the necessary personal information including names, email ids, and other valid information that are required for successful registration on Flyme247 B2B account. ",
          "All provided information must be real and verifiable in case, the provided information needs to change, client is responsible to update the changes on Flyme247 B2B portal. ",
          "Flyme247 has the rights to verify the information periodically and randomly. On successfully completion of examination, Clients will get the access to browse Flyme247 B2B portal. ",
          "Flyme247 has complete rights to suspend, revoke and hold the accounts when information is untrue, invalid or incomplete by technical or manual inspection. ",
        ],
      },
      {
        title: "Account Security",
        bullets: [
          "It’s the responsibility of clients to keep the account user credential after registration. Clients will be responsible for all the activities done through its account. ",
          "It is strictly prohibited to transfer, lease, rent the account to any third party without prior written permission from Flyme247. Flyme247 has all the rights to suspend the account in case of violation of terms and conditions. ",
        ],
      },
    ],
  },
  {
    title: "Services and Policies",
    list: [
      {
        subTitle: "Flyme247 will provide the below services to its clients:",
        title: "Flyme247 Services ",
        bullets: ["Flight search", "Seat Reservation", "Ticketing"],
        postPoints:
          "Detail of services can be found by browsing Flyme247 B2B portal. Flyme247 is happy to assist with professional staff for consultative services within office hours as mentioned on Flyme247.",
      },
    ],
  },
  {
    title: "Service Regulations",
    list: [
      {
        title: "",
        bullets: [
          "Clients agree to follow all the laws and regulations of Somalia and not allowed to violate, breach and harm the terms and condition of Flyme247. That includes but not limited to send or receive harmful information, illegal speech, illegal access, or misuse Flyme247 company information like email tiles, money laundering, business confidentiality, personal and secret information of Flyme247 B2B. ",
          "Technically they are committed not to disturb the normal operations, system hacking of Flyme247 or national information security system. ",
          "In Case of violation and non-authorized or illegal activities on Flyme247 B2B. Flyme247 has all the reserves and rights to suspend, block or delete the information. Clients will be responsible for all its behaviour and speech and legally responsible for damage compensation.  ",
          "Clients must have to follow the trading rules and regulations while trading on Flyme247. ",
          "Flyme247 remains all the rights to rectify the information displayed on Flyme247 B2B when finding mistakes of them. ",
          "The account logs in to Flyme247 B2B will be valid for only one year in case of not used, Flyme247 have all the legal rights to suspend, hold or revoke the access for client. ",
        ],
      },
    ],
  },
  {
    title: "Trading Rules",
    subheader:
      "User promises to follow and obey all trading rules as following",
    list: [
      {
        title: "Browsing Trading Information",
        bullets: [
          "Clients need to read all the details and information carefully while making a deal on Flyme247 B2B. including but not limited to time, effective dates, currency, refund, and change policy unless the user accept all the required mentioned trading information. Also, users need to acknowledge and responsible for tickets change charges, additional cost and some extend unchangeable. Clients need to make sure about the fare rules of tickets before proceeding. ",
        ],
      },
      {
        title: "Confirmation of Trading ",
        bullets: [
          "All confirmed orders by clients will be considered aligned with understanding of trading information, agree to trading rules and regulation of Flyme247. ",
        ],
      },
      {
        title: "User Information Protection and Authorization",
        bullets: [
          "Flyme247 consider confidentiality and protection for users’ individual information. Fyme247 B2B will directly or indirectly interact with clients.  ",
          "For the better use of Flyme247 B2B, user shall authorize Flyme247 to use provided information for betterment and development of services. ",
        ],
      },
      {
        title: "Breach and Disposal",
        subTitle:
          "Below listed behavior will be considered as violation of Terms and Conditions.",
        bullets: [
          "user breach the laws and regulation while using airline services. ",
          "Users violate the terms and conditions. ",
          'User violates the rules of bookings and air tickets; The violation includes but is not limited to:\n \t - seat holding, even airline has cancelled the seat and user is not removing it before the departure "Origin and Destination abuse" .\n \t - Booking additional sectors within an already ticketed PNR.\n \t - ooking a separate, short, unrelated flight at the end of a large itinerary, causing the GDS to price the fare with a notably lower fuel surcharge. \n \t - Rebooking and ticket reissue not completed at the same time, or within a set time frame. ',
        ],
      },
      {
        title: "Alteration, Suspension and Termination of Services ",
        bullets: [
          "If service improvement or product development happens, Flyme247 will notify the users in advance by publishing a post on the bulletin board on Flyme247 B2B.",
          "Flyme247 will not be responsible for any damage due to users’ failure of checking the notification. ",
          "There will be no obligation of Flyme247 for the change of alternation. ",
          "Flyme247 have all the rights to decide, implement and process its own strategy. Once there is a decision of suspension of some services, Flyme247 will notify the trading parties and decide to close the transactions orders based on trading parties’ agreement. ",
        ],
      },
      {
        title:
          "Modification and Termination of this Terms and Conditions Modification of this Terms and Conditions ",
        bullets: [
          "All rights for modification, upgrading of this term and conditions are reserved with Flyme247 to protect the interest of consumers according to the change of laws and regulations. ",
          "Flyme247 will announce in advance with the changes in law and rules. ",
          "As user continues to use services of Flyme247 after the modification being effective, user agrees to accept any modification of Terms and Conditions and its supplementary agreement. If user does not agree to the modification, the user shall stop using services of Flyme247 since the effective date of modification. ",
        ],
      },
      {
        title: "Termination of this Terms and Conditions ",
        bullets: [
          "All rights are with Flyme247 for the suspension of the account based on these terms and conditions. With accordance to the legal procedure, Flyme247 has the right to terminate its services.",
          "Once the terms and conditions are terminated, there will be no obligation of Flyme247 to provide any services to the users including but not limited to keep the users account information or communication. ",
        ],
      },
      {
        title: "Applicable Law and Jurisdiction",
        bullets: [
          "All concerns, dispute and challenges will be settled by mutual negotiation between Flyme247 and Clients. In case it’s not resolved all terms and conditions enter into force, revision, supplement, termination, execution, and dispute settlement according to the applicable law in Somalia. ",
        ],
      },
    ],
  },
];

const Section = ({ title, list, subheader }) => {
  return (
    <div id={parseTextIntoId(title)} className="p-7 d-flex flex-column">
      <Typography variant="h4" className=" mb-1" line>
        {title}
      </Typography>
      <Typography variant="body1" className=" mb-7" line>
        {subheader}
      </Typography>
      {list.map(({ title, bullets, subTitle, postPoints }) => (
        <div id={parseTextIntoId(title)}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{subTitle}</Typography>
          <ul>
            {bullets.map((text) => (
              <li>
                <Typography style={{ color: "#77838F" }} variant="body1">
                  <Box
                    sx={{ lineHeight: 2 }}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Typography>
              </li>
            ))}
          </ul>
          <Typography variant="body1">{postPoints}</Typography>
        </div>
      ))}
    </div>
  );
};
