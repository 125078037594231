import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_helpers";

import { Button, LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/companyCredit";
import { actions as companyActions } from "../../../../../../redux/state/company";
import { TopUpEditModal } from "./topUpEdit/TopUpEditModal";
import { getDateTime } from "../../../../../_helpers/date";
import { useCallback } from "react";
import { creditBadgeClassNames } from "../../../payment/credit/Credit";
import { CREDIT_STATUS_PENDING } from "../../../../../../redux/state/companyCredit/companyCreditConstants";
import useExportAccounting, {
  exportAccountingTypesMap,
} from "../../../../../../app/helpers/hooks/useExportAccounting";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { Pagination } from "@material-ui/lab";
import { CreditSearchCard } from "../../../payment/credit/CreditSearchCard";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

const TableRow = ({
  data,
  onApprove,
  onReject,
  getCompanyName,
  hideActions,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { status, createdAt, creditAmount, remarks, agencyId, id } = data;
  const { date, time } = getDateTime(createdAt);
  const rows = (
    <>
      <td className="text-left text-primary">{id || "-"}</td>
      <td>{getCompanyName(agencyId) || "-"}</td>
      {matchesSm && (
        <>
          <td>
            <div>
              <p className="mb-0">{date}</p>
              <p className="text-muted font-weight-normal mb-0">{time}</p>
            </div>
          </td>
          <td>{remarks || "-"}</td>
        </>
      )}
      <td>${creditAmount?.toFixed(2) || "-"}</td>
      <td>
        <span className={creditBadgeClassNames[status]}>{status}</span>
      </td>
      {matchesSm && (
        <td>
          <div hidden={hideActions || status !== CREDIT_STATUS_PENDING}>
            <button
              title="Accept Credit Request"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => onApprove(id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  title="Accept Credit Request"
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>
            </button>
            <button
              title="Reject Credit Request"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => onReject(id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  title="Reject Credit Request"
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </button>
          </div>
        </td>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Requested Date",
      value: (
        <>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </>
      ),
    },
    { label: "remark", value: remarks || "-" },
    {
      label: "Actions",
      value: (
        <div hidden={hideActions || status !== CREDIT_STATUS_PENDING}>
          <button
            title="Accept Credit Request"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => onApprove(id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Accept Credit Request"
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </button>
          <button
            title="Reject Credit Request"
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => onReject(id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                title="Reject Credit Request"
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </button>
        </div>
      ),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const AdminCredit = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const { exportToExcel, loading: downloadLoading } = useExportAccounting();
  const { list, loading, paginationInfo } = useSelector(
    ({ companyCredit }) => companyCredit.creditRequests
  );
  const { totalPages } = paginationInfo ?? {};
  const { list: allCompanies, loading: allCompaniesLoading } = useSelector(
    ({ company }) => company.allCompanies
  );

  const { fromDate, toDate } = dateRange;

  useEffect(() => {
    dispatch(
      actions.getAllCompanyCredit({
        filterParam: [...appliedFilters],
        pageNumber,
      })
    );
    dispatch(companyActions.getAllCompanies());
    return () => {
      dispatch(actions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);

  const onReject = (id) => {
    setIsReject(true);
    setShowModal(true);
    setSelectedId(id);
  };
  const onApprove = (id) => {
    setIsReject(false);
    setShowModal(true);
    setSelectedId(id);
  };
  const getSelectBooking = () => list?.filter(({ id }) => selectedId === id)[0];
  const getCompanyName = useCallback(
    (companyId) =>
      allCompanies?.filter(({ id }) => id === companyId)?.[0]?.name,
    [allCompanies]
  );
  const hideActions = useMemo(
    () =>
      !subPermissions.includes(
        adminPermissionsIds.payment.credit.subPermissions.approveOrReject.id
      ),
    [subPermissions]
  );
  const tableContent = list?.map((item, index) => (
    <TableRow
      key={index}
      getCompanyName={getCompanyName}
      data={item}
      onApprove={onApprove}
      onReject={onReject}
      hideActions={hideActions}
    />
  ));
  const onExport = () => {
    exportToExcel({
      type: exportAccountingTypesMap.credit,
      filterParam: [...appliedFilters, {}],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const onSuccess = useCallback(() => {
    dispatch(actions.getAllCompanyCredit({ filterParam: [...appliedFilters] }));
    dispatch(actions.reset());
  }, [appliedFilters, dispatch]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.getAllCompanyCredit({
        filterParam: [...filters],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(actions.getAllCompanyCredit({}));
    } else {
      setPageNumber(1);
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <CreditSearchCard
            setDateRange={setDateRange}
            onSearch={search}
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <div>
              <Typography variant="h5" className="font-weight-bold mb-5">
                Credit Requests
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <Button
              onClick={onExport}
              loading={downloadLoading}
              variant="danger"
            >
              Export
            </Button>
          </div>
          <TopUpEditModal
            onHide={() => setShowModal(false)}
            show={showModal}
            data={getSelectBooking()}
            isReject={isReject}
            getCompanyName={getCompanyName}
            onSuccess={onSuccess}
          />
          {loading || allCompaniesLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-vertical-center text-center font-weight-bold">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">ID</th>
                    <th>Agency Name</th>
                    {matchesSm && (
                      <>
                        <th className="min-w-100px">Requested Date</th>
                        <th>remark</th>
                      </>
                    )}
                    <th>Amount</th>
                    <th>Status</th>
                    {matchesSm && <th className="min-w-120px">Actions</th>}
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No requests to show
                </Typography>
              )}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
