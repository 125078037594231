import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common";
import styles from "./FlightSearch.module.scss";
import { BOOKING_MANAGEMENT_TRACKING } from "../../../../app/routesMap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/flightSearch";

export const PnrSearchCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(
    ({ flightSearch }) => flightSearch.searchResults
  );
  const { loading: startSearchLoading } = useSelector(
    ({ flightSearch }) => flightSearch.startSearch
  );

  const schema = Yup.object().shape({
    searchField: Yup.string()
      .min(2, "Search query length must be longer than 2 characters")
      .required("Please enter pnr to be able to search"),
  });

  const formik = useFormik({
    initialValues: { searchField: "" },
    onSubmit: ({ searchField }) => {
      const params = {
        GUID: "",
        Username: "Test",
        Password: "Test",
        GDS: "CONVERTERAPI",
        PNR: searchField,
        FirstName: "",
        LastName: "",
      };
      dispatch(actions.searchFlightsByPnr(params));
      // history.push({
      //   pathname: BOOKING_MANAGEMENT_TRACKING,
      //   state: { pnr: searchField },
      // });
    },
    validationSchema: schema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-1">
        <textarea
          value={formik.values.searchField}
          name="searchField"
          onChange={formik.handleChange}
          className="form-control form-control-solid min-h-100px "
          placeholder="NZ 456 H 26OCT 5 WLGAKL HK1  1945 2050  26OCT  E  NZ/W268NH"
        />
      </div>
      <div className="d-flex justify-content-between align-items-start">
        <p className={styles.sub}>Please enter your full RNR</p>
        <Button loading={loading || startSearchLoading} type="submit" variant="primary" className="mt-4">
          Search
        </Button>
      </div>
    </form>
  );
};
