import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Input, Select, PhoneInput } from "../common";
import { FLIGHT } from "../../../../app/routesMap";
import countries from "./countries.data.json";
import {
  BookingHold,
  pax_types_text,
  PAX_TYPE_ADULT,
} from "../../../../redux/state/booking/bookingConstants";
import { parseRoute } from "../../../../app/helpers/formatters";
import { getToday } from "../../../_helpers";
import useAgenciesOptions from "../../../../app/helpers/hooks/useAgenciesOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3, 5),
    textAlign: "left",
    height: "100%",
  },
}));
export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  // { value: "Miss", label: "Miss" },
  { value: "Ms", label: "Ms" },
  // { value: "Master", label: "Master" },
  // { value: "Dr", label: "Dr" },
];

export const countriesOptions = countries.map(({ name }) => ({
  label: name,
  value: name,
}));

export const TravellerDetails = ({ steps, searchRQ, fields, loading }) => {
  const history = useHistory();
  const { id, guid } = useParams();
  const classes = useStyles();
  const { agencyOptions } = useAgenciesOptions();
  const { bookingStatus } = useSelector((state) => state.booking);
  const today = getToday();

  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { isManual } = useSelector((state) => state.booking);
  const {
    values,
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    setFieldValue,
  } = fields;
  const leadGuestOptions = values.passengers
    .filter(({ type }) => type === PAX_TYPE_ADULT)
    .map(({ id, firstName, lastName }, index) => {
      const name = `${firstName} ${lastName}`;
      return {
        label: name !== " " ? name : `Passenger ${index + 1}`,
        value: id,
      };
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {steps}
        </Grid>
        {values.passengers.map(({ type }, index) => (
          <Grid key={`passenger-${index}`} item xs={12}>
            <Paper className={classes.paper}>
              <h2 className="my-5">
                Passenger {index + 1} Details - {pax_types_text[type]}
              </h2>
              <Grid container spacing={3} className="pb-5">
                <Grid item xs={12} sm={6}>
                  <Select
                    name={`passengers.${index}.title`}
                    showErrors={touched.passengers?.[`${index}`]?.title}
                    errorMessage={errors.passengers?.[`${index}`]?.title}
                    {...getFieldProps(`passengers.${index}.title`)}
                    onChange={(value) =>
                      setFieldValue(`passengers.${index}.title`, value)
                    }
                    options={titleOptions}
                    placeholder="Title here"
                    label="Title"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    name={`passengers.${index}.gender`}
                    showErrors={touched.passengers?.[`${index}`]?.gender}
                    errorMessage={errors.passengers?.[`${index}`]?.gender}
                    {...getFieldProps(`passengers.${index}.gender`)}
                    onChange={(value) =>
                      setFieldValue(`passengers.${index}.gender`, value)
                    }
                    label="Gender"
                    options={genderOptions}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name={`passengers.${index}.firstName`}
                    showErrors={touched.passengers?.[`${index}`]?.firstName}
                    errorMessage={errors.passengers?.[`${index}`]?.firstName}
                    {...getFieldProps(`passengers.${index}.firstName`)}
                    label="First Name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name={`passengers.${index}.lastName`}
                    showErrors={touched.passengers?.[`${index}`]?.lastName}
                    errorMessage={errors.passengers?.[`${index}`]?.lastName}
                    {...getFieldProps(`passengers.${index}.lastName`)}
                    label="Last Name"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name={`passengers.${index}.passportNumber`}
                    showErrors={
                      touched.passengers?.[`${index}`]?.passportNumber
                    }
                    errorMessage={
                      errors.passengers?.[`${index}`]?.passportNumber
                    }
                    {...getFieldProps(`passengers.${index}.passportNumber`)}
                    label="Passport Number"
                    required={!isManual}
                    optional={isManual}
                  />
                </Grid>
                {/* {type !== PAX_TYPE_ADULT && ( */}
                <Grid item xs={12} sm={6}>
                  <Input
                    name={`passengers.${index}.birthday`}
                    showErrors={touched.passengers?.[`${index}`]?.birthday}
                    errorMessage={errors.passengers?.[`${index}`]?.birthday}
                    {...getFieldProps(`passengers.${index}.birthday`)}
                    label="Birthday"
                    type="date"
                    max={today}
                    required={!isManual}
                    optional={isManual}
                  />
                </Grid>
                {/* )} */}
                <Grid item xs={12} sm={6}>
                  <Select
                    name={`passengers.${index}.passportNationality`}
                    showErrors={
                      touched.passengers?.[`${index}`]?.passportNationality
                    }
                    errorMessage={
                      errors.passengers?.[`${index}`]?.passportNationality
                    }
                    {...getFieldProps(
                      `passengers.${index}.passportNationality`
                    )}
                    onChange={(value) =>
                      setFieldValue(
                        `passengers.${index}.passportNationality`,
                        value
                      )
                    }
                    label="Passport Nationality"
                    options={countriesOptions}
                    required={!isManual}
                    optional={isManual}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    name={`passengers.${index}.passportExpDate`}
                    showErrors={
                      touched.passengers?.[`${index}`]?.passportExpDate
                    }
                    errorMessage={
                      errors.passengers?.[`${index}`]?.passportExpDate
                    }
                    {...getFieldProps(`passengers.${index}.passportExpDate`)}
                    label="Passport EXP. Date"
                    type="date"
                    min={today}
                    required={!isManual}
                    optional={isManual}
                  />
                </Grid>
              </Grid>
              {/* hide until we get data from backend */}
              {/* <Seperator />
              <h2 className="my-5">Special Services</h2>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Select
                    name={`passengers.${index}.wealChair`}
                    {...getFieldProps(`passengers.${index}.wealChair`)}
                    label="Wheal Chair (Optional)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    name={`passengers.${index}.mealSelection`}
                    {...getFieldProps(`passengers.${index}.mealSelection`)}
                    label="Meal selecton (Optional)"
                  />
                </Grid>
              </Grid> */}
            </Paper>
          </Grid>
        ))}
        {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2 className="my-5">Client contact Details</h2>
            <Grid container spacing={3} className="pb-5">
              <Grid item xs={12} sm={6}>
                <Input
                  showErrors={touched.client?.title}
                  errorMessage={errors.client?.title}
                  {...getFieldProps("client.title")}
                  name="client.title"
                  label="Title"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  showErrors={touched.client?.phoneNumber}
                  errorMessage={errors.client?.phoneNumber}
                  {...getFieldProps("client.phoneNumber")}
                  name="client.phoneNumber"
                  placeholder="+00 00000000000"
                  label="contact Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  showErrors={touched.client?.firstName}
                  errorMessage={errors.client?.firstName}
                  {...getFieldProps("client.firstName")}
                  name="client.firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  showErrors={touched.client?.lastName}
                  errorMessage={errors.client?.lastName}
                  {...getFieldProps("client.lastName")}
                  name="client.lastName"
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  showErrors={touched.client?.email}
                  errorMessage={errors.client?.email}
                  {...getFieldProps("client.email")}
                  name="client.email"
                  placeholder="I.G Email@info.com"
                  label="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  showErrors={touched.client?.country}
                  errorMessage={errors.client?.country}
                  {...getFieldProps("client.country")}
                  name="client.country"
                  label="Country"
                  options={countriesOptions}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  showErrors={touched.client?.zipCode}
                  errorMessage={errors.client?.zipCode}
                  {...getFieldProps("client.zipCode")}
                  name="client.zipCode"
                  label="Zip Code"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2 className="my-5">Select Lead guest</h2>
            <Grid container spacing={3} className="pb-5">
              <Grid item xs={12} sm={6}>
                <Select
                  showErrors={touched.leadGuest?.passengerNo}
                  errorMessage={errors.leadGuest?.passengerNo}
                  {...getFieldProps("leadGuest.passengerNo")}
                  onChange={(value) =>
                    setFieldValue("leadGuest.passengerNo", value)
                  }
                  name="leadGuest.passengerNo"
                  label="Lead Guest"
                  options={leadGuestOptions}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {values.leadGuest.passengerNo && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h2 className="my-5">Lead guest contact Details</h2>
              <Grid container spacing={3} className="pb-5">
                <Grid item xs={12} sm={6}>
                  <PhoneInput
                    showErrorsCode={touched.leadGuest?.phoneCode}
                    errorMessageCode={errors.leadGuest?.phoneCode}
                    valueCode={values.leadGuest?.phoneCode}
                    onChangeCode={(value) =>
                      setFieldValue("leadGuest.phoneCode", value)
                    }
                    showErrorsPhone={touched.leadGuest?.phoneNumber}
                    errorMessagePhone={errors.leadGuest?.phoneNumber}
                    fieldPropsPhone={{
                      ...getFieldProps("leadGuest.phoneNumber"),
                    }}
                    name="leadGuest.phoneNumber"
                    label="contact Number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    showErrors={touched.leadGuest?.email}
                    errorMessage={errors.leadGuest?.email}
                    {...getFieldProps("leadGuest.email")}
                    name="leadGuest.email"
                    placeholder="I.G Email@info.com"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    showErrors={touched.leadGuest?.country}
                    errorMessage={errors.leadGuest?.country}
                    {...getFieldProps("leadGuest.country")}
                    onChange={(value) =>
                      setFieldValue("leadGuest.country", value)
                    }
                    name="leadGuest.country"
                    label="Country"
                    options={countriesOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    showErrors={touched.leadGuest?.zipCode}
                    errorMessage={errors.leadGuest?.zipCode}
                    {...getFieldProps("leadGuest.zipCode")}
                    name="leadGuest.zipCode"
                    label="Zip Code"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h2 className="my-5">Under which agency, is this booking for?</h2>
              <Select
                showErrors={touched["agencyId"]}
                errorMessage={errors["agencyId"]}
                {...getFieldProps("agencyId")}
                onChange={(value) => setFieldValue("agencyId", value)}
                name="agencyId"
                label="Agency"
                options={agencyOptions}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs>
                <button
                  onClick={() => history.push(parseRoute(FLIGHT, { id, guid }))}
                  className="btn btn-light-primary ml-auto my-10 w-100 h-auto"
                  disabled={loading}
                >
                  Previous
                </button>
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs>
                <Button
                  loading={loading}
                  smallPadding
                  type="submit"
                  className="ml-auto my-10 w-100"
                >
                  {bookingStatus === BookingHold ? "Place Hold Order" : "Next"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
