import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../common";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { OperationLog } from "../../../account/accountSubUser/SubUser";
import { actions as bookingMngActions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { useHistory, useParams } from "react-router";
import { OrderSummary } from "../issuedOrderDetails/orderSummary/OrderSummary";
import { RejectReissueModal } from "./RejectReissueModal";
import { BOOKING_MANAGEMENT_REISSUE_ACCEPT } from "../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import {
  PendingPayments,
  ReIssueApplied,
  ReIssueCompleted,
  ReIssueProcessing,
} from "../../../../../../redux/state/bookingMng/bookingMngConstants";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { BOOKING_MANAGEMENT_REISSUE_PAY } from "../../../../../../app/routesMap";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

export const ReissueDetails = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);

  const [openRejectModal, setOpenRejectModal] = useState(false);

  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    success: refundReissueSuccess,
    loading: refundReissueLoading,
    failure: refundReissueFailure,
  } = useSelector(({ bookingMng }) => bookingMng.refundReissue);
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificReissue) || {};
  const { preTransaction, reIssue } = data || {};
  const { status, transaction } = reIssue || {};
  useEffect(() => {
    dispatch(bookingMngActions.getSpecificReissue({ id }));
  }, [dispatch, id]);
  const manageOptionsAdmin = [
    {
      label: "Refund Paid Amount",
      onClick: () => setOpenConfirmationModal(true),
      hidden: status !== ReIssueCompleted,
    },
  ];
  const hideConfirmationModal = () => {
    setOpenConfirmationModal(false);
    dispatch(bookingMngActions.clearRefundReissue());
  };
  const onFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(bookingMngActions.clearRefundReissue());
  };
  const onSuccess = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: "Success! Reissue Amount is refunded back to agency.",
      })
    );
    dispatch(bookingMngActions.getSpecificReissue({ id }));
  };
  const refundReissue = () => {
    dispatch(bookingMngActions.refundReissue({ id, token }));
  };
  const allReisssueSubPermission =
    adminPermissionsIds.bookingManagement.reissue.subPermissions;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-between">
          <ConfirmationModal
            show={openConfirmationModal}
            onHide={hideConfirmationModal}
            onConfirm={refundReissue}
            title="Refund for paid reissue amount"
            body="Are you sure you want to refund the reissue amount back to agency?"
            loading={refundReissueLoading}
            success={refundReissueSuccess}
            failure={refundReissueFailure}
            onFailure={onFailure}
            onSuccess={onSuccess}
          />
          <RejectReissueModal
            show={openRejectModal}
            onHide={() => setOpenRejectModal(false)}
            id={id}
          />
          {!isAdmin && status === PendingPayments && (
            <div className="d-flex">
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                // variant="primary-inverse"
                onClick={() =>
                  history.push(
                    parseRoute(BOOKING_MANAGEMENT_REISSUE_PAY, {
                      id: transaction?.bookingId,
                    })
                  )
                }
              >
                Pay
              </Button>
              <Button
                smallPadding
                className="d-flex align-items-center mr-3"
                variant="primary-inverse"
                onClick={() => {
                  // setIsReject(true);
                  setOpenRejectModal(true);
                }}
              >
                Reject Offer
              </Button>
            </div>
          )}
          {isAdmin &&
            subPermissions.includes(allReisssueSubPermission.actions.id) &&
            (status === ReIssueApplied || status === PendingPayments) && (
              <div className="d-flex">
                <Button
                  smallPadding
                  className="d-flex align-items-center mr-3"
                  // variant="primary-inverse"
                  onClick={() =>
                    history.push(
                      parseRoute(BOOKING_MANAGEMENT_REISSUE_ACCEPT, {
                        id,
                      })
                    )
                  }
                >
                  Make Reissue Offer
                </Button>
                <Button
                  smallPadding
                  className="d-flex align-items-center mr-3"
                  variant="primary-inverse"
                  onClick={() => {
                    // setIsReject(true);
                    setOpenRejectModal(true);
                  }}
                >
                  Reject
                </Button>
              </div>
            )}
          <div className="d-flex justify-content-between">
            <Button
              smallPadding
              className="d-flex align-items-center mr-3 ml-auto"
              variant="primary-inverse"
              // onClick={() => setOpenEmailModal(true)}
            >
              <SVG
                className="mr-2"
                src={toAbsoluteUrl("/media/svg/icons/General/message-blue.svg")}
              />
              Send
            </Button>

            {
              <div className="mr-2">
                {isAdmin &&
                  subPermissions.includes(
                    allReisssueSubPermission.actions.id
                  ) &&
                  (status === ReIssueCompleted ||
                    status === ReIssueProcessing) && (
                    <DropDownButton
                      options={manageOptionsAdmin}
                      buttonClassName="btn-danger"
                      title="Manage"
                    />
                  )}
              </div>
            }

            <DropDownButton
              // options={manageOptions}
              // buttonClassName="btn-danger"
              title="Download"
            />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <OrderSummary data={{ ...transaction, status }} />
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanel
          // expanded={expanded === index}
          // onChange={handlePanelChange(index)}
          // elevation={0}
          className="border rounded overflow-hidden"
          style={{ filter: "grayscale(100%)" }}
        >
          <ExpansionPanelSummary className="min-w-100 bg-primary">
            <Typography variant="h5" className="text-white">
              Original Order Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <OrderSummary data={preTransaction} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <OperationLog />
        </Paper>
      </Grid>
    </Grid>
  );
};

export const DropDownButton = ({
  title,
  buttonClassName,
  className,
  icon,
  options,
}) => {
  return (
    <div className={clsx("card-toolbar", className)}>
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className={clsx(
            "btn btn-primary btn-sm font-weight-bolder dropdown-toggle h-40px",
            buttonClassName
          )}
        >
          <SVG className="mr-2" src={icon} />
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          {options?.map(({ label, onClick }) => (
            <Dropdown.Item onClick={onClick}>{label}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
