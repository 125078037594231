import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./templatesConstants";

export const CREATE_TEMPLATE_TYPE = requestActions(
  MODULE_NAME,
  "CREATE_TEMPLATE_TYPE"
);
export const DELETE_TEMPLATE_TYPE = requestActions(
  MODULE_NAME,
  "DELETE_TEMPLATE_TYPE"
);
export const EDIT_TEMPLATE_TYPE = requestActions(
  MODULE_NAME,
  "EDIT_TEMPLATE_TYPE"
);
export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const DELETE = requestActions(MODULE_NAME, "DELETE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const GET_ALL = requestActions(MODULE_NAME, "GET_ALL");
export const GET_SPECIFIC_TEMPLATE = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_TEMPLATE"
);
export const GET_TEMPLATE_TYPES = requestActions(
  MODULE_NAME,
  "GET_TEMPLATE_TYPES"
);
export const GET_EMAIL_PARAMETERS = requestActions(
  MODULE_NAME,
  "GET_EMAIL_PARAMETERS"
);
export const RESET = `${MODULE_NAME}/RESET`;

export const getEmailParameters = (params) =>
  requestApiCall("getEmailParameters", params, GET_EMAIL_PARAMETERS);

export const createTemplateType = (params) =>
  requestApiCall("createTemplateType", params, CREATE_TEMPLATE_TYPE);

export const editTemplateType = (params) =>
  requestApiCall("editTemplateType", params, EDIT_TEMPLATE_TYPE);

export const deleteTemplateType = (params) =>
  requestApiCall("deleteTemplateType", params, DELETE_TEMPLATE_TYPE);

export const createTemplate = (params) =>
  requestApiCall("createTemplate", params, CREATE);

export const deleteTemplate = (params) =>
  requestApiCall("deleteTemplate", params, DELETE);

export const editTemplate = (params) =>
  requestApiCall("editTemplate", params, EDIT);

export const getTemplates = () => requestApiCall("getTemplates", {}, GET_ALL);

export const getSpecificTemplate = (params) =>
  requestApiCall("getSpecificTemplate", params, GET_SPECIFIC_TEMPLATE);

export const getTemplateTypes = () =>
  requestApiCall("getTemplateTypes", {}, GET_TEMPLATE_TYPES);

export const clearCreateType = () => ({
  type: CREATE_TEMPLATE_TYPE.CLEAR,
});

export const clearSpecificTemplate = () => ({
  type: GET_SPECIFIC_TEMPLATE.CLEAR,
});

export const clearDelete = () => ({
  type: DELETE.CLEAR,
});

export const reset = () => ({ type: RESET });
