import produce from "immer";
import { isArray, isNil } from "lodash-es";
import { toCamel } from "../../../_metronic/_helpers/string";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./bookingMngActions";

import { orderFilterTypesMap } from "./bookingMngConstants";

const initialState = {
  specificOrder: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  orders: {
    list: undefined,
    paginationInfo: undefined,
    statusCounts: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  allReissue: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  reissue: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  refundReissue: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  specificReissue: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  refunds: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  specificRefund: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  voids: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  specificVoid: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  void: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  voidWithNoRefund: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  refund: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  sendEmail: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  bookingStats: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  updateBookingTickets: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  createFee: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  editFee: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  fees: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  feeLogs: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  feeByType: {
    data: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  bookingNotes: {
    list: undefined,
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  createBookingNote: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  updateBookingStatus: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  discount: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  export: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    data: undefined,
  },
  cancelProcessingBooking: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  paySubscription: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
  createPaymentIntent: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    clientSecret: undefined,
  },
  createMastercardIntent: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    token: undefined,
    orderId: undefined,
  },
};

export const bookingMngReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.CREATE_MASTERCARD_INTENT.REQUESTED:
        state.createMastercardIntent.loading = true;
        break;
      case actions.CREATE_MASTERCARD_INTENT.SUCCEEDED:
        state.createMastercardIntent.loading = false;
        state.createMastercardIntent.success = true;
        state.createMastercardIntent.token = payload.data.data;
        state.createMastercardIntent.orderId = payload.data.$id;
        break;
      case actions.CREATE_MASTERCARD_INTENT.FAILED:
        state.createMastercardIntent.loading = false;
        state.createMastercardIntent.failure = payload.error;
        break;
      case actions.CREATE_MASTERCARD_INTENT.CLEAR:
        state.createMastercardIntent = initialState.createMastercardIntent;
        break;
      case actions.CREATE_PAYMENT_INTENT.REQUESTED:
        state.createPaymentIntent.loading = true;
        break;
      case actions.CREATE_PAYMENT_INTENT.SUCCEEDED:
        state.createPaymentIntent.loading = false;
        state.createPaymentIntent.success = true;
        state.createPaymentIntent.clientSecret = payload.data.clientSecret;
        break;
      case actions.CREATE_PAYMENT_INTENT.FAILED:
        state.createPaymentIntent.loading = false;
        state.createPaymentIntent.failure = payload.error;
        break;
      case actions.CREATE_PAYMENT_INTENT.CLEAR:
        state.createPaymentIntent = initialState.createPaymentIntent;
        break;
      case actions.PAY_SUBSCRIPTION.REQUESTED:
        state.paySubscription.loading = true;
        break;
      case actions.PAY_SUBSCRIPTION.SUCCEEDED:
        state.paySubscription.loading = false;
        if (payload.data.status === "OK") {
          state.paySubscription.success = true;
        } else {
          state.paySubscription.failure = payload.data.message;
        }
        break;
      case actions.PAY_SUBSCRIPTION.FAILED:
        state.paySubscription.loading = false;
        state.paySubscription.failure = payload.error;
        break;
      case actions.PAY_SUBSCRIPTION.CLEAR:
        state.paySubscription = initialState.paySubscription;
        break;
      case actions.CANCEL_PROCESSING_BOOKING.REQUESTED:
        state.cancelProcessingBooking.loading = true;
        break;
      case actions.CANCEL_PROCESSING_BOOKING.SUCCEEDED:
        state.cancelProcessingBooking.loading = false;
        state.cancelProcessingBooking.success = true;
        break;
      case actions.CANCEL_PROCESSING_BOOKING.FAILED:
        state.cancelProcessingBooking.loading = false;
        state.cancelProcessingBooking.failure = payload.error;
        break;
      case actions.CANCEL_PROCESSING_BOOKING.CLEAR:
        state.cancelProcessingBooking = initialState.cancelProcessingBooking;
        break;
      case actions.EXPORT_ORDERS.REQUESTED:
        state.export.loading = true;
        break;
      case actions.EXPORT_ORDERS.SUCCEEDED:
        state.export.loading = false;
        state.export.success = true;
        state.export.data = payload.data;

        break;
      case actions.EXPORT_ORDERS.FAILED:
        state.export.loading = false;
        state.export.failure = payload.error;
        break;
      case actions.EXPORT_ORDERS.CLEAR:
        state.export = initialState.export;
        break;
      case actions.ADD_DISCOUNT.REQUESTED:
        state.discount.loading = true;
        break;
      case actions.ADD_DISCOUNT.SUCCEEDED:
        state.discount.loading = false;
        if (payload.data.status === "200") {
          state.discount.success = true;
        } else {
          state.discount.failure = payload.data.message;
        }
        break;
      case actions.ADD_DISCOUNT.FAILED:
        state.discount.loading = false;
        state.discount.failure = payload.error;
        break;
      case actions.ADD_DISCOUNT.CLEAR:
        state.discount = initialState.discount;
        break;
      case actions.VOID_WITH_NO_REFUND.REQUESTED:
        state.voidWithNoRefund.loading = true;
        break;
      case actions.VOID_WITH_NO_REFUND.SUCCEEDED:
        state.voidWithNoRefund.loading = false;
        if (payload.data.status === "200") {
          state.voidWithNoRefund.success = true;
        } else {
          state.voidWithNoRefund.failure = payload.data.message;
        }
        break;
      case actions.VOID_WITH_NO_REFUND.FAILED:
        state.voidWithNoRefund.loading = false;
        state.voidWithNoRefund.failure = payload.error;
        break;
      case actions.VOID_WITH_NO_REFUND.CLEAR:
        state.voidWithNoRefund = initialState.voidWithNoRefund;
        break;
      case actions.UPDATE_BOOKING_STATUS.REQUESTED:
        state.updateBookingStatus.loading = true;
        break;
      case actions.UPDATE_BOOKING_STATUS.SUCCEEDED:
        state.updateBookingStatus.loading = false;
        state.updateBookingStatus.success = true;
        break;
      case actions.UPDATE_BOOKING_STATUS.FAILED:
        state.updateBookingStatus.loading = false;
        state.updateBookingStatus.failure = payload.error;
        break;
      case actions.UPDATE_BOOKING_STATUS.CLEAR:
        state.updateBookingStatus = initialState.updateBookingStatus;
        break;
      case actions.GET_BOOKING_NOTES.REQUESTED:
        state.bookingNotes.loading = true;
        break;
      case actions.GET_BOOKING_NOTES.SUCCEEDED:
        state.bookingNotes.loading = false;
        state.bookingNotes.success = true;
        state.bookingNotes.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_BOOKING_NOTES.FAILED:
        state.bookingNotes.loading = false;
        state.bookingNotes.failure = payload.error;
        break;
      case actions.CREATE_BOOKING_NOTE.REQUESTED:
        state.createBookingNote.loading = true;
        break;
      case actions.CREATE_BOOKING_NOTE.SUCCEEDED:
        state.createBookingNote.loading = false;
        state.createBookingNote.success = true;
        break;
      case actions.CREATE_BOOKING_NOTE.FAILED:
        state.createBookingNote.loading = false;
        state.createBookingNote.failure = payload.error;
        break;
      case actions.CREATE_BOOKING_NOTE.CLEAR:
        state.createBookingNote = initialState.createBookingNote;
        break;
      case actions.GET_FEE_LOGS.REQUESTED:
        state.feeLogs.loading = true;
        break;
      case actions.GET_FEE_LOGS.SUCCEEDED:
        state.feeLogs.loading = false;
        state.feeLogs.success = true;
        state.feeLogs.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_FEE_LOGS.FAILED:
        state.feeLogs.loading = false;
        state.feeLogs.failure = payload.error;
        break;
      case actions.GET_FEE_LOGS.CLEAR:
        state.feeLogs = initialState.feeLogs;
        break;
      case actions.GET_FEE_BY_TYPE.REQUESTED:
        state.feeByType.loading = true;
        break;
      case actions.GET_FEE_BY_TYPE.SUCCEEDED:
        state.feeByType.loading = false;
        state.feeByType.success = true;
        state.feeByType.data = payload.data.data;
        break;
      case actions.GET_FEE_BY_TYPE.FAILED:
        state.feeByType.loading = false;
        state.feeByType.failure = payload.error;
        break;
      case actions.GET_FEE_BY_TYPE.CLEAR:
        state.feeByType = initialState.feeByType;
        break;
      case actions.GET_ALL_FEES.REQUESTED:
        state.fees.loading = true;
        break;
      case actions.GET_ALL_FEES.SUCCEEDED:
        state.fees.loading = false;
        state.fees.success = true;
        state.fees.list = payload.data.data.$values;
        break;
      case actions.GET_ALL_FEES.FAILED:
        state.fees.loading = false;
        state.fees.failure = payload.error;
        break;
      case actions.CREATE_FEE.REQUESTED:
        state.createFee.loading = true;
        break;
      case actions.CREATE_FEE.SUCCEEDED:
        state.createFee.loading = false;
        state.createFee.success = true;
        break;
      case actions.CREATE_FEE.FAILED:
        state.createFee.loading = false;
        state.createFee.failure = payload.error;
        break;
      case actions.CREATE_FEE.CLEAR:
        state.createFee = initialState.createFee;
        break;
      case actions.EDIT_FEE.REQUESTED:
        state.editFee.loading = true;
        break;
      case actions.EDIT_FEE.SUCCEEDED:
        state.editFee.loading = false;
        state.editFee.success = true;
        break;
      case actions.EDIT_FEE.FAILED:
        state.editFee.loading = false;
        state.editFee.failure = payload.error;
        break;
      case actions.EDIT_FEE.CLEAR:
        state.editFee = initialState.editFee;
        break;
      case actions.UPDATE_BOOKING_TICKETS.REQUESTED:
        state.updateBookingTickets.loading = true;
        break;
      case actions.UPDATE_BOOKING_TICKETS.SUCCEEDED:
        state.updateBookingTickets.loading = false;
        state.updateBookingTickets.success = true;
        break;
      case actions.UPDATE_BOOKING_TICKETS.FAILED:
        state.updateBookingTickets.loading = false;
        state.updateBookingTickets.failure = payload.error;
        break;
      case actions.UPDATE_BOOKING_TICKETS.CLEAR:
        state.updateBookingTickets = initialState.updateBookingTickets;
        break;
      case actions.GET_SPECIFIC_ORDER.REQUESTED:
      case actions.GET_SPECIFIC_ORDER_BY_BOOKING_ID.REQUESTED:
        state.specificOrder.loading = true;
        break;
      case actions.GET_SPECIFIC_ORDER.SUCCEEDED:
      case actions.GET_SPECIFIC_ORDER_BY_BOOKING_ID.SUCCEEDED:
        state.specificOrder.loading = false;
        state.specificOrder.success = true;
        state.specificOrder.data = payload.data.data;
        break;
      case actions.GET_SPECIFIC_ORDER.FAILED:
      case actions.GET_SPECIFIC_ORDER_BY_BOOKING_ID.FAILED:
        state.specificOrder.loading = false;
        state.specificOrder.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_ORDER.CLEAR:
      case actions.GET_SPECIFIC_ORDER_BY_BOOKING_ID.CLEAR:
        state.specificOrder = initialState.specificOrder;
        break;
      case actions.GET_SPECIFIC_REISSUE.REQUESTED:
        state.specificReissue.loading = true;
        break;
      case actions.GET_SPECIFIC_REISSUE.SUCCEEDED:
        state.specificReissue.loading = false;
        state.specificReissue.success = true;
        state.specificReissue.data = payload.data.data;
        break;
      case actions.GET_SPECIFIC_REISSUE.FAILED:
        state.specificReissue.loading = false;
        state.specificReissue.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_REISSUE.CLEAR:
        state.specificReissue = initialState.specificReissue;
        break;
      case actions.GET_SPECIFIC_VOID.REQUESTED:
      case actions.GET_SPECIFIC_VOID_BY_GUID.REQUESTED:
        state.specificVoid.loading = true;
        break;
      case actions.GET_SPECIFIC_VOID.SUCCEEDED:
      case actions.GET_SPECIFIC_VOID_BY_GUID.SUCCEEDED:
        state.specificVoid.loading = false;
        state.specificVoid.success = true;
        state.specificVoid.data = payload.data.data;
        break;
      case actions.GET_SPECIFIC_VOID.FAILED:
      case actions.GET_SPECIFIC_VOID_BY_GUID.FAILED:
        state.specificVoid.loading = false;
        state.specificVoid.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_VOID.CLEAR:
      case actions.GET_SPECIFIC_VOID_BY_GUID.CLEAR:
        state.specificVoid = initialState.specificVoid;
        break;
      case actions.GET_SPECIFIC_REFUND.REQUESTED:
        state.specificRefund.loading = true;
        break;
      case actions.GET_SPECIFIC_REFUND.SUCCEEDED:
        state.specificRefund.loading = false;
        state.specificRefund.success = true;
        state.specificRefund.data = payload.data.data;
        break;
      case actions.GET_SPECIFIC_REFUND.FAILED:
        state.specificRefund.loading = false;
        state.specificRefund.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_REFUND.CLEAR:
        state.specificRefund = initialState.specificRefund;
        break;
      case actions.GET_BOOKING_STATS.REQUESTED:
      case actions.GET_ALL_BOOKING_STATS.REQUESTED:
        state.bookingStats.loading = true;
        break;
      case actions.GET_BOOKING_STATS.SUCCEEDED:
      case actions.GET_ALL_BOOKING_STATS.SUCCEEDED:
        state.bookingStats.loading = false;
        state.bookingStats.success = true;
        state.bookingStats.data = payload.data.data;
        break;
      case actions.GET_BOOKING_STATS.FAILED:
      case actions.GET_ALL_BOOKING_STATS.FAILED:
        state.bookingStats.loading = false;
        state.bookingStats.failure = payload.error;
        break;
      case actions.SEND_REFUND_EMAIL.REQUESTED:
      case actions.SEND_VOID_EMAIL.REQUESTED:
      case actions.SEND_REISSUE_EMAIL.REQUESTED:
        state.sendEmail.loading = true;
        break;
      case actions.SEND_REFUND_EMAIL.SUCCEEDED:
      case actions.SEND_VOID_EMAIL.SUCCEEDED:
      case actions.SEND_REISSUE_EMAIL.SUCCEEDED:
        state.sendEmail.loading = false;
        state.sendEmail.success = true;
        // if (payload.data.status === "200") {
        //   state.sendEmail.success = true;
        // } else {
        //   state.sendEmail.failure = payload.data.message;
        // }
        break;
      case actions.SEND_REFUND_EMAIL.FAILED:
      case actions.SEND_VOID_EMAIL.FAILED:
      case actions.SEND_REISSUE_EMAIL.FAILED:
        state.sendEmail.loading = false;
        state.sendEmail.failure = payload.error;
        break;
      case actions.SEND_REFUND_EMAIL.CLEAR:
      case actions.SEND_VOID_EMAIL.CLEAR:
      case actions.SEND_REISSUE_EMAIL.CLEAR:
        state.sendEmail = initialState.sendEmail;
        break;
      case actions.REQUEST_VOID.REQUESTED:
      case actions.ACCEPT_VOID.REQUESTED:
      case actions.ACCEPT_VOID_MANUALLY.REQUESTED:
      case actions.REJECT_VOID.REQUESTED:
        state.void.success = undefined;
        state.void.failure = undefined;
        state.void.loading = true;
        break;
      case actions.REQUEST_VOID.SUCCEEDED:
      case actions.ACCEPT_VOID.SUCCEEDED:
      case actions.ACCEPT_VOID_MANUALLY.SUCCEEDED:
      case actions.REJECT_VOID.SUCCEEDED:
        state.void.loading = false;
        if (payload.data.status === "200") {
          state.void.success = true;
        } else {
          state.void.failure = payload.data.message;
        }
        break;
      case actions.REQUEST_VOID.FAILED:
      case actions.ACCEPT_VOID.FAILED:
      case actions.ACCEPT_VOID_MANUALLY.FAILED:
      case actions.REJECT_VOID.FAILED:
        state.void.loading = false;
        state.void.failure = payload.error;
        break;
      case actions.REQUEST_VOID.CLEAR:
      case actions.ACCEPT_VOID.CLEAR:
      case actions.REJECT_VOID.CLEAR:
        state.void = initialState.void;
        break;
      case actions.REQUEST_REISSUE.REQUESTED:
      case actions.ACCEPT_REISSUE.REQUESTED:
      case actions.REJECT_REISSUE_OFFER.REQUESTED:
      case actions.REJECT_REISSUE.REQUESTED:
        state.reissue.success = undefined;
        state.reissue.failure = undefined;
        state.reissue.loading = true;
        break;
      case actions.REQUEST_REISSUE.SUCCEEDED:
      case actions.ACCEPT_REISSUE.SUCCEEDED:
      case actions.REJECT_REISSUE_OFFER.SUCCEEDED:
      case actions.REJECT_REISSUE.SUCCEEDED:
        state.reissue.loading = false;
        if (payload.data.status === "200") {
          state.reissue.success = true;
        } else {
          state.reissue.failure = payload.data.message || "Something is wrong!";
        }
        break;
      case actions.REQUEST_REISSUE.FAILED:
      case actions.ACCEPT_REISSUE.FAILED:
      case actions.REJECT_REISSUE_OFFER.FAILED:
      case actions.REJECT_REISSUE.FAILED:
        state.reissue.loading = false;
        state.reissue.failure = payload.error;
        break;
      case actions.REQUEST_REISSUE.CLEAR:
      case actions.ACCEPT_REISSUE.CLEAR:
      case actions.REJECT_REISSUE_OFFER.CLEAR:
      case actions.REJECT_REISSUE.CLEAR:
        state.reissue = initialState.reissue;
        break;
      case actions.REFUND_REISSUE.REQUESTED:
        state.refundReissue.success = undefined;
        state.refundReissue.failure = undefined;
        state.refundReissue.loading = true;
        break;
      case actions.REFUND_REISSUE.SUCCEEDED:
        state.refundReissue.loading = false;
        if (payload.data.status === "200") {
          state.refundReissue.success = true;
        } else {
          state.refundReissue.failure =
            payload.data.message || "Something is wrong!";
        }
        break;
      case actions.REFUND_REISSUE.FAILED:
        state.refundReissue.loading = false;
        state.refundReissue.failure = payload.error;
        break;
      case actions.REFUND_REISSUE.CLEAR:
        state.refundReissue = initialState.refundReissue;
        break;
      case actions.REQUEST_REFUND.REQUESTED:
      case actions.UPDATE_REFUND_STATUS.REQUESTED:
      case actions.ACCEPT_REFUND.REQUESTED:
      case actions.REJECT_REFUND.REQUESTED:
        state.refund = initialState.refund;
        state.refund.loading = true;
        break;
      case actions.REQUEST_REFUND.SUCCEEDED:
      case actions.UPDATE_REFUND_STATUS.SUCCEEDED:
      case actions.ACCEPT_REFUND.SUCCEEDED:
      case actions.REJECT_REFUND.SUCCEEDED:
        state.refund.loading = false;
        if (payload.data.status === "200") {
          state.refund.success = true;
        } else {
          state.refund.failure = payload.data.message;
        }
        break;
      case actions.REQUEST_REFUND.FAILED:
      case actions.UPDATE_REFUND_STATUS.FAILED:
      case actions.ACCEPT_REFUND.FAILED:
      case actions.REJECT_REFUND.FAILED:
        state.refund.loading = false;
        state.refund.failure = payload.error;
        break;
      case actions.REQUEST_REFUND.CLEAR:
        state.refund = initialState.refund;
        break;

      case actions.GET_ALL_ORDERS.REQUESTED:
      case actions.GET_ORDERS.REQUESTED:
      case actions.GET_USER_ORDERS.REQUESTED:
        state.orders.loading = true;
        break;
      case actions.GET_ALL_ORDERS.SUCCEEDED:
      case actions.GET_ORDERS.SUCCEEDED:
      case actions.GET_USER_ORDERS.SUCCEEDED:
        state.orders.loading = false;
        state.orders.success = true;
        state.orders.list = payload.data.data?.$values;
        break;
      case actions.GET_ALL_ORDERS.FAILED:
      case actions.GET_ORDERS.FAILED:
      case actions.GET_USER_ORDERS.FAILED:
        state.orders.loading = false;
        state.orders.failure = payload.error;
        break;
      case actions.FILTER_BOOKINGS.REQUESTED:
        state.orders.loading = true;
        break;
      case actions.FILTER_BOOKINGS.SUCCEEDED:
        state.orders.loading = false;
        state.orders.success = true;
        switch (payload.args.type) {
          case orderFilterTypesMap.ticketTracking:
            const {
              data: ordersData,
              bookingCounts,
              ...orderPaginationInfo
            } = payload.data.data;
            state.orders.list = ordersData.$values;
            state.orders.paginationInfo = orderPaginationInfo;
            state.orders.statusCounts = bookingCounts;
            break;
          case orderFilterTypesMap.reissue:
          case orderFilterTypesMap.cancel:
            const {
              data: voidData,
              bookingCounts: voidCounts,
              ...voidPaginationInfo
            } = payload.data.data;

            state.orders.list = voidData.$values;
            state.orders.paginationInfo = voidPaginationInfo;
            state.orders.statusCounts = voidCounts;
            break;
          case orderFilterTypesMap.refund:
            let data = JSON.parse(payload.data.data);
            data = toCamel(data);
            const {
              data: refundData,
              bookingCounts: refundCounts,
              ...refundPaginationInfo
            } = data;
            state.orders.list = refundData;
            state.orders.paginationInfo = refundPaginationInfo;
            state.orders.statusCounts = refundCounts;
            break;

          default:
            break;
        }
        break;
      case actions.FILTER_BOOKINGS.FAILED:
        state.orders.loading = false;
        state.orders.failure = payload.error;
        break;
      case actions.GET_ALL_VOIDS.REQUESTED:
      case actions.GET_VOIDS.REQUESTED:
        state.voids.loading = true;
        break;
      case actions.GET_ALL_VOIDS.SUCCEEDED:
      case actions.GET_VOIDS.SUCCEEDED:
        state.voids.loading = false;
        state.voids.success = true;
        state.voids.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_ALL_VOIDS.FAILED:
      case actions.GET_VOIDS.FAILED:
        state.voids.loading = false;
        state.voids.failure = payload.error;
        break;
      case actions.GET_REFUNDS.REQUESTED:
      case actions.GET_ALL_REFUNDS.REQUESTED:
        state.refunds.loading = true;
        break;
      case actions.GET_REFUNDS.SUCCEEDED:
      case actions.GET_ALL_REFUNDS.SUCCEEDED:
        state.refunds.loading = false;
        state.refunds.success = true;
        state.refunds.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_REFUNDS.FAILED:
      case actions.GET_ALL_REFUNDS.FAILED:
        state.refunds.loading = false;
        state.refunds.failure = payload.error;
        break;
      case actions.GET_REISSUE.REQUESTED:
      case actions.GET_ALL_REISSUE.REQUESTED:
        state.allReissue.loading = true;
        break;
      case actions.GET_REISSUE.SUCCEEDED:
      case actions.GET_ALL_REISSUE.SUCCEEDED:
        state.allReissue.loading = false;
        state.allReissue.success = true;
        state.allReissue.list = payload.data.data.$values.reverse();
        break;
      case actions.GET_ALL_REISSUE.FAILED:
      case actions.GET_REISSUE.FAILED:
        state.allReissue.loading = false;
        state.allReissue.failure = payload.error;
        break;
      case actions.SEARCH_ALL_ORDERS.REQUESTED:
      case actions.FILTER_ALL_ORDERS.REQUESTED:
      case actions.FILTER_ORDERS.REQUESTED:
        state.orders.loading = true;
        break;
      case actions.SEARCH_ALL_ORDERS.FAILED:
      case actions.FILTER_ALL_ORDERS.FAILED:
      case actions.FILTER_ORDERS.FAILED:
        state.orders.loading = false;
        state.orders.failure = payload.error;
        break;
      case actions.SEARCH_ALL_ORDERS.SUCCEEDED:
      case actions.FILTER_ALL_ORDERS.SUCCEEDED:
      case actions.FILTER_ORDERS.SUCCEEDED:
        state.orders.loading = false;
        state.orders.success = true;
        const data = payload.data.data?.$values || payload.data.data;
        if (isNil(data)) {
          state.orders.list = [];
        } else {
          state.orders.list = isArray(data) ? data.reverse() : [data] || [];
        }
        break;
      case actions.FILTER_ALL_VOID.SUCCEEDED:
      case actions.FILTER_VOID.SUCCEEDED:
        const voidData = payload.data.data?.$values || payload.data.data;
        if (isNil(voidData)) {
          state.voids.list = [];
        } else {
          state.voids.list = isArray(voidData)
            ? voidData.reverse()
            : [voidData] || [];
        }
        break;
      case actions.FILTER_REISSUE.SUCCEEDED:
        const reissueData = payload.data.data?.$values || payload.data.data;
        if (isNil(reissueData)) {
          state.allReissue.list = [];
        } else {
          state.allReissue.list = isArray(reissueData)
            ? reissueData.reverse()
            : [reissueData] || [];
        }
        break;
      case actions.FILTER_REFUNDS.SUCCEEDED:
        const refundsData = payload.data.data?.$values || payload.data.data;
        if (isNil(refundsData)) {
          state.refunds.list = [];
        } else {
          const list = isArray(refundsData) ? refundsData : [refundsData] || [];
          state.refunds.list = list.reverse();
        }
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
