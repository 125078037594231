import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

export const ResponsiveRow = ({ children, detailsData }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [style, animate] = useSpring(() => ({ height: "0px" }), []);
  const ref = useRef(null);
  const handleRowClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    animate({
      height: (isExpanded ? ref.current.offsetHeight : 0) + "px",
    });
  }, [animate, ref, isExpanded]);

  return (
    <>
      <tr onClick={handleRowClick} className="cursor-pointer">
        {children}
      </tr>
      <tr className="min-w-100" onClick={handleRowClick}>
        <td colSpan="4" className="border-0 position-relative">
          <div
            className="position-absolute"
            style={{
              left: "47%",
              top: -10,
              transform: isExpanded ? "rotate(180deg)" : "none",
            }}
          >
            <ExpandMoreIcon />
          </div>
        </td>
      </tr>
      <tr className="min-w-100 border-0">
        <td colSpan="4" className="border-0">
          <animated.div
            style={{
              overflow: "hidden",
              ...style,
            }}
          >
            <table className="w-100" ref={ref}>
              <tbody>
                {detailsData.map(({ label, value }) => (
                  <tr key={label}>
                    <td className="text-muted text-left">{label}</td>
                    <td className="font-weight-bold text-left p-0">{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </animated.div>
        </td>
      </tr>
    </>
  );
};
