import React, { useEffect, useState, useCallback } from "react";
import {
  PAX_TYPE_ADULT,
  PAX_TYPE_CHILD,
  PAX_TYPE_INFANT,
  PAX_TYPE_YOUTH,
} from "../../../../../../../redux/state/booking/bookingConstants";
import { Seperator } from "../../../../common";

export const OrderPriceCard = ({ data, isBookingDetails, invoice }) => {
  const [passengerFare, setPassengerFare] = useState({
    [PAX_TYPE_ADULT]: 0,
    [PAX_TYPE_YOUTH]: 0,
    [PAX_TYPE_CHILD]: 0,
    [PAX_TYPE_INFANT]: 0,
  });
  const [passengersCount, setPassengersCount] = useState({
    [PAX_TYPE_ADULT]: 0,
    [PAX_TYPE_YOUTH]: 0,
    [PAX_TYPE_CHILD]: 0,
    [PAX_TYPE_INFANT]: 0,
  });
  const [priceState, setPriceState] = useState({
    totalTax: 0,
    passengerCount: 0,
    totalPrice: 0,
    discount: 0,
    subTotal: 0,
    transactionFee: 0,
  });

  const initiateFlightInfoPriceDetails = useCallback(() => {
    const { selectedAirline, paxDetails } = data;
    const {
      totalAmt,
      abf,
      cbf,
      ybf,
      ibf,
      atx,
      ctx,
      ytx,
      itx,
    } = selectedAirline;
    const { adult, youth, child, infant } = paxDetails;
    const passengerCount = adult + youth + child + infant;
    const tax =
      +atx * adult +
      (+ytx || 0) * youth +
      (+ctx || 0) * child +
      (+itx || 0) * infant;
    setPassengerFare({
      [PAX_TYPE_ADULT]: abf || 0,
      [PAX_TYPE_YOUTH]: ybf || 0,
      [PAX_TYPE_CHILD]: cbf || 0,
      [PAX_TYPE_INFANT]: ibf || 0,
    });
    setPassengersCount({
      [PAX_TYPE_ADULT]: adult,
      [PAX_TYPE_YOUTH]: youth,
      [PAX_TYPE_CHILD]: child,
      [PAX_TYPE_INFANT]: infant,
    });
    setPriceState({
      totalTax: tax,
      passengerCount,
      totalPrice: totalAmt,
      discount: 0,
      subTotal: totalAmt,
      transactionFee: 0,
    });
  }, [data]);

  const initiateBookingPriceDetails = useCallback(() => {
    const { passengers, totalPrice, discount, subTotal, transactionFee } = data;
    const passengerArr = passengers?.$values || passengers;
    if (passengerArr) {
      const totalTax = passengerArr.reduce(
        (acc, passenger) => acc + +passenger.totalTaxAmount,
        0
      );
      passengerArr.forEach(({ totalFareAmount, paxType }) => {
        setPassengerFare((prevState) => ({
          ...prevState,
          [paxType]: totalFareAmount,
        }));
        setPassengersCount((prevState) => ({
          ...prevState,
          [paxType]: prevState[paxType] + 1,
        }));
      });
      setPriceState({
        totalTax,
        passengerCount: passengerArr.length,
        totalPrice,
        discount,
        subTotal,
        transactionFee,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setPassengerFare({
      [PAX_TYPE_ADULT]: 0,
      [PAX_TYPE_YOUTH]: 0,
      [PAX_TYPE_CHILD]: 0,
      [PAX_TYPE_INFANT]: 0,
    });
    setPassengersCount({
      [PAX_TYPE_ADULT]: 0,
      [PAX_TYPE_YOUTH]: 0,
      [PAX_TYPE_CHILD]: 0,
      [PAX_TYPE_INFANT]: 0,
    });
    setPriceState({
      totalTax: 0,
      passengerCount: 0,
      totalPrice: 0,
      discount: 0,
    });
    if (isBookingDetails) {
      initiateBookingPriceDetails();
    } else {
      initiateFlightInfoPriceDetails();
    }
  }, [
    data,
    initiateBookingPriceDetails,
    initiateFlightInfoPriceDetails,
    isBookingDetails,
  ]);
  const {
    totalPrice,
    totalTax,
    passengerCount,
    discount,
    transactionFee,
    subTotal,
  } = priceState;

  return (
    <div className="m-7">
      <div className="d-flex justify-content-between mb-2">
        <span>Price Adult (X{passengersCount[PAX_TYPE_ADULT]})</span>
        <span>
          $
          {(
            (+passengerFare?.[PAX_TYPE_ADULT] || 0) *
            passengersCount[PAX_TYPE_ADULT]
          ).toFixed(2)}
        </span>
      </div>
      {/* <div className="d-flex justify-content-between mb-2">
        <span>Price Youth (X{passengersCount[PAX_TYPE_YOUTH]})</span>
        <span>
          $
          {(+passengerFare?.[PAX_TYPE_YOUTH] || 0) *
            passengersCount[PAX_TYPE_YOUTH]}
        </span>
      </div> */}
      <div className="d-flex justify-content-between mb-2">
        <span>Price Child (X{passengersCount[PAX_TYPE_CHILD]})</span>
        <span>
          $
          {(
            (+passengerFare?.[PAX_TYPE_CHILD] || 0) *
            passengersCount[PAX_TYPE_CHILD]
          ).toFixed(2)}
        </span>
      </div>
      <div className="d-flex justify-content-between mb-8">
        <span>Price Infant (X{passengersCount[PAX_TYPE_INFANT]})</span>
        <span>
          $
          {(
            (+passengerFare?.[PAX_TYPE_INFANT] || 0) *
            passengersCount[PAX_TYPE_INFANT]
          ).toFixed(2)}
        </span>
      </div>
      <div className="d-flex justify-content-between mb-5">
        <span>Fee & Taxes</span>
        <span>${totalTax}</span>
      </div>
      <div className="d-flex justify-content-between mb-5">
        <span>No. of pax</span>
        <span>{passengerCount}</span>
      </div>
      <Seperator />
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Subtotal</span>
        <span>${(+subTotal).toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Transaction fee</span>
        <span>+${(transactionFee || 0).toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Discount</span>
        <span>-${(discount || 0).toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Total Amount</span>
        <span>${(+totalPrice || 0).toFixed(2)}</span>
      </div>
      {invoice && (
        <>
          <Seperator />
          <div className="font-weight-bold d-flex justify-content-between mb-5">
            <span>Paid Amount</span>
            <span>${(invoice?.received || 0).toFixed(2)}</span>
          </div>
          <div className="font-weight-bolder d-flex justify-content-between mb-5">
            <span>Balance Outstanding</span>
            <span>${(invoice?.due || 0).toFixed(2)}</span>
          </div>
        </>
      )}
    </div>
  );
};
