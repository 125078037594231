import { FormControlLabel, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import usePackageInclusionsList from "../../../../../../app/helpers/hooks/usePackageInclusionsList";
import usePkgInclusionName from "../../../../../../app/helpers/hooks/usePkgInclusionName";

import { GreenCheckbox, LoadingSpinner } from "../../../common";
import { InventoryFormActions } from "./InventoryFormActions";

export const PackageInclusions = ({
  setSelectedTab,
  selectedTab,
  data,
  onSubmit,
}) => {
  const { list, loading } = usePackageInclusionsList();
  const { getInclusionName } = usePkgInclusionName();
  const fields = useFormik({
    initialValues: data ?? {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const { setFieldValue, values, handleSubmit, setValues } = fields;
  useEffect(() => {
    if (list) {
      const inclusionsDefaultValues = list.reduce(
        (acc, { id }) => ({ ...acc, [id]: false }),
        {}
      );
      setValues(inclusionsDefaultValues);
    }
  }, [list, setValues]);
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" className="font-weight-bold">
        Inclusions
      </Typography>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={3}>
          {Object.keys(values).map((key, index) => (
            <Grid key={`inclusion-${index}`} item xs={12} md={3}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={values[key]}
                    onClick={() => setFieldValue(key, !values[key])}
                  />
                }
                label={
                  <Typography className="font-weight-bold">
                    {getInclusionName(+key)}
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
      <InventoryFormActions
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </form>
  );
};
