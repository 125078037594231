import clsx from "clsx";
import React from "react";
import SVG from "react-inlinesvg";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { getDateTime } from "../../../../../_helpers/date";
import { useMediaQuery, useTheme } from "@material-ui/core";

export const BankRow = ({ subPermissions, data, onDelete, onEdit }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { id, name, createdAt, country, isActive } = data;
  const { time, date } = getDateTime(createdAt);
  const allBanksSubPermission =
    adminPermissionsIds.payment.banks.subPermissions;
  return (
    <tr>
      <td className="text-left text-primary">{name || "-"}</td>
      <td
        className={clsx({ "text-success": isActive, "text-danger": !isActive })}
      >
        {isActive ? "Active" : "Inactive"}
      </td>
      <td>{country || "-"}</td>
      {matchesSm && (
        <td>
          <div>
            <p className="mb-0">{date}</p>
            <p className="text-muted font-weight-normal mb-0">{time}</p>
          </div>
        </td>
      )}

      <td className="text-right d-flex justify-content-end">
        <button
          hidden={!subPermissions.includes(allBanksSubPermission.edit.id)}
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span title="Edit" className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button
          hidden={!subPermissions.includes(allBanksSubPermission.delete.id)}
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span title="Delete" className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </td>
    </tr>
  );
};
