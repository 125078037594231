import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { SupplierUpdateHeader } from "./SupplierUpdateHeader";
import { actions } from "../../../../../../../redux/state/supplier";
import { Button, Input } from "../../../../common";
import { getDateTime } from "../../../../../../_helpers/date";
import { actions as inlineNotificationActions } from "../../../../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const supplierTopUpEditSchema = Yup.object().shape({
  fee: Yup.number().required("Please Enter Supplier Fee"),
  receivedDate: Yup.date().required("Please Enter Received Date"),
});

export const SupplierUpdateModal = ({
  show,
  data,
  onHide,
  getSupplierName,
}) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const { success, loading, failure } = useSelector(
    ({ supplier }) => supplier.editSupplierTopUIp
  );

  const fields = useFormik({
    enableReinitialize: true,
    initialValues: { fee: "", receivedDate: "" },
    validationSchema: supplierTopUpEditSchema,
    onSubmit: ({ fee, receivedDate }) => {
      dispatch(
        actions.editSupplierTopUp({
          ...data,
          status: 2,
          fee: +fee,
          receivedDate,
          approvedByUserInTopUpId: +userId,
        })
      );
    },
  });

  const { resetForm } = fields;

  const handleClose = useCallback(() => {
    onHide();
    resetForm();
  }, [resetForm, onHide]);
  useEffect(() => {
    if (success) {
      dispatch(actions.clearEditSupplierTopUp());
      handleClose();
    }
  }, [dispatch, handleClose, onHide, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearEditSupplierTopUp());
    }
  }, [dispatch, handleClose, onHide, failure]);
  const { getFieldProps, touched, errors, handleSubmit } = fields;
  const { id, createdAt, bookingTransactionId, supplierId, amount } =
    data || {};
  const { date, time } = getDateTime(createdAt);

  return (
    <Modal
      size="md"
      onHide={handleClose}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <SupplierUpdateHeader
          title={"Update Supplier Refund"}
          loading={loading}
        />
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">ID</Typography>
              <Typography>{id}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Booking Id</Typography>
              <Typography>{bookingTransactionId}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">
                Requested Date
              </Typography>
              <Typography>{date}</Typography>
              <Typography>{time}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Amount</Typography>
              <Typography>${amount}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className="mb-3 text-muted">Supplier</Typography>
              <Typography>{getSupplierName(supplierId)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                className="mt-3"
                {...getFieldProps("fee")}
                showErrors={touched["fee"]}
                errorMessage={errors["fee"]}
                variant="secondary"
                label="Supplier Fee"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                className="mt-3"
                {...getFieldProps("receivedDate")}
                showErrors={touched["receivedDate"]}
                errorMessage={errors["receivedDate"]}
                type="date"
                variant="secondary"
                label="Received Date"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={handleClose}
            variant="light"
          >
            Cancel
          </Button>
          <> </>
          <Button smallPadding loading={loading} type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
