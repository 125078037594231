import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/package";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { CityForm } from "./CityForm";
import { CityRow } from "./CityRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";

const citySchema = Yup.object().shape({
  cityName: Yup.string().required("City name is required"),
  // country: Yup.string().required("country is required"),
});

export const Cities = ({ permissionId }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showCityForm, setShowCityForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ packages }) => packages.allCities);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ packages }) => packages.deleteCity);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ packages }) => packages.createCity);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ packages }) => packages.editCity);
  const fields = useFormik({
    initialValues: {
      cityName: "",
      country: "",
    },
    validationSchema: citySchema,
    onSubmit: ({ cityName, country }) => {
      if (!!selectedId) {
        const data = getSelectedCity(selectedId);
        dispatch(actions.editCity({ ...data, name: cityName, country }));
      } else {
        dispatch(actions.createCity({ name: cityName, country }));
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllCities());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowCityForm(false);
    dispatch(actions.clearCreateCity());
    dispatch(actions.clearEditCity());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "City Added",
        })
      );
      dispatch(actions.getAllCities());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateCity());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "City Edited",
        })
      );
      dispatch(actions.getAllCities());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeleteCity());
    setSelectedId(undefined);
  }, [dispatch]);
  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(actions.clearEditCity());
  //   }
  // }, [dispatch, editSuccess]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deleteCity({ id: selectedId }));
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeleteCity());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { name, country } = getSelectedCity(id);
    fields.setValues({ cityName: name, country });
    setShowCityForm(true);
  };
  const getSelectedCity = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );

  const tableContent = list?.map((item, index) => (
    <CityRow
      key={index}
      // subPermissions={subPermissions}
      data={item}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Cities</Typography>
            <button
              // hidden={!subPermissions.includes(allCitiesSubPermission.add.id)}
              onClick={() => setShowCityForm(true)}
              className="btn btn-danger"
            >
              Add city
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete city"
            body="Are you sure you want to delete this city?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
          />
          {/* <CityForm
            onHide={() => setShowEdit(false)}
            show={showEdit}
            data={getSelectBooking()}
            success={editSuccess}
            loading={editLoading}
            fields={fields}
          /> */}
          <CityForm
            onHide={hideFormModal}
            show={showCityForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Id</th>
                      <th>City Name</th>
                      <th>Country</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No cities to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
