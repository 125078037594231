import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../app/helpers/formatters";
import useCompanyName from "../../../../app/helpers/hooks/useCompanyName";
import useExportBooking, {
  exportBookingTypesMap,
} from "../../../../app/helpers/hooks/useExportBooking";
import { TICKET_DETAILS } from "../../../../app/routesMap";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../app/routesMapAdmin";
import {
  BookingCancelled,
  BookingFailed,
  BookingHold,
  BookingIssue,
  BookingPending,
  BookingProcessing,
  BookingReIssuePending,
  BookingRefund,
  BookingRefundPending,
  BookingVoidPending,
  bookingTypesMap,
} from "../../../../redux/state/booking/bookingConstants";
import { actions } from "../../../../redux/state/bookingMng";
import {
  ALL,
  orderFilterTypesMap,
  sortTypesMap,
} from "../../../../redux/state/bookingMng/bookingMngConstants";
import { filterOptions } from "../../../../redux/state/invoices/invoicesConstants";
import { getDateTime } from "../../../_helpers/date";
import { LoadingSpinner, Tabs } from "../common";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";
import { SearchCard } from "./common/SearchCard";

export const trackingBadgeClassNames = {
  [BookingIssue]: "text-success",
  [BookingHold]: "text-warning",
  [BookingCancelled]: "text-danger",
  [BookingRefund]: "text-info",
  [BookingProcessing]: "text-warning",
  [BookingPending]: "text-warning",
  [BookingFailed]: "text-danger",
  [BookingVoidPending]: "text-warning",
  [BookingRefundPending]: "text-warning",
  [BookingReIssuePending]: "text-warning",
};
export const trackingBadgeText = {
  [BookingIssue]: BookingIssue,
  [BookingHold]: BookingHold,
  [BookingCancelled]: BookingCancelled,
  [BookingRefund]: BookingRefund,
  [BookingProcessing]: BookingProcessing,
  [BookingPending]: BookingPending,
  [BookingFailed]: BookingFailed,
  [BookingVoidPending]: BookingVoidPending,
  [BookingRefundPending]: BookingRefundPending,
  [BookingReIssuePending]: BookingReIssuePending,
};
const TableRow = ({ data, isAdmin, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    insertedOn,
    bookingId,
    gdsPnr,
    totalPrice,
    bookingStatus,
    airlineRefNo,
    agencyId,
    segment,
  } = data;
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  const agencyName = getCompanyName(agencyId);
  const { date, time } = getDateTime(insertedOn);
  const detailsLink = isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS;
  const rows = (
    <>
      <td>
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(detailsLink, { id: bookingId })}
        >
          <span className="font-weight-bold">{bookingId}</span>
        </NavLink>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="text-dark-75 font-weight-bold d-block font-size-lg">
              {date}
            </span>
            <span className="text-muted font-weight-bold">{time}</span>
          </td>
          <td>
            <span className="font-weight-bold">{gdsPnr || "-"}</span>
          </td>
          <td>
            <div className="d-flex flex-column">
              {segments?.map(({ iSeg }, index) =>
                iSeg?.map(({ fn }, index2) => (
                  <span
                    key={`fn-${index}-${index2}`}
                    className="font-weight-bold mb-1"
                  >
                    {fn}
                  </span>
                ))
              )}
            </div>
          </td>
        </>
      )}
      <td>
        <div className="d-flex flex-column">
          {segments?.map(({ iSeg }, index) =>
            iSeg?.map(({ dApt, aApt }, index2) => (
              <span key={`route-${index}-${index2}`}>
                <span className="text-dark-75 font-weight-bold mb-1 font-size-lg">
                  {dApt}
                </span>
                <span className="font-weight-bold">{" > "}</span>
                <span className="text-dark-75 font-weight-bold mb-1 font-size-lg">
                  {aApt}
                </span>
              </span>
            ))
          )}
        </div>
      </td>
      {matchesSm && (
        <>
          <td>
            <div className="d-flex flex-column">
              {segments?.map(({ iSeg }, index) =>
                iSeg?.map(({ dDat }, index2) => (
                  <span
                    key={`dDat-${index}-${index2}`}
                    className="font-weight-bold mb-1"
                  >
                    {dDat}
                  </span>
                ))
              )}
            </div>
          </td>
          <td>
            <span className="font-weight-bold">{airlineRefNo || "-"}</span>
          </td>
          <td>
            <span className="font-weight-bold">
              ${(+totalPrice)?.toFixed(2) || "-"}
            </span>
          </td>
          <td>
            <span className="font-weight-bold">{agencyName || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span className={trackingBadgeClassNames[bookingStatus]}>
          {trackingBadgeText[bookingStatus] || "-"}
        </span>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "Creation Date",
      value: (
        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
          {date}
        </span>
      ),
    },
    {
      label: "GDS PNR",
      value: <span className="font-weight-bold">{gdsPnr || "-"}</span>,
    },
    {
      label: "Flight No",
      value: (
        <div className="d-flex flex-column">
          {segments?.map(({ iSeg }, index) =>
            iSeg?.map(({ fn }, index2) => (
              <span key={`fn-${index}-${index2}`} className="font-weight-bold">
                {fn}
              </span>
            ))
          )}
        </div>
      ),
    },
    {
      label: "Route",
      value: (
        <div className="d-flex flex-column">
          {segments?.map(({ iSeg }, index) =>
            iSeg?.map(({ dApt, aApt }, index2) => (
              <span key={`route-${index}-${index2}`}>
                <span className="text-dark-75 font-weight-bold mb-1 font-size-lg">
                  {dApt}
                </span>
                <span className="font-weight-bold">{" > "}</span>
                <span className="text-dark-75 font-weight-bold mb-1 font-size-lg">
                  {aApt}
                </span>
              </span>
            ))
          )}
        </div>
      ),
    },
    {
      label: "Flight Date",
      value: (
        <div className="d-flex flex-column">
          {segments?.map(({ iSeg }, index) =>
            iSeg?.map(({ dDat }, index2) => (
              <span
                key={`dDat-${index}-${index2}`}
                className="font-weight-bold mb-1"
              >
                {dDat}
              </span>
            ))
          )}
        </div>
      ),
    },
    {
      label: "Airline PNR",
      value: <span className="font-weight-bold">{airlineRefNo || "-"}</span>,
    },
    {
      label: "Total Price",
      value: (
        <span className="font-weight-bold">
          ${(+totalPrice)?.toFixed(2) || "-"}
        </span>
      ),
    },
    {
      label: "Agency Name",
      value: <span className="font-weight-bold">{agencyName || "-"}</span>,
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const TrackingTable = ({ data, sortState, setSortState }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { getCompanyName } = useCompanyName();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const tableContent = data?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      isAdmin={isAdmin}
      key={`row_${index}`}
      data={item}
    />
  ));
  const handleClickSort = (columnName) => {
    const resetState = {
      bookingId: sortTypesMap.noSort,
      insertedOn: sortTypesMap.noSort,
      totalPrice: sortTypesMap.noSort,
    };
    const newSortValue = (sortState[columnName] + 1) % 3;
    setSortState({ ...resetState, [columnName]: newSortValue });
  };
  return (
    <div className="table-responsive">
      <table className="table table-vertical-center mb-0 mt-5">
        <thead>
          <tr className="bg-gray-100">
            <th>
              <div className="d-flex align-items-center">
                <span>Order No</span>
                <div
                  onClick={() => handleClickSort("bookingId")}
                  className="btn-group-vertical ml-4"
                >
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-up", {
                        "text-dark":
                          sortState.bookingId === sortTypesMap.ascending,
                      })}
                    ></i>
                  </span>
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-down", {
                        "text-dark":
                          sortState.bookingId === sortTypesMap.descending,
                      })}
                    ></i>
                  </span>
                </div>
              </div>
            </th>
            {matchesSm && (
              <>
                <th className="d-flex align-items-center min-w-100px">
                  <span className="d-block">Creation Date</span>
                  <div
                    onClick={() => handleClickSort("insertedOn")}
                    className="btn-group-vertical ml-4"
                  >
                    <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                      <i
                        className={clsx("fas fa-sort-up", {
                          "text-dark":
                            sortState.insertedOn === sortTypesMap.ascending,
                        })}
                      ></i>
                    </span>
                    <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                      <i
                        className={clsx("fas fa-sort-down", {
                          "text-dark":
                            sortState.insertedOn === sortTypesMap.descending,
                        })}
                      ></i>
                    </span>
                  </div>
                </th>
                <th>GDS PNR </th>
                <th>Flight No</th>
              </>
            )}
            <th className="min-w-100px">Route</th>
            {matchesSm && (
              <>
                <th className="min-w-100px">Flight Date</th>
                <th>Airline PNR </th>
                <th className="d-flex align-items-center">
                  <span className="d-block">Total Price</span>
                  <div
                    onClick={() => handleClickSort("totalPrice")}
                    className="btn-group-vertical ml-4"
                  >
                    <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                      <i
                        className={clsx("fas fa-sort-up", {
                          "text-dark":
                            sortState.totalPrice === sortTypesMap.ascending,
                        })}
                      ></i>
                    </span>
                    <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                      <i
                        className={clsx("fas fa-sort-down", {
                          "text-dark":
                            sortState.totalPrice === sortTypesMap.descending,
                        })}
                      ></i>
                    </span>
                  </div>
                </th>
                <th>Agency Name</th>
              </>
            )}
            <th>Status</th>
            {/* {!isAdmin && <th>actions</th>} */}
          </tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>
      {data?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No bookings to show
        </Typography>
      )}
    </div>
  );
};

export const Tracking = () => {
  const [sortState, setSortState] = useState({
    bookingId: sortTypesMap.noSort,
    insertedOn: sortTypesMap.descending,
    totalPrice: sortTypesMap.noSort,
  });
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const { exportToExcel, loading: downloadLoading } = useExportBooking();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const [searchValue, setSearchValue] = useState("");
  const [secondSearchValue, setSecondSearchValue] = useState("");
  const [filter, setFilter] = useState();
  const [statusFilter, setStatusFilter] = useState(ALL);
  const { list, loading, paginationInfo, statusCounts } = useSelector(
    ({ bookingMng }) => bookingMng.orders
  );
  const { totalPages } = paginationInfo ?? {};

  useEffect(() => {
    setSearchValue("");
    setSecondSearchValue("");
  }, [filter]);
  useEffect(() => {
    setPageNumber(1);
  }, [sortState]);
  useEffect(() => {
    const sortingParams = Object.keys(sortState)
      .filter((key) => sortState[key] !== sortTypesMap.noSort)
      .map((key) => ({ columnName: key, sortOrder: sortState[key] }));
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "bookingStatus",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                  {
                    columnName: "transactionType",
                    filterValue: `${bookingTypesMap.package}`,
                    filterOption: filterOptions.IsNotEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.ticketTracking,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            {
              columnName: "transactionType",
              filterValue: `${bookingTypesMap.package}`,
              filterOption: filterOptions.IsNotEqualTo,
            },
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "bookingStatus",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.ticketTracking,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInUserId, dispatch, isAdmin, pageNumber, statusFilter, sortState]);

  const handleChange = (e, isSecond) => {
    if (isSecond) {
      setSecondSearchValue(e?.target?.value || e);
    } else {
      setSearchValue(e?.target?.value || e);
    }
  };
  const handleSubmit = (filters) => {
    setPageNumber(1);

    setAppliedFilters(filters);
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [
            ...filters,
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "bookingStatus",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                  {
                    columnName: "transactionType",
                    filterValue: `${bookingTypesMap.package}`,
                    filterOption: filterOptions.IsNotEqualTo,
                  },
                ]),
          ],
          type: orderFilterTypesMap.ticketTracking,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            {
              columnName: "transactionType",
              filterValue: `${bookingTypesMap.package}`,
              filterOption: filterOptions.IsNotEqualTo,
            },
            ...filters,
          ],
          type: orderFilterTypesMap.ticketTracking,
        })
      );
    }
  };
  const reset = () => {
    setSearchValue("");
    setSecondSearchValue("");
    setStatusFilter(ALL);
    setAppliedFilters([{}]);
    setSortState({
      bookingId: sortTypesMap.noSort,
      insertedOn: sortTypesMap.descending,
      totalPrice: sortTypesMap.noSort,
    });
    if (pageNumber === 1) {
      if (isAdmin) {
        dispatch(
          actions.filterOrdersPagination({
            filterParam: [
              {
                columnName: "transactionType",
                filterValue: `${bookingTypesMap.package}`,
                filterOption: filterOptions.IsNotEqualTo,
              },
            ],
            type: orderFilterTypesMap.ticketTracking,
          })
        );
      } else {
        dispatch(
          actions.filterOrdersPagination({
            filterParam: [
              {
                columnName: "agencyId",
                filterValue: `${companyInUserId}`,
                filterOption: filterOptions.IsEqualTo,
              },
              {
                columnName: "transactionType",
                filterValue: `${bookingTypesMap.package}`,
                filterOption: filterOptions.IsNotEqualTo,
              },
            ],
            type: orderFilterTypesMap.ticketTracking,
          })
        );
      }
    } else {
      setPageNumber(1);
    }
  };
  const onRightButtonClick = () => {
    const filterParam = [
      ...(isAdmin
        ? []
        : [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
          ]),
      ...(statusFilter === ALL
        ? []
        : [
            {
              columnName: "bookingStatus",
              filterValue: statusFilter,
              filterOption: filterOptions.IsEqualTo,
            },
          ]),
      ...appliedFilters,
    ];
    exportToExcel({ type: exportBookingTypesMap.ticketTracking, filterParam });
    // dispatch(actions.exportOrders({}));
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const adminTabsData = useMemo(() => {
    const { all, cancelled, failed, hold, issued, processing, pending } =
      statusCounts ?? {};
    return [
      { title: `ALL (${all ?? 0})`, filter: ALL },
      { title: `Reserved (${hold ?? 0})`, filter: BookingHold },
      {
        title: `Ticketing Processing (${processing ?? 0})`,
        filter: BookingProcessing,
      },
      { title: `Ticketing Completed (${issued ?? 0})`, filter: BookingIssue },
      {
        title: `Pending (${pending ?? 0})`,
        filter: BookingPending,
      },
      { title: `Cancelled (${cancelled ?? 0})`, filter: BookingCancelled },
      { title: `Failed (${failed ?? 0})`, filter: BookingFailed },
    ];
  }, [statusCounts]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            secondValue={secondSearchValue}
            onSubmit={handleSubmit}
            reset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <Tabs
            setFilter={(v) => {
              setStatusFilter(v);
              setPageNumber(1);
            }}
            data={isAdmin ? adminTabsData : agencyTabsData}
            noContent
            rightButtonTitle={isAdmin ? "Export" : ""}
            onRightButtonClick={onRightButtonClick}
            buttonLoading={downloadLoading}
            selectedFilter={statusFilter}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <TrackingTable
                sortState={sortState}
                setSortState={setSortState}
                data={list}
              />
            </>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

const agencyTabsData = [
  { title: `ALL`, filter: ALL },
  { title: `Reserved`, filter: BookingHold },
  {
    title: `Ticketing Processing`,
    filter: BookingProcessing,
  },
  { title: `Ticketing Completed`, filter: BookingIssue },
  {
    title: "Pending",
    filter: BookingPending,
  },
  { title: `Cancelled`, filter: BookingCancelled },
  { title: `Failed`, filter: BookingFailed },
];
