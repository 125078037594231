import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const SearchCardShimmer = () => {
  return (
    <div className="d-flex flex-column">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} className="">
          <Skeleton
            className="rounded"
            animation="wave"
            variant="rect"
            width="100%"
            height={80}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton
            className="rounded"
            animation="wave"
            variant="rect"
            width="100%"
            height={80}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Skeleton
            className="rounded"
            animation="wave"
            variant="rect"
            width="100%"
            height={80}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Skeleton
            className="rounded"
            animation="wave"
            variant="rect"
            width="100%"
            height={80}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Skeleton
            className="rounded"
            animation="wave"
            variant="rect"
            width="100%"
            height={80}
          />
        </Grid>
      </Grid>
      <Skeleton
        className="rounded ml-auto mt-4"
        animation="wave"
        variant="rect"
        width={120}
        height={50}
      />
    </div>
  );
};
