// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Grid, Typography } from "@material-ui/core";

import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../../_partials/controls";
import { getDateTime } from "../../../../../../../_helpers/date";
import { pax_types_text } from "../../../../../../../../redux/state/booking/bookingConstants.js";
import { Button } from "../../../../../common";
import useCompanyName from "../../../../../../../../app/helpers/hooks/useCompanyName";

const statusOptions = [
  { label: "Hold", value: "Hold" },
  { label: "Issued", value: "Issued" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Refund", value: "Refund" },
];

export const BookingEditForm = ({ onSave, data, loading, onHide }) => {
  const { getCompanyName } = useCompanyName();
  const { id, agencyId, bookingId, passengers, flight } = data || {};
  const agencyName = getCompanyName(agencyId);
  // const { date, time } = getDateTime(insertedOn);
  // const initialValues = {
  //   tickets: passengers?.$values.map(() => ""),
  // };
  const { $values } = passengers || {};
  const { date, time } = getDateTime(flight?.departureDateTime);
  const initialValues = useMemo(() => {
    if (!$values) return {};
    const ticketsValuesObject = {};
    $values.forEach(({ id }) => (ticketsValuesObject[`${id}`] = ""));
    return ticketsValuesObject;
  }, [$values]);
  const CustomerEditSchema = useMemo(() => {
    if (!$values) return {};
    const ticketsSchemaObject = {};
    $values.forEach(
      ({ id }) =>
        (ticketsSchemaObject[`${id}`] = Yup.string().required(
          "Ticket No is required"
        ))
    );
    return Yup.object().shape({ ...ticketsSchemaObject });
  }, [$values]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          const tickets = Object.keys(values).map((key) => ({
            passengerId: +key,
            ticketNo: values[key],
          }));
          onSave({ id, tickets });
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="overlay overlay-block cursor-default">
              {loading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className="mb-3 text-muted">
                    Booking Id
                  </Typography>
                  <Typography>{bookingId}</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className="mb-3 text-muted">
                    Agency Name
                  </Typography>
                  <Typography>{agencyName}</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography className="mb-3 text-muted">
                    Depart Date
                  </Typography>
                  <Typography>
                    {date} {time}
                  </Typography>
                </Grid>
                <div className="mb-5" />
                {passengers?.$values.map(
                  ({ firstName, lastName, paxType, id }, index) => {
                    const name = `${firstName} ${lastName}`;
                    const passengerType = pax_types_text[paxType];
                    return (
                      <Grid key={`passenger-${id}`} item xs>
                        <Typography className="mb-2">
                          Ticket No for{" "}
                          <span className="font-weight-bold">{name}</span> (
                          {passengerType})
                        </Typography>
                        <Field
                          name={`${id}`}
                          component={Input}
                          placeholder="Ticket Number"
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <Button
                smallPadding
                type="button"
                onClick={onHide}
                variant="light"
              >
                Cancel
              </Button>
              <> </>
              <Button loading={loading} smallPadding type="submit">
                Update
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};
