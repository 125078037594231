import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/package";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { InclusionsForm } from "./InclusionsForm";
import { InclusionsRow } from "./InclusionsRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";

const inclusionSchema = Yup.object().shape({
  inclusionName: Yup.string().required("Package Inclusion name is required"),
  // country: Yup.string().required("country is required"),
});

export const Inclusions = ({ permissionId }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showInclusionForm, setShowInclusionForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(
    ({ packages }) => packages.allInclusions
  );
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ packages }) => packages.deleteInclusion);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ packages }) => packages.createInclusion);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ packages }) => packages.editInclusion);
  const fields = useFormik({
    initialValues: {
      inclusionName: "",
    },
    validationSchema: inclusionSchema,
    onSubmit: ({ inclusionName }) => {
      if (!!selectedId) {
        const data = getSelectedInclusion(selectedId);
        dispatch(
          actions.editInclusion({
            ...data,
            name: inclusionName,
          })
        );
      } else {
        dispatch(actions.createInclusion({ name: inclusionName }));
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllInclusions());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowInclusionForm(false);
    dispatch(actions.clearCreateInclusion());
    dispatch(actions.clearEditInclusion());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Inclusion Added",
        })
      );
      dispatch(actions.getAllInclusions());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateInclusion());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Inclusion Edited",
        })
      );
      dispatch(actions.getAllInclusions());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeleteInclusion());
    setSelectedId(undefined);
  }, [dispatch]);
  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(actions.clearEditInclusion());
  //   }
  // }, [dispatch, editSuccess]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deleteInclusion({ id: selectedId }));
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeleteInclusion());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { name } = getSelectedInclusion(id);
    fields.setValues({ inclusionName: name });
    setShowInclusionForm(true);
  };
  const getSelectedInclusion = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );

  const tableContent = list?.map((item, index) => (
    <InclusionsRow
      key={index}
      // subPermissions={subPermissions}
      data={item}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Inclusions</Typography>
            <button
              // hidden={!subPermissions.includes(allCitiesSubPermission.add.id)}
              onClick={() => setShowInclusionForm(true)}
              className="btn btn-danger"
            >
              Add inclusion
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete inclusion"
            body="Are you sure you want to delete this inclusion?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
          />
          {/* <InclusionForm
            onHide={() => setShowEdit(false)}
            show={showEdit}
            data={getSelectBooking()}
            success={editSuccess}
            loading={editLoading}
            fields={fields}
          /> */}
          <InclusionsForm
            onHide={hideFormModal}
            show={showInclusionForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Id</th>
                      <th>Name</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Inclusions to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
