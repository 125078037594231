import React, { useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ADMIN_PACKAGES_CREATE_INVENTORY } from "../../../../../../app/routesMapAdmin";
import { Button, LoadingSpinner } from "../../../common";
import { InventoryRow } from "./InventoryRow";
import { actions } from "../../../../../../redux/state/package";
import usePkgSeasonName from "../../../../../../app/helpers/hooks/usePkgSeasonName";
import usePkgTypeName from "../../../../../../app/helpers/hooks/usePkgTypeName";
import { useMemo } from "react";

export const InventoryTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getPkgSeasonName, loading: pkgSeasonsLoading } = usePkgSeasonName();
  const { getPkgTypeName, loading: pkgTypeLoading } = usePkgTypeName();

  const { list, loading: packagesLoading } = useSelector(
    ({ packages }) => packages.allPackages
  );
  const loading = useMemo(
    () => pkgSeasonsLoading || pkgTypeLoading || packagesLoading,
    [pkgSeasonsLoading, pkgTypeLoading, packagesLoading]
  );
  const tableContent = list?.map((item, index) => (
    <InventoryRow
      key={index}
      // subPermissions={subPermissions}
      data={item}
      getPkgSeasonName={getPkgSeasonName}
      getPkgTypeName={getPkgTypeName}

      // onEdit={onEdit}
      // onDelete={onDelete}
    />
  ));
  useEffect(() => {
    dispatch(actions.getAllPackages());
  }, [dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-between">
          <Typography variant="h5" className="text-primary">
            Inventory list
          </Typography>
          <Button
            onClick={() => history.push(ADMIN_PACKAGES_CREATE_INVENTORY)}
            variant="danger"
          >
            Add New Package
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Name</th>
                      <th>Season</th>
                      <th>Type</th>
                      <th>Status</th>
                      {/* <th className="min-w-120px text-right">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Packages to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
