import React, { useEffect, useState, useCallback } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/accounting";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { ChartOfAccountsForm } from "./ChartOfAccountsForm";
import { ChartOfAccountsRow } from "./ChartOfAccountsRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const chartOfAccountSchema = Yup.object().shape({
  accountType: Yup.number().required("Account Type is required"),
  code: Yup.number().required("Code is required"),
  accountName: Yup.string().required("Account Name is required"),
});

export const ChartOfAccounts = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(
    ({ accounting }) => accounting.chartOfAccounts
  );
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ accounting }) => accounting.createChartOfAccount);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ accounting }) => accounting.editChartOfAccount);
  const fields = useFormik({
    initialValues: {
      code: "",
      accountType: "",
      accountName: "",
    },
    validationSchema: chartOfAccountSchema,
    onSubmit: ({ code, accountType, accountName }) => {
      if (!!selectedId) {
        const data = getSelectedChartOfAccount(selectedId);
        dispatch(
          actions.editChartOfAccount({
            ...data,
            code: +code,
            accountTypeId: +accountType,
            accountName,
          })
        );
      } else {
        dispatch(
          actions.createChartOfAccount({
            code: +code,
            accountTypeId: +accountType,
            accountName,
          })
        );
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getChartOfAccounts());
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearCreateChartOfAccount());
    dispatch(actions.clearEditChartOfAccount());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          code: NOTIFICATION_TYPE_SUCCESS,
          message: "Chart Of Account Added",
        })
      );
      dispatch(actions.getChartOfAccounts());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          code: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateChartOfAccount());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          code: NOTIFICATION_TYPE_SUCCESS,
          message: "Chart Of Account Edited",
        })
      );
      dispatch(actions.getChartOfAccounts());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const onEdit = (id) => {
    setSelectedId(id);
    const { code, accountTypeId, accountName } = getSelectedChartOfAccount(id);
    fields.setValues({ code, accountType: accountTypeId, accountName });
    showChartForm();
  };
  const showChartForm = () => {
    setShowForm(true);
    dispatch(actions.getAccountTypes());
  };
  const getSelectedChartOfAccount = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const tableContent = list?.map((item, index) => (
    <ChartOfAccountsRow key={index} data={item} onEdit={onEdit} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Chart Of Accounts</Typography>
            <button onClick={showChartForm} className="btn btn-danger">
              Add Chart Of Account
            </button>
          </div>
          <ChartOfAccountsForm
            onHide={hideFormModal}
            show={showForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Account Name</th>
                      <th>Account Type</th>
                      <th>Code</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Data to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
