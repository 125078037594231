import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./markupActions";

const initialState = {
  create: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  delete: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  markups: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificMarkup: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const markupReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_SPECIFIC_MARKUP.REQUESTED:
        state.specificMarkup.loading = true;
        break;
      case actions.GET_SPECIFIC_MARKUP.SUCCEEDED:
        state.specificMarkup.loading = false;
        state.specificMarkup.success = true;
        state.specificMarkup.data = payload.data;
        break;
      case actions.GET_SPECIFIC_MARKUP.FAILED:
        state.specificMarkup.loading = false;
        state.specificMarkup.failure = payload.error;
        break;
      case actions.GET_ALL_MARKUPS.REQUESTED:
      case actions.GET_COMPANY_MARKUPS.REQUESTED:
        state.markups.loading = true;
        break;
      case actions.GET_ALL_MARKUPS.SUCCEEDED:
      case actions.GET_COMPANY_MARKUPS.SUCCEEDED:
        state.markups.loading = false;
        state.markups.success = true;
        state.markups.list = payload.data;
        break;
      case actions.GET_ALL_MARKUPS.FAILED:
      case actions.GET_COMPANY_MARKUPS.FAILED:
        state.markups.loading = false;
        state.markups.failure = payload.error;
        break;
      case actions.CREATE_MARKUP.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE_MARKUP.SUCCEEDED:
        state.create.loading = false;
        state.create.success = true;
        break;
      case actions.CREATE_MARKUP.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;
        break;
      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.DELETE.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.markups.list = state.markups.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE.CLEAR:
        state.delete = initialState.delete;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
