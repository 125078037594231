import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/documents";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { getDateTime } from "../../../../../_helpers/date";
import { DocumentForm } from "./DocumentForm";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { NOTIFICATION_TYPE_ERROR } from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const Documents = ({ isUser, companyORUserId, title }) => {
  const dispatch = useDispatch();
  const { list } = useSelector(({ documents }) => documents.documents);
  const [showEditModal, setShowEditModal] = useState(false);
  const onHide = () => {
    setShowEditModal(false);
  };
  useEffect(() => {
    if (isUser) {
      dispatch(actions.getUserDocuments({ userId: companyORUserId }));
    } else {
      dispatch(actions.getCompanyDocuments({ companyId: companyORUserId }));
    }
  }, [dispatch, companyORUserId, isUser]);
  return (
    <Paper className="p-7">
      <DocumentForm
        isUser={isUser}
        companyORUserId={companyORUserId}
        onHide={onHide}
        show={showEditModal}
      />
      <div className="d-flex justify-content-between">
        <Typography variant="h6">{title}</Typography>
        <button
          onClick={() => setShowEditModal(true)}
          className="btn btn-danger"
        >
          Add document
        </button>
      </div>
      <div>
        {list?.map((item) => (
          <FileRow data={item} />
        ))}
        {list?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No documents to show
          </Typography>
        )}
      </div>
    </Paper>
  );
};

const FileRow = ({ data }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ documents }) => documents.delete);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { documentLink, createdAt, id, documentName } = data;
  const getFileType = () => {
    const arr = documentLink.split(".");
    return arr[arr.length - 1];
  };
  const { date } = getDateTime(createdAt);
  const deleteDocument = () => {
    dispatch(actions.deleteDocument({ id }));
  };
  const onFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDelete());
  };
  const onHide = () => {
    dispatch(actions.clearDelete());
    setShowModal(false);
  };
  return (
    <div className="d-flex justify-content-between align-items-center py-3 w-100">
      <ConfirmationModal
        onHide={onHide}
        show={showModal}
        onConfirm={deleteDocument}
        title="Delete document"
        body="Are you sure you want to delete this document?"
        loading={deleteLoading}
        success={deleteSuccess}
        failure={deleteFailure}
        onFailure={onFailure}
      />
      <a
        href={documentLink}
        target="_blank"
        rel="noopener noreferrer"
        className="d-flex align-items-center"
      >
        <div className="w-50px text-center">
          <img
            className="w-50px"
            src={toAbsoluteUrl("/media/svg/icons/General/cerificateIcon.svg")}
            alt={"file"}
          />
        </div>
        <div className="ml-5">
          <Typography variant="h6">{documentName}</Typography>
          <Typography variant="body1" className="text-muted">
            {date}
          </Typography>
        </div>
        <span
          className={`label label-lg label-success label-inline font-weight-bold ml-3 mb-auto`}
        >
          {getFileType()}
        </span>
      </a>
      {isAdmin && (
        <div className="d-flex align-items-center">
          {/* <button className="btn btn-icon btn-light mr-3 h-30px w-30px">
          <span className="svg-icon svg-icon-sm ">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button> */}
          <button
            onClick={() => setShowModal(true)}
            className="btn btn-icon btn-light mr-3 h-30px w-30px"
          >
            <span className="svg-icon svg-icon-sm ">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
