import { communicationClient } from "./client";

export const getAllCommunicationTypes = () =>
  communicationClient.get(`communication/v1/communicationType/GetAll`);

export const getEmails = ({ companyId }) =>
  communicationClient.get(
    `/communication/v1/Communication/GetAllByCompany/${companyId}`
  );

export const setEmails = ({ companyId, params }) =>
  communicationClient.post(
    `/communication/v1/Communication/AddByCompany/${companyId}`,
    params
  );

export const updateEmails = ({ companyId, params }) =>
  communicationClient.put(
    `/communication/v1/Communication/UpdateAllByCompany/${companyId}`,
    params
  );

export const updateNotificationStatus = ({ id }) =>
  communicationClient.put(
    `/communication/v1/Notification/UpdateNotificationStatus/${id}`
  );

export const resetNotificationCount = ({ id }) =>
  communicationClient.put(
    `/communication/v1/Notification/ResetNotificationCount/${id}`
  );

export const getSpecificNotification = ({ id }) =>
  communicationClient.get(`/communication/v1/Notification/${id}`);
