import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Seperator } from "../../../common";

export const FeesReportSummary = ({ stats }) => {
  const { totalFee, totalPax, totalBookings } = stats ?? {};
  return (
    <div>
      <Grid container className="bg-gray-200">
        <Grid item className="p-6 bg-gray-700" xs={12}></Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Fees</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">${(+totalFee).toFixed(2)}</Typography>
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Bookings</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">{totalBookings}</Typography>
        </Grid>
        <Seperator className="m-0" />
        <Grid item className="px-7 py-2" xs={6}>
          <Typography>Total Tickets</Typography>
        </Grid>
        <Grid item className="px-7 py-2" xs={6}>
          <Typography align="right">{totalPax}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
