import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input } from "../common";
import {
  HOME,
  SIGNUP_USER_TYPE,
  SIGN_IN_BY_CODE,
} from "../../../../app/routesMap";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/auth";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { useHistory, useLocation } from "react-router";
import { NOTIFICATION_TYPE_ERROR } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const initialValues = {
  // email: "sakulachi8+1@gmail.com",
  // password: "Sa123abc@",
  // email: "ahmedfayezmine3@gmail.com",
  // password: "11223344Aa",
  email: "",
  password: "",
};

export const LoginForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { from: fromPath } = location.state ?? {};
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required()
      .trim(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: ({ email, password }) => {
      login({ email: email.trim(), password });
    },
  });
  const { loading: loginLoading, success, status } = useSelector(
    ({ auth }) => auth.login
  );

  useEffect(() => {
    if (success === true) {
      if (status === 4) {
        history.push(SIGN_IN_BY_CODE);
      } else if (status === 2) {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            message: "The email or password you entered is incorrect",
            type: NOTIFICATION_TYPE_ERROR,
          })
        );
      } else if (status === 3) {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            message: "Email is not verified, please check your email",
            type: NOTIFICATION_TYPE_ERROR,
          })
        );
      } else if (status === 5) {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            message: "Company is not active!",
            type: NOTIFICATION_TYPE_ERROR,
          })
        );
      } else {
        history.replace(fromPath ?? HOME);
        dispatch(actions.authorizeLoginByCode());
        if (onSuccess) {
          onSuccess();
        }
      }
      dispatch(actions.clearLogin());
    }
  }, [dispatch, history, onSuccess, status, success, fromPath]);

  const login = ({ email, password }) => {
    dispatch(actions.login({ email, password }));
  };
  return (
    <div>
      <Typography
        variant="h5"
        className="font-weight-bolder text-dark text-white"
      >
        Welcome to Flyme247
      </Typography>
      <NavLink className="text-primary my-4" to={SIGNUP_USER_TYPE}>
        <span> Create an Account</span>
      </NavLink>
      <form onSubmit={formik.handleSubmit} className="mt-5">
        <Input
          spacing
          {...formik.getFieldProps("email")}
          errorMessage={formik.errors.email}
          showErrors={formik.touched.email}
          // variant="secondary"
          outlined={false}
          label="Email"
          placeholder="email@address.com"
          className="w-350px"
          // vertical
        />
        <Input
          spacing
          {...formik.getFieldProps("password")}
          errorMessage={formik.errors.password}
          showErrors={formik.touched.password}
          // vertical
          // variant="secondary"
          outlined={false}
          label="Password"
          placeholder="Enter your password"
          type="password"
          className="w-350px"
          haveShowPassword
          forgotPassword
        />

        <div className="d-flex justify-content-center mt-7 mb-3 font-weight-bolder w-100">
          <Button className="flex-grow-1" type="submit" loading={loginLoading}>
            Sign in
          </Button>
          {/* <button onClick={() => {}} className="btn ml-7 d-flex border-dark ">
              <img
                className="mr-3"
                src={toAbsoluteUrl("media/svg/social-icons/google.svg")}
                alt=""
              />
              Sign in with Google
            </button> */}
        </div>
      </form>
    </div>
  );
};
