import { searchClient } from "./client";

export const startSearch = (params) =>
  searchClient.post("/apiv3/flight/StartSearch", params);

export const startSearchByPnr = (params) =>
  searchClient.post("/apiv3/flight/StartSearchByPNR", params);

export const getSearchResults = (params) =>
  searchClient.post("/apiv3/flight/getsearchresult", params);

export const reprice = ({ GUID, FlightId }) =>
  searchClient.get(`/apiv3/flight/CheckFare?GUID=${GUID}&FlightId=${FlightId}`);

export const verifyTickets = (params) =>
  searchClient.post("apiv3/flight/TicketVerify",params);

export const getFareRules = ({ GUID, FlightId }) =>
  searchClient.get(`/apiv3/flight/FareRule?GUID=${GUID}&FlightId=${FlightId}`);
