import { client } from "./client";

export const createMarkup = ({ isActive, companyId, markupRuleJson }) =>
  client.post("/api/markup/", { isActive, companyId, markupRuleJson });

export const getMarkupByCompany = ({ companyId }) =>
  client.get(`/company/${companyId}`);

export const getSpecificMarkup = ({ id }) => client.get(`/api/markup/${id}`);

export const editMarkup = ({ id, ...rest }) =>
  client.put(`/api/markup/${id}`, { id, ...rest });

export const deleteMarkup = ({ id }) => client.delete(`/api/markup/${id}`);

export const getAllMarkups = () => client.get("/api/markup/");
