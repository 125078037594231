import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./documentsConstants";

export const GET_COMPANY_DOCUMENTS = requestActions(
  MODULE_NAME,
  "GET_COMPANY_DOCUMENTS"
);
export const GET_USER_DOCUMENTS = requestActions(
  MODULE_NAME,
  "GET_USER_DOCUMENTS"
);
export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const DELETE = requestActions(MODULE_NAME, "DELETE");
export const RESET = `${MODULE_NAME}/RESET`;

export const createDocument = (params) =>
  requestApiCall("createDocument", params, CREATE);

export const deleteDocument = (params) =>
  requestApiCall("deleteDocument", params, DELETE);

export const getCompanyDocuments = (params) =>
  requestApiCall("getCompanyDocuments", params, GET_COMPANY_DOCUMENTS);

export const getUserDocuments = (params) =>
  requestApiCall("getUserDocuments", params, GET_USER_DOCUMENTS);

export const clearEdit = () => ({ type: CREATE.CLEAR });

export const clearDelete = () => ({ type: DELETE.CLEAR });

export const clearCreate = () => ({ type: CREATE.CLEAR });

export const reset = () => ({ type: RESET });
