import React from "react";
import { getDateTime } from "../../../../../../_helpers/date";
import { paymentForLabels } from "../../../../../../../redux/state/user/userConstants";

export const PaymentBalanceLogRow = ({ data, getCompanyName, getUserName }) => {
  const {
    id,
    transactionId,
    paymentFor,
    amount,
    createdAt,
    type,
    companyId,
  } = data;
  const { time, date } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  const agencyName = getCompanyName(companyId);
  return (
    <tr>
      <td className="text-primary">{id || "-"}</td>
      <td>{transactionId || "-"}</td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </div>
      </td>
      <td>{agencyName || "-"}</td>
      <td>{paymentForLabels[paymentFor] || "-"}</td>

      <td>
        <span
          className={`font-weight-bold ${
            isNegative ? "text-danger" : "text-success"
          }`}
        >
          {isNegative ? "-" : "+"}
          {amount?.toFixed(2)}
        </span>
      </td>
    </tr>
  );
};
