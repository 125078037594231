import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./authConstants";

export const LOGIN = requestActions(MODULE_NAME, "LOGIN");
export const LOGIN_BY_CODE = requestActions(MODULE_NAME, "LOGIN_BY_CODE");
export const LOGOUT = requestActions(MODULE_NAME, "LOGOUT");
export const REQUEST_PASSWORD_RESET = requestActions(
  MODULE_NAME,
  "REQUEST_PASSWORD_RESET"
);
export const RESET_PASSWORD = requestActions(MODULE_NAME, "RESET_PASSWORD");
export const GET_LOCAL_STORAGE = "GET_LOCAL_STORAGE";
export const AUTHORIZE_LOGIN_BY_CODE = "AUTHORIZE_LOGIN_BY_CODE";
export const RESET = `${MODULE_NAME}/RESET`;

export const requestPasswordReset = ({ email }) =>
  requestApiCall("requestPasswordReset", { email }, REQUEST_PASSWORD_RESET);

export const restPassword = ({ password, key }) =>
  requestApiCall("restPassword", { password, key }, RESET_PASSWORD);

export const login = ({ email, password }) =>
  requestApiCall("login", { email, password }, LOGIN);

export const loginByCode = ({ code }) =>
  requestApiCall("loginByCode", { code }, LOGIN_BY_CODE);

// export const loginByCode = () => ({ type: LOGIN_BY_CODE.REQUESTED });

export const logout = (params) => requestApiCall("logout", params, LOGOUT);

export const clearLogin = () => ({ type: LOGIN.CLEAR });

export const clearLoginByCode = () => ({ type: LOGIN_BY_CODE.CLEAR });

export const getLocalStorage = () => ({ type: GET_LOCAL_STORAGE });

export const authorizeLoginByCode = () => ({ type: AUTHORIZE_LOGIN_BY_CODE });

export const reset = () => ({ type: RESET });
