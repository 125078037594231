import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "../../../common";
import { Typography } from "@material-ui/core";
import { actions as filesActions } from "../../../../../../redux/state/files";
import { actions as documentsActions } from "../../../../../../redux/state/documents";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ModalProgressBar } from "../../../../../_partials/controls";

export const DocumentForm = ({ show, onHide, isUser, companyORUserId }) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(({ user }) => user.userData);
  const {
    url,
    loading: uploadLoading,
    success: uploadSuccess,
    failure: uploadFailure,
  } = useSelector(({ files }) => files.upload);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ documents }) => documents.create);
  const loading = uploadLoading || createLoading;
  const fields = useFormik({
    initialValues: {
      documentFile: "",
      name: "",
    },
    validationSchema: Yup.object().shape({
      documentFile: Yup.mixed().required("Please select document"),
      name: Yup.string().required("Please enter document name"),
    }),
    onSubmit: () => {
      const formData = new FormData();
      formData.append("file", documentFile, documentFile.name);
      dispatch(filesActions.uploadFile({ userId, formData }));
    },
  });
  const {
    setFieldValue,
    touched,
    errors,
    values,
    handleSubmit,
    resetForm,
    getFieldProps,
  } = fields;
  const hide = useCallback(() => {
    resetForm();
    onHide();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (uploadSuccess) {
      dispatch(
        documentsActions.createDocument({
          isUser,
          companyORUserId,
          documentLink: url,
          documentName: values.name,
        })
      );
    }
  }, [companyORUserId, dispatch, isUser, uploadSuccess, url]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Document Added",
        })
      );
      dispatch(documentsActions.clearCreate());
      dispatch(filesActions.clearUpload());
      hide();
    }
  }, [createSuccess, dispatch, hide]);
  useEffect(() => {
    if (uploadFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong! (File error)",
        })
      );
      dispatch(filesActions.clearUpload());
    }
  }, [uploadFailure, dispatch]);
  useEffect(() => {
    if (createFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(documentsActions.clearCreate());
    }
  }, [dispatch, createFailure]);
  const { documentFile } = values;

  return (
    <Modal
      size="md"
      onHide={hide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Upload documentFile</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {loading && <ModalProgressBar />}
          <Input
            {...getFieldProps("name")}
            showErrors={touched["name"]}
            errorMessage={errors["name"]}
            placeholder="Document name"
            label="Document Name"
            variant="secondary"
          />

          <div className="my-1">
            <span className="min-w-150px mb-2">Upload files</span>
            <label className="btn btn-primary w-100 ">
              + Upload document
              <input
                onChange={(e) =>
                  setFieldValue("documentFile", e.target.files[0])
                }
                type="file"
                accept="image/* application/pdf"
                hidden
              />
            </label>
            <span className="text-muted">
              (JPG, PNG, PDF) Max size 2MB, 5 files.
            </span>
          </div>
          <div className="d-flex mb-6">
            {touched.documentFile && (
              <div className="fv-plugins-message-container h-20px max-w-100">
                <div className="fv-help-block">{errors.documentFile}</div>
              </div>
            )}
            {documentFile && (
              <div className="w-350px">
                <div className="d-flex w-100 mb-1 align-items-center">
                  {/* <SVG src="/media/svg/icons/General/valid.svg" /> */}
                  <Typography className="ml-3" variant="body1">
                    {documentFile?.name}
                  </Typography>
                  <button
                    type="button"
                    onClick={() => setFieldValue("documentFile", "")}
                    className="btn btn-icon ml-auto"
                  >
                    <SVG src="/media/svg/icons/General/cancel.svg" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={hide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
