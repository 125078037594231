import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationsAccessPage } from ".";
import { NotificationDetails, Notifications } from "../../../_metronic/layout/components/account";
import {
  ACCOUNT_NOTIFICATION,
  ACCOUNT_NOTIFICATION_ACCESS,
  NOTIFICATION_DETAILS,
} from "../../routesMap";
import { NotificationDetailsPage } from "./NotificationDetailsPage";

export const NotificationsPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={ACCOUNT_NOTIFICATION_ACCESS}
        component={NotificationsAccessPage}
      />
      <Route
        exact
        path={NOTIFICATION_DETAILS}
        component={NotificationDetails}
      />
      <Route exact path={ACCOUNT_NOTIFICATION} component={Notifications} />
    </Switch>
  );
};
