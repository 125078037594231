import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";
import useAgenciesOptions from "../../../../../../app/helpers/hooks/useAgenciesOptions";
import { toAbsoluteUrl } from "../../../../../_helpers";

import { Button, Input, Select } from "../../../common";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { DateRangeModal } from "../../reports/common/reportsSearchCard/DateRangeModal";
import {
  PAYMENT_TYPE_ISSUE,
  PAYMENT_TYPE_REFUND,
  PAYMENT_TYPE_REFUND_FEE,
  PAYMENT_TYPE_REISSUE,
  PAYMENT_TYPE_VOID,
  PAYMENT_TYPE_VOID_FEE,
  transactionStatusMap,
} from "../../../../../../redux/state/accountBalance/accountBalanceConstants";

export const getFilterObject = (columnName, value, option) => [
  ...(value
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

const StatusOptions = [
  { label: "Issued", value: PAYMENT_TYPE_ISSUE },
  { label: "Void", value: PAYMENT_TYPE_VOID },
  { label: "Refund", value: PAYMENT_TYPE_REFUND },
  { label: "ReIssued", value: PAYMENT_TYPE_REISSUE },
  { label: "Void Fee", value: PAYMENT_TYPE_VOID_FEE },
  { label: "Refund Fee", value: PAYMENT_TYPE_REFUND_FEE },
];

const typeOptions = [
  { label: "In", value: transactionStatusMap.in },
  { label: "Out", value: transactionStatusMap.out },
];

export const BalanceSummarySearchCard = ({
  onReset,
  setDateRange,
  onSearch,
}) => {
  const [showModal, setShowModal] = useState(null);
  const { agencyOptions } = useAgenciesOptions();
  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      bookingId: "",
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      status: "",
      type: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      bookingId: Yup.string(),
      agency: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      status: Yup.string(),
      type: Yup.string(),
    }),
    onSubmit: ({ fromDate, toDate, bookingId, agency, status, type }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject(
          "bookingTransactionId",
          bookingId,
          filterOptions.Contains
        ),
        ...getFilterObject("companyId", `${agency}`, filterOptions.IsEqualTo),
        ...getFilterObject("status", status, filterOptions.IsEqualTo),
        ...getFilterObject("type", type, filterOptions.IsEqualTo),
        ...getFilterObject(
          "CreatedAt",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "CreatedAt",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    values,
    touched,
    getFieldProps,
    errors,
    setValues,
  } = fields;
  const handleReset = () => {
    setValues({
      bookingId: "",
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
      status: "",
      type: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };
  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid justify="center" container spacing={2}>
        <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.bookingId}
            errorMessage={errors.bookingId}
            {...getFieldProps("bookingId")}
            placeholder="Booking ID"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.status}
            onChange={(v) => setFieldValue("status", v)}
            placeholder="All Status"
            options={[
              { value: "", label: "All Status" },
              ...(StatusOptions || []),
            ]}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.type}
            onChange={(v) => setFieldValue("type", v)}
            placeholder="Money flow"
            options={[
              { value: "", label: "All Flow Types" },
              ...(typeOptions || []),
            ]}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.agency}
            onChange={(v) => setFieldValue("agency", v)}
            options={[
              { value: "", label: "All Agencies" },
              ...(agencyOptions || []),
            ]}
            placeholder="Agency"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-center">
            <Button type="submit">Search</Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
