import { packagesClient } from "./client";

export const getAllPackages = () => packagesClient.get("/api/v1/packages");

export const searchPackages = (params) =>
  packagesClient.post("/api/v1/search-packages", { ...params });

export const createPackage = (params) =>
  packagesClient.post("/api/v1/create-package", { ...params });

export const getAllCities = () => packagesClient.get("/api/v1/city");

export const getCity = ({ id }) => packagesClient.post(`/api/v1/city/${id}`);

export const createCity = (params) =>
  packagesClient.post("/api/v1/city", {
    ...params,
  });

export const editCity = (params) =>
  packagesClient.put("/api/v1/city", {
    ...params,
  });

export const deleteCity = ({ id }) =>
  packagesClient.delete(`/api/v1/city/${id}`);

export const getAllInclusions = () => packagesClient.get("/api/v1/inclusion");

export const getInclusion = ({ id }) =>
  packagesClient.post(`/api/v1/inclusion/${id}`);

export const createInclusion = (params) =>
  packagesClient.post("/api/v1/inclusion", {
    ...params,
  });

export const editInclusion = (params) =>
  packagesClient.put("/api/v1/inclusion", {
    ...params,
  });

export const deleteInclusion = ({ id }) =>
  packagesClient.delete(`/api/v1/inclusion/${id}`);

export const getAllPkgClasses = () => packagesClient.get("/api/v1/pkgClass");

export const getPkgClass = ({ id }) =>
  packagesClient.post(`/api/v1/pkgClass/${id}`);

export const createPkgClass = (params) =>
  packagesClient.post("/api/v1/pkgClass", {
    ...params,
  });

export const editPkgClass = (params) =>
  packagesClient.put("/api/v1/pkgClass", {
    ...params,
  });

export const deletePkgClass = ({ id }) =>
  packagesClient.delete(`/api/v1/pkgClass/${id}`);

export const getAllPkgSeasons = () => packagesClient.get("/api/v1/pkgSeason");

export const getPkgSeason = ({ id }) =>
  packagesClient.post(`/api/v1/pkgSeason/${id}`);

export const createPkgSeason = (params) =>
  packagesClient.post("/api/v1/pkgSeason", {
    ...params,
  });

export const editPkgSeason = (params) =>
  packagesClient.put("/api/v1/pkgSeason", {
    ...params,
  });

export const deletePkgSeason = ({ id }) =>
  packagesClient.delete(`/api/v1/pkgSeason/${id}`);

export const getAllPkgTypes = () => packagesClient.get("/api/v1/pkgType");

export const getPkgType = ({ id }) =>
  packagesClient.post(`/api/v1/pkgType/${id}`);

export const createPkgType = (params) =>
  packagesClient.post("/api/v1/pkgType", {
    ...params,
  });

export const editPkgType = (params) =>
  packagesClient.put("/api/v1/pkgType", {
    ...params,
  });

export const deletePkgType = ({ id }) =>
  packagesClient.delete(`/api/v1/pkgType/${id}`);

export const getAllPkgRoomTypes = () =>
  packagesClient.get("/api/v1/pkgRoomType");

export const getPkgRoomType = ({ id }) =>
  packagesClient.post(`/api/v1/pkgRoomType/${id}`);

export const createPkgRoomType = (params) =>
  packagesClient.post("/api/v1/pkgRoomType", {
    ...params,
  });

export const editPkgRoomType = (params) =>
  packagesClient.put("/api/v1/pkgRoomType", {
    ...params,
  });

export const deletePkgRoomType = ({ id }) =>
  packagesClient.delete(`/api/v1/pkgRoomType/${id}`);
