import { Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const OrderDetailsShimmer = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={60}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={70}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={150}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={200}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={200}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={200}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
