import { Grid } from "@material-ui/core";
import React from "react";
import { ContentSectionAction } from "./ContentSectionAction";
import { formatImageUrl } from "../../../../../app/helpers/formatters/strapiImagesUrl";

// imageAlign: "left" | "right"

export const ContentSection = ({ data, imageSpan }) => {
  const { title, description, image, imagePosition, action } = data;
  const imageUrl = formatImageUrl(image?.data?.attributes?.url);
  const contentSpan = 12 - (imageSpan ?? 4);
  return (
    <>
      <div>
        <Grid container spacing={3}>
          {imagePosition === "left" ? (
            <Grid item sm={12} md={imageSpan ?? 4}>
              <img loading="lazy" src={imageUrl} className="img" alt="" />
            </Grid>
          ) : null}
          <Grid item sm={12} md={contentSpan}>
            <div className="div-2">
              <div className="div-3">{title}</div>
              <div className="div-4">{description}</div>
              <div className="mt-3">
                {!!action ? <ContentSectionAction actions={action} /> : null}
              </div>
            </div>
          </Grid>
          {imagePosition === "right" ? (
            <Grid item sm={12} md={imageSpan ?? 4}>
              <img loading="lazy" src={imageUrl} className="img" alt="" />
            </Grid>
          ) : null}
        </Grid>
      </div>
      <style jsx>{`
        @media (max-width: 991px) {
          .div {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 57%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column {
            width: 100%;
          }
        }
        .div-2 {
          align-items: start;
          display: flex;
          margin-top: 73px;
          flex-grow: 1;
          flex-direction: column;
          padding: 12px 20px 0;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-3 {
          color: #000;
          align-self: stretch;
          font: 700 59px Inter, sans-serif;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .div-4 {
          color: var(--gray-gray-600, #7e8299);
          text-align: justify;
          align-self: stretch;
          margin-top: 35px;
          font: 500 20px/30px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
          }
        }
        .div-5 {
          color: #3798d4;
          white-space: nowrap;
          border-radius: 6px;
          border: 2px solid #3798d4;
          margin-top: 60px;
          justify-content: center;
          padding: 16px 24px;
          font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            white-space: initial;
            margin-top: 40px;
            padding: 0 20px;
          }
        }
        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 43%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .img {
          aspect-ratio: 0.93;
          object-fit: contain;
          object-position: center;
          width: 100%;
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .img {
            max-width: 100%;
            margin-top: 40px;
          }
        }
      `}</style>{" "}
    </>
  );
};
