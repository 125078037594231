import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Button } from "../../../common";
import { inventoryTabsData } from "./InventoryForm";

export const  InventoryFormActions = ({ selectedTab, setSelectedTab }) => {
  const { loading } = useSelector(({ packages }) => packages.createPackage);
  // const onNext = () => {
  //   if (selectedTab < tabsData.length - 1) {
  //     setSelectedTab((prev) => prev + 1);
  //   } else {
  //     onFinalSubmit();
  //   }
  // };
  return (
    <Box className="w-100 d-flex justify-content-between align-items-center mt-4">
      {selectedTab > 0 && (
        <Button
          onClick={() => setSelectedTab((prev) => prev - 1)}
          variant="light-primary"
          className="mr-3"
          type="button"
        >
          Back
        </Button>
      )}
      <Button
        type="submit"
        // onClick={onNext}
        loading={loading}
        name="next"
        className='ml-auto'
      >
        {selectedTab < inventoryTabsData.length - 1 ? "Next" : "Submit"}
      </Button>
    </Box>
  );
};
