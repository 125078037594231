import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accounting";
import { isNil } from "lodash-es";
import { getDateTime } from "../../../../../_helpers/date";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { TICKET_DETAILS } from "../../../../../../app/routesMap";
import { LoadingSpinner, MenuComponent } from "../../../common";
import { Typography } from "@material-ui/core";

export const ExpensesTable = () => {
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  useEffect(() => {
    dispatch(actions.getExpenses());
  }, []);
  const { list, loading } = useSelector(
    ({ accounting }) => accounting.expenses
  );

  const entities = list?.map(({ id, amount, categoryId, createdAt, total }) => {
    const { date: createdDate } = getDateTime(createdAt);
    return {
      id,
      total,
      createdDate,
      categoryId,
      amount,
    };
  });
  const menuOptions = [
    { label: "View", onClick: () => {} },
    {
      label: "Edit",
      onClick: () => {},
    },
    { label: "Send", onClick: () => {} },
  ];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      className: "text-primary",
    },
    {
      dataField: "payee",
      text: "Payee",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
    },
    {
      dataField: "note",
      text: "Memo",
    },
    {
      dataField: "amount",
      text: "Total",
    },
    {
      // dataField: "action",
      text: "Actions",
      formatter: (cellContent) => {
        return <MenuComponent options={menuOptions} />;
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right",
    },
  ];
  // Table pagination properties
  // const paginationOptions = {
  //   custom: true,
  //   totalSize: entities?.length,
  //   // sizePerPageList: [10],
  //   sizePerPage: 10,
  //   page: 1,
  // };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination  isLoading={loading} paginationProps={paginationProps}> */}
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={isNil(entities) ? [] : entities}
        columns={columns}
        // selectRow={getSelectRow({
        //   entities: entities,
        //   ids: ids,
        //   setIds: setIds,
        // })}
        // rowEvents={rowEvents}
        rowStyle={{ cursor: "pointer" }}
        // defaultSorted={uiHelpers.defaultSorted}
        // onTableChange={getHandlerTableChange(
        //   customersUIProps.setQueryParams
        // )}
        // selectRow={getSelectRow({
        //   entities,
        //   ids: customersUIProps.ids,
        //   setIds: customersUIProps.setIds,
        // })}
        // {...paginationTableProps}
      />
      {loading && <LoadingSpinner />}
      {!loading && list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Expenses to show
        </Typography>
      )}
    </>
  );
};
