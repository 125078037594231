import { Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import useUserName from "../../../../../../app/helpers/hooks/useUserName";
import { getDateTime } from "../../../../../_helpers/date";

export const OrderDetailsBar = () => {
  const { getUserName } = useUserName();
  const { getCompanyName } = useCompanyName();
  const { data } = useSelector(({ bookingMng }) => bookingMng.specificOrder);
  const { insertedOn, bookingId, agencyId, orderNo, userId, isManual } =
    data || {};
  const agencyName = getCompanyName(agencyId);
  const { date: bookingDate } = getDateTime(insertedOn);
  const userName = getUserName(userId);
  return (
    <Paper className="d-flex justify-content-between flex-wrap p-7 bg-primary text-white">
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">Booking ID</span>
        <span>{bookingId}</span>
      </div>
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">Invoice No.</span>
        <span>{orderNo}</span>
      </div>
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">B2B Agents</span>
        <span>{agencyName}</span>
      </div>
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">Booking Date</span>
        <span>{bookingDate}</span>
      </div>
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">Sales Person</span>
        <span>{userName}</span>
      </div>
      <div className="d-flex flex-column m-2">
        <span className="font-weight-bold mb-3">Type</span>
        <span>{!isManual ? "Online" : "Offline"}</span>
      </div>
    </Paper>
  );
};
