import React from "react";
import { useSelector } from "react-redux";
import {
  calcTimeDiff,
  convertMinToHrs,
  formatTo12Hrs,
  toAbsoluteUrl,
} from "../../../_helpers";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import clsx from "clsx";
import { FlightDetailsCard } from "./DapartingFlights";
import { Button } from "../common";
import {
  BookingHold,
  BookingIssue,
  ManualBooking,
} from "../../../../redux/state/booking/bookingConstants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export function SearchResultCard({
  data,
  isReissue,
  reissueOrder,
  selectState,
  handleChange,
  continueBooking,
}) {
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const { searchRQ } = useSelector(
    ({ flightSearch }) => flightSearch.searchResults
  );
  const [expanded, setExpanded] = useState();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const handlePanelChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { loading: requestReissueLoading } = useSelector(
    ({ bookingMng }) => bookingMng.reissue
  );
  const {
    stop,
    segments,
    cabinClass,
    totalAmt,
    id,
    abf,
    cbf,
    ibf,
    atx,
    ctx,
    ytx,
    itx,
    gds,
  } = data;
  const { iSeg, totalDuration } = segments[0];
  const {
    dTime: firstDTime,
    dApt,
    aDat: firstADate,
    aTime: firstATime,
  } = iSeg[0];
  const { dTime: secondDTime, dDat: SecondDDate } = iSeg[
    iSeg.length >= 2 ? 1 : 0
  ];
  const { aApt, aTime: lastATime } = iSeg[iSeg.length - 1];
  const airline = iSeg[0].airlineNm;
  const duration = convertMinToHrs(parseInt(totalDuration));
  const { generalInfo, paxDetails } = searchRQ;
  const { adult, youth, child, infant } = paxDetails;
  const tripTypeText = {
    O: "One way",
    R: "Round",
    M: "Multi City",
  };
  const getAirlineInfo = (airlineCode) =>
    airlinesList?.filter(({ code }) => airlineCode === code)[0];
  const getAirportName = (airportCode) =>
    airportsList?.filter(({ code }) => airportCode === code)?.[0]?.name;
  const airlineInfo = getAirlineInfo(airline);
  const fees =
    +atx * adult +
    (+ytx || 0) * youth +
    (+ctx || 0) * child +
    (+itx || 0) * infant;
  return (
    <ExpansionPanel
      expanded={expanded === id}
      onChange={handlePanelChange(id)}
      elevation={0}
    >
      <ExpansionPanelSummary
        style={{
          display: "inline-block",
        }}
        className="min-w-100 p-0"
      >
        <div className="min-w-100">
          <div className="d-flex justify-content-between min-w-100">
            <div className="max-w-300px">
              <div className="d-flex justify-content-start">
                <div className="symbol text-center symbol-45px mr-5">
                  <img
                    src={airlineInfo?.logo}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = toAbsoluteUrl(
                        "/media/svg/icons/General/plane-default.svg"
                      );
                    }}
                    alt=""
                  />
                  <span className="text-muted font-size-sm">{`${gds[0]}${gds[1]}`}</span>
                </div>
                {/* {matchesMd && ( */}
                <div>
                  <div className="flex-row">
                    <span className="text-dark font-weight-bolder font-size-6 mr-2">
                      {formatTo12Hrs(firstDTime)}
                    </span>
                    <span className="mr-2">{"-"}</span>

                    <span className="text-dark font-weight-bolder font-size-6 ">
                      {formatTo12Hrs(lastATime)}
                    </span>
                  </div>
                  <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                    {airlineInfo?.name}
                  </span>
                </div>

                {/* )} */}
              </div>
            </div>
            {matchesMd && (
              <>
                <div className="max-w-100px">
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-dark font-weight-bolder font-size-6 mr-2">
                      {duration}
                    </span>
                    <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                      {dApt}-{aApt}
                    </span>
                  </div>
                </div>
                <div className="max-w-120px">
                  {+stop === 0 ? (
                    <span className="text-success font-weight-bolder font-size-6">
                      Direct
                    </span>
                  ) : (
                    <div className="d-flex justify-content-start flex-column">
                      <span className="text-dark font-weight-bolder font-size-6 mr-2">
                        {stop} Stops
                      </span>
                      {matchesMd && (
                        <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                          {calcTimeDiff(
                            firstADate,
                            SecondDDate,
                            firstATime,
                            secondDTime
                          )}{" "}
                          {getAirportName(iSeg[0].aApt) || iSeg[0].aApt}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="w-75px d-flex align-items-start flex-column">
              <div className="d-flex justify-content-start flex-column">
                {!isReissue && (
                  <div className="flex-row">
                    <span className="text-success font-weight-bolder font-size-6 mr-2">
                      {totalAmt}$
                    </span>
                  </div>
                )}
                <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                  {tripTypeText[generalInfo.tripType]}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center min-w-100">
            {+stop === 0 ? (
              <span className="text-success font-weight-bolder font-size-6">
                Direct
              </span>
            ) : (
              <div className="d-flex justify-content-start flex-column ">
                <span className="text-muted font-weight-bolder font-size-6 mr-2">
                  {stop} Stops
                </span>
              </div>
            )}
            <span
              style={{
                transform: expanded === id ? "rotate(180deg)" : "none",
              }}
            >
              <ExpandMoreIcon />
            </span>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="p-0">
        <div
          className={clsx("d-flex justify-content-between w-100", {
            "flex-column": isReissue || !matchesMd,
          })}
        >
          <div
            className={clsx({
              "w-100": isReissue,
            })}
          >
            <FlightDetailsCard
              segments={segments}
              cabinClass={cabinClass}
              airlinesList={airlinesList}
              airportsList={airportsList}
              gds={gds}
            />
          </div>
          {isReissue && (
            <div className="d-flex mt-5">
              <Button variant="primary-inverse" className="mr-5">
                Flight Rules
              </Button>
              <Button
                loading={requestReissueLoading}
                onClick={() => reissueOrder(id)}
              >
                Continue
              </Button>
            </div>
          )}
          {!isReissue && (
            <div className="card card-custom shadow-sm min-w-300px p-5">
              <div className="d-flex justify-content-between  card-header p-0 mb-5 pb-3">
                <span className="font-weight-bolder">TOTAL PAY:</span>
                <span className="font-weight-bolder text-success">
                  {totalAmt}$
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Price Adult (X{adult})</span>
                <span>{(+abf || 0) * +adult}$</span>
              </div>
              {/* <div className="d-flex justify-content-between mb-2">
           <span>Price Youth (X{youth || 0})</span>
           <span>{(+ybf || 0) * +youth}$</span>
          </div> */}
              <div className="d-flex justify-content-between mb-2">
                <span>Price Child (X{child || 0})</span>
                <span>{(+cbf || 0) * +child}$</span>
              </div>
              <div className="d-flex justify-content-between mb-8">
                <span>Price Infant (X{infant || 0})</span>
                <span>{(+ibf || 0) * infant}$</span>
              </div>
              <div className="d-flex justify-content-between mb-5">
                <span>Fee & Taxes</span>
                <span>{fees.toFixed(2)}$</span>
              </div>
              <form>
                <div className="form-check form-check-custom form-check-solid mb-3">
                  <label className="form-check-label">
                    <input
                      onChange={handleChange}
                      className="form-check-input"
                      type="radio"
                      value={BookingHold}
                      name="bookingStatus"
                      checked={selectState.bookingStatus === BookingHold}
                    />
                    Book and hold
                  </label>
                </div>
                {isAdmin && (
                  <div className="form-check form-check-custom form-check-solid mb-7">
                    <label className="form-check-label">
                      <input
                        name="bookingStatus"
                        onChange={handleChange}
                        className="form-check-input"
                        type="radio"
                        value={ManualBooking}
                        checked={selectState.bookingStatus === ManualBooking}
                      />
                      Manual Booking
                    </label>
                  </div>
                )}
                {!isAdmin && (
                  <div className="form-check form-check-custom form-check-solid mb-7">
                    <label className="form-check-label">
                      <input
                        name="bookingStatus"
                        onChange={handleChange}
                        className="form-check-input"
                        type="radio"
                        value={BookingIssue}
                        checked={selectState.bookingStatus === BookingIssue}
                      />
                      Book and issue
                    </label>
                  </div>
                )}
              </form>
              <button
                onClick={() => continueBooking(id, totalAmt)}
                className="btn btn-primary min-w-auto"
              >
                continue booking
              </button>
            </div>
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
