import { Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getDateTime } from "../../../../_helpers";
import { LoadingSpinner } from "../../common";
import { ResponsiveRow } from "../../common/ResponsiveRow/ResponsiveRow";

export const SubscriptionHistoryTable = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { list, loading } = useSelector(
    ({ user }) => user.subscriptionPaymentsByCompany
  );

  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  return (
    <Paper className="p-7">
      <div className="table-responsive border">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <table className="table table-vertical-center mb-0 mt-0">
            <thead>
              <tr className="bg-gray-200">
                <th>ID</th>
                <th>Payment Date</th>
                <th>Payment Method</th>
                <th>Paid Amount</th>
                <th>Discount</th>
              </tr>
            </thead>
            <tbody>{tableContent}</tbody>
          </table>
        )}
        {list?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No Records to show
          </Typography>
        )}
      </div>
    </Paper>
  );
};

const TableRow = ({ data, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { id, paymentDate, amount, discountAmount, paymentMethod } = data;
  const { date } = getDateTime(paymentDate);
  const rows = (
    <>
      {/* {matchesSm && ( */}
      <td>
        <span>{id || "-"}</span>
      </td>
      {/* )} */}
      {/* {matchesSm && (
      <> */}
      <td>
        <span>{date || "-"}</span>
      </td>
      <td>
        <span>{paymentMethod ?? "-"}</span>
      </td>
      <td>
        <span>${amount?.toFixed(2) ?? "-"}</span>
      </td>
      {/* </>
      )} */}
      <td>
        <span>${discountAmount?.toFixed(2) || "-"}</span>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "ID",
      value: id,
    },
    {
      label: "Payment Method",
      value: paymentMethod,
    },
    {
      label: "Payment Date",
      value: date,
    },
    {
      label: "Amount",
      value: amount?.toFixed(2),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
