import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

import { LoadingSpinner } from "../../../common";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const OperationDashboardTable = ({
  list,
  loading,
  goToAgencyDetails,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

  const tableContent = list?.map((item, index) => (
    <TableRow
      key={`row_${index}`}
      data={item}
      goToAgencyDetails={goToAgencyDetails}
    />
  ));
  return (
    <div className="table-responsive">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-100">
              <th>Client Name</th>
              <th className="text-center">Route</th>
              <th className="text-center">Booked</th>
              {matchesSm && <th className="text-center">Look to Book ratio</th>}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, goToAgencyDetails }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { agencyId, agencyName, totalBooked, totalSearched } = data;
  const rows = (
    <>
      <td>
        <button
          className="btn font-weight-bold text-dark text-hover-primary pl-0"
          onClick={() => goToAgencyDetails(agencyId)}
        >
          <span className="font-weight-bold">{agencyName}</span>
        </button>
      </td>
      <td className="text-center font-weight-bold text-center ">
        <span>{totalSearched}</span>
      </td>
      <td className="text-center font-weight-bold text-center ">
        <span>{totalBooked}</span>
      </td>
      {matchesSm && (
        <td className="text-center font-weight-bold text-center ">
          <span>
            {totalSearched}:{totalBooked}
          </span>
        </td>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Route",
      value: `${totalSearched}:${totalBooked}`,
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
