import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { actions } from "../../../../../../../redux/state/bookingMng";
import { actions as companyActions } from "../../../../../../../redux/state/company";
import { actions as inlineNotificationActions } from "../../../../../../../redux/state/inlineNotification";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { Button, Input, Select } from "../../../../common";
import { BookingHold } from "../../../../../../../redux/state/booking/bookingConstants";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { creditAgreementLabelMap } from "../../../../../../../redux/state/company/companyConstants";

const discountSchema = Yup.object().shape({
  discount: Yup.number()
    .positive("Discount should be a positive number")
    .required("Discount is required"),
  note: Yup.string(),
});

export const AccountsSection = () => {
  const dispatch = useDispatch();
  // const { markup, amount: supplierAmount, total, discount } =
  //   useSelector(({ invoices }) => invoices.specificInvoice.data) || {};
  const { success, failure, loading } = useSelector(
    ({ bookingMng }) => bookingMng.discount
  );
  const { userId } = useSelector((state) => state.auth);
  const { creditContractType } =
    useSelector(({ company }) => company.specificCompanyData.data) || {};
  const {
    id,
    bookingStatus,
    bookingId,
    orderNo,
    totalPrice,
    discount,
    price,
    flymeMarkup,
    agencyId,
  } = useSelector(({ bookingMng }) => bookingMng.specificOrder.data) || {};
  const fields = useFormik({
    initialValues: { discount: "", note: "" },
    validationSchema: discountSchema,
    onSubmit: ({ discount, note }) => {
      dispatch(actions.addDiscount({ id, discount, note, userId }));
    },
  });
  const {
    setFieldValue,
    getFieldProps,
    errors,
    touched,
    values,
    handleSubmit,
  } = fields;
  useEffect(() => {
    if (agencyId) {
      dispatch(companyActions.getSpecificCompany({ id: agencyId }));
    }
    return () => dispatch(companyActions.clearSpecificCompany());
  }, [agencyId, dispatch]);

  useEffect(() => {
    if (discount !== undefined && discount) {
      setFieldValue("discount", discount);
    }
  }, [discount, setFieldValue]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: "Discount Added",
          type: NOTIFICATION_TYPE_SUCCESS,
        })
      );
      dispatch(actions.clearDiscount());
      dispatch(actions.getSpecificOrderByBookingId({ bookingId }));
      // dispatch(invoicesActions.getInvoiceByInvoiceNo({ invoiceNo: orderNo }));
    }
  }, [bookingId, dispatch, orderNo, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: failure,
          type: NOTIFICATION_TYPE_ERROR,
        })
      );
      dispatch(actions.clearDiscount());
    }
  }, [dispatch, failure]);
  return (
    <CardWithBlueHeader title="Accounts - USD">
      <div className="p-7">
        <Input
          disabled
          spacing
          variant="secondary"
          value={totalPrice}
          horizontal
          label="Selling Price"
          fixedLabelWidth
        />
        {/* <div className="d-flex">
          <span className="min-w-150px mt-2">Selling price</span>
          <Select
            disabled
            spacing
            variant="secondary"
            className="ml-3 w-50"
            options={currencyOptions}
            name="IsPaxWiseMinAmount"
            value={currency}
            horizontal
          />
        </div> */}
        <Input
          disabled
          spacing
          variant="secondary"
          value={price}
          label="Flight Net"
          horizontal
          fixedLabelWidth
        />
        <Select
          disabled
          spacing
          variant="secondary"
          options={[{ label: "Flight Only", value: "flight" }]}
          value="flight"
          label="ATOL Category"
          horizontal
          fixedLabelWidth
        />
        <Input
          disabled
          spacing
          variant="secondary"
          value={flymeMarkup}
          label="Commission"
          horizontal
          fixedLabelWidth
        />
        {/* <Input
          disabled
          spacing
          variant="secondary"
          value="20"
          label="Vat Payable"
          horizontal
          fixedLabelWidth
        /> */}
        <Input
          disabled
          spacing
          variant="secondary"
          value={creditAgreementLabelMap[creditContractType]}
          label="Credit Agreement"
          horizontal
          fixedLabelWidth
        />
        <Input
          disabled={bookingStatus !== BookingHold || discount > 0}
          spacing
          variant="secondary"
          {...getFieldProps("discount")}
          showErrors={touched.discount}
          errorMessage={errors.discount}
          label="Discount"
          horizontal
          fixedLabelWidth
        />
        <Input
          disabled={bookingStatus !== BookingHold || discount > 0}
          spacing
          variant="secondary"
          {...getFieldProps("note")}
          showErrors={touched.note}
          errorMessage={errors.note}
          label="Remarks"
          horizontal
          fixedLabelWidth
        />
        <div className="d-flex mt-5">
          <Button
            onClick={handleSubmit}
            disabled={discount === values.discount}
            className="ml-auto"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </CardWithBlueHeader>
  );
};
