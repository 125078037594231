import React from "react";
import SVG from "react-inlinesvg";
import { NavLink, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { getDateTime } from "../../../../../_helpers/date";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import {
  ADMIN_STAFF_ROLE_DETAILS,
  ADMIN_STAFF_ROLE_PERMISSIONS,
} from "../../../../../../app/routesMapAdmin";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

export const RoleRow = ({ subPermissions, data, onDelete, onEdit }) => {
  const history = useHistory();
  const { id, createdAt, name } = data;
  const { time, date } = getDateTime(createdAt);
  const allRoleSubPermission =
    adminPermissionsIds.systemManagement.flymeStaffRoles.subPermissions;
  return (
    <tr>
      <td className="text-left">
        <NavLink
          to={parseRoute(ADMIN_STAFF_ROLE_DETAILS, { id })}
          className="btn text-hover-primary"
        >
          {name || "-"}
        </NavLink>
      </td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </div>
      </td>

      <td className="text-right">
        <button
          hidden={!subPermissions.includes(allRoleSubPermission.editRole.id)}
          title="Update Permissions"
          className="btn btn-icon btn-light btn-hover-warning btn-sm"
          onClick={() =>
            history.push(parseRoute(ADMIN_STAFF_ROLE_PERMISSIONS, { id }))
          }
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG
              title="Update Permissions"
              src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
            />
          </span>
        </button>
        <button
          hidden={!subPermissions.includes(allRoleSubPermission.editRole.id)}
          title="Edit Role Info"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              title="Edit Role Info"
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button
          hidden={!subPermissions.includes(allRoleSubPermission.deleteRole.id)}
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG
              title="Delete"
              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
            />
          </span>
        </button>
      </td>
    </tr>
  );
};
