/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { InfoBar } from "../common/InfoBar";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_partials/dropdowns";
import { toAbsoluteUrl } from "../../../../_helpers";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

export const SubUser = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid container spacing={3}>
      {!matchesSm && (
        <Grid item container xs={12}>
          <InfoBar />
        </Grid>
      )}
      <Grid container item xs={12} sm={7}>
        <Grid xs={12} className="mb-6">
          <Paper className="bg-white h-100">
            <OverviewList />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper className="bg-white h-100">
            <OperationLog />
          </Paper>
        </Grid>
      </Grid>
      {matchesSm && (
        <Grid item container sm={5}>
          <InfoBar />
        </Grid>
      )}
    </Grid>
  );
};

export const OperationLog = () => {
  const rows = Array(4)
    .fill(0)
    .map((_, index) => (
      <div key={`log${index}`} className="d-flex w-100 mb-3">
        <span className="svg-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/play.svg")} />
        </span>
        <Typography
          variant="body1"
          className="mx-5"
          style={{ color: "#464E5F" }}
        >
          2020-03-13 23:50:55
        </Typography>
        <Typography variant="body1">
          Lorem ipsum sit amet, consectetur adipiscing elit.
        </Typography>
      </div>
    ));
  return (
    <div>
      <span className="d-flex px-7 align-items-center bg-gray-100 py-3">
        <span className="svg-icon mr-3">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/papers.svg")} />
        </span>
        <Typography variant="h6" className="font-weight-bolder">
          Operation Log
        </Typography>
      </span>
      <div className="px-7 py-4">{rows}</div>
    </div>
  );
};

export const OverviewList = ({ className }) => {
  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          User Overview
        </h3>

        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" alignRight>
            <Dropdown.Toggle
              id="dropdown-toggle-top"
              as={DropdownCustomToggler}
            >
              <i className="ki ki-bold-more-ver" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ul className="navi">
                <li className="navi-item">
                  <a href="#" className="navi-link">
                    <span className="navi-text">option1</span>
                  </a>
                </li>
                <li className="navi-item">
                  <a href="#" className="navi-link">
                    <span className="navi-text">option2</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Body */}
      <div className="card-body pt-0">
        <div className="mb-6">
          <div className="d-flex align-items-center flex-grow-1">
            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
            </label>

            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                <a
                  href="#"
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                >
                  Another purpose would be to persuade people
                </a>

                <span className="text-muted font-weight-bold">
                  Due in 2 Days
                </span>
              </div>

              <span className="label label-lg label-light-info label-inline font-weight-bold py-4">
                Approved
              </span>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <div className="d-flex align-items-center flex-grow-1">
            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
            </label>

            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                <a
                  href="#"
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                >
                  Group Town Hall Meet-up with showcase
                </a>

                <span className="text-muted font-weight-bold">
                  Due in 2 Days
                </span>
              </div>

              <span className="label label-lg label-light-warning label-inline font-weight-bold py-4">
                In Progress
              </span>
            </div>
          </div>
        </div>
        {/* end::Item */}

        {/* begin::Item */}
        <div className="mb-6">
          {/* begin::Content */}
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin::Checkbox */}
            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
            </label>
            {/* end::Checkbox */}

            {/* begin::Section */}
            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
              {/* begin::Info */}
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                {/* begin::Title */}
                <a
                  href="#"
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                >
                  Next sprint planning and estimations
                </a>
                {/* end::Title */}

                {/* begin::Data */}
                <span className="text-muted font-weight-bold">
                  Due in 2 Days
                </span>
                {/* end::Data */}
              </div>
              {/* end::Info */}

              {/* begin::Label */}
              <span className="label label-lg label-light-success label-inline font-weight-bold py-4">
                Success
              </span>
              {/* end::Label */}
            </div>
            {/* end::Section */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Item */}

        {/* begin::Item */}
        <div className="mb-6">
          {/* begin::Content */}
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin::Checkbox */}
            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
            </label>
            {/* end::Checkbox */}

            {/* begin::Section */}
            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
              {/* begin::Info */}
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                {/* begin::Title */}
                <a
                  href="#"
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                >
                  Sprint delivery and project deployment
                </a>
                {/* end::Title */}

                {/* begin::Data */}
                <span className="text-muted font-weight-bold">
                  Due in 2 Days
                </span>
                {/* end::Data */}
              </div>
              {/* end::Info */}

              {/* begin::Label */}
              <span className="label label-lg label-light-danger label-inline font-weight-bold py-4">
                Rejected
              </span>
              {/* end::Label */}
            </div>
            {/* end::Section */}
          </div>
          {/* end::Content */}
        </div>
        {/* end: Item */}

        {/* begin: Item */}
        <div className="">
          {/* begin::Content */}
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin::Checkbox */}
            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
              <input type="checkbox" value="1" />
              <span></span>
            </label>
            {/* end::Checkbox */}

            {/* begin::Section */}
            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
              {/* begin::Info */}
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                {/* begin::Title */}
                <a
                  href="#"
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                >
                  Data analytics research showcase
                </a>
                {/* end::Title */}

                {/* begin::Data */}
                <span className="text-muted font-weight-bold">
                  Due in 2 Days
                </span>
                {/* end::Data */}
              </div>
              {/* end::Info */}

              {/* begin::Label */}
              <span className="label label-lg label-light-warning label-inline font-weight-bold py-4">
                In Progress
              </span>
              {/* end::Label */}
            </div>
            {/* end::Section */}
          </div>
          {/* end::Content */}
        </div>
        {/* end: Item */}
      </div>
    </div>
  );
};
