import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { SupplierBalanceHistory } from "../../../_metronic/layout/components/admin/systemManagement/supplier/supplierBalanceHistory/SupplierBalanceHistory";
import { SupplierForm } from "../../../_metronic/layout/components/admin/systemManagement/supplier/SupplierForm";
import { SupplierList } from "../../../_metronic/layout/components/admin/systemManagement/supplier/SupplierList";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  ADMIN_CREATE_SUPPLIER,
  ADMIN_EDIT_SUPPLIER,
  ADMIN_SUPPLIERS,
  ADMIN_SUPPLIER_BALANCE_HISTORY,
} from "../../routesMapAdmin";

export const SuppliersPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.addSupplier",
        ])}
        exact
        path={ADMIN_CREATE_SUPPLIER}
        component={SupplierForm}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.supplierHistory",
        ])}
        exact
        path={ADMIN_SUPPLIER_BALANCE_HISTORY}
        component={SupplierBalanceHistory}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.editSupplier",
        ])}
        exact
        path={ADMIN_EDIT_SUPPLIER}
        render={() => <SupplierForm isEdit={true} />}
      />
      <Route
        exact
        path={ADMIN_SUPPLIERS}
        render={(props) => (
          <SupplierList {...props} permissionId={permissionId} />
        )}
      />
    </Switch>
  );
};
