import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./packageActions";

const initialState = {
  allCities: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  city: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createCity: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editCity: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deleteCity: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allInclusions: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  inclusion: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createInclusion: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editInclusion: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deleteInclusion: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPkgClasses: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  pkgClass: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPkgClass: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPkgClass: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePkgClass: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPkgSeasons: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  pkgSeason: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPkgSeason: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPkgSeason: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePkgSeason: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPkgRoomTypes: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  pkgRoomType: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPkgRoomType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPkgRoomType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePkgRoomType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPkgTypes: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  pkgType: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPkgType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPkgType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePkgType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPackages: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPackage: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  searchPackages: {
    searchDetails: undefined,
    searchDetails: {
      fromCity: 1,
      packageSeasonId: 2,
      packageTypeId: 2,
      packageClassId: 2,
      numOfPassengers: 1,
    },
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  selectedPackage: undefined,
  // selectedPackage: {
  //   guid: "891258eb-a08f-434f-8139-8b2351b6f547",
  //   package: {
  //     id: 6,
  //     packageName: "5 star package",
  //     packageNumber: null,
  //     duration: "30",
  //     departureDate: "2005-05-05T05:05:00",
  //     returnDate: "2004-05-05T05:05:00",
  //     destination: "1",
  //     qty: 50,
  //     availableQty: 50,
  //     thumbnail: "",
  //     status: "Active",
  //     fromCity: 1,
  //     city: null,
  //     packageSeasonId: 2,
  //     packageSeason: null,
  //     packageTypeId: 2,
  //     packageType: null,
  //     createdBy: 18,
  //     updatedBy: 18,
  //     createdDate: "0001-01-01T00:00:00",
  //     updatedDate: "0001-01-01T00:00:00",
  //     packageDescription: {
  //       id: 4,
  //       packageId: 6,
  //       description: "<p>31 person package</p>",
  //     },
  //     packageInclusions: [
  //       {
  //         id: 7,
  //         packageId: 6,
  //         inclusionId: 2,
  //         inclusion: null,
  //       },
  //       {
  //         id: 8,
  //         packageId: 6,
  //         inclusionId: 3,
  //         inclusion: null,
  //       },
  //     ],
  //     packageRooms: [
  //       {
  //         id: 7,
  //         price: 33,
  //         packageId: 6,
  //         roomTypeId: 3,
  //         packageRoomType: null,
  //       },
  //       {
  //         id: 8,
  //         price: 53,
  //         packageId: 6,
  //         roomTypeId: 3,
  //         packageRoomType: null,
  //       },
  //     ],
  //     packagePrices: [
  //       {
  //         id: 6,
  //         price: 1000,
  //         packageId: 6,
  //         packageClassId: 2,
  //         packageClass: null,
  //       },
  //     ],
  //     total: 1000,
  //     individualPrice: 1000,
  //     packageClassId: 2,
  //   },
  // },
};

export const packageReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.SET_SELECTED_PACKAGE:
        state.selectedPackage = payload;
        break;
      case actions.SEARCH_PACKAGES.REQUESTED:
        state.searchPackages.loading = true;
        break;
      case actions.SEARCH_PACKAGES.SUCCEEDED:
        state.searchPackages.loading = false;
        state.searchPackages.success = true;
        state.searchPackages.list = payload.data.data;
        state.searchPackages.searchDetails = payload.args;
        break;
      case actions.SEARCH_PACKAGES.FAILED:
        state.searchPackages.loading = false;
        state.searchPackages.failure = payload.error;
        break;
      case actions.SEARCH_PACKAGES.CLEAR:
        state.searchPackages = initialState.searchPackages;
        break;
      case actions.GET_ALL_PACKAGES.REQUESTED:
        state.allPackages.loading = true;
        break;
      case actions.GET_ALL_PACKAGES.SUCCEEDED:
        state.allPackages.loading = false;
        state.allPackages.success = true;
        state.allPackages.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PACKAGES.FAILED:
        state.allPackages.loading = false;
        state.allPackages.failure = payload.error;
        break;
      case actions.CREATE_PACKAGE.REQUESTED:
        state.createPackage.loading = true;
        break;
      case actions.CREATE_PACKAGE.SUCCEEDED:
        state.createPackage.loading = false;
        state.createPackage.success = true;
        break;
      case actions.CREATE_PACKAGE.FAILED:
        state.createPackage.loading = false;
        state.createPackage.failure = payload.error;
        break;
      case actions.CREATE_PACKAGE.CLEAR:
        state.createPackage = initialState.createPackage;
        break;
      case actions.GET_CITY.REQUESTED:
        state.city.loading = true;
        break;
      case actions.GET_CITY.SUCCEEDED:
        state.city.loading = false;
        state.city.success = true;
        state.city.data = payload.data;
        break;
      case actions.GET_CITY.FAILED:
        state.city.loading = false;
        state.city.failure = payload.error;
        break;
      case actions.GET_ALL_CITIES.REQUESTED:
        state.allCities.loading = true;
        break;
      case actions.GET_ALL_CITIES.SUCCEEDED:
        state.allCities.loading = false;
        state.allCities.success = true;
        state.allCities.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_CITIES.FAILED:
        state.allCities.loading = false;
        state.allCities.failure = payload.error;
        break;
      case actions.CREATE_CITY.REQUESTED:
        state.createCity.loading = true;
        break;
      case actions.CREATE_CITY.SUCCEEDED:
        state.createCity.loading = false;
        state.createCity.success = true;
        state.allCities.list = [payload.data, ...state.allCities.list];
        break;
      case actions.CREATE_CITY.FAILED:
        state.createCity.loading = false;
        state.createCity.failure = payload.error;
        break;
      case actions.CREATE_CITY.CLEAR:
        state.createCity = initialState.createCity;
        break;
      case actions.EDIT_CITY.REQUESTED:
        state.editCity.loading = true;
        break;
      case actions.EDIT_CITY.SUCCEEDED:
        state.editCity.loading = false;
        state.editCity.success = true;
        break;
      case actions.EDIT_CITY.FAILED:
        state.editCity.loading = false;
        state.editCity.failure = payload.error;
        break;
      case actions.EDIT_CITY.CLEAR:
        state.editCity = initialState.editCity;
        break;
      case actions.DELETE_CITY.REQUESTED:
        state.deleteCity.loading = true;
        break;
      case actions.DELETE_CITY.SUCCEEDED:
        state.deleteCity.loading = false;
        state.deleteCity.success = true;
        state.allCities.list = state.allCities.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_CITY.FAILED:
        state.deleteCity.loading = false;
        state.deleteCity.failure = payload.error;
        break;
      case actions.DELETE_CITY.CLEAR:
        state.deleteCity = initialState.deleteCity;
        break;
      case actions.GET_INCLUSION.REQUESTED:
        state.inclusion.loading = true;
        break;
      case actions.GET_INCLUSION.SUCCEEDED:
        state.inclusion.loading = false;
        state.inclusion.success = true;
        state.inclusion.data = payload.data.data;
        break;
      case actions.GET_INCLUSION.FAILED:
        state.inclusion.loading = false;
        state.inclusion.failure = payload.error;
        break;
      case actions.GET_ALL_INCLUSIONS.REQUESTED:
        state.allInclusions.loading = true;
        break;
      case actions.GET_ALL_INCLUSIONS.SUCCEEDED:
        state.allInclusions.loading = false;
        state.allInclusions.success = true;
        state.allInclusions.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_INCLUSIONS.FAILED:
        state.allInclusions.loading = false;
        state.allInclusions.failure = payload.error;
        break;
      case actions.CREATE_INCLUSION.REQUESTED:
        state.createInclusion.loading = true;
        break;
      case actions.CREATE_INCLUSION.SUCCEEDED:
        state.createInclusion.loading = false;
        state.createInclusion.success = true;
        state.allInclusions.list = [payload.data, ...state.allInclusions.list];
        break;
      case actions.CREATE_INCLUSION.FAILED:
        state.createInclusion.loading = false;
        state.createInclusion.failure = payload.error;
        break;
      case actions.CREATE_INCLUSION.CLEAR:
        state.createInclusion = initialState.createInclusion;
        break;
      case actions.EDIT_INCLUSION.REQUESTED:
        state.editInclusion.loading = true;
        break;
      case actions.EDIT_INCLUSION.SUCCEEDED:
        state.editInclusion.loading = false;
        state.editInclusion.success = true;
        break;
      case actions.EDIT_INCLUSION.FAILED:
        state.editInclusion.loading = false;
        state.editInclusion.failure = payload.error;
        break;
      case actions.EDIT_INCLUSION.CLEAR:
        state.editInclusion = initialState.editInclusion;
        break;
      case actions.DELETE_INCLUSION.REQUESTED:
        state.deleteInclusion.loading = true;
        break;
      case actions.DELETE_INCLUSION.SUCCEEDED:
        state.deleteInclusion.loading = false;
        state.deleteInclusion.success = true;
        state.allInclusions.list = state.allInclusions.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_INCLUSION.FAILED:
        state.deleteInclusion.loading = false;
        state.deleteInclusion.failure = payload.error;
        break;
      case actions.DELETE_INCLUSION.CLEAR:
        state.deleteInclusion = initialState.deleteInclusion;
        break;
      case actions.GET_PKG_ROOM_TYPE.REQUESTED:
        state.pkgRoomType.loading = true;
        break;
      case actions.GET_PKG_ROOM_TYPE.SUCCEEDED:
        state.pkgRoomType.loading = false;
        state.pkgRoomType.success = true;
        state.pkgRoomType.data = payload.data;
        break;
      case actions.GET_PKG_ROOM_TYPE.FAILED:
        state.pkgRoomType.loading = false;
        state.pkgRoomType.failure = payload.error;
        break;
      case actions.GET_ALL_PKG_ROOM_TYPES.REQUESTED:
        state.allPkgRoomTypes.loading = true;
        break;
      case actions.GET_ALL_PKG_ROOM_TYPES.SUCCEEDED:
        state.allPkgRoomTypes.loading = false;
        state.allPkgRoomTypes.success = true;
        state.allPkgRoomTypes.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PKG_ROOM_TYPES.FAILED:
        state.allPkgRoomTypes.loading = false;
        state.allPkgRoomTypes.failure = payload.error;
        break;
      case actions.CREATE_PKG_ROOM_TYPE.REQUESTED:
        state.createPkgRoomType.loading = true;
        break;
      case actions.CREATE_PKG_ROOM_TYPE.SUCCEEDED:
        state.createPkgRoomType.loading = false;
        state.createPkgRoomType.success = true;
        state.allPkgRoomTypes.list = [
          payload.data,
          ...state.allPkgRoomTypes.list,
        ];
        break;
      case actions.CREATE_PKG_ROOM_TYPE.FAILED:
        state.createPkgRoomType.loading = false;
        state.createPkgRoomType.failure = payload.error;
        break;
      case actions.CREATE_PKG_ROOM_TYPE.CLEAR:
        state.createPkgRoomType = initialState.createPkgRoomType;
        break;
      case actions.EDIT_PKG_ROOM_TYPE.REQUESTED:
        state.editPkgRoomType.loading = true;
        break;
      case actions.EDIT_PKG_ROOM_TYPE.SUCCEEDED:
        state.editPkgRoomType.loading = false;
        state.editPkgRoomType.success = true;
        break;
      case actions.EDIT_PKG_ROOM_TYPE.FAILED:
        state.editPkgRoomType.loading = false;
        state.editPkgRoomType.failure = payload.error;
        break;
      case actions.EDIT_PKG_ROOM_TYPE.CLEAR:
        state.editPkgRoomType = initialState.editPkgRoomType;
        break;
      case actions.DELETE_PKG_ROOM_TYPE.REQUESTED:
        state.deletePkgRoomType.loading = true;
        break;
      case actions.DELETE_PKG_ROOM_TYPE.SUCCEEDED:
        state.deletePkgRoomType.loading = false;
        state.deletePkgRoomType.success = true;
        state.allPkgRoomTypes.list = state.allPkgRoomTypes.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_PKG_ROOM_TYPE.FAILED:
        state.deletePkgRoomType.loading = false;
        state.deletePkgRoomType.failure = payload.error;
        break;
      case actions.DELETE_PKG_ROOM_TYPE.CLEAR:
        state.deletePkgRoomType = initialState.deletePkgRoomType;
        break;
      case actions.GET_PKG_TYPE.REQUESTED:
        state.pkgType.loading = true;
        break;
      case actions.GET_PKG_TYPE.SUCCEEDED:
        state.pkgType.loading = false;
        state.pkgType.success = true;
        state.pkgType.data = payload.data;
        break;
      case actions.GET_PKG_TYPE.FAILED:
        state.pkgType.loading = false;
        state.pkgType.failure = payload.error;
        break;
      case actions.GET_ALL_PKG_TYPES.REQUESTED:
        state.allPkgTypes.loading = true;
        break;
      case actions.GET_ALL_PKG_TYPES.SUCCEEDED:
        state.allPkgTypes.loading = false;
        state.allPkgTypes.success = true;
        state.allPkgTypes.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PKG_TYPES.FAILED:
        state.allPkgTypes.loading = false;
        state.allPkgTypes.failure = payload.error;
        break;
      case actions.CREATE_PKG_TYPE.REQUESTED:
        state.createPkgType.loading = true;
        break;
      case actions.CREATE_PKG_TYPE.SUCCEEDED:
        state.createPkgType.loading = false;
        state.createPkgType.success = true;
        state.allPkgTypes.list = [payload.data, ...state.allPkgTypes.list];
        break;
      case actions.CREATE_PKG_TYPE.FAILED:
        state.createPkgType.loading = false;
        state.createPkgType.failure = payload.error;
        break;
      case actions.CREATE_PKG_TYPE.CLEAR:
        state.createPkgType = initialState.createPkgType;
        break;
      case actions.EDIT_PKG_TYPE.REQUESTED:
        state.editPkgType.loading = true;
        break;
      case actions.EDIT_PKG_TYPE.SUCCEEDED:
        state.editPkgType.loading = false;
        state.editPkgType.success = true;
        break;
      case actions.EDIT_PKG_TYPE.FAILED:
        state.editPkgType.loading = false;
        state.editPkgType.failure = payload.error;
        break;
      case actions.EDIT_PKG_TYPE.CLEAR:
        state.editPkgType = initialState.editPkgType;
        break;
      case actions.DELETE_PKG_TYPE.REQUESTED:
        state.deletePkgType.loading = true;
        break;
      case actions.DELETE_PKG_TYPE.SUCCEEDED:
        state.deletePkgType.loading = false;
        state.deletePkgType.success = true;
        state.allPkgTypes.list = state.allPkgTypes.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_PKG_TYPE.FAILED:
        state.deletePkgType.loading = false;
        state.deletePkgType.failure = payload.error;
        break;
      case actions.DELETE_PKG_TYPE.CLEAR:
        state.deletePkgType = initialState.deletePkgType;
        break;
      case actions.GET_PKG_SEASON.REQUESTED:
        state.pkgSeason.loading = true;
        break;
      case actions.GET_PKG_SEASON.SUCCEEDED:
        state.pkgSeason.loading = false;
        state.pkgSeason.success = true;
        state.pkgSeason.data = payload.data.data;
        break;
      case actions.GET_PKG_SEASON.FAILED:
        state.pkgSeason.loading = false;
        state.pkgSeason.failure = payload.error;
        break;
      case actions.GET_ALL_PKG_SEASONS.REQUESTED:
        state.allPkgSeasons.loading = true;
        break;
      case actions.GET_ALL_PKG_SEASONS.SUCCEEDED:
        state.allPkgSeasons.loading = false;
        state.allPkgSeasons.success = true;
        state.allPkgSeasons.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PKG_SEASONS.FAILED:
        state.allPkgSeasons.loading = false;
        state.allPkgSeasons.failure = payload.error;
        break;
      case actions.CREATE_PKG_SEASON.REQUESTED:
        state.createPkgSeason.loading = true;
        break;
      case actions.CREATE_PKG_SEASON.SUCCEEDED:
        state.createPkgSeason.loading = false;
        state.createPkgSeason.success = true;
        state.allPkgSeasons.list = [payload.data, ...state.allPkgSeasons.list];
        break;
      case actions.CREATE_PKG_SEASON.FAILED:
        state.createPkgSeason.loading = false;
        state.createPkgSeason.failure = payload.error;
        break;
      case actions.CREATE_PKG_SEASON.CLEAR:
        state.createPkgSeason = initialState.createPkgSeason;
        break;
      case actions.EDIT_PKG_SEASON.REQUESTED:
        state.editPkgSeason.loading = true;
        break;
      case actions.EDIT_PKG_SEASON.SUCCEEDED:
        state.editPkgSeason.loading = false;
        state.editPkgSeason.success = true;
        break;
      case actions.EDIT_PKG_SEASON.FAILED:
        state.editPkgSeason.loading = false;
        state.editPkgSeason.failure = payload.error;
        break;
      case actions.EDIT_PKG_SEASON.CLEAR:
        state.editPkgSeason = initialState.editPkgSeason;
        break;
      case actions.DELETE_PKG_SEASON.REQUESTED:
        state.deletePkgSeason.loading = true;
        break;
      case actions.DELETE_PKG_SEASON.SUCCEEDED:
        state.deletePkgSeason.loading = false;
        state.deletePkgSeason.success = true;
        state.allPkgSeasons.list = state.allPkgSeasons.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_PKG_SEASON.FAILED:
        state.deletePkgSeason.loading = false;
        state.deletePkgSeason.failure = payload.error;
        break;
      case actions.DELETE_PKG_SEASON.CLEAR:
        state.deletePkgSeason = initialState.deletePkgSeason;
        break;
      case actions.GET_PKG_CLASS.REQUESTED:
        state.pkgClass.loading = true;
        break;
      case actions.GET_PKG_CLASS.SUCCEEDED:
        state.pkgClass.loading = false;
        state.pkgClass.success = true;
        state.pkgClass.data = payload.data;
        break;
      case actions.GET_PKG_CLASS.FAILED:
        state.pkgClass.loading = false;
        state.pkgClass.failure = payload.error;
        break;
      case actions.GET_ALL_PKG_CLASSES.REQUESTED:
        state.allPkgClasses.loading = true;
        break;
      case actions.GET_ALL_PKG_CLASSES.SUCCEEDED:
        state.allPkgClasses.loading = false;
        state.allPkgClasses.success = true;
        state.allPkgClasses.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PKG_CLASSES.FAILED:
        state.allPkgClasses.loading = false;
        state.allPkgClasses.failure = payload.error;
        break;
      case actions.CREATE_PKG_CLASS.REQUESTED:
        state.createPkgClass.loading = true;
        break;
      case actions.CREATE_PKG_CLASS.SUCCEEDED:
        state.createPkgClass.loading = false;
        state.createPkgClass.success = true;
        state.allPkgClasses.list = [payload.data, ...state.allPkgClasses.list];
        break;
      case actions.CREATE_PKG_CLASS.FAILED:
        state.createPkgClass.loading = false;
        state.createPkgClass.failure = payload.error;
        break;
      case actions.EDIT_PKG_CLASS.REQUESTED:
        state.editPkgClass.loading = true;
        break;
      case actions.EDIT_PKG_CLASS.SUCCEEDED:
        state.editPkgClass.loading = false;
        state.editPkgClass.success = true;
        break;
      case actions.EDIT_PKG_CLASS.FAILED:
        state.editPkgClass.loading = false;
        state.editPkgClass.failure = payload.error;
        break;
      case actions.EDIT_PKG_CLASS.CLEAR:
        state.editPkgClass = initialState.editPkgClass;
        break;
      case actions.DELETE_PKG_CLASS.REQUESTED:
        state.deletePkgClass.loading = true;
        break;
      case actions.DELETE_PKG_CLASS.SUCCEEDED:
        state.deletePkgClass.loading = false;
        state.deletePkgClass.success = true;
        state.allPkgClasses.list = state.allPkgClasses.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_PKG_CLASS.FAILED:
        state.deletePkgClass.loading = false;
        state.deletePkgClass.failure = payload.error;
        break;
      case actions.DELETE_PKG_CLASS.CLEAR:
        state.deletePkgClass = initialState.deletePkgClass;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
