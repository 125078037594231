import React from "react";
import { Modal } from "react-bootstrap";

import { Button, Input, Select } from "../../../common";
import { Grid, Switch } from "@material-ui/core";
import { currencyOptions } from "../../../../../_assets/js/components/options";

export const BankAccountForm = ({
  fields,
  show,
  banksOptions,
  loading,
  onHide,
}) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;
  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Payment Method</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Select
                showErrors={touched["bank"]}
                errorMessage={errors["bank"]}
                value={values.bank}
                onChange={(newValue) => setFieldValue("bank", newValue)}
                variant="secondary"
                label="Bank"
                options={banksOptions}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Input
                {...getFieldProps("accountHolderName")}
                showErrors={touched["accountHolderName"]}
                errorMessage={errors["accountHolderName"]}
                variant="secondary"
                label="Account Name"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Input
                {...getFieldProps("accountNo")}
                showErrors={touched["accountNo"]}
                errorMessage={errors["accountNo"]}
                variant="secondary"
                label="Account Number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Input
                {...getFieldProps("swiftCode")}
                showErrors={touched["swiftCode"]}
                errorMessage={errors["swiftCode"]}
                variant="secondary"
                label="IBAN"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Input
                {...getFieldProps("iban")}
                showErrors={touched["iban"]}
                errorMessage={errors["iban"]}
                variant="secondary"
                label="SWIFT/BIC"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Select
                value={values.currency}
                onChange={(newValue) => setFieldValue("currency", newValue)}
                showErrors={touched["currency"]}
                errorMessage={errors["currency"]}
                variant="secondary"
                label="Currencies"
                options={currencyOptions}
              />
            </Grid>
            <Grid className="d-flex justify-content-start" item xs={12} md={6}>
              <label className="d-flex flex-column align-items-center justify-content-between">
                Active
                <Switch
                  checked={values.isActive}
                  onClick={() => setFieldValue("isActive", !values.isActive)}
                  color="primary"
                />
              </label>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
