import React from "react";
import SVG from "react-inlinesvg";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { NavLink } from "react-router-dom";
import { ADMIN_FEE_LOGS } from "../../../../../../app/routesMapAdmin";

export const FEE_TYPE_REISSUE = 1;
export const FEE_TYPE_REFUND = 2;
export const FEE_TYPE_VOID = 3;
export const FEE_TYPE_SUBSCRIPTION = 4;
export const FEE_TYPE_STRIPE = 5;
export const FEE_TYPE_WAAFI = 6;
export const FEE_TYPE_PREMIERE = 7;
export const FEE_TYPE_STRIPE_PERCENTAGE = 8;

export const feeTypes = {
  [FEE_TYPE_REISSUE]: "ReIssue",
  [FEE_TYPE_REFUND]: "Refund",
  [FEE_TYPE_VOID]: "Void",
  [FEE_TYPE_SUBSCRIPTION]: "Subscription",
  [FEE_TYPE_STRIPE]: "Stripe Fixed Fee",
  [FEE_TYPE_WAAFI]: "Waafi Pay",
  [FEE_TYPE_PREMIERE]: "Premiere Wallet",
  [FEE_TYPE_STRIPE_PERCENTAGE]: "Stripe Percentage",
};

export const ServiceFeesRow = ({ subPermissions, data, onEdit }) => {
  const { id, amount, type, isPercentage } = data;
  const allServiceFeeSubPermission =
    adminPermissionsIds.systemManagement.serviceFees.subPermissions;
  return (
    <tr>
      <td className="text-left">
        <NavLink
          to={parseRoute(ADMIN_FEE_LOGS, { type })}
          className="text-hover-primary"
        >
          {feeTypes[type]}
        </NavLink>
      </td>
      <td>{`${amount}${isPercentage ? "%" : "$"}`}</td>

      <td className="text-right">
        <button
          hidden={!subPermissions.includes(allServiceFeeSubPermission.edit.id)}
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        {/* <button
          title=""
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button> */}
      </td>
    </tr>
  );
};
