import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./billsConstants";

export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const GET_ALL = requestActions(MODULE_NAME, "GET_ALL");
export const GET_ONE = requestActions(MODULE_NAME, "GET_ONE");

export const RESET = `${MODULE_NAME}/RESET`;

export const editBill = (params) => requestApiCall("editBill", params, EDIT);

export const getBills = () => requestApiCall("getBills", {}, GET_ALL);

export const filterBills = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterBills",
    { sortingParams, filterParam, pageNumber, pageSize },
    GET_ALL
  );

export const getSpecificBill = (params) =>
  requestApiCall("getSpecificBill", params, GET_ONE);

export const clearSpecificBill = () => ({
  type: GET_ONE.CLEAR,
});

export const clearCreate = () => ({ type: CREATE.CLEAR });

export const clearEdit = () => ({ type: EDIT.CLEAR });

export const reset = () => ({ type: RESET });
