import React from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Button, Input } from "../../../common";

export const PackageTypesForm = ({ fields, show, loading, onHide }) => {
  const { getFieldProps, touched, errors, handleSubmit } = fields;
  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Package Type</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {loading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Input
                {...getFieldProps("pkgTypeName")}
                showErrors={touched["pkgTypeName"]}
                errorMessage={errors["pkgTypeName"]}
                variant="secondary"
                label="Name"
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            smallPadding
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
