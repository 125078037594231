import { Paper, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useExportAccounting, {
  exportAccountingTypesMap,
} from "../../../../../../app/helpers/hooks/useExportAccounting";
import { actions } from "../../../../../../redux/state/invoices";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { Button, Seperator } from "../../../common";
import { BalanceDueSummary } from "../../reports/balanceDueReport/BalanceDueSummary";
import { ReportsSearchCard } from "../../reports/common";
import { ReceivableDueTable } from "./ReceivableDueTable";

export const ReceivableDue = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { paginationInfo, stats } = useSelector(
    ({ invoices }) => invoices.invoices
  );
  const { totalPages } = paginationInfo ?? {};
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { exportToExcel, loading: downloadLoading } = useExportAccounting();

  const { fromDate, toDate } = dateRange;
  const getInvoices = useCallback(
    ({ pageNumber = 1, filters = [] } = {}) => {
      dispatch(
        actions.filterInvoices({
          filterParam: [
            {
              columnName: "status",
              filterValue: "UnPaid",
              filterOption: filterOptions.StartsWith,
            },
            ...filters,
          ],
          pageNumber,
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    getInvoices({ pageNumber, filters: appliedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    getInvoices({ pageNumber: 1, filters });
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      getInvoices();
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const onExport = () => {
    exportToExcel({
      type: exportAccountingTypesMap.invoices,
      filterParam: [
        {
          columnName: "status",
          filterValue: "UnPaid",
          filterOption: filterOptions.StartsWith,
        },
        ...appliedFilters,
      ],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <ReportsSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Typography variant="h6" className="font-weight-bold mb-2">
                Receivable Due
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <Button
              onClick={onExport}
              loading={downloadLoading}
              variant="danger"
            >
              Export
            </Button>
          </div>
          <Seperator />
          <BalanceDueSummary stats={stats} />
          <Seperator />
          <ReceivableDueTable
            onUpdateSuccess={() =>
              getInvoices({
                pageNumber,
                filters: appliedFilters,
              })
            }
          />
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
