import { bookingClient } from "./client";

export const getCompanyRevenueByMonth = ({ companyId }) =>
  bookingClient.get(`/booking/v1/Charts/CompanyRevenueByMonth/${companyId}`);

export const getCompanyBookingByMonth = ({ companyId }) =>
  bookingClient.get(`/booking/v1/Charts/CompanyBookingByMonth/${companyId}`);

export const getRevenueByMonth = () =>
  bookingClient.get("/booking/v1/Charts/GetRevenueByMonth");

export const getBookingByMonth = () =>
  bookingClient.get("/booking/v1/Charts/GetBookingByMonth");
