import { Grid, Typography, Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/accountBalance";
import { actions as companyCreditActions } from "../../../../../redux/state/companyCredit";

import { toAbsoluteUrl } from "../../../../_helpers";
import { Chart } from "./Chart";

export const ProfileInfo = ({ noBottomPadding }) => {
  const dispatch = useDispatch();
  const { id, name, email, address, phone, companyInUserId } = useSelector(
    ({ user }) => user.userData
  );
  const { name: companyName, id: companyId } = useSelector(
    ({ company }) => company.companyData.data || {}
  );
  const { data } = useSelector(({ accountBalance }) => accountBalance.balance);
  const { availableBalance: availableCredit } =
    useSelector(({ companyCredit }) => companyCredit.creditDetails.data) || {};
  useEffect(() => {
    dispatch(actions.getAccountBalance({ companyId }));
    dispatch(
      companyCreditActions.getCreditDetailsByCompanyId({
        companyId: companyInUserId,
      })
    );
    return () => {
      dispatch(actions.clearAccountBalance());
    };
  }, []);
  return (
    <Fragment>
      <Grid item xs={12} className="pb-3">
        <Paper className="bg-white p-7 d-flex justify-content-between align-items-center h-100">
          <div>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="body1" className="text-primary my-1">
              {email}
            </Typography>
            <Typography variant="body1" className="text-muted">
              {companyName}
            </Typography>
          </div>
          <img
            className="rounded-circle"
            src={toAbsoluteUrl("/media/users/default.jpg")}
            alt=""
          />
        </Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        className={clsx("pt-3 pr-3", { "pb-3": !noBottomPadding })}
      >
        <Paper className="bg-white py-7 text-center h-100 d-flex flex-column justify-content-center">
          <Typography variant="h4" className="mb-3">
            <span className="text-primary">$</span>
            {data?.availableBalance}
          </Typography>
          <Typography variant="h6" className="mb-2">
            Available Balance
          </Typography>
          {/* <Typography variant="body2">
            Payable Balance: {data?.availableBalance}
          </Typography> */}
        </Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        className={clsx("pt-3 pl-3", { "pb-3": !noBottomPadding })}
      >
        <Paper className="bg-white py-7 text-center h-100 d-flex flex-column justify-content-center">
          <Typography variant="h4" className="mb-3">
            <span className="text-primary">$</span>
            {availableCredit}
          </Typography>
          <Typography variant="h6" className="mb-2">
            Available Credit
          </Typography>
          {/* <Typography variant="body2">
            Payable Balance: ${data?.availableCredit}
          </Typography> */}
        </Paper>
      </Grid>
    </Fragment>
  );
};

export const InfoBar = () => {
  return (
    <Fragment>
      <ProfileInfo />
      {/* <Grid item xs={12} className="pt-3">
        <Paper className="bg-white h-100">
          <Chart>
            <div className="">
              <Typography variant="h6" className="mb-8">
                Sales Summary
              </Typography>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" className="text-muted">
                  Avarage Sale
                </Typography>
                <Typography variant="h5" className="font-weight-bolder">
                  $650
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" className="text-muted">
                  Comission
                </Typography>
                <Typography variant="h5" className="font-weight-bolder">
                  $29,004
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="p" className="text-muted">
                  Annual Taxes 2019
                </Typography>
                <Typography variant="h5" className="font-weight-bolder">
                  $233,600
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="p" className="text-muted">
                  Annual Income
                </Typography>
                <Typography variant="h5" className="font-weight-bolder">
                  $1,480,00
                </Typography>
              </Grid>
            </Grid>
          </Chart>
        </Paper> */}
      {/* </Grid> */}
    </Fragment>
  );
};
