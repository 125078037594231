import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { OperationDashboardTable } from "./OperationDashboardTable";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import Svg from "react-inlinesvg";
import { useFormik } from "formik";

import { Button, Select } from "../../../common";
import { toAbsoluteUrl } from "../../../../../_helpers";
import useAgenciesOptions from "../../../../../../app/helpers/hooks/useAgenciesOptions";
import { DateRangeModal } from "../../reports/common/reportsSearchCard/DateRangeModal";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { useHistory } from "react-router-dom";
import { ADMIN_ACCOUNTING_OPERATION_AGENCY } from "../../../../../../app/routesMapAdmin";

export const OperationDashboard = () => {
  const { agencyOptions } = useAgenciesOptions();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [connected, setConnected] = useState(false);
  const [showModal, setShowModal] = useState(null);
  // const [connection, setConnection] = useState();

  const fields = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      client: 0,
    },
    enableReinitialize: true,
    onSubmit: ({ fromDate, toDate, client }) => {
      invokeAgencyStats({
        fromDate: fromDate || null,
        toDate: toDate || null,
        client: client || 0,
      });
    },
  });
  const { values, handleSubmit, setFieldValue, setValues } = fields;
  const { fromDate, toDate, client } = values;
  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_REPORTING_API_URL.replace(
            "https",
            "wss"
          )}/flyme-reporting-hub`,
          {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            headers: {
              authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoicWFzaW02ODMiLCJqdGkiOiJiZTlkZWZhMS1lZjljLTQ1YTctOWYzOS1iYjYwOGUyYWNiZTAiLCJleHAiOjE2NTc3OTYzMTEsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAxIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDEifQ.2GRIEyAOrs6h3BveqCgepb_61YByT7aP6MSXYyrinQA",
            },
          }
        )
        .configureLogging(LogLevel.Information)
        // .withAutomaticReconnect()
        .build(),
    []
  );
  const start = useCallback(async () => {
    try {
      // connection.hub.qs = {
      //   authorization:
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoicWFzaW02ODMiLCJqdGkiOiJiZTlkZWZhMS1lZjljLTQ1YTctOWYzOS1iYjYwOGUyYWNiZTAiLCJleHAiOjE2NTc3OTYzMTEsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAxIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDEifQ.2GRIEyAOrs6h3BveqCgepb_61YByT7aP6MSXYyrinQA",
      // };
      connection.on("AgencyStats", (data) => {
        if (data.data) {
          setData(data.data?.length ? data.data : [data.data]);
        } else {
          setData([]);
        }
      });
      connection.on("GetAgencyStats", async () => {
        invokeAgencyStats();
      });
      connection
        .start()
        .then(() => {
          setConnected(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (err) {
      console.error(err);
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const invokeAgencyStats = useCallback(
    ({ client = 0, fromDate = null, toDate = null } = {}) => {
      connection.invoke("AgencyStats", client, fromDate, toDate);
    },
    [connection]
  );

  useEffect(() => {
    if (!connected) return;
    invokeAgencyStats();
  }, [connected, invokeAgencyStats]);

  useEffect(() => {
    start();
    return () => connection.stop();
  }, [connection, start]);
  const handleDateClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleReset = () => {
    setValues({
      fromDate: "",
      toDate: "",
      client: 0,
    });
  };
  const goToAgencyDetails = (agencyId) => {
    const searchParams = {};
    if (fromDate && toDate) {
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
    }
    history.push(
      parseRoute(
        ADMIN_ACCOUNTING_OPERATION_AGENCY,
        { id: agencyId },
        searchParams
      )
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 ">
          <DateRangeModal
            fields={fields}
            show={showModal}
            onHide={handleClose}
          />
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              className="mb-3 justify-content-between"
            >
              <Grid item xs={8} md={4}>
                <Select
                  value={client}
                  onChange={(v) => setFieldValue("client", v)}
                  options={[
                    { value: 0, label: "All Clients" },
                    ...(agencyOptions || []),
                  ]}
                  placeholder="Client"
                />
              </Grid>
              <Grid item xs={8} md={4}>
                <button
                  onClick={handleDateClick}
                  type="button"
                  className="btn text-underlined d-flex align-items-center "
                >
                  <Svg
                    src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")}
                  />
                  <span className="font-weight-bold ml-2">Date Filter</span>
                </button>
                {/* <Select
                value={selectedTimeFilter}
                onChange={(v) => setSelectedTimeFilter(v)}
                options={timeFilterOptions}
                placeholder="Time"
              /> */}
              </Grid>
              {/* <Grid item xs={12} md={4}></Grid> */}
              <Grid item xs>
                <div className="d-flex justify-content-center">
                  <Button type="submit">Search</Button>
                  <Button
                    onClick={handleReset}
                    className="ml-3"
                    type="button"
                    variant="danger"
                  >
                    Reset
                  </Button>
                </div>
              </Grid>
              <Grid item xs>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary-inverse"
                    className="font-weight-bolder mr-3"
                    smallPadding
                    title="Export"
                    type="button"
                  >
                    <span className="svg-icon svg-icon-primary">
                      <SVG
                        title="Export"
                        src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")}
                      />
                    </span>
                    Export
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
          {(fromDate || toDate) && (
            <Typography>
              Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          {/* <Grid item xs>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid> */}
          <OperationDashboardTable
            goToAgencyDetails={goToAgencyDetails}
            list={data}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
