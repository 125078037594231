import { CircularProgress } from "@material-ui/core";
import React from "react";

export const LoadingSpinner = () => {
  return (
    <div className="w-100 min-h-100px d-flex align-items-center justify-content-center">
      <CircularProgress className="splash-screen-spinner" />
    </div>
  );
};
