import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { INVOICES_PAGE_SIZE, MODULE_NAME } from "./invoicesConstants";

export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const GET_ALL = requestActions(MODULE_NAME, "GET_ALL");
export const GET_UNPAID_INVOICES = requestActions(
  MODULE_NAME,
  "GET_UNPAID_INVOICES"
);
export const FILTER = requestActions(MODULE_NAME, "FILTER");
export const GET_ONE = requestActions(MODULE_NAME, "GET_ONE");

export const GET_BY_INVOICE_NO = requestActions(
  MODULE_NAME,
  "GET_BY_INVOICE_NO"
);

export const RESET = `${MODULE_NAME}/RESET`;

export const createInvoice = (params) =>
  requestApiCall("createInvoice", params, CREATE);

export const editInvoice = (params) =>
  requestApiCall("editInvoice", params, EDIT);

export const getUnpaidInvoices = () =>
  requestApiCall("getUnpaidInvoices", {}, GET_UNPAID_INVOICES);

export const getInvoices = () => requestApiCall("getInvoices", {}, GET_ALL);

export const filterInvoicesPagination = (params) =>
  requestApiCall(
    "filterInvoicesPagination",
    { pageSize: INVOICES_PAGE_SIZE, pageNumber: 1, ...params },
    FILTER
  );

export const filterInvoices = (params) =>
  requestApiCall(
    "filterInvoicesPagination",
    { pageSize: INVOICES_PAGE_SIZE, ...params },
    FILTER
  );

export const getSpecificInvoice = (params) =>
  requestApiCall("getSpecificInvoice", params, GET_ONE);

export const getInvoiceByInvoiceNo = (params) =>
  requestApiCall("getInvoiceByInvoiceNo", params, GET_BY_INVOICE_NO);

export const clearSpecificInvoice = () => ({
  type: GET_ONE.CLEAR,
});

export const clearCreate = () => ({ type: CREATE.CLEAR });

export const clearEdit = () => ({ type: EDIT.CLEAR });

export const reset = () => ({ type: RESET });
