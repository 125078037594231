import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { InventoryForm } from "../../../_metronic/layout/components/admin/packageInventory/inventory/InventoryForm";
import { InventoryTable } from "../../../_metronic/layout/components/admin/packageInventory/inventory/InventoryTable";
import {
  ADMIN_PACKAGES_CREATE_INVENTORY,
  ADMIN_PACKAGES_INVENTORY,
} from "../../routesMapAdmin";

export const InventoryPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={ADMIN_PACKAGES_CREATE_INVENTORY}
        component={InventoryForm}
      />
      <Route exact path={ADMIN_PACKAGES_INVENTORY} component={InventoryTable} />
      <Redirect to={ADMIN_PACKAGES_INVENTORY} />
    </Switch>
  );
};
