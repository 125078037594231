import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ReactSelect, { createFilter, components } from "react-select";
import { FixedSizeList as List } from "react-window";

import styles from "./Select.module.scss";
import { Button } from "../Button/Button";

const dummyOptions = [
  { value: "option1", label: "option1" },
  { value: "option2", label: "option2" },
  { value: "option3", label: "option3" },
];

const CustomMenu = ({ isDisabled, onClickAdd, children, ...rest }) =>
  !isDisabled ? (
    <components.MenuList {...rest}>
      {children}
      <Button
        variant="neutral"
        type="button"
        onClick={onClickAdd}
        className="w-100 font-weight-bold rounded-0 mt-1 bg-hover-light-primary"
      >
        Add New
      </Button>
    </components.MenuList>
  ) : null;

export const Select = ({
  className,
  placeholder,
  style,
  label,
  id,
  horizontal,
  disabled,
  onChange,
  value,
  required,
  options,
  inputProps,
  outlined,
  fixedLabelWidth,
  fixedLabelWidthSmall,
  noErrorMessage,
  showErrors,
  errorMessage,
  name,
  variant,
  spacing,
  multi,
  optional,
  hasAddOption,
  onClickAdd,
  ...others
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const selectClassNames = clsx({
    [styles.select]: variant === "primary",
    [styles.selectOutlined]: outlined,
    [styles.secondarySelect]: variant === "secondary",
    [styles.inputError]: showErrors,
  });

  const containerClassNames = clsx("d-flex w-100", className, {
    "justify-content-start flex-column": !horizontal || !matchesSm,
    "align-items-center justify-content-between": horizontal && matchesSm,
    "mb-3": spacing,
    // "pb-8": !noErrorMessage,
  });
  // const optionsContent = options.map(({ label, value }) => (
  //   <option key={value} value={value}>
  //     {label}
  //   </option>
  // ));
  const handleMultiChange = (selectedOptions) => {
    const values = selectedOptions?.map(({ value }) => value);
    onChange(values);
  };
  const handleSingleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };
  const selectStyles = {
    border: outlined ? "1px solid #666666" : "none",
    background: variant === "primary" ? "#f3f6f9" : "",
  };
  const getValue = () => {
    if (multi) {
      return options.filter((option) => value?.includes(option.value));
    }
    return options.filter((option) => option.value === value)[0];
  };
  return (
    <div className={containerClassNames}>
      {label && (
        <label
          style={{ whiteSpace: "nowrap" }}
          // className={labelClassNames}
          htmlFor={id || label}
          className={clsx({
            "min-w-150px mb-3": fixedLabelWidth,
            "min-w-75px": fixedLabelWidthSmall,
          })}
        >
          {required && <span className="text-danger">*</span>}
          {label} {optional && <span className="text-muted">(optional)</span>}
          {horizontal && ":"}
        </label>
      )}
      <div className="w-100">
        <ReactSelect
          isDisabled={disabled}
          isMulti={multi}
          options={options}
          closeMenuOnSelect={!multi}
          classNamePrefix="select"
          placeholder={placeholder}
          name={name}
          onChange={multi ? handleMultiChange : handleSingleChange}
          value={getValue()}
          {...others}
          styles={{
            control: (base, state) => ({
              ...base,
              ...selectStyles,
              "&:focus": { borderColor: "#3798d3" }, // border style on hover
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (base) => ({
              ...base,
              // textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              // overflow: "hidden",
              maxWidth: "100%",
              // padding: "1rem",
            }),
          }}
          components={{
            // Input:Control,
            IndicatorSeparator: () => null,
            ...(hasAddOption
              ? {
                  MenuList: (props) => (
                    <CustomMenu onClickAdd={onClickAdd} {...props} />
                  ),
                }
              : {}),
            // MenuList,
            // IndicatorsContainer: () => null,
          }}
          // menuPortalTarget={document.body}
          filterOption={createFilter({ ignoreAccents: false })}
          // styles={}
        />
        {showErrors && errorMessage && (
          <div className="fv-plugins-message-container h-20px max-w-100">
            <div className="fv-help-block">{showErrors && errorMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};

Select.defaultProps = {
  options: dummyOptions,
  outlined: true,
  variant: "primary",
};

Select.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  horizontal: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  outlined: PropTypes.bool,
  variant: PropTypes.string,
};
