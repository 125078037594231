import React, { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input, Select } from "../../../../common";
import { BookingNotesTable } from "./BookingNotesTable";
import { actions } from "../../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../../redux/state/inlineNotification";
import { bookingNotesOptions } from "../../../../../../_assets/js/components/options";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const BookingNotes = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userId } = useSelector(({ auth }) => auth);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ bookingMng }) => bookingMng.createBookingNote);
  useEffect(() => {
    dispatch(actions.getBookingNotes({ bookingId: id }));
  }, [dispatch, id]);
  const fields = useFormik({
    initialValues: { category: "", note: "" },
    validationSchema: Yup.object().shape({
      category: Yup.string().required("Please select category first"),
      note: Yup.string().required("Please enter note first"),
    }),
    onSubmit: ({ note, category }) => {
      dispatch(
        actions.createBookingNote({
          bookingId: id,
          notes: note,
          userId,
          category,
        })
      );
    },
  });
  const {
    getFieldProps,
    errors,
    touched,
    values,
    setFieldValue,
    handleSubmit,
  } = fields;
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Note Added!",
        })
      );
      dispatch(actions.clearCreateBookingNote());
      dispatch(actions.getBookingNotes({ bookingId: id }));
    }
  }, [createSuccess, dispatch, id]);
  useEffect(() => {
    if (createFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateBookingNote());
    }
  }, [createFailure, dispatch]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmit}>
                <Select
                  variant="secondary"
                  spacing
                  fixedLabelWidth
                  label="Category"
                  showErrors={touched.category}
                  errorMessage={errors.category}
                  value={values.note}
                  onChange={(v) => setFieldValue("category", v)}
                  options={bookingNotesOptions}
                />
                <Input
                  variant="secondary"
                  multiline
                  fixedLabelWidth
                  label="Note"
                  {...getFieldProps("note")}
                  showErrors={touched.note}
                  errorMessage={errors.note}
                  spacing
                />
                <div className="d-flex justify-content-center">
                  <Button loading={createLoading} type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <BookingNotesTable />
      </Grid>
    </Grid>
  );
};
