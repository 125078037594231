export const MODULE_NAME = "accounting";

export const ACCOUNT_TYPE_ASSETS = 0;
export const ACCOUNT_TYPE_LIABILITIES = 1;
export const ACCOUNT_TYPE_EQUITY = 2;
export const ACCOUNT_TYPE_REVENUES = 3;
export const ACCOUNT_TYPE_EXPENSES = 4;

export const accountTypesLabel = {
  [ACCOUNT_TYPE_ASSETS]: "Assets",
  [ACCOUNT_TYPE_LIABILITIES]: "Liabilities",
  [ACCOUNT_TYPE_EQUITY]: "Equity",
  [ACCOUNT_TYPE_REVENUES]: "Revenues",
  [ACCOUNT_TYPE_EXPENSES]: "Expenses",
};
