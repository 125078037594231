import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import useCitiesOptions from "../../../../../../app/helpers/hooks/useCitiesOptions";
import usePkgClassOptions from "../../../../../../app/helpers/hooks/usePkgClassOptions";
import usePkgSeasonsOptions from "../../../../../../app/helpers/hooks/usePkgSeasonsOptions";
import usePkgTypesOptions from "../../../../../../app/helpers/hooks/usePkgTypesOptions";
import { Input, LoadingSpinner, Select } from "../../../common";

import { InventoryFormActions } from "./InventoryFormActions";

const departDaysSchema = Yup.object().shape({
  packageName: Yup.string().required("Required Field"),
  packageTYpe: Yup.string().required("Required Field"),
  packageSeason: Yup.string().required("Required Field"),
  fromCity: Yup.string().required("Required Field"),
  destination: Yup.string().required("Required Field"),
  departDate: Yup.string().required("Required Field"),
  returnDate: Yup.string().required("Required Field"),
  quantity: Yup.string().required("Required Field"),
  duration: Yup.string().required("Required Field"),
  bookingClasses: Yup.array()
    .of(Yup.number())
    .required("Required Field"),
});

export const PackageDetails = ({
  setSelectedTab,
  selectedTab,
  data,
  onSubmit,
}) => {
  const {
    pkgTypesOptions,
    loading: pkgTypesOptionsLoading,
  } = usePkgTypesOptions();
  const {
    pkgSeasonOptions,
    loading: pkgSeasonOptionsLoading,
  } = usePkgSeasonsOptions();
  const { citiesOptions, loading: citiesOptionsLoading } = useCitiesOptions();
  const {
    pkgClassesOptions,
    loading: pkgClassesOptionsLoading,
  } = usePkgClassOptions();

  const fields = useFormik({
    initialValues: data ?? {
      packageName: "",
      packageTYpe: "",
      packageSeason: "",
      fromCity: "",
      destination: "",
      departDate: "",
      returnDate: "",
      quantity: "",
      duration: "",
      bookingClasses: [],
    },
    validationSchema: departDaysSchema,
    onSubmit: (values) => {
      // handleSubmit(values);
      // setSelectedTab((prev) => prev + 1);
      onSubmit(values);
    },
  });
  const {
    getFieldProps,
    errors,
    touched,
    setFieldValue,
    values,
    handleSubmit,
  } = fields;
  const {
    packageTYpe,
    destination,
    fromCity,
    packageSeason,
    bookingClasses,
  } = values;
  return (
    <form onSubmit={handleSubmit}>
      {pkgTypesOptionsLoading ||
      citiesOptionsLoading ||
      citiesOptionsLoading ||
      pkgSeasonOptionsLoading ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              {...getFieldProps("packageName")}
              showErrors={touched["packageName"]}
              errorMessage={errors["packageName"]}
              label="Package Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              value={packageTYpe}
              errorMessage={errors?.packageTYpe}
              showErrors={touched?.packageTYpe}
              onChange={(v) => setFieldValue(`packageTYpe`, v)}
              isLoading={pkgTypesOptionsLoading}
              options={pkgTypesOptions}
              variant="secondary"
              label="Package Type"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              value={bookingClasses}
              errorMessage={errors?.bookingClasses}
              showErrors={touched?.bookingClasses}
              onChange={(v) => setFieldValue(`bookingClasses`, v)}
              isLoading={pkgClassesOptionsLoading}
              options={pkgClassesOptions}
              variant="secondary"
              label="Package Classes"
              // hasAddOption
              multi
              // onClickAdd={() => setShowAirportForm(true)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              value={packageSeason}
              errorMessage={errors?.packageSeason}
              showErrors={touched?.packageSeason}
              onChange={(v) => setFieldValue(`packageSeason`, v)}
              isLoading={pkgSeasonOptionsLoading}
              options={pkgSeasonOptions}
              variant="secondary"
              label="Package Season"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              value={fromCity}
              errorMessage={errors?.fromCity}
              showErrors={touched?.fromCity}
              onChange={(v) => setFieldValue(`fromCity`, v)}
              isLoading={citiesOptionsLoading}
              options={citiesOptions}
              variant="secondary"
              label="From City"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              value={destination}
              errorMessage={errors?.destination}
              showErrors={touched?.destination}
              onChange={(v) => setFieldValue(`destination`, v)}
              isLoading={citiesOptionsLoading}
              options={citiesOptions}
              variant="secondary"
              label="Destination"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              {...getFieldProps("departDate")}
              showErrors={touched["departDate"]}
              errorMessage={errors["departDate"]}
              type="datetime-local"
              label="Depart Date and Time"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              {...getFieldProps("returnDate")}
              showErrors={touched["returnDate"]}
              errorMessage={errors["returnDate"]}
              type="datetime-local"
              label="Return Date and Time"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              {...getFieldProps("quantity")}
              showErrors={touched["quantity"]}
              errorMessage={errors["quantity"]}
              label="Quantity"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              variant="secondary"
              {...getFieldProps("duration")}
              showErrors={touched["duration"]}
              errorMessage={errors["duration"]}
              label="Duration in days"
            />
          </Grid>
        </Grid>
      )}
      <InventoryFormActions
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </form>
  );
};
