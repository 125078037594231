import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/companyCredit";
import { actions as accountBalanceActions } from "../../../redux/state/accountBalance";

export default function useAgencyPaymentEligibility() {
  const dispatch = useDispatch();
  const { data: accountBalanceData, loading: balanceLoading } = useSelector(
    ({ accountBalance }) => accountBalance.balance
  );
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { availableBalance: availableCredit, loading: creditLoading } =
    useSelector(({ companyCredit }) => companyCredit.creditDetails.data) || {};
  const { availableBalance } = accountBalanceData || {};

  useEffect(() => {
    if (companyInUserId) {
      dispatch(
        actions.getCreditDetailsByCompanyId({ companyId: companyInUserId })
      );
      dispatch(
        accountBalanceActions.getAccountBalance({ companyId: companyInUserId })
      );
    }
  }, [dispatch, companyInUserId]);
  const getAgencyPaymentEligibility = useCallback(
    (price) => {
      const sufficientBalance =
        +price <= +availableBalance || +price <= +availableCredit;
      return sufficientBalance;
    },
    [availableBalance, availableCredit]
  );
  return {
    getAgencyPaymentEligibility,
    loading: balanceLoading && creditLoading,
  };
}
