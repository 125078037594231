import React, { useEffect, useState } from "react";
import { Grid, Paper, withStyles, Tabs, Tab } from "@material-ui/core";
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
  Switch,
  Route,
  Redirect,
} from "react-router";
import {
  ADMIN_CLIENT_BILLING,
  ADMIN_CLIENT_DOCUMENTS,
  ADMIN_CLIENT_SECURITY,
  ADMIN_CLIENT_SETTINGS,
  ADMIN_CLIENT_VIEW,
  ADMIN_FLYME_PROFILE_BILLING,
  ADMIN_FLYME_PROFILE_DOCUMENTS,
  ADMIN_FLYME_PROFILE_SECURITY,
  ADMIN_FLYME_PROFILE_SETTINGS,
  ADMIN_FLYME_PROFILE_VIEW,
} from "../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { FlymeView } from "./flymeView/FlymeView.jsx";
import { FlymeSecurity } from "./flymeSecurity/FlymeSecurity";
import { FlymeBilling } from "./flymeBilling/FlymeBilling";
import { FlymeDocuments } from "./flymeDocuments/FlymeDocuments";
import { FlymeSettings } from "./flymeSettings/FlymeSettings";
import { FlymeInfoBar } from "./FlymeInfoBar";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

const tabsRoutes = {
  // View: ADMIN_FLYME_PROFILE_VIEW,
  Settings: ADMIN_FLYME_PROFILE_SETTINGS,
  // Billing: ADMIN_FLYME_PROFILE_BILLING,
  Security: ADMIN_FLYME_PROFILE_SECURITY,
  // Documents: ADMIN_FLYME_PROFILE_DOCUMENTS,
};

export const FlymeProfile = () => {
  const [selectedTab, setSelectedTab] = useState("View");
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    Object.keys(tabsRoutes).forEach((key) => {
      const match = matchPath(location.pathname, {
        path: tabsRoutes[key],
        exact: true,
        strict: false,
      });
      if (match) setSelectedTab(key);
    });
  }, [location.pathname]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FlymeInfoBar />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => {
              history.push(parseRoute(tabsRoutes[newValue], { id }));
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
          >
            {Object.keys(tabsRoutes).map((key) => (
              <Tab value={key} key={key} label={key} />
            ))}
          </AntTabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          {/* <Route exact path={ADMIN_FLYME_PROFILE_VIEW} component={FlymeView} /> */}
          <Route
            exact
            path={ADMIN_FLYME_PROFILE_SECURITY}
            component={FlymeSecurity}
          />
          {/* <Route
            exact
            path={ADMIN_FLYME_PROFILE_BILLING}
            component={FlymeBilling}
          /> */}
          {/* <Route
            exact
            path={ADMIN_FLYME_PROFILE_DOCUMENTS}
            component={FlymeDocuments}
          /> */}
          <Route
            exact
            path={ADMIN_FLYME_PROFILE_SETTINGS}
            component={FlymeSettings}
          />
          <Redirect to={ADMIN_FLYME_PROFILE_SETTINGS} />
        </Switch>
      </Grid>
    </Grid>
  );
};
