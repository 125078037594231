import React from "react";

export const SubscriptionPriceCard = ({
  subtotal,
  discount,
  total,
  transactionFee,
}) => {
  return (
    <div className="m-7">
      <h5 className="font-weight-bold mb-5">Price Details</h5>

      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Subtotal</span>
        <span>${subtotal.toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Transaction fee</span>
        <span>+${transactionFee.toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Discount</span>
        <span>-${discount.toFixed(2)}</span>
      </div>
      <div className="font-weight-bold d-flex justify-content-between mb-5">
        <span>Total Amount</span>
        <span>${total.toFixed(2)}</span>
      </div>
    </div>
  );
};
