import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./banksConstants";

export const GET_ALL_BANKS = requestActions(MODULE_NAME, "GET_ALL_BANKS");
export const GET_BANK = requestActions(MODULE_NAME, "GET_BANK");
export const CREATE_BANK = requestActions(MODULE_NAME, "CREATE_BANK");
export const EDIT_BANK = requestActions(MODULE_NAME, "EDIT_BANK");
export const DELETE_BANK = requestActions(MODULE_NAME, "DELETE_BANK");
export const GET_ALL_BANK_ACCOUNTS = requestActions(
  MODULE_NAME,
  "GET_ALL_BANK_ACCOUNTS"
);
export const GET_BANK_ACCOUNT = requestActions(MODULE_NAME, "GET_BANK_ACCOUNT");
export const CREATE_BANK_ACCOUNT = requestActions(
  MODULE_NAME,
  "CREATE_BANK_ACCOUNT"
);
export const EDIT_BANK_ACCOUNT = requestActions(
  MODULE_NAME,
  "EDIT_BANK_ACCOUNT"
);
export const DELETE_BANK_ACCOUNT = requestActions(
  MODULE_NAME,
  "DELETE_BANK_ACCOUNT"
);
export const GET_ALL_PAYMENT_METHODS = requestActions(
  MODULE_NAME,
  "GET_ALL_PAYMENT_METHODS"
);
export const GET_PAYMENT_METHOD = requestActions(
  MODULE_NAME,
  "GET_PAYMENT_METHOD"
);
export const CREATE_PAYMENT_METHOD = requestActions(
  MODULE_NAME,
  "CREATE_PAYMENT_METHOD"
);
export const EDIT_PAYMENT_METHOD = requestActions(
  MODULE_NAME,
  "EDIT_PAYMENT_METHOD"
);
export const DELETE_PAYMENT_METHOD = requestActions(
  MODULE_NAME,
  "DELETE_PAYMENT_METHOD"
);
export const WITHDRAW = requestActions(MODULE_NAME, "WITHDRAW");
export const DEPOSIT = requestActions(MODULE_NAME, "DEPOSIT");
export const GET_ALL_BANK_ACCOUNT_HISTORY = requestActions(
  MODULE_NAME,
  "GET_ALL_BANK_ACCOUNT_HISTORY"
);
export const GET_BANK_ACCOUNT_HISTORY = requestActions(
  MODULE_NAME,
  "GET_BANK_ACCOUNT_HISTORY"
);
export const RESET = `${MODULE_NAME}/RESET`;

export const getBankAccountHistory = (params) =>
  requestApiCall("getBankAccountHistory", params, GET_BANK_ACCOUNT_HISTORY);

export const getAllBankAccountsHistory = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "getAllBankAccountsHistory",
    { sortingParams, filterParam, pageNumber, pageSize },
    GET_ALL_BANK_ACCOUNT_HISTORY
  );

export const deposit = (params) => requestApiCall("deposit", params, DEPOSIT);

export const createBank = (params) =>
  requestApiCall("createBank", params, CREATE_BANK);

export const editBank = (params) =>
  requestApiCall("editBank", params, EDIT_BANK);

export const withdraw = (params) =>
  requestApiCall("withdraw", params, WITHDRAW);

export const deleteBank = (params) =>
  requestApiCall("deleteBank", params, DELETE_BANK);

export const getBank = ({ id }) => requestApiCall("getBank", { id }, GET_BANK);

export const getAllBanks = (params) =>
  requestApiCall("getAllBanks", params, GET_ALL_BANKS);

export const createBankAccount = (params) =>
  requestApiCall("createBankAccount", params, CREATE_BANK_ACCOUNT);
export const editBankAccount = (params) =>
  requestApiCall("editBankAccount", params, EDIT_BANK_ACCOUNT);

export const deleteBankAccount = (params) =>
  requestApiCall("deleteBankAccount", params, DELETE_BANK_ACCOUNT);

export const getBankAccount = ({ id }) =>
  requestApiCall("getBankAccount", { id }, GET_BANK_ACCOUNT);

export const getAllBankAccounts = (params) =>
  requestApiCall("getAllBankAccounts", params, GET_ALL_BANK_ACCOUNTS);

export const createPaymentMethod = (params) =>
  requestApiCall("createPaymentMethod", params, CREATE_PAYMENT_METHOD);

export const editPaymentMethod = (params) =>
  requestApiCall("editPaymentMethod", params, EDIT_PAYMENT_METHOD);

export const deletePaymentMethod = (params) =>
  requestApiCall("deletePaymentMethod", params, DELETE_PAYMENT_METHOD);

export const getPaymentMethod = ({ id }) =>
  requestApiCall("getPaymentMethod", { id }, GET_PAYMENT_METHOD);

export const getAllPaymentMethods = (params) =>
  requestApiCall("getAllPaymentMethods", params, GET_ALL_PAYMENT_METHODS);

export const reset = () => ({ type: RESET });

export const clearEditBank = () => ({ type: EDIT_BANK.CLEAR });

export const clearDeleteBank = () => ({ type: DELETE_BANK.CLEAR });

export const clearCreateBank = () => ({ type: CREATE_BANK.CLEAR });

export const clearEditBankAccount = () => ({ type: EDIT_BANK_ACCOUNT.CLEAR });

export const clearDeleteBankAccount = () => ({
  type: DELETE_BANK_ACCOUNT.CLEAR,
});

export const clearCreateBankAccount = () => ({
  type: CREATE_BANK_ACCOUNT.CLEAR,
});

export const clearEditPaymentMethod = () => ({
  type: EDIT_PAYMENT_METHOD.CLEAR,
});

export const clearDeletePaymentMethod = () => ({
  type: DELETE_PAYMENT_METHOD.CLEAR,
});

export const clearCreatePaymentMethod = () => ({
  type: CREATE_PAYMENT_METHOD.CLEAR,
});

export const clearWithdraw = () => ({
  type: WITHDRAW.CLEAR,
});

export const clearDeposit = () => ({
  type: DEPOSIT.CLEAR,
});
