import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { OrderConfirmed } from "../../../_metronic/layout/components/fareSearch/OrderConfirmed";
import { actions } from "../../../redux/state/booking";

export const OrderConfirmedPage = ({ steps, issueHoldBooking }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  });
  return <OrderConfirmed issueHoldBooking={issueHoldBooking} steps={steps} />;
};
