import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import SVG from "react-inlinesvg";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import {
  Button,
  Input,
  LoadingSpinner,
  PhoneInput,
  Select,
} from "../../common";
import {
  creditTypeOptions,
  getCitiesOptions,
  getCountriesOptions,
} from "../../../../_assets/js/components/options";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/company";
import { SubmitContainer } from "../../signUp/CompanyDetails";
import { ADMIN_FLYME_PROFILE_SETTINGS } from "../../../../../app/routesMapAdmin";
import { ORGANIZATION_TYPE_SUBSCRIBER } from "../../../../../redux/state/company/companyConstants";
import { getCompanySchema } from "../../common/schemas/companySchema";

export const ClientInfoForm = ({
  withSubmitContainer,
  outlined,
  isEdit,
  id,
  onSubmit,
  loading,
  initialValues,
  success,
  companyType,
}) => {
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const location = useLocation();
  const isFLymeSettings = location.pathname === ADMIN_FLYME_PROFILE_SETTINGS;
  const [editMode, setEditMode] = useState(!isEdit);
  const [prevLogo, setPrevLogo] = useState();
  const {
    data: companyData,
    success: getDataSuccess,
    loading: companyDataLoading,
  } = useSelector(({ company }) => company.specificCompanyData);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (isEdit) {
      dispatch(actions.getSpecificCompany({ id }));
    }
  }, [dispatch, id, isEdit]);
  const fields = useFormik({
    initialValues: {
      logo: "",
      registeredPhone: "",
      registeredPhoneCountryCode: "+252",
      mobileNumber: "", //bmMobileNumber
      bmCountryCode: "+252",
      emergencyContactPhone: "",
      emergencyCountryCode: "+252",
      country: "",
      city: "",
      address: "",
      name: "",
      businessManager: "",
      recoveryEmail: "",
      salesEmail: "",
      emergencyContactPerson: "",
      iata: "",
      creditContractType: "",
    },
    validationSchema: getCompanySchema(
      companyType ?? companyData?.organisationTypeId
    ),
    onSubmit: (values) => {
      const {
        registeredPhoneCountryCode,
        registeredPhone,
        bmCountryCode,
        emergencyCountryCode,
        emergencyContactPhone,
        mobileNumber,
        logo,
        creditContractType,
        ...rest
      } = values || {};
      const registeredPhoneFull = [
        registeredPhoneCountryCode,
        registeredPhone,
      ].join("-");
      const bmMobileNumberFull = [bmCountryCode, mobileNumber].join("-");
      const emergencyPhoneFull = [
        emergencyCountryCode,
        emergencyContactPhone,
      ].join("-");
      const params = {
        ...rest,
        logo: logo || prevLogo,
        registeredPhone: registeredPhoneFull,
        mobileNumber: bmMobileNumberFull,
        emergencyContactPhone: emergencyPhoneFull,
        creditContractType:
          creditContractType || companyData?.creditContractType,
        // isActive: false,
        // organisationTypeId: ORGANIZATION_TYPE_AGENCY,
      };

      onSubmit(params);
    },
  });
  const {
    getFieldProps,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleSubmit,
  } = fields;
  const setInitialValues = useCallback(() => {
    const {
      name,
      address,
      businessManager,
      mobileNumber,
      registeredPhone,
      // currency,
      logo,
      // businessLicense,
      city,
      country,
      salesEmail,
      emergencyContactPerson,
      emergencyContactPhone,
      recoveryEmail,
      iata,
      creditContractType,
    } = companyData || initialValues;
    const registeredPhoneFull = registeredPhone?.split("-");
    const bmMobileNumberFull = mobileNumber?.split("-");
    const emergencyPhoneFull = emergencyContactPhone?.split("-");
    setPrevLogo(logo);
    setValues({
      creditContractType: creditContractType || "",
      name: name || "",
      address: address || "",
      businessManager: businessManager || "",
      // currency: currency || "",
      logo: "",
      // businessLicense,
      recoveryEmail: recoveryEmail || "",
      city: city || "",
      country: country || "",
      salesEmail: salesEmail || "",
      emergencyContactPerson: emergencyContactPerson || "",
      iata: iata || "",
      registeredPhone: registeredPhoneFull?.[1] || "",
      registeredPhoneCountryCode: registeredPhoneFull?.[0] || "+252",
      mobileNumber: bmMobileNumberFull?.[1] || "",
      bmCountryCode: bmMobileNumberFull?.[0] || "+252",
      emergencyContactPhone: emergencyPhoneFull?.[1] || "",
      emergencyCountryCode: emergencyPhoneFull?.[0] || "+252",
    });
  }, [companyData, initialValues, setValues]);
  useEffect(() => {
    if ((getDataSuccess && isEdit) || !!initialValues) {
      setInitialValues();
    }
  }, [getDataSuccess, initialValues, isEdit, setInitialValues]);
  useEffect(() => {
    if (success) {
      setEditMode(false);
    }
  }, [success]);
  const { logo } = values;
  const onCancel = () => {
    setEditMode(false);
    setInitialValues();
  };
  return (
    <>
      {companyDataLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                name="name"
                showErrors={touched["name"]}
                errorMessage={errors["name"]}
                {...getFieldProps("name")}
                label={
                  companyType === ORGANIZATION_TYPE_SUBSCRIBER
                    ? "Full Name"
                    : "Company Name"
                }
              />
            </Grid>

            {companyType !== ORGANIZATION_TYPE_SUBSCRIBER && (
              <Grid item xs={12} md={6}>
                <Input
                  disabled={!editMode}
                  required
                  outlined={outlined}
                  variant="secondary"
                  name="businessManager"
                  showErrors={touched["businessManager"]}
                  errorMessage={errors["businessManager"]}
                  {...getFieldProps("businessManager")}
                  label="Business Manager"
                />
              </Grid>
            )}
            {companyType !== ORGANIZATION_TYPE_SUBSCRIBER && (
              <Grid item xs={12} md={6}>
                <PhoneInput
                  disabled={!editMode}
                  required
                  outlined={outlined}
                  variant="secondary"
                  label="BM Mobile Phone"
                  showErrorsCode={touched.bmCountryCode}
                  errorMessageCode={errors.bmCountryCode}
                  valueCode={values.bmCountryCode}
                  onChangeCode={(value) =>
                    setFieldValue("bmCountryCode", value)
                  }
                  showErrorsPhone={touched.mobileNumber}
                  errorMessagePhone={errors.mobileNumber}
                  fieldPropsPhone={{ ...getFieldProps("mobileNumber") }}
                />
              </Grid>
            )}
            {/* <Grid item xs={12} md={6}>
              <Input
                disabled={!editMode}
              required
                outlined={outlined}
                variant="secondary"
                showErrors={touched["registeredEmail"]}
                errorMessage={errors["registeredEmail"]}
                {...getFieldProps("registeredEmail")}
                name="registeredEmail"
                label="Registered Email"
              />
            </Grid> */}
            {companyType !== ORGANIZATION_TYPE_SUBSCRIBER && (
              <Grid item xs={12} md={6}>
                <Input
                  disabled={!editMode}
                  required
                  outlined={outlined}
                  variant="secondary"
                  name="salesEmail"
                  showErrors={touched["salesEmail"]}
                  errorMessage={errors["salesEmail"]}
                  {...getFieldProps("salesEmail")}
                  label="Sales Email"
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Input
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                name="recoveryEmail"
                showErrors={touched["recoveryEmail"]}
                errorMessage={errors["recoveryEmail"]}
                {...getFieldProps("recoveryEmail")}
                label="recovery email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                label="Registered Phone"
                showErrorsCode={touched.registeredPhoneCountryCode}
                errorMessageCode={errors.registeredPhoneCountryCode}
                valueCode={values.registeredPhoneCountryCode}
                onChangeCode={(value) =>
                  setFieldValue("registeredPhoneCountryCode", value)
                }
                showErrorsPhone={touched.registeredPhone}
                errorMessagePhone={errors.registeredPhone}
                fieldPropsPhone={{ ...getFieldProps("registeredPhone") }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                name="country"
                showErrors={touched["country"]}
                errorMessage={errors["country"]}
                label="Country"
                options={getCountriesOptions()}
                onChange={(value) => setFieldValue("country", value)}
                value={values.country}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                required
                outlined={outlined}
                variant="secondary"
                name="city"
                showErrors={touched["city"]}
                errorMessage={errors["city"]}
                disabled={values.country === "" || !editMode}
                options={getCitiesOptions(values.country)}
                onChange={(value) => setFieldValue("city", value)}
                value={values.city}
                label="City"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                name="address"
                showErrors={touched["address"]}
                errorMessage={errors["address"]}
                {...getFieldProps("address")}
                label="Company Address"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                name="emergencyContactPerson"
                showErrors={touched["emergencyContactPerson"]}
                errorMessage={errors["emergencyContactPerson"]}
                {...getFieldProps("emergencyContactPerson")}
                label="Emergency contact Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                disabled={!editMode}
                required
                outlined={outlined}
                variant="secondary"
                label="Emergency contact Phone"
                showErrorsCode={touched.emergencyCountryCode}
                errorMessageCode={errors.emergencyCountryCode}
                valueCode={values.emergencyCountryCode}
                onChangeCode={(value) =>
                  setFieldValue("emergencyCountryCode", value)
                }
                showErrorsPhone={touched.emergencyContactPhone}
                errorMessagePhone={errors.emergencyContactPhone}
                fieldPropsPhone={{ ...getFieldProps("emergencyContactPhone") }}
              />
            </Grid>
            {companyType !== ORGANIZATION_TYPE_SUBSCRIBER && (
              <Grid item xs={12} md={6}>
                <Input
                  disabled={!editMode}
                  outlined={outlined}
                  variant="secondary"
                  name="iata"
                  showErrors={touched["iata"]}
                  errorMessage={errors["iata"]}
                  {...getFieldProps("iata")}
                  label="IATA Number"
                />
              </Grid>
            )}
            {isAdmin && !isFLymeSettings && (
              <Grid item xs={12} md={6}>
                <Select
                  disabled={!editMode}
                  outlined={outlined}
                  variant="secondary"
                  name="creditContractType"
                  showErrors={touched["creditContractType"]}
                  errorMessage={errors["creditContractType"]}
                  onChange={(value) =>
                    setFieldValue("creditContractType", value)
                  }
                  options={creditTypeOptions}
                  value={values.creditContractType}
                  label="Credit Agreement Type"
                />
              </Grid>
            )}
            {!withSubmitContainer && matchesMd && (
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start flex-column ">
                  <span className="min-w-150px mb-2">Upload Logo</span>
                  <label
                    className={clsx("btn btn-primary py-auto w-100 h-38px", {
                      disabled: !editMode,
                    })}
                    type="button"
                  >
                    Change Logo
                    <input
                      disabled={!editMode}
                      onChange={(e) => setFieldValue("logo", e.target.files[0])}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                  </label>
                  {/* <span className="ml-5 text-muted">
              (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
            </span> */}
                </div>
                <div className="d-flex mb-6">
                  {touched.logo && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.logo}</div>
                    </div>
                  )}
                  {logo && (
                    <div className="w-350px">
                      <div className="d-flex w-100 mb-1 align-items-center">
                        {/* <SVG src="/media/svg/icons/General/valid.svg" /> */}
                        <Typography className="ml-3" variant="body1">
                          {logo?.name}
                        </Typography>
                        <button
                          type="button"
                          onClick={() => setFieldValue("logo", "")}
                          className="btn btn-icon ml-auto"
                        >
                          <SVG src="/media/svg/icons/General/cancel.svg" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
          {withSubmitContainer && (
            <SubmitContainer firstStep loading={loading} />
          )}
          {!withSubmitContainer && isEdit && (
            <div className="d-flex justify-content-center mt-5">
              {!editMode ? (
                <button
                  type="button"
                  onClick={() => {
                    setEditMode(true);
                  }}
                  className="btn btn-primary w-100px"
                >
                  Edit
                </button>
              ) : (
                <>
                  <Button
                    onClick={onCancel}
                    className="mr-3"
                    type="button"
                    variant="light"
                  >
                    Cancel
                  </Button>
                  <Button loading={loading} type="submit">
                    Save
                  </Button>
                </>
              )}
            </div>
          )}
          {!withSubmitContainer && !isEdit && (
            <div className="d-flex justify-content-center mt-5">
              <Button loading={loading} type="submit">
                Create
              </Button>
            </div>
          )}
        </form>
      )}
    </>
  );
};
