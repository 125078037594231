import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./banksActions";

const initialState = {
  allBanks: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  bank: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createBank: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editBank: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deleteBank: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allBankAccounts: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allBankAccountsHistory: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  bankAccount: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createBankAccount: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editBankAccount: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deleteBankAccount: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  allPaymentMethods: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  PaymentMethod: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createPaymentMethod: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editPaymentMethod: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deletePaymentMethod: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  withdraw: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  deposit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  bankAccountHistory: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const banksReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_BANK.REQUESTED:
        state.bank.loading = true;
        break;
      case actions.GET_BANK.SUCCEEDED:
        state.bank.loading = false;
        state.bank.success = true;
        state.bank.data = payload.data;
        break;
      case actions.GET_BANK.FAILED:
        state.bank.loading = false;
        state.bank.failure = payload.error;
        break;
      case actions.GET_BANK_ACCOUNT_HISTORY.REQUESTED:
        state.bankAccountHistory.loading = true;
        break;
      case actions.GET_BANK_ACCOUNT_HISTORY.SUCCEEDED:
        state.bankAccountHistory.loading = false;
        state.bankAccountHistory.success = true;
        state.bankAccountHistory.list = payload.data.data;
        break;
      case actions.GET_BANK_ACCOUNT_HISTORY.FAILED:
        state.bankAccountHistory.loading = false;
        state.bankAccountHistory.failure = payload.error;
        break;
      case actions.GET_ALL_BANKS.REQUESTED:
        state.allBanks.loading = true;
        break;
      case actions.GET_ALL_BANKS.SUCCEEDED:
        state.allBanks.loading = false;
        state.allBanks.success = true;
        state.allBanks.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_BANKS.FAILED:
        state.allBanks.loading = false;
        state.allBanks.failure = payload.error;
        break;
      case actions.CREATE_BANK.REQUESTED:
        state.createBank.loading = true;
        break;
      case actions.CREATE_BANK.SUCCEEDED:
        state.createBank.loading = false;
        state.createBank.success = true;
        state.allBanks.list = [payload.data, ...state.allBanks.list];
        break;
      case actions.CREATE_BANK.FAILED:
        state.createBank.loading = false;
        state.createBank.failure = payload.error;
        break;
      case actions.EDIT_BANK.REQUESTED:
        state.editBank.loading = true;
        break;
      case actions.EDIT_BANK.SUCCEEDED:
        state.editBank.loading = false;
        state.editBank.success = true;
        break;
      case actions.EDIT_BANK.FAILED:
        state.editBank.loading = false;
        state.editBank.failure = payload.error;
        break;
      case actions.EDIT_BANK.CLEAR:
        state.editBank = initialState.editBank;
        break;
      case actions.DELETE_BANK.REQUESTED:
        state.deleteBank.loading = true;
        break;
      case actions.DELETE_BANK.SUCCEEDED:
        state.deleteBank.loading = false;
        state.deleteBank.success = true;
        state.allBanks.list = state.allBanks.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_BANK.FAILED:
        state.deleteBank.loading = false;
        state.deleteBank.failure = payload.error;
        break;
      case actions.DELETE_BANK.CLEAR:
        state.deleteBank = initialState.deleteBank;
        break;
      case actions.GET_BANK_ACCOUNT.REQUESTED:
        state.bankAccount.loading = true;
        break;
      case actions.GET_BANK_ACCOUNT.SUCCEEDED:
        state.bankAccount.loading = false;
        state.bankAccount.success = true;
        state.bankAccount.data = payload.data.data;
        break;
      case actions.GET_BANK_ACCOUNT.FAILED:
        state.bankAccount.loading = false;
        state.bankAccount.failure = payload.error;
        break;
      case actions.GET_ALL_BANK_ACCOUNT_HISTORY.REQUESTED:
        state.bankAccountHistory.loading = true;
        break;
      case actions.GET_ALL_BANK_ACCOUNT_HISTORY.SUCCEEDED:
        state.bankAccountHistory.loading = false;
        state.bankAccountHistory.success = true;
        const { data, ...rest } = payload.data.data?.filtered;
        state.bankAccountHistory.list = data;
        state.bankAccountHistory.stats = payload.data.data?.total;
        state.bankAccountHistory.paginationInfo = rest;
        break;
      case actions.GET_ALL_BANK_ACCOUNT_HISTORY.FAILED:
        state.bankAccountHistory.loading = false;
        state.bankAccountHistory.failure = payload.error;
        break;
      case actions.GET_ALL_BANK_ACCOUNTS.REQUESTED:
        state.allBankAccounts.loading = true;
        break;
      case actions.GET_ALL_BANK_ACCOUNTS.SUCCEEDED:
        state.allBankAccounts.loading = false;
        state.allBankAccounts.success = true;
        state.allBankAccounts.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_BANK_ACCOUNTS.FAILED:
        state.allBankAccounts.loading = false;
        state.allBankAccounts.failure = payload.error;
        break;
      case actions.CREATE_BANK_ACCOUNT.REQUESTED:
        state.createBankAccount.loading = true;
        break;
      case actions.CREATE_BANK_ACCOUNT.SUCCEEDED:
        state.createBankAccount.loading = false;
        state.createBankAccount.success = true;
        state.allBankAccounts.list = [
          payload.data,
          ...state.allBankAccounts.list,
        ];
        break;
      case actions.CREATE_BANK_ACCOUNT.FAILED:
        state.createBankAccount.loading = false;
        state.createBankAccount.failure = payload.error;
        break;
      case actions.WITHDRAW.REQUESTED:
        state.withdraw.loading = true;
        break;
      case actions.WITHDRAW.SUCCEEDED:
        state.withdraw.loading = false;
        state.withdraw.success = true;
        break;
      case actions.WITHDRAW.FAILED:
        state.withdraw.loading = false;
        state.withdraw.failure = payload.error;
        break;
      case actions.WITHDRAW.CLEAR:
        state.withdraw = initialState.withdraw;
        break;
      case actions.DEPOSIT.REQUESTED:
        state.deposit.loading = true;
        break;
      case actions.DEPOSIT.SUCCEEDED:
        state.deposit.loading = false;
        state.deposit.success = true;
        break;
      case actions.DEPOSIT.FAILED:
        state.deposit.loading = false;
        state.deposit.failure = payload.error;
        break;
      case actions.DEPOSIT.CLEAR:
        state.deposit = initialState.deposit;
        break;

      case actions.EDIT_BANK_ACCOUNT.REQUESTED:
        state.editBankAccount.loading = true;
        break;
      case actions.EDIT_BANK_ACCOUNT.SUCCEEDED:
        state.editBankAccount.loading = false;
        state.editBankAccount.success = true;
        break;
      case actions.EDIT_BANK_ACCOUNT.FAILED:
        state.editBankAccount.loading = false;
        state.editBankAccount.failure = payload.error;
        break;
      case actions.EDIT_BANK_ACCOUNT.CLEAR:
        state.editBankAccount = initialState.editBankAccount;
        break;
      case actions.DELETE_BANK_ACCOUNT.REQUESTED:
        state.deleteBankAccount.loading = true;
        break;
      case actions.DELETE_BANK_ACCOUNT.SUCCEEDED:
        state.deleteBankAccount.loading = false;
        state.deleteBankAccount.success = true;
        state.allBankAccounts.list = state.allBankAccounts.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_BANK_ACCOUNT.FAILED:
        state.deleteBankAccount.loading = false;
        state.deleteBankAccount.failure = payload.error;
        break;
      case actions.DELETE_BANK_ACCOUNT.CLEAR:
        state.deleteBankAccount = initialState.deleteBankAccount;
        break;
      case actions.GET_ALL_PAYMENT_METHODS.REQUESTED:
        state.allPaymentMethods.loading = true;
        break;
      case actions.GET_ALL_PAYMENT_METHODS.SUCCEEDED:
        state.allPaymentMethods.loading = false;
        state.allPaymentMethods.success = true;
        state.allPaymentMethods.list = payload.data.data.reverse();
        break;
      case actions.GET_ALL_PAYMENT_METHODS.FAILED:
        state.allPaymentMethods.loading = false;
        state.allPaymentMethods.failure = payload.error;
        break;
      case actions.CREATE_PAYMENT_METHOD.REQUESTED:
        state.createPaymentMethod.loading = true;
        break;
      case actions.CREATE_PAYMENT_METHOD.SUCCEEDED:
        state.createPaymentMethod.loading = false;
        state.createPaymentMethod.success = true;
        state.allPaymentMethods.list = [
          payload.data,
          ...state.allPaymentMethods.list,
        ];
        break;
      case actions.CREATE_PAYMENT_METHOD.FAILED:
        state.createPaymentMethod.loading = false;
        state.createPaymentMethod.failure = payload.error;
        break;
      case actions.EDIT_PAYMENT_METHOD.REQUESTED:
        state.editPaymentMethod.loading = true;
        break;
      case actions.EDIT_PAYMENT_METHOD.SUCCEEDED:
        state.editPaymentMethod.loading = false;
        state.editPaymentMethod.success = true;
        break;
      case actions.EDIT_PAYMENT_METHOD.FAILED:
        state.editPaymentMethod.loading = false;
        state.editPaymentMethod.failure = payload.error;
        break;
      case actions.EDIT_PAYMENT_METHOD.CLEAR:
        state.editPaymentMethod = initialState.editPaymentMethod;
        break;
      case actions.DELETE_PAYMENT_METHOD.REQUESTED:
        state.deletePaymentMethod.loading = true;
        break;
      case actions.DELETE_PAYMENT_METHOD.SUCCEEDED:
        state.deletePaymentMethod.loading = false;
        state.deletePaymentMethod.success = true;
        state.allPaymentMethods.list = state.allPaymentMethods.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_PAYMENT_METHOD.FAILED:
        state.deletePaymentMethod.loading = false;
        state.deletePaymentMethod.failure = payload.error;
        break;
      case actions.DELETE_PAYMENT_METHOD.CLEAR:
        state.deletePaymentMethod = initialState.deletePaymentMethod;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
