import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";

import useSuppliersOptions from "../../../../../../app/helpers/hooks/useSuppliersOptions";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { Button, Input, Select } from "../../../common";
// import { DateRangeModal } from "./DateRangeModal";

export const getFilterObject = (columnName, value, option) => [
  ...(value !== ""
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

const invoiceStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const SupplierSearchCard = ({
  setDateRange,
  onSearch,
  onReset,
  dateFilterKey = "createdAt",
}) => {
  const [showModal, setShowModal] = useState(null);
  const { supplierOptions } = useSuppliersOptions();

  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      name: "",
      supplier: "",
      isActive: "",
    },
    enableReinitialize: true,
    // validationSchema: Yup.object().shape({}),
    onSubmit: ({ supplier, name, isActive }) => {
      // setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject("name", name, filterOptions.Contains),
        // ...getFilterObject("createdBy", user, filterOptions.IsEqualTo),
        ...getFilterObject("code", supplier, filterOptions.IsEqualTo),
        ...getFilterObject("isActive", isActive, filterOptions.IsEqualTo),
        // ...getFilterObject(
        //   dateFilterKey,
        //   `${fromDate}`,
        //   filterOptions.IsGreaterThanOrEqualTo
        // ),
        // ...getFilterObject(
        //   dateFilterKey,
        //   `${toDate}`,
        //   filterOptions.IsLessThanOrEqualTo
        // ),
      ]);
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    values,
    setValues,
    touched,
    errors,
    getFieldProps,
  } = fields;
  const handleReset = () => {
    setValues({
      name: "",
      supplier: "",
      isActive: "",
    });
    onReset();
    // setDateRange({ fromDate: "", toDate: "" });
  };
  // "filterParam": [
  //   {
  //     "columnName": "createdAt",
  //     "filterValue": "2022-01-06",
  //     "filterOption": 8
  //   },
  //    {
  //     "columnName": "agencyId",
  //     "filterValue": "13",
  //     "filterOption": 11
  //   }
  // ]
  return (
    <form onSubmit={handleSubmit}>
      {/* <DateRangeModal fields={fields} show={showModal} onHide={handleClose} /> */}
      <Grid justify="center" container spacing={2}>
        {/* <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.bookingId}
            errorMessage={errors.bookingId}
            {...getFieldProps("bookingId")}
            placeholder="Invoice To"
          />
        </Grid> */}
        <Grid item xs={6} md={2}>
          <Input
            showErrors={touched.name}
            errorMessage={errors.name}
            {...getFieldProps("name")}
            placeholder="Name"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.isActive}
            onChange={(v) => setFieldValue("isActive", v)}
            placeholder="All Status"
            options={[
              { value: "", label: "All Status" },
              ...(invoiceStatusOptions || []),
            ]}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.supplier}
            onChange={(v) => setFieldValue("supplier", v)}
            placeholder="All Suppliers"
            options={[
              { value: "", label: "All Suppliers" },
              ...(supplierOptions || []),
            ]}
          />
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid> */}
        <Grid item xs>
          <div className="d-flex justify-content-center">
            <Button type="submit">Search</Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
