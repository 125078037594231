import { client } from "./client";

export const getAccountBalance = ({ companyId }) =>
  client.get(`/api/accountBalance/company/${companyId}`);

export const updateCompanyBalance = ({
  companyId,
  updatedBy,
  updatedBalance,
  reason,
}) =>
  client.post("/api/accountBalance/updateBalanceByAdmin", {
    companyId,
    updatedBy,
    updatedBalance,
    reason,
  });

export const getAllAccountBalance = () => client.get("/api/accountBalance");

export const getTransactionsHistory = ({ companyId }) =>
  client.get(`/api/accountBalance/company/${companyId}/transationhistory`);

export const getAllTransactionsHistory = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post(`/api/accountBalance/transationhistory/filters`, {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber,
    pageSize,
  });
