import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Support } from "../../../_metronic/layout/components/helpCenter";

export const SupportPage = () => {
  const accountSettingsSchema = Yup.object().shape({
    department: Yup.string().required(),
    email: Yup.string().required(),
    msg: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      department: "",
      msg: "",
    },
    validationSchema: accountSettingsSchema,
    onSubmit: () => formik.resetForm(),
  });
  return <Support formData={formik} />;
};
