import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { SIGN_IN } from "../../../../app/routesMap";
import { Input } from "../common";
import { SubmitContainer } from "./CompanyDetails";
import { LeftBar } from "./LeftBar";
import { ORGANIZATION_TYPE_SUBSCRIBER } from "../../../../redux/state/company/companyConstants";

export const LoginDetails = ({
  loading,
  routesMap,
  formik,
  registrationType,
}) => {
  return (
    <LeftBar routesMap={routesMap} havingIssues>
      <div className="d-flex flex-column justify-content-around w-100">
        <div className="mb-6">
          <Typography className="font-weight-bold mb-1" variant="h6">
            Create Account
          </Typography>
          <Typography variant="body1" className="text-muted" noWrap>
            Already have an Account?
            <Link className="text-primary text-weight-bold" to={SIGN_IN}>
              Sign In
            </Link>
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            {registrationType !== ORGANIZATION_TYPE_SUBSCRIBER && (
              <Input
                spacing
                variant="secondary"
                outlined={false}
                label="Name"
                showErrors={formik.touched.name}
                errorMessage={formik.errors.name}
                inputProps={formik.getFieldProps("name")}
                required
              />
            )}
            <Input
              spacing
              variant="secondary"
              outlined={false}
              type="email"
              label="Email"
              showErrors={formik.touched.email}
              errorMessage={formik.errors.email}
              inputProps={formik.getFieldProps("email")}
              required
            />
            <Grid item xs={12}>
              <Input
                spacing
                variant="secondary"
                outlined={false}
                type="password"
                label="Password"
                showErrors={formik.touched.password}
                errorMessage={formik.errors.password}
                inputProps={formik.getFieldProps("password")}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                spacing
                variant="secondary"
                outlined={false}
                type="password"
                label="Confirm Password"
                showErrors={formik.touched.confirmPassword}
                errorMessage={formik.errors.confirmPassword}
                inputProps={formik.getFieldProps("confirmPassword")}
                required
              />
            </Grid>
          </Grid>
          <SubmitContainer loading={loading} />
        </form>
      </div>
    </LeftBar>
  );
};
