import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { AcceptReissue } from "../../../_metronic/layout/components/admin/bookingMng/reissue/AcceptReissue";
import { ReissueDetails } from "../../../_metronic/layout/components/admin/bookingMng/reissue/ReissueDetails";

import {
  IssueHoldBooking,
  ReissueTracking,
} from "../../../_metronic/layout/components/bookingManagement";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  BOOKING_MANAGEMENT_REISSUE,
  BOOKING_MANAGEMENT_REISSUE_DETAILS,
  BOOKING_MANAGEMENT_REISSUE_PAY,
} from "../../routesMap";
import { BOOKING_MANAGEMENT_REISSUE_ACCEPT } from "../../routesMapAdmin";

export const ReissuePage = ({ permissionKey, permissionId }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  return (
    <Switch>
      {isAdmin && (
        <Switch>
          <ContentRoute
            permissionKey={generateNestedObjectKey([
              permissionKey,
              "subPermissions.view",
            ])}
            exact
            path={BOOKING_MANAGEMENT_REISSUE_DETAILS}
            render={(props) => (
              <ReissueDetails {...props} permissionId={permissionId} />
            )}
          />
          <ContentRoute
            permissionKey={generateNestedObjectKey([
              permissionKey,
              "subPermissions.actions",
            ])}
            exact
            path={BOOKING_MANAGEMENT_REISSUE_ACCEPT}
            component={AcceptReissue}
          />
          <Route
            exact
            path={BOOKING_MANAGEMENT_REISSUE}
            component={ReissueTracking}
          />
        </Switch>
      )}

      {!isAdmin && (
        <Switch>
          <Route
            exact
            path={BOOKING_MANAGEMENT_REISSUE_DETAILS}
            component={ReissueDetails}
          />
          <Route
            exact
            path={BOOKING_MANAGEMENT_REISSUE_PAY}
            render={() => <IssueHoldBooking isReissue />}
          />
          <Route
            exact
            path={BOOKING_MANAGEMENT_REISSUE}
            component={ReissueTracking}
          />
        </Switch>
      )}
    </Switch>
  );
};
