import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../../../_helpers";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { Input, Button, Select } from "../../../common";
import { getFilterObject } from "../../reports/common/reportsSearchCard/ReportsSearchCard";
import { DateRangeModal } from "../../reports/common/reportsSearchCard/DateRangeModal";
import useSuppliersOptions from "../../../../../../app/helpers/hooks/useSuppliersOptions";

export const SupplierRefundSearchCard = ({
  setDateRange,
  onSearch,
  onReset,
}) => {
  const [showModal, setShowModal] = useState(null);
  const { supplierOptions } = useSuppliersOptions(2);
  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      agency: Yup.string(),
      supplier: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      user: Yup.string(),
    }),
    onSubmit: ({ fromDate, supplier, toDate, bookingId }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject(
          "bookingTransactionId",
          bookingId,
          filterOptions.Contains
        ),
        ...getFilterObject("supplierId", supplier, filterOptions.IsEqualTo),
        ...getFilterObject(
          "CreatedAt",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "CreatedAt",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    values,
    getFieldProps,
    setValues,
  } = fields;
  const handleReset = () => {
    setValues({
      agency: "",
      supplier: "",
      fromDate: "",
      toDate: "",
      user: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };
  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={2}>
          <Input {...getFieldProps("bookingId")} placeholder="Booking Id" />
        </Grid>
        <Grid item xs={6} md={2}>
          <Select
            value={values.supplier}
            onChange={(v) => setFieldValue("supplier", v)}
            placeholder="All Suppliers"
            options={[
              { value: "", label: "All Suppliers" },
              ...(supplierOptions || []),
            ]}
          />
        </Grid>
        {/* <Grid item xs={6} md={2}>
          <Select
            value={values.user}
            onChange={(v) => setFieldValue("user", v)}
            options={[
              { value: "", label: "All Users" },
              ...(usersOptions || []),
            ]}
            placeholder="User"
          />
        </Grid> */}
        {/* <Grid item xs={6} md={2}>
          <Select
            value={values.agency}
            onChange={(v) => setFieldValue("agency", v)}
            options={[
              { value: "", label: "All Agencies" },
              ...(agencyOptions || []),
            ]}
            placeholder="Agency"
          />
        </Grid> */}
        <Grid item xs>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs>
          <div className="d-flex">
            <Button type="submit">Search</Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
