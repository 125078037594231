import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";
import { useSelector } from "react-redux";

import useAgenciesOptions from "../../../../../app/helpers/hooks/useAgenciesOptions";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Button, Select } from "../../common";
import { DateRangeModal } from "../../admin/reports/common/reportsSearchCard/DateRangeModal";
import { filterOptions } from "../../../../../redux/state/invoices/invoicesConstants";

const getFilterObject = (columnName, value, option) => [
  ...(value !== ""
    ? [
        {
          columnName: columnName,
          filterValue: `${value}`,
          filterOption: option,
        },
      ]
    : []),
];

const StatusOptions = [
  { label: "Pending", value: "Pending" },
  { label: "Rejected", value: "Failed" },
  { label: "Approved", value: "Approved" },
];

export const CreditSearchCard = ({
  setDateRange,
  onSearch,
  isAll,
  onReset,
}) => {
  const [showModal, setShowModal] = useState(null);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { agencyOptions } = useAgenciesOptions();
  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const fields = useFormik({
    initialValues: {
      companyId: "",
      fromDate: "",
      toDate: "",
      status: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      companyId: Yup.string(),
      bankAccountId: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      status: Yup.string(),
    }),
    onSubmit: ({ fromDate, toDate, status, companyId }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject("agencyId", `${companyId}`, filterOptions.IsEqualTo),
        ...getFilterObject("status", status, filterOptions.IsEqualTo),
        ...getFilterObject(
          "createdAt",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "createdAt",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const { handleSubmit, setFieldValue, values, setValues } = fields;
  const handleReset = () => {
    setValues({
      companyId: "",
      fromDate: "",
      toDate: "",
      status: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };

  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={2}>
          <Select
            value={values.status}
            onChange={(v) => setFieldValue("status", v)}
            placeholder="All Status"
            options={[
              { value: "", label: "All Status" },
              ...(StatusOptions || []),
            ]}
          />
        </Grid>
        {isAdmin && (
          <Grid item xs={6} md={2}>
            <Select
              value={values.companyId}
              onChange={(v) => setFieldValue("companyId", v)}
              options={[
                { value: "", label: "All Agencies" },
                ...(agencyOptions || []),
              ]}
              placeholder="companyId"
            />
          </Grid>
        )}
        <Grid item xs>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs className="d-flex">
          <Button className="ml-auto" type="submit">
            Search
          </Button>
          <Button
            onClick={handleReset}
            className="ml-3"
            type="button"
            variant="primary-inverse"
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
