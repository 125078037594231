import React from "react";
import { Grid } from "@material-ui/core";
import { CompanySettings } from "../../admin/common/companySettings/CompanySettings";
import { useSelector } from "react-redux";

export const AgencySettings = () => {
  const { id } = useSelector(({ company }) => company.companyData.data);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CompanySettings isCurrent title="Edit Company Info" id={id} />
      </Grid>
    </Grid>
  );
};
