import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/package";

export default function usePackageSeasonsList() {
  const dispatch = useDispatch();
  const { list, loading, success, failure } = useSelector(
    ({ packages }) => packages.allPkgSeasons
  );
  useEffect(() => {
    if (!list && !loading) {
      dispatch(actions.getAllPkgSeasons());
    }
  }, [dispatch, list, loading]);
  return { list, loading, success, failure };
}
