import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./communicationConstants";

export const GET_COMPANY_EMAILS = requestActions(
  MODULE_NAME,
  "GET_COMPANY_EMAILS"
);
export const SET_COMPANY_EMAILS = requestActions(
  MODULE_NAME,
  "SET_COMPANY_EMAILS"
);
export const UPDATE_COMPANY_EMAILS = requestActions(
  MODULE_NAME,
  "UPDATE_COMPANY_EMAILS"
);
export const GET_ALL_COMMUNICATION_TYPES = requestActions(
  MODULE_NAME,
  "GET_ALL_COMMUNICATION_TYPES"
);
export const UPDATE_NOTIFICATION_STATUS = requestActions(
  MODULE_NAME,
  "UPDATE_NOTIFICATION_STATUS"
);
export const GET_SPECIFIC_NOTIFICATION = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_NOTIFICATION"
);
export const RESET_NOTIFICATION_COUNT = requestActions(
  MODULE_NAME,
  "RESET_NOTIFICATION_COUNT"
);
export const RESET = `${MODULE_NAME}/RESET`;
export const SET_NOTIFICATION = `${MODULE_NAME}/SET_NOTIFICATION`;

export const resetNotificationCount = (params) =>
  requestApiCall("resetNotificationCount", params, RESET_NOTIFICATION_COUNT);

export const updateNotificationStatus = (params) =>
  requestApiCall(
    "updateNotificationStatus",
    params,
    UPDATE_NOTIFICATION_STATUS
  );

export const getSpecificNotification = (params) =>
  requestApiCall("getSpecificNotification", params, GET_SPECIFIC_NOTIFICATION);

export const getAllCommunicationTypes = (params) =>
  requestApiCall(
    "getAllCommunicationTypes",
    params,
    GET_ALL_COMMUNICATION_TYPES
  );

export const getEmails = (params) =>
  requestApiCall("getEmails", params, GET_COMPANY_EMAILS);

export const setEmails = (params) =>
  requestApiCall("setEmails", params, SET_COMPANY_EMAILS);

export const updateEmails = (params) =>
  requestApiCall("updateEmails", params, UPDATE_COMPANY_EMAILS);

export const setNotifications = (payload) => ({
  type: SET_NOTIFICATION,
  payload,
});

export const reset = () => ({ type: RESET });

export const clearSetEmails = () => ({
  type: SET_COMPANY_EMAILS.CLEAR,
});

export const clearSpecificNotification = () => ({
  type: GET_SPECIFIC_NOTIFICATION.CLEAR,
});

export const clearUpdateNotificationStatus = () => ({
  type: UPDATE_NOTIFICATION_STATUS.CLEAR,
});
