import React, { useEffect, useMemo, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useParams } from "react-router";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import "react-quill/dist/quill.snow.css";

import { Input, Select } from "../../../../../_partials/controls";
import { Button } from "../../../common";
import { actions } from "../../../../../../redux/state/templates";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { actions as communicationActions } from "../../../../../../redux/state/communication";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { ADMIN_TEMPLATES } from "../../../../../../app/routesMapAdmin";

const CustomerEditSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subject: Yup.string().required("Subject is required"),
  body: Yup.string().required("Body No is required"),
  notificationTypeId: Yup.number().required("Category No is required"),
  footer: Yup.string().required("Footer No is required"),
});

const typeEditSchema = Yup.object().shape({
  type: Yup.string().required("Title is required"),
});

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  // classes.push("border");
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  if (!touched && !errors) {
    classes.push("border");
  }

  return classes.join(" ");
};

export const TemplateForm = ({ isEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [typeEditMode, setTypeEditMode] = useState(false);
  const [showEmailParams, setShowEmailParams] = useState(false);
  const { id } = useParams();
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ templates }) => templates.create);
  const { success: createTypeSuccess } = useSelector(
    ({ templates }) => templates.createType
  );
  const {
    loading: editLoading,
    success: editSuccess,
    failure: editFailure,
  } = useSelector(({ templates }) => templates.edit);
  const { list: templateTypesList } = useSelector(
    ({ templates }) => templates.templateTypes
  );
  const { list: emailParametersList } = useSelector(
    ({ templates }) => templates.emailParameters
  );
  const { data } = useSelector(({ templates }) => templates.specificTemplate);
  const { list: communicationTypesList } = useSelector(
    ({ communication }) => communication.communicationTypes
  );
  const templateParamsContent = Object.keys(emailParametersList || {})
    .filter((paramName) => paramName !== "$id")
    .map((paramName) => <li className="list-group-item">{paramName}</li>);
  useEffect(() => {
    dispatch(actions.getTemplateTypes());
    dispatch(actions.getEmailParameters());
    dispatch(communicationActions.getAllCommunicationTypes());
    if (isEdit) dispatch(actions.getSpecificTemplate({ id }));
    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (createTypeSuccess) {
      dispatch(actions.getTemplateTypes());
      dispatch(actions.clearCreateType());
    }
  }, [createTypeSuccess, dispatch]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Template was created successfully",
        })
      );
      dispatch(actions.reset());
      history.push(ADMIN_TEMPLATES);
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [createSuccess, dispatch, history]);
  useEffect(() => {
    if (createFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't create template",
        })
      );
      dispatch(actions.reset());
    }
  }, [createFailure, dispatch]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Template was edited successfully",
        })
      );
      dispatch(actions.reset());
      history.push(ADMIN_TEMPLATES);
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, editSuccess, history]);
  useEffect(() => {
    if (editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't edit template",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, editFailure]);
  const typesOptions = templateTypesList?.map(({ id, type }) => ({
    label: type,
    value: id,
  }));
  const communicationTypesOptions = useMemo(
    () =>
      communicationTypesList?.map(({ id, type }) => ({
        label: type,
        value: id,
      })),
    [communicationTypesList]
  );
  const loading = createLoading || editLoading;
  const { title, subject, body, notificationTypeId, footer } = data || {};
  const initialValues = {
    title: title || "",
    subject: subject || "",
    body: body || "",
    notificationTypeId: notificationTypeId || "",
    footer: footer || "",
  };
  const create = (params) => {
    dispatch(actions.createTemplate(params));
  };
  const edit = (params) => {
    dispatch(actions.editTemplate(params));
  };
  const getLabel = (value) => {
    // eslint-disable-next-line no-unused-expressions
    const { label } =
      typesOptions?.filter((option) => option.value === value)[0] || {};
    return label;
  };
  return (
    <Paper className="p-7">
      <Typography variant="h5" className="mb-5">
        {isEdit ? "Edit Template" : "Create New Template"}
      </Typography>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={({ title, subject, body, notificationTypeId, footer }) => {
          if (isEdit) {
            edit({
              ...data,
              title,
              subject,
              body,
              notificationTypeId: +notificationTypeId,
              footer,
            });
          } else {
            create({
              title,
              subject,
              body,
              notificationTypeId: +notificationTypeId,
              footer,
            });
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <div className="overlay overlay-block cursor-default">
              {loading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}

              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="title"
                      component={Input}
                      placeholder="Template Title"
                      label="Template Title"
                    />
                  </div>
                  <div className="col-lg-6 mt-auto">
                    <Dropdown
                      className="dropdown-inline w-100"
                      drop="down"
                      onSelect={(value) =>
                        setFieldValue("notificationTypeId", value)
                      }
                    >
                      <Dropdown.Toggle
                        className={clsx(
                          getFieldCSSClasses(
                            touched.notificationTypeId,
                            errors.notificationTypeId
                          ),
                          "btn btn-sm font-weight-bolder dropdown-toggle px-5 d-flex justify-content-left align-items-center"
                        )}
                        variant="transparent"
                        id="dropdown-toggle-top"
                      >
                        {values.notificationTypeId
                          ? getLabel(+values.notificationTypeId)
                          : "Template Type"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100 d-flex flex-column dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <div className="mx-3 ">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{ type: "", emailType: "" }}
                            validationSchema={typeEditSchema}
                            onSubmit={({ type, emailType }) => {
                              dispatch(
                                actions.createTemplateType({
                                  type,
                                  communicationTypeId: +emailType,
                                })
                              );
                            }}
                          >
                            {({ resetForm }) => (
                              <Form>
                                <div className="d-flex">
                                  <div>
                                    <Field
                                      name="type"
                                      component={Input}
                                      placeholder="Add new Template Type"
                                    />
                                  </div>
                                  <div className="ml-2">
                                    <Field
                                      name="emailType"
                                      component={Select}
                                      placeholder="Email Type"
                                    >
                                      <option value="" disabled>
                                        Select Email Type
                                      </option>
                                      {communicationTypesOptions.map(
                                        ({ label, value }) => (
                                          <option value={value} key={value}>
                                            {label}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                  </div>
                                  <button
                                    type="submit"
                                    className="btn btn-success btn-icon mt-auto mb-1 btn-sm mx-3 px-1"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-white">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Check.svg"
                                        )}
                                      />
                                    </span>
                                  </button>
                                  <button
                                    onClick={() => resetForm()}
                                    type="button"
                                    className="btn btn-danger btn-icon mt-auto mb-1 btn-sm px-1"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-white">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Close.svg"
                                        )}
                                      />
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                        <Button
                          type="button"
                          className="m-3 flex-grow"
                          onClick={() => setTypeEditMode(!typeEditMode)}
                        >
                          {typeEditMode ? "Close Edit" : "Edit"}
                        </Button>
                        <Dropdown.Divider />
                        {typeEditMode ? (
                          <ul className="navi navi-hover mx-3">
                            {typesOptions?.map(({ label, value }) => {
                              const templateType = templateTypesList?.filter(
                                ({ id }) => value === id
                              )[0];
                              return (
                                <li
                                  key={value}
                                  eventKey={value}
                                  className="d-flex justify-content-between"
                                  // onClick={() => setFieldValue("category", value)}
                                >
                                  <TypeForm
                                    label={label}
                                    value={value}
                                    templateType={templateType}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <>
                            {typesOptions?.map(({ label, value }) => (
                              <Dropdown.Item
                                key={value}
                                eventKey={value}
                                // onClick={() => setFieldValue("category", value)}
                              >
                                {label}
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="subject"
                      component={Input}
                      placeholder="Subject"
                      label="Subject"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="footer"
                      component={Input}
                      placeholder="Footer"
                      label="Footer"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12 position-relative">
                    <label>Body</label>
                    <ReactQuill
                      theme="snow"
                      value={values.body}
                      onChange={(value) => setFieldValue("body", value)}
                      placeholder="Write something..."
                      className="h-500px"
                    />
                    <div
                      className="position-absolute overflow-scroll max-h-500px"
                      style={{ top: "2.5rem", right: "3rem" }}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          setShowEmailParams((prevState) => !prevState)
                        }
                        className={clsx("btn text-hover-primary py-0 mb-4", {
                          "text-primary": showEmailParams,
                        })}
                      >
                        <Typography variant="h5" className="m-0">
                          Email Parameters
                        </Typography>
                      </button>
                      {showEmailParams && (
                        <ul className="list-group overflow-auto max-h-300px">
                          {templateParamsContent}
                        </ul>
                      )}
                    </div>
                    {/* <Field
                      name="body"
                      component={TextArea}
                      placeholder="Body"
                      label="Body"
                    /> */}
                  </div>
                </div>
              </Form>
            </div>
            <div className="d-flex justify-content-center mt-5">
              {/* <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button> */}
              <Button
                loading={loading}
                type="submit"
                onClick={() => handleSubmit()}
                // className="btn btn-primary btn-elevate"
              >
                {isEdit ? "Save" : "Create New Template"}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </Paper>
  );
};

const TypeForm = ({ label, value, templateType }) => {
  const dispatch = useDispatch();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ type: label, isEdit: false }}
      validationSchema={typeEditSchema}
      onSubmit={({ type }, { resetForm }) => {
        dispatch(
          actions.editTemplateType({
            ...templateType,
            type,
          })
        );
        resetForm();
      }}
    >
      {({ resetForm, setFieldValue, values: { isEdit } }) => (
        <Form className="form w-100">
          <div className="d-flex mb-3 w-100">
            <Field
              disabled={!isEdit}
              name="type"
              component={Input}
              placeholder="Add new Category"
            />
            {isEdit && (
              <>
                <button
                  type="submit"
                  className="btn btn-success btn-icon mt-auto mb-1 btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Check.svg"
                      )}
                    />
                  </span>
                </button>
                <button
                  onClick={() =>
                    resetForm({
                      type: label,
                      isEdit: false,
                    })
                  }
                  type="button"
                  className="btn btn-danger btn-icon mt-auto mb-1 btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Close.svg"
                      )}
                    />
                  </span>
                </button>
              </>
            )}
            {!isEdit && (
              <>
                <button
                  onClick={() => setFieldValue("isEdit", true)}
                  type="button"
                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                </button>
                <button
                  onClick={() =>
                    dispatch(
                      actions.deleteTemplateType({
                        id: value,
                      })
                    )
                  }
                  type="button"
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    />
                  </span>
                </button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
