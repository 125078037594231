import React from "react";
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Svg from "react-inlinesvg";
import { makeStyles } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../_helpers";
import { parseRoute } from "../../../../app/helpers/formatters";
import { useSelector } from "react-redux";
import { BookingHold } from "../../../../redux/state/booking/bookingConstants";

const useStyles = makeStyles((theme) => ({
  svg: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  wrapper: {
    margin: theme.spacing(0, 4),
  },
  active: {
    color: "#3699FF",
  },
}));

export const Steps = ({ routesMap,noFlight }) => {
  const { bookingStatus } = useSelector((state) => state.booking);
  const { id, guid } = useParams();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const stepNumber = routesMap.findIndex(
    ({ route }) => location.pathname === parseRoute(route, { id, guid })
  );
  const getStepClasses = (currStep) =>
    clsx({
      [classes.active]: currStep <= stepNumber,
    });

  const displayedRoutes = routesMap.slice(
    0,
    bookingStatus === BookingHold &&!noFlight ? 2 : 3
  );
  const steps = displayedRoutes.map(({ route, title }, index) => (
    <Box
      key={route}
      display="flex"
      width={displayedRoutes.length > index + 1 ? "100%" : "auto"}
      className="d-flex align-items-center text-center"
    >
      <div>
        <Typography
          variant={matchesMd ? "h3" : "h4"}
          className={getStepClasses(index)}
        >
          {index + 1}
        </Typography>
        {matchesSM && (
          <Typography
            variant={matchesMd ? "h5" : "h6"}
            className={getStepClasses(index)}
            noWrap
          >
            {title}
          </Typography>
        )}
      </div>

      {displayedRoutes.length > index + 1 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="auto"
          className={clsx(
            classes.svg,
            "svg-icon",

            {
              "svg-icon-primary": index <= stepNumber,

              "svg-icon-dark": index > stepNumber,
              "svg-icon-5x": matchesMd,
              "svg-icon-3x": !matchesMd,
            }
          )}
        >
          <Svg
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
            width={64}
            height="auto"
          />
        </Box>
      )}
    </Box>
  ));
  return (
    <Paper className="p-7 d-flex justify-content-around text-center">
      {steps}
    </Paper>
  );
};
