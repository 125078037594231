import { matchPath, useHistory, useLocation, useParams } from "react-router";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { parseRoute } from "../../../../../../app/helpers/formatters";

export const BookingDetailsTabs = ({ tabsRoutes }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    tabsRoutes.forEach(({ route }, index) => {
      const match = matchPath(location.pathname, {
        path: route,
        exact: true,
        strict: false,
      });
      if (match) setSelectedTab(index);
    });
  }, [location.pathname, tabsRoutes]);
  const listTitles = useMemo(
    () =>
      tabsRoutes.map(({ route, title }, index) => (
        <li
          key={`title_${route}`}
          onClick={() => {
            history.push(parseRoute(route, { id }));
            setSelectedTab(index);
          }}
          className={clsx("mr-2 px-7 py-3 font-weight-bold", {
            "bg-primary text-white": selectedTab === index,
            "bg-secondary": selectedTab !== index,
          })}
          style={{ borderRadius: "14px", cursor: "pointer",whiteSpace:'nowrap' }}
        >
          {title}
        </li>
      )),
    [history, id, selectedTab, tabsRoutes]
  );

  return (
    <div>
      <div className={clsx("d-flex  align-items-center")}>
        <ScrollContainer horizontal={true}>
          <ul className="d-flex list-unstyled ">{listTitles}</ul>
        </ScrollContainer>
      </div>
    </div>
  );
};
