import React from "react";
import { Input, Select } from "..";
import { countryCodesOptions } from "../../../../_assets/js/components/options";

export const PhoneInput = ({
  label,
  required,
  variant = "primary",
  outlined = true,
  showErrorsCode,
  errorMessageCode,
  valueCode,
  onChangeCode,
  showErrorsPhone,
  errorMessagePhone,
  fieldPropsPhone,
  disabled,
}) => {
  return (
    <div>
      <div className="d-flex align-items-end ">
        <div className="w-80px">
          <Select
            outlined={outlined}
            variant={variant}
            label={label}
            placeholder="Code"
            showErrors={showErrorsCode}
            errorMessage={errorMessageCode}
            value={valueCode}
            onChange={onChangeCode}
            required={required}
            options={countryCodesOptions}
            noErrorMessage
            disabled={disabled}
          />
        </div>
        <div className="flex-grow-1 ml-2">
          <Input
            variant={variant}
            outlined={outlined}
            showErrors={showErrorsPhone}
            errorMessage={errorMessagePhone}
            {...fieldPropsPhone}
            type="tel"
            placeholder="123 456 7891"
            noErrorMessage
            disabled={disabled}
          />
        </div>
      </div>
      {showErrorsPhone && errorMessagePhone && (
        <div className="fv-plugins-message-container h-20px max-w-100">
          <div className="fv-help-block">
            {showErrorsPhone && errorMessagePhone}
          </div>
        </div>
      )}
    </div>
  );
};
