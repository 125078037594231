import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../common";
import { actions } from "../../../../../../../redux/state/payment";
import { useParams } from "react-router-dom";
import { PaymentBalanceLogRow } from "./PaymentBalanceLogRow";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { BankReportSearchCard } from "../../../reports/Bank Report/BankReportSearchCard";
import { filterOptions } from "../../../../../../../redux/state/invoices/invoicesConstants";
import useUserName from "../../../../../../../app/helpers/hooks/useUserName";
import { paymentTypeLabel } from "../../../../../../../redux/state/booking/bookingConstants";

export const PaymentBalanceLog = () => {
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const { getUserName } = useUserName();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { list, loading } = useSelector(({ payment }) => payment.paymentLogs);
  const {
    list: paymentMethodBalancesList,
    loading: bankAccountLoading,
  } = useSelector(({ payment }) => payment.paymentMethodBalances);
  const { fromDate, toDate } = dateRange;

  useEffect(() => {
    dispatch(actions.getPaymentMethodBalances());
    dispatch(actions.getPaymentLogs({ paymentType: id, companyId: 0 }));
  }, [dispatch, id]);

  const tableContent = list?.map((item, index) => (
    <PaymentBalanceLogRow
      getCompanyName={getCompanyName}
      key={index}
      data={item}
      getUserName={getUserName}
    />
  ));
  const currentPaymentMethod =
    paymentMethodBalancesList?.find((item) => item.paymentMethod === +id) ?? {};
  const search = (filters) => {
    dispatch(
      actions.getAllBankAccountsHistory({
        filterParam: [
          {
            columnName: "bankAccountId",
            filterValue: `${id}`,
            filterOption: filterOptions.IsEqualTo,
          },
          ...filters,
        ],
      })
    );
  };
  if (loading || bankAccountLoading || allCompaniesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <Grid container spacing={3}>
            <Grid xs={12} md={6} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Payment Type
                </span>
                <span className="">
                  {paymentTypeLabel[currentPaymentMethod.paymentMethod]}
                </span>
              </Typography>
            </Grid>
            <Grid xs={12} md={6} item>
              <Typography
                variant="h6"
                className="d-flex flex-column align-items-center"
              >
                <span className="font-weight-normal text-muted mb-2">
                  Available Balance
                </span>
                <span className="">
                  ${currentPaymentMethod?.availableBalance?.toFixed(2)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper className="p-7">
          <BankReportSearchCard
            isBankHistory
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid> */}
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div>
            <Typography variant="h6" className="font-weight-bold">
              Payment Balance Report
            </Typography>
            {fromDate && toDate && (
              <Typography>
                Date: <b>{fromDate}</b> to: <b>{toDate}</b>
              </Typography>
            )}
          </div>
          <>
            <div className="table-responsive mt-5">
              <table className="table table-vertical-center text-center font-weight-bold">
                <thead>
                  <tr className="bg-gray-100">
                    <th>ID</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Agency Name</th>
                    <th>Payment For</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
            </div>
            {list?.length === 0 && (
              <Typography
                variant="h5"
                className="text-center w-100 text-muted my-5"
              >
                No records to show
              </Typography>
            )}
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};
