import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/supplier";

// with code options => 1
// with id options => 2

export default function useSuppliersOptions(type = 1) {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ supplier }) => supplier.suppliers);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllSuppliers());
    }
  }, [dispatch, list]);
  const supplierOptions = useMemo(
    () =>
      list?.map(({ code, id }) => ({
        value: type === 1 ? code : id,
        label: code,
      })),
    [list, type]
  );
  return { supplierOptions, loading };
}
