/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {
  Paper,
  Grid,
  Switch,
  Checkbox,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../_helpers";
import { Button, LoadingSpinner, Tabs } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/company";
import { getDateTime } from "../../../../_helpers/date";
import { parseRoute } from "../../../../../app/helpers/formatters";
import {
  ADMIN_CLIENT_VIEW,
  ADMIN_CREATE_CLIENT,
} from "../../../../../app/routesMapAdmin";
import { AllocateFundsModal } from "./modals/AllocateFundsModal";
import { CreditSettingsModal } from "./modals/CreditSettingsModal";
import {
  ORGANIZATION_TYPE_AGENCY,
  ORGANIZATION_TYPE_SUBSCRIBER,
  creditAgreementLabelMap,
} from "../../../../../redux/state/company/companyConstants";
import { ClientSearchCard } from "./clientSearchCard/ClientSearchCard";
import useExportBooking, {
  exportBookingTypesMap,
} from "../../../../../app/helpers/hooks/useExportBooking";
import useSubPermissions from "../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../data/adminPermissionIds";
import { Pagination } from "@material-ui/lab";
import { filterOptions } from "../../../../../redux/state/invoices/invoicesConstants";
import { UpdateCompanyBalance } from "./modals/UpdateCompanyBalance";
import { ResponsiveRow } from "../../common/ResponsiveRow/ResponsiveRow";

const removeArrDuplicates = (arr) => {
  return arr.filter((element, index) => {
    return arr.indexOf(element) === index;
  });
};

const FILTER_TYPE_AGENCIES = "agencies";
const FILTER_TYPE_SUBSCRIBERS = "subscriber";
const tabsData = [
  { title: "Agencies", filter: FILTER_TYPE_AGENCIES },
  { title: "Subscribers", filter: FILTER_TYPE_SUBSCRIBERS },
];

export const Clients = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const [openFundsModal, setOpenFundsModal] = useState(false);
  const [openUpdateBalanceModal, setOpenUpdateBalanceModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [ids, setIds] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { exportToExcel, loading: downloadLoading } = useExportBooking();
  const [statusFilter, setStatusFilter] = useState(FILTER_TYPE_AGENCIES);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  // const { fromDate, toDate } = dateRange;
  const [openCreditSettingsModal, setOpenCreditSettingsModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { loading, list, paginationInfo } = useSelector(
    ({ company }) => company.allCompanies
  );
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { totalPages } = paginationInfo ?? {};
  // const { loading: companyDataLoading } = useSelector(
  //   ({ company }) => company.companyData
  // );
  // const loading = companyDataLoading && listLoading;
  const dispatch = useDispatch();
  const filterCompanies = useCallback(
    (pageNumber = 1, filters = []) => {
      let filterParam = [...filters];
      if (statusFilter === FILTER_TYPE_AGENCIES) {
        filterParam.push({
          columnName: "organisationTypeId",
          filterValue: `${ORGANIZATION_TYPE_AGENCY}`,
          filterOption: filterOptions.IsEqualTo,
        });
      } else if (statusFilter === FILTER_TYPE_SUBSCRIBERS) {
        filterParam.push({
          columnName: "organisationTypeId",
          filterValue: `${ORGANIZATION_TYPE_SUBSCRIBER}`,
          filterOption: filterOptions.IsEqualTo,
        });
      }

      dispatch(actions.filterAllCompanies({ pageNumber, filterParam }));
    },
    [dispatch, statusFilter]
  );
  useEffect(() => {
    filterCompanies(pageNumber, appliedFilters);
  }, [appliedFilters, dispatch, filterCompanies, pageNumber]);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      // all ids are selected
      if (id === 0) {
        const allListIds = list.map(({ id }) => id);
        setIds((prevState) => {
          const newList = removeArrDuplicates([...prevState, ...allListIds]);
          return newList;
        });
      } else {
        setIds((prevState) => [...prevState, id]);
      }
    } else {
      if (id === 0) {
        const allListIds = list.map(({ id }) => id);
        setIds((prevState) => {
          return prevState.filter(
            (selectedId) => !allListIds.includes(selectedId)
          );
        });
      } else {
        setIds((prevState) => {
          return prevState.filter((selectedId) => selectedId !== id);
        });
      }
    }
  };
  const tableContent = list?.map((item, index) => {
    return (
      <TableRow
        key={`row_${index}`}
        data={item}
        checked={ids.includes(item.id)}
        onChange={(e) => handleChange(e, item.id)}
        openFundsModal={(id) => {
          setSelectedId(id);
          setOpenFundsModal(true);
        }}
        openUpdateBalanceModal={(id) => {
          setSelectedId(id);
          setOpenUpdateBalanceModal(true);
        }}
        openCreditSettingsModal={(id) => {
          setSelectedId(id);
          setOpenCreditSettingsModal(true);
        }}
        subPermissions={subPermissions}
      />
    );
  });
  const isAllChecked = useMemo(
    () => !list?.filter(({ id }) => !ids.includes(id))?.length,
    [list, ids]
  );
  const getCompany = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
  };
  const onExport = () => {
    const idsFilter = ids.map((id) => ({
      columnName: "id",
      filterValue: `${id}`,
      filterOption: filterOptions.IsEqualTo,
    }));
    exportToExcel({
      type: exportBookingTypesMap.agency,
      filterParam: [...appliedFilters, ...idsFilter, {}],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const allAgenciesSubPermission =
    adminPermissionsIds.clients.agencies.subPermissions;
  const reset = () => {
    setAppliedFilters([]);
    setPageNumber(1);
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <ClientSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
            isSubscribers={statusFilter === FILTER_TYPE_SUBSCRIBERS}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <UpdateCompanyBalance
            show={openUpdateBalanceModal}
            onHide={() => {
              setOpenUpdateBalanceModal(false);
              setSelectedId(undefined);
            }}
            data={getCompany(selectedId)}
          />
          <AllocateFundsModal
            show={openFundsModal}
            onHide={() => {
              setOpenFundsModal(false);
              setSelectedId(undefined);
            }}
            data={getCompany(selectedId)}
          />
          <CreditSettingsModal
            show={openCreditSettingsModal}
            onHide={() => {
              setOpenCreditSettingsModal(false);
              setSelectedId(undefined);
            }}
            data={getCompany(selectedId)}
          />
          {/* <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="bg-white rounded"
          style={{ maxWidth: "600px", flexGrow: 1, overflow: "hidden" }}
        >
          <div className="bg-primary p-7">
            <Typography variant="h5" className="font-weight-bolder text-white">
              Welcome to Flyme247
            </Typography>
          </div>
          <div className="py-7 px-20" style={{ color: "#464E5F" }}>
            <Grid container spacing={3}>
              <Grid item xs className="d-flex align-items-center">
                <div className="symbol symbol-75 symbol-light mr-4 rounded-circle p-0">
                  <span className="symbol-label rounded-circle overflow-hidden p-0">
                    <span className="svg-icon svg-icon-5x rounded-circle  mt-auto">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                      />
                    </span>
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    Ahmed Adel
                  </a>
                  <a
                    href="#"
                    className="text-primary font-weight-bolder  mb-1 font-size-sm"
                  >
                    Info@email.com
                  </a>
                  <a
                    href="#"
                    className="text-muted font-weight-bolder  mb-1 font-size-sm"
                  >
                    Flyme247 Ltd.
                  </a>
                </div>
              </Grid>
              <Grid
                item
                xs
                className="bg-white border rounded border-dark p-7 min-w-200px text-center h-100 d-flex flex-column justify-content-center"
              >
                <Typography variant="h4" className="mb-3">
                  <span className="text-primary">$</span>
                  445.50
                </Typography>
                <Typography variant="h6" className="mb-2">
                  Available Balance
                </Typography>
                <Typography variant="body2">
                  Payable Balance: $445.50
                </Typography>
              </Grid>
            </Grid>
            <Seperator />
            <Select
              spacing
              onChange={(value) => setFieldValue("paymentMethod", value)}
              value={values.paymentMethod}
              errorMessage={errors.paymentMethod}
              showErrors={touched.paymentMethod}
              fixedLabelWidth
              horizontal
              label="Payment Method"
            />
            <div className="d-flex align-items-end">
              <Input
                spacing
                fixedLabelWidth
                horizontal
                placeholder="Amount here"
                label="Credit Amount"
                name="creditAmount"
                onChange={handleChange}
                value={values.creditAmount}
                errorMessage={errors.creditAmount}
                showErrors={touched.creditAmount}
              />
              <Select
                spacing
                onChange={(value) => setFieldValue("currency", value)}
                value={values.currency}
                errorMessage={errors.currency}
                showErrors={touched.currency}
                className="max-w-75px ml-3"
                fixedLabelWidth
              />
            </div>
            <div className="d-flex align-items-end">
              <Input
                spacing
                name="creditNotes"
                onChange={handleChange}
                value={values.creditNotes}
                errorMessage={errors.creditNotes}
                showErrors={touched.creditNotes}
                fixedLabelWidth
                horizontal
                placeholder="Amount here"
                label="Credit Notes"
              />
              <Select
                spacing
                onChange={(value) => setFieldValue("noteType", value)}
                value={values.noteType}
                errorMessage={errors.noteType}
                showErrors={touched.noteType}
                className="max-w-75px ml-3"
                fixedLabelWidth
              />
            </div>
            <div className="d-flex justify-content-center mt-7 mb-3">
              <Button onClick={() => {}} variant="primary" className="w-100">
                Save
              </Button>
            </div>
          </div>
        </Paper>
      </Modal> */}
          <div className="card-header d-flex align-items-center justify-content-between flex-wrap border-0 p-0 py-5">
            <div className="pb-3">
              <Tabs
                setFilter={(v) => {
                  setStatusFilter(v);
                  setPageNumber(1);
                }}
                data={tabsData}
                noContent
                selectedFilter={statusFilter}
              />
            </div>
            {/* hide for release */}
            {/* <Input
        spacing noErrorMessage className="max-w-500px" search /> */}
            <div className="card-toolbar ml-auto">
              <Button
                variant="primary-inverse"
                className="font-weight-bolder mr-3"
                smallPadding
                title="Export"
                loading={downloadLoading}
                onClick={onExport}
              >
                <span className="svg-icon svg-icon-primary">
                  <SVG
                    title="Export"
                    src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")}
                  />
                </span>
                Export
              </Button>
              <NavLink
                hidden={
                  !subPermissions.includes(
                    allAgenciesSubPermission.createAgency.id
                  )
                }
                to={ADMIN_CREATE_CLIENT}
                className="btn btn-danger font-weight-bolder font-size-sm"
              >
                Create
              </NavLink>
            </div>
          </div>
          {/* Body */}
          <div className="card-body p-0">
            <div className="tab-content">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th>
                          <Checkbox
                            value={0}
                            checked={isAllChecked}
                            onChange={(e) => handleChange(e, 0)}
                          />
                        </th>
                        <th style={{ minWidth: "auto" }}>
                          <span className="text-dark-75">Agency name</span>
                        </th>
                        {/* <th style={{ minWidth: "100px" }}>User Type</th> */}
                        {matchesSm && (
                          <>
                            <th style={{ minWidth: "100px" }}>Joined Date</th>
                            <th style={{ minWidth: "100px" }}>Last active</th>
                            <th>Phone Number</th>
                            <th>Credit Type</th>
                            <th style={{ minWidth: "100px" }}>Email Address</th>
                          </>
                        )}
                        <th style={{ minWidth: "100px" }}>Status</th>
                        <th style={{ minWidth: "80px" }}></th>
                      </tr>
                    </thead>

                    <tbody>{tableContent}</tbody>
                  </table>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <Pagination
                  className="mx-auto mt-2"
                  onChange={handlePageChange}
                  page={pageNumber}
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
            {/* end::Tap pane Day */}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const ClientActionsDropDown = ({
  openFundsModal,
  openCreditSettingsModal,
  openUpdateBalanceModal,
  data,
  subPermissions,
}) => {
  const { isActive, id } = data || {};
  const dispatch = useDispatch();
  const { loading } = useSelector(({ company }) => company.edit);
  const setActivationStatus = () => {
    if (isActive) {
      dispatch(actions.blockCompany({ id }));
    } else {
      dispatch(actions.unblockCompany({ id }));
    }
  };
  const allAgenciesSubPermission =
    adminPermissionsIds.clients.agencies.subPermissions;
  return (
    <div className="card-toolbar">
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi navi-hover">
            <li
              hidden={
                !subPermissions.includes(allAgenciesSubPermission.viewAgency.id)
              }
              className="navi-item"
            >
              <NavLink
                to={parseRoute(ADMIN_CLIENT_VIEW, { id })}
                className="navi-link"
              >
                <span className="navi-text">View/Edit</span>
              </NavLink>
            </li>
            {/* <li
              hidden={
                !subPermissions.includes(allAgenciesSubPermission.editAgency.id)
              }
              className="navi-item"
            >
              <NavLink
                to={parseRoute(ADMIN_CLIENT_SETTINGS, { id })}
                className="navi-link"
              >
                <span className="navi-text">Edit</span>
              </NavLink>
            </li> */}
            <li
              hidden={
                !subPermissions.includes(
                  allAgenciesSubPermission.creditSettings.id
                )
              }
              className="navi-item"
            >
              <button
                onClick={() => openCreditSettingsModal(id)}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Credit Settings</span>
              </button>
            </li>
            <li
              hidden={
                !subPermissions.includes(
                  allAgenciesSubPermission.allocateCredit.id
                )
              }
              className="navi-item"
            >
              <button
                onClick={() => openFundsModal(id)}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Allocate Credit</span>
              </button>
            </li>
            <li
              // hidden={
              //   !subPermissions.includes(
              //     allAgenciesSubPermission.allocateCredit.id
              //   )
              // }
              className="navi-item"
            >
              <button
                onClick={() => openUpdateBalanceModal(id)}
                className="btn navi-link w-100 text-left"
              >
                <span className="navi-text">Edit Balance</span>
              </button>
            </li>
            <li
              hidden={
                !subPermissions.includes(
                  allAgenciesSubPermission.activeAgency.id
                )
              }
              className="navi-item"
            >
              <label className="pl-6 d-flex align-items-center justify-content-between">
                Active
                <Switch
                  checked={isActive}
                  onClick={setActivationStatus}
                  disabled={!!loading}
                  color="primary"
                />
              </label>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const TableRow = ({
  data,
  openFundsModal,
  openCreditSettingsModal,
  openUpdateBalanceModal,
  subPermissions,
  checked,
  onChange,
}) => {
  const {
    logo,
    name,
    createdAt,
    id,
    isActive,
    lastActive,
    salesEmail,
    creditContractType,
    registeredPhone,
  } = data;
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { date } = getDateTime(createdAt);
  const creditTypeLabel = creditAgreementLabelMap[creditContractType];
  const rows = (
    <>
      <td>
        <Checkbox value={id} checked={checked} onChange={onChange} />
      </td>
      <td>
        <NavLink
          to={parseRoute(ADMIN_CLIENT_VIEW, { id })}
          className="navi-link"
        >
          <div className="d-flex align-items-center ">
            {matchesSm && (
              <div className="">
                <span className="">
                  <img
                    className="rounded-circle mr-5 max-w-50px"
                    src={logo || ""}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = toAbsoluteUrl(
                        "/media/svg/icons/General/company-logo-default.svg"
                      );
                    }}
                    alt=""
                  />
                </span>
              </div>
            )}
            <div>
              <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {name}
              </span>
            </div>
          </div>
        </NavLink>
      </td>

      {matchesSm && (
        <>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {date}
            </span>
            {/* <span className="text-muted font-weight-bold">{time}</span> */}
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {lastActive || "-"}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {registeredPhone || "-"}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {creditTypeLabel || "-"}
            </span>
          </td>

          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {salesEmail || "-"}
            </span>
          </td>
        </>
      )}
      <td>
        <span className={isActive ? "text-success" : "text-danger"}>
          {isActive ? "Active" : "Inactive"}
        </span>
      </td>
      <td className="pr-0 text-right">
        <ClientActionsDropDown
          data={data}
          openFundsModal={() => openFundsModal(id)}
          openCreditSettingsModal={() => openCreditSettingsModal(id)}
          openUpdateBalanceModal={() => openUpdateBalanceModal(id)}
          subPermissions={subPermissions}
        />
      </td>
    </>
  );

  const mobileRowDetails = [
    {
      label: "Joined Date",
      value: date,
    },
    {
      label: "Last active",
      value: lastActive || "-",
    },
    {
      label: "Phone Number",
      value: registeredPhone || "-",
    },
    {
      label: "Credit Type",
      value: creditTypeLabel || "-",
    },
    {
      label: "Email Address",
      value: salesEmail || "-",
    },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
