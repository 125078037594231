import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { TextWithIcon } from "../../home/Home";
import { formatImageUrl } from "../../../../../app/helpers/formatters/strapiImagesUrl";

export const ContentWithTextIcons = ({ data }) => {
  const { title, textIcon, backgroundImage } = data;
  const backgroundImageStyle = backgroundImage.data
    ? {
        backgroundImage: `url(${formatImageUrl(
          backgroundImage.data.attributes.url
        )})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
      }
    : null; // Set default background color to dark if there is no background image

  const textStyle = backgroundImage.data ? { color: "white" } : {}; // Set text color to white if there is a background image, otherwise set it to contrast

  return (
    <div style={backgroundImageStyle} className="px-10 py-20 position-relative">
      {/* make absolute div to fill whole width with background */}
      {/* <div className="position-absolute top-0 vw-100 bottom-0 bg-gray-100" /> */}
      <Typography
        variant="h3"
        className="font-weight-bolder text-center mb-10"
        style={textStyle}
      >
        {title}
      </Typography>
      <Grid container spacing={3}>
        {textIcon?.map(({ title, description, icon }, index) => (
          <TextWithIcon
            key={`text-icon-${index}`}
            textStyle={textStyle}
            title={title}
            iconPath={formatImageUrl(icon.data?.attributes?.url)}
            content={description}
          />
        ))}
      </Grid>
    </div>
  );
};
