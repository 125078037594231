import { accountingClient } from "./client";

export const getBills = () => accountingClient.get("/accounting/v1/bills");

export const getSpecificBill = ({ id }) =>
  accountingClient.get(`/accounting/v1/bills/${id}`);

export const filterBills = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  accountingClient.post("/accounting/v1/payable", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber,
    pageSize,
  });

export const editBill = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/bills/${id}`, { ...rest });
