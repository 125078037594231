import React from "react";
import { Modal } from "react-bootstrap";
import { Grid, Switch } from "@material-ui/core";
import { Button, Input } from "../../../common";

export const PaymentMethodForm = ({ fields, show, loading, onHide }) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;

  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Payment method</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {loading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Input
                {...getFieldProps("name")}
                showErrors={touched["name"]}
                errorMessage={errors["name"]}
                variant="secondary"
                label="Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="pl-6 d-flex flex-column align-items-center justify-content-between">
                Active
                <Switch
                  checked={values.isActive}
                  onClick={() => setFieldValue("isActive", !values.isActive)}
                  color="primary"
                />
              </label>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={loading}
            smallPadding
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
