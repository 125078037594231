import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  PAX_TYPE_ADULT,
  PAX_TYPE_CHILD,
  PAX_TYPE_YOUTH,
  PAX_TYPE_INFANT,
  BookingIssue,
  PAYMENT_TYPE_CARD,
  bookingTypesMap,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_PREMIERE,
} from "../../../redux/state/booking/bookingConstants";
import { getCountryAbbrvCode } from "../../../_metronic/_assets/js/components/options";
import { generatePhoneValue } from "../formatters/phone";
import { getStripeFee } from "../../../_metronic/layout/components/common/payment/utils";

export function useGetBookingParams() {
  const {
    id: userId,
    companyInUserId: companyId,
    name: userName,
  } = useSelector(({ user }) => user.userData);

  const { bookingStatus } = useSelector((state) => state.booking);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { availableBalance } =
    useSelector(({ accountBalance }) => accountBalance.balance.data) || {};
  const { token } = useSelector((state) => state.auth);

  const getAdminMarkup = useCallback(
    ({ markups, adult, infant, child, youth }) =>
      [...(markups || [])].reduce(
        (
          acc,
          { ruleName, perAdult, perInfant, perChild, perYouth, totalMarkup }
        ) => {
          const markupType = ruleName.split(":")[0];
          if (markupType === "Admin")
            return (
              acc +
              +(perAdult || 0) * adult +
              +(perInfant || 0) * infant +
              +(perChild || 0) * child +
              +(perYouth || 0) * youth +
              +(totalMarkup || 0)
            );
          return acc + 0;
        },
        0
      ),
    []
  );
  const getAgencyMarkup = useCallback(
    ({ markups, adult, child, infant, youth }) =>
      [...(markups || [])].reduce(
        (
          acc,
          { ruleName, perAdult, perInfant, perChild, perYouth, totalMarkup }
        ) => {
          const markupType = ruleName.split(":")[0];
          if (markupType === "Agency") {
            return (
              acc +
              +(perAdult || 0) * adult +
              +(perInfant || 0) * infant +
              +(perChild || 0) * child +
              +(perYouth || 0) * youth +
              +(totalMarkup || 0)
            );
          }
          return acc + 0;
        },
        0
      ),
    []
  );
  const getWalletParams = useCallback(
    ({ values, paymentType, supplierAmount, supplierCode, totalAmt }) => {
      const { pin } = values || {};
      return {
        createdBy: userId,
        updatedBy: userId,
        transactionId: 0,
        billingInfo: {
          createdBy: userId,
          updatedBy: userId,
          transactionId: 0,
          country: "",
          street: "",
          zip: "",
          state: "",
          contactNo: "",
          email: "",
          countryCode: "",
          nameOnCard: "",
          cardNumber: "",
          expYear: 0,
          expMonth: 0,
          cardCVC: "",
        },
        payment_transaction_id: 0,
        pinCode: pin,
        amount: +totalAmt,
        paymentType,
        availableBalance: +availableBalance,
        status: "",
        token,
        supplierAmount,
        supplierCode,
      };
    },
    [availableBalance, token, userId]
  );
  const getCardParams = useCallback(
    ({ values, paymentType, supplierAmount, supplierCode, totalAmt }) => {
      const { nameOnCard, cardNumber, expYear, expMonth, cardCVC } =
        values || {};
      return {
        createdBy: userId,
        updatedBy: userId,
        transactionId: 0,
        billingInfo: {
          createdBy: userId,
          updatedBy: userId,
          transactionId: 0,
          country: "",
          street: "",
          zip: "",
          state: "",
          contactNo: "",
          email: "",
          countryCode: "",
          nameOnCard: nameOnCard,
          cardNumber: cardNumber,
          expYear: +expYear,
          expMonth: +expMonth,
          cardCVC: cardCVC.toString(),
        },
        payment_transaction_id: 0,
        pinCode: "",
        amount: +totalAmt,
        paymentType,
        availableBalance: +availableBalance,
        status: "",
        token,
        supplierCode,
        supplierAmount,
      };
    },
    [availableBalance, token, userId]
  );
  const getEWalletParams = useCallback(
    ({ values, paymentType, supplierAmount, supplierCode, totalAmt }) => {
      const { accountNo } = values || {};
      return {
        createdBy: userId,
        updatedBy: userId,
        transactionId: 0,
        billingInfo: {
          createdBy: userId,
          updatedBy: userId,
          transactionId: 0,
          country: "",
          street: "",
          zip: "",
          state: "",
          contactNo: "",
          email: "",
          countryCode: "",
          nameOnCard: "",
          cardNumber:
            paymentType === PAYMENT_TYPE_WAAFI
              ? accountNo
              : `00252${accountNo}`,
          expYear: 0,
          expMonth: 0,
          cardCVC: "",
        },
        payment_transaction_id: 0,
        pinCode: "",
        amount: +totalAmt,
        paymentType,
        availableBalance: 0,
        status: "",
        token,
        supplierCode,
        supplierAmount,
      };
    },
    [token, userId]
  );

  const getPaymentVM = useCallback(
    ({
      paymentValues,
      paymentType,
      supplierAmount,
      supplierCode,
      totalAmt,
      gds,
    }) => {
      switch (paymentType) {
        case PAYMENT_TYPE_CARD:
          return getCardParams({
            values: paymentValues,
            paymentType,
            supplierCode,
            supplierAmount,
            totalAmt,
          });

        case PAYMENT_TYPE_WALLET:
          return getWalletParams({
            values: paymentValues,
            paymentType,
            supplierCode,
            supplierAmount,
            totalAmt,
          });

        case PAYMENT_TYPE_WAAFI:
        case PAYMENT_TYPE_PREMIERE:
          return getEWalletParams({
            values: paymentValues,
            paymentType,
            supplierCode,
            supplierAmount,
            totalAmt,
          });

        default:
          return {};
      }
    },
    [getCardParams, getWalletParams, getEWalletParams]
  );
  const calculateTotal = ({
    paymentType,
    stripeFixedFee,
    stripePercentageFee,
    totalPrice,
  }) => {
    let total = 0;
    let stripeFee = 0;
    if (paymentType === PAYMENT_TYPE_CARD) {
      stripeFee = getStripeFee({
        stripeFixedFee,
        stripePercentageFee,
        total: totalPrice,
      });
    }
    total = +(+totalPrice + +stripeFee).toFixed(2);
    console.log({ total, stripeFee });
    return {
      totalAmount: total,
      stripeFee: stripeFee,
    };
  };

  const getBookingParams = useCallback(
    ({
      travellersValues,
      paymentValues,
      selectedAirline,
      searchRQ,
      paymentType,
      isManual,
      isReissue,
      bookingType,
      fareRules,
      stripeFixedFee = 0,
      stripePercentageFee = 0,
    }) => {
      const { passengers, agencyId, leadGuest } = travellersValues;
      const { generalInfo, paxDetails } = searchRQ;
      const {
        totalAmt,
        segments,
        gds,
        markups,
        supplierAmt,
        pnr,
        ltd,
        refNo,
        cancellationCharges,
      } = selectedAirline;

      const ltdValue = ltd ? ltd.replace(" ", "T") : "0001-01-01T00:00:00";
      const passengerParams = isReissue
        ? passengers
        : getPassengerParams({
            passengers,
            leadGuest,
            userId,
            selectedAirline,
          });
      const { totalAmount, stripeFee } = calculateTotal({
        paymentType,
        stripeFixedFee,
        stripePercentageFee,
        totalPrice: totalAmt,
      });

      const agencyMarkup =
        isManual || isReissue || bookingType === bookingTypesMap.package
          ? 0
          : getAgencyMarkup({ markups, ...paxDetails });
      const flymeMarkup =
        isManual || isReissue || bookingType === bookingTypesMap.package
          ? +(totalAmt || 0) - +(supplierAmt || 0)
          : getAdminMarkup({ markups, ...paxDetails });
      const supplierPrice =
        isManual || isReissue || bookingType === bookingTypesMap.package
          ? +(supplierAmt || 0)
          : +totalAmt - ((agencyMarkup || 0) + (flymeMarkup || 0));
      let paymentVM = getPaymentVM({
        paymentValues,
        paymentType,
        supplierAmount: supplierPrice,
        supplierCode: gds,
        totalAmt,
        gds,
      });

      return {
        createdBy: userId,
        createdByName: userName,
        updatedBy: userId,
        penalities: bookingTypesMap.flight
          ? JSON.stringify(cancellationCharges ?? "")
          : "",
        fareRules: bookingTypesMap.flight
          ? JSON.stringify(fareRules ?? "")
          : "",
        transactionType: bookingType,
        isManual,
        portalId: 1,
        bookingId: "",
        orderNo: "",
        flowType: 1,
        engineId: 1,
        tripType:
          bookingType === bookingTypesMap.flight ? generalInfo.tripType : "",
        ltd: ltdValue,
        pnr: refNo || "",
        pnrType: "",
        pcc: "",
        coupanCode: "",
        segment:
          bookingType === bookingTypesMap.flight
            ? JSON.stringify(segments)
            : "",
        guid: `${generalInfo?.guid}`,
        flightId: selectedAirline.id.toString(),
        modifiedBy: 1,
        // isRefundable: refundable === "true",
        isRefundable: true,
        isReissueable: true,
        transactionStatus: "",
        // chargedCurrency: supplierTags.supplierCurrency,
        chargedCurrency: "$",
        totalPrice: `${totalAmount}`,
        subTotal: +totalAmt,
        transactionFee: stripeFee,
        isVoidable: true,
        totalPax: Object.values(paxDetails)
          .reduce((prev, curr) => prev + curr)
          .toString(),
        parentTId: 1,
        userId,
        bookingStatus,
        filler1: "",
        filler2: "",
        agencyId: isAdmin ? agencyId : companyId,
        price: `${supplierPrice}`, //supplier's price
        agencyMarkup: `${agencyMarkup}`,
        flymeMarkup: `${flymeMarkup}`,
        companyMarkup: "0",
        agencyName: "",
        cancellationFee: "0.0", // percentage, amount(with or without currency), null
        isCancelPercentage: false, ///
        client: "", ///
        gdsPnr: pnr || "", ///
        airlineRefNo: "", ///
        // Supplier: gds,
        // SupplierId: "1",
        supplier: gds,
        supplierId: gds,
        passengers: passengerParams,
        flight:
          bookingType === bookingTypesMap.flight
            ? getFlightParams({
                selectedAirline,
                userId,
                cabin: generalInfo.cabin,
              })
            : {},
        ...(bookingStatus === BookingIssue ||
        bookingType === bookingTypesMap.package
          ? {
              paymentVM,
            }
          : {}),
      };
    },
    [
      userId,
      userName,
      bookingStatus,
      isAdmin,
      companyId,
      getAgencyMarkup,
      getAdminMarkup,
      getPaymentVM,
    ]
  );
  return { getBookingParams };
}

const getFlightParams = ({ selectedAirline, userId, cabin }) => {
  const { cabinClass, segments, stop } = selectedAirline;
  const { iSeg } = segments[0];
  const { dApt } = iSeg[0];
  const { aApt } = iSeg[iSeg.length - 1];
  const { iSeg: firstISeg } = segments[0];
  const { iSeg: lastISeg } = segments[segments.length - 1];
  const { fn, fba, dTer, dTime, dDat } = firstISeg[0];
  const { aDat, aTime, aTer } = lastISeg[lastISeg.length - 1];
  return {
    createdBy: userId,
    updatedBy: userId,
    transaction: "",
    boundType: "",
    ticketType: cabinClass,
    ticketNumber: "",
    flightCount: "",
    flightNumber: `${fn}`,
    fromCode: dApt,
    toCode: aApt,
    fareBasis: "",
    marketingCarrier: "",
    departureDateTime: `${dDat}T${dTime}`,
    cabin,
    cabinDetail: "",
    classOfServices: "",
    flightServices: "",
    equipmentType: "",
    status: "",
    arrivalDateTime: `${aDat} ${aTime}`,
    depart_Terminal: dTer,
    arrival_Terminal: aTer,
    flightName: `${fn}`,
    baggageInfo: fba,
    fare_Bases: "",
    flightType: cabinClass,
    stops: stop,
  };
};

const getPassengerParams = ({
  passengers,
  leadGuest,
  userId,
  selectedAirline,
}) => {
  const { abf, atx, cbf, ctx, ibf, itx, ybf, ytx } = selectedAirline;
  const passengerFare = {
    [PAX_TYPE_ADULT]: abf,
    [PAX_TYPE_YOUTH]: ybf,
    [PAX_TYPE_CHILD]: cbf,
    [PAX_TYPE_INFANT]: ibf,
  };
  const passengerTax = {
    [PAX_TYPE_ADULT]: atx,
    [PAX_TYPE_YOUTH]: ytx,
    [PAX_TYPE_CHILD]: ctx,
    [PAX_TYPE_INFANT]: itx,
  };
  return passengers.map(
    ({
      title,
      gender,
      firstName,
      lastName,
      passportNumber,
      birthday,
      passportNationality,
      passportExpDate,
      wealChair,
      mealSelection,
      type,
      id,
    }) => {
      const isLead = id === leadGuest.passengerNo;
      const { country, email, phoneNumber, zipCode, phoneCode } = leadGuest;
      // const files = [];
      return {
        // ...(files
        //   ? files.map(({ url, typId }) => ({
        //       passengerDocuments: [
        //         {
        //           url: url,
        //           passengerId: 0,
        //         },
        //       ],
        //     }))
        //   : {}),
        createdBy: userId,
        updatedBy: userId,
        transaction: "",
        ticketNo: "",
        paxType: type,
        title,
        firstName,
        middleName: "",
        lastName,
        gender,
        PassportNationalityCode: getCountryAbbrvCode(passportNationality),
        dateOfBirth: birthday,
        totalFareAmount: `${passengerFare[type]}`,
        totalTaxAmount: `${passengerTax[type]}`,
        isPriceIncludeTax: true,
        issuence: "",
        mco: "",
        passportNo: passportNumber,
        passportNationality,
        passportExpDate,
        isLeadGuest: isLead,
        contactNo: isLead
          ? generatePhoneValue({ number: phoneNumber, code: phoneCode })
          : "",
        email: isLead ? email : "",
        countryCode: isLead ? country : "",
        zip: isLead ? zipCode : "",
      };
    }
  );
};
