import { Typography, useTheme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import { TICKET_DETAILS } from "../../../../../../app/routesMap";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../../../app/routesMapAdmin";
import { getDateTime } from "../../../../../_helpers";
import { LoadingSpinner } from "../../../common";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const SalesReportTable = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { list, loading } = useSelector(({ invoices }) => invoices.invoices);
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const tableContent = list?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
    />
  ));
  return (
    <div className="table-responsive border">
      {loading || allCompaniesLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-200">
              {matchesSm && <th>SNo.</th>}
              <th>Booking ID</th>
              {/* <th>Invoice To</th> */}
              {matchesSm && (
                <>
                  <th>Invoice No.</th>
                  <th>Num of Passengers</th>
                  <th>Supplier</th>
                  <th className="min-w-100px">Booking Date</th>
                  <th>Agency Name</th>
                </>
              )}
              <th>Sales Price</th>
              {matchesSm && <th>Cost Price</th>}
              <th>Agency Margin</th>
              <th>Flyme Margin</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const {
    id,
    tranDate,
    bookingId,
    agencyId,
    amount,
    total,
    supplierName,
    invoiceNo,
    totalPax,
    agencyMarkup,
    markup,
  } = data;
  const agencyName = getCompanyName(agencyId);
  const { date } = getDateTime(tranDate);
  const detailsLink = isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS;
  const rows = (
    <>
      {matchesSm && (
        <td>
          <span>{id || "-"}</span>
        </td>
      )}
      <td>
        <NavLink
          className="btn text-hover-primary pl-0"
          to={parseRoute(detailsLink, { id: bookingId })}
        >
          <span>{bookingId}</span>
        </NavLink>
      </td>
      {/* <td>
        <span>{passenger || "-"}</span>
      </td> */}
      {matchesSm && (
        <>
          <td>
            <span>{invoiceNo || "-"}</span>
          </td>
          <td>
            <span>{totalPax || "-"}</span>
          </td>
          <td>
            <span>{supplierName?.slice(0, 2) || "-"}</span>
          </td>
          <td>
            <span>{date || "-"}</span>
          </td>
          <td>
            <span>{agencyName || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span>{total?.toFixed(2) || "-"}</span>
      </td>
      {matchesSm && (
        <td>
          <span>{amount?.toFixed(2) || "-"}</span>
        </td>
      )}
      <td>
        <span>{agencyMarkup?.toFixed(2)}</span>
      </td>
      <td>
        <span>{markup?.toFixed(2)}</span>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "Invoice No.",
      value: invoiceNo,
    },
    {
      label: "Num of Passengers",
      value: totalPax,
    },
    {
      label: "Supplier",
      value: supplierName?.slice(0, 2),
    },
    {
      label: "Booking Date",
      value: date,
    },
    {
      label: "Agency Name",
      value: agencyName,
    },
    {
      label: "Received Amount",
      value: amount?.toFixed(2),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
