import * as Yup from "yup";

export const getPassengerDetailsSchema = ({ isAdmin, isManual }) =>
  Yup.object({
    passengers: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Title is required"),
        gender: Yup.string().required("Gender is required"),
        firstName: Yup.string()
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for First Name")
          .required("First Name is required"),
        lastName: Yup.string()
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for Last Name")
          .required("Last Name is required"),
        ...(!isManual
          ? {
              passportNumber: Yup.string().required(
                "Passport Number is required"
              ),
              passportNationality: Yup.string().required(
                "Passport nationality is required"
              ),
              passportExpDate: Yup.mixed().required(
                "Passport expiration date is required"
              ),
              birthday: Yup.mixed().required("Birthday is required"),
            }
          : {}),
        wealChair: Yup.string(),
        mealSelection: Yup.string(),
      })
    ),
    // client: Yup.object().shape({
    //   title: Yup.string().required(),
    //   firstName: Yup.string().required(),
    //   lastName: Yup.string().required(),
    //   phoneNumber: Yup.number().required(),
    //   email: Yup.string()
    //     .email()
    //     .required(),
    //   country: Yup.string().required(),
    //   zipCode: Yup.number().required(),
    // }),
    leadGuest: Yup.object().shape({
      passengerNo: Yup.string().required("Please select lead guest"),
      phoneCode: Yup.string().required("Please select Code"),
      phoneNumber: Yup.number().required("Contact phone number is required"),
      email: Yup.string()
        .email()
        .required("Email is required"),
      country: Yup.string().required("Country is required"),
      zipCode: Yup.string().required("Zip code is required"),
    }),
    ...(isAdmin ? { agencyId: Yup.number().required() } : {}),
  });
