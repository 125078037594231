import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Button, LoadingSpinner } from "../../common";
import clsx from "clsx";
import { actions } from "../../../../../redux/state/companyCredit";
import { getDateTime } from "../../../../_helpers/date";
import {
  CREDIT_STATUS_APPROVED,
  CREDIT_STATUS_PENDING,
  CREDIT_STATUS_REJECTED,
} from "../../../../../redux/state/companyCredit/companyCreditConstants";
import { AllocateFundsModal } from "../../admin/client/modals/AllocateFundsModal";
import { Pagination } from "@material-ui/lab";
import { filterOptions } from "../../../../../redux/state/invoices/invoicesConstants";
import { CreditSearchCard } from "./CreditSearchCard";
import { ResponsiveRow } from "../../common/ResponsiveRow/ResponsiveRow";

export const creditBadgeClassNames = {
  [CREDIT_STATUS_PENDING]: "text-warning",
  [CREDIT_STATUS_REJECTED]: "text-danger",
  [CREDIT_STATUS_APPROVED]: "text-success",
};
const TableRow = ({ data }) => {
  const { status, createdAt, creditAmount, remarks, id } = data;
  const { date, time } = getDateTime(createdAt);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const rows = (
    <>
      <td className="text-left text-primary">{id || "-"}</td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted font-weight-normal mb-0">{time}</p>
        </div>
      </td>
      {matchesSm && <td>{remarks || "-"}</td>}
      <td>${creditAmount?.toFixed(2) || "-"}</td>
      <td>
        <span className={creditBadgeClassNames[status]}>{status}</span>
      </td>
    </>
  );
  const mobileRowDetails = [
    {
      label: "ID",
      value: id || "-",
    },
    {
      label: "Request Date",
      value: `${date} ${time}`,
    },
    {
      label: "Remarks",
      value: remarks || "-",
    },
    {
      label: "Amount",
      value: `$${creditAmount?.toFixed(2) || "-"}`,
    },
    {
      label: "Status",
      value: status,
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

// export const paymentMethodOptions = [
//   // { label: "Card", value: 0 },
//   { label: "Bank Transfer", value: 1 },
// ];

export const Credit = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [openModal, setOpenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: companyData } = useSelector(
    ({ company }) => company.companyData
  );
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const { fromDate, toDate } = dateRange;
  const { id: companyId } = companyData || {};
  const { list, loading: requestsLoading, paginationInfo } = useSelector(
    ({ companyCredit }) => companyCredit.creditRequests
  );
  const { totalPages } = paginationInfo ?? {};
  const { success: addCreditSuccess } = useSelector(
    ({ companyCredit }) => companyCredit.addCredit
  );
  const tableContent = list?.map((item, index) => (
    <TableRow key={index} data={item} />
  ));

  useEffect(() => {
    dispatch(
      actions.getAllCompanyCredit({
        filterParam: [
          {
            columnName: "agencyId",
            filterValue: `${companyId}`,
            filterOption: filterOptions.IsEqualTo,
          },
          ...appliedFilters,
        ],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, dispatch, pageNumber]);
  useEffect(() => {
    if (addCreditSuccess) {
      setPageNumber(1);
      dispatch(
        actions.getAllCompanyCredit({
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            ...appliedFilters,
          ],
          pageNumber: 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCreditSuccess, companyId, dispatch]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.getAllCompanyCredit({
        filterParam: [
          {
            columnName: "agencyId",
            filterValue: `${companyId}`,
            filterOption: filterOptions.IsEqualTo,
          },
          ...filters,
        ],
      })
    );
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(
        actions.getAllCompanyCredit({
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyId}`,
              filterOption: filterOptions.IsEqualTo,
            },
          ],
        })
      );
    } else {
      setPageNumber(1);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <AllocateFundsModal
            show={openModal}
            onHide={() => {
              setOpenModal(false);
            }}
            isRequest
            data={companyData}
          />
          {/* hidden for release */}
          {/* <Typography variant="h5" className="font-weight-bold mb-5">
            Payment proof
          </Typography> */}
          <div
            className={clsx("d-flex justify-content-between", {
              "flex-column": !matchesSm,
            })}
          >
            <div>
              <Typography variant="h5" className="font-weight-bold mb-5">
                Credit Requests
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            {/* hidden for release */}
            {/* <div className="d-flex flex-grow-1">
              <Input
                noErrorMessage
                className={clsx("flex-grow-1", { "max-w-500px": MatchesSm })}
                placeholder="Search"
              />
              <Button className="ml-4" variant="primary">
                Search
              </Button>
            </div> */}
            <Button
              onClick={() => setOpenModal(true)}
              variant="primary-inverse"
              className={clsx({
                "ml-auto": matchesSm,
                "mx-auto mt-3": !matchesSm,
              })}
              smallPadding
            >
              Request Credit
            </Button>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <CreditSearchCard
            setDateRange={setDateRange}
            onSearch={search}
            onReset={reset}
          />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          {requestsLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-vertical-center text-center font-weight-bold">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">ID</th>
                    <th className="min-w-100px">Request Date</th>
                    {matchesSm && <th>Remark</th>}
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{tableContent}</tbody>
              </table>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No requests to show
                </Typography>
              )}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
