import {
  Paper,
  Switch,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { LoadingSpinner, Tabs } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/user";
import { getDateTime } from "../../../../../_helpers/date";
import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { Pagination } from "@material-ui/lab";
import { UsersSearchCard } from "../usersSerchCard/UsersSearchCard";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

/* -------------------------------------------------------------------------- */
const FILTER_TYPE_AGENCIES = "agencies";
const FILTER_TYPE_STAFF = "staff";
const tabsData = [
  { title: "Agencies Users", filter: FILTER_TYPE_AGENCIES },
  { title: "Flyme Staff", filter: FILTER_TYPE_STAFF },
];
/* -------------------------------------------------------------------------- */

export const AllSubUsers = ({ permissionId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [statusFilter, setStatusFilter] = useState(FILTER_TYPE_AGENCIES);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const subPermissions = useSubPermissions(permissionId);
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const { loading: listLoading, list, paginationInfo } = useSelector(
    ({ user }) => user.allUsers
  );
  const { id: companyId } =
    useSelector(({ company }) => company.companyData.data) || {};
  const { totalPages } = paginationInfo ?? {};
  const { fromDate, toDate } = dateRange;

  const loading = listLoading || allCompaniesLoading;
  const filterUsers = useCallback(
    (pageNumber = 1, filters = []) => {
      let filterParam = [...filters];
      const includesAgencyFilter = filters.find(
        (item) => item.columnName === "companyInUserId"
      );
      if (statusFilter === FILTER_TYPE_AGENCIES && !includesAgencyFilter) {
        filterParam.push({
          columnName: "companyInUserId",
          filterValue: companyId,
          filterOption: filterOptions.IsNotEqualTo,
        });
      } else if (statusFilter === FILTER_TYPE_STAFF) {
        filterParam.push({
          columnName: "companyInUserId",
          filterValue: companyId,
          filterOption: filterOptions.IsEqualTo,
        });
      }

      dispatch(actions.filterUsers({ pageNumber, filterParam }));
    },
    [companyId, dispatch, statusFilter]
  );

  useEffect(() => {
    setPageNumber(1);
    setAppliedFilters([]);
  }, [statusFilter]);

  useEffect(() => {
    filterUsers(pageNumber, appliedFilters);
  }, [appliedFilters, dispatch, filterUsers, pageNumber]);
  const tableContent = list?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
      subPermissions={subPermissions}
    />
  ));
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    // dispatch(actions.filterUsers({ filterParam: [...filters] }));
  };
  const reset = () => {
    setAppliedFilters([]);
    setPageNumber(1);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <UsersSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
            isStaff={statusFilter === FILTER_TYPE_STAFF}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          {/* <div className="d-flex justify-content-between align-items-center border-0">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                User Management
              </span>
            </h3>
          </div> */}
          {fromDate && toDate && (
            <Typography className="mb-2">
              Created Date: <b>{fromDate}</b> to: <b>{toDate}</b>
            </Typography>
          )}
          <div className="pb-3">
            <Tabs
              setFilter={(v) => {
                setStatusFilter(v);
                setPageNumber(1);
              }}
              data={tabsData}
              noContent
              selectedFilter={statusFilter}
            />
          </div>
          <div className="pt-0 pb-3">
            <div className="tab-content">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <div className="table-responsive">
                  <table className="font-size-md table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th>ID</th>
                        <th className="">
                          <span className="text-dark-75">Name</span>
                        </th>
                        {matchesSm && (
                          <>
                            <th className="max-w-100px">Email</th>
                            <th>Agency Name</th>
                            <th style={{ minWidth: "100px" }}>Created Date</th>
                            <th>Email Verification</th>
                          </>
                        )}
                        <th>Status</th>
                        {/* <th style={{ minWidth: "100px" }}>Company</th> */}
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>

                    <tbody>{tableContent}</tbody>
                  </table>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <Pagination
                  className="mx-auto mt-2"
                  onChange={handlePageChange}
                  page={pageNumber}
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const SubUserDropDown = ({ subPermissions, showVerify, data }) => {
  const { isActive, id, companyInUserId } = data || {};
  const dispatch = useDispatch();
  const { loading } = useSelector(({ user }) => user.blockUnblock);
  const { loading: verifyLoading } = useSelector(
    ({ user }) => user.verifyEmail
  );
  const setActivationStatus = () => {
    if (isActive) {
      dispatch(actions.blockUser({ userId: id, companyId: companyInUserId }));
    } else {
      dispatch(actions.unblockUser({ userId: id, companyId: companyInUserId }));
    }
  };
  const verifyEmail = () => {
    dispatch(actions.verifyUserEmail({ id }));
  };
  const allAgenciesSubPermission =
    adminPermissionsIds.clients.userManagement.subPermissions;
  return (
    <div className="card-toolbar">
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          variant="transparent"
          className="btn btn-primary btn-sm font-weight-bolder dropdown-toggle"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi navi-hover">
            <li
              hidden={
                !subPermissions.includes(
                  allAgenciesSubPermission.verifyEmail.id
                )
              }
              className="navi-item"
            >
              <button
                disabled={!showVerify || verifyLoading}
                onClick={verifyEmail}
                className="btn w-100 text-left"
              >
                <span>Verify Email</span>
                {verifyLoading && (
                  <span className="spinner spinner-primary ml-5" />
                )}
              </button>
            </li>
            <li
              hidden={
                !subPermissions.includes(allAgenciesSubPermission.activeUser.id)
              }
              className="navi-item"
            >
              <label className="pl-6 d-flex align-items-center justify-content-between">
                Active
                <Switch
                  checked={isActive}
                  onClick={setActivationStatus}
                  disabled={!!loading}
                  color="primary"
                />
              </label>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const TableRow = ({ subPermissions, data, getCompanyName }) => {
  const {
    email,
    name,
    createdAt,
    isEmailVerified,
    isActive,
    id,
    companyInUserId,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const companyName = getCompanyName(companyInUserId);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const rows = (
    <>
      <td>
        <span className=" d-block ">{id}</span>
      </td>
      <td style={{ minWidth: "120px" }}>
        <span>{name}</span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span>{email}</span>
          </td>
          <td title={companyName} className="max-w-130px">
            <span className="text-break">{companyName}</span>
          </td>
          <td className="d-flex flex-column">
            <span>{date}</span>
            <span className="text-muted font-weight-bold">{time}</span>
          </td>
          <td>
            <span className={isEmailVerified ? "text-success" : "text-danger"}>
              {isEmailVerified ? "Verified" : "Not Verified"}
            </span>
          </td>
        </>
      )}
      <td>
        <span className={isActive ? "text-success" : "text-danger"}>
          {isActive ? "Active" : "Inactive"}
        </span>
      </td>
      <td className="pr-0 text-right">
        <SubUserDropDown
          data={data}
          showVerify={isEmailVerified === false}
          subPermissions={subPermissions}
        />
      </td>
    </>
  );

  const mobileRowDetails = [
    {
      label: "Email",
      value: email,
    },
    {
      label: "Agency Name",
      value: companyName,
    },
    {
      label: "Created Date",
      value: `${date} ${time}`,
    },
    {
      label: "Email Verification",

      value: isEmailVerified ? "Verified" : "Not Verified",
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
