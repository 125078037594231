import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FEE_TYPE_STRIPE,
  FEE_TYPE_STRIPE_PERCENTAGE,
  FEE_TYPE_SUBSCRIPTION,
} from "../../../_metronic/layout/components/admin/systemManagement/serviceFees/ServiceFeesRow";
import { actions } from "../../../redux/state/bookingMng";

export const useTransactionFee = () => {
  const dispatch = useDispatch();
  const {
    list: feesList,
    loading: feesLoading,
    success: feesSuccess,
    failure: feesFailure,
  } = useSelector(({ bookingMng }) => bookingMng.fees);
  useEffect(() => {
    // dispatch(actions.getFeeByType({ type: FEE_TYPE_SUBSCRIPTION }));
    dispatch(actions.getAllFees());
  }, [dispatch]);
  const { amount: stripePercentageFee } =
    feesList?.find((fee) => fee.type === FEE_TYPE_STRIPE_PERCENTAGE) ?? {};
  const { amount: stripeFixedFee } =
    feesList?.find((fee) => fee.type === FEE_TYPE_STRIPE) ?? {};
  const { amount: subscriptionFee } =
    feesList?.find((fee) => fee.type === FEE_TYPE_SUBSCRIPTION) ?? {};

  return {
    stripePercentageFee,
    subscriptionFee,
    stripeFixedFee,
    feesLoading,
    feesSuccess,
    feesFailure,
  };
};
