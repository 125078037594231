export const MODULE_NAME = "accountBalance";

// adding money
export const PAYMENT_TYPE_VOID = "void";
export const PAYMENT_TYPE_REFUND = "refund";
export const PAYMENT_TYPE_TOPUP = "topup";
export const PAYMENT_TYPE_REISSUE_REFUND = "reissueRefund";

export const PAYMENT_TYPE_CREDIT_TOPUP = "creditTopup"; // for supplier topup only

// balance change
export const BALANCE_UPDATE = "balanceUpdated";

// deducting money
export const PAYMENT_TYPE_REISSUE = "reissue";
export const PAYMENT_TYPE_VOID_FEE = "voidFee";
export const PAYMENT_TYPE_REFUND_FEE = "refundFee";
export const PAYMENT_TYPE_ISSUE = "issueTickets";
export const PAYMENT_TYPE_SUBSCRIPTION = "Subscription";
export const transactionStatusMap = {
  in: "IN",
  out: "OUT",
};

export const topUpStatusMap = {
  pending: 0,
  rejected: 1,
  approved: 2,
};
