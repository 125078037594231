import { Grid, Paper, Tab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CreateSubUserForm } from "./CreateSubUserForm";
import { AntTabs } from "../accountDetails/AccountDetails";
import { GivePermissions } from "./GivePermissions";

export const CreateSubUser = ({
  formik,
  createUserLoading,
  userId,
  createSuccess,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (createSuccess) {
      setSelectedTab(1);
    }
  }, [createSuccess]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Paper className="bg-white h-100">
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
          >
            <Tab label="Create a SubUser" />
            <Tab disabled={!userId} label="Give Permission" />
          </AntTabs>
          <div className="p-7">
            {selectedTab === 0 && <CreateSubUserForm formik={formik} />}
            {selectedTab === 1 && <GivePermissions userId={userId} />}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
