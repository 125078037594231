import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions } from "../../../../../../../redux/state/bookingMng";
import { GoBack, LoadingSpinner } from "../../../../common";
import { FeeLogsRow } from "./FeeLogsRow";
import useUserName from "../../../../../../../app/helpers/hooks/useUserName";

export const FeeLogs = () => {
  const { type } = useParams();
  const { getUserName, loading: usersLoading } = useUserName();

  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ bookingMng }) => bookingMng.feeLogs);

  useEffect(() => {
    if (!type) return;
    dispatch(actions.getFeeLogs({ type }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, type]);
  const tableContent = list?.map((item, index) => (
    <FeeLogsRow key={index} data={item} getUserName={getUserName} />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7">
          <GoBack />
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper className="p-7">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Fee Logs</Typography>
          </div>
          {loading || usersLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Updated By</th>
                      <th>Amount</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Logs to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
