import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { FlightDetailsCard } from "../../../../fareSearch/DapartingFlights";
import { ContactInfo } from "../orderSummary/ContactInfo";
import { SupplierInfo } from "./SupplierInfo";
import { SupplierPayment } from "./SupplierPayment";
import useAirlinesList from "../../../../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../../../../app/helpers/hooks/useAirportsList";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";

export const OrderFlight = () => {
  const { data } =
    useSelector(({ bookingMng }) => bookingMng.specificOrder) || {};
  const { passengers, segment, cabinClass, agencyId, supplier } = data || {};
  const { getCompanyName } = useCompanyName();
  const agencyName = getCompanyName(agencyId);
  const leadGuest = passengers?.$values.filter(
    ({ isLeadGuest }) => isLeadGuest === true
  )?.[0];
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ContactInfo agencyName={agencyName} leadGuest={leadGuest} />
      </Grid>
      <Grid item xs={12}>
        <CardWithBlueHeader
          title="Flight information"
          print
          iconSrc="/media/svg/icons/General/plane-white.svg"
        >
          <FlightDetailsCard
            airlinesList={airlinesList}
            airportsList={airportsList}
            segments={segments}
            cabinClass={cabinClass}
            padding
          />
        </CardWithBlueHeader>
      </Grid>
      <Grid item xs={12} md={7}>
        <SupplierInfo orderData={data} />
      </Grid>
      <Grid item xs={12} md={5}>
        <SupplierPayment />
      </Grid>
    </Grid>
  );
};
