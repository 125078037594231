/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchCard } from "./common/SearchCard";
import { LoadingSpinner, Tabs } from "../common";
import { actions } from "../../../../redux/state/bookingMng";
import {
  ALL,
  orderFilterTypesMap,
  sortTypesMap,
  VoidCompleted,
  VoidPending,
  VoidRejected,
} from "../../../../redux/state/bookingMng/bookingMngConstants";
import { getDateTime } from "../../../_helpers/date";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../app/helpers/formatters";
import { BOOKING_MANAGEMENT_VOID_DETAILS } from "../../../../app/routesMap";
import useCompanyName from "../../../../app/helpers/hooks/useCompanyName";
import { Pagination } from "@material-ui/lab";
import { filterOptions } from "../../../../redux/state/invoices/invoicesConstants";
import clsx from "clsx";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";
import { useMemo } from "react";

export const voidBadgeClassNames = {
  [VoidCompleted]: "text-success",
  [VoidPending]: "text-warning",
  [VoidRejected]: "text-danger",
};
export const voidBadgeText = {
  [VoidCompleted]: "Complete",
  [VoidPending]: "Pending",
  [VoidRejected]: "Rejected",
};
const TableRow = ({ data, noRefund, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { transaction, createdAt, status, id } = data;
  const { departureDateTime, flightNumber } = transaction?.flight || {};
  const { orderNo, totalPrice, cancellationFee, gdsPnr, passengers, agencyId } =
    transaction || {};
  const agencyName = getCompanyName(agencyId);
  const { date, time } = getDateTime(createdAt);
  const { date: flightDate } = getDateTime(departureDateTime);
  const rows = (
    <>
      <td>
        <NavLink
          className="btn text-hover-primary p-0"
          to={parseRoute(BOOKING_MANAGEMENT_VOID_DETAILS, { id })}
        >
          <span className="font-weight-bold">{orderNo}</span>
        </NavLink>
      </td>
      {matchesSm && (
        <td className="d-flex flex-column align-items-start">
          <span className="font-weight-bolder ">{date}</span>
          <span className="text-muted">{time}</span>
        </td>
      )}
      <td>
        <span className="font-weight-bolder ">
          {passengers?.$values?.map(
            ({ firstName, lastName }) => `${firstName} ${lastName}`
          ) || "no passengers Data"}
        </span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="font-weight-bolder ">{flightNumber || "-"}</span>
          </td>
          <td>
            <span className="font-weight-bolder ">{flightDate || "-"}</span>
          </td>
          <td>
            <span className="font-weight-bolder "> {gdsPnr || "-"}</span>
          </td>
          <td>
            <span className="font-weight-bolder ">${totalPrice}</span>
          </td>
          {!noRefund && <td>{cancellationFee}</td>}
          <td>
            <span className="font-weight-bold">{agencyName || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span className={voidBadgeClassNames[status]}>
          {voidBadgeText[status] || "-"}
        </span>
      </td>
    </>
  );
  const mobileRowDetails = [
    { label: "Flight No", value: flightNumber || "-" },
    { label: "Flight Date", value: flightDate || "-" },
    { label: "GDS PNR", value: gdsPnr || "-" },
    { label: "Total Price", value: `$${totalPrice}` },
    { label: "Agency Name", value: agencyName || "-" },
    { label: "Status", value: voidBadgeText[status] || "-" },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const VoidTracking = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [sortState, setSortState] = useState({
    bookingId: sortTypesMap.noSort,
    createdAt: sortTypesMap.descending,
    totalPrice: sortTypesMap.noSort,
  });
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const [searchValue, setSearchValue] = useState("");
  const [secondSearchValue, setSecondSearchValue] = useState("");
  const [filter, setFilter] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [statusFilter, setStatusFilter] = useState();
  const { list, loading, paginationInfo, statusCounts } = useSelector(
    ({ bookingMng }) => bookingMng.orders
  );

  const { totalPages } = paginationInfo ?? {};
  // useEffect(() => {
  //   dispatch(
  //     actions.getReissue({ companyId: companyInUserId, status: statusFilter })
  //   );
  // }, [statusFilter]);
  useEffect(() => {
    setSearchValue("");
    setSecondSearchValue("");
  }, [filter]);
  useEffect(() => {
    setPageNumber(1);
  }, [sortState]);
  useEffect(() => {
    const sortingParams = Object.keys(sortState)
      .filter((key) => sortState[key] !== sortTypesMap.noSort)
      .map((key) => ({ columnName: key, sortOrder: sortState[key] }));
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "status",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.cancel,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "status",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.cancel,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInUserId, dispatch, isAdmin, pageNumber, statusFilter, sortState]);
  const handleChange = (e, isSecond) => {
    if (isSecond) {
      setSecondSearchValue(e?.target?.value || e);
    } else {
      setSearchValue(e?.target?.value || e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setPageNumber(1);
    let filtersParam = [{}];
    if (filter === "createdAt") {
      filtersParam = [
        {
          columnName: "createdAt",
          filterValue: searchValue,
          filterOption: filterOptions.IsGreaterThanOrEqualTo,
        },
        {
          columnName: "createdAt",
          filterValue: secondSearchValue,
          filterOption: filterOptions.IsLessThanOrEqualTo,
        },
      ];
    } else {
      filtersParam = [
        {
          columnName: filter,
          filterValue: `${searchValue}`,
          filterOption: filterOptions.IsEqualTo,
        },
      ];
    }
    setAppliedFilters(filtersParam);
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [...filtersParam],
          type: orderFilterTypesMap.cancel,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            ...filtersParam,
          ],
          type: orderFilterTypesMap.cancel,
        })
      );
    }
  };

  const reset = () => {
    setSearchValue("");
    setSecondSearchValue("");
    setStatusFilter(ALL);
    setAppliedFilters([{}]);
    setSortState({
      bookingId: sortTypesMap.noSort,
      createdAt: sortTypesMap.descending,
      totalPrice: sortTypesMap.noSort,
    });
    if (pageNumber === 1) {
      if (isAdmin) {
        dispatch(
          actions.filterOrdersPagination({
            type: orderFilterTypesMap.cancel,
          })
        );
      } else {
        dispatch(
          actions.filterOrdersPagination({
            filterParam: [
              {
                columnName: "agencyId",
                filterValue: `${companyInUserId}`,
                filterOption: filterOptions.IsEqualTo,
              },
            ],
            type: orderFilterTypesMap.cancel,
          })
        );
      }
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const adminTabsData = useMemo(() => {
    const { all, completed, pending, rejected } = statusCounts ?? {};
    return [
      { title: `All (${all ?? 0})`, filter: ALL },
      { title: `Pending (${pending ?? 0})`, filter: VoidPending },
      { title: `Completed (${completed ?? 0})`, filter: VoidCompleted },
      { title: `Rejected (${rejected ?? 0})`, filter: VoidRejected },
    ];
  }, [statusCounts]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            onSubmit={handleSubmit}
            reset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <Tabs
            setFilter={(v) => {
              setStatusFilter(v);
              setPageNumber(1);
            }}
            data={isAdmin ? adminTabsData : agencyTabsData}
            noContent
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <VoidTable
                sortState={sortState}
                setSortState={setSortState}
                data={list}
              />
            </>
          )}
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

const VoidTable = ({ data, sortState, setSortState }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { getCompanyName } = useCompanyName();
  const tableContent = data?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
      noRefund
    />
  ));
  const handleClickSort = (columnName) => {
    const resetState = {
      bookingId: sortTypesMap.noSort,
      createdAt: sortTypesMap.noSort,
      totalPrice: sortTypesMap.noSort,
    };
    const newSortValue = (sortState[columnName] + 1) % 3;
    setSortState({ ...resetState, [columnName]: newSortValue });
  };
  return (
    <div className="table-responsive">
      <table className="table table-vertical-center mb-0 mt-5">
        <thead>
          <tr className="bg-gray-100">
            <th>
              <div className="d-flex align-items-center">
                <span>Order No</span>
                <div
                  onClick={() => handleClickSort("bookingId")}
                  className="btn-group-vertical ml-4"
                >
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-up", {
                        "text-dark":
                          sortState.bookingId === sortTypesMap.ascending,
                      })}
                    ></i>
                  </span>
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-down", {
                        "text-dark":
                          sortState.bookingId === sortTypesMap.descending,
                      })}
                    ></i>
                  </span>
                </div>
              </div>
            </th>
            {matchesSm && (
              <th className="d-flex align-items-center min-w-100px">
                <span className="d-block">Creation Date</span>
                <div
                  onClick={() => handleClickSort("createdAt")}
                  className="btn-group-vertical ml-4"
                >
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-up", {
                        "text-dark":
                          sortState.createdAt === sortTypesMap.ascending,
                      })}
                    ></i>
                  </span>
                  <span className="d-flex align-items-center justify-content-center btn btn-xs p-0 m-0 border-0 h-5px">
                    <i
                      className={clsx("fas fa-sort-down", {
                        "text-dark":
                          sortState.createdAt === sortTypesMap.descending,
                      })}
                    ></i>
                  </span>
                </div>
              </th>
            )}
            <th>Passengers</th>
            {matchesSm && (
              <>
                <th>Flight No</th>
                <th>Flight Date</th>
                <th>GDS PNR </th>
                <th>Total Price</th>
                <th>Agency Name</th>
              </>
            )}
            <th>Status</th>
            {/* <th>Operator</th> */}
          </tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>

      {data?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No void orders to show
        </Typography>
      )}
    </div>
  );
};

const agencyTabsData = [
  { title: "All", filter: ALL },
  { title: "Pending", filter: VoidPending },
  { title: "Completed", filter: VoidCompleted },
  { title: "Rejected", filter: VoidRejected },
];
