import React, { useEffect, useState,useCallback } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/accounting";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { AccountTypesForm } from "./AccountTypesForm";
import { AccountTypesRow } from "./AccountTypesRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

const accountTypeSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
});

export const AccountTypes = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ accounting }) => accounting.accountTypes);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ accounting }) => accounting.createAccountType);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ accounting }) => accounting.editAccountType);
  const fields = useFormik({
    initialValues: {
      type: "",
      name: "",
    },
    validationSchema: accountTypeSchema,
    onSubmit: ({ type, name }) => {
      if (!!selectedId) {
        const data = getSelectedAccountType(selectedId);
        dispatch(actions.editAccountType({ ...data, type, name }));
      } else {
        dispatch(actions.createAccountType({ type, name }));
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAccountTypes());
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearCreateAccountType());
    dispatch(actions.clearEditAccountType());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Account Type Added",
        })
      );
      dispatch(actions.getAccountTypes());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateAccountType());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Account Type Edited",
        })
      );
      dispatch(actions.getAccountTypes());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const onDelete = (id) => {
    setSelectedId(id);
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { type, name } = getSelectedAccountType(id);
    fields.setValues({ type, name });
    setShowForm(true);
  };
  const getSelectedAccountType = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const tableContent = list?.map((item, index) => (
    <AccountTypesRow
      key={index}
      data={item}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Account Types</Typography>
            <button
              onClick={() => setShowForm(true)}
              className="btn btn-danger"
            >
              Add Account Type
            </button>
          </div>
          <AccountTypesForm
            onHide={hideFormModal}
            show={showForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Name</th>
                      <th>Type</th>
                      <th className="min-w-120px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Account Types to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
