import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useMemo } from "react";
import Svg from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Input } from "../";
import { PAYMENT_PROOF, PAYMENT_SETTINGS } from "../../../../../app/routesMap";
import { actions as accountBalanceActions } from "../../../../../redux/state/accountBalance";
import { actions } from "../../../../../redux/state/companyCredit";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ConfirmButton } from "./ConfirmButton";

export const B2BWallet = ({
  formik,
  price,
  isIssueAdmin,
  loading,
  companyId,
  noCredit,
}) => {
  const dispatch = useDispatch();
  const { data: accountBalanceData } = useSelector(
    ({ accountBalance }) => accountBalance.balance
  );
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { availableBalance: availableCredit } =
    useSelector(({ companyCredit }) => companyCredit.creditDetails.data) || {};
  useEffect(() => {
    if (companyInUserId) {
      dispatch(
        actions.getCreditDetailsByCompanyId({
          companyId: isIssueAdmin ? companyId : companyInUserId,
        })
      );
      dispatch(
        accountBalanceActions.getAccountBalance({
          companyId: isIssueAdmin ? companyId : companyInUserId,
        })
      );
    }
  }, [dispatch, companyInUserId, isIssueAdmin, companyId]);
  const { pinNumber } =
    useSelector(({ company }) => company.companyData.data) || {};
  const { availableBalance } = accountBalanceData || {};
  const isInsufficientBalance = useMemo(
    () => +price > +availableBalance && +price > +(availableCredit || 0),
    [availableBalance, availableCredit, price]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <Typography
              variant="h5"
              className="ml-5 text-dark font-weight-bold"
            >
              Available Balance
            </Typography>
            <Typography
              variant="h5"
              className={clsx("ml-5 font-weight-bold", {
                "text-success": +availableBalance > +price,
                "text-danger": +availableBalance < +price,
              })}
            >
              ${availableBalance}
            </Typography>
          </div>
          {!noCredit && (
            <div className="d-flex justify-content-between">
              <Typography
                variant="h5"
                className="ml-5 text-dark font-weight-bold"
              >
                Available Credit
              </Typography>
              <Typography
                variant="h5"
                className={clsx("ml-5 font-weight-bold", {
                  "text-success": +availableCredit > +price,
                  "text-danger": +availableCredit < +price,
                })}
              >
                ${availableCredit}
              </Typography>
            </div>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="h-100 overflow-hidden">
          <div className="bg-primary w-100 h-80px d-flex justify-content-center align-items-center">
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/wallet.svg")} />
            <Typography variant="h3" className="ml-5 text-white">
              B2B wallet
            </Typography>
          </div>
          {isInsufficientBalance && pinNumber && (
            <div className="w-100 min-h-100px d-flex flex-column justify-content-center align-items-center">
              <Typography className="text-muted font-weight-bold" variant="h5">
                Insufficient Balance
              </Typography>
              <Typography className="text-muted font-weight-bold" variant="h6">
                <NavLink
                  to={PAYMENT_PROOF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add Balance
                </NavLink>
              </Typography>
            </div>
          )}
          {!pinNumber && !isIssueAdmin && (
            <div className="w-100 min-h-100px d-flex flex-column justify-content-center align-items-center">
              <Typography className="text-muted font-weight-bold" variant="h5">
                Please create pin number first
              </Typography>
              <Typography className="text-muted font-weight-bold" variant="h6">
                <NavLink
                  to={PAYMENT_SETTINGS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pin Settings
                </NavLink>
              </Typography>
            </div>
          )}
          {!isInsufficientBalance && pinNumber && !isIssueAdmin && (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} className="p-12">
                <Grid item xs={12} sm={12}>
                  <Input
                    type="password"
                    outlined={false}
                    placeholder="*****************"
                    label="PIN*"
                    showErrors={formik.touched["pin"]}
                    errorMessage={formik.errors["pin"]}
                    {...formik.getFieldProps("pin")}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-end"
                >
                  <ConfirmButton loading={loading} />
                </Grid>
              </Grid>
            </form>
          )}
          {isIssueAdmin && (
            <Grid container spacing={3} className="p-12">
              <Grid item sm={12} xs={12} className="d-flex justify-content-end">
                <ConfirmButton
                  onClick={formik.handleSubmit}
                  loading={loading}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
