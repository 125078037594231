import { Checkbox, withStyles } from "@material-ui/core";
import React from "react";

export const GreenCheckbox = withStyles({
  root: {
    color: "#B5B5C3",
    "&$checked": {
      color: "#0BB783",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
