import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useSubPermissions(parentId) {
  const { list } = useSelector(({ user }) => user.currentUserPermissions);

  return useMemo(() => {
    let subPermissionsList = [];
    if (list?.length) {
      subPermissionsList = list
        // .filter((permission) => parentId === permission.parentId)
        .map(({ permissionId }) => permissionId);
    }
    return subPermissionsList;
  }, [list, parentId]);
}
