import * as Yup from "yup";


export const markupSchema = Yup.object().shape({
  // MarkupId: "GUID", //backend
  ComponentType: Yup.string(), //F only
  MarkupType: Yup.string(), // B2C for agency B2B for superadmin
  RuleType: Yup.string(),
  RuleName: Yup.string(),
  Amount: Yup.number(),
  IsPercent: Yup.bool(), // fixed
  IsPaxWise: Yup.bool(), // fixed
  IsOnTotalFare: Yup.bool(), // fixed
  MinAmount: Yup.number(),
  MaxAmount: Yup.number(),
  JourneyType: Yup.mixed(),
  FareType: Yup.string(),
  Classes: Yup.string(),
  StopOvers: Yup.mixed(),
  Airlines: Yup.string(),
  Airlines2: Yup.string(),
  AgencyIds: Yup.string(), // fixed
  SubAgencyIds: Yup.string(), // null
  Suppliers: Yup.string(),
  PCC: Yup.string(), // fixed
  EngineType: Yup.string(), // api user
  PassengerType: Yup.string(),
  Passengers: Yup.string(),
  DepartCountry: Yup.array().of(Yup.string()),
  // DepartCountry2: Yup.array().of(Yup.string()),
  DepartAirport: Yup.array().of(Yup.string()),
  // DepartAirport2: Yup.array().of(Yup.string()),
  ArrivalCountry: Yup.array().of(Yup.string()),
  // ArrivalCountry2: Yup.array().of(Yup.string()),
  ArrivalAirport: Yup.array().of(Yup.string()),
  // ArrivalAirport2: Yup.array().of(Yup.string()),
  DepartDates: Yup.array().of(
    Yup.object().shape({
      From: Yup.string(),
      To: Yup.string(),
    })
  ),
  ArrivalDates: Yup.array().of(
    Yup.object().shape({
      From: Yup.string(),
      To: Yup.string(),
    })
  ),
  ArrivalTimes: Yup.array().of(
    Yup.object().shape({
      Day: Yup.string(),
      Times: Yup.array().of(
        Yup.object().shape({
          From: Yup.string(),
          To: Yup.string(),
        })
      ),
    })
  ),
  DepartTimes: Yup.array().of(
    Yup.object().shape({
      Day: Yup.string(),
      Times: Yup.array().of(
        Yup.object().shape({
          From: Yup.string(),
          To: Yup.string(),
        })
      ),
    })
  ),
  OLTFrom: Yup.number(),
  OLTTo: Yup.number(),
  OBC: Yup.string(),
  IBC: Yup.string(),
  FlightDurationFrom: Yup.number(),
  FlightDurationTo: Yup.number(),
  markupStatus: Yup.bool(),
});
