import { useMemo } from "react";
import usePkgRoomTypesList from "./usePkgRoomTypesList";

export default function usePkgRoomTypesOptions() {
  const { list, loading } = usePkgRoomTypesList();
  const pkgRoomTypesOptions = useMemo(
    () =>
      list?.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
    [list]
  );
  return { pkgRoomTypesOptions, loading };
}
