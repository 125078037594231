import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SubscriptionDetails } from "../../_metronic/layout/components/account/Subscription/SubscriptionDetails";
import { SubscriptionExpired } from "../../_metronic/layout/components/account/Subscription/SubscriptionExpired";
import { SubscriptionPayment } from "../../_metronic/layout/components/account/Subscription/SubscriptionPayment";
import {
  SUBSCRIPTION,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_EXPIRED,
  SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_PAYMENT_SUCCESS,
} from "../routesMap";
import { SubscriptionSuccess } from "../../_metronic/layout/components/account/Subscription/SubscriptionSuccess";

export const SubscriptionRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={SUBSCRIPTION_DETAILS}
        component={SubscriptionDetails}
      />
      <Route
        exact
        path={SUBSCRIPTION_PAYMENT_SUCCESS}
        component={SubscriptionSuccess}
      />
      <Route
        exact
        path={SUBSCRIPTION_PAYMENT}
        component={SubscriptionPayment}
      />
      <Route
        exact
        path={SUBSCRIPTION_EXPIRED}
        component={SubscriptionExpired}
      />
      <Redirect from={SUBSCRIPTION} to={SUBSCRIPTION_DETAILS} />
    </Switch>
  );
};
