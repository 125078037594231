import { Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUserName from "../../../../../../../app/helpers/hooks/useUserName";
import { actions } from "../../../../../../../redux/state/accountBalance";
import {
  BALANCE_UPDATE,
  PAYMENT_TYPE_ISSUE,
  PAYMENT_TYPE_REFUND,
  PAYMENT_TYPE_REFUND_FEE,
  PAYMENT_TYPE_REISSUE,
  PAYMENT_TYPE_REISSUE_REFUND,
  PAYMENT_TYPE_SUBSCRIPTION,
  PAYMENT_TYPE_TOPUP,
  PAYMENT_TYPE_VOID,
  PAYMENT_TYPE_VOID_FEE,
} from "../../../../../../../redux/state/accountBalance/accountBalanceConstants";
import { filterOptions } from "../../../../../../../redux/state/invoices/invoicesConstants";
import { getDateTime } from "../../../../../../_helpers/date";
import { ResponsiveRow } from "../../../../common/ResponsiveRow/ResponsiveRow";

export const paymentTypeText = {
  [PAYMENT_TYPE_VOID]: "Void",
  [PAYMENT_TYPE_REFUND]: "Refund",
  [PAYMENT_TYPE_ISSUE]: "Issue Tickets",
  [PAYMENT_TYPE_REISSUE]: "Reissue",
  [PAYMENT_TYPE_REFUND_FEE]: "Refund Fee",
  [PAYMENT_TYPE_TOPUP]: "Top up",
  [PAYMENT_TYPE_VOID_FEE]: "Void Fee",
  [PAYMENT_TYPE_REISSUE_REFUND]: "Reimbursement for Reissue",
  [BALANCE_UPDATE]: "Flyme Balance Update",
  [PAYMENT_TYPE_SUBSCRIPTION]: "Subscription",
};

export const TransactionsTableRow = ({ data }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    createdAt,
    bookingTransactionId,
    currency,
    type,
    paymentAmount,
    status,
    remarks,
    availableBalanceAfterTransaction,
    payableAmount,
    createdBy,
  } = data;
  const { date, time } = getDateTime(createdAt);
  const isNegative = type === "OUT";
  const isPositive = type === "IN";
  const { getUserName } = useUserName();
  const userName = getUserName(createdBy);

  const rows = (
    <>
      <td>
        <span className="font-weight-bold">{bookingTransactionId || "-"}</span>
      </td>
      {isAdmin && matchesSm && (
        <td className="font-weight-bold">{userName || "-"}</td>
      )}
      <td>
        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      {matchesSm && (
        <td>
          <span className="font-weight-bold">{remarks}</span>
        </td>
      )}
      <td>
        <span>{paymentTypeText[status] || "-"}</span>
      </td>
      <td>
        <span
          className={clsx("font-weight-bold", {
            "text-danger": isNegative,
            "text-success": isPositive,
          })}
        >
          {type && (
            <>
              {isNegative ? "-" : "+"}
              {currency}
              {paymentAmount.toFixed(2)}
            </>
          )}
        </span>
      </td>
      {matchesSm && (
        <td>
          <span className="font-weight-bold">
            {currency}
            {+availableBalanceAfterTransaction.toFixed(2)}
          </span>
        </td>
      )}
      {matchesSm && (
        <td>
          <span className="font-weight-bold">
            {currency}
            {+(payableAmount || 0).toFixed(2)}
          </span>
        </td>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Created By",
      value: userName || "-",
    },
    {
      label: "remark",
      value: remarks || "-",
    },
    {
      label: "Available balance",
      value: `${currency}${+availableBalanceAfterTransaction.toFixed(2)}`,
    },
    {
      label: "Payable balance",
      value: `${currency}${+(payableAmount || 0).toFixed(2)}`,
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const BillingHistory = ({ companyId }) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const { list, paginationInfo } = useSelector(
    ({ accountBalance }) => accountBalance.transactionsHistory
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { totalPages } = paginationInfo ?? {};
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    dispatch(
      actions.getAllTransactionsHistory({
        filterParam: [
          {
            columnName: "companyId",
            filterValue: `${companyId}`,
            filterOption: filterOptions.IsEqualTo,
          },
        ],
        pageNumber,
      })
    );
  }, [dispatch, pageNumber, companyId]);
  // const data = [
  //   {
  //     dateTime: "2021-12-11T21:06:06.955Z",
  //     transactionCode: "BG584982",
  //     amount: "685.00",
  //     status: "Completed",
  //     invoice: "File_name.PDF",
  //     remark: "remark",
  //   },
  // ];
  const tableContent = list?.map((item, index) => (
    <TransactionsTableRow key={`row_${index}`} data={item} />
  ));
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Paper id="billingHistory" className="h-100 p-7">
      <div className="d-flex justify-content-between">
        <Typography variant="h6">Billing History</Typography>
      </div>
      <div className="table-responsive">
        {/* <CancelConfirmation
        id={selectedId}
        show={showCancelModal}
        onHide={onHideModal}
      /> */}
        <table className="table table-vertical-center mb-0 mt-5">
          <thead>
            <tr className="bg-gray-100">
              {<th>Booking Id</th>}
              {isAdmin && matchesSm ? <th>Created By</th> : null}
              <th className="min-w-100px">Date</th>
              {matchesSm && <th>remark</th>}
              <th>Status</th>
              <th>Amount</th>
              {matchesSm && <th>Available balance</th>}
              {matchesSm && <th>Payable balance</th>}
              {/* <th className="text-center">Action</th> */}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
        {list?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No Records to show
          </Typography>
        )}
        <div className="d-flex justify-content-center">
          <Pagination
            className="mx-auto mt-2"
            onChange={handlePageChange}
            page={pageNumber}
            count={totalPages}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </Paper>
  );
};
