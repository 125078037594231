import { accountingClient } from "./client";
import { bookingClient } from "./client";
import { reportsClient } from "./client";

export const updateInvoiceDueDate = ({ bookingId, remarks, dueDate }) =>
  accountingClient.post("/accounting/v1/updateInvoiceDueDate", {
    bookingId,
    remarks,
    dueDate,
  });

export const getClearedInvoices = ({ topupId }) =>
  accountingClient.post(`/accounting/v1/ClearInvoicesOnTopup/${topupId}`);

export const getRouteSearches = ({
  agencyId,
  fromCode,
  toCode,
  fromDate,
  toDate,
}) =>
  reportsClient.post(`reporting/v1/operational-dashboard/SearchRouteDetails`, {
    agencyId,
    from: fromCode,
    to: toCode,
    fromDate,
    toDate,
  });

export const getRouteDetails = ({ agencyId, fromCode, toCode }) =>
  reportsClient.post(`reporting/v1/operational-dashboard/RouteDetails`, {
    agencyId,
    from: fromCode,
    to: toCode,
  });

export const getAgencyRoutes = ({ id, fromDate, toDate }) =>
  reportsClient.get(
    `reporting/v1/operational-dashboard/AgencyRoutesStats/${id}`,
    {
      params: {
        fromDate,
        toDate,
      },
    }
  );

export const getCurrencies = () =>
  accountingClient.get("/accounting/v1/currency");

export const getInvoices = () => accountingClient.get("/accounting/v1/invoice");

export const filterWeeklyTargets = ({ sortingParams, filterParam }) =>
  accountingClient.post(`/accounting/v1/weeklytarget/FilterWeeklyTarget`, {
    sortingParams: sortingParams || [{}],
    filterParam: filterParam || [],
  });

export const setWeeklyTarget = ({ from, to, target }) =>
  accountingClient.post(`/accounting/v1/weeklytarget`, { from, to, target });

export const getSalesGraphData = ({ id }) =>
  bookingClient.get(`/booking/v1/GraphSalesDashboard/${id ?? 0}`);

export const editWeeklyTarget = ({ id, ...rest }) =>
  accountingClient.put(`/accounting/v1/weeklytarget/${id}`, { id, ...rest });

export const editCurrencyRate = ({
  id,
  fromCurrency,
  toCurrency,
  conversionRate,
}) =>
  accountingClient.put(`/accounting/v1/currency/${id}`, {
    id,
    fromCurrency,
    toCurrency,
    conversionRate,
  });

export const getAccountTypes = () =>
  accountingClient.get("/accounting/v1/account_type");

export const createAccountType = (params) =>
  accountingClient.post("/accounting/v1/account_type", params);

export const editAccountType = ({ id, ...params }) =>
  accountingClient.put(`/accounting/v1/account_type/${id}`, { id, ...params });

export const getChartOfAccounts = () =>
  accountingClient.get("/accounting/v1/charts");

export const createChartOfAccount = (params) =>
  accountingClient.post("/accounting/v1/charts", params);

export const editChartOfAccount = ({ id, ...params }) =>
  accountingClient.put(`/accounting/v1/charts/${id}`, { id, ...params });
