import React, { useEffect, useState } from "react";
import { Grid, Paper, withStyles, Tabs, Tab } from "@material-ui/core";
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
  Switch,
  Route,
  Redirect,
} from "react-router";
import {
  ADMIN_STAFF_DOCUMENTS,
  ADMIN_STAFF_PERMISSIONS,
  ADMIN_STAFF_SECURITY,
  ADMIN_STAFF_SETTINGS,
  ADMIN_STAFF_VIEW,
} from "../../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { StaffView } from "./staffView/StaffView";
import { StaffSettings } from "./staffSettings/StaffSettings";
import { StaffSecurity } from "./staffSecurity/StaffSecurity";
import { StaffInfoBar } from "./StaffInfoBar";
import { StaffDocuments } from "./staffDocuments/StaffDocuments";
import { StaffPermissions } from "./staffPermissions/StaffPermissions";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

const tabsRoutes = {
  View: ADMIN_STAFF_VIEW,
  Settings: ADMIN_STAFF_SETTINGS,
  Security: ADMIN_STAFF_SECURITY,
  Permissions: ADMIN_STAFF_PERMISSIONS,
  // Documents: ADMIN_STAFF_DOCUMENTS,
};

export const StaffMember = () => {
  const [selectedTab, setSelectedTab] = useState("View");
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    Object.keys(tabsRoutes).forEach((key) => {
      const match = matchPath(location.pathname, {
        path: tabsRoutes[key],
        exact: true,
        strict: false,
      });
      if (match) setSelectedTab(key);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StaffInfoBar />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => {
              history.push(parseRoute(tabsRoutes[newValue], { id }));
              setSelectedTab(newValue);
            }}
            indicatorColor="primary"
          >
            {Object.keys(tabsRoutes).map((key) => (
              <Tab value={key} key={key} label={key} />
            ))}
          </AntTabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route path={ADMIN_STAFF_VIEW} component={StaffView} />
          <Route path={ADMIN_STAFF_SETTINGS} component={StaffSettings} />
          <Route path={ADMIN_STAFF_SECURITY} component={StaffSecurity} />
          {/* <Route path={ADMIN_STAFF_DOCUMENTS} component={StaffDocuments} /> */}
          <Route path={ADMIN_STAFF_PERMISSIONS} component={StaffPermissions} />
          <Redirect to={ADMIN_STAFF_VIEW} />
        </Switch>
      </Grid>
    </Grid>
  );
};
