import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Grid } from "@material-ui/core/";
import styles from "./FlightSearch.module.scss";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { DepartingFlights } from "./DapartingFlights";
import { PnrSearchCard } from "./PnrSearchCard";
import { FlightSearchCard } from "./FlightSearchCard";
import {
  FLIGHT_SEARCH,
  PNR_SEARCH,
  PACKAGE_SEARCH,
} from "../../../../app/routesMap";
import { useMemo } from "react";
import { PackageSearchCard } from "./PackageSearchCard";

const tabs = {
  [FLIGHT_SEARCH]: { name: "flight" },
  [PNR_SEARCH]: { name: "pnr" },
  [PACKAGE_SEARCH]: { name: "umrah" },
};

export const SearchCard = ({ isPnr }) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("flight");
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  useEffect(() => {
    setSelectedTab(tabs[location.pathname]?.name ?? "flight");
  }, [location.pathname]);
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const permissionIdsList = list?.map(({ permissionId }) => permissionId);
  const SearchCardContent = useMemo(() => {
    switch (selectedTab) {
      case "flight":
        return FlightSearchCard;
      case "pnr":
        return PnrSearchCard;
      case "umrah":
        return PackageSearchCard;
      default:
        return FlightSearchCard;
    }
  }, [selectedTab]);
  // useEffect(() => {
  //   if (!searchDetails?.isFromHome) {
  //     dispatch(actions.reset());
  //   }
  // }, [selectedTab]);
  return (
    <div>
      <div className="d-flex overflow-auto h-55px">
        <ul className="nav nav-stretch ">
          <li
            className={clsx(
              styles.tab,
              selectedTab === "flight" && styles.activeButton
            )}
            hidden={!isAdmin ? false : !permissionIdsList?.includes(12)}
          >
            <button
              onClick={() => history.push(FLIGHT_SEARCH)}
              className={`btn `}
            >
              <span className="svg-icon svg-icon-2 mr-2">
                <SVG
                  className={
                    selectedTab === "flight" ? styles.svgActive : styles.svg
                  }
                  src="/media/svg/icons/General/plane.svg"
                />
              </span>
              <span
                className={clsx(
                  "font-weight-bold",
                  selectedTab === "flight" ? "text-dark" : "text-white"
                )}
              >
                Flight search
              </span>
            </button>
          </li>
          <li
            className={clsx(
              styles.tab,
              selectedTab === "pnr" && styles.activeButton
            )}
            hidden={!isAdmin ? false : !permissionIdsList?.includes(13)}
          >
            <button onClick={() => history.push(PNR_SEARCH)} className={`btn `}>
              <span className="svg-icon svg-icon-2 mr-2">
                <SVG
                  className={
                    selectedTab === "pnr" ? styles.svgActive : styles.svg
                  }
                  src="/media/svg/icons/General/ticket.svg"
                />
              </span>
              <span
                className={clsx(
                  "font-weight-bold",
                  selectedTab === "pnr" ? "text-dark" : "text-white"
                )}
              >
                PNR search
              </span>
            </button>
          </li>
          {/* <li
            className={clsx(
              styles.tab,
              selectedTab === "umrah" && styles.activeButton
            )}
            // hidden={!isAdmin ? false : !permissionIdsList?.includes(13)}
          >
            <button
              onClick={() => history.push(PACKAGE_SEARCH)}
              className={`btn `}
            >
              <span className="svg-icon svg-icon-2 mr-2">
                <SVG
                  className={
                    selectedTab === "umrah" ? styles.svgActive : styles.svg
                  }
                  src="/media/svg/icons/General/kaaba.svg"
                />
              </span>
              <span
                className={clsx(
                  "font-weight-bold",
                  selectedTab === "umrah" ? "text-dark" : "text-white"
                )}
              >
                Umrah
              </span>
            </button>
          </li> */}
        </ul>
      </div>
      <div className={clsx("card ", styles.cardSpecial)}>
        <div className={clsx("card-body py-7", styles.cardContent)}>
          <SearchCardContent />
        </div>
      </div>
    </div>
  );
};

export const FlightSearch = () => {
  const { searchRQ } = useSelector(
    ({ flightSearch }) => flightSearch.searchResults
  );

  // useEffect(() => {
  //   return () => dispatch(actions.reset());
  // }, [dispatch]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SearchCard />
      </Grid>

      {searchRQ && (
        <Grid item xs={12}>
          <DepartingFlights />
        </Grid>
      )}
    </Grid>
  );
};
