import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/dict";

export default function useAirportsList() {
  const dispatch = useDispatch();
  const { list, loading, success, failure } = useSelector(
    ({ dict }) => dict.airports
  );
  useEffect(() => {
    if (!list && !loading) {
      dispatch(actions.getAirports());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, list]);
  return { airportsList: list, loading, success, failure };
}
