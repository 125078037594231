import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/banks";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { BankForm } from "./BankForm";
import { BankRow } from "./BankRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

const bankSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank name is required"),
  // country: Yup.string().required("country is required"),
});
export const Banks = ({ permissionId }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ banks }) => banks.allBanks);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ banks }) => banks.deleteBank);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ banks }) => banks.createBank);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ banks }) => banks.editBank);
  const fields = useFormik({
    initialValues: {
      bankName: "",
      country: "",
      isActive: true,
    },
    validationSchema: bankSchema,
    onSubmit: ({ bankName, country, isActive }) => {
      if (!!selectedId) {
        const data = getSelectedBank(selectedId);
        dispatch(
          actions.editBank({ ...data, name: bankName, country, isActive })
        );
      } else {
        dispatch(actions.createBank({ name: bankName, country, isActive }));
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllBanks());
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowBankForm(false);
    dispatch(actions.clearCreateBank());
    dispatch(actions.clearEditBank());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Bank Added",
        })
      );
      dispatch(actions.getAllBanks());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateBank());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Bank Edited",
        })
      );
      dispatch(actions.getAllBanks());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeleteBank());
    setSelectedId(undefined);
  }, [dispatch]);
  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(actions.clearEditBank());
  //   }
  // }, [dispatch, editSuccess]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deleteBank({ id: selectedId }));
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeleteBank());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { name, country, isActive } = getSelectedBank(id);
    fields.setValues({ bankName: name, country, isActive });
    setShowBankForm(true);
  };
  const getSelectedBank = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  // const deleteBank = () => {
  //   dispatch(actions.deleteTopUp({ id: selectedId }));
  // };
  const allBanksSubPermission =
    adminPermissionsIds.payment.banks.subPermissions;
  const tableContent = list?.map((item, index) => (
    <BankRow
      key={index}
      subPermissions={subPermissions}
      data={item}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Banks</Typography>
            <button
              hidden={!subPermissions.includes(allBanksSubPermission.add.id)}
              onClick={() => setShowBankForm(true)}
              className="btn btn-danger"
            >
              Add bank
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete bank"
            body="Are you sure you want to delete this bank?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
          />
          {/* <BankForm
            onHide={() => setShowEdit(false)}
            show={showEdit}
            data={getSelectBooking()}
            success={editSuccess}
            loading={editLoading}
            fields={fields}
          /> */}
          <BankForm
            onHide={hideFormModal}
            show={showBankForm}
            success={createSuccess || editSuccess}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Bank Name</th>
                      <th>Status</th>
                      <th>Country</th>
                      {matchesSm && <th>Creation Date</th>}
                      <th className="min-w-75px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No banks to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
