import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./accountingConstants";

export const EDIT_CURRENCY_RATE = requestActions(
  MODULE_NAME,
  "EDIT_CURRENCY_RATE"
);
export const GET_CURRENCIES = requestActions(MODULE_NAME, "GET_CURRENCIES");
export const GET_INVOICES = requestActions(MODULE_NAME, "GET_INVOICES");
export const GET_EXPENSES = requestActions(MODULE_NAME, "GET_EXPENSES");
export const GET_ACCOUNT_TYPES = requestActions(
  MODULE_NAME,
  "GET_ACCOUNT_TYPES"
);
export const CREATE_ACCOUNT_TYPE = requestActions(
  MODULE_NAME,
  "CREATE_ACCOUNT_TYPE"
);
export const EDIT_ACCOUNT_TYPE = requestActions(
  MODULE_NAME,
  "EDIT_ACCOUNT_TYPE"
);
export const GET_CHART_OF_ACCOUNTS = requestActions(
  MODULE_NAME,
  "GET_CHART_OF_ACCOUNTS"
);
export const CREATE_CHART_OF_ACCOUNT = requestActions(
  MODULE_NAME,
  "CREATE_CHART_OF_ACCOUNT"
);
export const EDIT_CHART_OF_ACCOUNT = requestActions(
  MODULE_NAME,
  "EDIT_CHART_OF_ACCOUNT"
);
export const SET_WEAKLY_TARGET = requestActions(
  MODULE_NAME,
  "SET_WEAKLY_TARGET"
);
export const EDIT_WEAKLY_TARGET = requestActions(
  MODULE_NAME,
  "EDIT_WEAKLY_TARGET"
);
export const FILTER_WEAKLY_TARGETS = requestActions(
  MODULE_NAME,
  "FILTER_WEAKLY_TARGETS"
);
export const GET_SALES_GRAPH_DATA = requestActions(
  MODULE_NAME,
  "GET_SALES_GRAPH_DATA"
);
export const GET_AGENCY_ROUTES = requestActions(
  MODULE_NAME,
  "GET_AGENCY_ROUTES"
);
export const GET_ROUTE_DETAILS = requestActions(
  MODULE_NAME,
  "GET_ROUTE_DETAILS"
);
export const GET_ROUTE_SEARCHES = requestActions(
  MODULE_NAME,
  "GET_ROUTE_SEARCHES"
);
export const GET_TOP_UP_CLEARED_INVOICES = requestActions(
  MODULE_NAME,
  "GET_TOP_UP_CLEARED_INVOICES"
);
export const UPDATE_INVOICE_DUE_DATE = requestActions(
  MODULE_NAME,
  "UPDATE_INVOICE_DUE_DATE"
);
export const RESET = `${MODULE_NAME}/RESET`;

export const updateInvoiceDueDate = ({ bookingId, remarks, dueDate }) =>
  requestApiCall(
    "updateInvoiceDueDate",
    { bookingId, remarks, dueDate },
    UPDATE_INVOICE_DUE_DATE
  );

export const getClearedInvoices = ({ topupId }) =>
  requestApiCall(
    "getClearedInvoices",
    { topupId },
    GET_TOP_UP_CLEARED_INVOICES
  );

export const getRouteSearches = (params) =>
  requestApiCall("getRouteSearches", params, GET_ROUTE_SEARCHES);

export const getRouteDetails = (params) =>
  requestApiCall("getRouteDetails", params, GET_ROUTE_DETAILS);

export const getAgencyRoutes = (params) =>
  requestApiCall("getAgencyRoutes", params, GET_AGENCY_ROUTES);

export const getSalesGraphData = (params) =>
  requestApiCall("getSalesGraphData", params, GET_SALES_GRAPH_DATA);

export const filterWeeklyTargets = (params) =>
  requestApiCall("filterWeeklyTargets", params, FILTER_WEAKLY_TARGETS);

export const editWeeklyTarget = (params) =>
  requestApiCall("editWeeklyTarget", params, EDIT_WEAKLY_TARGET);

export const setWeeklyTarget = (params) =>
  requestApiCall("setWeeklyTarget", params, SET_WEAKLY_TARGET);

export const getAccountTypes = () =>
  requestApiCall("getAccountTypes", {}, GET_ACCOUNT_TYPES);

export const createAccountType = (params) =>
  requestApiCall("createAccountType", params, CREATE_ACCOUNT_TYPE);

export const editAccountType = (params) =>
  requestApiCall("editAccountType", params, EDIT_ACCOUNT_TYPE);

export const getChartOfAccounts = () =>
  requestApiCall("getChartOfAccounts", {}, GET_CHART_OF_ACCOUNTS);

export const createChartOfAccount = (params) =>
  requestApiCall("createChartOfAccount", params, CREATE_CHART_OF_ACCOUNT);

export const editChartOfAccount = (params) =>
  requestApiCall("editChartOfAccount", params, EDIT_CHART_OF_ACCOUNT);

export const editCurrencyRate = (params) =>
  requestApiCall("editCurrencyRate", params, EDIT_CURRENCY_RATE);

export const getInvoices = (params) =>
  requestApiCall("getInvoices", params, GET_INVOICES);

export const getExpenses = (params) =>
  requestApiCall("getExpenses", params, GET_EXPENSES);

export const getCurrencies = (params) =>
  requestApiCall("getCurrencies", params, GET_CURRENCIES);

export const clearCreateAccountType = () => ({
  type: CREATE_ACCOUNT_TYPE.CLEAR,
});

export const clearEditAccountType = () => ({
  type: EDIT_ACCOUNT_TYPE.CLEAR,
});

export const clearCreateChartOfAccount = () => ({
  type: CREATE_CHART_OF_ACCOUNT.CLEAR,
});

export const clearEditChartOfAccount = () => ({
  type: EDIT_CHART_OF_ACCOUNT.CLEAR,
});

export const clearSetWeaklyTarget = () => ({
  type: SET_WEAKLY_TARGET.CLEAR,
});

export const clearUpdateInvoiceDueDate = () => ({
  type: UPDATE_INVOICE_DUE_DATE.CLEAR,
});

export const clearEditWeaklyTarget = () => ({
  type: EDIT_WEAKLY_TARGET.CLEAR,
});

export const reset = () => ({ type: RESET });
