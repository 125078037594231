import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import SVG from 'react-inlinesvg'

import { Wrapper } from "../../../../_metronic/layout/components/ticketSummary/common/Wrapper";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { SIGN_IN } from "../../../routesMap";

export const SessionTimeoutPage = () => {
  return (
    <Wrapper>
      <Paper className="h-100 p-8 d-flex flex-column justify-content-center align-items-center">
        <span className='svg-icon svg-icon-primary svg-icon-10x'>
          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")}/>
        </span>
        <Typography
          variant="h4"
          className="max-w-500px text-center font-weight-bold mt-8 mb-1"
        >
          Session Timeout!
        </Typography>
        <Typography
          variant="h6"
          className="max-w-500px text-center font-weight-normal mb-8"
        >
          Please <NavLink to={SIGN_IN}>Login</NavLink> Again.
        </Typography>
      </Paper>
    </Wrapper>
  );
};
