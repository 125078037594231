import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../_helpers";

export const PublicHeader = ({ noSpacing }) => {
  return (
    <div
      className={clsx("bg-white d-flex justify-content-center py-5", {
        "mb-10": !noSpacing,
      })}
    >
      <Link to="/">
        <img
          alt="logo"
          src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
        />
      </Link>
    </div>
  );
};
