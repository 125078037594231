import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Svg from "react-inlinesvg";
import { isNil } from "lodash-es";

import { getFilterObject } from "../../reports/common/reportsSearchCard/ReportsSearchCard";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { DateRangeModal } from "../../reports/common/reportsSearchCard/DateRangeModal";
import { Button, Input, Select } from "../../../common";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { creditTypeOptions } from "../../../../../_assets/js/components/options";
import useAgenciesOptions from "../../../../../../app/helpers/hooks/useAgenciesOptions";

const StatusOptions = [
  { label: "All Status", value: "" },
  { label: "Active", value: "true" },
  { label: "InActive", value: "false" },
];

export const UsersSearchCard = ({
  setDateRange,
  onSearch,
  onReset,
  isStaff,
}) => {
  const [showModal, setShowModal] = useState(null);
  const { agencyOptions } = useAgenciesOptions();

  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const getStatusValue = () => {
    switch (fields.values.status) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return undefined;
    }
  };
  const fields = useFormik({
    initialValues: {
      name: "",
      status: "",
      agencyId: "",
      fromDate: "",
      toDate: "",
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      agencyId: Yup.string(),
      fromDate: Yup.string(),
      toDate: Yup.string(),
      email: Yup.string(),
    }),
    onSubmit: ({ fromDate, agencyId, toDate, status, name, email }) => {
      setDateRange({ fromDate, toDate });
      onSearch([
        ...getFilterObject("name", name, filterOptions.Contains),
        ...getFilterObject("email", email, filterOptions.Contains),
        ...(status
          ? [
              {
                columnName: "isActive",
                filterValue: getStatusValue(status),
                filterOption: filterOptions.IsEqualTo,
              },
            ]
          : []),
        ...getFilterObject(
          "companyInUserId",
          agencyId,
          filterOptions.IsEqualTo
        ),
        ...getFilterObject(
          "CreatedAt",
          `${fromDate}`,
          filterOptions.IsGreaterThanOrEqualTo
        ),
        ...getFilterObject(
          "CreatedAt",
          `${toDate}`,
          filterOptions.IsLessThanOrEqualTo
        ),
      ]);
    },
  });
  const {
    handleSubmit,
    setFieldValue,
    values,
    getFieldProps,
    setValues,
  } = fields;

  const handleReset = () => {
    setValues({
      name: "",
      agencyId: "",
      fromDate: "",
      toDate: "",
    });
    onReset();
    setDateRange({ fromDate: "", toDate: "" });
  };
  return (
    <form onSubmit={handleSubmit}>
      <DateRangeModal fields={fields} show={showModal} onHide={handleClose} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={2}>
          <Input {...getFieldProps("name")} placeholder="Name" />
        </Grid>
        <Grid item xs={6} md={2}>
          <Input {...getFieldProps("email")} placeholder="Email" />
        </Grid>
        {!isStaff && (
          <Grid item xs={6} md={2}>
            <Select
              value={values.agencyId}
              onChange={(v) => setFieldValue("agencyId", v)}
              placeholder="Credit Type"
              options={[{ label: "All Agencies", value: "" }, ...agencyOptions]}
            />
          </Grid>
        )}
        <Grid item xs={6} md={2}>
          <Select
            value={values.status}
            onChange={(v) => setFieldValue("status", v)}
            placeholder="All Status"
            options={StatusOptions}
          />
        </Grid>
        <Grid item xs>
          <button
            onClick={handleClick}
            type="button"
            className="btn text-underlined d-flex align-items-center "
          >
            <Svg src={toAbsoluteUrl("/media/svg/icons/General/calender.svg")} />
            <span className="font-weight-bold ml-2">Date Filter</span>
          </button>
        </Grid>
        <Grid item xs>
          <div className="d-flex">
            <Button type="submit">Search</Button>
            <Button
              onClick={handleReset}
              className="ml-3"
              type="button"
              variant="primary-inverse"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
