import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography } from "@material-ui/core";

import { actions } from "../../../../redux/state/markup";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { getValues, optimizeDatesArray, optimizeTimesArray } from "./utils";
import { markupSchema } from "./schema";
import { MarkupForm } from "./MarkupForm";
import { useParams } from "react-router";
import { LoadingSpinner } from "../common";

export const EditMarkup = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, success, failure } = useSelector(
    ({ markup }) => markup.edit
  );
  const { companyInUserId, id: userId } = useSelector(
    ({ user }) => user.userData
  );
  const { data, success: getDataSuccess, loading: dataLoading } = useSelector(
    ({ markup }) => markup.specificMarkup
  );
  useEffect(() => {
    dispatch(actions.getSpecificMarkup({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id]);

  const formFields = useFormik({
    initialValues: undefined,
    validationSchema: markupSchema,
    onSubmit: ({
      JourneyType,
      FareType,
      Classes,
      Suppliers,
      Airlines,
      Airlines2,
      PassengerType,
      Passengers,
      DepartCountry,
      DepartAirport,
      ArrivalCountry,
      ArrivalAirport,
      ArrivalDates,
      ArrivalTimes,
      DepartDates,
      DepartTimes,
      StopOvers,
      AgencyIds,
      TripType,
      markupStatus: isActive,
      ...rest
    }) => {
      const markupParams = {
        ...rest,
        JourneyType: getValues(JourneyType),
        TripType: getValues(TripType),
        FareType: getValues(FareType),
        Classes: getValues(Classes),
        Suppliers: getValues(Suppliers),
        Airlines: getValues(Airlines),
        PassengerType: getValues(PassengerType),
        Passengers: getValues(Passengers),
        DepartCountry: getValues(DepartCountry),
        DepartAirport: getValues(DepartAirport),
        ArrivalCountry: getValues(ArrivalCountry),
        ArrivalAirport: getValues(ArrivalAirport),
        StopOvers: getValues(StopOvers),
        ArrivalDates: optimizeDatesArray(ArrivalDates),
        ArrivalTimes: optimizeTimesArray(ArrivalTimes),
        DepartDates: optimizeDatesArray(DepartDates),
        DepartTimes: optimizeTimesArray(DepartTimes),
        AgencyIds: getValues(AgencyIds),
      };
      const markupRuleJson = JSON.stringify(markupParams);
      dispatch(
        actions.editMarkup({
          ...data,
          markupRuleJson,
          companyId: companyInUserId,
          isActive,
          markupUpdatedById: userId,
        })
      );
    },
  });
  const [markupData, setMarkupData] = useState({});
  const { setValues, values } = formFields;
  useEffect(() => {
    if (getDataSuccess) {
      let markup = data.markupRuleJson;
      try {
        markup = JSON.parse(markup);
        setMarkupData(markup);
      } catch (error) {}
      setValues({
        ...markup,
        markupStatus: data.isActive,
      });
    }
  }, [data, getDataSuccess, setValues]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Markup Edited Successfully",
        })
      );
      // formFields.resetForm();
      dispatch(actions.reset());
    }
  }, [dispatch, formFields, success]);

  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, failure]);

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h5" className="font-weight-bold">
            Edit Markup - {markupData?.RuleName}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {dataLoading || !values ? (
          <LoadingSpinner />
        ) : (
          <MarkupForm isEdit={true} loading={loading} formFields={formFields} />
        )}
      </Grid>
    </Grid>
  );
};
