import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./companyCreditActions";

const initialState = {
  creditAllowance: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  creditDetails: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createEditCreditAllowance: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  addCredit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  creditAction: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  creditRequests: {
    loading: false,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
};

export const companyCreditReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.APPROVE_CREDIT.REQUESTED:
      case actions.REJECT_CREDIT.REQUESTED:
        state.creditAction.loading = true;
        break;
      case actions.APPROVE_CREDIT.SUCCEEDED:
      case actions.REJECT_CREDIT.SUCCEEDED:
        state.creditAction.loading = false;
        state.creditAction.data = payload.data.data;
        if (payload.data.status === "200") {
          state.creditAction.success = payload.data.message;
        } else {
          state.creditAction.failure = payload?.data?.message;
        }
        break;
      case actions.APPROVE_CREDIT.FAILED:
      case actions.REJECT_CREDIT.FAILED:
        state.creditAction.loading = false;
        state.creditAction.failure = payload.error;
        break;
      case actions.APPROVE_CREDIT.CLEAR:
      case actions.REJECT_CREDIT.CLEAR:
        state.creditAction = initialState.creditAction;
        break;
      case actions.GET_COMPANY_CREDIT.REQUESTED:
      case actions.GET_ِALL_COMPANY_CREDIT.REQUESTED:
        state.creditRequests.loading = true;
        break;
      case actions.GET_COMPANY_CREDIT.SUCCEEDED:
      case actions.GET_ِALL_COMPANY_CREDIT.SUCCEEDED:
        state.creditRequests.loading = false;
        const { data, ...rest } = payload.data.data;
        state.creditRequests.list = data;
        state.creditRequests.paginationInfo = rest;
        break;
      case actions.GET_COMPANY_CREDIT.FAILED:
      case actions.GET_ِALL_COMPANY_CREDIT.FAILED:
        state.creditRequests.loading = false;
        state.creditRequests.failure = payload.error;
        break;
      case actions.GET_CREDIT_ALLOWANCE_BY_COMPANY.REQUESTED:
        state.creditAllowance.loading = true;
        break;
      case actions.GET_CREDIT_ALLOWANCE_BY_COMPANY.SUCCEEDED:
        state.creditAllowance.loading = false;
        state.creditAllowance.data = payload.data.data;
        if (payload.data.status === "200") {
          state.creditAllowance.success = payload.data.message;
        } else {
          state.creditAllowance.failure = payload?.data?.message;
        }
        break;
      case actions.GET_CREDIT_ALLOWANCE_BY_COMPANY.FAILED:
        state.creditAllowance.loading = false;
        state.creditAllowance.failure = payload.error;
        break;
      case actions.GET_CREDIT_DETAILS_BY_COMPANY.REQUESTED:
        state.creditDetails.loading = true;
        break;
      case actions.GET_CREDIT_DETAILS_BY_COMPANY.SUCCEEDED:
        state.creditDetails.loading = false;
        state.creditDetails.data = payload.data.data;
        if (payload.data.status === "200") {
          state.creditDetails.success = payload.data.message;
        } else {
          state.creditDetails.failure = payload?.data?.message;
        }
        break;
      case actions.GET_CREDIT_DETAILS_BY_COMPANY.FAILED:
        state.creditDetails.loading = false;
        state.creditDetails.failure = payload.error?.message;
        break;
      case actions.CREATE_CREDIT_ALLOWANCE.REQUESTED:
      case actions.EDIT_CREDIT_ALLOWANCE.REQUESTED:
        state.createEditCreditAllowance.loading = true;
        break;
      case actions.CREATE_CREDIT_ALLOWANCE.SUCCEEDED:
      case actions.EDIT_CREDIT_ALLOWANCE.SUCCEEDED:
        state.createEditCreditAllowance.loading = false;
        if (payload.data.status === "200") {
          state.createEditCreditAllowance.success = payload.data.message;
        } else {
          state.createEditCreditAllowance.failure = payload?.data?.message;
        }
        break;
      case actions.CREATE_CREDIT_ALLOWANCE.FAILED:
      case actions.EDIT_CREDIT_ALLOWANCE.FAILED:
        state.createEditCreditAllowance.loading = false;
        state.createEditCreditAllowance.failure = payload.error?.message;
        break;
      case actions.CREATE_CREDIT_ALLOWANCE.CLEAR:
      case actions.EDIT_CREDIT_ALLOWANCE.CLEAR:
        state.createEditCreditAllowance =
          initialState.createEditCreditAllowance;
        break;
      case actions.ADD_COMPANY_CREDIT.REQUESTED:
        state.addCredit.loading = true;
        break;
      case actions.ADD_COMPANY_CREDIT.SUCCEEDED:
        state.addCredit.loading = false;
        if (payload.data.status === "200") {
          state.addCredit.success = payload.data.message;
        } else {
          state.addCredit.failure = payload?.data?.message;
        }
        break;
      case actions.ADD_COMPANY_CREDIT.FAILED:
        state.addCredit.loading = false;
        state.addCredit.failure = payload.error?.message;
        break;
      case actions.ADD_COMPANY_CREDIT.CLEAR:
        state.addCredit = initialState.addCredit;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
