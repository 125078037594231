import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isProd } from "../../../redux/state/auth/authConstants";
import { SIGN_IN } from "../../routesMap";

export default function useIdle(onTimeout, isAuthorized) {
  // const [isTimeout, setIsTimeout] = useState(false);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: isProd ? 600 : 1800, // in seconds
      onTimeout: () => {
        onTimeout();
      },
      onExpired: () => {
        // do something if expired on load
        onTimeout();
        history.replace({
          pathname: SIGN_IN,
          state: { from: location.pathname },
        });
      },
      isAuthorized,
    });

    return () => {
      timer.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);
}

class IdleTimer {
  constructor({ timeout, onTimeout, onExpired, isAuthorized }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.isAuthorized = isAuthorized;

    if (!isAuthorized) {
      this.cleanUp();
    } else {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );
      if (expiredTime > 0 && expiredTime < Date.now()) {
        onExpired();
        return;
      }

      this.eventHandler = this.updateExpiredTime.bind(this);
      this.tracker();
      this.startInterval();
    }
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );
      if (expiredTime > 0 && expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem("_expiredTime");
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}
