import React from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { CompanySettings } from "../../../common/companySettings/CompanySettings";
import { useSelector } from "react-redux";
import { ORGANIZATION_TYPE_SUBSCRIBER } from "../../../../../../../redux/state/company/companyConstants";

export const ClientSettings = () => {
  const { id } = useParams();
  const { data } = useSelector(({ company }) => company.specificCompanyData);

  const title =
    data?.organisationTypeId === ORGANIZATION_TYPE_SUBSCRIBER
      ? "Edit Subscriber Info"
      : "Edit Agency Info";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CompanySettings title={title} id={id} hasReset />
      </Grid>
    </Grid>
  );
};
