import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";

export const SupplierInfo = ({ orderData }) => {
  const { supplier, pnr } = orderData || {};
  return (
    <CardWithBlueHeader
      iconSrc="/media/svg/icons/General/person.svg"
      title="Supplier information"
    >
      <Grid container>
        <Grid
          item
          style={{ background: "rgba(55, 152, 211, 0.85)" }}
          className=" text-white p-7"
          xs={4}
          md={3}
        >
          <Typography className="font-weight-bold">Name:</Typography>
        </Grid>
        <Grid item className="p-7" xs={8} md={9}>
          <Typography className="font-weight-bold">
            {supplier?.slice(0, 2)}
          </Typography>
        </Grid>
        <Grid item className="bg-primary text-white  p-7" xs={4} md={3}>
          <Typography className="font-weight-bold">Supplier ref.:</Typography>
        </Grid>
        <Grid item className="bg-light-primary p-7" xs={8} md={9}>
          <Typography className="font-weight-bold">{pnr}</Typography>
        </Grid>
        <Grid
          item
          style={{ background: "rgba(55, 152, 211, 0.85)" }}
          className=" text-white p-7"
          xs={4}
          md={3}
        >
          <Typography className="font-weight-bold">B2B ref.:</Typography>
        </Grid>
        <Grid item className="p-7" xs={8} md={9}>
          <Typography className="font-weight-bold"></Typography>
        </Grid>
        <Grid item className="bg-primary text-white  p-7" xs={4} md={3}>
          <Typography className="font-weight-bold">Phone No.:</Typography>
        </Grid>
        <Grid item className="bg-light-primary p-7" xs={8} md={9}>
          <Typography className="font-weight-bold"></Typography>
        </Grid>
        <Grid
          item
          style={{ background: "rgba(55, 152, 211, 0.85)" }}
          className="text-white p-7"
          xs={4}
          md={3}
        >
          <Typography className="font-weight-bold">Address:</Typography>
        </Grid>
        <Grid item className="p-7" xs={8} md={9}>
          <Typography className="font-weight-bold"></Typography>
        </Grid>
      </Grid>
    </CardWithBlueHeader>
  );
};
