import { MODULE_NAME } from "./inlineNotificationConstants";

export const RESET = `${MODULE_NAME}/RESET`;
export const SHOW = `${MODULE_NAME}/SHOW`;

export const setInlineNotification = ({ message, type }) => ({
  type: SHOW,
  payload: { message, type },
});

export const reset = () => ({ type: RESET });
