import { client } from "./client";

export const getCompanyDocuments = ({ companyId }) =>
  client.get(`/api/document/company/${companyId}`);

export const getUserDocuments = ({ userId }) =>
  client.get(`/api/document/user/${userId}`);

export const deleteDocument = ({ id }) => client.delete(`/api/document/${id}`);

export const createDocument = (params) =>
  client.post("/api/document", { ...params });
