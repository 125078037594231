import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./chartsConstants";

export const RESET = "RESET";
export const BOOK_FLIGHT = requestActions(MODULE_NAME, "BOOK_FLIGHT");
export const GET_COMPANY_BOOKING_BY_MONTH = requestActions(
  MODULE_NAME,
  "GET_COMPANY_BOOKING_BY_MONTH"
);
export const GET_COMPANY_REVENUE_BY_MONTH = requestActions(
  MODULE_NAME,
  "GET_COMPANY_REVENUE_BY_MONTH"
);

export const GET_BOOKING_BY_MONTH = requestActions(
  MODULE_NAME,
  "GET_BOOKING_BY_MONTH"
);
export const GET_REVENUE_BY_MONTH = requestActions(
  MODULE_NAME,
  "GET_REVENUE_BY_MONTH"
);

export const getCompanyRevenueByMonth = (params) =>
  requestApiCall(
    "getCompanyRevenueByMonth",
    params,
    GET_COMPANY_REVENUE_BY_MONTH
  );

export const getCompanyBookingByMonth = (params) =>
  requestApiCall(
    "getCompanyBookingByMonth",
    params,
    GET_COMPANY_BOOKING_BY_MONTH
  );

export const getRevenueByMonth = (params) =>
  requestApiCall("getRevenueByMonth", params, GET_REVENUE_BY_MONTH);

export const getBookingByMonth = (params) =>
  requestApiCall("getBookingByMonth", params, GET_BOOKING_BY_MONTH);

export const reset = () => ({ type: RESET });
