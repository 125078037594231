import { Grid, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { getDateTime } from "../../../_helpers/date";
import { SearchCard } from "../bookingManagement/common/SearchCard";
import { Button, Input, LoadingSpinner, Tabs } from "../common";
import { actions } from "../../../../redux/state/bookingMng";
import { parseRoute } from "../../../../app/helpers/formatters";
import {
  BOOKING_MANAGEMENT_REFUND_DETAILS,
  BOOKING_MANAGEMENT_REISSUE_DETAILS,
  BOOKING_MANAGEMENT_VOID_DETAILS,
  TICKET_DETAILS,
} from "../../../../app/routesMap";
import {
  trackingBadgeClassNames,
  trackingBadgeText,
} from "../bookingManagement/Tracking";
import {
  refundBadgeClassNames,
  refundBadgeText,
} from "../bookingManagement/RefundTracking";
import {
  reissueBadgeClassNames,
  reissueBadgeText,
} from "../bookingManagement/ReissueTracking";
import {
  voidBadgeClassNames,
  voidBadgeText,
} from "../bookingManagement/VoidTracking";
import {
  TransactionCompleted,
  TRANSACTION_STATUS,
} from "../../../../redux/state/bookingMng/bookingMngConstants";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";

const ISSUED_TICKETS = "issued";
const REFUND = "refund";
const REISSUE = "reissue";
const VOID = "void";

const badgeTextMap = {
  [ISSUED_TICKETS]: trackingBadgeText,
  [REFUND]: refundBadgeText,
  [REISSUE]: reissueBadgeText,
  [VOID]: voidBadgeText,
};

const badgeClassNamesMap = {
  [ISSUED_TICKETS]: trackingBadgeClassNames,
  [REFUND]: refundBadgeClassNames,
  [REISSUE]: reissueBadgeClassNames,
  [VOID]: voidBadgeClassNames,
};

export const TableRow = ({ data, orderType, index }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const orderDate = orderType === ISSUED_TICKETS ? data : data?.transaction;
  const status =
    orderType === ISSUED_TICKETS ? data?.bookingStatus : data?.status;
  const { insertedOn, orderNo, gdsPnr, flight, totalPrice, passengers } =
    orderDate || {};
  const detailPages = {
    [ISSUED_TICKETS]: parseRoute(TICKET_DETAILS, { id: data.bookingId }),
    [REFUND]: parseRoute(BOOKING_MANAGEMENT_REFUND_DETAILS, { id: data.id }),
    [REISSUE]: parseRoute(BOOKING_MANAGEMENT_REISSUE_DETAILS, { id: data.id }),
    [VOID]: parseRoute(BOOKING_MANAGEMENT_VOID_DETAILS, { id: data.id }),
  };
  const leadPax = passengers?.$values.filter(
    ({ isLeadGuest }) => isLeadGuest
  )[0];
  const { firstName, lastName } = leadPax || {};
  const paxName = `${firstName} ${lastName}`;
  const { flightNumber, departureDateTime } = flight || {};
  const { date, time } = getDateTime(insertedOn);

  const rows = (
    <>
      <td>
        <NavLink
          className="btn pl-0 text-hover-primary"
          to={detailPages[orderType]}
        >
          <span className="font-weight-bold">{orderNo}</span>
        </NavLink>
      </td>
      {matchesSm && (
        <>
          <td className="d-flex flex-column align-items-left">
            <span className="font-weight-bolder">{date}</span>
            <span className="text-muted">{time}</span>
          </td>
          <td>
            <span className="font-weight-bolder">{paxName}</span>
          </td>
          <td>
            <span className="font-weight-bolder">{flightNumber || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span
          href="#"
          className="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg"
        >
          {/* {getAirportName(flight.fromCode)} */}
          {flight?.fromCode}
        </span>
        <span className="font-weight-bold">{" > "}</span>
        <span
          href="#"
          className="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg"
        >
          {/* {getAirportName(flight.toCode)} */}
          {flight?.toCode}
        </span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="font-weight-bolder ">
              {departureDateTime || "-"}
            </span>
          </td>
          <td>
            <span className="font-weight-bolder "> {gdsPnr || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span className="font-weight-bolder ">{totalPrice}</span>
      </td>
      {/* {!noRefund && <td>{cancellationFee}</td>} */}
      <td>
        <span className={badgeClassNamesMap[orderType][status]}>
          {badgeTextMap[orderType][status] || "-"}
        </span>
      </td>
      {/* <td>{data.Operator || "-"}</td> */}
    </>
  );

  const mobileRowDetails = [
    {
      label: "Issue Date",
      value: (
        <span className="font-weight-bolder">
          {date} {time}
        </span>
      ),
    },
    {
      label: "Passengers",
      value: <span className="font-weight-bolder">{paxName}</span>,
    },
    {
      label: "Flight No",
      value: <span className="font-weight-bolder">{flightNumber || "-"}</span>,
    },
    {
      label: "Flight Date",
      value: <span className="font-weight-bolder">{departureDateTime}</span>,
    },
    {
      label: "GDS PNR",
      value: <span className="font-weight-bolder">{gdsPnr || "-"}</span>,
    },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const StandardTicketsTable = ({ orderType, data }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const tableContent = data?.map((item, index) => (
    <TableRow orderType={orderType} key={index} index={index} data={item} />
  ));
  return (
    <div>
      <div className="table-responsive mt-5">
        <table className="table table-vertical-center mb-0">
          <thead>
            <tr className="bg-gray-100">
              <th>Order No</th>
              {matchesSm && (
                <>
                  <th>Issue Date</th>
                  <th>Passengers</th>
                  <th>Flight No</th>
                </>
              )}
              <th>Route</th>
              {matchesSm && (
                <>
                  <th>Flight Date</th>
                  <th>GDS PNR </th>
                </>
              )}
              <th>Total Price</th>
              {/* <th>Refund Price</th> */}
              <th>Status</th>
              {/* <th>Operator</th> */}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </div>
    </div>
  );
};

export const TicketSummary = () => {
  const dispatch = useDispatch();
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { list: ordersList, loading } = useSelector(
    ({ bookingMng }) => bookingMng.orders
  );
  const { list: refundsList } = useSelector(
    ({ bookingMng }) => bookingMng.refunds
  );
  const { list: voidList } = useSelector(({ bookingMng }) => bookingMng.voids);
  const { list: reissueList } = useSelector(
    ({ bookingMng }) => bookingMng.allReissue
  );
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState();
  const [statusFilter, setStatusFilter] = useState(ISSUED_TICKETS);
  const [list, setList] = useState([]);
  const orderActions = {
    [ISSUED_TICKETS]: () =>
      actions.filterOrders({
        companyId: companyInUserId,
        filterValue: TransactionCompleted,
        filterType: TRANSACTION_STATUS,
      }),
    [REFUND]: () => actions.getRefunds({ companyId: companyInUserId }),
    [REISSUE]: () => actions.getReissue({ companyId: companyInUserId }),
    [VOID]: () => actions.getVoid({ companyId: companyInUserId }),
  };
  useEffect(() => {
    setSearchValue("");
  }, [filter]);
  useEffect(() => {
    dispatch(orderActions[statusFilter]());
  }, [dispatch, statusFilter]);
  useEffect(() => {
    if (statusFilter === ISSUED_TICKETS) {
      setList(ordersList);
    } else if (statusFilter === REFUND) {
      setList(refundsList);
    } else if (statusFilter === REISSUE) {
      setList(reissueList);
    } else if (statusFilter === VOID) {
      setList(voidList);
    }
  }, [dispatch, ordersList, refundsList, reissueList, statusFilter, voidList]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      actions.filterOrders({
        companyId: companyInUserId,
        filterValue: searchValue,
        filterType: filter,
      })
    );
  };
  const reset = () => {
    setSearchValue("");
    if (statusFilter === ISSUED_TICKETS) {
      dispatch(actions.getOrders({ companyId: companyInUserId, PageNo: 1 }));
    } else {
      setStatusFilter(ISSUED_TICKETS);
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            onSubmit={handleSubmit}
            reset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Tabs
                setFilter={setStatusFilter}
                data={tabsData}
                noContent
                rightButtonTitle="Export"
              />
              <StandardTicketsTable orderType={statusFilter} data={list} />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export const dateContent = () => {
  return (
    <div>
      <Input type="date" />
      <Button variant="danger">Search</Button>
    </div>
  );
};

const tabsData = [
  { title: "Issued Tickets", filter: ISSUED_TICKETS },
  { title: "Refund", filter: REFUND },
  { title: "Change", filter: REISSUE },
  { title: "Void", filter: VOID },
  // { title: "Order Form" },
  // { title: "Others" },
];
