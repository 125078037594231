import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import ApexCharts from "apexcharts";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { actions } from "../../../../../../redux/state/accounting";
import { getDateTime } from "../../../../../_helpers/date";

const ALL = "all";
const ONE_MONTH = "1m";
const THREE_MONTHS = "3m";
const ONE_YEAR = "1y";

const pastWeeksMap = {
  [ONE_MONTH]: 4,
  [THREE_MONTHS]: 12,
  [ONE_YEAR]: 52,
};

export const SalesGraph = () => {
  const dispatch = useDispatch();
  const [targets, setTargets] = useState([]);
  const [achieved, setAchieved] = useState([]);
  const [selectedTime, setSelectedTime] = useState(ALL);

  const [labels, setLabels] = useState([]);
  const { list } = useSelector(({ accounting }) => accounting.salesGraphData);
  useEffect(() => {
    dispatch(actions.getSalesGraphData());
  }, [dispatch]);
  const sortedList = useMemo(() => {
    if (!list?.length) {
      return [];
    }
    return [...list].sort((a, b) => new Date(b.from) - new Date(a.from));
  }, [list]);
  useEffect(() => {
    if (sortedList) {
      let selectedData =
        selectedTime === ALL
          ? [...sortedList]
          : [...sortedList].splice(0, pastWeeksMap[selectedTime]);
      selectedData = selectedData.reverse();
      const tempTargets = [],
        tempAchieved = [],
        tempLabels = [];
      selectedData.forEach(({ target, achieved, from }) => {
        tempTargets.push(+target);
        tempAchieved.push(+achieved);
        const { date } = getDateTime(from);
        tempLabels.push(date);
      });
      setAchieved(tempAchieved);
      setTargets(tempTargets);
      setLabels(tempLabels);
    }
  }, [sortedList, selectedTime]);
  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(targets, achieved, labels);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [targets, achieved, labels]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div
            className="d-flex bg-gray-100 rounded p-1 mb-3"
            style={{ width: "fit-content" }}
          >
            <button
              onClick={() => setSelectedTime(ALL)}
              className={clsx("btn btn-icon", {
                "bg-white": selectedTime === ALL,
              })}
            >
              All
            </button>
            <button
              onClick={() => setSelectedTime(ONE_MONTH)}
              className={clsx("btn btn-icon", {
                "bg-white": selectedTime === ONE_MONTH,
              })}
            >
              1M
            </button>
            <button
              onClick={() => setSelectedTime(THREE_MONTHS)}
              className={clsx("btn btn-icon ", {
                "bg-white": selectedTime === THREE_MONTHS,
              })}
            >
              3M
            </button>
            <button
              onClick={() => setSelectedTime(ONE_YEAR)}
              className={clsx("btn btn-icon ", {
                "bg-white": selectedTime === ONE_YEAR,
              })}
            >
              1Y
            </button>
          </div>
          <div id="kt_mixed_widget_1_chart" className="card-rounded-bottom" />
        </Paper>
      </Grid>
    </Grid>
  );
};

var getChartOptions = (target, achieved, labels) => ({
  series: [
    {
      name: "Target",
      data: target,
    },
    {
      name: "Achieved",
      data: achieved,
    },
  ],
  chart: {
    height: 350,
    type: "line",
    // dropShadow: {
    //   enabled: true,
    //   color: "#000",
    //   top: 18,
    //   left: 7,
    //   blur: 10,
    //   opacity: 0.2,
    // },
    toolbar: {
      show: false,
    },
    background: "#fff",
  },
  colors: ["#3699FF", "#FFA800"],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    text: "Ticket Statistics",
    align: "left",
  },
  // grid: {
  //   borderColor: "#e7e7e7",
  //   row: {
  //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
  //     opacity: 0.5,
  //   },
  // },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: labels,
    title: {
      text: "Week",
    },
  },
  yaxis: {
    title: {
      text: "Num Of Tickets",
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    floating: true,
    offsetY: -25,
    offsetX: -5,
  },
});
