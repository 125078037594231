import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import clsx from "clsx";
import {
  Grid,
  InputBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { LoginModal } from "../public/LoginModal";
import styles from "./Home.module.scss";
import { toAbsoluteUrl } from "../../../_helpers";
import { SearchCard } from "../fareSearch/FlightSearch";
import { TripCard } from "./TripCard";
import { Footer } from "./Footer";
import { ContactUs } from "./ContactUs";
import { Header } from "./Header_OLD";
import { MobileHeader } from "../public/MobileHeader";
import { Button } from "../common";
import { useHistory } from "react-router";
import { SIGNUP_USER_TYPE } from "../../../../app/routesMap";
import { useSelector } from "react-redux";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Home = ({ isAuthorized }) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const onSignInClick = () => {
    setOpenModal(true);
  };
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const permissionIdsList = list?.map(({ permissionId }) => permissionId);
  return (
    <div className="bg-white text-dark">
      <div className={styles.background}>
        <div className={clsx(styles.topSection, styles.section)}>
          {matchesMd ? (
            <Header isAuthorized={isAuthorized} onSignInClick={onSignInClick} />
          ) : (
            <MobileHeader isAuthorized={isAuthorized} />
          )}
          <div className="d-flex flex-column align-items-center max-w-650px text-center ml-auto mr-auto">
            <Typography variant="h3" className="my-15  text-white">
              Discover the real value of travel
            </Typography>
            {!isAuthorized && (
              <>
                {/* <Typography variant="body1" className="my-10 text-white">
                  Lorem ipsum dolor sit amet, consectetumet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </Typography> */}
                <Button
                  onClick={() => history.push(SIGNUP_USER_TYPE)}
                  variant="danger"
                >
                  join us today
                </Button>
              </>
            )}
          </div>
          <div className={styles.curveContainer}>
            <img
              className={styles.curve}
              src={toAbsoluteUrl("media/svg/shapes/curve.svg")}
              alt=""
            />
            <div className={styles.rectangle} />
          </div>
          {isAuthorized && (
            <>
              {(!isAdmin ||
                (permissionIdsList.includes(12) &&
                  permissionIdsList.includes(13))) && (
                <div className="mt-20">
                  <SearchCard />
                </div>
              )}
            </>
          )}
        </div>
        <LoginModal open={openModal} setOpen={setOpenModal} />
      </div>
      <div className={styles.section}>
        <Typography variant="h4" className="font-weight-bold mb-5">
          Flights to Popular Destinations
        </Typography>
        <Slider {...sliderSettings}>
          <TripCard />
          <TripCard />
          <TripCard />
          <TripCard />
          <TripCard />
          <TripCard />
          <TripCard />
          <TripCard />
        </Slider>
      </div>
      <div
        className={clsx(
          styles.section,
          "mt-20 d-flex flex-column align-items-center"
        )}
      >
        <Typography variant="h4" className="font-weight-bold mb-5">
          Why travel with Flyme247?
        </Typography>
        {/* <Typography
          variant="body1"
          className="mb-5 text-center"
          style={{ maxWidth: "600px", color: "#B2B2B2 " }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Typography> */}
      </div>
      <div className={styles.section}>
        <Grid container spacing={3}>
          <TextWithIcon
            title="Flight Savings"
            iconPath={toAbsoluteUrl("media/svg/icons/General/flight.svg")}
            content="Save up to $525 per trip by booking at the same time"
          />
          <TextWithIcon
            title="No change Fees"
            iconPath={toAbsoluteUrl("media/svg/icons/General/fees.svg")}
            content="Change your plans if you need to, without extra fees"
          />
          <TextWithIcon
            title="Price Guarantee"
            iconPath={toAbsoluteUrl("media/svg/icons/General/price.svg")}
            content="Get the best available price on every trip"
          />
          <TextWithIcon
            title="No booking Fees"
            iconPath={toAbsoluteUrl("media/svg/icons/General/click.svg")}
            content="Choose any single carrier flight and save with no ours booking fees
            "
          />
        </Grid>
      </div>
      <div className={styles.info}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className="font-weight-bolder mb-5">
              Contact us
            </Typography>
            <ContactUs />
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Typography variant="h4" className="font-weight-bolder mb-5">
                Our address
              </Typography>
              <div className={styles.mapContainer}>
                <img
                  src={toAbsoluteUrl("media/svg/icons/temp/map.jpg")}
                  alt=""
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.subscribe}>
        <Typography variant="h5" className="font-weight-bolder mb-2 text-white">
          Be the first to see the Updates
        </Typography>
        <Typography variant="body1" className="mb-5 text-white">
          Stay up to date with our latest Flights and Hotels Deals
        </Typography>
        <InputBase
          placeholder="Email Here..."
          className="h-40px border rounded bg-white p-2 flex-grow-1 mb-5"
          // onChange={onChange}
          // value={value}
        />
        <button className="btn bg-white text-primary font-weight-bolder px-5 ml-5">
          Subscribe
        </button>
      </div>
      <Footer />
    </div>
  );
};

export const TextWithIcon = ({ iconPath, title, content, textStyle }) => (
  <Grid item lg={3} md={6} xs={12} className="text-center mb-5">
    <img src={iconPath} className="mb-2" alt="" />
    <Typography
      style={textStyle}
      variant="body1"
      className="mb-2 font-weight-bolder"
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      style={textStyle}
      // style={{ maxWidth: "600px", color: "#B2B2B2 " }}
    >
      {content}
    </Typography>
  </Grid>
);
