import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { VoidDetails } from "../../../_metronic/layout/components/admin/bookingMng/VoidDetails";
import { VoidTracking } from "../../../_metronic/layout/components/bookingManagement";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  BOOKING_MANAGEMENT_VOID,
  BOOKING_MANAGEMENT_VOID_DETAILS,
} from "../../routesMap";

export const VoidTrackingPage = ({ permissionKey, permissionId }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  return (
    <Switch>
      {isAdmin && (
        <ContentRoute
          permissionKey={generateNestedObjectKey([
            permissionKey,
            "subPermissions.view",
          ])}
          path={BOOKING_MANAGEMENT_VOID_DETAILS}
          render={(props) => (
            <VoidDetails {...props} permissionId={permissionId} />
          )}
        />
      )}
      {!isAdmin && (
        <Route path={BOOKING_MANAGEMENT_VOID_DETAILS} component={VoidDetails} />
      )}
      <Route exact path={BOOKING_MANAGEMENT_VOID} component={VoidTracking} />
    </Switch>
  );
};
