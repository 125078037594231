import { Grid, Paper, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Seperator } from "../../../common";
import { ReportsSearchCard } from "../common";
import { SalesReportTable } from "./SalesReportTable";
import { SalesSummary } from "./SalesSummary";
import { actions } from "../../../../../../redux/state/invoices";
import useExportAccounting, {
  exportAccountingTypesMap,
} from "../../../../../../app/helpers/hooks/useExportAccounting";
import { invoiceTypeMap } from "../../../../../../redux/state/invoices/invoicesConstants";
import { useCallback } from "react";

export const SalesReport = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { exportToExcel, loading: downloadLoading } = useExportAccounting();
  const { paginationInfo, stats } = useSelector(
    ({ invoices }) => invoices.invoices
  );
  const { totalPages } = paginationInfo ?? {};
  const { fromDate, toDate } = dateRange;
  const filterInvoice = useCallback(
    (pageNumber, filters) => {
      const newFilters = [
        ...filters,
        {
          columnName: "invoiceType",
          filterValue: invoiceTypeMap.issued,
          filterOption: 1,
        },
        {
          columnName: "invoiceType",
          filterValue: invoiceTypeMap.refund,
          filterOption: 1,
        },
      ];
      dispatch(
        actions.filterInvoices({
          pageNumber,
          filterParam: newFilters,
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    filterInvoice(pageNumber, appliedFilters);
  }, [appliedFilters, dispatch, filterInvoice, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
  };
  const onExport = () => {
    exportToExcel({
      type: exportAccountingTypesMap.invoices,
      filterParam: [
        {
          columnName: "invoiceType",
          filterValue: invoiceTypeMap.issued,
          filterOption: 1,
        },
        {
          columnName: "invoiceType",
          filterValue: invoiceTypeMap.refund,
          filterOption: 1,
        },
        ...appliedFilters,
      ],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const reset = () => {
    setAppliedFilters([]);
    setPageNumber(1);
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <ReportsSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Typography variant="h6" className="font-weight-bold">
                Sales Report
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <Button
              onClick={onExport}
              loading={downloadLoading}
              variant="danger"
            >
              Export
            </Button>
          </div>
          <Seperator />
          <SalesReportTable />
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
          <div className="m-5" />
          <SalesSummary stats={stats} />
        </Paper>
      </Grid>
    </Grid>
  );
};
