import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./templatesActions";

const initialState = {
  templates: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  specificTemplate: {
    data: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  create: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  createType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  editType: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  templateTypes: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  delete: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  edit: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  emailParameters: {
    loading: false,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
};

export const templatesReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_EMAIL_PARAMETERS.REQUESTED:
        state.emailParameters.loading = true;
        break;
      case actions.GET_EMAIL_PARAMETERS.SUCCEEDED:
        state.emailParameters.loading = false;
        state.emailParameters.success = true;
        state.emailParameters.list = payload.data.data;
        break;
      case actions.GET_EMAIL_PARAMETERS.FAILED:
        state.emailParameters.loading = false;
        state.emailParameters.failure = payload.error;
        break;
      case actions.GET_ALL.REQUESTED:
        state.templates.loading = true;
        break;
      case actions.GET_ALL.SUCCEEDED:
        state.templates.loading = false;
        state.templates.success = true;
        state.templates.list = payload.data.data.$values?.reverse();
        break;
      case actions.GET_ALL.FAILED:
        state.templates.loading = false;
        state.templates.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_TEMPLATE.REQUESTED:
        state.specificTemplate.loading = true;
        break;
      case actions.GET_SPECIFIC_TEMPLATE.SUCCEEDED:
        state.specificTemplate.loading = false;
        state.specificTemplate.success = true;
        state.specificTemplate.data = payload.data.data;
        break;
      case actions.GET_SPECIFIC_TEMPLATE.FAILED:
        state.specificTemplate.loading = false;
        state.specificTemplate.failure = payload.error;
        break;
      case actions.GET_SPECIFIC_TEMPLATE.CLEAR:
        state.specificTemplate = initialState.specificTemplate;
        break;
      case actions.GET_TEMPLATE_TYPES.REQUESTED:
        state.templateTypes.loading = true;
        break;
      case actions.GET_TEMPLATE_TYPES.SUCCEEDED:
        state.templateTypes.loading = false;
        state.templateTypes.success = true;
        state.templateTypes.list = payload.data.data.$values?.reverse();
        break;
      case actions.GET_TEMPLATE_TYPES.FAILED:
        state.templateTypes.loading = false;
        state.templateTypes.failure = payload.error;
        break;
      case actions.CREATE.REQUESTED:
        state.create.loading = true;
        break;
      case actions.CREATE.SUCCEEDED:
        state.create.loading = false;
        if (payload.data.status === "200") {
          state.create.success = true;
        } else {
          state.create.failure = true;
        }
        break;

      case actions.CREATE.FAILED:
        state.create.loading = false;
        state.create.failure = payload.error;

        break;
      case actions.CREATE.CLEAR:
        state.create = initialState.create;
        break;
      case actions.CREATE_TEMPLATE_TYPE.REQUESTED:
        state.createType.loading = true;
        break;
      case actions.CREATE_TEMPLATE_TYPE.SUCCEEDED:
        state.createType.loading = false;
        state.createType.success = true;
        const list = state.templateTypes.list;
        state.templateTypes.list = [payload, ...list];
        break;
      case actions.CREATE_TEMPLATE_TYPE.FAILED:
        state.createType.loading = false;
        state.createType.failure = payload.error;
        break;
      case actions.CREATE_TEMPLATE_TYPE.CLEAR:
        state.createType = initialState.createType;
        break;
      case actions.DELETE_TEMPLATE_TYPE.REQUESTED:
      case actions.DELETE.REQUESTED:
        state.delete.loading = true;
        break;
      case actions.DELETE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.templates.list = state.templates.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_TEMPLATE_TYPE.SUCCEEDED:
        state.delete.loading = false;
        state.delete.success = true;
        state.templateTypes.list = state.templateTypes.list.filter(
          ({ id }) => id !== payload.args.id
        );
        break;
      case actions.DELETE_TEMPLATE_TYPE.FAILED:
      case actions.DELETE.FAILED:
        state.delete.loading = false;
        state.delete.failure = payload.error;
        break;
      case actions.DELETE.CLEAR:
        state.delete = initialState.delete;
        break;

      case actions.EDIT.REQUESTED:
        state.edit.loading = true;
        break;
      case actions.EDIT.SUCCEEDED:
        state.edit.loading = false;
        state.edit.success = true;
        break;
      case actions.EDIT.FAILED:
        state.edit.loading = false;
        state.edit.failure = payload.error;
        break;
      case actions.EDIT.CLEAR:
        state.edit = initialState.edit;
        break;
      case actions.EDIT_TEMPLATE_TYPE.REQUESTED:
        state.editType.loading = true;
        break;
      case actions.EDIT_TEMPLATE_TYPE.SUCCEEDED:
        state.editType.loading = false;
        state.editType.success = true;
        const index = state.templateTypes.list.findIndex(
          ({ id }) => id === payload.args.id
        );
        state.templateTypes.list[index] = payload.args;
        break;
      case actions.EDIT_TEMPLATE_TYPE.FAILED:
        state.editType.loading = false;
        state.editType.failure = payload.error;
        break;
      case actions.EDIT_TEMPLATE_TYPE.CLEAR:
        state.editType = initialState.editType;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
