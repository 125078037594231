/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
// import AgencyRoutes from "./AgencyRoutes";
import { actions as authActions } from "../redux/state/auth";
import { actions as communicationActions } from "../redux/state/communication";
import { actions as companyActions } from "../redux/state/company";
import { actions as dictActions } from "../redux/state/dict";
import { actions } from "../redux/state/user";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { HomePage } from "./pages/HomePage";
import { NewPasswordPage } from "./pages/NewPasswordPage";
import { SignInByCodePage } from "./pages/SignInByCodePage";
import { SignInPage } from "./pages/SignInPage";
import {
  ABOUT,
  CERTIFICATES,
  CONTACT_US,
  DOWNLOAD,
  FORGOT_PASSWORD,
  HOME,
  NEW_PASSWORD,
  NOT_FOUND,
  PAYMENT_PROOF,
  PAYMENT_SETTINGS,
  PAYMENT_SUCCESS,
  PRIVACY_POLICY,
  PRODUCTS,
  SIGNUP,
  SIGN_IN,
  SIGN_IN_BY_CODE,
  SUBSCRIPTION,
  TERMS_AND_CONDITIONS,
  TIME_OUT,
} from "./routesMap";
// import AdminRoutes from "./AdminRoutes";
import { InlineNotification } from "../_metronic/layout/components/common/inlineNotification/InlineNotification";
import { SignUp } from "../_metronic/layout/components/signUp/SignUp";
import useIdle from "./helpers/hooks/useIdle";
import { AboutPage } from "./pages/helpCenter";
import { TermsAndConditions } from "./pages/helpCenter/TermsAndConditions";
import { NotFoundPage } from "./pages/public/notFoundPage/NotFoundPage";
import { SessionTimeoutPage } from "./pages/public/sessionTimeoutPage/SessionTimeoutPage";
import { SubscriptionRoutes } from "./pages/SubscriptionRoutes";
import { SubscriptionLayout } from "../_metronic/layout/components/SubscriptionLayout";
import { PaymentProofPage, PaymentSettingsPage } from "./pages/payment";
import { PrivacyPolicy } from "./pages/helpCenter/PrivacyPolicy";
import { PaymentSuccessfulPage } from "./pages/PaymentSuccessful";
import { ProductPage } from "./pages/ProductPage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { CertificatesPage } from "./pages/CertificatesPage";
import { DownloadPage } from "./pages/DownloadPage";
import { ContactUsPage } from "./pages/ContactUsPage";

const AdminRoutes = lazy(() => import("./AdminRoutes"));
const AgencyRoutes = lazy(() => import("./AgencyRoutes"));

export function Routes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [connectionId, setConnectionId] = useState("");
  const [connected, setConnected] = useState(false);

  const { isAuthorized } = useSelector(({ auth }) => ({
    isAuthorized: !!auth.token,
  }));
  const { loading: logoutLoading } = useSelector(({ auth }) => auth.logout);
  const companyId = useSelector(({ user }) => user.userData?.companyInUserId);
  const { success: companySuccess } = useSelector(
    ({ company }) => company.companyData
  );
  const { isAdmin, subscription } = useSelector(
    ({ company }) => company.companyData
  );

  // const { organisationTypeId: organizationType } = companyData || {};

  const { success: getPermissionsSuccess } = useSelector(
    ({ user }) => user.currentUserPermissions
  );
  const { dataLoaded } = useSelector(
    ({ user }) => ({
      dataLoaded: !!user.userData,
    }),
    shallowEqual
  );
  const history = useHistory();
  const { userId, sessionId } = useSelector(({ auth }) => auth);
  const renderScreen = isAuthorized
    ? getPermissionsSuccess && companySuccess && dataLoaded
    : true;

  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_COMMUNICATION_API_URL}/flyme-communication-hub`,
          {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          }
        )
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build(),
    []
  );
  const invokeNotifications = useCallback(async () => {
    if (connected) {
      await connection.invoke(
        "NotificationsData",
        isAdmin ? "0" : `${companyId}`,
        connectionId,
        null,
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, connectionId, companyId, isAdmin]);
  useEffect(() => {
    connection.on("GetNotificationsData", async () => {
      await invokeNotifications();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invokeNotifications]);
  const start = useCallback(async () => {
    try {
      connection.on("NotificationsData", (data) => {
        dispatch(communicationActions.setNotifications({ data: data?.data }));
      });
      connection
        .start()
        .then(async () => {
          await connection.invoke("getconnectionid").then((data) => {
            setConnectionId(data);
            setConnected(true);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!connected) return;
    invokeNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);
  useEffect(() => {
    return () => {
      connection.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { state: connectionState } = connection || {};
  useEffect(() => {
    if (!isAuthorized && connectionState === "Connected") {
      connection.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState, isAuthorized]);

  useEffect(() => {
    if (companyId && isAuthorized && !logoutLoading) {
      dispatch(companyActions.getCompany({ id: companyId }));
    }
  }, [companyId, dispatch, isAuthorized, logoutLoading]);

  useIdle(() => {
    dispatch(
      authActions.logout({ userinUserSessionId: userId, id: sessionId })
    );
    history.push(TIME_OUT);
  }, isAuthorized);

  useEffect(() => {
    if (isAuthorized && !dataLoaded) {
      dispatch(actions.getUser({ id: userId }));
      dispatch(actions.getCurrentUserPermissions({ id: userId }));
    }
    if (!isAuthorized) {
      dispatch(authActions.getLocalStorage());
    }
  }, [dataLoaded, dispatch, isAuthorized, userId]);

  const authorizedRoutes = (
    <>
      <Layout>{isAdmin ? <AdminRoutes /> : <AgencyRoutes />}</Layout>
    </>
  );
  const unauthorizedRoutes = (
    <Switch>
      <Route path={SIGNUP} component={SignUp} />
      <Route exact path={SIGN_IN} component={SignInPage} />
      <Route exact path={SIGN_IN_BY_CODE} component={SignInByCodePage} />
      <Route exact path={NEW_PASSWORD} component={NewPasswordPage} />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Redirect
        to={{ pathname: SIGN_IN, state: { from: location.pathname } }}
      />
    </Switch>
  );
  useEffect(() => {
    if (renderScreen && isAuthorized && !logoutLoading) {
      start();
      dispatch(dictActions.getAirports());
      dispatch(dictActions.getAirlines());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderScreen, dispatch, isAuthorized]);

  if (renderScreen && subscription && !subscription?.isActive) {
    return (
      <SubscriptionLayout>
        <InlineNotification />
        <Switch>
          <Route path={SUBSCRIPTION} component={SubscriptionRoutes} />
          <Route
            exact
            path={PAYMENT_SETTINGS}
            component={PaymentSettingsPage}
          />
          <Route exact path={PAYMENT_PROOF} component={PaymentProofPage} />
          <Route
            exact
            path={PAYMENT_SUCCESS}
            component={PaymentSuccessfulPage}
          />
          <Redirect to={SUBSCRIPTION} />
        </Switch>
      </SubscriptionLayout>
    );
  }

  return (
    <>
      <InlineNotification />
      {renderScreen ? (
        <Switch>
          <Route exact path="/">
            <Redirect to={HOME} />
          </Route>
          {/* <Route path="/error" component={ErrorsPage} /> */}
          <Route exact path={NOT_FOUND} component={NotFoundPage} />
          <Route exact path={HOME} component={HomePage} />
          <Route exact path={PRODUCTS} component={ProductPage} />
          <Route exact path={ABOUT} component={AboutUsPage} />
          <Route exact path={TIME_OUT} component={SessionTimeoutPage} />
          <Route exact path={CERTIFICATES} component={CertificatesPage} />
          <Route exact path={DOWNLOAD} component={DownloadPage} />
          <Route exact path={CONTACT_US} component={ContactUsPage} />
          <Route
            exact
            path={TERMS_AND_CONDITIONS}
            component={TermsAndConditions}
          />
          <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
          {/* <Route exact path={ABOUT} component={AboutPage} /> */}
          <Route
            exact
            path={PAYMENT_SUCCESS}
            component={PaymentSuccessfulPage}
          />
          {/* <Route exact path={ACCEPT_VOID} component={AcceptVoid} />
          <Route exact path={REJECT_VOID} component={RejectVoid} />
          <Route exact path={ACCEPT_REFUND} component={AcceptRefund} />
          <Route exact path={REJECT_REFUND} component={RejectRefund} />
          <Route exact path={ACCEPT_REISSUE} component={AcceptReissue} />
          <Route exact path={REJECT_REISSUE} component={RejectReissue} /> */}
          {isAuthorized ? authorizedRoutes : unauthorizedRoutes}
          <Redirect to={NOT_FOUND} />
        </Switch>
      ) : (
        <>
          <LayoutSplashScreen />
        </>
      )}
    </>
  );
}
