export const MODULE_NAME = "user";

export const promoDurationTypeEnums = {
  oneTime: 1,
  limitedPeriod: 2,
  forever: 3,
};

export const promoDurationTypeLabels = {
  [promoDurationTypeEnums.oneTime]: "One Time",
  [promoDurationTypeEnums.limitedPeriod]: "Limited Period",
  [promoDurationTypeEnums.forever]: "Forever",
};

export const promoAppliedOnEnums = {
  subscription: 1,
  booking: 2,
};

export const paymentForEnums = {
  booking: 1,
  subscription: 2,
};

export const paymentForLabels = {
  [paymentForEnums.booking]: "Booking",
  [paymentForEnums.subscription]: "Subscription",
};

export const promoAppliedOnLabels = {
  [promoAppliedOnEnums.subscription]: "Subscription",
  [promoAppliedOnEnums.booking]: "Booking",
};

export const stripeWebhookPaymentForEnums = {
  BookAndIssue: 1,
  IssueHold: 2,
  Subscription: 3,
};
