/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { Grid, Paper } from "@material-ui/core";
import { useHtmlClassService } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/charts";
import { actions as bookingActions } from "../../../../redux/state/bookingMng";

export function BookingStats({ className }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { isAdmin } = useSelector(
    ({ company }) => company.companyData
  );
  const { list } = useSelector(({ charts }) => charts.getBookingByMonth);
  const { data: bookingStats } = useSelector(
    ({ bookingMng }) => bookingMng.bookingStats
  );

  useEffect(() => {
    if (isAdmin) {
      dispatch(actions.getBookingByMonth());
      dispatch(bookingActions.getBookingStates());
    } else {
      dispatch(
        actions.getCompanyBookingByMonth({ companyId: companyInUserId })
      );
      dispatch(
        bookingActions.getBookingStatesForAgency({ companyId: companyInUserId })
      );
    }
  }, [companyInUserId, dispatch, isAdmin]);

  useEffect(() => {
    if (list) {
      const tempData = [],
        tempLabels = [];
      list.forEach(({ month, value }) => {
        tempData.push(parseInt(value));
        tempLabels.push(month);
      });
      setData(tempData);
      setLabels(tempLabels);
    }
  }, [list]);

  const { past, upcoming, cancelled } = bookingStats || {};

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps, data, labels);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [data, labels, layoutProps]);

  return (
    <div className={`card card-custom  h-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-primary py-5">
        <h3 className="card-title font-weight-bolder text-white">
          Booking States
        </h3>
        {/* <div className="card-toolbar">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"
              variant="transparent"
              id="dropdown-toggle-top"
            >
              Last week
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu2 />
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_1_chart"
          className="card-rounded-bottom bg-primary"
          style={{ height: "200px" }}
        ></div>

        {/* Stat */}
        <Grid
          container
          spacing={3}
          className="px-15 pb-5"
          style={{ marginTop: "-70px" }}
        >
          <Grid item xs={12}>
            <Paper
              // elevation={2}
              className="p-7 text-center d-flex flex-column align-items-center justify-content-center rounded"
            >
              <span className="font-weight-bolder font-size-h1">
                {upcoming}
              </span>
              <span>Upcoming</span>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              // elevation={2}
              className="p-7 text-center d-flex flex-column align-items-center justify-content-center rounded"
            >
              <span className="font-weight-bolder font-size-h1">
                {cancelled}
              </span>
              <span>Cancelled</span>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              // elevation={2}
              className="p-7 text-center d-flex flex-column align-items-center justify-content-center rounded"
            >
              <span className="font-weight-bolder font-size-h1">{past}</span>
              <span>Past</span>
            </Paper>
          </Grid>
        </Grid>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps, data, labels) {
  const strokeColor = "#308AE7";

  const options = {
    series: [
      {
        name: "Bookings",
        data: data,
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      // max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return "$" + val;
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBasePrimary,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}
