import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";
import React from "react";
import { Button } from "../../_metronic/layout/components/common";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";
import { ContentSection } from "../../_metronic/layout/components/public/common/ContentSection";
import { GET_PRODUCT_CONTENT } from "../../graphql/queries";
import { AboutUseSkeleton } from "../skeletons/AboutUsSkeleton";

export const ProductPage = () => {
  const { data, loading, error } = useQuery(GET_PRODUCT_CONTENT);
  if (loading)
    return (
      <PublicLayout>
        <AboutUseSkeleton />
      </PublicLayout>
    );
  if (!data?.productsPage?.data?.attributes) {
    return <div className="text-center vh-100 pt-20" />;
  }
  if (error) {
    return (
      <PublicLayout>
        <div className="text-center vh-100 pt-20">
          <Typography variant="h3" className="text-dark font-weight-bolder">
            Something went wrong
          </Typography>
          <div className="my-5" />
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
      </PublicLayout>
    );
  }
  const { contentBlocks } = data?.productsPage?.data?.attributes;
  return (
    <PublicLayout>
      <div className="my-20">
        {contentBlocks.map((block, index) => (
          <div key={index}>
            <ContentSection data={block} />
            <div className="my-25" />
          </div>
        ))}
      </div>
    </PublicLayout>
  );
};
