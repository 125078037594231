import { client, bookingClient } from "./client";

export const getPaymentMethodBalances = () =>
  bookingClient.post("v1/PaymentMethods");

export const getPaymentLogs = ({ companyId = 0, paymentType }) =>
  bookingClient.post(
    `v1/PaymentLogs?companyId=${companyId}&paymentTypes=${paymentType}`
  );

export const getTopUps = ({ companyId }) =>
  client.get(`api/topUp/company/${companyId}`);

export const getSpecificTopUp = ({ id }) => client.get(`api/topUp/${id}`);

export const getAllTopUps = () => client.get("api/topUp");

export const deleteTopUp = ({ id }) => client.delete(`api/topUp/${id}`);

export const editTopUp = (params) =>
  client.put(`api/topUp/${params.id}`, { ...params });

export const createTopUps = (params) => client.post("/api/topUp", params);

export const filterAllTopUps = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post("api/topUp/filters", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [],
    pageNumber,
    pageSize,
  });
