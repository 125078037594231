import React from "react";
import { Route, Switch } from "react-router-dom";
import { Expenses } from "../../../_metronic/layout/components/admin/accounting";
import { CreateExpense } from "../../../_metronic/layout/components/admin/accounting/expanses/CreateExpense";
import { ADMIN_ACCOUNTING_CREATE_EXPENSES, ADMIN_ACCOUNTING_EXPENSES } from "../../routesMapAdmin";

export const ExpensesPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={ADMIN_ACCOUNTING_CREATE_EXPENSES}
        component={CreateExpense}
      />
      <Route exact path={ADMIN_ACCOUNTING_EXPENSES} component={Expenses} />
    </Switch>
  );
};
