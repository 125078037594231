import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Switch,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions } from "../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { Button, LoadingSpinner, Seperator } from "../../common";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ACCOUNT_SUB_USER_LIST } from "../../../../../app/routesMap";
import { routesPermissionMap } from "../../../../../app/AgencyRoutes";
import { usePermissionIds } from "../../../../../app/helpers/hooks/usePermissionIds";

export const GivePermissions = ({ directLink, userId }) => {
  return (
    <Permissions
      directLink={directLink}
      permissions={routesPermissionMap}
      userId={userId}
      isAdminPermissions={false}
    />
  );
};

export const Permissions = ({
  permissions,
  directLink,
  userId,
  roleId,
  isAdminPermissions = true,
}) => {
  const dispatch = useDispatch();
  const [selectedPanel, setSelectedPanel] = useState();
  const { getPermissionObj } = usePermissionIds({ isAdminPermissions });
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const {
    list: userPermissionsList,
    success: userPermissionsSuccess,
    loading: userPermissionsLoading,
  } = useSelector(({ user }) => user.userPermissions);
  const {
    list: rolePermissionsList,
    success: rolePermissionsSuccess,
    loading: rolePermissionsLoading,
  } = useSelector(({ user }) => user.rolePermissions);
  const list = roleId ? rolePermissionsList : userPermissionsList;
  const success = roleId ? rolePermissionsSuccess : userPermissionsSuccess;
  const loading = roleId ? rolePermissionsLoading : userPermissionsLoading;
  const {
    loading: editLoading,
    success: editSuccess,
    failure: editFailure,
  } = useSelector(({ user }) => user.editPermission);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const [switches, setSwitches] = useState({});
  const permissionIdsList = useMemo(
    () => list?.map(({ permissionId }) => permissionId),
    [list]
  );
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Permissions updated",
        })
      );
      dispatch(actions.clearEditPermissions());
      setEdit(false);
      history.push(isAdmin ? directLink : ACCOUNT_SUB_USER_LIST);
    }
  }, [editSuccess, dispatch, history, isAdmin, directLink]);
  useEffect(() => {
    if (editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearEditPermissions());
    }
  }, [dispatch, editFailure]);

  useEffect(() => {
    if (userId) {
      dispatch(actions.getUserPermissions({ id: userId }));
    }
    if (roleId) {
      dispatch(actions.getRolePermissions({ id: roleId }));
    }
    return () => {
      dispatch(actions.clearEditPermissions());
      dispatch(actions.clearUserPermissions());
      dispatch(actions.clearRolePermissions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const initializePermission = useCallback(() => {
    const temp = {};
    Object.keys(permissions).forEach((key) =>
      permissions[key].items.forEach(({ permissionKey }) => {
        const { id, subPermissions } = getPermissionObj(permissionKey);
        temp[id] = permissionIdsList.includes(id);
        Object.keys(subPermissions || {}).forEach((key) => {
          const { id: subPermissionId } = subPermissions[key];
          temp[subPermissionId] = permissionIdsList.includes(subPermissionId);
        });
      })
    );
    setSwitches(temp);
  }, [getPermissionObj, permissionIdsList, permissions]);

  useEffect(() => {
    if (success) {
      initializePermission();
    }
  }, [success, initializePermission]);
  const handleCancel = () => {
    initializePermission();
    setEdit(false);
  };
  const handleSubmit = () => {
    const permissionsList = Object.keys(switches)
      .filter((key) => switches[key])
      .map((key) => +key);
    if (roleId) {
      dispatch(actions.editRolePermissions({ id: roleId, permissionsList }));
    } else {
      dispatch(actions.editUserPermissions({ id: userId, permissionsList }));
    }
  };
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setSelectedPanel(isExpanded ? panel : false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-100">
          {Object.keys(permissions).map((key) => (
            <ExpansionPanel
              expanded={selectedPanel === key}
              onChange={handlePanelChange(key)}
              key={key}
              className="rounded"
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="bg-primary text-white"
                // color='primary'
              >
                <Typography className="font-weight-bold" variant="h6">
                  {key}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="d-flex flex-column">
                {permissions[key].items.map(
                  ({ label, permissionKey }, index) => {
                    const { id, subPermissions } = getPermissionObj(
                      permissionKey
                    );
                    return (
                      <div key={id}>
                        <label className="d-flex align-items-center justify-content-between font-weight-bold ">
                          {label}
                          <Switch
                            disabled={!edit}
                            checked={switches[id] || false}
                            onClick={() =>
                              setSwitches({ ...switches, [id]: !switches[id] })
                            }
                            color="primary"
                          />
                        </label>
                        {subPermissions && (
                          <div className="d-flex flex-column mx-12">
                            {switches[id] &&
                              Object.keys(subPermissions || {}).map((key) => {
                                const { id, label } = subPermissions[key];
                                return (
                                  <label
                                    key={id}
                                    className="d-flex align-items-center justify-content-between font-italic"
                                  >
                                    {label}
                                    <Switch
                                      disabled={!edit}
                                      checked={switches[id] || false}
                                      onClick={() =>
                                        setSwitches({
                                          ...switches,
                                          [id]: !switches[id],
                                        })
                                      }
                                      color="primary"
                                    />
                                  </label>
                                );
                              })}
                          </div>
                        )}
                        {index !== permissions[key].items.length - 1 && (
                          <Seperator />
                        )}
                      </div>
                    );
                  }
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}

          <div className="d-flex justify-content-end mt-7">
            {!edit ? (
              <button
                type="button"
                onClick={() => {
                  setEdit(true);
                }}
                className="btn btn-primary w-100px"
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  onClick={handleCancel}
                  type="button"
                  className="btn bg-gray-200 w-100px "
                >
                  Cancel
                </button>
                <Button
                  onClick={() => handleSubmit()}
                  loading={editLoading}
                  type="submit"
                  className="ml-7"
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
