import {
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";

import { Input, GreenCheckbox } from "../../common";
import clsx from "clsx";

export const Section = ({ title, iconPath, formFields, name }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { setFieldValue, values, touched, errors, getFieldProps } = formFields;
  const addField = () => {
    setFieldValue(`${name}.emails`, [...values[name].emails, ""]);
  };
  const removeField = (index) => {
    const emails = [...values[name].emails];
    emails.splice(index, 1);
    setFieldValue(`${name}.emails`, [...emails]);
  };

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center">
        {iconPath && (
          <span className="svg-icon svg-icon-md mr-2">
            <SVG src={iconPath}></SVG>
          </span>
        )}
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </div>
      <FormControlLabel
        className="ml-8"
        control={
          <GreenCheckbox
            checked={values[name].isSubscribed}
            onChange={(e) =>
              setFieldValue(`${name}.isSubscribed`, e.target.checked)
            }
            value="isSubscribed"
          />
        }
        label={
          <Typography className="font-weight-bold ">
            Email Subscription
          </Typography>
        }
      />
      {values[name].emails.map((_, index) => (
        <div
          key={index}
          className={clsx("d-flex align-items-start", {
            "ml-20": matchesSm,
            "flex-column": !matchesSm,
          })}
        >
          <span
            className={clsx("mt-3", {
              "mb-1": !matchesSm,
            })}
          >
            Email Address
          </span>
          <div
            className={clsx("", {
              "w-400px ml-4": matchesSm,
              "w-100": !matchesSm,
            })}
          >
            <Input
              showErrors={touched[name]?.emails?.[index]}
              errorMessage={errors[name]?.emails?.[index]}
              {...getFieldProps(`${name}.emails.${index}`)}
              outlined={false}
              placeholder="Email"
              spacing
            />
          </div>
          {index > 0 && (
            <button
              type="button"
              onClick={() => removeField(index)}
              className="ml-3 d-flex justify-content-center align-items-center 
                btn p-0 rounded-circle text-danger pt-1 text-white min-w-20px h-20px mt-3 font-size-h5"
            >
              -
            </button>
          )}
        </div>
      ))}
      <div className="w-100 d-flex">
        <button
          type="button"
          onClick={addField}
          style={{ marginLeft: matchesSm ? "170px" : "unset" }}
          className={clsx(
            "mt-4  btn text-primary border-primary font-weight-bold",
            {
              "w-400px": matchesSm,
              "m-auto": !matchesSm,
            }
          )}
        >
          + Add one other email
        </button>
      </div>
    </div>
  );
};
