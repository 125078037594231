import React from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";

import { Button, Input, Select } from "../../../common";
import { feeTypes } from "./ServiceFeesRow";

const typeOptions = Object.keys(feeTypes).map((key) => ({
  label: feeTypes[key],
  value: key,
}));
export const ServiceFeesForm = ({ fields, show, loading, onHide }) => {
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
  } = fields;
  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <>
        <Modal.Header>Service Fee</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Select
                showErrors={touched["type"]}
                errorMessage={errors["type"]}
                value={values.type}
                onChange={(newValue) => setFieldValue("type", newValue)}
                variant="secondary"
                label="Type"
                options={typeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="d-flex align-items-end">
                <Input
                  {...getFieldProps("amount")}
                  showErrors={touched["amount"]}
                  errorMessage={errors["amount"]}
                  variant="secondary"
                  label="Amount"
                />
                <Select
                  variant="secondary"
                  className="mx-3 max-w-60px"
                  options={[
                    { value: true, label: "%" },
                    { value: false, label: "$" },
                  ]}
                  onChange={(value) => setFieldValue("isPercentage", value)}
                  value={values.isPercentage}
                  errorMessage={errors.isPercentage}
                  showErrors={touched.isPercentage}
                  horizontal
                  fixedLabelWidth
                />
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <> </>
          <Button
            type="submit"
            loading={loading}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};
