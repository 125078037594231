import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

export function AboutUseSkeleton() {
  return (
    <div className="my-20">
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rect" width="100%" height={118} />
      </div>
      <div className="my-25" />
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rect" width="100%" height={118} />
      </div>
      <div className="my-25" />
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rect" width="100%" height={118} />
      </div>
      <div className="my-25" />
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rect" width="100%" height={118} />
      </div>
      <div className="my-25" />
      <div>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rect" width="100%" height={118} />
      </div>
      <div className="my-25" />
      <div>
        <Skeleton variant="text" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="text" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
