import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/bookingMng";
import { LoadingSpinner } from "../loadingSpinner/LoadingSpinner";
import StripCheckoutForm from "./StripCheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// const clientSecret =
//   "pi_3NxNDBF0ADPPKmQ81Y1hvDUt_secret_CwBoBVooILvxoYb8wsxEvXV5j";
export const StripePayment = ({ stripePaymentBody, total }) => {
  const dispatch = useDispatch();
  const { clientSecret, loading, failure } = useSelector(
    ({ bookingMng }) => bookingMng.createPaymentIntent
  );

  useEffect(() => {
    dispatch(
      actions.createPaymentIntent({
        amount: `${total}`,
        stripeWebhookPaymentRequest: stripePaymentBody,
      })
    );
  }, [total]);
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  if (loading) {
    return <LoadingSpinner />;
  }
  if (failure) {
    return <div>Something went wrong! Please contact Flyme247 team.</div>;
  }
  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        <StripCheckoutForm />
      </Elements>
    </>
  );
};
