import { communicationClient } from "./client";
import { bookingClient } from "./client";

export const getTemplates = () =>
  communicationClient.get("/communication/v1/notificationDetail/GetAll");

export const getSpecificTemplate = ({ id }) =>
  communicationClient.get(`/communication/v1/notificationDetail/${id}`);

export const getTemplateTypes = () =>
  communicationClient.get("/communication/v1/notificationType/GetAll");

export const createTemplateType = (params) =>
  communicationClient.post("/communication/v1/notificationType", params);
//
export const editTemplateType = ({ id, ...rest }) =>
  communicationClient.put(`/communication/v1/notificationType/${id}`, {
    id,
    ...rest,
  });

export const deleteTemplateType = ({ id }) =>
  communicationClient.delete(`/communication/v1/notificationType/${id}`);

export const createTemplate = (params) =>
  communicationClient.post("/communication/v1/notificationDetail", {
    ...params,
  });

export const deleteTemplate = ({ id }) =>
  communicationClient.delete(`/communication/v1/notificationDetail/${id}`);

export const editTemplate = ({ $id, id, ...params }) =>
  communicationClient.put(`/communication/v1/notificationDetail/${id}`, {
    id,
    ...params,
  });

export const getEmailParameters = () =>
  bookingClient.get(`/booking/v1/Orders/EmailParameters`);
