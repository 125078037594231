import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardBody } from "../../../../../_partials/controls";
import { BookingEdit } from "./bookings/bookingEdit/BookingEdit";
import { QueueTable } from "./bookings/QueueTable";
import {
  actions,
  actions as bookingMngActions,
} from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import {
  orderFilterTypesMap,
  sortTypesMap,
  TransactionProcessing,
} from "../../../../../../redux/state/bookingMng/bookingMngConstants";
import { Grid, Paper } from "@material-ui/core";
import { SearchCard } from "../../../bookingManagement/common/SearchCard";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { filterOptions } from "../../../../../../redux/state/invoices/invoicesConstants";
import { Pagination } from "@material-ui/lab";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { LoadingSpinner } from "../../../common";

export const Queue = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [filter, setFilter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [secondSearchValue, setSecondSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ bookingMng }) => bookingMng.updateBookingTickets);
  const {
    success: cancelSuccess,
    loading: cancelLoading,
    failure: cancelFailure,
  } = useSelector(({ bookingMng }) => bookingMng.cancelProcessingBooking);
  const { data, loading: dataLoading } = useSelector(
    ({ bookingMng }) => bookingMng.specificOrder
  );
  const { list, paginationInfo } = useSelector(
    ({ bookingMng }) => bookingMng.orders
  );
  const { totalPages } = paginationInfo ?? {};
  useEffect(() => {
    setSearchValue("");
  }, [filter]);
  const tableActions = {
    openEditCustomerDialog: (id) => {
      setSelectedId(id);
      setShowEdit(true);
    },
    openCancelModal: (id) => {
      setSelectedId(id);
      setOpenConfirmationModal(true);
    },
  };

  useEffect(() => {
    if (!!selectedId && OpenConfirmationModal) {
      dispatch(actions.getSpecificOrderByBookingId({ selectedId }));
    }
  }, [OpenConfirmationModal, dispatch, selectedId]);

  const getSelectBooking = () => list?.filter(({ id }) => selectedId === id)[0];
  const onUpdate = ({ id, tickets }) => {
    dispatch(bookingMngActions.updatePassengersTickets({ id, tickets }));
  };
  useEffect(() => {
    dispatch(
      actions.filterOrdersPagination({
        pageNumber,
        sortingParams: [
          {
            sortOrder: sortTypesMap.descending,
            columnName: "insertedOn",
          },
        ],
        filterParam: [
          {
            columnName: "bookingStatus",
            filterValue: TransactionProcessing,
            filterOption: filterOptions.IsEqualTo,
          },
          ...appliedFilters,
        ],
        type: orderFilterTypesMap.ticketTracking,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  useEffect(() => {
    if (editSuccess) {
      setShowEdit(false);
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Booking updated",
        })
      );
      dispatch(
        bookingMngActions.filterAllOrders({
          status: TransactionProcessing,
        })
      );
      dispatch(bookingMngActions.clearUpdateBooking());
      setSelectedId(undefined);
    }
  }, [editSuccess, dispatch]);
  useEffect(() => {
    if (editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(bookingMngActions.clearUpdateBooking());
    }
  }, [dispatch, editFailure]);
  const onCancelSuccess = () => {
    setOpenConfirmationModal(false);
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        message: "Booking Cancelled Successfully!",
      })
    );
    dispatch(
      bookingMngActions.filterAllOrders({
        status: TransactionProcessing,
      })
    );
    dispatch(bookingMngActions.clearCancelProcessingBooking());
    setSelectedId(undefined);
  };
  const onCancelFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(bookingMngActions.clearCancelProcessingBooking());
  };
  const handleChange = (e, isSecond) => {
    if (isSecond) {
      setSecondSearchValue(e?.target?.value || e);
    } else {
      setSearchValue(e?.target?.value || e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setPageNumber(1);
    let filtersParam = [{}];
    if (filter === "insertedOn") {
      filtersParam = [
        {
          columnName: filter,
          filterValue: searchValue,
          filterOption: filterOptions.IsGreaterThanOrEqualTo,
        },
        {
          columnName: filter,
          filterValue: secondSearchValue,
          filterOption: filterOptions.IsLessThanOrEqualTo,
        },
      ];
    } else {
      filtersParam = [
        {
          columnName: filter,
          filterValue: searchValue,
          filterOption: filterOptions.IsEqualTo,
        },
      ];
    }
    setAppliedFilters(filtersParam);

    dispatch(
      actions.filterOrdersPagination({
        pageNumber,
        filterParam: [
          {
            columnName: "bookingStatus",
            filterValue: TransactionProcessing,
            filterOption: filterOptions.IsEqualTo,
          },
          ...filtersParam,
        ],
        type: orderFilterTypesMap.ticketTracking,
      })
    );
  };
  const reset = () => {
    setSearchValue("");
    setSecondSearchValue("");
    setAppliedFilters([{}]);
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const onConfirmCancel = () => {
    dispatch(actions.cancelProcessingBooking({ id: data.id }));
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            onSubmit={handleSubmit}
            reset={reset}
            queue={true}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Card>
          <ConfirmationModal
            onHide={() => {
              setSelectedId(undefined);
              setOpenConfirmationModal(false);
            }}
            show={OpenConfirmationModal}
            title="Cancel and refund order"
            // body="Are you sure you want to cancel this order and refund the amount back to Agency?"
            success={cancelSuccess}
            failure={cancelFailure}
            loading={cancelLoading}
            onSuccess={onCancelSuccess}
            onFailure={onCancelFailure}
            onConfirm={onConfirmCancel}
          >
            {dataLoading ? (
              <div className="text-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                Are you sure you want to cancel this order{" "}
                <strong>{selectedId}</strong> and refund the amount back to
                Agency?
              </>
            )}
          </ConfirmationModal>
          <BookingEdit
            onHide={() => {
              setSelectedId(undefined);
              setShowEdit(false);
            }}
            show={showEdit}
            setIds={setIds}
            bookingId={selectedId}
            data={getSelectBooking()}
            success={editSuccess}
            loading={editLoading}
            onSave={onUpdate}
          />
          <CardBody className="p-2">
            <QueueTable
              subPermissions={subPermissions}
              onClickActions={tableActions}
              setIds={setIds}
              ids={ids}
            />
            <div className="d-flex justify-content-center">
              <Pagination
                className="mx-auto mt-2"
                onChange={handlePageChange}
                page={pageNumber}
                count={totalPages}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};
