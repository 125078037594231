import { isDev } from "../auth/authConstants";

export const MODULE_NAME = "company";

export const ORGANIZATION_TYPE_ADMIN = isDev ? 1 : 2;
export const ORGANIZATION_TYPE_AGENCY = isDev ? 0 : 1;
export const ORGANIZATION_TYPE_SUBSCRIBER = 3;

export const creditAgreementTypeMap = {
  oneday: 1,
  twodays: 2,
  week: 3,
};

export const creditAgreementLabelMap = {
  1: "One Day",
  2: "Two Days",
  3: "Week",
};

const s = [
  {
    id: 23,
  },
  {
    id: 20,
  },
  {
    id: 19,
  },
  {
    id: 18,
  },
  {
    id: 17,
  },
  {
    id: 16,
  },
  {
    id: 15,
  },
  {
    id: 14,
  },
  {
    id: 13,
  },
  {
    id: 12,
  },
  {
    id: 11,
  },
  {
    id: 10,
  },
  {
    id: 9,
  },
  {
    id: 8,
  },
  {
    id: 7,
  },
  {
    id: 6,
  },
  {
    id: 5,
  },
  {
    id: 4,
  },
  {
    id: 2,
  },
];
