import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/company";

export default function useCompanyName() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ company }) => company.allCompanies);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllCompanies());
    }
  }, [dispatch, list]);
  const getCompanyName = useCallback(
    (companyId) => list?.filter(({ id }) => +id === +companyId)?.[0]?.name,
    [list]
  );
  return { getCompanyName, allCompaniesLoading: loading };
}
