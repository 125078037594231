import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import { MODULE_NAME } from "./userConstants";

export const CREATE = requestActions(MODULE_NAME, "CREATE");
export const EDIT = requestActions(MODULE_NAME, "EDIT");
export const EDIT_SPECIFIC_USER = requestActions(
  MODULE_NAME,
  "EDIT_SPECIFIC_USER"
);
export const GET_ALL_USER = requestActions(MODULE_NAME, "GET_ALL_USER");
export const GET_USER = requestActions(MODULE_NAME, "GET_USER");
export const GET_SPECIFIC_USER = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_USER"
);
export const GET_USER_PERMISSION = requestActions(
  MODULE_NAME,
  "GET_USER_PERMISSION"
);
export const GET_USER_SESSIONS = requestActions(
  MODULE_NAME,
  "GET_USER_SESSIONS"
);
export const GET_CURRENT_USER_PERMISSION = requestActions(
  MODULE_NAME,
  "GET_CURRENT_USER_PERMISSION"
);
export const EDIT_USER_PERMISSION = requestActions(
  MODULE_NAME,
  "EDIT_USER_PERMISSION "
);
export const CHANGE_PASSWORD = requestActions(MODULE_NAME, "CHANGE_PASSWORD ");
export const VERIFY_USER_EMAIL = requestActions(
  MODULE_NAME,
  "VERIFY_USER_EMAIL "
);
export const BLOCK = requestActions(MODULE_NAME, "BLOCK");
export const UNBLOCK = requestActions(MODULE_NAME, "UNBLOCK");
export const GET_ALL_ROLES = requestActions(MODULE_NAME, "GET_ALL_ROLES");
export const GET_ROLE = requestActions(MODULE_NAME, "GET_ROLE");
export const EDIT_ROLE_PERMISSION = requestActions(
  MODULE_NAME,
  "EDIT_ROLE_PERMISSION"
);
export const GET_ROLE_PERMISSION = requestActions(
  MODULE_NAME,
  "GET_ROLE_PERMISSION"
);
export const GET_USERS_BY_ROLE = requestActions(
  MODULE_NAME,
  "GET_USERS_BY_ROLE"
);
export const EDIT_ROLE = requestActions(MODULE_NAME, "EDIT_ROLE");
export const CREATE_ROLE = requestActions(MODULE_NAME, "CREATE_ROLE");
export const DELETE_ROLE = requestActions(MODULE_NAME, "DELETE_ROLE");
export const FILTER_USERS = requestActions(MODULE_NAME, "FILTER_USERS");
export const GET_ALL_PROMO_CODES = requestActions(
  MODULE_NAME,
  "GET_ALL_PROMO_CODES"
);
export const GET_PROMO_CODE = requestActions(MODULE_NAME, "GET_PROMO_CODE");
export const CREATE_PROMO_CODE = requestActions(
  MODULE_NAME,
  "CREATE_PROMO_CODE"
);
export const EDIT_PROMO_CODE = requestActions(MODULE_NAME, "EDIT_PROMO_CODE");
export const DELETE_PROMO_CODE = requestActions(
  MODULE_NAME,
  "DELETE_PROMO_CODE"
);
export const GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY = requestActions(
  MODULE_NAME,
  "GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY"
);

export const GET_PROMO_CODE_BY_CODE = requestActions(
  MODULE_NAME,
  "GET_PROMO_CODE_BY_CODE"
);

export const DELETE_USER = requestActions(MODULE_NAME, "DELETE_USER");

export const RESET = `${MODULE_NAME}/RESET`;

export const deleteUser = ({ id }) =>
  requestApiCall("deleteUser", { id }, DELETE_USER);

export const getPromoCodeByCode = ({ code }) =>
  requestApiCall("getPromoCodeByCode", { code }, GET_PROMO_CODE_BY_CODE);

export const getSubscriptionPaymentsByCompany = ({ companyId }) =>
  requestApiCall(
    "getSubscriptionPaymentsByCompany",
    { companyId },
    GET_SUBSCRIPTION_PAYMENTS_BY_COMPANY
  );

export const getAllPromoCodes = () =>
  requestApiCall("getAllPromoCodes", {}, GET_ALL_PROMO_CODES);

export const getPromoCode = ({ id }) =>
  requestApiCall("getPromoCode", { id }, GET_PROMO_CODE);

export const createPromoCode = (params) =>
  requestApiCall("createPromoCode", params, CREATE_PROMO_CODE);

export const editPromoCode = (params) =>
  requestApiCall("editPromoCode", params, EDIT_PROMO_CODE);

export const deletePromoCode = ({ id }) =>
  requestApiCall("deletePromoCode", { id }, DELETE_PROMO_CODE);

export const deleteRole = (params) =>
  requestApiCall("deleteRole", params, DELETE_ROLE);

export const createRole = (params) =>
  requestApiCall("createRole", params, CREATE_ROLE);

export const editRole = (params) =>
  requestApiCall("editRole", params, EDIT_ROLE);

export const unblockUser = (params) =>
  requestApiCall("unblockUser", params, UNBLOCK);

export const blockUser = (params) => requestApiCall("blockUser", params, BLOCK);

export const verifyUserEmail = (params) =>
  requestApiCall("verifyUserEmail", params, VERIFY_USER_EMAIL);

export const createUser = (params) =>
  requestApiCall("createUser", params, CREATE);

export const editUser = (params) => requestApiCall("editUser", params, EDIT);

export const ediSpecificUser = (params) =>
  requestApiCall("editUser", params, EDIT_SPECIFIC_USER);

export const getSpecificUser = ({ id }) =>
  requestApiCall("getUser", { id }, GET_SPECIFIC_USER);

export const filterUsers = ({
  sortingParams,
  filterParam,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterUsers",
    { sortingParams, filterParam, pageNumber, pageSize },
    FILTER_USERS
  );

export const getAllUsers = () =>
  requestApiCall("getAllUsers", {}, GET_ALL_USER);

export const getUser = ({ id }) => requestApiCall("getUser", { id }, GET_USER);

export const changePassword = (params) =>
  requestApiCall("changePassword", params, CHANGE_PASSWORD);

export const getCurrentUserPermissions = (params) =>
  requestApiCall("getUserPermissions", params, GET_CURRENT_USER_PERMISSION);

export const getUserPermissions = (params) =>
  requestApiCall("getUserPermissions", params, GET_USER_PERMISSION);

export const getUserSessions = (params) =>
  requestApiCall("getUserSessions", params, GET_USER_SESSIONS);

export const editUserPermissions = (params) =>
  requestApiCall("editUserPermissions", params, EDIT_USER_PERMISSION);

export const getAllRoles = (params) =>
  requestApiCall("getAllRoles", params, GET_ALL_ROLES);

export const getRole = (params) => requestApiCall("getRole", params, GET_ROLE);

export const getRolePermissions = (params) =>
  requestApiCall("getRolePermissions", params, GET_ROLE_PERMISSION);

export const getUsersByRole = (params) =>
  requestApiCall("getUsersByRole", params, GET_USERS_BY_ROLE);

export const editRolePermissions = (params) =>
  requestApiCall("editRolePermissions", params, EDIT_ROLE_PERMISSION);

export const clearEditPermissions = () => ({
  type: EDIT_USER_PERMISSION.CLEAR,
});

export const clearEditRole = () => ({
  type: EDIT_ROLE.CLEAR,
});

export const clearCreateRole = () => ({
  type: CREATE_ROLE.CLEAR,
});

export const clearEdit = () => ({ type: EDIT.CLEAR });

export const clearDeleteRole = () => ({ type: DELETE_ROLE.CLEAR });

export const clearCreate = () => ({ type: CREATE.CLEAR });

export const clearChangePassword = () => ({ type: CHANGE_PASSWORD.CLEAR });

export const clearSpecificUser = () => ({ type: GET_SPECIFIC_USER.CLEAR });

export const clearRolePermissions = () => ({ type: GET_ROLE_PERMISSION.CLEAR });

export const clearEditSpecificUser = () => ({ type: EDIT_SPECIFIC_USER.CLEAR });

export const clearUserPermissions = () => ({ type: GET_USER_PERMISSION.CLEAR });

export const clearUserSessions = () => ({ type: GET_USER_SESSIONS.CLEAR });

export const clearCreatePromoCode = () => ({
  type: CREATE_PROMO_CODE.CLEAR,
});

export const clearEditPromoCode = () => ({ type: EDIT_PROMO_CODE.CLEAR });

export const clearDeletePromoCode = () => ({
  type: DELETE_PROMO_CODE.CLEAR,
});

export const clearDeleteUser = () => ({
  type: DELETE_USER.CLEAR,
});

export const reset = () => ({ type: RESET });
