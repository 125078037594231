/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDateTime, toAbsoluteUrl } from "../../../_helpers";
import { actions } from "../../../../redux/state/bookingMng";
import { LoadingSpinner } from "../common";
import {
  BookingHold,
  BookingIssue,
  BookingCancelled,
  BookingRefund,
  BookingPending,
  BookingFailed,
} from "../../../../redux/state/booking/bookingConstants";
import { parseRoute } from "../../../../app/helpers/formatters";
import {
  BOOKING_MANAGEMENT_TRACKING,
  TICKET_DETAILS,
} from "../../../../app/routesMap";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import {
  TransactionProcessing,
  orderFilterTypesMap,
} from "../../../../redux/state/bookingMng/bookingMngConstants";
import { trackingBadgeText } from "../bookingManagement/Tracking";
import useAirlinesList from "../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import { ADMIN_COMPLETED_ORDER_DETAILS } from "../../../../app/routesMapAdmin";
import { filterOptions } from "../../../../redux/state/invoices/invoicesConstants";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";

export const TableRow = ({ data, goToDetails }) => {
  const { insertedOn, flight, id, bookingStatus, segment, bookingId } = data;
  const badgeClassNames = {
    [BookingIssue]: "label-light-success",
    [BookingHold]: "label-light-warning",
    [BookingCancelled]: "label-light-danger",
    [BookingRefund]: "label-light-info",
    [TransactionProcessing]: "label-light-warning",
    [BookingPending]: "label-light-warning",
    [BookingFailed]: "label-light-danger",
  };
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const getAirportName = (airportCode) =>
    airportsList?.filter(({ code }) => airportCode === code)?.[0]?.name;
  const getAirlineName = (airlineCode) =>
    airlinesList?.filter(({ code }) => airlineCode === code)[0]?.name;

  const { date } = getDateTime(insertedOn);
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}

  const airlineNm = segments?.[0].iSeg[0]?.airlineNm;

  const rows = (
    <>
      <td className="pl-0 py-4 d-flex align-items-center">
        <div className="flex-row">
          <a
            href="#"
            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
          >
            {bookingId}
          </a>
        </div>
      </td>
      <td className="pl-0">
        <div>
          <span
            href="#"
            className="text-dark-75 font-weight-bolder mb-1 font-size-lg"
          >
            {/* {getAirportName(flight.fromCode)} */}
            {flight?.fromCode}
          </span>
          <span className="font-weight-bolder">{" > "}</span>
          <span
            href="#"
            className="text-dark-75 font-weight-bolder mb-1 font-size-lg"
          >
            {/* {getAirportName(flight.toCode)} */}
            {flight?.toCode}
          </span>
        </div>
        {!matchesSm && (
          <div>
            {/* <span className="text-muted"></span>{" "} */}
            <span className="text-muted font-weight-bold">{date}</span>
          </div>
        )}
      </td>
      {matchesSm && (
        <td className="pl-0">
          <span className="text-primary font-weight-bolder d-block font-size-lg">
            {date}
          </span>
        </td>
      )}
      {matchesSm && (
        <td className="pl-0">
          <span className="text-muted font-weight-bold">
            {airlineNm ? getAirlineName(airlineNm) : "-"}
          </span>
        </td>
      )}
      <td className="pl-0">
        <span
          className={`label label-lg ${badgeClassNames[bookingStatus]} label-inline`}
        >
          {trackingBadgeText[bookingStatus]}
        </span>
      </td>
      {/* {matchesSm && ( */}
      <td className="">
        <button
          onClick={() => goToDetails(bookingId)}
          className="btn btn-icon btn-light btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
            ></SVG>
          </span>
        </button>
      </td>
      {/* )} */}
    </>
  );

  const mobileRowDetails = [
    {
      label: "Date",
      value: date,
    },
    {
      label: "Airline",
      value: airlineNm ? getAirlineName(airlineNm) : "-",
    },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export function Bookings({ className, isUser, isAll }) {
  const [key, setKey] = useState("Month");
  const { id } = useParams();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { list, loading } = useSelector(({ bookingMng }) => bookingMng.orders);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  useEffect(() => {
    if (isAdmin) {
      if (isAll) {
        dispatch(
          actions.filterOrdersPagination({
            type: orderFilterTypesMap.ticketTracking,
          })
        );
      } else if (isUser) {
        dispatch(
          actions.filterOrdersPagination({
            type: orderFilterTypesMap.ticketTracking,
            filterParam: [
              {
                columnName: "createdBy",
                filterValue: id,
                filterOption: filterOptions.IsEqualTo,
              },
            ],
          })
        );
      } else {
        dispatch(
          actions.filterOrdersPagination({
            type: orderFilterTypesMap.ticketTracking,
            filterParam: [
              {
                columnName: "agencyId",
                filterValue: id,
                filterOption: filterOptions.IsEqualTo,
              },
            ],
          })
        );
      }
    } else {
      dispatch(
        actions.filterOrdersPagination({
          type: orderFilterTypesMap.ticketTracking,
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: companyInUserId,
              filterOption: filterOptions.IsEqualTo,
            },
          ],
        })
      );
    }
    return () => dispatch(actions.reset());
  }, [companyInUserId, dispatch, id, isAdmin, isAll, isUser]);
  const goToDetails = (id) => {
    history.push(
      parseRoute(isAdmin ? ADMIN_COMPLETED_ORDER_DETAILS : TICKET_DETAILS, {
        id,
      })
    );
  };
  const tableContent = list?.map((item, index) => (
    <TableRow key={index} goToDetails={goToDetails} data={item} />
  ));
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Recent Bookings
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            booking brief
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            onClick={() => history.push(BOOKING_MANAGEMENT_TRACKING)}
            className="btn btn-sm btn-primary"
          >
            More details
          </button>
        </div>
      </div>
      {/* Body */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className={`tab-content mt-5`} id="myTabTables12">
            {/* begin::Tap pane MONTH */}
            <div
              className={`tab-pane fade ${
                key === "Month" ? "show active" : ""
              }`}
            >
              <div className="table-responsive">
                <table className="table table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0 text-muted">Booking ID</th>
                      <th
                        className="p-0 text-muted"
                        style={{ minWidth: "80px" }}
                      >
                        Route
                      </th>
                      {matchesSm && (
                        <th
                          className="p-0 text-primary"
                          // style={{ minWidth: "120px" }}
                        >
                          Date
                        </th>
                      )}
                      {matchesSm && (
                        <th
                          className="p-0 text-muted"
                          // style={{ minWidth: "140px" }}
                        >
                          Airline
                        </th>
                      )}
                      <th
                        className="p-0 text-muted"
                        // style={{ minWidth: "110px" }}
                      >
                        STATUS
                      </th>
                      <th
                        className="p-0 text-muted"
                        // style={{ maxWidth: "120px" }}
                      >
                        DETAILS
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
                {list?.length === 0 && (
                  <Typography
                    variant="h5"
                    className="text-center w-100 text-muted mb-5"
                  >
                    No bookings to show
                  </Typography>
                )}
              </div>
            </div>
            {/* end::Tap pane Day */}
          </div>
        </div>
      )}
    </div>
  );
}
