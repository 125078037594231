import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../_redux/customers/customersActions";
import { actions } from "../../../../../../../../redux/state/bookingMng";
import { BookingEditForm } from "./BookingEditForm";
import { BookingEditHeader } from "./BookingEditHeader";
import { LoadingSpinner } from "../../../../../common";

// import { useCustomersUIContext } from "../CustomersUIContext";

export function BookingEdit({
  bookingId,
  onSave,
  success,
  loading,
  // data,
  onHide,
  show,
}) {
  const dispatch = useDispatch();
  const { data, loading: dataLoading } = useSelector(
    ({ bookingMng }) => bookingMng.specificOrder
  );
  useEffect(() => {
    if (success) {
      onHide();
    }
  }, [success]);
  useEffect(() => {
    if (bookingId) {
      dispatch(actions.getSpecificOrderByBookingId({ bookingId }));
    }
  }, [bookingId, dispatch]);
  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {dataLoading ? (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <BookingEditHeader title="Edit Booking" />
          <BookingEditForm
            loading={loading}
            // saveCustomer={saveCustomer}
            // actionsLoading={actionsLoading}
            // customer={customerForEdit || customersUIProps.initCustomer}
            onSave={onSave}
            onHide={onHide}
            data={data}
          />
        </>
      )}
    </Modal>
  );
}
