import { Paper, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";

import { actions } from "../../../../../../redux/state/supplier";
import { Button, Input, LoadingSpinner, Select } from "../../../common";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { Modal } from "react-bootstrap";
import {
  PAYMENT_TYPE_CREDIT_TOPUP,
  PAYMENT_TYPE_TOPUP,
} from "../../../../../../redux/state/accountBalance/accountBalanceConstants";

const supplierTopupSchema = Yup.object().shape({
  isCredit: Yup.boolean().required("Field is required"),
  amount: Yup.number()
    .positive()
    .required("Field is required"),
  // currency: Yup.string().required("Field is required"),
  remarks: Yup.string(),
});

const topupTypeOptions = [
  { label: "Balance", value: false },
  { label: "Credit", value: true },
];

export const AllocateBalanceModal = ({ onHide, show, supplierData }) => {
  const dispatch = useDispatch();
  const [hasAccountBalance, setHasAccountBalance] = useState(true);
  const { data, loading } = useSelector(
    ({ supplier }) => supplier.supplierBalance
  );
  const { loading: createLoading } = useSelector(
    ({ supplier }) => supplier.create
  );
  const { success, failure } = useSelector(
    ({ supplier }) => supplier.addDeductAmount
  );
  const { id, name } = supplierData || {};
  const { availableBalance, availableCredit, payableAmount } = data || {};
  const formFields = useFormik({
    initialValues: {
      isCredit: false,
      amount: "",
      currency: "",
      paymentProof: "",
      remarks: "",
    },
    validationSchema: supplierTopupSchema,
    onSubmit: ({ amount, remarks, isCredit }) => {
      dispatch(
        actions.addToSupplierBalance({
          id,
          amount,
          isCredit,
          remarks,
          type: "IN",
          status: isCredit ? PAYMENT_TYPE_CREDIT_TOPUP : PAYMENT_TYPE_TOPUP,
        })
      );
    },
  });
  const { resetForm } = formFields;
  useEffect(() => {
    if (show) {
      dispatch(actions.getSupplierBalanceBySupplierId({ id }));
    }
    return () => {
      dispatch(actions.clearEdit());
      dispatch(actions.clearCreate());
      dispatch(actions.clearAddDeduct());
      dispatch(actions.clearSupplierBalance());
    };
  }, [dispatch, id, show]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "balance was added successfully to supplier",
        })
      );
      resetForm();
      onHide();
      dispatch(actions.getAllSuppliers());
      dispatch(actions.clearAddDeduct());
    }
  }, [dispatch, resetForm, onHide, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "something went wrong! couldn't add balance to supplier",
        })
      );
      dispatch(actions.clearAddDeduct());
    }
  }, [dispatch, failure]);
  useEffect(() => {
    setHasAccountBalance(!!data);
  }, [data]);

  const createBalanceAccount = () => {
    dispatch(
      actions.createSupplierBalance({
        supplierInSupplierBalanceId: id,
        availableBalance: 0,
        availableCredit: 0,
        balanceAlert: 0,
        payableAmount: 0,
      })
    );
  };
  return (
    <Modal
      size="md"
      onHide={() => onHide(false)}
      show={show}
      className="d-flex align-items-center justify-content-center"
    >
      <Paper className="bg-white rounded" style={{ overflow: "hidden" }}>
        <div className="bg-primary p-7">
          <Typography variant="h5" className="font-weight-bolder text-white">
            Allocate Balance
          </Typography>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {hasAccountBalance ? (
              <div className="py-7 px-20">
                <Grid container className="mb-7" spacing={3}>
                  <Grid
                    item
                    xs={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Typography variant="h5" className="mb-3">
                      {name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="bg-white p-3 text-center h-100 d-flex flex-column justify-content-center"
                  >
                    <Typography variant="h5" className="mb-3">
                      <span className="text-primary">$</span>
                      {(availableBalance || 0).toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="mb-2 font-weight-bold"
                    >
                      Available Balance
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="bg-white p-3 text-center h-100 d-flex flex-column justify-content-center"
                  >
                    <Typography variant="h5" className="mb-3">
                      <span className="text-primary">$</span>
                      {(availableCredit || 0).toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="mb-2 font-weight-bold"
                    >
                      Available Credit
                    </Typography>
                    <Typography variant="body2">
                      Payable Balance: {(payableAmount || 0).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <BalanceModalForm formFields={formFields} onCancel={onHide} />
              </div>
            ) : (
              <div className="text-center mt-5">
                <Typography variant="h5">
                  This supplier doesn't have a balance account!
                </Typography>
                <div className="d-flex justify-content-center mt-7 mb-3">
                  <Button variant="secondary" onClick={() => onHide(false)}>
                    Cancel
                  </Button>
                  <Button
                    loading={createLoading}
                    onClick={createBalanceAccount}
                    className="ml-7"
                  >
                    Create Balance Account
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Paper>
    </Modal>
  );
};

const BalanceModalForm = ({ formFields, onCancel }) => {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = formFields;
  const { loading } = useSelector(({ supplier }) => supplier.addDeductAmount);

  return (
    <form onSubmit={handleSubmit} style={{ color: "#464E5F" }}>
      {/* <Select
        options={[]}
        showErrors={touched.paymentMethod}
        errorMessage={errors.paymentMethod}
        onChange={(value) => setFieldValue("paymentMethod", value)}
        value={values.paymentMethod}
        name="paymentMethod"
        outlined={false}
        fixedLabelWidth
        spacing
        label="Payment Method"
        disabled={true}
      /> */}
      <Select
        options={topupTypeOptions}
        showErrors={touched.isCredit}
        errorMessage={errors.isCredit}
        onChange={(value) => setFieldValue("isCredit", value)}
        value={values.isCredit}
        name="isCredit"
        outlined={false}
        fixedLabelWidth
        spacing
        label="Top up type"
      />
      <div className="d-flex align-items-end w-100">
        <span style={{ flexGrow: 1 }}>
          <Input
            spacing
            showErrors={touched.amount}
            errorMessage={errors.amount}
            onChange={handleChange}
            value={values.amount}
            name="amount"
            outlined={false}
            fixedLabelWidth
            label="Amount"
            placeholder="Type here"
          />
        </span>
        {/* <span className="w-150px ml-3">
          <Select
            spacing
            showErrors={touched.currency}
            errorMessage={errors.currency}
            onChange={(value) => setFieldValue("currency", value)}
            value={values.currency}
            name="currency"
            outlined={false}
            fixedLabelWidth
            classNames="m-0"
            placeholder="currency"
            options={currencyOptions}
          />
        </span> */}
      </div>
      <div className="d-flex align-items-end w-100">
        <span style={{ flexGrow: 1 }}>
          <Input
            multiline
            spacing
            showErrors={touched.remarks}
            errorMessage={errors.remarks}
            onChange={handleChange}
            value={values.remarks}
            name="remarks"
            outlined={false}
            fixedLabelWidth
            label="Remark"
            placeholder="Type here"
          />
        </span>
        {/* <span className="w-150px ml-3">
          <Select
            spacing
            showErrors={touched.currency}
            errorMessage={errors.currency}
            onChange={(value) => setFieldValue("currency", value)}
            value={values.currency}
            name="currency"
            outlined={false}
            fixedLabelWidth
            classNames="m-0"
            placeholder="Credit"
            options={[]}
          />
        </span> */}
      </div>
      <div className="d-flex justify-content-center mt-7 mb-3">
        <Button onClick={() => onCancel(false)} variant="secondary">
          Cancel
        </Button>
        <Button loading={loading} type="submit" className="ml-7">
          Save
        </Button>
      </div>
    </form>
  );
};
