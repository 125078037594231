import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../common";

export const ContentSectionAction = ({ actions }) => {
  return (
    <div className="d-flex justify-content-center">
      {actions.map(({ title, color, link, isExternal }, index) => {
        const formattedColor = color.replace("_", "-");
        return (
          <Link key={index} to={link} target={isExternal ? "_blank" : ""}>
            <Button variant={formattedColor} className="font-weight-bold">
              {title}
            </Button>
          </Link>
        );
      })}
    </div>
  );
};
