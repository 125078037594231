import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../../../_helpers";
import { Button, Input, LoadingSpinner, Select } from "../../../common";
import { InventoryFormActions } from "./InventoryFormActions";
import { useState } from "react";
import { InventoryFormSection } from "./InventoryFormSection";
import usePkgClassName from "../../../../../../app/helpers/hooks/usePkgClassName";
import usePkgRoomTypesOptions from "../../../../../../app/helpers/hooks/usePkgRoomTypesOptions";
import { useMemo } from "react";

const pricingSchema = Yup.object().shape({
  pricing: Yup.lazy((value) => {
    if (value === undefined) {
      return Yup.mixed().notRequired();
    }
    const pricing = {};
    Object.keys(value).map(
      (key) => (pricing[key] = Yup.string().required("Required Field"))
    );
    return Yup.object().shape({ ...pricing });
  }),
  roomFees: Yup.lazy((value) => {
    if (!value?.length) {
      return Yup.mixed().notRequired();
    }
    return Yup.array().of(
      Yup.object().shape({
        roomType: Yup.string().required("Required Field"),
        fee: Yup.string().required("Required Field"),
      })
    );
  }),
});

export const PackagePricing = ({
  setSelectedTab,
  selectedTab,
  data,
  onSubmit,
  selectedClasses,
}) => {
  const [selectedPanel, setSelectedPanel] = useState(0);
  const { getPkgClassName, loading: pkgClassesLoading } = usePkgClassName();
  const {
    loading: pkgRoomTypesOptionLoading,
    pkgRoomTypesOptions,
  } = usePkgRoomTypesOptions();

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setSelectedPanel(isExpanded ? panel : false);
  };
  const roomFeesInitialValues = {
    roomType: "",
    fee: "",
  };
  const fields = useFormik({
    initialValues: data ?? {
      pricing: {},
      roomFees: [roomFeesInitialValues],
    },
    validationSchema: pricingSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const {
    getFieldProps,
    errors,
    touched,
    setFieldValue,
    values,
    handleSubmit,
  } = fields;
  const { pricing, roomFees } = values;

  const calculatedRoomTypesOptions = useMemo(() => {
    if (!pkgRoomTypesOptions) return [];
    return pkgRoomTypesOptions.map((option) => {
      const isOptionUsedBefore = roomFees.some(
        ({ roomType }) => roomType === option.value
      );
      return { ...option, isDisabled: isOptionUsedBefore };
    });
  }, [pkgRoomTypesOptions, roomFees]);

  useEffect(() => {
    if (selectedClasses) {
      const roomFeesDefaultValues = selectedClasses.reduce(
        (acc, packageClassId) => ({ ...acc, [packageClassId]: "" }),
        {}
      );
      setFieldValue("pricing", roomFeesDefaultValues);
    }
  }, [selectedClasses, setFieldValue]);

  const handleAddRoomFees = () => {
    setFieldValue("roomFees", [...roomFees, roomFeesInitialValues]);
  };
  const handleRemoveRoomFees = (index) => {
    const segmentNew = [...roomFees];
    segmentNew.splice(index, 1);
    setFieldValue("roomFees", [...segmentNew]);
  };
  return (
    <form onSubmit={handleSubmit}>
      {pkgClassesLoading || pkgRoomTypesOptionLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <InventoryFormSection title="Prices" hasSeparator>
            <Grid container spacing={3}>
              {Object.keys(pricing).map((key) => {
                const pkgClassName = getPkgClassName(+key);
                return (
                  <Grid item xs={12} md={6}>
                    <Input
                      required
                      variant="secondary"
                      {...getFieldProps(`pricing.${key}`)}
                      showErrors={touched.pricing?.[key]}
                      errorMessage={errors.pricing?.[key]}
                      label={pkgClassName}
                      placeholder="157"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </InventoryFormSection>
          <InventoryFormSection title="Room Extra Fees">
            {roomFees.map(({ roomType }, index) => (
              <ExpansionPanel
                expanded={selectedPanel === index}
                onChange={handlePanelChange(index)}
                elevation={0}
                className="border"
                key={`roomFee-${index}`}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <span className="svg-icon svg-icon-gray-400">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/navigation/Angle-down.svg"
                        )}
                      />
                    </span>
                  }
                >
                  <Typography
                    variant="h6"
                    className="text-primary font-weight-bold"
                  >
                    Room Extra Fee {index + 1}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box className="d-flex flex-column w-100">
                    {roomFees.length > 1 && (
                      <button
                        onClick={() => handleRemoveRoomFees(index)}
                        className="btn text-danger text-hover-white btn-hover-danger p-2 ml-auto p-0"
                        type="button"
                      >
                        {/* <span className="svg-icon m-0">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/cancel.svg"
                            )}
                          />\
                        </span> */}
                        Remove
                      </button>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Select
                          required
                          value={roomType}
                          errorMessage={errors.roomFees?.[index]?.roomType}
                          showErrors={touched.roomFees?.[index]?.roomType}
                          onChange={(v) =>
                            setFieldValue(`roomFees.${index}.roomType`, v)
                          }
                          // isLoading={airportLoading}
                          options={calculatedRoomTypesOptions}
                          variant="secondary"
                          label="Room Type"
                          // hasAddOption
                          // onClickAdd={() => setShowAirportForm(true)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          required
                          variant="secondary"
                          {...getFieldProps(`roomFees.${index}.fee`)}
                          errorMessage={errors.roomFees?.[index]?.fee}
                          showErrors={touched.roomFees?.[index]?.fee}
                          label="Extra Fee"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </InventoryFormSection>
          <Button
            onClick={handleAddRoomFees}
            className="d-flex align-items-center justify-content-center w-100 mt-4"
            type="button"
          >
            Add Room Fee
          </Button>
        </>
      )}
      <InventoryFormActions
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </form>
  );
};
