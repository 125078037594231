import { Typography } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

export const CARD_TYPE_VISA = "visa";
export const CARD_TYPE_MASTERCARD = "masterCard";

const cardLogoPath = {
  [CARD_TYPE_VISA]: "/media/svg/icons/Companies/visa.svg",
  [CARD_TYPE_MASTERCARD]: "/media/svg/icons/Companies/masterCard.svg",
};

const cardName = {
  [CARD_TYPE_VISA]: "Visa Card",
  [CARD_TYPE_MASTERCARD]: "MasterCard",
};

export const PaymentMethodCard = ({ cardType, primary }) => {
  return (
    <div className="d-flex justify-content-between align-items-center py-3 w-100">
      <div className="d-flex align-items-center">
        <div className="w-50px text-center">
          <img
            src={toAbsoluteUrl(cardLogoPath[cardType])}
            alt={cardName[cardType]}
          />
        </div>
        <div className="ml-5">
          <Typography variant="h6">{cardName[cardType]}</Typography>
          <Typography variant="body1" className="text-muted">
            Expires Oct 2024
          </Typography>
        </div>
        {primary && <span className={`label label-lg label-success label-inline font-weight-bold ml-3`}>
          Primary
        </span>}
      </div>
      <div className="d-flex align-items-center">
        <button className="btn btn-icon btn-light mr-3 h-30px w-30px">
          <span className="svg-icon svg-icon-sm ">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
            />
          </span>
        </button>
        <button className="btn btn-icon btn-light mr-3 h-30px w-30px">
          <span className="svg-icon svg-icon-sm ">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button className="btn btn-icon btn-light mr-3 h-30px w-30px">
          <span className="svg-icon svg-icon-sm ">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </div>
    </div>
  );
};
