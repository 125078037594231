import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_MASTERCARD,
  PAYMENT_TYPE_PREMIERE,
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_WALLET,
} from "../../../../../redux/state/booking/bookingConstants";
import { Button, CustomSelect, Input } from "../";
import { B2BWallet } from "./B2BWallet";
import { EWalletPayment } from "./EWalletPayment";
import {
  paymentForEnums,
  promoAppliedOnEnums,
  promoDurationTypeEnums,
} from "../../../../../redux/state/user/userConstants";
import { SubscriptionPriceCard } from "../../account/Subscription/SubscriptionPriceCard";
import { OrderPriceCard } from "../../admin/bookingMng/issuedOrderDetails/orderSummary/OrderPriceCard";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_SUCCESS } from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import LoadingModal from "../loadingModal/LoadingModal";
import { StripePayment } from "./StripePayment";
import { MasterCardPayment } from "./MasterCardPayment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3, 5),
    textAlign: "left",
    height: "100%",
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

export const paymentOptions = [
  { value: PAYMENT_TYPE_WALLET, label: "B2B wallet" },
  { value: PAYMENT_TYPE_CARD, label: "Debit/Credit Card" },
  { value: PAYMENT_TYPE_WAAFI, label: "WAAFI" },
  { value: PAYMENT_TYPE_PREMIERE, label: "Premiere Wallet" },
  { value: PAYMENT_TYPE_MASTERCARD, label: "Master Card" },
];

export const Payment = ({
  totalPrice,
  cardFormik,
  b2bFormik,
  premiereFormik,
  waafiFormik,
  promoCodeFormik,
  setPaymentType,
  paymentType,
  loading,
  bookingDetails,
  isIssueAdmin,
  agencyId,
  paymentFor,
  subscriptionDetails,
  stripePaymentBody,
  transactionFee,
  onSubmit,
  hasPromoCode = false,
  noCredit = false,
}) => {
  const dispatch = useDispatch();
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const {
    data: promoCodeData,
    loading: promoCodeLoading,
    success: promoCodeSuccess,
    failure: promoCodeFailure,
  } = useSelector(({ user }) => user.promoCode);

  const { setFieldError, values, setFieldValue } = promoCodeFormik ?? {};
  const { promoCode, discount } = values || {
    promoCode: "",
    discount: 0,
  };

  const handleApplyPromo = () => {
    dispatch(
      userActions.getPromoCodeByCode({
        code: promoCode,
      })
    );
  };

  const handleRemovePromo = () => {
    setIsPromoApplied(false);
    setFieldValue("discount", 0);
    setFieldValue("promoCode", "");
  };

  useEffect(() => {
    if (promoCodeSuccess) {
      if (promoCodeData?.appliedOn === promoAppliedOnEnums.subscription) {
        const isUsedOneTime =
          promoCodeData?.isUsed &&
          promoCodeData?.durationType === promoDurationTypeEnums.oneTime;
        if (isUsedOneTime) {
          setFieldError("promoCode", "Promotion code already used");
          return;
        }
        if (!promoCodeData?.isActive) {
          setFieldError("promoCode", "Promotion code is expired");
          return;
        }
        dispatch(
          inlineNotificationActions.setInlineNotification({
            message: "Promotion code applied successfully",
            type: NOTIFICATION_TYPE_SUCCESS,
          })
        );
        if (promoCodeData.isPercentage) {
          setFieldValue("discount", (promoCodeData.amount / 100) * totalPrice);
        } else {
          setFieldValue("discount", promoCodeData.amount);
        }
        setIsPromoApplied(true);
      } else {
        setFieldError("promoCode", "Invalid promotion code");
      }
    }
    if (promoCodeFailure) {
      setFieldError("promoCode", "Invalid promotion code");
      setIsPromoApplied(false);
    }
  }, [
    setFieldError,
    dispatch,
    promoCodeSuccess,
    promoCodeFailure,
    promoCodeData,
    totalPrice,
    setFieldValue,
  ]);
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);
  const loadingMessage =
    paymentType === PAYMENT_TYPE_WAAFI
      ? "Check your phone to confirm payment."
      : "Processing payment...";
  return (
    <Grid container spacing={3}>
      <LoadingModal
        loading={promoCodeLoading}
        message="Applying promo code..."
      />
      <LoadingModal loading={loading} message={loadingMessage} />
      {/* <LoadingModal show={loading} paymentType={paymentType} /> */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <h2 className="my-5">choose a payment method</h2>
          <Grid
            container
            spacing={3}
            className="d-flex justify-content-between"
          >
            <Grid item sm={4} xs={12}>
              <CustomSelect
                onChange={(e) => setPaymentType(+e.target.value)}
                options={paymentOptions}
                value={paymentType}
                noErrorMessage
              />
            </Grid>
            <Grid item sm={3} xs={12} className="d-flex justify-content-end">
              <button
                disabled={!paymentType}
                onClick={() => setConfirmed(true)}
                className="btn btn-primary mt-auto w-160px flex-grow-1"
              >
                confirm
              </button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {hasPromoCode && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} sm={12}>
              <div className="d-flex">
                <Input
                  type="text"
                  outlined={false}
                  label="Promotion Code"
                  showErrors={promoCodeFormik.touched["promoCode"]}
                  errorMessage={promoCodeFormik.errors["promoCode"]}
                  {...promoCodeFormik.getFieldProps("promoCode")}
                  autoComplete="new-password"
                  disabled={isPromoApplied}
                  className="max-w-300px"
                />
                {isPromoApplied ? (
                  <Button
                    variant="danger-inverse"
                    onClick={handleRemovePromo}
                    // loading={applyPromoLoading}
                    className="ml-3 mt-auto"
                    type="button"
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleApplyPromo}
                    // loading={applyPromoLoading}
                    className="ml-3 mt-auto"
                    type="button"
                  >
                    Apply
                  </Button>
                )}
              </div>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item sm={7} xs={12}>
        {confirmed && paymentType === PAYMENT_TYPE_WALLET && (
          <B2BWallet
            companyId={agencyId}
            price={totalPrice}
            formik={b2bFormik}
            loading={loading}
            isIssueAdmin={isIssueAdmin}
            noCredit={noCredit}
          />
        )}
        {confirmed && paymentType === PAYMENT_TYPE_CARD && (
          <StripePayment
            loading={loading}
            formik={cardFormik}
            stripePaymentBody={stripePaymentBody}
            total={totalPrice}
          />
        )}
        {confirmed && paymentType === PAYMENT_TYPE_WAAFI && (
          <EWalletPayment
            formik={waafiFormik}
            loading={loading}
            title="WAAFI"
            description={
              <Typography className="font-weight-bold font-italic px-4 text-danger">
                Make sure you have your mobile within your reach. You will
                receive an sms to confirm the payment.
                <br />
                You can make this payment using EVC, Zaad and Sahal without
                country code
              </Typography>
            }
          />
        )}
        {confirmed && paymentType === PAYMENT_TYPE_PREMIERE && (
          <EWalletPayment
            formik={premiereFormik}
            loading={loading}
            title="Premiere Wallet"
            description={
              <Typography className="font-weight-bold font-italic px-4 text-danger">
                Make sure you have your mobile within your reach. You will
                receive popup from your Premier Wallet app to confirm the
                payment.
                <br />
                Please make sure to accept the payment,
                <br />
                Use your mobile Premier Wallet number without country code below
              </Typography>
            }
          />
        )}
        {confirmed && paymentType === PAYMENT_TYPE_MASTERCARD && (
          <MasterCardPayment amount={totalPrice} onSubmit={onSubmit} />
        )}
        {/* {confirmed && paymentType === PAYMENT_TYPE_QR && <QRCode />} */}
        {!confirmed && (
          <Paper>
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={300}
            />
          </Paper>
        )}
      </Grid>
      <Grid item sm={5} xs={12}>
        {confirmed ? (
          <Paper className={classes.paper}>
            {paymentFor === paymentForEnums.booking && (
              <OrderPriceCard isBookingDetails data={bookingDetails} />
            )}
            {paymentFor === paymentForEnums.subscription && (
              <SubscriptionPriceCard
                total={subscriptionDetails?.total}
                subtotal={subscriptionDetails?.subtotal}
                transactionFee={subscriptionDetails?.transactionFee}
                discount={discount}
              />
            )}
          </Paper>
        ) : (
          <Paper>
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={300}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
