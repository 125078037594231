import {
  InputBase,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../../../app/routesMap";
import styles from "./TextField.module.scss";

export const SecondaryInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F3F6F9",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export const TextFieldContainer = ({
  label,
  disabled,
  value,
  onChange,
  name,
  placeholder,
  required,
  multiline,
  vertical,
  showErrors,
  errorMessage,
  inputProps,
  variant = "primary",
  optional,
  type,
  forgotPassword,
  noErrorMessage,
  labelClassName,
  fixedLabelWidth,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const containeClassNames = clsx("w-100 max-w-500px d-flex", {
    "align-items-start ": !vertical,
    "flex-column align-items-start": vertical || !matchesSm,
  });
  const getInputClasses = () => {
    if (showErrors && errorMessage) {
      return "is-invalid";
    }

    if (showErrors && !errorMessage) {
      return "is-valid";
    }

    return "";
  };
  const inputVariants = {
    primary: InputBase,
    secondary: SecondaryInput,
  };
  const Input = inputVariants[variant];
  const inputClassNames = clsx(
    "border rounded flex-grow-1 w-100",
    getInputClasses(),
    {
      "bg-white p-2": variant === "primary",
      "h-40px": !multiline,
    }
  );
  return (
    <div className={containeClassNames}>
      <div
        className={clsx("text-nowrap d-flex justify-content-between", {
          "mb-3": vertical || !matchesSm,
          "mt-3": !vertical,
          "min-w-150px": fixedLabelWidth,
        })}
      >
        {label && (
          <span className="w-100" style={{ whiteSpace: "pre-wrap" }}>
            {required && <span className="text-danger">*</span>}
            {label}
            {optional && <span className="text-muted">(optional)</span>}
          </span>
        )}
        {forgotPassword && (
          <NavLink
            to={FORGOT_PASSWORD}
            className="text-primary font-weight-bold"
          >
            Forgot Password?
          </NavLink>
        )}
      </div>
      <div className="w-100">
        {!multiline ? (
          <Input
            placeholder={placeholder || `Add ${label}`}
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
            multiline={multiline}
            type={type}
            {...inputProps}
            className={inputClassNames}
          />
        ) : (
          <textarea
            placeholder={placeholder || `Add ${label}`}
            className={clsx(inputClassNames, styles.textArea)}
            onChange={onChange}
            value={value}
            name={name}
            {...inputProps}
            rows="4"
          />
        )}

        {!noErrorMessage && (
          <div className="fv-plugins-message-container h-20px ">
            <div className="fv-help-block">{showErrors && errorMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
};
