import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/user";

export default function useRoleOptions() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ user }) => user.allRoles);
  useEffect(() => {
    if (!list) {
      dispatch(actions.getAllRoles());
    }
  }, [dispatch, list]);
  const customizedRole = { name: "Custom", id: 0 };
  const roleOptions = useMemo(() => {
    if (list?.length) {
      return [customizedRole, ...list].map(({ name, id }) => ({
        value: id,
        label: name,
      }));
    }
  }, [list, customizedRole]);
  return { roleOptions, loading };
}
