import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { SIGNUP_COMPANY_DETAILS, SIGN_IN } from "../../../../app/routesMap";
import { actions } from "../../../../redux/state/company";
import {
  ORGANIZATION_TYPE_AGENCY,
  ORGANIZATION_TYPE_SUBSCRIBER,
  companyTypeMap,
} from "../../../../redux/state/company/companyConstants";
import { toAbsoluteUrl } from "../../../_helpers";

import { LeftBar } from "./LeftBar";

export const SelectUserType = ({ routesMap }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <LeftBar key="select-user" routesMap={routesMap} havingIssues>
      <div className="mb-6">
        <Typography className="font-weight-bold mb-1" variant="h6">
          Select Account Type
        </Typography>
        <Typography variant="body1" className="text-muted" noWrap>
          Already have an Account?{" "}
          <NavLink className="text-primary text-weight-bold" to={SIGN_IN}>
            Sign In
          </NavLink>
        </Typography>
      </div>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <Paper className="max-w-300px mx-auto">
            <button
              className="btn w-100 m-0 border-2 border-primary text-primary btn-hover-primary"
              variant="primary-inverse"
              onClick={() => {
                history.push(SIGNUP_COMPANY_DETAILS);
                dispatch(
                  actions.setRegisterCompanyType({
                    type: ORGANIZATION_TYPE_SUBSCRIBER,
                  })
                );
              }}
            >
              <span className="svg-icon svg-icon-xxl-5x m-0 svg-icon-primary mb-7 d-block">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/person.svg")}
                />
              </span>
              <Typography variant="body1">Register as</Typography>
              <Typography className="font-weight-bold" variant="h4">
                Subscriber
              </Typography>
            </button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className="max-w-300px mx-auto">
            <button
              className="btn w-100 py-5 border-2 border-primary text-primary btn-hover-primary"
              variant="primary-inverse"
              onClick={() => {
                history.push(SIGNUP_COMPANY_DETAILS);
                dispatch(
                  actions.setRegisterCompanyType({
                    type: ORGANIZATION_TYPE_AGENCY,
                  })
                );
              }}
            >
              <span className="svg-icon svg-icon-xxl-5x svg-icon-primary mb-7 d-block">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/building.svg")}
                />
              </span>
              <Typography variant="body1">Register as</Typography>
              <Typography className="font-weight-bold" variant="h4">
                B2B agency
              </Typography>
            </button>
          </Paper>
        </Grid>
      </Grid>
    </LeftBar>
  );
};
