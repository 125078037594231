import * as React from "react";
import { AboutContentCard } from "./AboutContentCard";

export const WorkWithUsSection = ({ data }) => {
  const { title, cards } = data;
  return (
    <>
      <div className="div">
        <div className="div-2">{title}</div>
        <div className="div-3">
          <div className="div-4">
            {cards.map((card, index) => (
              <AboutContentCard
                key={index}
                title={card.title}
                content={card.description}
              />
            ))}
            {/* <AboutContentCard
              title="Expertise in Flight Solutions"
              content="Benefit from our extensive experience, financial security, and industry knowledge for top-tier flight options. Our dedicated team ensures 24/7 personalized service, offering meticulous attention to detail, guaranteeing a tailored experience."
            />
            <AboutContentCard
              title="Commitment to Innovation"
              content="We invest significantly in cutting-edge technology and our team, ensuring FlyMe247 remains your ultimate choice. Whether it's for business or leisure, trust us for the most competitive flight prices. Contact us at short number 3636 for local Somalia assistance or +442080049377 for our London office."
            />
            <AboutContentCard
              title="Personalized Care, Global Reach"
              content="With FlyMe247, expect personalized care no matter your destination or travel purpose. Experience a seamless journey and access competitive quotes by reaching out to our dedicated team, available round the clock."
            /> */}
          </div>
        </div>
      </div>
      <style jsx>{`
        .div {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
        }
        .div-2 {
          color: #222;
          width: 100%;
          font: 700 48px/87px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
            font-size: 40px;
            line-height: 81px;
          }
        }
        .div-3 {
          width: 100%;
        }
        @media (max-width: 991px) {
          .div-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-4 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-4 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
      `}</style>{" "}
    </>
  );
};
