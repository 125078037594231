import { useCallback } from "react";
import usePackageClassesList from "./usePackageClassesList";

export default function usePkgClassName() {
  const { list, loading, success, failure } = usePackageClassesList();

  const getPkgClassName = useCallback(
    (pkgClassId) => list?.find(({ id }) => pkgClassId === id)?.name ?? "",
    [list]
  );
  return { getPkgClassName, loading, success, failure };
}
