import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, Select } from "../../../common";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const CreateExpense = () => {
  const proofSchema = Yup.object().shape({
    bank: Yup.number().required("Bank is required"),
    paymentMethod: Yup.number().required("Payment method is required"),
    // bankAccount: Yup.string().required("Bank Account is required"),
    paymentAmount: Yup.number().required("Amount is required"),
    currency: Yup.string().required("Currency is required"),
    paymentProof: Yup.mixed().required("Proof upload payment proof"),
    remarks: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      paymentMethod: "",
      bank: "",
      bankAccount: "",
      paymentAmount: "",
      currency: "",
      paymentProof: "",
      remarks: "",
    },
    validationSchema: proofSchema,
    onSubmit: ({ paymentProof }) => {
      const formData = new FormData();
      formData.append("file", paymentProof, paymentProof.name);
      // dispatch(filesActions.uploadFile({ userId, formData }));
    },
  });
  const {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    resetForm,
  } = formik;
  const { paymentProof } = values;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Select variant="secondary" label="Payee Name" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select variant="secondary" label="Payment Account" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select variant="secondary" label="Payment Method" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input variant="secondary" type="date" label="Date" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input variant="secondary" label="Ref. No." />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex align-items-center mb-3">
            <span className="svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")} />
            </span>
            <Typography variant="h6" className="font-weight-bold ml-2">
              Memo
            </Typography>
          </div>
          <Input multiline variant="secondary" placeholder="Memo" />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex align-items-center mb-3">
            <span className="svg-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/blue-pin.svg")}
              />
            </span>
            <Typography variant="h6" className="font-weight-bold ml-2">
              Attachments
            </Typography>
          </div>
          {/* <div className="d-flex my-1 align-items-center">
            <label className="btn btn-primary w-350px ">
              + Upload Files
              <input
                onChange={(e) =>
                  setFieldValue("paymentProof", e.target.files[0])
                }
                type="file"
                accept="image/* application/pdf"
                hidden
              />
            </label>
            <span className="ml-5 text-muted">
              (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
            </span>
          </div> */}
          <div className="d-flex mb-6">
            <span className="min-w-150px"></span>
            {touched.paymentProof && (
              <div className="fv-plugins-message-container h-20px max-w-100">
                <div className="fv-help-block">{errors.paymentProof}</div>
              </div>
            )}
            {paymentProof && (
              <div className="w-350px">
                <div className="d-flex w-100 mb-1 align-items-center">
                  {/* <SVG src="/media/svg/icons/General/valid.svg" /> */}
                  <Typography className="ml-3" variant="body1">
                    {paymentProof?.name}
                  </Typography>
                  <button
                    type="button"
                    onClick={() => setFieldValue("paymentProof", "")}
                    className="btn btn-icon ml-auto"
                  >
                    <SVG src="/media/svg/icons/General/cancel.svg" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
