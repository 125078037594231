import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash-es";
import { useParams } from "react-router-dom";
import {
  ADMIN_CLIENT_BILLING,
  ADMIN_CLIENT_SECURITY,
  ADMIN_SESSIONS,
} from "../../../../../../../app/routesMapAdmin";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { getDateTime } from "../../../../../../_helpers/date";
import { HashLink } from "react-router-hash-link";
import { actions as userActions } from "../../../../../../../redux/state/user";
import { actions as companyActions } from "../../../../../../../redux/state/company";

const LoginSessionOk = "OK";
const LoginSessionWarning = "WRN";
const LoginSessionERROR = "ERR";

const loginSessionBadgeClassNames = {
  [LoginSessionOk]: "label-light-success",
  [LoginSessionWarning]: "label-light-warning",
  [LoginSessionERROR]: "label-light-danger",
};
const loginSessionBadgeText = {
  [LoginSessionOk]: LoginSessionOk,
  [LoginSessionWarning]: LoginSessionWarning,
  [LoginSessionERROR]: LoginSessionERROR,
};

const TableRow = ({ data }) => {
  const { createdAt, location, deviceName, browser, ipAddress, status } = data;
  const { date, time } = getDateTime(createdAt);

  return (
    <tr>
      <td>
        <span className="font-weight-bold">{location || "-"}</span>
      </td>
      <td>
        <span className="text-dark-75 font-weight-bold d-block font-size-lg">
          {date}
        </span>
        <span className="text-muted font-weight-bold">{time}</span>
      </td>
      <td>
        <span className="font-weight-bold">{deviceName || browser || "-"}</span>
      </td>
      <td>
        <span className="font-weight-bold">{ipAddress || "-"}</span>
      </td>
      <td>
        <span
          className={`label label-lg ${loginSessionBadgeClassNames[status]} label-inline`}
        >
          {loginSessionBadgeText[status]}
        </span>
      </td>
    </tr>
  );
};

export const LoginSessionsTable = ({ showAll, isUser, id }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState();
  const { list: companySessions } = useSelector(
    ({ company }) => company.companySessions
  );
  const { list: userSessions } = useSelector(({ user }) => user.userSessions);
  useEffect(() => {
    if (!isNil(companySessions) || !isNil(userSessions)) {
      if (isUser) {
        setList(showAll ? userSessions : userSessions?.slice(0, 5));
      } else {
        setList(showAll ? companySessions : companySessions?.slice(0, 5));
      }
    }
  }, [companySessions, isUser, showAll, userSessions]);
  useEffect(() => {
    if (isUser) {
      dispatch(userActions.getUserSessions({ id }));
    } else {
      dispatch(companyActions.getCompanySessions({ id }));
    }
  }, [dispatch, id, isUser]);
  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  return (
    <Paper className="h-100 p-7">
      <div className="d-flex justify-content-between">
        <Typography variant="h6">Login Sessions</Typography>
        <HashLink
          to={parseRoute(`${ADMIN_SESSIONS}`, { id, type: isUser ? 0 : 1 })}
          className="btn btn-primary"
        >
          View All
        </HashLink>
      </div>
      <div className="table-responsive">
        <table className="table table-vertical-center mb-0 mt-5">
          <thead>
            <tr className="bg-gray-100">
              <th>Location</th>
              <th>Time</th>
              <th>remark</th>
              <th>IP Address</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
        {list?.length === 0 && (
          <Typography
            variant="h5"
            className="text-center w-100 text-muted my-5"
          >
            No Records to show
          </Typography>
        )}
      </div>
    </Paper>
  );
};
