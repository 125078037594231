import React from "react";
import { getDateTime } from "../../../../../../_helpers";

export const FEE_TYPE_REISSUE = 1;
export const FEE_TYPE_REFUND = 2;
export const FEE_TYPE_VOID = 3;
export const FEE_TYPE_SUBSCRIPTION = 4;

export const feeTypes = {
  [FEE_TYPE_REISSUE]: "ReIssue",
  [FEE_TYPE_REFUND]: "Refund",
  [FEE_TYPE_VOID]: "Void",
  [FEE_TYPE_SUBSCRIPTION]: "Subscription",
};

export const FeeLogsRow = ({ getUserName, data }) => {
  const { amount, createdAt, createdBy, isPercentage } = data;
  const { time, date } = getDateTime(createdAt);
  const userName = getUserName(createdBy);

  return (
    <tr>
      <td className="text-left">{userName || "-"}</td>
      <td>{`${amount}${isPercentage ? "%" : "$"}`}</td>
      <td>{`${date} ${time}`}</td>

      {/* <td className="text-right">
        <button
          hidden={!subPermissions.includes(allServiceFeeSubPermission.edit.id)}
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button> */}
      {/* <button
          title=""
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </td>
         */}
    </tr>
  );
};
