import {
  Grid,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { PersonalForm } from "./PersonalForm";
import { InfoBar } from "../common/InfoBar";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { DeleteUser } from "./DeleteUser";

export const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3798D3",
  },
})(Tabs);

export const AccountDetails = () => {
  const [selectedTab, setSelectedTab] = useState("personal");
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid container spacing={3}>
      {!matchesSm && (
        <Grid item container xs={12}>
          <InfoBar />
        </Grid>
      )}
      <Grid item xs={12} sm={7}>
        <Paper className="bg-white h-100">
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
          >
            <Tab value="personal" label="Personal" />
            {/* <Tab value="company" label="Company" /> */}
            <Tab value="password" label="Change password" />
            <Tab value="delete-account" label="Delete Account" />
          </AntTabs>
          <div className="p-7">
            {selectedTab === "personal" && <PersonalForm />}
            {/* {selectedTab === "company" && <CompanyForm />} */}
            {selectedTab === "password" && <ChangePasswordForm />}
            {selectedTab === "delete-account" && <DeleteUser />}
          </div>
        </Paper>
      </Grid>
      {matchesSm && (
        <Grid item container sm={5}>
          <InfoBar />
        </Grid>
      )}
    </Grid>
  );
};
