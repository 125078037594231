import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { TERMS_AND_CONDITIONS } from "../../../../../app/routesMap";
import { Button } from "../";

export const ConfirmButton = ({ loading, onClick }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  return (
    <div className="w-100 flex-grow-1">
      <FormControlLabel
        control={
          <Checkbox
            // {...getFieldProps("isFlexi")}
            // checked={state.checkedB}
            // onChange={handleChange}
            value={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
            color="primary"
          />
        }
        label={
          <Typography variant="caption">
            I have read and accept{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={TERMS_AND_CONDITIONS}
            >
              terms and conditions
            </a>
          </Typography>
        }
      />
      <Button
        loading={loading}
        type="submit"
        variant="primary"
        className="w-100 flex-grow-1 mt-3"
        disabled={!acceptedTerms}
        onClick={onClick}
      >
        Confirm & Pay Now
      </Button>
    </div>
  );
};
