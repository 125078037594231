import moment from "moment";

export const convertMinToHrs = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let time = "";
  time += hours > 0 ? `${hours} hr ` : "";
  time += minutes > 0 ? `${minutes} min` : "";
  return time;
};

export const formatTo12Hrs = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "AM" : "PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const calcTimeDiff = (startDate, endDate, startTime, endTime) => {
  const startDateTime = moment(`${startDate} ${startTime}`);
  const endDateTime = moment(`${endDate} ${endTime}`);
  var duration = moment.duration(endDateTime.diff(startDateTime));
  const minutes = duration.asMinutes();

  return Math.floor(minutes / 60) + " hr " + (minutes % 60) + " min";
};
