import { client } from "./client";

export const filterSuppliers = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post("api/supplierManagement/supplier/filters", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [{}],
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 10,
    groupingColumns: [],
  });

export const getAllSuppliers = () => client.get("api/supplierManagement");

export const getSupplier = ({ id }) =>
  client.get(`api/supplierManagement/${id}`);

export const createSupplier = (params) =>
  client.post("api/supplierManagement", params);

export const editSupplier = ({ id, ...rest }) =>
  client.put(`api/supplierManagement/${id}`, { ...rest });

export const deleteSupplier = ({ id }) =>
  client.delete(`api/supplierManagement/${id}`);

export const createSupplierBalance = ({
  supplierInSupplierBalanceId,
  availableBalance,
  availableCredit,
  balanceAlert,
}) =>
  client.post("api/supplierBalance", {
    supplierInSupplierBalanceId,
    availableBalance,
    availableCredit,
    balanceAlert,
  });

export const editSupplierBalance = ({ id, ...rest }) =>
  client.put(`api/supplierBalance/${id}`, { ...rest });

export const addToSupplierBalance = ({ id, ...rest }) =>
  client.put(`api/supplierBalance/${id}/addAmount`, { ...rest });

export const deductFromSupplierBalance = ({ id, ...rest }) =>
  client.put(`api/supplierBalance/${id}/deductamount`, { ...rest });

export const getSupplierBalance = ({ id }) =>
  client.get(`api/supplierBalance/${id}`);

export const getSupplierBalanceHistory = ({ supplierId }) =>
  client.get(`api/supplierBalance/${supplierId}/gethistory`);

export const getSupplierBalanceBySupplierId = ({ id }) =>
  client.get(`api/supplierBalance/supplier/${id}`);

export const createSupplierApi = ({
  supplierManagementInAPIManagementId,
  apiType,
  apiKey,
  secretKey,
  isActive,
}) =>
  client.post("api/apiManagement", {
    supplierManagementInAPIManagementId,
    apiType,
    apiKey,
    secretKey,
    isActive,
  });

export const editSupplierApi = ({ id, ...rest }) =>
  client.put(`api/apiManagement/${id}`, { id, ...rest });

export const getSupplierApi = ({ id }) => client.get(`api/apiManagement/${id}`);

export const getSupplierRefundTopUps = () =>
  client.get("api/supplierBalance/suppliertopup");

export const filterSupplierRefundTopUps = ({
  sortingParams,
  filterParam,
  pageNumber,
  pageSize,
}) =>
  client.post("api/supplierBalance/suppliertopup/filters", {
    sortingParams: sortingParams || [
      {
        sortOrder: 2,
        columnName: "createdAt",
      },
    ],
    filterParam: filterParam || [],
    pageNumber,
    pageSize,
  });

export const editSupplierTopUp = ({ id, ...rest }) =>
  client.put(`api/supplierBalance/suppliertopup?id=${id}`, { id, ...rest });

export const getSupplierApiBySupplierId = ({ supplierId }) =>
  client.get(`api/apiManagement/supplier/${supplierId}`);
