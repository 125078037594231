import React from "react";
import { Route, Switch } from "react-router-dom";
import { CreateStaffMember } from "../../../_metronic/layout/components/admin/systemManagement/staff/create/CreateStaffMember";
import { Staff } from "../../../_metronic/layout/components/admin/systemManagement/staff/Staff";
import { StaffMember } from "../../../_metronic/layout/components/admin/systemManagement/staff/staffMember/StaffMember";
import {
  ADMIN_STAFF,
  ADMIN_STAFF_CREATE,
  ADMIN_STAFF_MEMBER,
} from "../../routesMapAdmin";
import { ContentRoute } from "../../../_metronic/layout/components/content/ContentRoute";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";

export const StaffPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.viewUser",
        ])}
        path={ADMIN_STAFF_MEMBER}
        component={StaffMember}
      />
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.addUser",
        ])}
        path={ADMIN_STAFF_CREATE}
        component={CreateStaffMember}
      />
      <Route
        exact
        path={ADMIN_STAFF}
        render={(props) => <Staff {...props} permissionId={permissionId} />}
      />
    </Switch>
  );
};
