import React, { useState } from "react";
import { Grid, Paper, Typography, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";

import { AntTabs } from "../account/accountDetails/AccountDetails";
import { ViewMarkup } from "./ViewMarkup";
import { CreateMarkup } from "./CreateMarkup";
import { adminPermissionsIds } from "../../../../data/adminPermissionIds";
import useSubPermissions from "../../../../app/helpers/hooks/useSubPermissions";

export const DistributionMarkup = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const [selectedTab, setSelectedTab] = useState("set");
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const allMarkupSubPermission =
    adminPermissionsIds.systemManagement.markup.subPermissions;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4 d-flex justify-content-between align-items-center">
          <Typography variant="h5" className="font-weight-bold">
            Markup
          </Typography>
          {/* <div className="d-flex">
            <Button variant="secondary" type="button">
              Cancel
            </Button>
            <Button
              // onClick={formFields.handleSubmit}
              className="ml-7 "
              type="submit"
              loading={loading}
            >
              Save
            </Button>
          </div> */}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="bg-white h-100">
          <AntTabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            indicatorColor="primary"
          >
            <Tab
              hidden={
                isAdmin &&
                !subPermissions.includes(allMarkupSubPermission.setMarkup.id)
              }
              value="set"
              label="Set Markup"
            />
            <Tab
              hidden={
                isAdmin &&
                !subPermissions.includes(
                  allMarkupSubPermission.viewFlymeMarkup.id
                )
              }
              value="view"
              label="View Company Markups"
            />
            {isAdmin && (
              <Tab
                hidden={
                  !subPermissions.includes(
                    allMarkupSubPermission.viewAllMarkups.id
                  )
                }
                value="viewAll"
                label="View All Markups"
              />
            )}
          </AntTabs>
        </Paper>
      </Grid>
      <>
        {selectedTab === "set" && (
          <Grid
            hidden={
              isAdmin &&
              !subPermissions.includes(allMarkupSubPermission.setMarkup.id)
            }
            item
            xs={12}
          >
            <CreateMarkup />
          </Grid>
        )}

        {selectedTab === "view" && (
          <Grid
            hidden={
              isAdmin &&
              !subPermissions.includes(
                allMarkupSubPermission.viewFlymeMarkup.id
              )
            }
            item
            xs={12}
          >
            <ViewMarkup permissionId={permissionId} />
          </Grid>
        )}

        {selectedTab === "viewAll" && (
          <Grid
            hidden={
              !subPermissions.includes(allMarkupSubPermission.viewAllMarkups.id)
            }
            item
            xs={12}
          >
            <ViewMarkup permissionId={permissionId} isAll />
          </Grid>
        )}
      </>
    </Grid>
  );
};
