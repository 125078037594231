import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../../common";
import { actions } from "../../../../../../redux/state/user";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";

import { RoleForm } from "./RoleForm";
import { RoleRow } from "./RoleRow";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { ConfirmationModal } from "../../../bookingManagement/common/ConfirmationModal";
import { useCallback } from "react";
import useSubPermissions from "../../../../../../app/helpers/hooks/useSubPermissions";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";

const roleSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export const Roles = ({ permissionId }) => {
  const subPermissions = useSubPermissions(permissionId);
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { list, loading } = useSelector(({ user }) => user.allRoles);

  const {
    loading: deleteLoading,
    success: deleteSuccess,
    failure: deleteFailure,
  } = useSelector(({ user }) => user.deleteRole);
  const {
    loading: createLoading,
    success: createSuccess,
    failure: createFailure,
  } = useSelector(({ user }) => user.createRole);
  const {
    success: editSuccess,
    loading: editLoading,
    failure: editFailure,
  } = useSelector(({ user }) => user.editRole);
  const fields = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: roleSchema,
    onSubmit: ({ name }) => {
      if (!!selectedId) {
        const data = getRole(selectedId);
        dispatch(
          actions.editRole({
            ...data,
            name,
          })
        );
      } else {
        dispatch(
          actions.createRole({
            name,
          })
        );
      }
    },
  });
  useEffect(() => {
    dispatch(actions.getAllRoles());
  }, [dispatch]);
  const { resetForm } = fields;
  const hideFormModal = useCallback(() => {
    setShowForm(false);
    dispatch(actions.clearCreateRole());
    dispatch(actions.clearEditRole());
    setSelectedId(undefined);
    resetForm();
  }, [dispatch, resetForm]);
  useEffect(() => {
    if (createSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Role Added",
        })
      );
      dispatch(actions.getAllRoles());
      hideFormModal();
    }
  }, [createSuccess, dispatch, hideFormModal]);
  useEffect(() => {
    if (createFailure || editFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.clearCreateRole());
      dispatch(actions.clearEditRole());
    }
  }, [createFailure, dispatch, editFailure]);
  useEffect(() => {
    if (editSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Role Edited",
        })
      );
      dispatch(actions.getAllRoles());
      hideFormModal();
    }
  }, [editSuccess, dispatch, hideFormModal]);
  const hideDeleteModal = useCallback(() => {
    setShowDelete(false);
    dispatch(actions.clearDeleteRole());
    setSelectedId(undefined);
  }, [dispatch]);
  const onDelete = (id) => {
    setShowDelete(true);
    setSelectedId(id);
  };
  const onConfirmDelete = () => {
    dispatch(actions.deleteRole({ id: selectedId }));
  };
  const onDeleteSuccess = () => {
    dispatch(actions.clearDeleteRole());
    dispatch(actions.getAllRoles());
  };
  const onDeleteFailure = () => {
    dispatch(
      inlineNotificationActions.setInlineNotification({
        type: NOTIFICATION_TYPE_ERROR,
        message: "Something is wrong!",
      })
    );
    dispatch(actions.clearDeleteRole());
  };
  const onEdit = (id) => {
    setSelectedId(id);
    const { name } = getRole(id);
    fields.setValues({
      name,
    });
    onShowForm();
  };
  const getRole = useCallback(
    (id) => list?.filter((item) => item.id === id)[0],
    [list]
  );
  const allRoleSubPermission =
    adminPermissionsIds.systemManagement.flymeStaffRoles.subPermissions;
  const tableContent = list?.map((item, index) => (
    <RoleRow
      key={index}
      data={item}
      subPermissions={subPermissions}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  ));
  const onShowForm = () => {
    setShowForm(true);
  };
  return (
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <Paper className="p-7 bg-white">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">Staff Roles</Typography>
            <button
              hidden={!subPermissions.includes(allRoleSubPermission.addRole.id)}
              onClick={onShowForm}
              className="btn btn-danger"
            >
              Add Role
            </button>
          </div>
          <ConfirmationModal
            onHide={hideDeleteModal}
            show={showDelete}
            onConfirm={onConfirmDelete}
            title="Delete Role"
            body="Are you sure you want to delete this Role?"
            loading={deleteLoading}
            success={deleteSuccess}
            failure={deleteFailure}
            onFailure={onDeleteFailure}
            onSuccess={onDeleteSuccess}
          />
          <RoleForm
            onHide={hideFormModal}
            show={showForm}
            loading={createLoading || editLoading}
            fields={fields}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive mt-5">
                <table className="table table-vertical-center text-center font-weight-bold">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-left">Role Name</th>
                      <th>Created Date</th>
                      <th className="min-w-160px text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
              </div>
              {list?.length === 0 && (
                <Typography
                  variant="h5"
                  className="text-center w-100 text-muted my-5"
                >
                  No Roles to show
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
