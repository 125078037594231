import React from "react";
import { Typography } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ModalProgressBar } from "../../../_partials/controls";
import { Button } from "../common";

export const REPRICE_ERROR_DEFAULT = "default";
export const REPRICE_ERROR_PRICE = "price";
export const REPRICE_ERROR_NO_HOLD = "noHold";

const errorTitleMessagesMap = {
  [REPRICE_ERROR_DEFAULT]: "Fares Changed",
  [REPRICE_ERROR_PRICE]: "Fares Changed",
  [REPRICE_ERROR_NO_HOLD]: "Book and Hold not available",
};

const errorBodyMessageMap = {
  [REPRICE_ERROR_DEFAULT]: "Flight sold out or Fares has changed",
  [REPRICE_ERROR_PRICE]: "Fares Has Changed",
  [REPRICE_ERROR_NO_HOLD]: "Book and Hold is not available for this flight",
};

const errorFooterMap = {
  [REPRICE_ERROR_DEFAULT]: "Please select another flight",
  [REPRICE_ERROR_PRICE]:
    "Please select another flight or continue with the new price",
  [REPRICE_ERROR_NO_HOLD]:
    "Please select another flight or continue with the Book and Issue",
};

export const RepriceModal = ({
  errorType,
  show,
  onHide,
  onGoBack,
  errorMessage,
  onContinue,
  oldPrice,
  newPrice,
}) => {
  const { loading } = useSelector(({ booking }) => booking.bookFlight);
  return (
    <Modal backdrop="static" show={show} onHide={onHide}>
      {loading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {errorTitleMessagesMap[errorType]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Typography>{errorBodyMessageMap[errorType]}</Typography>
        {/* {errorType === REPRICE_ERROR_DEFAULT && ( */}
        {/* <Typography className="text-danger mb-3">{errorMessage}</Typography> */}
        {/* )} */}
        {errorType === REPRICE_ERROR_PRICE && (
          <>
            <Typography className="mb-3">
              Old price: <b>{oldPrice}</b>
            </Typography>
            <Typography>
              New price: <b>{newPrice}</b>
            </Typography>
          </>
        )}
        <Typography className="font-weight-bold">
          {errorFooterMap[errorType]}
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <Button
            variant={errorType === REPRICE_ERROR_DEFAULT ? "primary" : "light"}
            smallPadding
            type="button"
            onClick={onGoBack}
          >
            Go beck to search
          </Button>
          {errorType !== REPRICE_ERROR_DEFAULT && (
            <Button smallPadding type="button" onClick={onContinue}>
              Continue
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
