import { requestActions } from "../common/actionsCreator";
import { requestApiCall } from "../connectivity/connectivityAction";
import {
  MODULE_NAME,
  RefundApplied,
  RefundWithdraw,
} from "./bookingMngConstants";

export const RESET = `${MODULE_NAME}/RESET`;
export const GET_SPECIFIC_ORDER = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_ORDER"
);
export const GET_SPECIFIC_ORDER_BY_BOOKING_ID = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_ORDER_BY_BOOKING_ID"
);
export const GET_ORDERS = requestActions(MODULE_NAME, "GET_ORDERS");
export const GET_ALL_ORDERS = requestActions(MODULE_NAME, "GET_ALL_ORDERS");
export const GET_USER_ORDERS = requestActions(MODULE_NAME, "GET_USER_ORDERS");
export const GET_REISSUE = requestActions(MODULE_NAME, "GET_REISSUE");
export const GET_REFUNDS = requestActions(MODULE_NAME, "GET_REFUNDS");
export const GET_ALL_REISSUE = requestActions(MODULE_NAME, "GET_ALL_REISSUE");
export const GET_ALL_REFUNDS = requestActions(MODULE_NAME, "GET_ALL_REFUNDS");
export const GET_ALL_VOIDS = requestActions(MODULE_NAME, "GET_ALL_VOIDS");
export const GET_VOIDS = requestActions(MODULE_NAME, "GET_VOIDS");
export const FILTER_ORDERS = requestActions(MODULE_NAME, "FILTER_ORDERS");
export const FILTER_ALL_ORDERS = requestActions(
  MODULE_NAME,
  "FILTER_ALL_ORDERS"
);
export const SEARCH_ALL_ORDERS = requestActions(
  MODULE_NAME,
  "SEARCH_ALL_ORDERS"
);
export const FILTER_REISSUE = requestActions(MODULE_NAME, "FILTER_REISSUE");
export const FILTER_REFUNDS = requestActions(MODULE_NAME, "FILTER_REFUNDS");
export const FILTER_ALL_VOID = requestActions(MODULE_NAME, "FILTER_ALL_VOID");
export const FILTER_VOID = requestActions(MODULE_NAME, "FILTER_VOID");
export const REQUEST_VOID = requestActions(MODULE_NAME, "REQUEST_VOID");
export const ACCEPT_VOID = requestActions(MODULE_NAME, "ACCEPT_VOID");
export const ACCEPT_VOID_MANUALLY = requestActions(
  MODULE_NAME,
  "ACCEPT_VOID_MANUALLY"
);
export const REJECT_VOID = requestActions(MODULE_NAME, "REJECT_VOID");
export const REQUEST_REFUND = requestActions(MODULE_NAME, "REQUEST_REFUND");
export const ACCEPT_REFUND = requestActions(MODULE_NAME, "ACCEPT_REFUND");
export const REJECT_REFUND = requestActions(MODULE_NAME, "REJECT_REFUND");
export const REQUEST_REISSUE = requestActions(MODULE_NAME, "REQUEST_REISSUE");
export const ACCEPT_REISSUE = requestActions(MODULE_NAME, "ACCEPT_REISSUE");
export const REJECT_REISSUE = requestActions(MODULE_NAME, "REJECT_REISSUE");
export const REJECT_REISSUE_OFFER = requestActions(
  MODULE_NAME,
  "REJECT_REISSUE_OFFER"
);
export const REFUND_REISSUE = requestActions(MODULE_NAME, "REFUND_REISSUE");
export const SEND_REFUND_EMAIL = requestActions(
  MODULE_NAME,
  "SEND_REFUND_EMAIL"
);
export const SEND_VOID_EMAIL = requestActions(MODULE_NAME, "SEND_VOID_EMAIL");
export const SEND_REISSUE_EMAIL = requestActions(
  MODULE_NAME,
  "SEND_REISSUE_EMAIL"
);
export const GET_SPECIFIC_VOID = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_VOID"
);
export const GET_SPECIFIC_REFUND = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_REFUND"
);
export const GET_SPECIFIC_REISSUE = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_REISSUE"
);

export const GET_BOOKING_STATS = requestActions(
  MODULE_NAME,
  "GET_BOOKING_STATS"
);
export const GET_ALL_BOOKING_STATS = requestActions(
  MODULE_NAME,
  "GET_ALL_BOOKING_STATS"
);
export const UPDATE_BOOKING_TICKETS = requestActions(
  MODULE_NAME,
  "UPDATE_BOOKING_TICKETS"
);
export const GET_SPECIFIC_VOID_BY_GUID = requestActions(
  MODULE_NAME,
  "GET_SPECIFIC_VOID_BY_GUID"
);
export const CREATE_MASTERCARD_INTENT = requestActions(
  MODULE_NAME,
  "CREATE_MASTERCARD_INTENT"
);
export const GET_FEE_BY_TYPE = requestActions(MODULE_NAME, "GET_FEE_BY_TYPE");
export const GET_ALL_FEES = requestActions(MODULE_NAME, "GET_ALL_FEES");
export const EDIT_FEE = requestActions(MODULE_NAME, "EDIT_FEE");
export const CREATE_FEE = requestActions(MODULE_NAME, "CREATE_FEE");
export const CREATE_BOOKING_NOTE = requestActions(
  MODULE_NAME,
  "CREATE_BOOKING_NOTE"
);
export const GET_BOOKING_NOTES = requestActions(
  MODULE_NAME,
  "GET_BOOKING_NOTES"
);
export const UPDATE_BOOKING_STATUS = requestActions(
  MODULE_NAME,
  "UPDATE_BOOKING_STATUS"
);
export const VOID_WITH_NO_REFUND = requestActions(
  MODULE_NAME,
  "VOID_WITH_NO_REFUND"
);
export const ADD_DISCOUNT = requestActions(MODULE_NAME, "ADD_DISCOUNT");
export const EXPORT_ORDERS = requestActions(MODULE_NAME, "EXPORT_ORDERS");
export const FILTER_BOOKINGS = requestActions(MODULE_NAME, "FILTER_BOOKINGS");
export const CANCEL_PROCESSING_BOOKING = requestActions(
  MODULE_NAME,
  "CANCEL_PROCESSING_BOOKING"
);
export const UPDATE_REFUND_STATUS = requestActions(
  MODULE_NAME,
  "UPDATE_REFUND_STATUS"
);
export const PAY_SUBSCRIPTION = requestActions(MODULE_NAME, "PAY_SUBSCRIPTION");
export const GET_FEE_LOGS = requestActions(MODULE_NAME, "GET_FEE_LOGS");
export const CREATE_PAYMENT_INTENT = requestActions(
  MODULE_NAME,
  "CREATE_PAYMENT_INTENT"
);

export const createMasterCardIntent = (params) =>
  requestApiCall("createMasterCardIntent", params, CREATE_MASTERCARD_INTENT);

export const createPaymentIntent = (params) =>
  requestApiCall("createPaymentIntent", params, CREATE_PAYMENT_INTENT);

export const getFeeLogs = (params) =>
  requestApiCall("getFeeLogs", params, GET_FEE_LOGS);

export const paySubscription = (params) =>
  requestApiCall("paySubscription", params, PAY_SUBSCRIPTION);

export const cancelProcessingBooking = (params) =>
  requestApiCall("cancelProcessingBooking", params, CANCEL_PROCESSING_BOOKING);

export const filterOrdersPagination = ({
  sortingParams,
  filterParam,
  type,
  pageNumber = 1,
  pageSize = 10,
}) =>
  requestApiCall(
    "filterBookings",
    {
      sortingParams,
      filterParam,
      type,
      pageNumber,
      pageSize,
    },
    FILTER_BOOKINGS
  );

export const exportOrders = (params) =>
  requestApiCall("exportOrders", params, EXPORT_ORDERS);

export const addDiscount = (params) =>
  requestApiCall("addDiscount", params, ADD_DISCOUNT);

export const voidWithNoRefund = (params) =>
  requestApiCall("voidWithNoRefund", params, VOID_WITH_NO_REFUND);

export const updateBookingStatus = (params) =>
  requestApiCall("updateBookingStatus", params, UPDATE_BOOKING_STATUS);

export const createBookingNote = (params) =>
  requestApiCall("createBookingNote", params, CREATE_BOOKING_NOTE);

export const getBookingNotes = (params) =>
  requestApiCall("getBookingNotes", params, GET_BOOKING_NOTES);

export const getFeeByType = ({ type }) =>
  requestApiCall("getFeeByType", { type }, GET_FEE_BY_TYPE);

export const getAllFees = (params) =>
  requestApiCall("getAllFees", params, GET_ALL_FEES);

export const createFee = (params) =>
  requestApiCall("createFee", params, CREATE_FEE);

export const editFee = (params) => requestApiCall("editFee", params, EDIT_FEE);

export const updatePassengersTickets = (params) =>
  requestApiCall("updatePassengersTickets", params, UPDATE_BOOKING_TICKETS);

export const getSpecificReissue = ({ id }) =>
  requestApiCall("getSpecificReissue", { id }, GET_SPECIFIC_REISSUE);

export const getSpecificRefund = ({ id }) =>
  requestApiCall("getSpecificRefund", { id }, GET_SPECIFIC_REFUND);

export const getSpecificVoid = ({ id }) =>
  requestApiCall("getSpecificVoid", { id }, GET_SPECIFIC_VOID);

export const getSpecificVoidByGuid = ({ guid }) =>
  requestApiCall("getSpecificVoidByGuid", { guid }, GET_SPECIFIC_VOID_BY_GUID);

export const getBookingStatesForAgency = ({ companyId }) =>
  requestApiCall("getBookingStatesForAgency", { companyId }, GET_BOOKING_STATS);

export const getBookingStates = () =>
  requestApiCall("getBookingStates", {}, GET_ALL_BOOKING_STATS);

export const sendReissueEmail = (params) =>
  requestApiCall("sendReissueEmail", params, SEND_REISSUE_EMAIL);

export const sendVoidEmail = (params) =>
  requestApiCall("sendVoidEmail", params, SEND_REFUND_EMAIL);

export const sendRefundEmail = (params) =>
  requestApiCall("sendRefundEmail", params, SEND_VOID_EMAIL);

export const requestReissue = (params) =>
  requestApiCall("requestReissue", params, REQUEST_REISSUE);

export const acceptReissue = (params) =>
  requestApiCall("acceptReissue", params, ACCEPT_REISSUE);

export const rejectReissue = (params) =>
  requestApiCall("rejectReissue", params, REJECT_REISSUE);

export const rejectReissueOffer = (params) =>
  requestApiCall("rejectReissueOffer", params, REJECT_REISSUE_OFFER);

export const refundReissue = (params) =>
  requestApiCall("refundReissue", params, REFUND_REISSUE);

export const requestRefund = (params) =>
  requestApiCall("requestRefund", params, REQUEST_REFUND);

export const acceptRefundFromAdmin = (params) =>
  requestApiCall("acceptRefundFromAdmin", params, ACCEPT_REFUND);

export const rejectRefundFromAdmin = (params) =>
  requestApiCall("rejectRefundFromAdmin", params, REJECT_REFUND);

export const acceptRefundFromAgency = (params) =>
  requestApiCall(
    "updateRefundStatusByAgency",
    { status: RefundApplied, ...params },
    ACCEPT_REFUND
  );

export const rejectRefundFromAgency = (params) =>
  requestApiCall(
    "updateRefundStatusByAgency",
    { status: RefundWithdraw, ...params },
    REJECT_REFUND
  );

export const updateRefundStatusByAdmin = (params) =>
  requestApiCall("updateRefundStatusByAdmin", params, UPDATE_REFUND_STATUS);

export const requestVoid = (params) =>
  requestApiCall("requestVoid", params, REQUEST_VOID);

export const acceptVoidManually = (params) =>
  requestApiCall("acceptVoidManually", params, ACCEPT_VOID_MANUALLY);

export const acceptVoid = (params) =>
  requestApiCall("acceptVoid", params, ACCEPT_VOID);

export const rejectVoid = (params) =>
  requestApiCall("rejectVoid", params, REJECT_VOID);

export const getSpecificOrder = (params) =>
  requestApiCall("getSpecificOrder", params, GET_SPECIFIC_ORDER);

export const getSpecificOrderByBookingId = (params) =>
  requestApiCall(
    "getSpecificOrderByBookingId",
    params,
    GET_SPECIFIC_ORDER_BY_BOOKING_ID
  );

export const getUserOrders = (params) =>
  requestApiCall("getUserOrders", params, GET_USER_ORDERS);

export const getOrders = (params) =>
  requestApiCall("getOrders", params, GET_ORDERS);

export const getAllOrders = (params) =>
  requestApiCall("getAllOrders", params, GET_ALL_ORDERS);

export const getRefunds = (params) =>
  requestApiCall("getRefunds", params, GET_REFUNDS);

export const getAllRefunds = (params) =>
  requestApiCall("getAllRefunds", params, GET_ALL_REFUNDS);

export const getVoid = (params) => requestApiCall("getVoid", params, GET_VOIDS);

export const getAllVoid = (params) =>
  requestApiCall("getAllVoid", params, GET_ALL_VOIDS);

export const filterVoid = (params) =>
  requestApiCall("filterVoid", params, FILTER_VOID);

export const filterAllVoid = (params) =>
  requestApiCall("filterAllVoid", params, FILTER_ALL_VOID);

export const getReissue = (params) =>
  requestApiCall("getReissue", params, GET_REISSUE);

export const getAllReissue = (params) =>
  requestApiCall("getAllReissue", params, GET_ALL_REISSUE);

export const filterAllOrders = (params) =>
  requestApiCall("filterAllOrders", params, FILTER_ALL_ORDERS);

export const searchAllOrders = (params) =>
  requestApiCall("searchAllOrders", params, SEARCH_ALL_ORDERS);

export const filterOrders = (params) =>
  requestApiCall("filterOrders", params, FILTER_ORDERS);

export const filterReissue = (params) =>
  requestApiCall("filterReissue", params, FILTER_REISSUE);

export const filterRefunds = (params) =>
  requestApiCall("filterRefunds", params, FILTER_REFUNDS);

export const clearVoid = () => ({ type: REQUEST_VOID.CLEAR });

export const clearVoidWithNoRefund = () => ({
  type: VOID_WITH_NO_REFUND.CLEAR,
});

export const clearSendEmail = () => ({ type: SEND_VOID_EMAIL.CLEAR });

export const clearUpdateBooking = () => ({
  type: UPDATE_BOOKING_TICKETS.CLEAR,
});

export const clearEditFee = () => ({ type: EDIT_FEE.CLEAR });

export const clearCreateFee = () => ({ type: CREATE_FEE.CLEAR });

export const clearCreateBookingNote = () => ({
  type: CREATE_BOOKING_NOTE.CLEAR,
});

export const clearUpdateBookingStatus = () => ({
  type: UPDATE_BOOKING_STATUS.CLEAR,
});

export const clearCancelProcessingBooking = () => ({
  type: CANCEL_PROCESSING_BOOKING.CLEAR,
});

export const clearReissue = () => ({
  type: REQUEST_REISSUE.CLEAR,
});

export const clearDiscount = () => ({
  type: ADD_DISCOUNT.CLEAR,
});

export const clearRefund = () => ({
  type: REQUEST_REFUND.CLEAR,
});

export const clearRefundReissue = () => ({
  type: REFUND_REISSUE.CLEAR,
});

export const clearPaySubscription = () => ({
  type: PAY_SUBSCRIPTION.CLEAR,
});

export const reset = () => ({ type: RESET });
