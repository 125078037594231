import React from "react";
import { Switch, Route } from "react-router-dom";
import TopUpClearedInvoices from "../../../_metronic/layout/components/admin/topUps/TopUpClearedInvoices";
import { TopUps } from "../../../_metronic/layout/components/admin/topUps/TopUps";
import {
  ADMIN_PAYMENTS_TOP_UPS,
  ADMIN_PAYMENTS_TOP_UP_CLEARED_INVOICES,
} from "../../routesMapAdmin";

export const TopUpsPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <Route
        exact
        path={ADMIN_PAYMENTS_TOP_UP_CLEARED_INVOICES}
        render={(props) => (
          <TopUpClearedInvoices permissionId={permissionId} {...props} />
        )}
      />
      <Route
        path={ADMIN_PAYMENTS_TOP_UPS}
        render={(props) => <TopUps permissionId={permissionId} {...props} />}
      />
    </Switch>
  );
};
