import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";

import { AgencyOperationTable } from "./AgencyOperationTable";
import { GoBack } from "../../../../common";
import { actions } from "../../../../../../../redux/state/accounting";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { parseRoute } from "../../../../../../../app/helpers/formatters";
import { ADMIN_ACCOUNTING_OPERATION_ROUTE } from "../../../../../../../app/routesMapAdmin";
import useQuery from "../../../../../../../app/helpers/hooks/useQuery";

export const AgencyOperation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getCompanyName } = useCompanyName();
  const { id } = useParams();
  const query = useQuery();
  const { list } = useSelector(({ accounting }) => accounting.agencyRoutes);

  const fromDate = query.get("fromDate");
  const toDate = query.get("toDate");
  useEffect(() => {
    dispatch(actions.getAgencyRoutes({ id, fromDate, toDate }));
  }, [dispatch, fromDate, id, toDate]);
  const goToRouteDetails = ({ agencyId, fromCode, toCode }) => {
    const searchParams = {};
    if (fromDate && toDate) {
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
    }
    const route = parseRoute(
      ADMIN_ACCOUNTING_OPERATION_ROUTE,
      {
        agencyId,
        fromCode,
        toCode,
      },
      searchParams
    );
    history.push(route);
  };
  // const fields = useFormik({
  //   initialValues: {
  //     fromDate: "",
  //     toDate: "",
  //     client: 0,
  //   },
  //   enableReinitialize: true,
  //   onSubmit: ({ fromDate, toDate, client }) => {},
  // });
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex justify-content-between align-items-center">
          <GoBack />
          <Typography className="text-primary" variant="h5">
            {getCompanyName(id)}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <AgencyOperationTable
            goToRouteDetails={goToRouteDetails}
            list={list}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
