import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_partials/controls";
import { Button } from "../../common";

export const ConfirmationModal = ({
  show,
  body,
  title,
  onHide,
  onConfirm,
  loading,
  success,
  failure,
  onFailure,
  onSuccess,
  children,
}) => {
  useEffect(() => {
    if (success) {
      onHide();
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [onHide, onSuccess, success]);

  useEffect(() => {
    if (failure) {
      onFailure();
    }
  }, [failure, onFailure]);

  return (
    <Modal show={show} onHide={onHide}>
      {loading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && <span>{body}</span>}
        <span>{children}</span>
        {loading && <span>loading...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            smallPadding
            variant="secondary"
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <> </>
          <Button
            smallPadding
            loading={loading}
            type="button"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
