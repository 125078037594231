import {
  ALL,
  BOOKING_DATE,
  ORDER_NO,
  PNR,
  TRANSACTION_STATUS,
  TICKET_NO,
  BOOKING_STATUS,
} from "../state/bookingMng/bookingMngConstants";
import { bookingClient } from "./client";

export const filterBookings = ({
  sortingParams,
  filterParam,
  type,
  pageNumber,
  pageSize,
}) =>
  bookingClient.post("booking/v1/bookingFilters", {
    sortingParams: sortingParams || [{}],
    filterParam: filterParam || [{}],
    type,
    pageNumber,
    pageSize,
  });

export const exportOrders = ({
  columns = [],
  fromDate,
  toDate,
  filters = [],
}) =>
  bookingClient.post("booking/v1/TransactionReportExcel", {
    columns,
    fromDate,
    toDate,
    filters,
  });

export const addDiscount = ({ id, discount, note, userId }) =>
  bookingClient.post(`/booking/v1/Orders/bookingDiscount/${id}`, {
    discount,
    note,
    userId,
  });

export const getSpecificOrder = ({ id }) =>
  bookingClient.get(`/booking/v1/Orders/${id}`);

export const getSpecificOrderByBookingId = ({ bookingId }) =>
  bookingClient.get(`/booking/v1/Orders/TransactionByBookingId/${bookingId}`);

export const getOrders = ({ companyId, PageNo, PageSize = 10 }) =>
  bookingClient.post(`/booking/v1/orders/company/${companyId}`, {
    PageNo,
    PageSize,
  });

export const getAllOrders = ({ PageNo, PageSize = 10 }) =>
  bookingClient.post("/booking/v1/Orders/GetAllBooking", { PageNo, PageSize });

export const filterAllOrders = ({ status }) => {
  return bookingClient.get(`/booking/v1/orders/status=${status}`);
};

export const searchAllOrders = ({ filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `${BOOKING_DATE}=${filterValue}`,
    [ORDER_NO]: `${ORDER_NO}=${filterValue}`,
    [PNR]: `${PNR}=${filterValue}`,
    [TICKET_NO]: `${TICKET_NO}=${filterValue}`,
    [TRANSACTION_STATUS]: `${TRANSACTION_STATUS}=${filterValue}`,
    [BOOKING_STATUS]: `${BOOKING_STATUS}=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(`/booking/v1/orders/${filter}`);
};

export const filterOrders = ({ companyId, filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `${BOOKING_DATE}=${filterValue}`,
    [ORDER_NO]: `${ORDER_NO}=${filterValue}`,
    [PNR]: `${PNR}=${filterValue}`,
    [TICKET_NO]: `${TICKET_NO}=${filterValue}`,
    [TRANSACTION_STATUS]: `${TRANSACTION_STATUS}=${filterValue}`,
    [BOOKING_STATUS]: `${BOOKING_STATUS}=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(`/booking/v1/orders/company/${companyId}/${filter}`);
};

export const getRefunds = ({ companyId, status = ALL }) =>
  bookingClient.get(
    `/booking/v1/orders/company/${companyId}/GetAllRefunds/${
      status === ALL ? "" : status
    }`
  );
export const getAllRefunds = ({ status }) =>
  bookingClient.get(
    `/booking/v1/orders/GetAllRefunds/${status === ALL ? "" : status}`
  );
export const filterRefunds = ({ companyId, filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `bookingDate=${filterValue}`,
    [ORDER_NO]: `orderno=${filterValue}`,
    [PNR]: `PNR=${filterValue}`,
    [TICKET_NO]: `ticketno=${filterValue}`,
    [TRANSACTION_STATUS]: `status=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(
    `/booking/v1/orders/company/${companyId}/Refunds/${filter}`
  );
};
export const getReissue = ({ companyId, status = ALL }) =>
  bookingClient.get(
    `/booking/v1/orders/company/${companyId}/GetAllReIssue/${
      status === ALL ? "" : status
    }`
  );

export const getAllReissue = ({ status }) =>
  bookingClient.get(
    `/booking/v1/orders/GetAllReIssue/${status === ALL ? "" : status}`
  );
export const filterReissue = ({ companyId, filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `bookingDate=${filterValue}`,
    [ORDER_NO]: `orderno=${filterValue}`,
    [PNR]: `PNR=${filterValue}`,
    [TICKET_NO]: `ticketno=${filterValue}`,
    [TRANSACTION_STATUS]: `status=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(
    `/booking/v1/orders/company/${companyId}/ReIssue/${filter}`
  );
};

export const getVoid = ({ companyId, status = ALL }) => {
  const path =
    status === ALL
      ? `/booking/v1/void/${companyId}/company/`
      : `/booking/v1/void/company/${companyId}/status=${status}`;
  return bookingClient.get(path);
};

export const getAllVoid = ({ status = ALL }) => {
  const path =
    status === ALL
      ? "/booking/v1/voidall/"
      : `/booking/v1/void/status=${status}`;
  return bookingClient.get(path);
};

export const filterVoid = ({ companyId, filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `bookingDate=${filterValue}`,
    [ORDER_NO]: `orderno=${filterValue}`,
    [PNR]: `PNR=${filterValue}`,
    [TICKET_NO]: `ticketno=${filterValue}`,
    [TRANSACTION_STATUS]: `status=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(`/booking/v1/void/company/${companyId}/${filter}`);
};

export const filterAllVoid = ({ companyId, filterValue, filterType }) => {
  const filtersMap = {
    [BOOKING_DATE]: `bookingDate=${filterValue}`,
    [ORDER_NO]: `orderno=${filterValue}`,
    [PNR]: `PNR=${filterValue}`,
    [TICKET_NO]: `ticketno=${filterValue}`,
    [TRANSACTION_STATUS]: `status=${filterValue}`,
  };
  const filter = filtersMap[filterType];
  return bookingClient.get(`/booking/v1/void/${filter}`);
};

export const getUserOrders = ({ userId }) =>
  bookingClient.get(`/booking/v1/Orders/bookingbyuser/${userId}`);

export const requestVoid = (params) =>
  bookingClient.post(`/booking/v1/void/SendRequest`, params);

export const voidWithNoRefund = (params) =>
  bookingClient.post(`/booking/v1/void/VoidWithNoRefund`, { ...params });

export const acceptVoid = ({ id, fee }) =>
  bookingClient.post(`/booking/v1/void/AcceptVoidRequest/${id}`, { fee });

export const acceptVoidManually = ({ id, fee }) =>
  bookingClient.post(`/booking/v1/void/ManualAcceptVoidRequest/${id}`, { fee });

export const rejectVoid = ({ id, note }) =>
  bookingClient.post(`/booking/v1/void/RejectVoidRequest/${id}`, { note });

export const requestRefund = (params) =>
  bookingClient.post(`/booking/v1/refund/SendRequest`, params);

export const acceptRefundFromAdmin = ({ id, note, refundAmount }) =>
  bookingClient.post(`/booking/v1/refund/AcceptRefundRequest/${id}`, {
    note,
    refundAmount,
  });

export const rejectRefundFromAdmin = ({ id, note }) =>
  bookingClient.post(`/booking/v1/refund/RejectRefundRequestByAdmin/${id}`, {
    note,
  });

export const updateRefundStatusByAgency = ({ id, status }) =>
  bookingClient.post(
    `/booking/v1/refund/updateRefundStatusByAgency/${id}/${status}`
  );

export const updateRefundStatusByAdmin = ({ id, status }) =>
  bookingClient.post(
    `/booking/v1/refund/updateRefundStatusByAdmin/${id}/${status}`
  );

// export const requestReissue = (params) =>
//   bookingClient.post(`/booking/v1/reissue/SendRequest`, params);

export const requestReissue = ({ prevTransactionId, params }) =>
  bookingClient.post(
    `/booking/v1/reissue/SendRequest/${prevTransactionId}`,
    params
  );

export const acceptReissue = ({ id, params }) =>
  bookingClient.post(`/booking/v1/reissue/AcceptReissueRequest/${id}`, params);

export const rejectReissue = ({ id, note }) =>
  bookingClient.post(`/booking/v1/reissue/RejectReissueRequest/${id}`, {
    note,
  });

export const rejectReissueOffer = ({ id, note }) =>
  bookingClient.post(`/booking/v1/reissue/AgencyRejectReissueRequest/${id}`, {
    note,
  });

export const refundReissue = ({ id, ...rest }) =>
  bookingClient.post(`/booking/v1/reissue/ReIssueRefund/${id}`, {
    id,
    ...rest,
  });

export const getBookingStatesForAgency = ({ companyId }) =>
  bookingClient.get(`/booking/v1/orders/bookingStatsByAgency/${companyId}`);

export const getBookingStates = () =>
  bookingClient.get("/booking/v1/Orders/bookingStats");

export const sendVoidEmail = ({ id, email, pnr }) =>
  bookingClient.post(`/booking/v1/void/SendMail/${id}`, { email, pnr });

export const sendRefundEmail = ({ id, email, pnr }) =>
  bookingClient.post(`/booking/v1/refund/SendMail/${id}`, { email, pnr });

export const sendReissueEmail = ({ id, email }) =>
  bookingClient.post(`/booking/v1/reissue/SendMail/${id}`, { email });

export const getSpecificVoid = ({ id }) =>
  bookingClient.get(`/booking/v1/void/${id}`);

export const getSpecificVoidByGuid = ({ guid }) =>
  bookingClient.get(`/booking/v1/void/ByGuid/${guid}`);

export const getSpecificRefund = ({ id }) =>
  bookingClient.get(`/booking/v1/refund/${id}`);

export const getSpecificReissue = ({ id }) =>
  bookingClient.get(`/booking/v1/Orders/reissue/${id}`);

export const updatePassengersTickets = ({ id, tickets }) =>
  bookingClient.post(`/booking/v1/UpdatePassegngerTicket/${id}`, tickets);

export const getFeeByType = ({ type }) =>
  bookingClient.get(`/booking/v1/FeeSettingByType/${type}`);

export const getFeeLogs = ({ type, startDate, endDate }) =>
  bookingClient.get(`/booking/v1/FeeSettingAuditByType/${type}`);

export const getAllFees = () =>
  bookingClient.get("/booking/v1/FeeSettings/GetAll");

export const createFee = ({ type, amount, isPercentage, createdBy }) =>
  bookingClient.post("/booking/v1/FeeSettings", {
    type,
    amount,
    isPercentage,
    createdBy,
  });

export const editFee = ({ id, ...params }) =>
  bookingClient.put(`/booking/v1/FeeSettings/${id}`, { id, ...params });

export const createBookingNote = ({ bookingId, ...params }) =>
  bookingClient.post(`/booking/v1/Orders/AddNotesByBookingId/${bookingId}`, {
    bookingId,
    ...params,
  });

export const paySubscription = (params) =>
  bookingClient.post("/v1/SubscriptionPayment", params);

export const getBookingNotes = ({ bookingId }) =>
  bookingClient.get(`/booking/v1/Orders/NotesByBookingId/${bookingId}`);

export const updateBookingStatus = ({ id, status }) =>
  bookingClient.post(`/booking/v1/orders/updateTicketStatus/${id}/${status}`);

export const cancelProcessingBooking = ({ id }) =>
  bookingClient.post(`/booking/v1/Orders/${id}/RefundOnProcessing`);

export const createPaymentIntent = ({ amount, stripeWebhookPaymentRequest }) =>
  bookingClient.post(`/v1/stripe-create-payment-intent`, {
    items: [
      {
        amount: amount,
      },
    ],
    stripeWebhookPaymentRequest,
  });

export const createMasterCardIntent = ({ amount }) =>
  bookingClient.post(`/v1/MasterCard/${amount}`);
