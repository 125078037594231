import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { actions } from "../../../../../redux/state/communication";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Seperator } from "../../common";
import { NotificationsWrapper } from "./NotificationsWrapper";
import { LoadingSpinner } from "../../../../../_metronic/layout/components/common/loadingSpinner/LoadingSpinner";
import { ADMIN_NOTIFICATIONS } from "../../../../../app/routesMapAdmin";
import { ACCOUNT_NOTIFICATION } from "../../../../../app/routesMap";

export const NotificationDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading } = useSelector(
    ({ communication }) => communication.specificNotification
  );
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  useEffect(() => {
    dispatch(actions.getSpecificNotification({ id }));
    return () => {
      dispatch(actions.clearSpecificNotification());
    };
  }, [dispatch, id]);
  const { subject, body } = data ?? {};
  return (
    <NotificationsWrapper>
      <div className="d-flex">
        <button
          onClick={() =>
            history.push(isAdmin ? ADMIN_NOTIFICATIONS : ACCOUNT_NOTIFICATION)
          }
          className="btn btn-icon btn-light mr-3"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
            ></SVG>
          </span>
        </button>
        {/* <button className="btn btn-icon btn-light mr-3">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            ></SVG>
          </span>
        </button>
        <button className="btn btn-icon btn-light mr-3">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
            ></SVG>
          </span>
        </button> */}
      </div>
      <Seperator />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex align-items-start justify-content-between">
          <div className="w-50">
            <Typography variant="h6">{subject}</Typography>
            <div className="w-100" dangerouslySetInnerHTML={{ __html: body }} />
          </div>
          <Typography variant="body1">06:52 | April 29,2021</Typography>
        </div>
      )}
    </NotificationsWrapper>
  );
};
