import { useMemo } from "react";
import useAirportsList from "./useAirportsList";

export default function useAirportsOptions() {
  const { airportsList, loading, success, failure } = useAirportsList();
  const airportsOptions = useMemo(
    () =>
      airportsList?.map(({ city, code, name }) => ({
        value: code,
        label: `${city} (${name}-${code})`,
        city,
        code,
        name,
      })),
    [airportsList]
  );
  return { airportsOptions, loading, success, failure };
}
