import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { InfoBar, ProfileInfo } from "../common/InfoBar";
import clsx from "clsx";
import { Fragment } from "react";
import {  useSelector } from "react-redux";
import { getDateTime } from "../../../../_helpers/date";
import { BillingHistory } from "../../admin/client/clientDetails/clientBilling/BillingHistory";

export const TableRow = ({ data }) => {
  const amountClasses = clsx({
    "text-dark": data.amount >= 0,
    "text-danger": data.amount < 0,
  });
  const { date, time } = getDateTime(data.insertedOn);
  return (
    <tr className="font-weight-bold">
      <td>{data.orderNo}</td>
      <td className="text-primary">{data.orderNo}</td>
      <td>
        <div>
          <p className="mb-0">{date}</p>
          <p className="text-muted">{time}</p>
        </div>
      </td>
      {/* <td>{data.status}</td> */}
      <td>
        <span className={amountClasses}>${data.totalPrice}</span>
      </td>
      <td>${data.refundPrice || "-"}</td>
      <td>{data.totalPrice || "-"}</td>
    </tr>
  );
};

export const AccountBalance = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const { companyInUserId } = useSelector(({ user }) => user.userData);

  return (
    <Grid container spacing={3}>
      {!matchesMd && (
        <Grid item container xs={12}>
          <InfoBar />
        </Grid>
      )}
      {matchesMd && (
        <Fragment>
          {/* <Grid item xs={12} sm={7}>
            <Paper className="bg-white h-100">
              <Chart>
                <div className="d-flex justify-content-between">
                  <div>
                    <Typography variant="h6" className="mb-1">
                      Balance stats
                    </Typography>
                    <Typography variant="body1" className="text-muted mb-1">
                      Detailed Balance stats in the last 30 days
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      align="right"
                      variant="h6"
                      className="text-primary font-weight-bolder mb-1"
                    >
                      ${accountBalanceData?.availableBalance}
                    </Typography>
                    <Typography
                      align="right"
                      variant="body1"
                      className="text-muted mb-1"
                    >
                      Available Balance
                    </Typography>
                  </div>
                </div>
              </Chart>
            </Paper>
          </Grid> */}
          <Grid
            item
            container
            xs={12}
            // sm={5}
            sm={12}
          >
            <ProfileInfo noBottomPadding />
          </Grid>
        </Fragment>
      )}
      {/* hidden for release */}
      {/* <Grid item xs={12}>
        <Paper className="bg-white p-7 d-flex align-items-end">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={5}
              className="d-flex justify-content-between flex-grow-1"
            >
              <Input
                placeholder="Statement Search"
                className=""
                noErrorMessage
              />
              {matchesMd && (
                <Button variant="primary" className="ml-5">
                  Search
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md>
              <Input type="date" noErrorMessage />
            </Grid>
            <Grid item xs={12} md>
              <Input type="date" noErrorMessage />
            </Grid>
            <Grid item xs={12} sm md className="d-flex justify-content-between">
              {!matchesMd && <Button variant="primary">Search</Button>}
              <Button
                ref={anchorRef}
                onClick={handleToggle}
                variant="danger"
                className="d-flex ml-auto"
                smallPadding
              >
                <span>Export</span>
                <ExpandMoreIcon style={{ color: "#ffffff" }} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <BillingHistory companyId={companyInUserId} />
      </Grid>
    </Grid>
  );
};
