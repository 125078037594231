import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./dictActions";

const initialState = {
  airports: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
  airlines: {
    list: undefined,
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

export const dictReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_AIRLINES.REQUESTED:
        state.airlines.loading = true;
        break;
      case actions.GET_AIRLINES.SUCCEEDED:
        state.airlines.loading = false;
        state.airlines.success = true;
        state.airlines.list = payload.data.airlines;
        break;
      case actions.GET_AIRLINES.FAILED:
        state.airlines.loading = false;
        state.airlines.failure = payload.error;
        break;
      case actions.GET_AIRPORTS.REQUESTED:
        state.airports.loading = true;
        break;
      case actions.GET_AIRPORTS.SUCCEEDED:
        state.airports.loading = false;
        state.airports.success = true;
        state.airports.list = payload.data.airports;
        break;
      case actions.GET_AIRPORTS.FAILED:
        state.airports.loading = false;
        state.airports.failure = payload.error;
        break;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
