import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationDetails, Notifications } from "../../../_metronic/layout/components/account";
import { NotificationsTypes } from "../../../_metronic/layout/components/admin/systemManagement/notifications/NotificationsTypes";
import {
  ADMIN_NOTIFICATIONS,
  ADMIN_NOTIFICATIONS_ACCESS,
  ADMIN_NOTIFICATION_DETAILS,
} from "../../routesMapAdmin";

export const AdminNotificationPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={ADMIN_NOTIFICATIONS_ACCESS}
        component={NotificationsTypes}
      />
      <Route
        exact
        path={ADMIN_NOTIFICATION_DETAILS}
        component={NotificationDetails}
      />
      <Route exact path={ADMIN_NOTIFICATIONS} component={Notifications} />
    </Switch>
  );
};
