import React from "react";
import { Route, Switch } from "react-router-dom";
import { CreateSubUserPage, SubUserPage } from ".";
import { Permissions } from "../../../_metronic/layout/components/account/accountSubUser/Permissions";

import { SubUsersList } from "../../../_metronic/layout/components/account/accountSubUser/SubUsersList";
import {
  ACCOUNT_SUB_USER,
  ACCOUNT_SUB_USER_LIST,
  CREATE_SUB_USER,
  SUB_USER_PERMISSION,
} from "../../routesMap";

export const SubUsersListPage = () => {
  return (
    <Switch>
      <Route exact path={CREATE_SUB_USER} component={CreateSubUserPage} />
      <Route exact path={ACCOUNT_SUB_USER} component={SubUserPage} />
      <Route exact path={SUB_USER_PERMISSION} component={Permissions} />
      <Route exact path={ACCOUNT_SUB_USER_LIST} component={SubUsersList} />
    </Switch>
  );
};
