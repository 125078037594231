import React from "react";
import { Switch, Route } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { BankAccountHistory } from "../../../_metronic/layout/components/admin/payments/bankAccounts/bankAccountHistory/BankAccountHistory";
import { BankAccounts } from "../../../_metronic/layout/components/admin/payments/bankAccounts/BankAccounts";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import {
  ADMIN_PAYMENTS_BANK_ACCOUNTS,
  ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY,
} from "../../routesMapAdmin";

export const BankAccountsPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <ContentRoute
        permissionKey={generateNestedObjectKey([
          permissionKey,
          "subPermissions.viewHistory",
        ])}
        path={ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY}
        component={BankAccountHistory}
      />
      <Route
        path={ADMIN_PAYMENTS_BANK_ACCOUNTS}
        render={(props) => (
          <BankAccounts permissionId={permissionId} {...props} />
        )}
      />
    </Switch>
  );
};
