import React, { useEffect, useState } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { useHistory, useParams } from "react-router";
import { Button, Select, Seperator } from "../../common";
import { parseRoute } from "../../../../../app/helpers/formatters";
import { TICKET_DETAILS } from "../../../../../app/routesMap";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import { BookingPending } from "../../../../../redux/state/booking/bookingConstants";
import { FlightPassengers } from "./FlightPassenger";
import { FlightSegments } from "./FlightSegments";
import { reissueReasonsOptions } from "../../../../_assets/js/components/options";

const reissueSchema = Yup.object().shape({
  reason: Yup.string().required("Field is required"),
  reasonFiles: Yup.array().of(Yup.string()),
  segments: Yup.array().of(
    Yup.object().shape({
      date: Yup.string().required("Field is required"),
      bookingClass: Yup.string().required("Field is required"),
    })
  ),
});

export const Reissue = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedPassengers, setSelectedPassengers] = useState([]);

  const { data, success: specificOrderSuccess } = useSelector(
    ({ bookingMng }) => bookingMng.specificOrder
  );
  const { loading, success, failure } = useSelector(
    ({ bookingMng }) => bookingMng.reissue
  );
  const { bookingId, passengers, segment } = data || {};
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}

  const fields = useFormik({
    initialValues: { reason: "", reasonFiles: [], segments: [] },
    validationSchema: reissueSchema,
    onSubmit: ({ segments: segmentsValues }) => {
      if (selectedPassengers.length === 0) {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: "Please select at least one passenger",
          })
        );
        return;
      }
      if (!selectedSegments || selectedSegments.length === 0) {
        dispatch(
          inlineNotificationActions.setInlineNotification({
            type: NOTIFICATION_TYPE_ERROR,
            message: "Please select at least one flight",
          })
        );
        return;
      }
      // const paxDetails = { adult, child, infant, youth };
      // const generalInfo = { tripType, cabin: flight.cabin };
      // const searchRQ = { paxDetails, generalInfo };
      const { from, to } = selectedSegments[0];
      const { date } = segmentsValues[0];
      const flightSegments = selectedSegments?.map(
        ({ id, from, to, iSeg, ...rest }) => {
          const { date, bookingClass } = segmentsValues.filter(
            (_, index) => id === index
          )[0];
          const oldISeg = iSeg[0];
          const newISeg = [
            {
              ...oldISeg,
              dDat: date,
              dApt: from,
              aApt: to,
              bc: bookingClass,
              aDat: "",
              aTime: "",
              dTime: "",
              fn: "",
              dTer: "",
              aTer: "",
              segmentDuration: "",
            },
          ];

          return { totalDuration: "", iSeg: newISeg };
        }
      );
      const newPassengers = selectedPassengers.map(({ $id, id, ...rest }) => ({
        ...rest,
        totalFareAmount: "0",
        totalTaxAmount: "0",
      }));
      // const selectedAirline = { segments: flightSegments };
      // getBookingParams({
      //   travellersValues: { passengers: selectedPassengers },
      //   isReissue: true,
      //   selectedAirline,
      //   searchRQ,
      // });
      const { flight, $id, id, transactionNotes, ...restParams } = data;
      const { $id: _, id: __, ...restFlight } = flight;
      const params = {
        ...restParams,
        segment: JSON.stringify(flightSegments),
        flight: {
          ...restFlight,
          fromCode: from,
          toCode: to,
          departureDateTime: date,
        },
        totalPax: selectedPassengers.length.toString(),
        bookingStatus: BookingPending,
        price: "0",
        agencyMarkup: "0",
        flymeMarkup: "0",
        totalPrice: "0",
        passengers: newPassengers,
        transaction: "",
      };
      dispatch(actions.requestReissue({ prevTransactionId: id, params }));
    },
  });
  const { setFieldValue, values, touched, errors, handleSubmit } = fields;
  useEffect(() => {
    dispatch(actions.getSpecificOrder({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (specificOrderSuccess) {
      setFieldValue(
        "segments",
        segments?.map(({ iSeg }) => ({
          date: iSeg[0].dDat,
          bookingClass: iSeg[0].bc,
        }))
      );
    }
  }, [specificOrderSuccess]);

  const removeFile = (clickedIndex) => {
    const newFileArr = values.reasonFiles.filter(
      (_, index) => index !== clickedIndex
    );
    setFieldValue("reasonFiles", [...newFileArr]);
  };
  useEffect(() => {
    if (success) {
      history.push(parseRoute(TICKET_DETAILS, { id: bookingId }));
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Reissue request sent successfully!",
        })
      );
      dispatch(actions.clearReissue());
    }
  }, [bookingId, dispatch, history, success]);

  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: failure,
        })
      );
      dispatch(actions.clearReissue());
    }
  }, [dispatch, failure]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h5">Reissue/Change Fligh</Typography>
          <Typography variant="body1">Order No: {bookingId}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="">
            <div className="d-flex align-items-center ">
              <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
                <SVG src="/media/svg/icons/General/person-blue.svg" />
              </span>
              <Typography variant="h5">Passengers</Typography>
            </div>
            <FlightPassengers
              setSelectedPassengers={setSelectedPassengers}
              passengers={passengers}
            />
          </div>
          <Seperator className="m-0" />
          <div className="mt-5">
            <div className="d-flex align-items-center ">
              <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
                <SVG src="/media/svg/icons/General/plane.svg" />
              </span>
              <Typography variant="h5">Flights</Typography>
            </div>
            <FlightSegments
              setSelectedSegments={setSelectedSegments}
              fields={fields}
              segments={segments}
            />
          </div>
          {/* <div className="d-flex justify-content-end">
            <Button
              disabled={
                !selectedPassengers?.length || !selectedSegments?.length
              }
              type="submit"
              onClick={searchFields.handleSubmit}
              loading={searchLoading}
            >
              Search Flights
            </Button>
          </div> */}
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        {isSubmitted && (
          <DepartingFlights
            // getReissueParams={getReissueParams}
            isReissue={true}
          />
        )}
      </Grid> */}
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex align-items-center mb-5">
            <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
              <SVG src="/media/svg/icons/General/upload.svg" />
            </span>
            <Typography variant="h5">Upload Files</Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Select
              label="Reissue reason"
              showErrors={touched.reason}
              errorMessage={errors.reason}
              onChange={(value) => setFieldValue("reason", value)}
              value={values.reason}
              fixedLabelWidth
              spacing
              horizontal
              className="max-w-500px"
              variant="secondary"
              options={reissueReasonsOptions}
            />
            <div className="d-flex mb-1 align-items-center">
              <span className="min-w-150px mb-2">Upload files</span>
              <label className="btn btn-primary w-350px ">
                + Upload Files
                <input
                  onChange={(e) =>
                    setFieldValue("reasonFiles", [
                      e.target.files[0],
                      ...values.reasonFiles,
                    ])
                  }
                  type="file"
                  accept="image/*"
                  hidden
                />
              </label>
              <span className="ml-5 text-muted">
                (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
              </span>
            </div>
            <div className="d-flex mb-6">
              <span className="min-w-150px"></span>
              {touched.reasonFiles && (
                <div className="fv-plugins-message-container h-20px max-w-100">
                  <div className="fv-help-block">{errors.reasonFiles}</div>
                </div>
              )}
              <div className="w-350px">
                {values.reasonFiles?.map(({ name }, index) => (
                  <div
                    key={`file${index}`}
                    className="d-flex w-100 mb-1 align-items-center"
                  >
                    <SVG src="/media/svg/icons/General/valid.svg" />
                    <Typography className="ml-3" variant="body1">
                      {name}
                    </Typography>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="btn btn-icon ml-auto"
                    >
                      <SVG src="/media/svg/icons/General/cancel.svg" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <Button loading={loading} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
