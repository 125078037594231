import React from "react";
import { Route, Switch } from "react-router-dom";
import { ServiceFees } from "../../../_metronic/layout/components/admin/systemManagement/serviceFees/ServiceFees";
import { FeeLogs } from "../../../_metronic/layout/components/admin/systemManagement/serviceFees/feeLogs/FeeLogs";
import { ADMIN_FEES, ADMIN_FEE_LOGS } from "../../routesMapAdmin";

export const ServiceFeesPage = ({ permissionId, permissionKey }) => {
  return (
    <Switch>
      <Route path={ADMIN_FEE_LOGS} component={FeeLogs} />
      <Route
        path={ADMIN_FEES}
        render={(props) => (
          <ServiceFees permissionId={permissionId} {...props} />
        )}
      />
    </Switch>
  );
};
