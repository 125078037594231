import React, { useEffect } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import { actions as filesActions } from "../../../../../redux/state/files";
import { useHistory, useParams } from "react-router";
import { Button, Select, Seperator } from "../../common";
import { parseRoute } from "../../../../../app/helpers/formatters";
import { TICKET_DETAILS } from "../../../../../app/routesMap";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const FlightSegments = ({ segments }) => {
  if (!segments) return <></>;
  const segmentsArr = [];
  segments.forEach(({ iSeg }) => {
    const from = iSeg[0].dApt;
    const to = iSeg[iSeg.length - 1].aApt;
    segmentsArr.push({ from, to });
  });
  return (
    <div className="mt-5">
      {segmentsArr.map(({ from, to }, index) => (
        <div key={`segment${index}`} className="h-50px ml-2 mb-">
          <Typography
            variant="h6"
            className="text-primary"
          >{`${from} - ${to}`}</Typography>
        </div>
      ))}
    </div>
  );
};

export const Void = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    dispatch(actions.getSpecificOrder({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, []);
  const { companyInUserId, id: userId } = useSelector(
    ({ user }) => user.userData
  );

  const { token } = useSelector((state) => state.auth);
  const { data } = useSelector(({ bookingMng }) => bookingMng.specificOrder);
  const { loading, success, failure } = useSelector(
    ({ bookingMng }) => bookingMng.void
  );
  const { bookingId, passengers, segment } = data || {};
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}

  const proofSchema = Yup.object().shape({
    reason: Yup.string().required("Field is required"),
    reasonFiles: Yup.array().of(Yup.string()),
  });
  const formik = useFormik({
    initialValues: {
      reason: "",
      reasonFiles: [],
    },
    validationSchema: proofSchema,
    onSubmit: ({ reasonFiles, reason }) => {
      const params = {
        transactionId: id,
        agencyId: `${companyInUserId}`,
        requestedUserId: `${userId}`,
        token,
        notes: "",
        reason,
        reasonImageUrl: "link",
      };
      dispatch(actions.requestVoid(params));
      // const formData = new FormData();
      // formData.append("file", reasonFiles, reasonFiles.name);
      // dispatch(filesActions.uploadFile({ userId, formData }));
    },
  });
  const {
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    resetForm,
  } = formik;
  const removeFile = (clickedIndex) => {
    const newFileArr = values.reasonFiles.filter(
      (_, index) => index !== clickedIndex
    );
    setFieldValue("reasonFiles", [...newFileArr]);
  };
  useEffect(() => {
    if (success) {
      history.push(parseRoute(TICKET_DETAILS, { id: bookingId }));
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Void request sent successfully!",
        })
      );
      dispatch(actions.reset());
    }
  }, [success]);

  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: failure,
        })
      );
      // dispatch(actions.reset());
    }
  }, [failure]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h5">Void</Typography>
          <Typography variant="body1">Order No: {bookingId}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="">
            <div className="d-flex align-items-center ">
              <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
                <SVG src="/media/svg/icons/General/person-blue.svg" />
              </span>
              <Typography variant="h5">Passengers</Typography>
            </div>
            <table className="table table-vertical-center mb-0 mt-5">
              <tr></tr>
              <tbody className="font-weight-bold">
                {passengers?.$values.map(
                  ({ firstName, lastName, gender, ticketNo }, index) => (
                    <tr key={`tr${index}`} className="h-50px ml-7">
                      <td className="text-primary">{`${firstName} ${lastName}`}</td>
                      <td>{gender}</td>
                      <td>*Adult</td>
                      <td>
                        <span className="text-primary">Ticket NO: </span>
                        {ticketNo}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <Seperator className="m-0" />
          <div className="mt-5">
            <div className="d-flex align-items-center ">
              <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
                <SVG src="/media/svg/icons/General/plane.svg" />
              </span>
              <Typography variant="h5">Flights</Typography>
            </div>
            <FlightSegments segments={segments} />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex align-items-center mb-5">
            <span className="svg-icon svg-icon-2 mr-4 svg-icon-primary">
              <SVG src="/media/svg/icons/General/upload.svg" />
            </span>
            <Typography variant="h5">Upload Files</Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Select
              label="Void reason"
              showErrors={touched.reason}
              errorMessage={errors.reason}
              onChange={(value) => setFieldValue("reason", value)}
              value={values.reason}
              fixedLabelWidth
              spacing
              horizontal
              className="max-w-500px"
              variant="secondary"
              options={[
                { label: "Wrong Date Booked", value: "Wrong Date Booked" },
                { label: "Name Mistake", value: "Name Mistake" },
                {
                  label: "Passenger Not Travelling Anymore",
                  value: "Passenger Not Travelling Anymore",
                },
              ]}
            />
            <div className="d-flex mt-3 mb-1 align-items-center">
              <span className="min-w-150px mb-2">Upload files</span>
              <label className="btn btn-primary w-350px ">
                + Upload Files
                <input
                  onChange={(e) =>
                    setFieldValue("reasonFiles", [
                      e.target.files[0],
                      ...values.reasonFiles,
                    ])
                  }
                  type="file"
                  accept="image/*"
                  hidden
                />
              </label>
              <span className="ml-5 text-muted">
                (JPG, PNG, RAR, ZIP) Max size 2MB, 5 files.
              </span>
            </div>
            <div className="d-flex mb-6">
              <span className="min-w-150px"></span>
              {touched.reasonFiles && (
                <div className="fv-plugins-message-container h-20px max-w-100">
                  <div className="fv-help-block">{errors.reasonFiles}</div>
                </div>
              )}
              <div className="w-350px">
                {values.reasonFiles.map(({ name }, index) => (
                  <div
                    key={`file${index}`}
                    className="d-flex w-100 mb-1 align-items-center"
                  >
                    <SVG src="/media/svg/icons/General/valid.svg" />
                    <Typography className="ml-3" variant="body1">
                      {name}
                    </Typography>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="btn btn-icon ml-auto"
                    >
                      <SVG src="/media/svg/icons/General/cancel.svg" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <Button loading={loading} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
