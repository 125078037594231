import { useFormik } from "formik";
import * as Yup from "yup";

const b2bSchema = Yup.object().shape({
  pin: Yup.number()
    .typeError("Pin must contain numbers only")
    .required("Please enter PIN"),
  // .test(
  //   "validate-promo-code",
  //   "Invalid promo code",
  //   function(value) {
  //     if (!value) return true;
  //     return new Promise((resolve, reject) => {
  //       validatePromoCodeDebounced(value, this.parent.promoType, resolve);
  //     });
  //   }
  // ),
});
const eWalletSchema = Yup.object().shape({
  accountNo: Yup.string().matches(
    /^[0-46-9]-*[0-9]{7,8}$/,
    "Invalid phone number"
  ),
});

const promoCodeSchema = Yup.object().shape({
  promoCode: Yup.string().required("Please enter promo code"),
});

// const validatePromoCodeDebounced = debounce((value, type, resolve) => {
//   client
//     .get(`/api/PromoCode/ByCode/${value}`)
//     .then((res) => {
//       if (+res.data.type === +type) {
//         resolve(true);
//       } else {
//         resolve(false);
//       }
//     })
//     .catch((err) => {
//       resolve(false);
//     });
// }, 500);

const cardSchema = Yup.object().shape({
  nameOnCard: Yup.string().required(),
  cardNumber: Yup.number().required(),
  expYear: Yup.number().required(),
  expMonth: Yup.number().required(),
  cardCVC: Yup.number().required(),
});

export function usePaymentForm(onSubmit, promoType) {
  const cardFields = useFormik({
    initialValues: {
      nameOnCard: "",
      cardNumber: "",
      expYear: "",
      expMonth: "",
      cardCVC: "",
    },
    validationSchema: cardSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSubmit();
    },
  });

  const b2bFields = useFormik({
    initialValues: {
      pin: "",
      promoCode: "",
      promoType,
    },
    validationSchema: b2bSchema,
    onSubmit: ({ pin }) => {
      onSubmit();
    },
  });

  const waafiFields = useFormik({
    initialValues: {
      accountNo: "",
    },
    validationSchema: eWalletSchema,
    onSubmit: () => {
      onSubmit();
    },
  });
  const premiereFields = useFormik({
    initialValues: {
      accountNo: "",
    },
    validationSchema: eWalletSchema,
    onSubmit: () => {
      onSubmit();
    },
  });

  const promoCodeFields = useFormik({
    initialValues: {
      promoCode: "",
      discount: 0,
    },
    validationSchema: promoCodeSchema,
    onSubmit: ({ discount, promoCode }) => {
      onSubmit({ discount, promoCode });
    },
  });

  return {
    cardFields,
    b2bFields,
    waafiFields,
    promoCodeFields,
    premiereFields,
  };
}
