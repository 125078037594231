import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Switch, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input, LoadingSpinner } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/companyCredit";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const CreditSettingsModal = ({ show, data, onHide }) => {
  const dispatch = useDispatch();
  const [firstTime, setFirstTime] = useState(true);
  const {
    data: creditAllowanceData,
    success: creditAllowanceSuccess,
    loading: creditAllowanceLoading,
  } = useSelector(({ companyCredit }) => companyCredit.creditAllowance);
  const {
    data: creditDetailsData,
    loading: creditDetailsLoading,
  } = useSelector(({ companyCredit }) => companyCredit.creditDetails);
  const { availableBalance } = creditDetailsData || {};
  const dataLoading = creditAllowanceLoading || creditDetailsLoading;
  const {
    loading: createEditLoading,
    success: createEditSuccess,
    failure: createEditFailure,
  } = useSelector(
    ({ companyCredit }) => companyCredit.createEditCreditAllowance
  );
  const { id, name } = data || {};
  useEffect(() => {
    return () => dispatch(actions.reset());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(actions.getCreditDetailsByCompanyId({ companyId: id }));
      dispatch(actions.getCreditAllowanceByCompanyId({ companyId: id }));
    }
  }, [dispatch, id]);

  const schema = Yup.object().shape({
    creditAllowed: Yup.number().min(0).required("Please enter Credit Allowance first"),
  });
  const fields = useFormik({
    initialValues: {
      creditAllowed: 0,
      isActive: true,
    },
    validationSchema: schema,
    onSubmit: ({ creditAllowed, isActive }) => {
      dispatch(
        actions.createCreditAllowance({
          creditAllowed,
          isActive,
          agencyId: id,
        })
      );
    },
  });
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = fields;
  const hideModal = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (creditAllowanceSuccess) {
      setFirstTime(!creditAllowanceData);
      if (creditAllowanceData) {
        const { isActive, creditAllowed } = creditAllowanceData;
        setValues({ isActive, creditAllowed });
      }
    }
  }, [creditAllowanceData, creditAllowanceSuccess, setValues]);
  useEffect(() => {
    if (createEditSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: createEditSuccess,
        })
      );
      hideModal();
      resetForm();
      dispatch(actions.reset());
    }
  }, [createEditSuccess, dispatch, hideModal, resetForm]);
  useEffect(() => {
    if (createEditFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: createEditFailure || "Something is wrong!",
        })
      );
      dispatch(actions.reset());
    }
  }, [createEditFailure, dispatch]);

  return (
    <Modal
      size="md"
      onHide={hideModal}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>Credit Allowance</Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {dataLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Grid container className="mb-7" spacing={3}>
                <Grid item xs className="d-flex align-items-center">
                  <Typography variant="h5" className="mb-3">
                    {name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className="bg-white border rounded border-dark p-3 min-w-200px text-center h-100 d-flex flex-column justify-content-center"
                >
                  <Typography variant="h5" className="mb-3">
                    <span className="text-primary">$</span>
                    {availableBalance || "0.0"}
                  </Typography>
                  <Typography variant="h6" className="mb-2">
                    Available Credit
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    {...getFieldProps("creditAllowed")}
                    showErrors={touched["creditAllowed"]}
                    errorMessage={errors["creditAllowed"]}
                    variant="secondary"
                    label="Allowed Credit"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="pl-6 d-flex flex-column align-items-center justify-content-between">
                    Active
                    <Switch
                      checked={values.isActive}
                      onClick={() =>
                        setFieldValue("isActive", !values.isActive)
                      }
                      color="primary"
                    />
                  </label>
                </Grid>
              </Grid>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={createEditLoading}
            smallPadding
            type="submit"
            disabled={dataLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
