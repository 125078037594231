import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { usePaymentForm } from "../../../../../app/helpers/hooks/usePaymentForm";
import { useTransactionFee } from "../../../../../app/helpers/hooks/useTransactionFee";
import { SUBSCRIPTION_PAYMENT_SUCCESS } from "../../../../../app/routesMap";
import {
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_PREMIERE,
  PAYMENT_TYPE_WAAFI,
  PAYMENT_TYPE_WALLET,
} from "../../../../../redux/state/booking/bookingConstants";
import { actions } from "../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import {
  paymentForEnums,
  promoAppliedOnEnums,
  stripeWebhookPaymentForEnums,
} from "../../../../../redux/state/user/userConstants";
import { Payment } from "../../common/payment/Payment";
import { getStripeFee } from "../../common/payment/utils";

export const SubscriptionPayment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentType, setPaymentType] = useState(0);

  const { companyInUserId, id: userId } = useSelector(
    ({ user }) => user.userData
  );
  const {
    feesFailure,
    feesLoading,
    stripeFixedFee,
    stripePercentageFee,
    subscriptionFee,
  } = useTransactionFee();

  // const {
  //   data,
  //   loading: feeByTypeLoading,
  //   failure: feeByTypeFailure,
  // } = useSelector(({ bookingMng }) => bookingMng.feeByType);
  const { availableBalance } =
    useSelector(({ accountBalance }) => accountBalance.balance.data) || {};
  const { token } = useSelector((state) => state.auth);
  const { loading, success, failure } = useSelector(
    ({ bookingMng }) => bookingMng.paySubscription
  );

  useEffect(() => {
    // dispatch(actions.getFeeByType({ type: FEE_TYPE_SUBSCRIPTION }));
    dispatch(actions.getAllFees());
  }, [dispatch]);

  const onSubmit = () => {
    let params = [];
    switch (paymentType) {
      case PAYMENT_TYPE_WAAFI:
        params = getEWalletParams({
          values: waafiFields.values,
          paymentType,
          totalAmt: totalAmount,
          discountAmount: discount,
          promoCode,
        });
        break;
      case PAYMENT_TYPE_PREMIERE:
        params = getEWalletParams({
          values: premiereFields.values,
          paymentType,
          totalAmt: totalAmount,
          discountAmount: discount,
          promoCode,
        });
        break;
      case PAYMENT_TYPE_WALLET:
        params = getWalletParams({
          values: b2bFields.values,
          paymentType,
          totalAmt: totalAmount,
          discountAmount: discount,
          promoCode,
        });
        break;
      default:
        break;
    }

    // getWalletParams({
    //   values: b2bFields.values,
    //   paymentType,
    //   totalAmt: totalAmount,
    //   discountAmount: discount,
    //   promoCode,
    // });
    dispatch(actions.paySubscription(params));
  };

  const {
    b2bFields,
    cardFields,
    waafiFields,
    premiereFields,
    promoCodeFields,
  } = usePaymentForm(onSubmit, promoAppliedOnEnums.subscription);
  const { discount, promoCode } = promoCodeFields.values;

  const { totalAmount, stripeFee } = useMemo(() => {
    let total = 0;
    let stripeFee = 0;
    if (paymentType === PAYMENT_TYPE_CARD) {
      stripeFee = getStripeFee({
        stripeFixedFee,
        stripePercentageFee,
        total: subscriptionFee,
      });
    }
    total = +subscriptionFee + +stripeFee - +discount;
    return {
      totalAmount: total,
      stripeFee: stripeFee,
    };
  }, [
    paymentType,
    stripeFixedFee,
    stripePercentageFee,
    subscriptionFee,
    discount,
  ]);

  const getWalletParams = useCallback(
    ({ values, paymentType, totalAmt, discountAmount, promoCode }) => {
      const { pin } = values || {};
      return {
        flymeAgencyId: companyInUserId,
        transactionFee: 0,
        customerEmail: "",
        customerName: "",
        bookingId: "",
        amount: totalAmt,
        discountAmount,
        availableBalance: +availableBalance,
        supplierCode: "",
        supplierAmount: 0,
        pinCode: pin,
        token,
        isAdmin: false,
        paymentType,
        paymentFor: paymentForEnums.subscription,
        promoCode,
        userId: userId,
        billingCardInfo: {
          customerId: "string",
          nameOnCard: "string",
          cardNumber: "string",
          expYear: 0,
          expMonth: 0,
          cardCVC: "string",
          billingAddress: "string",
          zipCode: "string",
        },
      };
    },
    [availableBalance, companyInUserId, token, userId]
  );
  const getCardParams = useCallback(
    ({ paymentType, totalAmt, discountAmount, promoCode }) => {
      return {
        flymeAgencyId: companyInUserId,
        customerEmail: "",
        customerName: "",
        bookingId: "",
        transactionFee: stripeFee,
        amount: totalAmt,
        discountAmount,
        availableBalance: 0,
        supplierCode: "",
        supplierAmount: 0,
        pinCode: "",
        token,
        isAdmin: false,
        paymentType,
        paymentFor: paymentForEnums.subscription,
        promoCode,
        userId: userId,
        billingCardInfo: {
          customerId: "string",
          nameOnCard: "string",
          cardNumber: "string",
          expYear: 0,
          expMonth: 0,
          cardCVC: "string",
          billingAddress: "string",
          zipCode: "string",
        },
      };
    },
    [companyInUserId, stripeFee, token, userId]
  );
  const getEWalletParams = useCallback(
    ({ values, paymentType, totalAmt, discountAmount, promoCode }) => {
      const { accountNo } = values || {};
      return {
        flymeAgencyId: companyInUserId,
        customerEmail: "",
        customerName: "",
        bookingId: uuidv4(),
        transactionFee: 0,
        amount: totalAmt,
        discountAmount,
        availableBalance: 0,
        supplierCode: "",
        supplierAmount: 0,
        pinCode: "",
        token,
        isAdmin: false,
        paymentType,
        paymentFor: paymentForEnums.subscription,
        promoCode,
        userId: userId,
        billingCardInfo: {
          customerId: "",
          nameOnCard: "",
          cardNumber:
            paymentType === PAYMENT_TYPE_WAAFI
              ? accountNo
              : `00252${accountNo}`,
          expYear: 0,
          expMonth: 0,
          cardCVC: "",
          billingAddress: "",
          zipCode: "",
        },
      };
    },
    [companyInUserId, token, userId]
  );
  // const getCardParams = useCallback(
  //   ({ values, paymentType, supplierAmount, supplierCode, totalAmt }) => {
  //     const { nameOnCard, cardNumber, expYear, expMonth, cardCVC } =
  //       values || {};
  //     return {
  //       createdBy: userId,
  //       updatedBy: userId,
  //       billingInfo: {
  //         createdBy: userId,
  //         updatedBy: userId,
  //         transactionId: 0,
  //         country: "",
  //         street: "",
  //         zip: "",
  //         state: "",
  //         contactNo: "",
  //         email: "",
  //         countryCode: "",
  //         nameOnCard: nameOnCard,
  //         cardNumber: cardNumber,
  //         expYear: +expYear,
  //         expMonth: +expMonth,
  //         cardCVC: cardCVC.toString(),
  //       },
  //       payment_transaction_id: 0,
  //       pinCode: "",
  //       amount: +totalAmt,
  //       paymentType,
  //       availableBalance: +availableBalance,
  //       status: "",
  //       token,
  //       supplierCode,
  //       supplierAmount,
  //     };
  //   },
  //   [availableBalance, token, userId]
  // );
  const stripePaymentObj = useMemo(() => {
    const subscriptionObj = getCardParams({
      paymentType,
      totalAmt: totalAmount,
      discountAmount: discount,
      promoCode,
    });
    const stringfiedSubscriptionObj = JSON.stringify(subscriptionObj);

    return {
      type: stripeWebhookPaymentForEnums.Subscription,
      object: stringfiedSubscriptionObj,
    };
  }, [getCardParams, paymentType, totalAmount, discount, promoCode]);

  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: "Subscription paid successfully",
          type: NOTIFICATION_TYPE_SUCCESS,
        })
      );
      history.replace(SUBSCRIPTION_PAYMENT_SUCCESS);
    }
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          message: failure,
          type: NOTIFICATION_TYPE_ERROR,
        })
      );
    }
  }, [success, failure, dispatch]);

  if (feesLoading) {
    return (
      <Skeleton animation="wave" variant="rect" width={"100%"} height={300} />
    );
  }

  if (feesFailure) {
    return (
      <div className="text-center">
        Error fetching data! Please contact Flyme247 support team
      </div>
    );
  }

  return (
    <>
      <Payment
        cardFormik={cardFields}
        b2bFormik={b2bFields}
        waafiFormik={waafiFields}
        promoCodeFormik={promoCodeFields}
        premiereFormik={premiereFields}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
        loading={loading}
        subscriptionDetails={{
          total: totalAmount,
          subtotal: subscriptionFee,
          transactionFee: stripeFee,
        }}
        agencyId={companyInUserId}
        paymentFor={paymentForEnums.subscription}
        totalPrice={totalAmount}
        stripePaymentBody={stripePaymentObj}
        noCredit
        hasPromoCode
      />
    </>
  );
};
