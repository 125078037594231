export const parseRoute = (route, params = {}, searchParams = {}) => {
  let parsedRoute = route;
  Object.keys(params).forEach((key) => {
    parsedRoute = parsedRoute.replace(`:${key}`, params[key]);
  });
  let searchQuery = "";
  Object.keys(searchParams).forEach((key, index) => {
    searchQuery += `${key}=${searchParams[key]}`;
    if (index !== Object.keys(searchParams).length - 1) {
      searchQuery += "&";
    }
  });
  if (searchQuery) {
    parsedRoute += `?${searchQuery}`;
  }
  return parsedRoute;
};
