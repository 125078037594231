import { Grid } from "@material-ui/core";
import React from "react";

import { Bookings } from "./Bookings";
import { BookingStats } from "./BookingStats";
import { RevenueStates } from "./RevenueStates";

export function Dashboard() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <BookingStats />
      </Grid>
      <Grid item xs={12} md={6}>
        <RevenueStates chartColor="primary" />
      </Grid>
      <Grid item xs={12}>
        <Bookings isAll />
      </Grid>
    </Grid>
  );
}
