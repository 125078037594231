import clsx from "clsx";
import React from "react";
import SVG from "react-inlinesvg";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const PaymentMethodRow = ({
  subPermissions,
  data,
  onDelete,
  onEdit,
}) => {
  const { id, name, isActive } = data;
  const allPaymentMethodsSubPermission =
    adminPermissionsIds.payment.paymentMethods.subPermissions;
  return (
    <tr>
      <td className="text-left text-primary">{name || "-"}</td>
      <td
        className={clsx({ "text-success": isActive, "text-danger": !isActive })}
      >
        {isActive ? "Active" : "Inactive"}
      </td>

      <td className="text-right">
        <button
          hidden={
            !subPermissions.includes(allPaymentMethodsSubPermission.edit.id)
          }
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
        <button
          hidden={
            !subPermissions.includes(allPaymentMethodsSubPermission.delete.id)
          }
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm "
          onClick={() => onDelete(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </td>
    </tr>
  );
};
