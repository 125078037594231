import produce from "immer";
import { RESET_STATE } from "../common/commonActions";
import * as actions from "./accountingActions";

const initialState = {
  edit: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  currencies: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  accountTypes: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  createAccountType: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  editAccountType: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  chartOfAccounts: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  createChartOfAccount: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  editChartOfAccount: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  invoices: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  expenses: {
    list: undefined,
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  setWeaklyTarget: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  editWeaklyTarget: {
    loading: undefined,
    failure: undefined,
    success: undefined,
  },
  weaklyTarget: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    data: undefined,
  },
  salesGraphData: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  agencyRoutes: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  routeDetails: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  routeSearches: {
    loading: undefined,
    failure: undefined,
    success: undefined,
    list: undefined,
  },
  topupClearedInvoices: {
    success: undefined,
    failure: undefined,
    loading: undefined,
    list: undefined,
  },
  updateInvoiceDueDate: {
    success: undefined,
    failure: undefined,
    loading: undefined,
  },
};

export const accountingReducer = produce(
  (state = initialState, { type, payload }) => {
    const { index } = payload?.args || {};
    switch (type) {
      case actions.UPDATE_INVOICE_DUE_DATE.REQUESTED:
        state.updateInvoiceDueDate.loading = true;
        break;
      case actions.UPDATE_INVOICE_DUE_DATE.SUCCEEDED:
        state.updateInvoiceDueDate.loading = false;
        state.updateInvoiceDueDate.success = true;
        state.updateInvoiceDueDate.list = payload.data.data;
        break;
      case actions.UPDATE_INVOICE_DUE_DATE.FAILED:
        state.updateInvoiceDueDate.loading = false;
        state.updateInvoiceDueDate.failure = payload.error;
        break;
      case actions.UPDATE_INVOICE_DUE_DATE.CLEAR:
        state.updateInvoiceDueDate = initialState.updateInvoiceDueDate;
        break;
      case actions.GET_TOP_UP_CLEARED_INVOICES.REQUESTED:
        state.topupClearedInvoices.loading = true;
        break;
      case actions.GET_TOP_UP_CLEARED_INVOICES.SUCCEEDED:
        state.topupClearedInvoices.loading = false;
        state.topupClearedInvoices.success = true;
        state.topupClearedInvoices.list = payload.data.data;
        break;
      case actions.GET_TOP_UP_CLEARED_INVOICES.FAILED:
        state.topupClearedInvoices.loading = false;
        state.topupClearedInvoices.failure = payload.error;
        break;
      case actions.GET_TOP_UP_CLEARED_INVOICES.CLEAR:
        state.topupClearedInvoices = initialState.topupClearedInvoices;
        break;
      case actions.GET_ROUTE_SEARCHES.REQUESTED:
        state.routeSearches.loading = true;
        break;
      case actions.GET_ROUTE_SEARCHES.SUCCEEDED:
        state.routeSearches.loading = false;
        state.routeSearches.success = true;
        state.routeSearches.list = payload.data.data;
        break;
      case actions.GET_ROUTE_SEARCHES.FAILED:
        state.routeSearches.loading = false;
        state.routeSearches.failure = payload.error;
        break;
      case actions.GET_ROUTE_DETAILS.REQUESTED:
        state.routeDetails.loading = true;
        break;
      case actions.GET_ROUTE_DETAILS.SUCCEEDED:
        state.routeDetails.loading = false;
        state.routeDetails.success = true;
        state.routeDetails.list = payload.data.data;
        break;
      case actions.GET_ROUTE_DETAILS.FAILED:
        state.routeDetails.loading = false;
        state.routeDetails.failure = payload.error;
        break;
      case actions.GET_AGENCY_ROUTES.REQUESTED:
        state.agencyRoutes.loading = true;
        break;
      case actions.GET_AGENCY_ROUTES.SUCCEEDED:
        state.agencyRoutes.loading = false;
        state.agencyRoutes.success = true;
        state.agencyRoutes.list = payload.data.data;
        break;
      case actions.GET_AGENCY_ROUTES.FAILED:
        state.agencyRoutes.loading = false;
        state.agencyRoutes.failure = payload.error;
        break;
      case actions.GET_SALES_GRAPH_DATA.REQUESTED:
        state.salesGraphData.loading = true;
        break;
      case actions.GET_SALES_GRAPH_DATA.SUCCEEDED:
        state.salesGraphData.loading = false;
        state.salesGraphData.success = true;
        state.salesGraphData.list = payload.data.data?.$values;
        break;
      case actions.GET_SALES_GRAPH_DATA.FAILED:
        state.salesGraphData.loading = false;
        state.salesGraphData.failure = payload.error;
        break;
      case actions.FILTER_WEAKLY_TARGETS.REQUESTED:
        state.weaklyTarget.loading = true;
        break;
      case actions.FILTER_WEAKLY_TARGETS.SUCCEEDED:
        state.weaklyTarget.loading = false;
        state.weaklyTarget.success = true;
        state.weaklyTarget.data = payload.data.data?.[0];
        break;
      case actions.FILTER_WEAKLY_TARGETS.FAILED:
        state.weaklyTarget.loading = false;
        state.weaklyTarget.failure = payload.error;
        break;
      case actions.FILTER_WEAKLY_TARGETS.CLEAR:
        state.weaklyTarget = initialState.weaklyTarget;
        break;
      case actions.EDIT_WEAKLY_TARGET.REQUESTED:
        state.editWeaklyTarget.loading = true;
        break;
      case actions.EDIT_WEAKLY_TARGET.SUCCEEDED:
        state.editWeaklyTarget.loading = false;
        state.editWeaklyTarget.success = true;
        break;
      case actions.EDIT_WEAKLY_TARGET.FAILED:
        state.editWeaklyTarget.loading = false;
        state.editWeaklyTarget.failure = payload.error;
        break;
      case actions.EDIT_WEAKLY_TARGET.CLEAR:
        state.editWeaklyTarget = initialState.editWeaklyTarget;
        break;
      case actions.SET_WEAKLY_TARGET.REQUESTED:
        state.setWeaklyTarget.loading = true;
        break;
      case actions.SET_WEAKLY_TARGET.SUCCEEDED:
        state.setWeaklyTarget.loading = false;
        state.setWeaklyTarget.success = true;
        break;
      case actions.SET_WEAKLY_TARGET.FAILED:
        state.setWeaklyTarget.loading = false;
        state.setWeaklyTarget.failure = payload.error;
        break;
      case actions.SET_WEAKLY_TARGET.CLEAR:
        state.setWeaklyTarget = initialState.setWeaklyTarget;
        break;
      case actions.EDIT_CURRENCY_RATE.REQUESTED:
        state.currencies.list[index].edit.failure = undefined;
        state.currencies.list[index].edit.success = undefined;
        state.currencies.list[index].edit.loading = true;
        break;
      case actions.EDIT_CURRENCY_RATE.SUCCEEDED:
        state.currencies.list[index].edit.loading = false;
        state.currencies.list[index].edit.success = true;
        break;
      case actions.EDIT_CURRENCY_RATE.FAILED:
        state.currencies.list[index].edit.loading = false;
        state.currencies.list[index].edit.failure = payload.error;
        break;
      case actions.GET_CURRENCIES.REQUESTED:
        state.currencies.loading = true;
        break;
      case actions.GET_CURRENCIES.SUCCEEDED:
        state.currencies.loading = false;
        state.currencies.success = true;
        state.currencies.list = payload?.data.data.map((currencyPair) => ({
          ...currencyPair,
          edit: {
            loading: false,
            failure: undefined,
            success: undefined,
          },
        }));
        break;
      case actions.GET_CURRENCIES.FAILED:
        state.currencies.loading = false;
        state.currencies.failure = payload.error;
        break;
      case actions.GET_INVOICES.REQUESTED:
        state.invoices.loading = true;
        break;
      case actions.GET_INVOICES.SUCCEEDED:
        state.invoices.loading = false;
        state.invoices.success = true;
        state.invoices.list = payload?.data.data.reverse();
        break;
      case actions.GET_INVOICES.FAILED:
        state.invoices.loading = false;
        state.invoices.failure = payload.error;
        break;
      case actions.GET_ACCOUNT_TYPES.REQUESTED:
        state.accountTypes.loading = true;
        break;
      case actions.GET_ACCOUNT_TYPES.SUCCEEDED:
        state.accountTypes.loading = false;
        state.accountTypes.success = true;
        state.accountTypes.list = payload?.data.data.reverse();
        break;
      case actions.GET_ACCOUNT_TYPES.FAILED:
        state.accountTypes.loading = false;
        state.accountTypes.failure = payload.error;
        break;
      case actions.CREATE_ACCOUNT_TYPE.REQUESTED:
        state.createAccountType.loading = true;
        break;
      case actions.CREATE_ACCOUNT_TYPE.SUCCEEDED:
        state.createAccountType.loading = false;
        state.createAccountType.success = true;
        break;
      case actions.CREATE_ACCOUNT_TYPE.FAILED:
        state.createAccountType.loading = false;
        state.createAccountType.failure = payload.error;
        break;
      case actions.CREATE_ACCOUNT_TYPE.CLEAR:
        state.createAccountType = initialState.createAccountType;
        break;
      case actions.EDIT_ACCOUNT_TYPE.REQUESTED:
        state.editAccountType.loading = true;
        break;
      case actions.EDIT_ACCOUNT_TYPE.SUCCEEDED:
        state.editAccountType.loading = false;
        state.editAccountType.success = true;
        break;
      case actions.EDIT_ACCOUNT_TYPE.FAILED:
        state.editAccountType.loading = false;
        state.editAccountType.failure = payload.error;
        break;
      case actions.EDIT_ACCOUNT_TYPE.CLEAR:
        state.editAccountType = initialState.editAccountType;
        break;
      case actions.GET_CHART_OF_ACCOUNTS.REQUESTED:
        state.chartOfAccounts.loading = true;
        break;
      case actions.GET_CHART_OF_ACCOUNTS.SUCCEEDED:
        state.chartOfAccounts.loading = false;
        state.chartOfAccounts.success = true;
        state.chartOfAccounts.list = payload?.data.data.reverse();
        break;
      case actions.GET_CHART_OF_ACCOUNTS.FAILED:
        state.chartOfAccounts.loading = false;
        state.chartOfAccounts.failure = payload.error;
        break;
      case actions.CREATE_CHART_OF_ACCOUNT.REQUESTED:
        state.createChartOfAccount.loading = true;
        break;
      case actions.CREATE_CHART_OF_ACCOUNT.SUCCEEDED:
        state.createChartOfAccount.loading = false;
        state.createChartOfAccount.success = true;
        break;
      case actions.CREATE_CHART_OF_ACCOUNT.FAILED:
        state.createChartOfAccount.loading = false;
        state.createChartOfAccount.failure = payload.error;
        break;
      case actions.CREATE_CHART_OF_ACCOUNT.CLEAR:
        state.createChartOfAccount = initialState.createChartOfAccount;
        break;
      case actions.EDIT_CHART_OF_ACCOUNT.REQUESTED:
        state.editChartOfAccount.loading = true;
        break;
      case actions.EDIT_CHART_OF_ACCOUNT.SUCCEEDED:
        state.editChartOfAccount.loading = false;
        state.editChartOfAccount.success = true;
        break;
      case actions.EDIT_CHART_OF_ACCOUNT.FAILED:
        state.editChartOfAccount.loading = false;
        state.editChartOfAccount.failure = payload.error;
        break;
      case actions.EDIT_CHART_OF_ACCOUNT.CLEAR:
        state.editChartOfAccount = initialState.editChartOfAccount;
        break;
      case actions.GET_EXPENSES.REQUESTED:
        state.expenses.loading = true;
        break;
      case actions.GET_EXPENSES.SUCCEEDED:
        state.expenses.loading = false;
        state.expenses.success = true;
        state.expenses.list = payload?.data.data.reverse();
        break;
      case actions.GET_EXPENSES.FAILED:
        state.expenses.loading = false;
        state.expenses.failure = payload.error;
        break;
      case actions.RESET:
        return initialState;
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
