import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/state/user";

export default function useUserName() {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(({ user }) => user.allUsers);
  useEffect(() => {
    if (!list && !loading) {
      dispatch(actions.getAllUsers());
    }
  }, [dispatch, list, loading]);
  const getUserName = useCallback(
    (userId) => {
      if (!list || !userId) return "";
      return list.filter(({ id }) => +id === +userId)[0]?.name;
    },
    [list]
  );
  return { getUserName, loading };
}
