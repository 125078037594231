import { useMemo } from "react";
import useAirlinesList from "./useAirlinesList";

export default function useAirlinesOptions() {
  const { airlinesList, loading, success, failure } = useAirlinesList();
  const airlinesOptions = useMemo(
    () =>
      airlinesList?.map(({ code, name }) => ({
        value: code,
        label: `${name} (${code})`,
      })),
    [airlinesList]
  );
  return { airlinesOptions, loading, success, failure };
}
