import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import {
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  withStyles,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core/";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./FlightSearch.module.scss";
import clsx from "clsx";
import { AutoCompleteSelect } from "./AutoCompleteSelect";
import { Button, CustomSelect } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../redux/state/package";
import { getToday, getTomorrow } from "../../../_helpers/date";
import { FLIGHT_SEARCH } from "../../../../app/routesMap";
import {
  CHILD_AGE,
  pax_types_text,
  PAX_TYPE_CHILD,
  PAX_TYPE_INFANT,
  PAX_TYPE_YOUTH,
  YOUTH_AGE,
} from "../../../../redux/state/booking/bookingConstants";
import { pluralize } from "../../../_helpers";
import useAirportsList from "../../../../app/helpers/hooks/useAirportsList";
import useAirportsOptions from "../../../../app/helpers/hooks/useAirportsOptions";
import usePkgSeasonsOptions from "../../../../app/helpers/hooks/usePkgSeasonsOptions";
import usePkgClassOptions from "../../../../app/helpers/hooks/usePkgClassOptions";
import usePkgTypesOptions from "../../../../app/helpers/hooks/usePkgTypesOptions";
import useCitiesOptions from "../../../../app/helpers/hooks/useCitiesOptions";
import { SearchCardShimmer } from "./SearchCardShimmer";
import { useMemo } from "react";

const agesOptions = {
  [PAX_TYPE_YOUTH]: Array(16 - YOUTH_AGE)
    .fill(0)
    .map((_, index) => ({
      value: index + YOUTH_AGE,
      label: index + YOUTH_AGE,
    })),
  [PAX_TYPE_CHILD]: Array(10)
    .fill(0)
    .map((_, index) => ({
      value: index + CHILD_AGE,
      label: index + CHILD_AGE,
    })),
  [PAX_TYPE_INFANT]: [
    { value: "<1", label: "<1" },
    { value: 1, label: 1 },
  ],
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(1),
  },
  InputRoot: {
    "&&::-webkit-calendar-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
  },
}));

const searchSchema = Yup.object().shape({
  fromCity: Yup.string().required(),
  packageSeason: Yup.string().required(),
  packageType: Yup.string().required(),
  packageClass: Yup.string().required(),
  numOfPassengers: Yup.number(),
});

export const PackageSearchCard = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { success } = useAirportsList();
  const {
    pkgSeasonOptions,
    loading: pkgSeasonOptionsLoading,
  } = usePkgSeasonsOptions();
  const {
    pkgClassesOptions,
    loading: pkgClassesOptionsLoading,
  } = usePkgClassOptions();
  const {
    pkgTypesOptions,
    loading: pkgTypesOptionsLoading,
  } = usePkgTypesOptions();
  const { citiesOptions, loading: citiesOptionsLoading } = useCitiesOptions();

  const { loading: searchLoading } = useSelector(
    ({ packages }) => packages.searchPackages
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromCity: "",
      packageSeason: "",
      packageType: "",
      packageClass: "",
      numOfPassengers: 1,
    },
    validationSchema: searchSchema,
    onSubmit: ({
      fromCity,
      packageSeason,
      packageType,
      packageClass,
      numOfPassengers,
    }) => {
      dispatch(
        actions.searchPackages({
          fromCity:fromCity.value,
          packageSeasonId: packageSeason.value,
          packageTypeId: packageType.value,
          packageClassId: packageClass.value,
          numOfPassengers,
        })
      );
    },
  });

  const {
    fromCity,
    packageSeason,
    packageType,
    packageClass,
    numOfPassengers,
  } = formik.values;

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { setFieldValue, resetForm } = formik;

  const optionsLoading = useMemo(
    () =>
      pkgSeasonOptionsLoading ||
      pkgClassesOptionsLoading ||
      pkgTypesOptionsLoading ||
      citiesOptionsLoading,
    [
      pkgSeasonOptionsLoading,
      pkgClassesOptionsLoading,
      pkgTypesOptionsLoading,
      citiesOptionsLoading,
    ]
  );
  return optionsLoading ? (
    <SearchCardShimmer />
  ) : (
    <div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="d-flex align-items-center justify-content-center"
      >
        <Paper
          className="d-flex flex-column bg-white rounded flex-grow-1 overflow-auto p-8 max-h-500px"
          style={{ maxWidth: "350px" }}
        >
          <Typography variant="h6">Number of Pilgrims</Typography>
          <div className="d-flex align-items-center my-4">
            <button
              id="numOfAdultsPlus"
              onClick={() => setFieldValue("numOfAdults", numOfPassengers - 1)}
              disabled={numOfPassengers === 1}
              className={styles.roundButton}
            >
              -
            </button>
            <Typography
              variant="h6"
              className="font-weight-bold w-25px d-flex justify-content-center"
            >
              {numOfPassengers}
            </Typography>
            <button
              id="numOfAdultsMinus"
              className={styles.roundButton}
              onClick={() =>
                setFieldValue("numOfPassengers", numOfPassengers + 1)
              }
            >
              +
            </button>
            {/* <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                16+ Years
              </Typography> */}
          </div>

          {/* <Typography variant="h6">Youth</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfYouthPlus"
                onClick={() => {
                  setFieldValue("numOfYouth", numOfYouth - 1);
                  removeAgeSelect(PAX_TYPE_YOUTH);
                }}
                disabled={numOfYouth === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfYouth}
              </Typography>
              <button
                id="numOfYouthMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfYouth", numOfYouth + 1);
                  addAgeSelect(PAX_TYPE_YOUTH);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                12-15 Years
              </Typography>
            </div> */}
          {/* <Typography variant="h6">Children</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfChildrenPlus"
                onClick={() => {
                  setFieldValue("numOfChildren", numOfChildren - 1);
                  removeAgeSelect(PAX_TYPE_CHILD);
                }}
                disabled={numOfChildren === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfChildren}
              </Typography>
              <button
                id="numOfChildrenMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfChildren", numOfChildren + 1);
                  addAgeSelect(PAX_TYPE_CHILD);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                2-15 Years
              </Typography>
            </div>
            <Typography variant="h6">Infants</Typography>
            <div className="d-flex align-items-center my-4">
              <button
                id="numOfInfantsPlus"
                onClick={() => {
                  setFieldValue("numOfInfants", numOfInfants - 1);
                  removeAgeSelect(PAX_TYPE_INFANT);
                }}
                disabled={numOfInfants === 0}
                className={styles.roundButton}
              >
                -
              </button>
              <Typography
                variant="h6"
                className="font-weight-bold w-25px d-flex justify-content-center"
              >
                {numOfInfants}
              </Typography>
              <button
                id="numOfInfantsMinus"
                className={styles.roundButton}
                onClick={() => {
                  setFieldValue("numOfInfants", numOfInfants + 1);
                  addAgeSelect(PAX_TYPE_INFANT);
                }}
              >
                +
              </button>
              <Typography
                variant="h6"
                className="ml-5"
                style={{ color: "#5A5A5A" }}
              >
                0-2 Years
              </Typography>
            </div> */}
          {/* <div>{modalSelects}</div> */}
          <Button
            id="saveAges"
            className="mx-auto mt-3"
            onClick={() => setOpenModal(false)}
          >
            Save
          </Button>
        </Paper>
      </Modal>

      <div className="d-flex">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div
              className={clsx(
                "position-relative p-0 px-3 w-100 h-100 d-flex align-items-center justify-content-between",
                {
                  "badge rounded": matchesSm,
                }
              )}
              style={{ border: "1px solid #DCDCDC" }}
            >
              <AutoCompleteSelect
                options={citiesOptions}
                isOptionsLoaded={success}
                onChange={(value) => setFieldValue(`fromCity`, value || "")}
                value={fromCity}
                label="From"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              className={clsx(
                "position-relative p-0 px-3 w-100 h-100 d-flex align-items-center justify-content-between",
                {
                  "badge rounded ": matchesSm,
                }
              )}
              style={{ border: "1px solid #DCDCDC" }}
            >
              <AutoCompleteSelect
                options={pkgSeasonOptions}
                isOptionsLoaded={success}
                onChange={(value) =>
                  setFieldValue(`packageSeason`, value || "")
                }
                value={packageSeason}
                label="Season"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div
              className={clsx(
                "position-relative p-0 px-3 w-100 h-100 d-flex align-items-center justify-content-between",
                {
                  "badge rounded": matchesSm,
                }
              )}
              style={{ border: "1px solid #DCDCDC" }}
            >
              <AutoCompleteSelect
                options={pkgTypesOptions}
                isOptionsLoaded={success}
                onChange={(value) => setFieldValue("packageType", value || "")}
                value={packageType}
                label="Package Type"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div
              className={clsx(
                "position-relative p-0 px-3 w-100 h-100 d-flex align-items-center justify-content-between",
                {
                  "badge rounded": matchesSm,
                }
              )}
              style={{ border: "1px solid #DCDCDC" }}
            >
              <AutoCompleteSelect
                options={pkgClassesOptions}
                isOptionsLoaded={success}
                onChange={(value) => setFieldValue("packageClass", value || "")}
                value={packageClass}
                label="Package Class"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div
              className="p-0 badge rounded w-100"
              style={{ border: "1px solid #DCDCDC" }}
            >
              <div className="position-relative d-flex h-100 d-flex justify-content-start w-100 p-5 pr-0">
                <TextField
                  // {...getFieldProps(`departDate.${index}`)}
                  value={pluralize(numOfPassengers, "Pilgrim", "s")}
                  // className={styles.datePickerInput}
                  label="Passengers"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onClick={() => setOpenModal(true)}
                  onMouseDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className={clsx("d-flex justify-content-end mt-3", {
          "flex-column ": !matchesSm,
        })}
      >
        <Button
          disabled={!formik.isValid}
          loading={searchLoading}
          onClick={formik.handleSubmit}
          variant="primary"
          type="submit"
        >
          Search
        </Button>
      </div>
    </div>
  );
};
