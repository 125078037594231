import clsx from "clsx";
import { differenceInHours } from "date-fns";
import React from "react";
import { getDateTime } from "../../../../../../_helpers/date";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { paymentTypeLabel } from "../../../../../../../redux/state/booking/bookingConstants";

const calculateHoursDiff = (date1, date2) => {
  const diff = Math.abs(date1 - date2);
  return Math.ceil(diff / (1000 * 60 * 60));
};

export const TicketInfo = ({ data, invoice }) => {
  const {
    insertedOn,
    bookingId,
    flight,
    userId,
    gdsPnr,
    bookingStatus,
    status,
    paymentType,
  } = data || {};
  const { due_date: dueDateTime } = invoice || {};
  const { date, time } = getDateTime(insertedOn);
  const { date: dueDate, time: dueTime } = getDateTime(dueDateTime);
  const isDue = differenceInHours(new Date(dueDateTime), new Date()) < 24;

  return (
    <CardWithBlueHeader
      title="Ticket information"
      subtitle={`Order No: ${bookingId}`} ///////////////////
    >
      <div className="table-responsive mt-5">
        <table className="table table-vertical-center mb-0">
          <thead>
            <tr>
              <th>Creation Date</th>
              <th>User ID</th>
              <th>OW/RT</th>
              <th>GDS PNR</th>
              <th>Transaction ID</th>
              <th>Due Date</th>
              <th>Payment Method</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "unset" }} className="text-primary">
              <td className="d-flex">
                <span className="mr-2">{date}</span>
                <span>{time}</span>
              </td>
              <td>
                <span>{userId}</span>
              </td>
              <td>
                <span>-</span>
              </td>
              <td>
                <span>{gdsPnr || "-"}</span>
              </td>
              <td>
                <span>{flight?.transactionId || "-"}</span>
              </td>
              <td className={clsx("d-flex", { "text-danger": isDue })}>
                <span className="mr-2">{dueDate}</span>
                <span>{dueTime}</span>
              </td>
              <td>
                <span>{paymentTypeLabel[paymentType]}</span>
              </td>
              <td>{status || bookingStatus}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CardWithBlueHeader>
  );
};
