import React, { useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Seperator } from "../common";
import { actions } from "../../../../redux/state/company";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import { NOTIFICATION_TYPE_SUCCESS } from "../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const PaymentSettings = () => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector(({ company }) => company.edit);
  const { success: getCompanySuccess } = useSelector(
    ({ company }) => company.companyData
  );
  const { pinNumber, ...companyParams } =
    useSelector(({ company }) => company.companyData.data) || {};
  const changePinSchema = Yup.object().shape({
    oldPIN: Yup.string().oneOf([pinNumber, null], "PIN is incorrect"),
    newPIN: Yup.string()
      .required("No new PIN provided")
      .min(4, "PIN is too short - should be 4 chars minimum"),
    confirmNewPIN: Yup.string().oneOf(
      [Yup.ref("newPIN"), null],
      "Passwords must match"
    ),
  });
  useEffect(() => {}, [getCompanySuccess]);

  const formik = useFormik({
    initialValues: { oldPIN: "", newPIN: "", confirmNewPIN: "" },
    validationSchema: changePinSchema,
    onSubmit: ({ newPIN }) => {
      dispatch(actions.editCompany({ pinNumber: newPIN, ...companyParams }));
    },
  });

  const createPinSchema = Yup.object().shape({
    PIN: Yup.string()
      .required("No PIN provided")
      .min(4, "PIN is too short - should be 4 chars minimum"),
    confirmPIN: Yup.string().oneOf(
      [Yup.ref("PIN"), null],
      "Passwords must match"
    ),
  });
  const createPinForm = useFormik({
    initialValues: { PIN: "", confirmPIN: "" },
    validationSchema: createPinSchema,
    onSubmit: ({ PIN }) => {
      dispatch(actions.editCompany({ pinNumber: PIN, ...companyParams }));
    },
  });
  useEffect(() => {
    if (success) {
      formik.resetForm();
      createPinForm.resetForm();
      dispatch(
        actions.setNewPin({
          pin: formik.values.newPIN || createPinForm.values.PIN,
        })
      );
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "PIN changed successfully",
        })
      );
    }
  }, [success]);
  return (
    <Paper className="p-7 bg-white">
      <Typography variant="h5" className="font-weight-bold">
        Payment PIN Settings
      </Typography>
      <Seperator />
      <ol className="pl-4" style={{ color: "#9D9D9D" }}>
        <li>Your PIN shall be 6-digits (Numbers only)</li>
        <li>
          After 3 wrong attempts account will be temporarily locked for 3 hours,
          or contact your Administrator to unlock it.
        </li>
        <li>
          Please contact us if your administrator Is unable to unlock your
          account.
        </li>
      </ol>

      {!!pinNumber ? (
        <form onSubmit={formik.handleSubmit} style={{ maxWidth: "600px" }}>
          <Input
            type="password"
            fixedLabelWidth
            outlined={false}
            spacing
            horizontal
            label="Old PIN"
            placeholder="Enter Old PIN"
            name="oldPIN"
            showErrors={formik.touched.oldPIN}
            errorMessage={formik.errors.oldPIN}
            {...formik.getFieldProps("oldPIN")}
          />
          <Input
            type="password"
            fixedLabelWidth
            outlined={false}
            spacing
            horizontal
            label="New PIN"
            placeholder="Enter New PIN"
            name="newPIN"
            showErrors={formik.touched.newPIN}
            errorMessage={formik.errors.newPIN}
            {...formik.getFieldProps("newPIN")}
          />
          <Input
            type="password"
            fixedLabelWidth
            outlined={false}
            spacing
            horizontal
            label="Confirm New PIN"
            placeholder="Re-Enter PIN"
            name="confirmNewPIN"
            showErrors={formik.touched.confirmNewPIN}
            errorMessage={formik.errors.confirmNewPIN}
            {...formik.getFieldProps("confirmNewPIN")}
          />
          <div className="d-flex justify-content-center">
            <Button loading={loading} type="submit">
              Change PIN
            </Button>
          </div>
        </form>
      ) : (
        <form
          onSubmit={createPinForm.handleSubmit}
          style={{ maxWidth: "600px" }}
        >
          <Input
            type="password"
            fixedLabelWidth
            outlined={false}
            spacing
            horizontal
            label="PIN"
            placeholder="Enter PIN"
            name="PIN"
            showErrors={createPinForm.touched.PIN}
            errorMessage={createPinForm.errors.PIN}
            {...createPinForm.getFieldProps("PIN")}
          />
          <Input
            type="password"
            fixedLabelWidth
            outlined={false}
            spacing
            horizontal
            label="Confirm PIN"
            placeholder="Re-Enter PIN"
            name="confirmPIN"
            showErrors={createPinForm.touched.confirmPIN}
            errorMessage={createPinForm.errors.confirmPIN}
            {...createPinForm.getFieldProps("confirmPIN")}
          />
          <div className="d-flex justify-content-center">
            <Button loading={loading} type="submit">
              Create PIN
            </Button>
          </div>
        </form>
      )}
    </Paper>
  );
};
