import React from "react";
import SVG from "react-inlinesvg";
import { accountTypesLabel } from "../../../../../../redux/state/accounting/accountingConstants";
import { toAbsoluteUrl } from "../../../../../_helpers";

export const AccountTypesRow = ({ data, onEdit }) => {
  const { id, name, type } = data;
  return (
    <tr>
      <td className="text-left text-primary">{name || "-"}</td>
      <td>{accountTypesLabel[type] || "-"}</td>

      <td className="text-right">
        <button
          title=""
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => onEdit(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
      </td>
    </tr>
  );
};
