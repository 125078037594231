export const MODULE_NAME = "booking";

export const PAYMENT_TYPE_WALLET = 1;
export const PAYMENT_TYPE_CARD = 2;
export const PAYMENT_TYPE_WAAFI = 3;
export const PAYMENT_TYPE_PREMIERE = 4;
export const PAYMENT_TYPE_MASTERCARD = 5;

export const paymentTypeLabel = {
  [PAYMENT_TYPE_WALLET]: "Wallet",
  [PAYMENT_TYPE_CARD]: "Card",
  [PAYMENT_TYPE_WAAFI]: "Waafi",
  [PAYMENT_TYPE_PREMIERE]: "Premiere",
};

export const bookingTypesMap = {
  flight: 1,
  package: 2,
  hotel: 3,
};

export const BookingPending = "Pending"; //When Click on Book & Hold or Book & Issue button
export const BookingFailed = "Failed"; //If Payment Successfully Charged & PNR Not Created
export const BookingHold = "Hold"; //If Payment Successfully Charged & PNR Created
export const BookingProcessing = "Processing"; //Incase of Offline Bookings If Requested For Ticket Issue or Incase of Online Bookings If Unable to Issue Ticket or due to any error ticket not issued.
export const BookingIssue = "Issued"; //If Ticket Issued Successfully
export const BookingCancelled = "Cancelled"; //If Ticket is Void
export const BookingRefund = "Refunded"; //if Ticket is Refunded
export const BookingReIssue = "ReIssue"; //if Ticket is ReIssued
export const BookingVoidPending = "VoidPending"; //New        //If Void Pending
export const BookingRefundPending = "RefundPending"; //New    //if Refund Pending
export const BookingReIssuePending = "ReIssuePendning"; //New//if ReIssue Pending

export const ManualBooking = "MB"; //for local use only

export const PAX_TYPE_ADULT = 1;
export const PAX_TYPE_YOUTH = 2;
export const PAX_TYPE_CHILD = 3;
export const PAX_TYPE_INFANT = 4;

export const YOUTH_AGE = 12;
export const CHILD_AGE = 2;

export const pax_types_text = {
  [PAX_TYPE_ADULT]: "Adult",
  [PAX_TYPE_YOUTH]: "Youth",
  [PAX_TYPE_CHILD]: "Child",
  [PAX_TYPE_INFANT]: "Infant",
};
