import { useCallback } from "react";
import usePackageInclusionsList from "./usePackageInclusionsList";

export default function usePkgInclusionName() {
  const { list, loading, success, failure } = usePackageInclusionsList();

  const getInclusionName = useCallback(
    (inclusionId) => list?.find(({ id }) => inclusionId === id)?.name ?? "",
    [list]
  );
  return { getInclusionName, loading, success, failure };
}
