import { Paper, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useExportAccounting, {
  exportAccountingTypesMap,
} from "../../../../../../app/helpers/hooks/useExportAccounting";
import { actions } from "../../../../../../redux/state/bills";
import { Button, Seperator } from "../../../common";
import { PayableDueTable } from "./PayableDueTable";
import { PayableSearchCard } from "./PayableSearchCard";

export const PayableDue = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const { exportToExcel, loading: downloadLoading } = useExportAccounting();
  const { paginationInfo } = useSelector(({ bills }) => bills.bills);
  const { totalPages } = paginationInfo ?? {};
  const { fromDate, toDate } = dateRange;
  useEffect(() => {
    dispatch(
      actions.filterBills({
        filterParam: [
          {
            columnName: "status",
            filterValue: "UnPaid",
            filterOption: 1,
          },
          ...appliedFilters,
        ],
        pageNumber,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);
  const search = (filters) => {
    setAppliedFilters(filters);
    setPageNumber(1);
    dispatch(
      actions.filterBills({
        filterParam: [
          {
            columnName: "status",
            filterValue: "UnPaid",
            filterOption: 1,
          },
          ...filters,
        ],
      })
    );
  };
  const onExport = () => {
    exportToExcel({
      type: exportAccountingTypesMap.bills,
      filterParam: [
        {
          columnName: "status",
          filterValue: "UnPaid",
          filterOption: 1,
        },
        ...appliedFilters,
      ],
      sortingParams: [
        {
          sortOrder: 1,
          columnName: "CreatedAt",
        },
      ],
    });
  };
  const reset = () => {
    setAppliedFilters([]);
    if (pageNumber === 1) {
      dispatch(
        actions.filterBills({
          filterParam: [
            {
              columnName: "status",
              filterValue: "UnPaid",
              filterOption: 1,
            },
          ],
        })
      );
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <PayableSearchCard
            onReset={reset}
            setDateRange={setDateRange}
            onSearch={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Typography variant="h6" className="font-weight-bold">
                Payable Due
              </Typography>
              {fromDate && toDate && (
                <Typography>
                  Date: <b>{fromDate}</b> to: <b>{toDate}</b>
                </Typography>
              )}
            </div>
            <Button
              onClick={onExport}
              loading={downloadLoading}
              variant="danger"
            >
              Export
            </Button>
          </div>

          <Seperator />
          <PayableDueTable />
          <div className="d-flex justify-content-center">
            <Pagination
              className="mx-auto mt-2"
              onChange={handlePageChange}
              page={pageNumber}
              count={totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
