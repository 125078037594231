import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import clsx from "clsx";

import useCompanyName from "../../../../../../app/helpers/hooks/useCompanyName";
import { LoadingSpinner } from "../../../common";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

const dailySumInitialState = {
  day1Sum: 0,
  day2Sum: 0,
  day3Sum: 0,
  day4Sum: 0,
  day5Sum: 0,
  day6Sum: 0,
  day7Sum: 0,
};

export const SalesDashboardTable = ({ list, loading }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { getCompanyName, allCompaniesLoading } = useCompanyName();
  const [daySumMap, setDaySumMap] = useState(dailySumInitialState);
  const tableContent = list?.map((item, index) => (
    <TableRow
      getCompanyName={getCompanyName}
      key={`row_${index}`}
      data={item}
    />
  ));
  useEffect(() => {
    if (list) {
      setDaySumMap(dailySumInitialState);
      list.forEach(
        ({
          day1Count,
          day2Count,
          day3Count,
          day4Count,
          day5Count,
          day6Count,
          day7Count,
        }) => {
          setDaySumMap(
            ({
              day1Sum,
              day2Sum,
              day3Sum,
              day4Sum,
              day5Sum,
              day6Sum,
              day7Sum,
            }) => ({
              day1Sum: day1Sum + day1Count,
              day2Sum: day2Sum + day2Count,
              day3Sum: day3Sum + day3Count,
              day4Sum: day4Sum + day4Count,
              day5Sum: day5Sum + day5Count,
              day6Sum: day6Sum + day6Count,
              day7Sum: day7Sum + day7Count,
            })
          );
        }
      );
    }
  }, [list]);
  const {
    day1Sum,
    day2Sum,
    day3Sum,
    day4Sum,
    day5Sum,
    day6Sum,
    day7Sum,
  } = daySumMap;
  return (
    <div className="table-responsive">
      {loading || allCompaniesLoading ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-vertical-center mb-0 mt-0">
          <thead>
            <tr className="bg-gray-100">
              <th>Client Name</th>
              {matchesSm && (
                <>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Saturday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day1Sum > 0,
                          "text-danger": day1Sum === 0,
                        })}
                      >
                        ({day1Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Sunday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day2Sum > 0,
                          "text-danger": day2Sum === 0,
                        })}
                      >
                        ({day2Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Monday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day3Sum > 0,
                          "text-danger": day3Sum === 0,
                        })}
                      >
                        ({day3Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Tuesday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day4Sum > 0,
                          "text-danger": day4Sum === 0,
                        })}
                      >
                        ({day4Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Wednesday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day5Sum > 0,
                          "text-danger": day5Sum === 0,
                        })}
                      >
                        ({day5Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Thursday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day6Sum > 0,
                          "text-danger": day6Sum === 0,
                        })}
                      >
                        ({day6Sum})
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="d-flex flex-column justify-content-center align-items-center">
                      Friday
                      <br />
                      <span
                        className={clsx({
                          "text-primary": day7Sum > 0,
                          "text-danger": day7Sum === 0,
                        })}
                      >
                        ({day7Sum})
                      </span>
                    </span>
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      )}
      {list?.length === 0 && (
        <Typography variant="h5" className="text-center w-100 text-muted my-5">
          No Records to show
        </Typography>
      )}
    </div>
  );
};

const TableRow = ({ data, getCompanyName }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    agencyId,
    day1Count,
    day2Count,
    day3Count,
    day4Count,
    day5Count,
    day6Count,
    day7Count,
  } = data;
  const agencyName = getCompanyName(agencyId);
  const rows = (
    <>
      <td className="font-weight-bold text-primary">
        <span>{agencyName}</span>
      </td>
      {matchesSm && (
        <>
          <td className="text-center font-weight-bold">
            <span>{day1Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day2Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day3Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day4Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day5Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day6Count}</span>
          </td>
          <td className="text-center font-weight-bold">
            <span>{day7Count}</span>
          </td>
        </>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Saturday",
      value: day1Count,
    },
    {
      label: "Sunday",
      value: day2Count,
    },
    {
      label: "Monday",
      value: day3Count,
    },
    {
      label: "Tuesday",
      value: day4Count,
    },
    {
      label: "Wednesday",
      value: day5Count,
    },
    {
      label: "Thursday",
      value: day6Count,
    },
    {
      label: "Friday",
      value: day7Count,
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
