import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { adminRoutesPermissionMap } from "../../../../../../../app/AdminRoutes";
import { ADMIN_STAFF_ROLES } from "../../../../../../../app/routesMapAdmin";
import { Permissions } from "../../../../account/accountSubUser/GivePermissions";
import { GoBack } from "../../../../common";

export const StaffRolePermissions = () => {
  const { id } = useParams();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7">
          <GoBack />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <Typography variant="h5" className="mb-5">
            Role Permissions
          </Typography>
          <Permissions
            roleId={id}
            directLink={ADMIN_STAFF_ROLES}
            permissions={adminRoutesPermissionMap}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
