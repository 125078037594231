import React, { useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input, LoadingSpinner } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/accountBalance";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const UpdateCompanyBalance = ({ show, data, onHide, isRequest }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector(({ auth }) => auth);
  const {
    data: accountBalanceData,
    loading: accountBalanceLoading,
  } = useSelector(({ accountBalance }) => accountBalance.balance);
  const { availableBalance } = accountBalanceData || {};
  const dataLoading = accountBalanceLoading;
  const {
    loading: updateCompanyBalanceLoading,
    success: updateCompanyBalanceSuccess,
    failure: updateCompanyBalanceFailure,
  } = useSelector(({ accountBalance }) => accountBalance.updateCompanyBalance);
  const { id, name } = data || {};
  useEffect(() => {
    return () => dispatch(actions.clearUpdateCompanyBalance());
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      dispatch(actions.getAccountBalance({ companyId: id }));
    }
  }, [dispatch, id]);

  const schema = Yup.object().shape({
    newBalance: Yup.number().required("Please enter New Balance first"),
  });
  const fields = useFormik({
    initialValues: {
      newBalance: "",
      reason: "",
    },
    validationSchema: schema,
    onSubmit: ({ newBalance, reason }) => {
      dispatch(
        actions.updateCompanyBalance({
          companyId: id,
          updatedBy: +userId,
          updatedBalance: +newBalance,
          reason,
        })
      );
    },
  });
  const { getFieldProps, touched, errors, handleSubmit, resetForm } = fields;
  const hideModal = useCallback(() => {
    onHide();
    resetForm();
  }, [onHide, resetForm]);
  useEffect(() => {
    if (updateCompanyBalanceSuccess) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Company Balance Updated Successfully",
        })
      );
      hideModal();
      dispatch(actions.clearUpdateCompanyBalance());
    }
  }, [updateCompanyBalanceSuccess, dispatch, hideModal, resetForm]);
  useEffect(() => {
    if (updateCompanyBalanceFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: updateCompanyBalanceFailure || "Something is wrong!",
        })
      );
      dispatch(actions.clearUpdateCompanyBalance());
    }
  }, [updateCompanyBalanceFailure, dispatch]);

  return (
    <Modal
      size="md"
      onHide={hideModal}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {isRequest ? "Request Credit" : "Allocate Credit"}
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {dataLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Grid container className="mb-7" spacing={3}>
                <Grid item xs className="d-flex align-items-center">
                  <Typography variant="h5" className="mb-3">
                    {name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className="bg-white border rounded border-dark p-3 min-w-200px text-center h-100 d-flex flex-column justify-content-center"
                >
                  <Typography variant="h5" className="mb-3">
                    <span className="text-primary">$</span>
                    {availableBalance || "0.0"}
                  </Typography>
                  <Typography variant="h6" className="mb-2">
                    Available Balance
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    {...getFieldProps("newBalance")}
                    showErrors={touched["newBalance"]}
                    errorMessage={errors["newBalance"]}
                    variant="secondary"
                    label="New Balance"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    {...getFieldProps("reason")}
                    showErrors={touched["reason"]}
                    errorMessage={errors["reason"]}
                    variant="secondary"
                    label="Reason"
                    optional
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            smallPadding
            type="button"
            onClick={onHide}
            variant="secondary"
          >
            Cancel
          </Button>
          <> </>
          <Button
            loading={updateCompanyBalanceLoading}
            smallPadding
            type="submit"
            disabled={dataLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
