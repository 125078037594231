import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { LoadingSpinner, Seperator } from "../../../../common";
import useAirportName from "../../../../../../../app/helpers/hooks/useAirportName";
import {
  calcTimeDiff,
  convertMinToHrs,
  formatTo12Hrs,
  toAbsoluteUrl,
} from "../../../../../../_helpers";
import styles from "../../../../fareSearch/FlightSearch.module.scss";
import useAirlineName from "../../../../../../../app/helpers/hooks/useAirlineName";
import { mapKeys, camelCase } from "lodash-es";
import { OrderPriceCard } from "../../../bookingMng/issuedOrderDetails/orderSummary/OrderPriceCard";

export const RouteBookings = () => {
  const [expanded, setExpanded] = useState();
  const { getAirportName } = useAirportName();
  const { getAirlineInfo } = useAirlineName();
  const { list, loading } = useSelector(
    ({ accounting }) => accounting.routeDetails
  );
  const handlePanelChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };
  const content = useMemo(
    () =>
      list?.map((data, index) => {
        const { SegmentDetails, PassengerDetails, SubTotal, Discount } = data;
        const { SegmentDetailsData, totalDuration } = SegmentDetails[0];
        const {
          DTime: firstDTime,
          DDat: firstDDat,
          DApt,
        } = SegmentDetailsData[0];
        const { AApt, ATime: lastATime, DDat: lastDDate } = SegmentDetailsData[
          SegmentDetailsData.length - 1
        ];
        const airline = SegmentDetailsData[0].AirlineNM;
        const duration = convertMinToHrs(parseInt(totalDuration));
        // const { generalInfo, paxDetails } = searchRQ;
        // const { adult, youth, child, infant } = paxDetails;
        // const tripTypeText = {
        //   O: "One way",
        //   R: "Round",
        //   M: "Multi City",
        // };
        const airlineInfo = getAirlineInfo(airline);
        const camelPassengerDetails = PassengerDetails.map((passenger) =>
          mapKeys(passenger, (v, k) => camelCase(k))
        );
        const bookingData = {
          totalPrice: SubTotal,
          discount: Discount,
          passengers: camelPassengerDetails,
        };
        return (
          <ExpansionPanel
            expanded={expanded === index}
            onChange={handlePanelChange(index)}
            elevation={0}
            key={`row${index}`}
          >
            <ExpansionPanelSummary
              style={{ display: "inline-block" }}
              className="min-w-100"
            >
              <div className="d-flex justify-content-between min-w-100">
                <div className="w-300px">
                  <div className="d-flex justify-content-start">
                    <div className="symbol text-center symbol-45px mr-5">
                      <img
                        src={airlineInfo?.logo}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = toAbsoluteUrl(
                            "/media/svg/icons/General/plane-default.svg"
                          );
                        }}
                        alt=""
                      />
                      {/* <span className="text-muted font-size-sm">{`${gds[0]}${gds[1]}`}</span> */}
                    </div>
                    <div>
                      <div className="flex-row">
                        <span className="text-dark font-weight-bolder font-size-6 mr-2">
                          <span className="font-weight-bolder">
                            {firstDDat.split("T")[0]}{" "}
                            {formatTo12Hrs(firstDTime)}
                          </span>{" "}
                        </span>
                        <span className="mr-2">{"-"}</span>

                        <span className="text-dark font-weight-bolder font-size-6 ">
                          {lastDDate.split("T")[0]} {formatTo12Hrs(lastATime)}
                        </span>
                      </div>
                      <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                        {airlineInfo?.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-100px">
                  <div className="d-flex justify-content-start flex-column">
                    <span className="text-dark font-weight-bolder font-size-6 mr-2">
                      {duration}
                    </span>
                    <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                      {DApt}-{AApt}
                    </span>
                  </div>
                </div>
                {/* <div className="w-120px">
              {+stop === 0 ? (
                <span className="text-success font-weight-bolder font-size-6">
                  Direct
                </span>
              ) : (
                <div className="d-flex justify-content-start flex-column">
                  <span className="text-dark font-weight-bolder font-size-6 mr-2">
                    {stop} Stops
                  </span>
                  <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                    {calcTimeDiff(
                      firstADate,
                      SecondDDate,
                      firstATime,
                      secondDTime
                    )}{" "}
                    {getAirportName(SegmentDetailsData[0].AApt) ||
                      SegmentDetailsData[0].AApt}
                  </span>
                </div>
              )}
            </div> */}
                <div className="w-100px">
                  <div className="d-flex justify-content-start flex-column">
                    {/* <div className="flex-row">
                    <span className="text-success font-weight-bolder font-size-6 mr-2">
                      {totalAmt}$
                    </span>
                  </div> */}
                    <span className="text-muted font-weight-bold text-muted d-block font-size-7">
                      {/* {tripTypeText[generalInfo.tripType]} */}
                    </span>
                  </div>
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={"d-flex justify-content-between"}>
              <div>
                {SegmentDetails?.map(
                  ({ SegmentDetailsData, totalDuration, airCraft }, index) => (
                    <div key={`segment${index}`}>
                      {index > 0 && (
                        <>
                          <Seperator />
                          <Seperator />
                        </>
                      )}
                      {SegmentDetailsData?.map(
                        (
                          {
                            DApt,
                            DTime,
                            SegmentDuration,
                            FN,
                            ma,
                            ATime,
                            AApt,
                            BC,
                            FBA,
                            DDat,
                            ADat,
                            AirlineNM,
                          },
                          index
                        ) => (
                          <div
                            className="min-w-400px"
                            key={`SegmentDetailsData${index}`}
                          >
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="symbol symbol-45px mr-5 mb-8">
                                <img
                                  src={getAirlineInfo(AirlineNM)?.logo}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = toAbsoluteUrl(
                                      "/media/svg/icons/General/plane-default.svg"
                                    );
                                  }}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div
                                  className={`d-flex flex-column ${styles.specialBorder} h-100px justify-content-between`}
                                >
                                  <span className="text-dark  font-size-6 mr-2">
                                    <span className="font-weight-bolder">
                                      {DDat.split("T")[0]}{" "}
                                      {formatTo12Hrs(DTime)}
                                    </span>{" "}
                                    {getAirportName(DApt) || DApt}
                                  </span>
                                  <span className="text-muted font-weight-bold text-muted d-block ">
                                    Travel time:
                                    {/* {calcTimeDiff(DDat, ADat, DTime, ATime)} */}
                                    {convertMinToHrs(SegmentDuration)}
                                  </span>
                                  <span className="text-dark  font-size-6 mr-2">
                                    <span className="font-weight-bolder">
                                      {ADat.split("T")[0]}{" "}
                                      {formatTo12Hrs(ATime)}
                                    </span>{" "}
                                    {getAirportName(AApt) || AApt}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <li className="d-flex align-items-center py-2 ml-2">
                                    <span className="bullet bullet-dot bg-primary mr-2"></span>
                                    {/* <span className="text-primary">
                                  {cabinClass}
                                </span> */}
                                  </li>
                                  <li className="d-flex align-items-center py-2 ml-2">
                                    <span className="bullet bullet-dot bg-primary mr-2"></span>
                                    <span className="text-primary">{FBA}</span>
                                  </li>
                                  <li className="d-flex align-items-center py-2 ml-2">
                                    <span className="bullet bullet-dot bg-primary mr-2"></span>
                                    <span className="text-primary">
                                      Class type {BC}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center py-2 ml-2">
                                    <span className="bullet bullet-dot bg-primary mr-2"></span>
                                    <span className="text-primary">{FN}</span>
                                  </li>
                                </div>
                              </div>
                            </div>
                            {index < SegmentDetailsData.length - 1 && (
                              <>
                                <Seperator />
                                <div>
                                  <div className="separator border-4 my-5" />
                                  <span className="label label-light-warning mr-3">
                                    !
                                  </span>
                                  <span>
                                    {calcTimeDiff(
                                      ADat.split("T")[0],
                                      SegmentDetailsData[index + 1].DDat.split(
                                        "T"
                                      )[0],
                                      ATime,
                                      SegmentDetailsData[index + 1].DTime
                                    )}{" "}
                                    {getAirportName(AApt) || AApt}
                                  </span>
                                  <div className="separator border-4 my-5" />
                                </div>
                                <Seperator />
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
              <div className="card card-custom shadow-sm min-w-300px p-5">
                <OrderPriceCard data={bookingData} isBookingDetails />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      }),
    [expanded, getAirlineInfo, getAirportName, list]
  );
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {content}
          {list?.length === 0 && (
            <Typography
              variant="h5"
              className="text-center w-100 text-muted my-5"
            >
              No Bookings to show
            </Typography>
          )}
        </>
      )}
    </div>
  );
};
