import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Content } from "./Content";
import { AccessDeniedPage } from "../../../../app/pages/public/accessDeniedPage/AccessDeniedPage";
import { usePermissionIds } from "../../../../app/helpers/hooks/usePermissionIds";
import { SubscriptionExpired } from "../account/Subscription/SubscriptionExpired";

export function ContentRoute({
  children,
  permissionKey,
  component,
  render,
  ...props
}) {
  const { isAdmin, subscription } = useSelector(
    ({ company }) => company.companyData
  );
  const { getPermissionObj } = usePermissionIds({
    isAdminPermissions: isAdmin,
  });
  const { list } = useSelector(({ user }) => user.currentUserPermissions);
  const permissionIdsList = list.map(({ permissionId }) => permissionId);
  const { id: permissionId } = getPermissionObj(permissionKey);
  return (
    <Route {...props}>
      {(routeProps) => {
        if (subscription && !subscription?.isActive) {
          return <SubscriptionExpired />;
        }
        if (!(permissionIdsList.includes(permissionId) || permissionId === 0)) {
          return <AccessDeniedPage />;
        }
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>
              {React.createElement(component, {
                ...routeProps,
                permissionKey,
                permissionId,
              })}
            </Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
