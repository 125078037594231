import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useQuery from "../../../../app/helpers/hooks/useQuery";
import { SIGN_IN } from "../../../../app/routesMap";
import { Button, TextFieldContainer } from "../common";
import { Wrapper } from "./Wrapper";
import { actions } from "../../../../redux/state/auth";
import { actions as inlineNotificationActions } from "../../../../redux/state/inlineNotification";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../redux/state/inlineNotification/inlineNotificationConstants";

export const NewPassword = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, success, failure } = useSelector(
    ({ auth }) => auth.resetPassword
  );
  const key = query.get("key");
  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(),
  });

  const formFields = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: LoginSchema,
    onSubmit: ({ password }) => {
      dispatch(actions.restPassword({ password, key }));
      // history.push(HOME);
    },
  });
  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);
  useEffect(() => {
    if (success) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Password reset successfully",
        })
      );
      history.replace(SIGN_IN);
      dispatch(actions.reset());
      formFields.resetForm();
    }
  }, [dispatch, formFields, history, success]);
  useEffect(() => {
    if (failure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: "Something is wrong!",
        })
      );
      dispatch(actions.reset());
    }
  }, [dispatch, failure]);
  return (
    <Wrapper>
      <div className="mb-10">
        <Typography
          variant="h5"
          className="font-weight-bolder text-dark text-white"
        >
          Reset your password
        </Typography>
      </div>
      <form onSubmit={formFields.handleSubmit}>
        <TextFieldContainer
          vertical
          label="New Password"
          showErrors={formFields.touched.password}
          errorMessage={formFields.errors.password}
          inputProps={formFields.getFieldProps("password")}
          variant="secondary"
          type="password"
        />
        <TextFieldContainer
          vertical
          label="Confirm Password"
          showErrors={formFields.touched.confirmPassword}
          errorMessage={formFields.errors.confirmPassword}
          inputProps={formFields.getFieldProps("confirmPassword")}
          variant="secondary"
          type="password"
        />
        <Button loading={loading} type="submit">
          Submit
        </Button>
      </form>
    </Wrapper>
  );
};
