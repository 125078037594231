import clsx from "clsx";
import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { getDateTime } from "../../../../../_helpers/date";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY } from "../../../../../../app/routesMapAdmin";
import { adminPermissionsIds } from "../../../../../../data/adminPermissionIds";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ResponsiveRow } from "../../../common/ResponsiveRow/ResponsiveRow";

export const BankAccountRow = ({
  subPermissions,
  data,
  onDelete,
  onEdit,
  onWithdraw,
  onDeposit,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    id,
    banks,
    createdAt,
    currency,
    accountHolderName,
    accountNo,
    isActive,
    availableBalance,
  } = data;
  // const { balance } = bank_account_balance || {};
  const { name: bankName, country } = banks || {};
  const { time, date } = getDateTime(createdAt);
  const allBankAccountsSubPermission =
    adminPermissionsIds.payment.bankAccounts.subPermissions;
  const rows = (
    <>
      <td>
        <NavLink
          to={parseRoute(ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY, { id })}
          className="btn text-hover-primary"
        >
          {accountNo || "-"}
        </NavLink>
      </td>
      {matchesSm && (
        <>
          <td className="text-left">{country || "-"}</td>
          <td>{currency || "-"}</td>
        </>
      )}
      <td>{bankName || "-"}</td>
      {matchesSm && <td>{accountHolderName || "-"}</td>}
      <td
        className={clsx({ "text-success": isActive, "text-danger": !isActive })}
      >
        {isActive ? "Active" : "Inactive"}
      </td>
      {matchesSm && (
        <>
          <td>
            <div>
              <p className="mb-0">{date}</p>
              <p className="text-muted font-weight-normal mb-0">{time}</p>
            </div>
          </td>
          <td>
            <span className="text-primary">{availableBalance?.toFixed(2)}</span>
          </td>

          <td className="text-right">
            <button
              hidden={
                !subPermissions.includes(
                  allBankAccountsSubPermission.deposit.id
                )
              }
              title="Deposit"
              className="btn btn-icon btn-light btn-hover-success btn-xs"
              onClick={() => onDeposit(id)}
            >
              <span className="svg-icon svg-icon-success svg-icon-sm">
                <SVG
                  title="Deposit"
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
                />
              </span>
            </button>
            <button
              hidden={
                !subPermissions.includes(
                  allBankAccountsSubPermission.withdraw.id
                )
              }
              title="withdraw"
              className="btn btn-icon btn-light btn-xs mx-3"
              onClick={() => onWithdraw(id)}
            >
              <span className="svg-icon svg-icon-sm">
                <SVG
                  title="withdraw"
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
                />
              </span>
            </button>
            <button
              hidden={
                !subPermissions.includes(allBankAccountsSubPermission.edit.id)
              }
              title="Edit"
              className="btn btn-icon btn-light btn-hover-primary btn-xs"
              onClick={() => onEdit(id)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-primary">
                <SVG
                  title="Edit"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </button>
            <button
              hidden={
                !subPermissions.includes(allBankAccountsSubPermission.delete.id)
              }
              title="Delete"
              className="btn btn-icon btn-light btn-hover-danger btn-xs ml-3"
              onClick={() => onDelete(id)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-danger">
                <SVG
                  title="Delete"
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </button>
          </td>
        </>
      )}
    </>
  );
  const mobileRowDetails = [
    {
      label: "Account Number",
      value: (
        <NavLink to={parseRoute(ADMIN_PAYMENTS_BANK_ACCOUNT_HISTORY, { id })}>
          {accountNo || "-"}
        </NavLink>
      ),
    },
    {
      label: "Country",
      value: country || "-",
    },
    {
      label: "Currency",
      value: currency || "-",
    },
    {
      label: "Bank Name",
      value: bankName || "-",
    },
    {
      label: "Account Holder Name",
      value: accountHolderName || "-",
    },
    {
      label: "Status",
      value: (
        <span
          className={clsx("label label-lg label-inline", {
            "label-light-success": isActive,
            "label-light-danger": !isActive,
          })}
        >
          {isActive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      label: "Created At",
      value: `${date} ${time}`,
    },
    {
      label: "Available Balance",
      value: (
        <span className="text-primary">{availableBalance?.toFixed(2)}</span>
      ),
    },
    {
      label: "Actions",
      value: (
        <div>
          <button
            hidden={
              !subPermissions.includes(allBankAccountsSubPermission.deposit.id)
            }
            title="Deposit"
            className="btn btn-icon btn-light btn-hover-success btn-xs"
            onClick={() => onDeposit(id)}
          >
            <span className="svg-icon svg-icon-success svg-icon-sm">
              <SVG
                title="Deposit"
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
          </button>
          <button
            hidden={
              !subPermissions.includes(allBankAccountsSubPermission.withdraw.id)
            }
            title="withdraw"
            className="btn btn-icon btn-light btn-xs mx-3"
            onClick={() => onWithdraw(id)}
          >
            <span className="svg-icon svg-icon-sm">
              <SVG
                title="withdraw"
                src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
              />
            </span>
          </button>
          <button
            hidden={
              !subPermissions.includes(allBankAccountsSubPermission.edit.id)
            }
            title="Edit"
            className="btn btn-icon btn-light btn-hover-primary btn-xs"
            onClick={() => onEdit(id)}
          >
            <span className="svg-icon svg-icon-sm svg-icon-primary">
              <SVG
                title="Edit"
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
          <button
            hidden={
              !subPermissions.includes(allBankAccountsSubPermission.delete.id)
            }
            title="Delete"
            className="btn btn-icon btn-light btn-hover-danger btn-xs ml-3"
            onClick={() => onDelete(id)}
          >
            <span className="svg-icon svg-icon-sm svg-icon-danger">
              <SVG
                title="Delete"
                src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
              />
            </span>
          </button>
        </div>
      ),
    },
  ];
  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};
