import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { SearchCard } from "./common/SearchCard";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner, Tabs } from "../common";
import { actions } from "../../../../redux/state/bookingMng";
import {
  ALL,
  orderFilterTypesMap,
  RefundApplied,
  RefundApproved,
  RefundCompleted,
  RefundProcessing,
  RefundQuotationCompleted,
  RefundQuotationRequest,
  RefundRejected,
  RefundWithdraw,
  sortTypesMap,
} from "../../../../redux/state/bookingMng/bookingMngConstants";
import { NavLink } from "react-router-dom";
import { parseRoute } from "../../../../app/helpers/formatters";
import { BOOKING_MANAGEMENT_REFUND_DETAILS } from "../../../../app/routesMap";
import { getDateTime } from "../../../_helpers";
import { filterOptions } from "../../../../redux/state/invoices/invoicesConstants";
import { Pagination } from "@material-ui/lab";
import { ResponsiveRow } from "../common/ResponsiveRow/ResponsiveRow";
import { useMemo } from "react";

export const refundBadgeText = {
  [RefundQuotationCompleted]: "Quotation Completed",
  [RefundQuotationRequest]: "Quotation Requested",
  [RefundApplied]: "Refund Applied",
  [RefundProcessing]: "Refund Processing",
  [RefundApproved]: "Approved",
  [RefundCompleted]: "Completed",
  [RefundRejected]: "Rejected",
  [RefundWithdraw]: "Withdrawn",
};
export const refundBadgeClassNames = {
  [RefundApproved]: "text-success",
  [RefundCompleted]: "text-success",
  [RefundQuotationCompleted]: "text-warning",
  [RefundQuotationRequest]: "text-warning",
  [RefundApplied]: "text-warning",
  [RefundProcessing]: "text-warning",
  [RefundRejected]: "text-danger",
  [RefundWithdraw]: "text-danger",
};
export const TableRow = ({ data }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { transaction, createdDate, refundAmount, status, id } = data;
  const { date: departDate, time: departTime } = getDateTime(
    transaction?.flight?.departureDateTime
  );
  const { date: createDate, time: createTime } = getDateTime(createdDate);
  const rows = (
    <>
      <td>
        <NavLink
          className="btn text-hover-primary p-0"
          to={parseRoute(BOOKING_MANAGEMENT_REFUND_DETAILS, { id })}
        >
          <span className="font-weight-bold">{transaction?.orderNo}</span>
        </NavLink>
      </td>
      {matchesSm && (
        <td>
          <span className="text-dark-75 font-weight-bold d-block font-size-lg">
            {createDate}
          </span>
          <span className="text-muted font-weight-bold">{createTime}</span>
        </td>
      )}
      <td>
        <span className="font-weight-bold">
          {transaction?.passengers?.map(
            ({ firstName, lastName }) => `${firstName} ${lastName}`
          ) || "no passengers Data"}
        </span>
      </td>
      {matchesSm && (
        <>
          <td>
            <span className="font-weight-bold">
              {transaction?.flight?.flightNumber || "-"}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bold d-block font-size-lg">
              {departDate}
            </span>
            <span className="text-muted font-weight-bold">{departTime}</span>
          </td>
          <td>
            <span className="font-weight-bold">
              {transaction?.gdsPnr || "-"}
            </span>
          </td>
          <td>
            <span className="font-weight-bold">
              ${transaction?.totalPrice || "-"}
            </span>
          </td>
          <td>
            <span className="font-weight-bold">${refundAmount || "-"}</span>
          </td>
        </>
      )}
      <td>
        <span className={refundBadgeClassNames[status]}>
          {refundBadgeText[status] || "-"}
        </span>
      </td>
    </>
  );
  const mobileRowDetails = [
    { label: "Flight No", value: transaction?.flight?.flightNumber },
    { label: "Flight Date", value: `${departDate} ${departTime}` },
    { label: "GDS PNR ", value: transaction?.gdsPnr },
    { label: "Total Price", value: transaction?.totalPrice },
    { label: "Refund Price", value: refundAmount },
  ];

  return matchesSm ? (
    <tr>{rows}</tr>
  ) : (
    <ResponsiveRow detailsData={mobileRowDetails}>{rows}</ResponsiveRow>
  );
};

export const RefundTracking = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [secondSearchValue, setSecondSearchValue] = useState("");
  const [filter, setFilter] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([{}]);
  const [statusFilter, setStatusFilter] = useState(ALL);
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  const { companyInUserId } = useSelector(({ user }) => user.userData);
  const { list, loading, paginationInfo, statusCounts } = useSelector(
    ({ bookingMng }) => bookingMng.orders
  );

  const { totalPages } = paginationInfo ?? {};
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setSearchValue("");
    setSecondSearchValue("");
  }, [filter]);
  useEffect(() => {
    setPageNumber(1);
  }, []);
  useEffect(() => {
    const sortingParams = [
      { columnName: "createdDate", sortOrder: sortTypesMap.descending },
    ];
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "status",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.refund,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          pageNumber,
          sortingParams,
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            ...(statusFilter === ALL
              ? []
              : [
                  {
                    columnName: "status",
                    filterValue: statusFilter,
                    filterOption: filterOptions.IsEqualTo,
                  },
                ]),
            ...appliedFilters,
          ],
          type: orderFilterTypesMap.refund,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInUserId, dispatch, isAdmin, pageNumber, statusFilter]);

  const handleChange = (e, isSecond) => {
    if (isSecond) {
      setSecondSearchValue(e?.target?.value || e);
    } else {
      setSearchValue(e?.target?.value || e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setPageNumber(1);
    let filtersParam = [{}];
    if (filter === "createdAt") {
      filtersParam = [
        {
          columnName: "createdDate",
          filterValue: searchValue,
          filterOption: filterOptions.IsGreaterThanOrEqualTo,
        },
        {
          columnName: "createdDate",
          filterValue: secondSearchValue,
          filterOption: filterOptions.IsLessThanOrEqualTo,
        },
      ];
    } else {
      filtersParam = [
        {
          columnName: filter,
          filterValue: `${searchValue}`,
          filterOption: filterOptions.IsEqualTo,
        },
      ];
    }
    setAppliedFilters(filtersParam);
    if (isAdmin) {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [...filtersParam],
          type: orderFilterTypesMap.refund,
        })
      );
    } else {
      dispatch(
        actions.filterOrdersPagination({
          filterParam: [
            {
              columnName: "agencyId",
              filterValue: `${companyInUserId}`,
              filterOption: filterOptions.IsEqualTo,
            },
            ...filtersParam,
          ],
          type: orderFilterTypesMap.refund,
        })
      );
    }
  };

  const reset = () => {
    setSearchValue("");
    setSecondSearchValue("");
    setStatusFilter(ALL);
    setAppliedFilters([{}]);

    if (pageNumber === 1) {
      if (isAdmin) {
        dispatch(
          actions.filterOrdersPagination({
            type: orderFilterTypesMap.refund,
          })
        );
      } else {
        dispatch(
          actions.filterOrdersPagination({
            filterParam: [
              {
                columnName: "agencyId",
                filterValue: `${companyInUserId}`,
                filterOption: filterOptions.IsEqualTo,
              },
            ],
            type: orderFilterTypesMap.refund,
          })
        );
      }
    } else {
      setPageNumber(1);
    }
  };
  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };
  const tableContent = list?.map((item, index) => (
    <TableRow key={`row_${index}`} data={item} />
  ));
  const adminTabsData = useMemo(() => {
    const {
      all,
      applied,
      approved,
      completed,
      processing,
      quoteCompleted,
      quoteRequested,
      rejected,
      withdrawn,
    } = statusCounts ?? {};
    return [
      { title: `ALL (${all || 0})`, filter: ALL },
      {
        title: `Quotation Request (${quoteRequested || 0})`,
        filter: RefundQuotationRequest,
      },
      {
        title: `Quotation Completed (${quoteCompleted || 0})`,
        filter: RefundQuotationCompleted,
      },
      { title: `Refund Applied (${applied || 0})`, filter: RefundApplied },
      {
        title: `Refund Processing (${processing || 0})`,
        filter: RefundProcessing,
      },
      { title: `Refund Approved (${approved || 0})`, filter: RefundApproved },
      {
        title: `Refund Completed (${completed || 0})`,
        filter: RefundCompleted,
      },
      { title: `Refund Rejected (${rejected || 0})`, filter: RefundRejected },
      { title: `Refund Withdrawn (${withdrawn || 0})`, filter: RefundWithdraw },
    ];
  }, [statusCounts]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <SearchCard
            setFilter={setFilter}
            onChange={handleChange}
            value={searchValue}
            onSubmit={handleSubmit}
            reset={reset}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Paper className="bg-white p-4">
          <Tabs
            data={isAdmin ? adminTabsData : agencyTabsData}
            setFilter={setStatusFilter}
            noContent
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-vertical-center mb-0 mt-5">
                  <thead>
                    <tr className="bg-gray-100">
                      <th>Order No</th>
                      {matchesSm && <th>Requested Time</th>}
                      <th>Passengers</th>
                      {matchesSm && (
                        <>
                          <th>Flight No</th>
                          <th>Flight Date</th>
                          <th>GDS PNR </th>
                          <th>Total Price</th>
                          <th>Refund Price</th>
                        </>
                      )}
                      <th>Status</th>
                      {/* <th>Operator</th> */}
                    </tr>
                  </thead>
                  <tbody>{tableContent}</tbody>
                </table>
                {list?.length === 0 && (
                  <Typography
                    variant="h5"
                    className="text-center w-100 text-muted my-5"
                  >
                    No Refunds to show
                  </Typography>
                )}
                <div className="d-flex justify-content-center">
                  <Pagination
                    className="mx-auto mt-2"
                    onChange={handlePageChange}
                    page={pageNumber}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              </div>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const agencyTabsData = [
  { title: "ALL ", filter: ALL },
  {
    title: "Quotation Request",
    filter: RefundQuotationRequest,
  },
  {
    title: "Quotation Completed",
    filter: RefundQuotationCompleted,
  },
  { title: "Refund Applied", filter: RefundApplied },
  {
    title: "Refund Processing",
    filter: RefundProcessing,
  },
  { title: "Refund Approved", filter: RefundApproved },
  {
    title: "Refund Completed",
    filter: RefundCompleted,
  },
  { title: "Refund Rejected", filter: RefundRejected },
  { title: "Refund Withdrawn", filter: RefundWithdraw },
];
