import React, { useCallback, useEffect, useMemo } from "react";
import { Grid, Paper, Typography } from "@material-ui/core/";

import { FlightDetailsForm } from "../../../fareSearch/manualBooking/FlightDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../../redux/state/bookingMng";
import { actions as inlineNotificationActions } from "../../../../../../redux/state/inlineNotification";
import { useHistory, useParams } from "react-router-dom";
import { LoadingSpinner } from "../../../common";
import { parseRoute } from "../../../../../../app/helpers/formatters";
import { BOOKING_MANAGEMENT_REISSUE_DETAILS } from "../../../../../../app/routesMap";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../../../../../redux/state/inlineNotification/inlineNotificationConstants";
import {
  BookingHold,
  PAX_TYPE_ADULT,
  PAX_TYPE_CHILD,
  PAX_TYPE_INFANT,
  PAX_TYPE_YOUTH,
} from "../../../../../../redux/state/booking/bookingConstants";

export const AcceptReissue = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: reissueId } = useParams();

  const {
    data,
    loading: getDataLoading,
    success: getDataSuccess,
  } = useSelector(({ bookingMng }) => bookingMng.specificReissue);
  const {
    loading: offerReissueLoading,
    success: offerReissueSuccess,
    failure: offerReissueFailure,
  } = useSelector(({ bookingMng }) => bookingMng.reissue);
  const { reIssue } = data || {};
  useEffect(() => {
    dispatch(actions.getSpecificReissue({ id: reissueId }));
  }, [dispatch, reissueId]);

  useEffect(() => {
    if (offerReissueSuccess) {
      history.push(
        parseRoute(BOOKING_MANAGEMENT_REISSUE_DETAILS, { id: reissueId })
      );
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: "Success!",
        })
      );
      dispatch(actions.clearReissue());
    }
  }, [reissueId, dispatch, history, offerReissueSuccess]);

  useEffect(() => {
    if (offerReissueFailure) {
      dispatch(
        inlineNotificationActions.setInlineNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: offerReissueFailure,
        })
      );
      dispatch(actions.clearReissue());
    }
  }, [dispatch, offerReissueFailure]);

  const { selectedAirline, searchRQ } = useMemo(() => {
    if (!getDataSuccess) return { selectedAirline: {}, searchRQ: {} };
    const { segment, supplierId, flight, tripType, id } = reIssue.transaction;
    let segments;
    try {
      segments = JSON.parse(segment);
    } catch {}
    const selectedAirline = {
      segments,
      gds: supplierId,
      cabinClass: flight.ticketType,
    };
    const paxDetails = {
      adult: 1,
      child: 0,
      infant: 0,
      youth: 0,
    };
    const segmentsSummary = segments.map(({ iSeg }) => {
      const departApt = iSeg[0].dApt;
      const arrivalApt = iSeg[iSeg.length - 1].aApt;
      return { arrivalApt, departApt };
    });
    const generalInfo = {
      tripType,
    };
    const searchRQ = {
      paxDetails,
      segments: segmentsSummary,
      generalInfo,
      id,
    };

    return { selectedAirline, searchRQ };
  }, [getDataSuccess, reIssue]);
  const getNewPassengers = useCallback(
    (airline) => {
      const { abf, atx, cbf, ctx, ibf, itx, ybf, ytx } = airline;
      const { passengers } = reIssue.transaction;
      const passengerFare = {
        [PAX_TYPE_ADULT]: abf,
        [PAX_TYPE_YOUTH]: ybf,
        [PAX_TYPE_CHILD]: cbf,
        [PAX_TYPE_INFANT]: ibf,
      };
      const passengerTax = {
        [PAX_TYPE_ADULT]: atx,
        [PAX_TYPE_YOUTH]: ytx,
        [PAX_TYPE_CHILD]: ctx,
        [PAX_TYPE_INFANT]: itx,
      };
      return passengers?.$values.map(({ paxType, ...rest }) => ({
        ...rest,
        paxType,
        totalFareAmount: passengerFare[paxType],
        totalTaxAmount: passengerTax[paxType],
      }));
    },
    [reIssue]
  );

  const onSubmit = (newAirline) => {
    const { segments, supplierAmt, totalAmt, ltd, refNo, pnr } = newAirline;
    const newPassengers = getNewPassengers(newAirline);
    const { dApt, aApt } = segments[0];
    const { dDat } = segments[0];

    // const selectedAirline = { segments: flightSegments };
    // getBookingParams({
    //   travellersValues: { passengers: selectedPassengers },
    //   isReissue: true,
    //   selectedAirline,
    //   searchRQ,
    // });
    const { flight, transactionNotes, ...restParams } = reIssue.transaction;
    const flymeMarkup = +totalAmt - +supplierAmt;
    const { ...restFlight } = flight;
    const params = {
      ...restParams,
      segment: JSON.stringify(segments),
      flight: {
        ...restFlight,
        fromCode: dApt,
        toCode: aApt,
        departureDateTime: dDat,
      },
      totalPax: newPassengers.length.toString(),
      pnr: refNo,
      gdsPnr: pnr,
      bookingStatus: BookingHold,
      agencyMarkup: "0",
      price: `${supplierAmt}`,
      flymeMarkup: `${flymeMarkup}`,
      totalPrice: `${totalAmt}`,
      passengers: newPassengers,
      transaction: "",
      ltd: ltd || "0001-01-01T00:00:00",
    };
    // const params = getBookingParams({
    //   selectedAirline: newAirline,
    //   searchRQ,
    //   travellersValues: { passengers: newPassengers },
    //   isReissue: true,
    // });
    dispatch(actions.acceptReissue({ id: reissueId, params }));
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className="p-7 d-flex">
          <Typography variant="h5">Reissue Offer</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {getDataLoading ? (
          <LoadingSpinner />
        ) : (
          <FlightDetailsForm
            searchRQ={searchRQ}
            selectedAirline={selectedAirline}
            onSubmit={onSubmit}
            loading={offerReissueLoading}
            isReissue
          />
        )}
      </Grid>
    </Grid>
  );
};
