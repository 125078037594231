import React from "react";
import { shallowEqual, useSelector } from "react-redux";
// import { Home } from "../../_metronic/layout/components/home/Home";
import { Home } from "../../_metronic/layout/components/home/NewHome";
import { PublicLayout } from "../../_metronic/layout/components/public/PublicLayout";

export const HomePage = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: !!auth.token,
    }),
    shallowEqual
  );
  return (
    <PublicLayout>
      <Home isAuthorized={isAuthorized} />
    </PublicLayout>
  );
};
