import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CreateSubUser } from "../../../_metronic/layout/components/account/accountSubUser/CreateSubUser";
import { actions } from "../../../redux/state/user";
import { useParams } from "react-router-dom";

export const CreateSubUserPage = () => {
  const dispatch = useDispatch();
  const { id: companyId } = useParams();
  const { loading: createUserLoading, success, userId } = useSelector(
    ({ user }) => user.create
  );

  const subUserSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .required("No password provided")
      .min(6, "Password is too short - should be 8 chars minimum"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    name: Yup.string().required(),
    phone: Yup.number().required(),
  });
  const formik = useFormik({
    initialValues: {
      companyId,
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      phone: "",
    },
    validationSchema: subUserSchema,
    onSubmit: () =>
      dispatch(
        actions.createUser({
          email,
          password,
          companyInUserId: companyId,
          name,
          phone,
          isDeleted: false,
        })
      ),
  });
  useEffect(() => {
    if (success && formik.isValid) formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
  const { email, password, name, phone } = formik.values;
  return (
    <CreateSubUser
      userId={userId}
      createSuccess={success}
      createUserLoading={createUserLoading}
      formik={formik}
    />
  );
};
