import { Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  ABOUT,
  CERTIFICATES,
  CONTACT_US,
  DASHBOARD,
  DOWNLOAD,
  HELP_CENTER,
  HOME,
  PRODUCTS,
  SIGNUP,
} from "../../../../app/routesMap";
import { ADMIN_DASHBOARD } from "../../../../app/routesMapAdmin";
import { actions } from "../../../../redux/state/auth";
import { toAbsoluteUrl } from "../../../_helpers";
import { Button } from "../common";
import { HeaderNavLink } from "./HeaderNavLink";
import styles from "./Public.module.scss";

export const headerLinks = [
  {
    path: HOME,
    label: "Home",
  },
  {
    path: PRODUCTS,
    label: "Products",
  },
  {
    path: ABOUT,
    label: "About Us",
  },
  {
    path: CERTIFICATES,
    label: "Certificates",
  },
  {
    path: DOWNLOAD,
    label: "Download",
  },
  {
    path: CONTACT_US,
    label: "Contact Us",
  },
];

export const DesktopHeader = ({ onSignInClick, isAuthorized }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const { isAdmin } = useSelector(({ company }) => company.companyData);

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className={styles["header-logo"]}>
        <Link to="/">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/new-logo-colored.svg")}
          />
        </Link>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles["div-5"]}>
          {headerLinks.map(({ path, label }) => (
            <HeaderNavLink key={path} path={path} label={label} />
          ))}
        </div>
        <div>
          {isAuthorized ? (
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger mr-5"
                onClick={() =>
                  history.push(isAdmin ? ADMIN_DASHBOARD : DASHBOARD)
                }
              >
                Dashboard
              </button>
              <div>
                <AccountButton handleClick={handleClick} />
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <Button
                variant="primary"
                className="mr-2"
                onClick={onSignInClick}
              >
                Sign In
              </Button>
              <Button
                variant="primary-inverse"
                onClick={() => history.push(SIGNUP)}
              >
                Register
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const HeaderMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const { userId, sessionId } = useSelector(({ auth }) => auth);
  const logout = () => {
    dispatch(actions.logout({ userinUserSessionId: userId, id: sessionId }));
  };
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className="mt-15"
    >
      <MenuItem onClick={handleClose}>
        <button className="btn" onClick={logout}>
          Sign Out
        </button>
      </MenuItem>
    </Menu>
  );
};

export const AccountButton = ({ handleClick }) => {
  return (
    <button
      className="btn p-0 rounded-circle overflow-hidden"
      onClick={handleClick}
      aria-controls="simple-menu"
      aria-haspopup="true"
    >
      <img
        className="w-50px h-50px"
        src={toAbsoluteUrl("media/users/default.jpg")}
        alt=""
      />
    </button>
  );
};
