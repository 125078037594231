import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import { DistributionMarkup } from "../../../_metronic/layout/components/disstribution";
import { EditMarkup } from "../../../_metronic/layout/components/disstribution/EditMarkup";
import { generateNestedObjectKey } from "../../../_metronic/_helpers/object";
import { DISTRIBUTION_MARKUP, DISTRIBUTION_MARKUP_EDIT } from "../../routesMap";
import { ADMIN_MARKUPS, ADMIN_MARKUP_EDIT } from "../../routesMapAdmin";

export const DistributionMarkupPage = ({ permissionKey, permissionId }) => {
  const { isAdmin } = useSelector(({ company }) => company.companyData);
  return (
    <Switch>
      <Route
        // permissionKey={generateNestedObjectKey([
        //   permissionKey,
        //   "subPermissions.editMarkup",
        // ])}
        path={isAdmin ? ADMIN_MARKUP_EDIT : DISTRIBUTION_MARKUP_EDIT}
        component={EditMarkup}
      />
      <Route
        exact
        path={isAdmin ? ADMIN_MARKUPS : DISTRIBUTION_MARKUP}
        render={(props) => (
          <DistributionMarkup {...props} permissionId={permissionId} />
        )}
      />
    </Switch>
  );
};
