import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { CardWithBlueHeader } from "../../../../ticketSummary/TicketDetails";
import { Tabs } from "../../../../common";
import { FlightDetailsCard } from "../../../../fareSearch/DapartingFlights";
import { RulesTabs } from "../../../../fareSearch/FlightDetails";
import { ContactInfo } from "./ContactInfo";
import { PassengersList } from "./PassengersList";
import { TicketInfo } from "./TicketInfo";
import { OrderPriceCard } from "./OrderPriceCard";
import useAirlinesList from "../../../../../../../app/helpers/hooks/useAirlinesList";
import useAirportsList from "../../../../../../../app/helpers/hooks/useAirportsList";
import useCompanyName from "../../../../../../../app/helpers/hooks/useCompanyName";
import { bookingTypesMap } from "../../../../../../../redux/state/booking/bookingConstants";
import { useMemo } from "react";

const MTVoidingTime = (
  <div>
    <h6 className="font-weight-bold mb-3">Dear Valued Customers</h6>
    <p className="mb-3">
      Thank you for being our valued customer. We are glad to serve you, For the
      MT details please reach to us.
    </p>
    <p>Call/ WhatsApp Phone Number</p>
    <p className="text-primary font-weight-bold my-2">
      <a href="tel:+252610809064">+252610809064</a>
    </p>
    <p>To send email</p>
    <p className="text-primary font-weight-bold my-1">
      <a href="mailto:aftersales@flyme247.com">aftersales@flyme247.com</a>
    </p>
    <p className="text-primary font-weight-bold my-1">
      <a href="mailto:ticketing@flyme247.com">ticketing@flyme247.com</a>
    </p>
  </div>
);

export const OrderSummary = ({ data, invoice, bookingType }) => {
  const { getCompanyName } = useCompanyName();
  const {
    passengers,
    segment,
    cabinClass,
    agencyId,
    penalities: penalitiesJson,
    fareRules: fareRulesJson,
    voidableTime,
    gds,
  } = data || {};
  let segments;
  try {
    segments = JSON.parse(segment);
  } catch {}
  let fareRules;
  try {
    fareRules = JSON.parse(fareRulesJson);
  } catch {}
  let cancellationCharges;
  try {
    cancellationCharges = JSON.parse(penalitiesJson);
  } catch {}
  const agencyName = getCompanyName(agencyId);

  const leadGuest = passengers?.$values.filter(
    ({ isLeadGuest }) => isLeadGuest === true
  )?.[0];
  const { airlinesList } = useAirlinesList();
  const { airportsList } = useAirportsList();
  const voidingTime = useMemo(() => {
    const content = (
      <div className="d-flex mb-5">
        <Typography variant="body1" gutterBottom>
          Last Voiding Time:
        </Typography>
        <Typography
          variant="body1"
          className="font-weight-bold ml-2"
          gutterBottom
        >
          {voidableTime}
        </Typography>
      </div>
    );
    return gds === "MTR" ? MTVoidingTime : content;
  }, [gds, voidableTime]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TicketInfo data={data} invoice={invoice} />
      </Grid>
      {!(bookingType === bookingTypesMap.package) && (
        <Grid item xs={12} md={7}>
          <CardWithBlueHeader
            title="Flight information"
            print
            iconSrc="/media/svg/icons/General/plane-white.svg"
          >
            <FlightDetailsCard
              airlinesList={airlinesList}
              airportsList={airportsList}
              segments={segments}
              cabinClass={cabinClass}
              padding
            />
          </CardWithBlueHeader>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={!(bookingType === bookingTypesMap.package) ? 5 : 12}
      >
        <CardWithBlueHeader
          title="Amount information"
          iconSrc="/media/svg/icons/General/cash.svg"
        >
          <OrderPriceCard isBookingDetails data={data} />
        </CardWithBlueHeader>
      </Grid>
      <Grid item xs={12}>
        <PassengersList passengers={passengers} />
      </Grid>
      <Grid item xs={12}>
        <Paper className="p-7">
          <RulesTabs
            fareRules={fareRules}
            cancellationCharges={cancellationCharges}
            voidingTime={voidingTime}
          />
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <InfoTabs />
      </Grid> */}
      <Grid item xs={12}>
        <ContactInfo agencyName={agencyName} leadGuest={leadGuest} />
      </Grid>
      {/* <Grid item xs={12}>
        <Paper>
          <OperationLog />
        </Paper>
      </Grid> */}
    </Grid>
  );
};

export const InfoTabs = () => (
  <Paper className="p-7">
    <Tabs data={tabsData2} />
  </Paper>
);

const tabContent =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehende.Lo commodo consequat. Duis aute irure dolor in reprehende.";
// const tabsData = [
//   { id: "1", title: "Baggage info", content: tabContent },
//   { id: "2", title: "refund and reissue", content: tabContent },
//   { id: "3", title: "Reissue Service Hours", content: tabContent },
//   { id: "4", title: "Tracing and voiding rules", content: tabContent },
//   { id: "5", title: "Fare rules", content: tabContent },
//   { id: "6", title: "Flyme rules", content: tabContent },
// ];
const tabsData2 = [
  { id: "1", title: "reissue reason", content: tabContent },
  { id: "2", title: "Uploaded Documents", content: tabContent },
  { id: "3", title: "Remarks", content: tabContent },
  { id: "4", title: "Third-party ref.no", content: tabContent },
];
