import React from "react";
import { Paper } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_helpers";
import { LoginForm } from "./LoginForm";
import { Modal } from "react-bootstrap";

export const initialValues = {
  // email: "sakulachi8+1@gmail.com",
  // password: "Sa123abc@",
  // email: "ahmedfayezmine3@gmail.com",
  // password: "11223344Aa",
  email: "",
  password: "",
};

export const LoginModal = ({ open, setOpen }) => {
  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      size='md'
    >
      <Paper
        className="bg-white rounded p-7 d-flex flex-column align-items-center"
        style={{ maxWidth: "500px", flexGrow: 1, overflow: "hidden" }}
      >
        <img
          className="w-160px h-auto mb-5"
          src={toAbsoluteUrl("media/logos/logo-colored.png")}
          alt=""
        />
        <LoginForm onSuccess={() => setOpen(false)} />
      </Paper>
    </Modal>
  );
};
