import produce from "immer";
import * as actions from "./authActions";
import {
  LOCAL_STORAGE_SESSION_ID,
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER_ID,
} from "./authConstants";
import { RESET_STATE } from "../common/commonActions";
import { isNil } from "lodash-es";

const initialState = {
  token: localStorage?.getItem(LOCAL_STORAGE_TOKEN),
  userId: localStorage?.getItem(LOCAL_STORAGE_USER_ID),
  sessionId: localStorage?.getItem(LOCAL_STORAGE_SESSION_ID),
  user: undefined,
  sessionData: undefined,
  login: {
    loading: false,
    failure: undefined,
    success: undefined,
    status: 1,
  },
  loginByCode: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  logout: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  resetPassword: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
  requestPasswordReset: {
    loading: false,
    failure: undefined,
    success: undefined,
  },
};

// LOGIN STATUS
// 1 success
// 2 wrong email or password
// 3 email not verified
// 4 login with code
// 5 company is not activated

export const authReducer = produce(
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_LOCAL_STORAGE:
        state.token = localStorage?.getItem(LOCAL_STORAGE_TOKEN);
        state.userId = localStorage?.getItem(LOCAL_STORAGE_USER_ID);
        state.sessionId = localStorage?.getItem(LOCAL_STORAGE_SESSION_ID);
        break;
      case actions.REQUEST_PASSWORD_RESET.REQUESTED:
        state.requestPasswordReset.loading = true;
        break;
      case actions.REQUEST_PASSWORD_RESET.SUCCEEDED:
        state.requestPasswordReset.success = true;
        state.requestPasswordReset.loading = false;
        break;
      case actions.REQUEST_PASSWORD_RESET.FAILED:
        state.requestPasswordReset.loading = false;
        state.requestPasswordReset.failure = payload.error;
        break;
      case actions.RESET_PASSWORD.REQUESTED:
        state.resetPassword.loading = true;
        break;
      case actions.RESET_PASSWORD.SUCCEEDED:
        state.resetPassword.success = true;
        state.resetPassword.loading = false;
        break;
      case actions.RESET_PASSWORD.FAILED:
        state.resetPassword.loading = false;
        state.resetPassword.failure = payload.error?.title;
        break;
      case actions.LOGIN.REQUESTED:
        state.login.loading = true;
        break;
      case actions.LOGIN.SUCCEEDED:
        state.login.success = true;
        state.login.loading = false;
        if (payload.data?.LoginByCode) {
          state.login.status = 4; //login with code (login from different ip)
        } else if (
          !isNil(payload.data?.WrongCredentials) ||
          payload.data?.["User not found"] === false
        ) {
          state.login.status = 2; // email or password is incorrect
        } else if (!isNil(payload.data?.IsEmailVerified)) {
          state.login.status = 3; // email is not verified
        } else if (
          !isNil(payload.data?.isActive) ||
          !isNil(payload.data?.IsActive)
        ) {
          state.login.status = 5; // company is not activated
        } else {
          state.login.status = 1; // success
          state.sessionData = payload.data;
        }
        break;
      case actions.LOGIN.FAILED:
        state.login.loading = false;
        state.login.failure = payload.error;
        break;
      case actions.LOGOUT.REQUESTED:
        state.logout.loading = true;
        state.token = undefined;
        break;
      case actions.LOGOUT.SUCCEEDED:
        state.logout.loading = false;
        state.logout.success = true;
        state.login = initialState.login;
        state.token = undefined;
        state.sessionId = undefined;
        state.userId = undefined;
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_USER_ID);
        localStorage.removeItem(LOCAL_STORAGE_SESSION_ID);
        localStorage.removeItem("_expiredTime");
        break;
      case actions.LOGOUT.FAILED:
        state.logout.loading = false;
        state.logout.failure = payload.error?.title;
        state.login = initialState.login;
        state.token = undefined;
        state.sessionId = undefined;
        state.userId = undefined;
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_USER_ID);
        localStorage.removeItem(LOCAL_STORAGE_SESSION_ID);
        localStorage.removeItem("_expiredTime");
        break;
      case actions.LOGIN_BY_CODE.REQUESTED:
        state.loginByCode.loading = true;
        break;
      case actions.LOGIN_BY_CODE.SUCCEEDED:
        state.loginByCode.loading = false;
        state.loginByCode.success = true;
        state.sessionData = payload.data;
        // state.token = payload.data.token;
        // state.sessionId = payload.data.id;
        // state.userId = payload.data.userinUserSessionId;
        // state.sessionInfo = payload.data;
        // localStorage.setItem(LOCAL_STORAGE_TOKEN, payload.data.token);
        // localStorage.setItem(
        //   LOCAL_STORAGE_USER_ID,
        //   payload.data.userinUserSessionId
        // );
        // localStorage.setItem(LOCAL_STORAGE_SESSION_ID, payload.data.id);

        break;
      case actions.AUTHORIZE_LOGIN_BY_CODE:
        const token = state.sessionData.token;
        const sessionId = state.sessionData.id;
        const userId = state.sessionData.userinUserSessionId;
        state.token = token;
        state.sessionId = sessionId;
        state.userId = userId;
        localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
        localStorage.setItem(LOCAL_STORAGE_USER_ID, userId);
        localStorage.setItem(LOCAL_STORAGE_SESSION_ID, sessionId);
        break;

      case actions.LOGIN_BY_CODE.FAILED:
        state.loginByCode.loading = false;
        if (payload.error?.status === 404) {
          state.loginByCode.failure = "Invalid code!";
        } else {
          state.loginByCode.failure = "Something is wrong!";
        }
        break;
      case actions.LOGIN_BY_CODE.CLEAR:
        state.loginByCode = initialState.loginByCode;
        break;
      case actions.LOGIN.CLEAR:
        state.login = initialState.login;
        break;
      case actions.RESET:
      case RESET_STATE:
        return initialState;
      default:
        return state;
    }
  }
);
