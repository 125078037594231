/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage5 } from "./modules/ErrorsExamples/ErrorPage5";
import { ApolloProvider } from "@apollo/client";
import graphqlClient from "../graphql/graphqlClient";

export default function App({ store, persistor, basename }) {
  return (
    <ApolloProvider client={graphqlClient}>
      {/* Provide Redux store  */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        {/* <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}> */}
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              {/* Render routes with provided `Layout`. */}
              {/* <AuthInit> */}
              <ErrorBoundary FallbackComponent={ErrorPage5}>
                <Routes />
              </ErrorBoundary>
              {/* </AuthInit> */}
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
        {/* </PersistGate> */}
      </Provider>
    </ApolloProvider>
  );
}
