import { gql } from "@apollo/client";

// common fragments
export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on UploadFile {
    name
    url
  }
`;

export const ACTION_FRAGMENT = gql`
  fragment ActionFragment on ComponentElementsButton {
    title
    color
    link
    isExternal
  }
`;

export const CONTENT_CARD_FRAGMENT = gql`
  fragment ContentCardFragment on ComponentBlocksContentWithImage {
    title
    description
    image {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    imagePosition
    action {
      ...ActionFragment
    }
  }
  ${IMAGE_FRAGMENT}
  ${ACTION_FRAGMENT}
`;

export const CONTENT_CARD_WITH_ICON_FRAGMENT = gql`
  fragment ContentCardWithIconFragment on ComponentBlocksContentWithTextIconPoints {
    title
    backgroundImage {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    textIcon {
      title
      description
      icon {
        data {
          attributes {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const DOWNLOAD_BLOCK_FRAGMENT = gql`
  fragment DownloadBlockFragment on ComponentBlocksDownloadDetailsBlock {
    viewOnDifferentScreens {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    iosQRCode {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    androidQRCode {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    iosLink
    androidLink
  }
  ${IMAGE_FRAGMENT}
`;

// about us page
export const GET_ABOUT_US_CONTENT = gql`
  query GetAboutUsContent {
    aboutUsPage {
      data {
        attributes {
          heroBlock {
            ...ContentCardFragment
          }
          cardsBlock {
            title
            cards {
              title
              description
            }
          }
          iconPointsBlock {
            ...ContentCardWithIconFragment
          }
          contentBlocks {
            ...ContentCardFragment
          }
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_WITH_ICON_FRAGMENT}
`;

// product page
export const GET_PRODUCT_CONTENT = gql`
  query GetProductContent {
    productsPage {
      data {
        attributes {
          contentBlocks {
            ...ContentCardFragment
          }
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
`;

// certificates page
export const GET_CERTIFICATES_CONTENT = gql`
  query GetCertificatesContent {
    certificatesPage {
      data {
        attributes {
          partners {
            data {
              attributes {
                ...ImageFragment
              }
            }
          }
          contentBlocks {
            ...ContentCardFragment
          }
          heroBlock {
            ...ContentCardFragment
          }
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

// home page
export const GET_HOME_CONTENT = gql`
  query GetHomeContent {
    homePage {
      data {
        attributes {
          heroBlock {
            ...ContentCardFragment
          }
          experienceBlock {
            ...ContentCardFragment
          }
          discoverBlock {
            ...ContentCardWithIconFragment
          }
          stepsBlock {
            title
            steps {
              title
              description
              icon {
                data {
                  attributes {
                    ...ImageFragment
                  }
                }
              }
            }
            image {
              data {
                attributes {
                  ...ImageFragment
                }
              }
            }
          }
          downloadBlock {
            ...DownloadBlockFragment
          }
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${CONTENT_CARD_WITH_ICON_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${DOWNLOAD_BLOCK_FRAGMENT}
`;

// download page
export const GET_DOWNLOAD_CONTENT = gql`
  query GetDownloadContent {
    downloadPage {
      data {
        attributes {
          heroBlock {
            ...ContentCardFragment
          }
          downloadDetails {
            ...DownloadBlockFragment
          }
        }
      }
    }
  }
  ${CONTENT_CARD_FRAGMENT}
  ${DOWNLOAD_BLOCK_FRAGMENT}
`;

// contact page
export const GET_CONTACT_CONTENT = gql`
  query GetContactContent {
    contactUsPage {
      data {
        attributes {
          addressCards {
            title
            addressText
            phone
            email
            icon {
              data {
                attributes {
                  ...ImageFragment
                }
              }
            }
          }
          pageTitle
          pageDescription
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;
